import { useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import api from 'api';

const RunSteps = ({ onUpdate, hasUnits }) => {
  const [activeStep, setActiveStep] = useState(0);

  const [data, setData] = useState({
    runningLevel: null
  });

  const handleNext = (nextStep) => {
    if (hasUnits) {
      onUpdate(data);
      return;
    }
    setActiveStep(nextStep);
  };

  return (
    <>
      <div className='onboarding-back'>
        <button disabled={!activeStep} onClick={() => setActiveStep(activeStep - 1)}>
          Back
        </button>
      </div>
      {activeStep === 0 && (
        <div className='onboarding-section'>
          <div className='onboarding-header'>
            <h1>What level is your running currently?</h1>
          </div>
          {runningLevel.map((level, i) => (
            <div
              key={`level-${i}`}
              className={`onboarding-button${data.runningLevel === i + 1 ? '--active' : ''}`}
              onClick={() => setData({ ...data, runningLevel: i + 1 })}>
              <p className='onboarding-button__text'>{level.text}</p>
              <p className='onboarding-button__subtext'>{level.subtext}</p>
            </div>
          ))}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button
              className='onboarding-next-button'
              disabled={!data.runningLevel}
              onClick={() => handleNext(activeStep + 1)}>
              Next
            </button>
          </div>
        </div>
      )}

      {activeStep === 1 && (
        <div className='onboarding-section'>
          <div className='onboarding-header'>
            <h1>Which units should we use?</h1>
          </div>
          {units.map((unit, i) => (
            <div
              key={`unit-${i}`}
              className={`onboarding-button${
                data.preferredUnits === unit.value.preferredUnits ? '--active' : ''
              } flex-center-all`}
              onClick={() => setData({ ...data, ...unit.value })}>
              <p className='onboarding-button__text'>{unit.text}</p>
            </div>
          ))}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button
              className='onboarding-next-button'
              disabled={!data.lapUnit}
              onClick={() => onUpdate(data)}>
              Next
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const SwimmingSteps = ({ onUpdate, hasUnits, lapUnit }) => {
  const [activeStep, setActiveStep] = useState(0);

  const [data, setData] = useState({
    swimmingLevel: null
  });

  const handleNext = (nextStep) => {
    if (hasUnits) {
      setActiveStep(2);
      return;
    }
    setActiveStep(nextStep);
  };

  return (
    <>
      <div className='onboarding-back'>
        <button disabled={!activeStep} onClick={() => setActiveStep(activeStep - 1)}>
          Back
        </button>
      </div>
      {activeStep === 0 && (
        <div className='onboarding-section'>
          <div className='onboarding-header'>
            <h1>What level is your swimming currently?</h1>
          </div>
          {swimmingLevel.map((level, i) => (
            <div
              key={`level-${i}`}
              className={`onboarding-button${data.swimmingLevel === i + 1 ? '--active' : ''}`}
              onClick={() => setData({ ...data, swimmingLevel: i + 1 })}>
              <p className='onboarding-button__text'>{level.text}</p>
              <p className='onboarding-button__subtext'>{level.subtext}</p>
            </div>
          ))}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button
              className='onboarding-next-button'
              disabled={!data.swimmingLevel}
              onClick={() => handleNext(activeStep + 1)}>
              Next
            </button>
          </div>
        </div>
      )}

      {activeStep === 1 && (
        <div className='onboarding-section'>
          <div className='onboarding-header'>
            <h1>Which units should we use?</h1>
          </div>
          {units.map((unit, i) => (
            <div
              key={`unit-${i}`}
              className={`onboarding-button${
                data.preferredUnits === unit.value.preferredUnits ? '--active' : ''
              } flex-center-all`}
              onClick={() => setData({ ...data, ...unit.value })}>
              <p className='onboarding-button__text'>{unit.text}</p>
            </div>
          ))}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button
              className='onboarding-next-button'
              disabled={!data.lapUnit}
              onClick={() => handleNext(activeStep + 1)}>
              Next
            </button>
          </div>
        </div>
      )}

      {activeStep === 2 && (
        <div className='onboarding-section'>
          <div className='onboarding-header'>
            <h1>What length pool do you normally swim in?</h1>
          </div>
          {lapLength.map((length, i) => (
            <div
              key={`length-${i}`}
              className={`onboarding-button${
                data.lapLength === length.value ? '--active' : ''
              } flex-center-all`}
              onClick={() => setData({ ...data, lapLength: length.value })}>
              <p className='onboarding-button__text'>
                {length.text}
                {lapUnit || data.lapUnit}
              </p>
            </div>
          ))}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button
              className='onboarding-next-button'
              disabled={!data.lapLength}
              onClick={() => onUpdate(data)}>
              Next
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const FlowSelector = ({ onChange, onboardStatus, onSkip }) => (
  <div className='onboarding-section'>
    <div className='onboarding-header'>
      <h1>Welcome to Simma!</h1>
      <h6>Help us set up the application for you.</h6>
    </div>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        alignItems: 'center'
      }}>
      <button
        className='onboarding-next-button'
        style={{ minWidth: 263 }}
        disabled={onboardStatus.onboardRun}
        onClick={() => onChange('running')}>
        Set up running
      </button>
      <button
        className='onboarding-next-button'
        style={{ minWidth: 263 }}
        disabled={onboardStatus.onboardSwimming}
        onClick={() => onChange('swimming')}>
        Set up swimming
      </button>
      {(onboardStatus.onboardRun || onboardStatus.onboardSwimming) && (
        <button onClick={onSkip} style={{ marginTop: 40 }}>
          Skip for now
        </button>
      )}
    </div>
  </div>
);

const FinishStep = ({ action, garmin, setGarmin }) => (
  <div className='onboarding-section'>
    <div className='onboarding-header'>
      <h1>Connect your Garmin account</h1>
      <p>You can connect an Apple watch with our iOS app</p>
    </div>
    <div
      className={`onboarding-button${garmin ? '--active' : ''} flex-center-all`}
      onClick={() => setGarmin(true)}>
      <p className='onboarding-button__text'>Connect Garmin account</p>
    </div>

    <div
      className={`onboarding-button${garmin === false ? '--active' : ''} flex-center-all`}
      onClick={() => setGarmin(false)}>
      <p className='onboarding-button__text'>No thanks</p>
    </div>

    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>{action}</div>
  </div>
);

const Onboard = () => {
  const history = useHistory();
  const [data, setData] = useState({});
  const [currentFlow, setCurrentFlow] = useState('');
  const [onboardStatus, setOnboardStatus] = useState({
    garmin: null,
    unitsPreference: false,
    onboardSwimming: false,
    onboardRun: false
  });

  const isFinished = onboardStatus.onboardSwimming && onboardStatus.onboardRun;

  const mutation = useMutation((data) => api.user.update(data), {
    onSuccess: async (data) => {
      if (onboardStatus.garmin) {
        const userId = data.id;
        const tokenResponse = await api.garmin.requestToken();
        window.location = `https://connect.garmin.com/oauthConfirm?oauth_token=${tokenResponse.oauthToken}&oauth_callback=${window.location.origin}/garmin?userId=${userId}`;
      } else {
        history.push('/progress');
      }
    }
  });

  const handleUpdateData = (data) => {
    if (data.lapUnit && data.preferredUnits) {
      setOnboardStatus((prevState) => ({
        ...prevState,
        unitsPreference: true
      }));
    }
    if (currentFlow === 'swimming') {
      setOnboardStatus((prevState) => ({
        ...prevState,
        onboardSwimming: true
      }));
    }
    if (currentFlow === 'running') {
      setOnboardStatus((prevState) => ({ ...prevState, onboardRun: true }));
    }
    setData((prevState) => ({ ...prevState, ...data }));
    setCurrentFlow('');
  };

  const submit = () => {
    mutation.mutate({ ...data, onboarded: true });
  };

  return (
    <div className='onboarding-container'>
      {!currentFlow && !isFinished && (
        <FlowSelector onChange={setCurrentFlow} onboardStatus={onboardStatus} onSkip={submit} />
      )}

      {currentFlow === 'swimming' && (
        <SwimmingSteps
          onUpdate={handleUpdateData}
          hasUnits={onboardStatus.unitsPreference}
          lapUnit={data.lapUnit}
        />
      )}

      {currentFlow === 'running' && (
        <RunSteps onUpdate={handleUpdateData} hasUnits={onboardStatus.unitsPreference} />
      )}

      {isFinished && (
        <FinishStep
          garmin={onboardStatus.garmin}
          setGarmin={(garmin) => setOnboardStatus((prevState) => ({ ...prevState, garmin }))}
          action={
            <button
              className='onboarding-next-button'
              onClick={submit}
              disabled={mutation.isLoading || mutation.isSuccess || onboardStatus.garmin === null}>
              Finish
            </button>
          }
        />
      )}
    </div>
  );
};

export default Onboard;

const swimmingLevel = [
  {
    text: 'Learning to swim',
    subtext: 'I can barely swim a length'
  },
  {
    text: 'New to swimming regularly',
    subtext: 'I am starting a regular practice for the the first time'
  },
  {
    text: 'Intermediate swimmer',
    subtext: 'I know something about training with sets'
  },
  {
    text: 'Advanced swimmer',
    subtext: 'I am comfortable with training sets'
  }
];

const runningLevel = [
  {
    text: 'New to running',
    subtext: 'I am working to my first regular run habit'
  },
  {
    text: 'Getting back into running regularly',
    subtext: 'I am starting a regular practice for the the first time'
  },
  {
    text: 'Intermediate runner',
    subtext: 'I know something about training with sets'
  },
  {
    text: 'Advanced runner',
    subtext: 'I am comfortable with training sets'
  }
];

const units = [
  {
    text: 'Metric',
    value: {
      preferredUnits: 'metric',
      lapUnit: 'm'
    }
  },
  {
    text: 'Imperial',
    value: {
      preferredUnits: 'imperial',
      lapUnit: 'y'
    }
  }
];

const lapLength = [
  {
    text: '25',
    value: 25
  },
  {
    text: '50',
    value: 50
  }
];
