import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { styled } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import colors from "style/colors";

const HelpIconTooltip = ({ content, icon, color }) => {
  return (
    <HtmlTooltip title={<TooltipContent content={content} />}>
      <HelpOutlineIcon fontSize="16" sx={{ color: "primary.main" }} />
    </HtmlTooltip>
  );
};
export default HelpIconTooltip;

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 450,
    padding: "1rem",
    backgroundColor: colors.backgroundTertiary,
  },
}));

const TooltipContent = ({ content }) => {
  if (typeof content === "string") {
    return <Typography pb={2}>{content}</Typography>;
  }

  if (Array.isArray(content)) {
    return (
      <Box>
        {content.map((element) => (
          <TooltipContent content={element} />
        ))}
      </Box>
    );
  }

  return content;
};
