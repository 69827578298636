import SvgIcon from '@mui/material/SvgIcon';

const HeartRateIcon = props => {
	return (
		<svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M32.45 11.1867C32.1155 11.1867 31.845 10.9157 31.845 10.5817C31.845 9.80066 31.6919 9.04254 31.3909 8.32841C31.0983 7.6385 30.6808 7.01901 30.1501 6.48833C29.6191 5.95681 29 5.53929 28.3097 5.24753C28.0021 5.11777 27.8582 4.76229 27.9884 4.45462C28.1186 4.14711 28.4724 4.00274 28.7818 4.13336C29.6161 4.48627 30.3644 4.99088 31.0064 5.63324C31.6475 6.27432 32.1521 7.02229 32.5054 7.857C32.8707 8.72153 33.0554 9.63761 33.0554 10.5815C33.055 10.9157 32.7844 11.1867 32.45 11.1867L32.45 11.1867Z" fill="#FF59B9"/>
			<path d="M18.1602 23.4044C17.9111 23.4044 17.6854 23.2504 17.5957 23.0154L14.3815 14.6018L13.3325 18.0774C13.2555 18.3331 13.0201 18.5077 12.7534 18.5077H3.72023C3.38579 18.5077 3.11523 18.2367 3.11523 17.9027C3.11523 17.5688 3.38579 17.2977 3.72023 17.2977H12.3043L13.7356 12.5559C13.8103 12.3082 14.0348 12.1353 14.293 12.1261C14.5451 12.1168 14.7876 12.273 14.88 12.5147L18.0453 20.8014L21.4205 7.54672C21.4853 7.29063 21.7091 7.10672 21.9731 7.09183C22.2234 7.07465 22.4791 7.23521 22.5728 7.48255L26.2713 17.2971L35.8402 17.297C36.1747 17.297 36.4452 17.568 36.4452 17.902C36.4452 18.236 36.1747 18.5078 35.8402 18.5078H25.853C25.6013 18.5078 25.3756 18.3521 25.2866 18.1162L22.1183 9.70892L18.747 22.9489C18.6822 23.2045 18.4592 23.3884 18.1957 23.4034C18.1838 23.4039 18.1723 23.4044 18.16 23.4044L18.1602 23.4044Z" fill="#FF59B9"/>
			<path d="M1.01795 18.4288C0.859969 18.4288 0.701129 18.3672 0.591114 18.2527C0.476674 18.1427 0.410645 17.9843 0.410645 17.8259C0.410645 17.663 0.476674 17.5091 0.591114 17.3946C0.811114 17.1746 1.22031 17.1702 1.44475 17.3991C1.55919 17.5091 1.62035 17.6675 1.62035 17.8259C1.62035 17.9843 1.55876 18.1427 1.44475 18.2527C1.33518 18.3672 1.17677 18.4288 1.01791 18.4288L1.01795 18.4288Z" fill="#FF59B9"/>
			<path d="M38.9823 18.4288C38.8243 18.4288 38.6654 18.3672 38.5554 18.2528C38.441 18.1428 38.3794 17.9844 38.3794 17.8259C38.3794 17.6675 38.4414 17.5091 38.5554 17.3991C38.7799 17.1704 39.1887 17.1704 39.4087 17.3991C39.5235 17.5091 39.5891 17.6675 39.5891 17.8259C39.5891 17.9844 39.5235 18.1428 39.4087 18.2528C39.2987 18.3672 39.1407 18.4288 38.9823 18.4288Z" fill="#FF59B9"/>
			<path d="M32.8246 15.8989C32.69 15.8989 32.5549 15.8541 32.4428 15.7626C32.1837 15.5513 32.1449 15.1703 32.3566 14.9112C33.5094 13.4988 34.1703 11.5887 34.1703 9.67192C34.1703 5.00755 30.3771 1.21292 25.7138 1.21292C23.6432 1.21292 21.6471 1.97676 20.0925 3.36323C19.8632 3.56834 19.5165 3.56834 19.2872 3.36323C17.7305 1.97463 15.7343 1.20991 13.6659 1.20991C9.0037 1.20991 5.21017 4.99501 5.21017 9.64838C5.21017 11.5967 5.88121 13.5123 7.05194 14.9045C7.26664 15.1606 7.23369 15.5421 6.97803 15.7573C6.72151 15.972 6.34095 15.9391 6.12524 15.6834C4.77488 14.0766 4 11.8771 4 9.64847C4 4.3285 8.33583 0 13.6657 0C15.8564 0 17.9762 0.749393 19.6897 2.12036C21.4017 0.75152 23.5206 0.00315328 25.7136 0.00315328C31.0435 0.00315328 35.3801 4.34082 35.3801 9.67215C35.3801 11.8634 34.6194 14.052 33.2941 15.6763C33.174 15.823 33.0002 15.899 32.8245 15.899L32.8246 15.8989Z" fill="#FF59B9"/>
			<path d="M20.0189 31.6348C19.8415 31.6348 19.6734 31.5569 19.5583 31.4222L10.3392 20.6045C10.1227 20.3501 10.1531 19.9682 10.4073 19.7517C10.6621 19.5348 11.0436 19.566 11.2601 19.8194L20.0187 30.0974L28.7883 19.8194C29.0043 19.565 29.3867 19.5347 29.6411 19.7521C29.895 19.9689 29.9254 20.3509 29.7084 20.6049L20.479 31.4227C20.3643 31.5573 20.1957 31.6348 20.0188 31.6348L20.0189 31.6348Z" fill="#FF59B9"/>
		</svg>
	);
}

export default HeartRateIcon;
