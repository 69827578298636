import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useInfiniteQuery } from 'react-query';
import InfiniteScroll from 'react-infinite-scroll-component';
// @mui
import { Box, Container, LinearProgress, Stack, Typography } from '@mui/material';
// components
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
import { SkeletonActivityItem } from '../../components/skeleton';
import StickyWrap from '../../components/sticky-wrap';
import ActivityItem from '../../components/Activities/ActivityItem';
import { SortBySelector, FiltersPopover } from '../../components/filters';
import api from 'api';
import { UserContext } from '../../context';

import { capitalize } from 'util';

const SORT_OPTIONS = [
  {
    value: 'latest',
    label: 'Latest first',
    params: { sortBy: 'date', sortOrder: 'desc' }
  },
  {
    value: 'oldest',
    label: 'Oldest first',
    params: { sortBy: 'date', sortOrder: 'asc' }
  },
  {
    value: 'distance_short',
    label: 'Distance short to long',
    params: { sortBy: 'distance', sortOrder: 'asc' }
  },
  {
    value: 'distance_long',
    label: 'Distance long to short',
    params: { sortBy: 'distance', sortOrder: 'desc' }
  },
  {
    value: 'stress_low',
    label: 'Stress low to high',
    params: { sortBy: 'stress', sortOrder: 'asc' }
  },
  {
    value: 'stress_high',
    label: 'Stress high to low',
    params: { sortBy: 'stress', sortOrder: 'desc' }
  }
];

const FILTERS_OPTIONS = [
  // { value: 'all_activities', label: 'All Activities' },
  // { value: 'all_swims', label: 'All Swims', activityType: 'swimming' },
  // { value: 'pool_swims', label: 'Pool Swims', activityType: 'poolSwim' },
  // {
  //   value: 'open_water_swims',
  //   label: 'Open Water Swims',
  //   activityType: 'openWaterSwim',
  // },
  // { value: 'runs', label: 'Runs', activityType: 'running' },
];

const ActivityListPage = () => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const userFilterOptions = user.activityTypes.map((item) => ({
    value: item,
    label: capitalize(item),
    activityType: item
  }));

  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0].value);
  const [filter, setFilter] = useState([]);
  const [params, setParams] = useState({});
  const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } = useInfiniteQuery({
    queryKey: ['activitiesData', params],
    queryFn: ({ pageParam = 0 }) => api.activities.getActivityList({ page: pageParam, ...params }),
    getNextPageParam: (lastPage) => lastPage.nextPage
  });

  const activityData = data?.pages.flatMap((page) => page.results.map((item) => item)) ?? [];
  const isNothingFound = !isLoading && !activityData.length;
  const dataLength = data?.pages ? data.pages.length * data.pages[0].results.length : 0;

  const handleChangeSortBy = (event) => {
    const newParams = getFilterParams(event.target.value, filter);
    setParams(newParams);
    setSortBy(event.target.value);
  };

  const handleChangeFilter = (data) => {
    const newParams = getFilterParams(sortBy, data, [...FILTERS_OPTIONS, ...userFilterOptions]);
    console.log(newParams);
    setParams(newParams);
    setFilter(data);
  };

  const handleOnClick = (id) => {
    history.push(`/activity/${id}`);
  };

  return (
    <Box sx={{ pb: 1 }}>
      <Container>
        <StickyWrap sx={{ pb: 1, pt: 2, transform: 'translateY(-8px)' }}>
          <CustomBreadcrumbs
            links={[
              {
                name: 'Activities'
              }
            ]}
          />

          <Stack mb={4} direction='row' alignItems='center' justifyContent='space-between'>
            <SortBySelector
              sortBy={sortBy}
              sortOptions={SORT_OPTIONS}
              onChange={handleChangeSortBy}
            />
            <FiltersPopover
              filterOptions={[...FILTERS_OPTIONS, ...userFilterOptions]}
              onApply={handleChangeFilter}
            />
          </Stack>
        </StickyWrap>

        <InfiniteScroll
          dataLength={dataLength}
          next={fetchNextPage}
          hasMore={hasNextPage}
          scrollableTarget='scrollContainer'>
          {(isLoading && !isNothingFound ? [...Array(12)] : activityData).map((item, index) =>
            item ? (
              <ActivityItem
                data={item}
                unitSystem={user.preferredUnits}
                key={item.id}
                onClick={() => handleOnClick(item.id)}
              />
            ) : (
              <SkeletonActivityItem key={index} />
            )
          )}
        </InfiniteScroll>

        {isNothingFound && (
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            Nothing found...
          </Typography>
        )}

        {isFetchingNextPage && <LinearProgress sx={{ borderRadius: 4 }} />}
      </Container>
    </Box>
  );
};

const getFilterParams = (sortBy, filter, filterOptions) => {
  const sortParam = SORT_OPTIONS.find((el) => sortBy === el.value)?.params || {};
  const activityTypes = filter
    ? filterOptions
        .filter((item) => filter.includes(item.value) && item.activityType)
        .map((item) => item.activityType)
        .join(',')
    : {};
  return { ...sortParam, ...(activityTypes && { activityTypes }) };
};

export default ActivityListPage;
