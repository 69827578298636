import Layout from '../components/Layout'
// import PropTypes from 'prop-types';

const Sessions = () => {
  return (
    <Layout title={"Sessions"} headerTitle={"Sessions"}>
      <div>Here are sessions</div>
    </Layout>
  );
}

// Sessions.propTypes = {
//   : PropTypes.
// };

export default Sessions;
