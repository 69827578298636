// import PropTypes from 'prop-types';

const Freestyle = ({size = 34}) => {

  return (
    <svg width={`${size}`} height={`${size}`} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.15365 29H5.53939V25.8364H8.78891V24.7085H5.53939V22.415H9.09506V21.2495H4.15365V29ZM10.6912 29H12.0769V26.0835H13.6131L15.1331 29H16.7176L15.0257 25.874C15.9441 25.541 16.4866 24.6924 16.4866 23.6665V23.6558C16.4866 22.1787 15.4876 21.2495 13.8225 21.2495H10.6912V29ZM12.0769 25.02V22.3721H13.6507C14.5315 22.3721 15.0633 22.877 15.0633 23.688V23.6987C15.0633 24.5312 14.553 25.02 13.6775 25.02H12.0769ZM18.2278 29H23.2444V27.8345H19.6135V25.6162H23.0456V24.5151H19.6135V22.415H23.2444V21.2495H18.2278V29ZM25.0231 29H30.0397V27.8345H26.4088V25.6162H29.841V24.5151H26.4088V22.415H30.0397V21.2495H25.0231V29Z" fill="#1ADEA3"/>
      <path d="M4.89996 16.6936C5.204 16.8691 5.54889 16.9615 5.89996 16.9615C6.25104 16.9615 6.59592 16.8691 6.89996 16.6936L8.40997 15.8235C8.86603 15.5602 9.38336 15.4216 9.90997 15.4216C10.4366 15.4216 10.9539 15.5602 11.41 15.8235L12.41 16.4136C12.866 16.6769 13.3834 16.8155 13.91 16.8155C14.4366 16.8155 14.9539 16.6769 15.41 16.4136L16.41 15.8235C16.866 15.5602 17.3834 15.4216 17.91 15.4216C18.4366 15.4216 18.9539 15.5602 19.41 15.8235L19.8799 16.0936C19.8351 15.3988 19.6551 14.7194 19.35 14.0936C18.6857 12.1746 17.8905 10.3036 16.97 8.49356C16.7903 8.1194 16.59 7.75551 16.37 7.40356C15.9262 6.74231 15.2498 6.27229 14.4753 6.087C13.7008 5.90171 12.885 6.01473 12.19 6.40356C11.77 6.63356 10.92 7.17356 10.49 7.40356L6.31995 9.87356C6.02146 10.0596 5.80399 10.3512 5.71069 10.6903C5.61739 11.0294 5.65515 11.3912 5.81647 11.7038C5.97778 12.0163 6.25077 12.2567 6.58124 12.3771C6.91171 12.4975 7.27542 12.4891 7.59998 12.3535L13.2599 9.88354C13.4799 10.5735 13.74 11.3436 14 12.1536C9.72 13.3036 5.85 14.9336 4 16.3736C4.32101 16.4079 4.62935 16.5175 4.89996 16.6936Z" fill="#1ADEA3"/>
      <path d="M24.89 9.97363C23.8291 9.97363 22.8117 10.3951 22.0615 11.1452C21.3114 11.8953 20.89 12.9128 20.89 13.9736C20.8917 15.0265 21.3086 16.0361 22.05 16.7836C22.5161 16.7654 22.9714 16.6387 23.3799 16.4136L24.3799 15.8236C24.836 15.5603 25.3533 15.4217 25.8799 15.4217C26.4066 15.4217 26.9239 15.5603 27.3799 15.8236L28.16 16.2736C28.5809 15.6746 28.8295 14.9716 28.8784 14.2411C28.9274 13.5106 28.7749 12.7807 28.4376 12.1309C28.1003 11.481 27.5912 10.9363 26.9656 10.5559C26.3401 10.1755 25.6221 9.97411 24.89 9.97363Z" fill="#1ADEA3"/>
    </svg>
  )
}

// Freestyle.propTypes = {
//   : PropTypes.
// };

export default Freestyle;
