import useStyles from './index.styles'

import { useState } from 'react';

import {
	Bar,
	BarChart,
	CartesianGrid,
	ReferenceLine,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from "recharts";
import { Box, Chip, Stack, Typography } from "@mui/material";

import {
	CHART_TEXT,
	parseChartData,
	resolveEfficiencyPullForceText,
	resolveTextColor
} from "../../utils";

import { HandIcon } from "../../../../assets/icons";
import CustomToolTip from "../../../charts/CustomToolTip";

const PullForce = ({ data, isMobile, features, hand, pullForce }) => {

	const { classes } = useStyles({ isMobile })

	const blah = parseChartData(features, pullForce, CHART_TEXT.PULL_FORCE)

	return (
		<Box className={ classes.root }>
			<Box className={ classes.header }>
				<Typography variant={ isMobile ? 'h5' : 'h4' }>Pull Force</Typography>
				<Typography variant={ 'body2' } className={ classes.greyText }>Pull Force measures the acceleration of
					your hand as it pull through the water to propel you forward and is expressed a measure of
					g-force</Typography>
			</Box>
			<Box className={ classes.subHeader }>
				<Typography variant={ 'h6' }>Analysis</Typography>
				<Typography variant={ 'body2' } className={ classes.greyText }>Data is compared to your
					"normal"</Typography>
			</Box>
			<Box className={ classes.buttonsContainer }>
				<Box className={ classes.chipContainer }>
					<Chip
						key={ 'Free' }
						className={ classes.activeChip }
						label={ 'Free' }
					/>
				</Box>
				<Box className={ classes.handContainer }>
					<HandIcon type={ hand }/>
					<Typography
						className={ classes.handText }>{ hand.charAt(0).toUpperCase() + hand.slice(1) } hand</Typography>
				</Box>
			</Box>
			<Box className={ classes.statsWrapper }>
				<Box className={ classes.statsContainer }>
					<Stack>
						<Typography variant={ 'h5' }>
							{ `${ data[pullForce].mean }g` }
						</Typography>
						<Typography variant={ 'caption' } sx={ { color: 'rgba(235, 235, 245, 0.6)' } }>
							Avg. this swim
						</Typography>
					</Stack>
					<Stack>
						<Typography sx={ { color: resolveTextColor(data[pullForce].similarityColor) } }
									variant={ 'h5' }>
							{ resolveEfficiencyPullForceText(data[pullForce].similarityColor) }
						</Typography>
						<Typography variant={ 'caption' } sx={ { color: 'rgba(235, 235, 245, 0.6)' } }>
							{ `${ (data[pullForce].similarityPercent) }% p-value` }
						</Typography>
					</Stack>
				</Box>
				<Box className={ classes.graphStatsContainer }>
					<Box className={ classes.square }></Box>
					<Typography sx={ { fontSize: '11px', marginRight: '17px' } }>Pull force</Typography>
					<Typography
						sx={ { fontSize: '11px', marginRight: '8px' } }>{ data[pullForce].normal + 'g' }</Typography>
					<Typography sx={ { fontSize: '11px', marginRight: '8px' } }>Your ‘normal’</Typography>
				</Box>
			</Box>
			<Box className={ classes.chartContainer }>
				<ResponsiveContainer width="100%" height="100%">
					<BarChart
						data={ blah.data }
						margin={ { top: 0, right: 0, left: 0, bottom: 0 } }
					>
						<CartesianGrid stroke={ 'rgba(255, 255, 255, 0.14)' }/>
						<XAxis ticks={ blah.ticks }/>
						<YAxis width={ 40 }/>
						<Tooltip content={ <CustomToolTip/> }/>
						<Bar dataKey={ CHART_TEXT.PULL_FORCE } fill="#FF5082"/>
						<ReferenceLine y={ blah.normal } stroke="white" strokeDasharray="3 3"/>
					</BarChart>
				</ResponsiveContainer>
			</Box>
			<Stack gap={ '8px' }>
				<Typography variant={ 'h6' }>About Pull Force</Typography>
				<Typography variant={ 'body1' } className={ classes.greyText }>Pull Force will change sa you add more
					effort into your swimming. A stronger force will increase pace at the expense of fatigue. Focusssing
					on pulling through with force right to the back of your stroke will improve your performance.
					Combining focus on this and depriving yourself of rest will build speed and endurance in your
					swimming. </Typography>
			</Stack>
		</Box>
	);
};

export default PullForce;
