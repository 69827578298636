import { coachApi } from "../axios";

export const list = async () => {
  const { data } = await coachApi.get("coaching/products");
  return data;
};

export const pricingGuidelines = async () => {
  const { data } = await coachApi.get("coaching/pricing-guidelines");
  return data;
};
