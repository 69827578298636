import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const PoolSwimIcon = ({sx, ...props}) => {
  return (
    <SvgIcon sx={{fill: '#00CDFF', fontSize: 48, ...sx}} {...props} viewBox="0 0 48 48">
      <rect width="48" height="48" rx="24" />
      <path d="M19 32.3333C19 26.7368 19 18.8632 19 17.4737C19 16.0842 20.1111 14.5789 20.6667 14" stroke="#002140" strokeWidth="1.75" strokeLinecap="round"/>
      <path d="M29 32.3333C29 26.7368 29 20.3298 29 18.9404C29 17.5509 30.1111 14.5789 30.6667 14" stroke="#002140" strokeWidth="1.75" strokeLinecap="round"/>
      <line x1="29" y1="24.875" x2="19" y2="24.875" stroke="#002140" strokeWidth="1.75"/>
      <line x1="29" y1="19.875" x2="19" y2="19.875" stroke="#002140" strokeWidth="1.75"/>
      <path d="M14 30.6667C17.125 30.6667 16.5 32.3334 19 32.3334C21.5 32.3334 21.5 30.6667 24 30.6667C26.5 30.6667 26.5 32.3334 29 32.3334C31.5 32.3334 32.125 30.6667 34 30.6667" stroke="#002140" strokeWidth="1.75" strokeLinecap="round"/>
    </SvgIcon>
  );
};

export default PoolSwimIcon;