export const matchUserActivityPage = (path) => {
  const results = path.match(/^\/activity\/(.*)/);
  if (!results) return null;
  return {
    page: "activity",
    activityId: results[1],
  };
};

export const matchUserProgressPage = (path) => {
  const results = path.match(/^\/progress/);
  if (!results) return null;
  return {
    page: "progress",
  };
};

export const matchCoachingActivityPage = (path) => {
  const results = path.match(/^\/coaching\/swimmers\/(.*)\/swims\/(.*)/);
  if (!results) return null;
  return {
    page: "activity",
    swimmerId: results[1],
    activityId: results[2],
  };
};

export const matchCoachingProgressPage = (path) => {
  const results = path.match(/^\/coaching\/swimmers\/(.*)\/progress/);
  if (!results) return null;
  return {
    page: "progress",
    swimmerId: results[1],
  };
};

export const matchCoachingView = (path) => {
  const results = path.match(/^\/coaching\/swimmers\/(.*?)\//);
  if (!results) return null;
  return {
    swimmerId: results[1],
  };
};
