import { useState, useEffect } from "react";
// import PropTypes from 'prop-types';

import { TextField } from "@mui/material";

const Input = ({
  type = "text",
  label,
  name,
  onUpdate,
  value,
  updateWithName,
  rows = 4,
  disabled = false,
  helperText,
  error,
  wrapperStyle = {},
  inputStyle = {},
  placeholder = "",
  size = "small",
}) => {
  const [state, setState] = useState(value);

  useEffect(() => {
    // if (type ==='text' || type === 'textarea' || type === 'number') {
    //   const value = type === 'number' ? Number(state) : state
    //   onUpdate( !!updateWithName && !!name ? {[name]: value} : value )
    // }
    if (state !== value) {
      const value = type === "number" ? Number(state) : state;
      onUpdate(!!updateWithName && !!name ? { [name]: value } : value);
    }
  }, [state]);

  useEffect(() => {
    if (value !== state || value !== Number(state)) {
      setState(value);
    }
  }, [value]);

  const handleChange = (event) => {
    const val = event.target.value;

    if (type === "text" || type === "textarea" || type === "number") {
      setState(val);
    }
  };

  return (
    <div className="input-wrapper" style={wrapperStyle}>
      {label && <label>{label}</label>}
      {type === "textarea" ? (
        <textarea
          type={type}
          name={name}
          onChange={handleChange}
          value={state}
          rows={rows}
          disabled={disabled}
          className={`${disabled ? "input-disabled" : ""} ${
            !!error ? "input-error" : ""
          }`}
          style={inputStyle}
          placeholder={placeholder}
        />
      ) : (
        // <input
        //   type={type}
        //   name={name}
        //   onChange={handleChange}
        //   value={state}
        //   disabled={disabled}
        //   className={`${disabled ? 'input-disabled' : ''} ${!!error ? 'input-error' : ''}`}
        //   style={inputStyle}
        //   placeholder={placeholder}
        // />
        <TextField
          type={type}
          id={name}
          label=""
          variant="outlined"
          size={size}
          value={state}
          onChange={handleChange}
          disabled={disabled}
          placeholder={placeholder}
          error={!!error}
          helperText={error || helperText}
        />
      )}
      {!!error && <p className="input-error-text">{error}</p>}
      {!!helperText && !error && (
        <p className="input-helper-text">{helperText}</p>
      )}
    </div>
  );
};

// Input.propTypes = {
//   : PropTypes.
// };

export default Input;
