import { useContext } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useMutation } from "react-query";
import { UserContext } from "context";
import api from "api";
import LoadingButton from "@mui/lab/LoadingButton";
import { useQueryParams } from "hooks";
import { Link } from "react-router-dom";

const SubscribeButton = ({
	coachId,
	text = "Subscribe to workouts",
	priceId,
	productType,
}) => {
	const { user } = useContext(UserContext);
	const { purchased } = useQueryParams();

	const subscription = user.currentSubscriptions.find(
		(sub) => sub.type === productType && sub.contentId === coachId
	);

	const subscriptionStatus =
		(purchased === `${productType}_${coachId}` && !subscription) ||
		(!!subscription && !subscription.isActive)
			? "pending"
			: !!subscription?.isActive
			? "active"
			: "unsubscribed";

	const { mutate, isLoading } = useMutation(api.stripe.createCheckoutSession, {
		onSuccess: (data) => {
			window.location.replace(data.url);
		},
	});

	const subscribe = () => {
		mutate({
			priceId,
			coachId,
			success_url:
				window.location.href + `?purchased=${productType}_${coachId}`,
			cancel_url: window.location.href,
		});
	};

	return (
		<Box>
			{subscriptionStatus === "active" ? (
				<Button variant="outlined" component={Link} to="/profile">
					Manage Subscription
				</Button>
			) : (
				<LoadingButton
					variant="contained"
					onClick={subscribe}
					disabled={subscriptionStatus === "pending"}
					loading={isLoading}
					size="large"
				>
					{subscriptionStatus === "pending" ? "Subscription Pending" : text}
				</LoadingButton>
			)}

			{subscriptionStatus === "pending" && (
				<Typography
					sx={{ fontSize: "0.8rem", opacity: 0.6, textAlign: "right" }}
				>
					Try refreshing the page
				</Typography>
			)}
		</Box>
	);
};
export default SubscribeButton;
