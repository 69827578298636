import {useHistory} from "react-router-dom";
import {useQuery} from "react-query";
// @mui
import {Box, Stack} from "@mui/material";
// components
import {SkeletonWorkoutItem} from "../../components/skeleton";
import WorkoutCard from "../../components/workout/WorkoutCard";
import ProductBanner from "../../components/coach/ProductBanner";
import api from "../../api";



const CoachWorkouts = ({coachId, product}) => {
  const history = useHistory();

  const { data, isLoading } = useQuery(
    ["workouts", coachId],
    () => api.workouts.list(coachId)
  );

  const isNothingFound = !isLoading && !data.length;

  const handleOnClick = (id) => {
    history.push(`/workout/${id}`);
  }


  return (
    <Stack gap={1}>
      {product && (
        <ProductBanner
          title="Subscribe to my workouts"
          product={product}
          coachId={coachId}
          productType="SIMMA-WORKOUTS-SUB"
        />
      )}
      <Box gap={2} display="grid" gridTemplateColumns={{ md: 'repeat(2, 1fr)' }}>
        {(isLoading && !isNothingFound ? [...Array(6)] : data).map((item, index) =>
          item ? (
            <WorkoutCard key={item.id} data={item} lapUnit={'m'} onClick={() => handleOnClick(item.id)} />
          ) : (
            <SkeletonWorkoutItem key={index} />
          )
        )}
      </Box>
    </Stack>
  );
};

export default CoachWorkouts;
