import { coachApi } from '../axios';

export const get = async () => {
  const { data } = await coachApi.get('coaching/profile');
  return data;
};

export const update = async (body) => {
  const { data } = await coachApi.patch('coaching/profile', body);
  return data;
};

export const assetUploadUrl = async (fileName, mimeType, type) => {
  const { data } = await coachApi.post('coaching/asset-upload-url', {
    fileName,
    mimeType,
    type
  });
  return data;
};

export const getTiles = async () => {
  const { data } = await coachApi.get('coaching/tiles');
  return data;
};
