import { adminApi } from "../axios";

export const list = async () => {
	const { data } = await adminApi.get(`admin/errors`);
	return data;
};

export const remove = async (id) => {
	const { data } = await adminApi.delete(`admin/errors/${id}`);
	return data;
};

export const reprocess = async (errorIds) => {
	const { data } = await adminApi.post(`admin/errors/reprocess`, { errorIds });
	return data;
};
