import React, {useCallback, useContext, useState} from "react";

import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography
} from "@mui/material";
import {VictoryArea, VictoryAxis, VictoryChart, VictoryLine} from "victory";
import {format} from 'date-fns'

import {theme} from "./theme";
import {useResponsive} from "../../hooks";
import {UserContext} from "../../context";


const colors = ["#FF8F00", "#558B2F", "#1565C0"]
const options = ['Current Month', '3 Month Average']

const dataTypes = [{
  type: 'normal', color: "#FF5082", label: 'Current Month'
}, {
  type: 'dashed', color: "#8F54A2", label: '3 Month Avg.', value: '3 Month Average'
},]

const ProgressDistance = ({distanceComparison}) => {
  const {user} = useContext(UserContext);
  const system = user.preferredUnits || "metric"

  const isMetric = system === "metric"
  const [displayedValues, setDisplayedValues] = useState(options)
  const [boundingRect, setBoundingRect] = useState({width: 0, height: 0});
  const isMobile = useResponsive('down', 'md');
  const graphRef = useCallback((node) => {
    if (node !== null) {
      setBoundingRect(node.getBoundingClientRect());
    }
  }, []);

  const currentMonthData = distanceComparison.filter(({current}) => current !== undefined).map(({current}, index) => ({
    x: index + 1, y: current / 1000
  }))

  const averageData = distanceComparison.map(({average}, index) => ({
    x: index + 1, y: average / 1000
  }))

  const threeMonthData = distanceComparison.map(({average, current, ...rest}) => ({
    ...rest
  })).map(i => Object.entries(i).map(arr => {
    const dataNumbers = String(arr[0]).split('_').map((i, index) => index === 1 ? +i - 1 : +i)
    const formattedData = format(new Date(...dataNumbers), 'MMMM, yyyy')
    return [formattedData, arr[1]]
  }))

  const formattedDate = threeMonthData[0]?.map(i => i[0]) || [];

  const resolveInputLabels = (value) => {
    switch (value) {
      case 'Current Month':
        return 'Current';
      case '3 Month Average':
        return 'Average';
      default:
        return value.split(', ')[0];
    }
  }

  const handleChangeDisplayedValues = (event) => {
    const {target: {value}} = event;
    setDisplayedValues(typeof value === 'string' ? value.split(',') : value,);
  }

  return (
    <Box sx={{position: 'relative'}}>
      <FormControl sx={{
        maxWidth: '460px', minWidth: '250px', ...(!isMobile && {
          position: 'absolute',
          top: '-70px',
          right: '0'
        })
      }}>
        <InputLabel id="select-distance-values-label">Activity Type</InputLabel>
        <Select
          labelId="select-distance-values-label"
          id="select-distance-values"
          value={displayedValues}
          label="Chart controls"
          onChange={handleChangeDisplayedValues}
          multiple
          variant={"standard"}
          size="small"
          renderValue={(selected) => selected.map(resolveInputLabels).join(', ')}
        >
          {[...options, ...formattedDate].map((option) => (<MenuItem key={option} value={option}>
            <Checkbox checked={displayedValues.indexOf(option) > -1}/>
            <ListItemText primary={option}/>
          </MenuItem>))}
        </Select>
      </FormControl>
      <Box sx={{width: '100%', height: `460px`}} ref={graphRef}>
        <VictoryChart
          theme={theme}
          height={460}
          width={boundingRect.width - 5}
          style={{
            background: {
              fill: "rgba(51, 51, 51, 1)"
            },
          }}
        >
          {displayedValues.includes('Current Month') && !!currentMonthData.length && <VictoryArea
            data={currentMonthData}
            style={{data: {fill: "rgba(255, 80, 130)", opacity: 0.3}}}
          />}
          {displayedValues.includes('Current Month') && !!currentMonthData.length && <VictoryLine
            data={currentMonthData}
            style={{data: {stroke: '#FF5082', strokeWidth: 2}}}
          />}
          {displayedValues.includes('3 Month Average') && !!averageData.length && <VictoryLine
            data={averageData}
            style={{data: {stroke: '#8F54A2', strokeWidth: 2, strokeDasharray: '5,5',}}}
          />}
          {!!threeMonthData[0]?.length && colors.map((color, index) => {
            if (displayedValues.includes(formattedDate[index])) {
              return (<VictoryLine
                key={color}
                data={threeMonthData.filter(d => !!d[index]).map((d, i) => ({
                  x: i + 1, y: d[index][1] / 1000
                }))}
                style={{data: {stroke: color, strokeWidth: 2, opacity: 0.5}}}
              />)
            } else {
              return null
            }
          })}
          <VictoryAxis
            dependentAxis
            tickCount={6}
            tickFormat={(x) => {
              return `${x}${isMetric ? 'km' : 'mi'}`
            }
            }
            style={{
              grid: {strokeWidth: 0.5, opacity: 1}, ticks: {strokeWidth: 0},
            }}
          />
          <VictoryAxis
            tickCount={6}
            style={{
              grid: {strokeWidth: 0, opacity: 0.3}, ticks: {strokeWidth: 0},
            }}
          />
        </VictoryChart>
      </Box>
      <Grid container sx={{gap: '22px'}} justifyContent={'space-between'}>
        {[...dataTypes, ...formattedDate.map((d, i) => ({
          type: 'normal', color: colors[i], label: d
        }))].map((data => {
          return (
            <>
              {displayedValues.includes(data?.value || data.label) &&
                <Grid item key={data.color} sx={{display: 'flex'}}>
                  <Stack direction={'row'} alignItems={'center'} gap={'10px'}>
                    <Typography variant={'body1'}>
                      {data.label}
                    </Typography>
                    {data.type === 'dashed' ?
                      <svg width="41" height="6" viewBox="0 0 41 6" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <line x1="2.605" y1="2.90867" x2="38.395" y2="2.90867" stroke="#8F54A2"
                              strokeWidth="4.21" strokeLinecap="round"
                              strokeDasharray="8.42 8.42"/>
                      </svg> : <Box sx={{
                        width: '41px',
                        height: '4px',
                        backgroundColor: data.color,
                        borderRadius: '100px',
                        overflow: 'hidden'
                      }}/>}
                  </Stack>
                </Grid>}
            </>

          )
        }))}

      </Grid>
    </Box>);
};

export default ProgressDistance;
