import { userApi } from "./axios";

export const getActivityForChat = async (id) => {
  const { data } = await userApi.get(`activities/${id}/chat`);
  return data;
};

export const list = async (nextPage) => {
  const path = `activities${nextPage ? `?page=${nextPage}` : ""}`;
  const { data } = await userApi.get(path);
  return data;
};

export const getActivityList= async (params) => {
  const { data } = await userApi.get('activities?newActivityFormat', {params});
  return data;
};

export const get = async (id, swimmerId) => {
  let path = `activities/${id}`;
  if (swimmerId) {
    path += `?swimmerId=${swimmerId}`;
  }
  const { data } = await userApi.get(path);
  return data;
};

export const update = async ({ id, data }) => {
  return userApi.patch(`activities/${id}`, data);
};
