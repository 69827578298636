// import PropTypes from 'prop-types';
import { secondsToHMS } from '../../util'

const CustomToolTip = ({ payload, label, active }) => {
  if (active) {
    return (
      <div className="custom-tooltip">
        {payload?.map(metric => {
          return (
            <div key={metric.dataKey} className="tooltip-label" style={{color: metric.color}}>
              <div style={{paddingRight: '.8rem'}}>{metric.dataKey}:</div>
              <div>{parseValue(metric.dataKey, metric.value)}</div>
            </div>
          )
        })}

      </div>
    );
  }

  return null;
}

// CustomToolTip.propTypes = {
//   : PropTypes.
// };

export default CustomToolTip;

// <p className="intro">{getIntroOfPage(label)}</p>
// <p className="desc">Anything you want can be displayed here.</p>

// <p className="tooltip-label" style={{color: metric.color}}>{`${metric.dataKey} : ${Math.round(metric.value * 100) / 100}`}</p>

const parseValue = (dataKey, value) => {
  let display = Math.round(value * 100) / 100
  if (dataKey === 'Pace') {
    return secondsToHMS(display)
  }

  return display
}
