import React from 'react';
// import PropTypes from 'prop-types';

const Open = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#1ADEA3"/>
      <g clipPath="url(#clip0_415_637)">
      <path d="M21.86 16.69C21.2226 16.6868 20.5986 16.8728 20.067 17.2245C19.5354 17.5762 19.1201 18.0778 18.8738 18.6657C18.6275 19.2536 18.5612 19.9014 18.6834 20.527C18.8055 21.1526 19.1106 21.7279 19.56 22.18C19.9394 22.1714 20.3105 22.0683 20.64 21.88L21.47 21.41C21.8345 21.1984 22.2485 21.0869 22.67 21.0869C23.0915 21.0869 23.5055 21.1984 23.87 21.41L24.5 21.77C24.8416 21.2872 25.0437 20.7197 25.0841 20.1296C25.1244 19.5396 25.0015 18.9499 24.7288 18.4251C24.4561 17.9003 24.044 17.4608 23.538 17.1547C23.0319 16.8487 22.4514 16.6879 21.86 16.69Z" fill="#0A1D26"/>
      <path d="M11.32 13.6C11.05 13.75 10.32 14.21 10.07 14.37L6.73001 16.37C6.43804 16.5581 6.22642 16.8481 6.13626 17.1835C6.0461 17.5189 6.08384 17.8759 6.24216 18.185C6.40047 18.4942 6.66803 18.7335 6.99289 18.8563C7.31775 18.9792 7.67668 18.977 7.99997 18.85L12.19 17.09C12.34 17.57 12.5 18.09 12.65 18.55C9.93067 19.2063 7.34342 20.3224 4.99997 21.85C5.25211 21.8684 5.49752 21.94 5.72 22.06C5.96602 22.1975 6.24316 22.2697 6.52499 22.2697C6.80683 22.2697 7.08397 22.1975 7.32999 22.06L8.55002 21.36C8.91453 21.1484 9.32848 21.0369 9.74997 21.0369C10.1715 21.0369 10.5855 21.1484 10.95 21.36L11.78 21.83C12.1445 22.0416 12.5585 22.1531 12.98 22.1531C13.4015 22.1531 13.8155 22.0416 14.18 21.83L15.01 21.36C15.3745 21.1484 15.7885 21.0369 16.21 21.0369C16.6315 21.0369 17.0455 21.1484 17.41 21.36L18.24 21.83C17.666 19.18 16.652 16.6449 15.24 14.33C14.8024 13.7322 14.1531 13.3238 13.4247 13.1881C12.6962 13.0525 11.9435 13.1998 11.32 13.6Z" fill="#0A1D26"/>
      <path d="M22.67 12.6299C22.67 12.1434 22.5257 11.6678 22.2554 11.2632C21.9851 10.8587 21.6009 10.5434 21.1514 10.3572C20.7019 10.171 20.2072 10.1223 19.73 10.2172C19.2529 10.3121 18.8145 10.5464 18.4705 10.8904C18.1264 11.2345 17.8922 11.6728 17.7972 12.15C17.7023 12.6272 17.751 13.1218 17.9372 13.5713C18.1234 14.0208 18.4387 14.405 18.8433 14.6754C19.2478 14.9457 19.7234 15.0899 20.21 15.0899C20.8624 15.0899 21.4881 14.8308 21.9495 14.3694C22.4108 13.9081 22.67 13.2824 22.67 12.6299Z" fill="#0A1D26"/>
      <path d="M15.95 11.59C16.0917 11.7289 16.2816 11.8077 16.48 11.81C16.5798 11.8103 16.6785 11.7899 16.77 11.75C16.8592 11.7121 16.9406 11.6578 17.01 11.59C17.1504 11.4494 17.2293 11.2588 17.2293 11.06C17.2293 10.8613 17.1504 10.6707 17.01 10.53C16.946 10.4559 16.863 10.4006 16.77 10.37C16.6801 10.3306 16.5831 10.3103 16.485 10.3103C16.3869 10.3103 16.2898 10.3306 16.2 10.37C16.1026 10.3974 16.0156 10.4531 15.95 10.53C15.8822 10.5994 15.828 10.6808 15.79 10.77C15.7504 10.8616 15.7299 10.9603 15.7299 11.06C15.7299 11.1598 15.7504 11.2585 15.79 11.35C15.8302 11.438 15.8842 11.519 15.95 11.59Z" fill="#0A1D26"/>
      <path d="M21.14 9.48009C21.213 9.48985 21.287 9.48985 21.36 9.48009C21.497 9.47773 21.6306 9.43794 21.7465 9.36501C21.8624 9.29207 21.9561 9.18878 22.0175 9.06636C22.0789 8.94394 22.1056 8.80706 22.0947 8.67055C22.0838 8.53404 22.0358 8.40311 21.9557 8.29198C21.8757 8.18084 21.7668 8.09374 21.6408 8.04012C21.5148 7.98649 21.3765 7.96839 21.241 7.98778C21.1054 8.00716 20.9777 8.06328 20.8718 8.15007C20.7658 8.23686 20.6857 8.35098 20.64 8.48009C20.6011 8.57818 20.5834 8.68341 20.5879 8.78883C20.5925 8.89426 20.6192 8.99752 20.6664 9.0919C20.7136 9.18629 20.7802 9.26964 20.8618 9.33653C20.9434 9.40343 21.0382 9.45234 21.14 9.48009Z" fill="#0A1D26"/>
      <path d="M23.65 11.26C23.789 11.259 23.9246 11.2173 24.04 11.14C24.1843 11.0443 24.2892 10.8999 24.3356 10.7331C24.382 10.5663 24.3668 10.3885 24.2927 10.232C24.2186 10.0756 24.0907 9.95101 23.9323 9.88121C23.7739 9.8114 23.5957 9.801 23.4302 9.85191C23.2648 9.90281 23.1232 10.0116 23.0314 10.1584C22.9397 10.3052 22.9039 10.4801 22.9306 10.6511C22.9573 10.8222 23.0447 10.9778 23.1768 11.0897C23.3089 11.2015 23.4769 11.262 23.65 11.26Z" fill="#0A1D26"/>
      <path d="M18.53 9.73002C18.6401 9.72692 18.7486 9.7032 18.85 9.66005C19.027 9.57156 19.1631 9.4183 19.2302 9.2321C19.2972 9.0459 19.29 8.84106 19.21 8.66005C19.1679 8.57075 19.1088 8.4906 19.0358 8.42418C18.9628 8.35776 18.8774 8.30636 18.7846 8.27293C18.6917 8.2395 18.5932 8.2247 18.4946 8.22935C18.396 8.234 18.2993 8.25801 18.21 8.30003C18.1207 8.34205 18.0406 8.40126 17.9741 8.47425C17.9077 8.54725 17.8563 8.63262 17.8229 8.72548C17.7895 8.81833 17.7747 8.91687 17.7793 9.01545C17.784 9.11404 17.808 9.21073 17.85 9.30003C17.9117 9.42815 18.0082 9.53639 18.1284 9.61241C18.2486 9.68842 18.3878 9.72916 18.53 9.73002Z" fill="#0A1D26"/>
      <path d="M23.9 12.67C23.8087 12.7041 23.7266 12.7589 23.66 12.83C23.587 12.8971 23.5291 12.9789 23.49 13.0701C23.4533 13.1622 23.4362 13.2609 23.44 13.36C23.4395 13.4586 23.4588 13.5562 23.4966 13.6472C23.5344 13.7383 23.5899 13.8208 23.66 13.89C23.8005 14.0307 23.9912 14.1099 24.19 14.11C24.2866 14.1105 24.3821 14.09 24.47 14.05C24.564 14.0155 24.6494 13.961 24.7202 13.8902C24.791 13.8194 24.8455 13.734 24.88 13.64C24.9199 13.5521 24.9404 13.4566 24.94 13.36C24.9403 13.2603 24.9199 13.1615 24.88 13.0701C24.8447 12.9794 24.7902 12.8975 24.72 12.83C24.6514 12.7566 24.5654 12.7016 24.47 12.67C24.3802 12.6307 24.2832 12.6104 24.1851 12.6104C24.0869 12.6104 23.9899 12.6307 23.9 12.67Z" fill="#0A1D26"/>
      </g>
      <defs>
      <clipPath id="clip0_415_637">
      <rect width="20.09" height="14.32" fill="white" transform="translate(5 8)"/>
      </clipPath>
      </defs>
    </svg>

  );
}

// Open.propTypes = {
//   : PropTypes.
// };

export default Open;
