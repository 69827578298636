import { useState, useRef, useLayoutEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import api from 'api';
import Spinner from 'components/common/Spinner';

import { WorkoutInfo } from 'pages/WorkoutDetails/WorkoutDetailsPage';
import WorkoutSet from 'components/workout/WorkoutSet';

const paddingAmount = '2rem';

const WorkoutFullScreen = () => {
  const { workoutId } = useParams();
  const { isLoading, data } = useQuery(
    ['coachWorkout', workoutId],
    () => api.coaching.workouts.get(workoutId),
    {
      enabled: !!workoutId,
    }
  );

  const [columnCount, setColumnCount] = useState(2);

  const containerRef = useRef(null);

  useLayoutEffect(() => {
    const determineColumnCount = () => {
      if (containerRef.current) {
        const contentHeight = containerRef.current.scrollHeight;
        const screenHeight = window.innerHeight;

        let newColumnCount = Math.ceil(contentHeight / screenHeight);
        console.log('newColumnCount', newColumnCount);
        newColumnCount = Math.max(newColumnCount, 2);

        if (newColumnCount !== columnCount) {
          setColumnCount(newColumnCount);
        }
      }
    };

    determineColumnCount();
    window.addEventListener('resize', determineColumnCount);

    return () => window.removeEventListener('resize', determineColumnCount);
  }, [data, data?.sets, columnCount]);

  if (isLoading) {
    return <Spinner window />;
  }

  return (
    <div
      ref={containerRef}
      style={{
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        padding: paddingAmount,
      }}
    >
      <div
        style={{
          columnCount: columnCount,
          columnFill: 'auto',
          columnGap: paddingAmount,
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <WorkoutInfo {...data} />
        <div style={{ height: paddingAmount }} />
        {data.sets?.map((set) => (
          <div
            key={set.id}
            style={{ breakInside: 'avoid', marginBottom: paddingAmount }}
          >
            <WorkoutSet set={set} />
          </div>
        ))}
      </div>
    </div>
  );
};
export default WorkoutFullScreen;
