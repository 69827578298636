import {coachApi} from "../axios";

export const list = async (params) => {
  const {data} = await coachApi.get("/coaching/workout-sets", {params});
  return data;
};

export const get = async (id) => {
  const {data} = await coachApi.get(`/coaching/workout-sets/${id}`);
  return data;
};

export const create = async (body) => {
  const {data} = await coachApi.post(`/coaching/workout-sets`, body);
  return data;
};

export const update = async (id, body) => {
  const {data} = await coachApi.patch(`/coaching/workout-sets/${id}`, body);
  return data;
};

export const remove = async (id) => {
  const {data} = await coachApi.delete(`/coaching/workout-sets/${id}`);
  return data;
};
