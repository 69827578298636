import SvgIcon from "@mui/material/SvgIcon";
const TechtocIcon = (props) => {
	return (
		<SvgIcon fill="#EBEBF5" fillOpacity="0.6" {...props}>
			<path d="M6,9C6,8.448 6.448,8 7,8L17,8C17.552,8 18,8.448 18,9C18,9.552 17.552,10 17,10L16,10C15,10 14,11 14,12L14,16L10,16L10,12C10,11 9,10 8,10L7,10C6.448,10 6,9.552 6,9ZM15,16L21,16C22.105,16 23,15.105 23,14C23,12.895 22.105,12 21,12L15,12L15,16ZM1,14C1,12.895 1.895,12 3,12L9,12L9,16L3,16C1.895,16 1,15.105 1,14Z" />
		</SvgIcon>
	);
};

export default TechtocIcon;
