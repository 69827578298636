import { adminApi } from "../axios";

export const list = async () => {
  const { data } = await adminApi.get(`coupons`);
  return data;
};

export const create = async (body) => {
  const { data } = await adminApi.post(`coupons`, body);
  return data;
};

export const remove = async (id) => {
  const { data } = await adminApi.delete(`coupons/${id}`);
  return data;
};
