// @mui
import {Skeleton, Grid} from '@mui/material';

// ----------------------------------------------------------------------

const SkeletonCoachItem = () => {
  return (
    <Grid item xs={12} sm={6} md={3}>
      <Skeleton variant="rectangular" width="100%" sx={{height: 210, borderRadius: 3}}/>
    </Grid>
  );
}

export default SkeletonCoachItem;