import * as React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
// @mui
import { Divider, Stack, MenuItem, IconButton } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Avatar from "@mui/material/Avatar";
// components
import { useAuthContext } from "../../../auth/useAuthContext";
import MenuPopover from "../../../components/menu-popover";

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: "Profile",
    linkTo: "/profile",
  },
];

const ADMIN_OPTIONS = [
  {
    label: "Admin",
    linkTo: "/admin",
  },
];

const COACH_OPTIONS = [
  {
    label: "Coaching",
    linkTo: "/coaching/swimmers",
  },
  {
    label: "Training",
    linkTo: "/progress",
  },
];

// ----------------------------------------------------------------------

const AccountPopover = ({ user }) => {
  const history = useHistory();

  const { logout } = useAuthContext();

  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    await logout();
    history.replace("/");
    handleClosePopover();
  };

  const handleClickItem = (path) => {
    handleClosePopover();
    history.push(path);
  };

  return (
    <>
      <IconButton
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
            },
          }),
        }}
      >
        <Avatar
          alt={user?.firstName || user?.username}
          sx={{ height: 28, width: 28, background: "none" }}
        >
          <AccountCircleIcon color="primary" sx={{ fontSize: 28 }} />
        </Avatar>
      </IconButton>

      <MenuPopover
        disabledArrow
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 200, p: 0 }}
      >
        <Stack sx={{ py: 1 }}>
          {[
            ...OPTIONS,
            ...(!!user?.isCoach ? COACH_OPTIONS : []),
            ...(!!user?.isAdmin ? ADMIN_OPTIONS : []),
          ].map((option) => (
            <MenuItem
              key={option.label}
              onClick={() => handleClickItem(option.linkTo)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider />

        <MenuItem onClick={handleLogout} sx={{ my: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
};

export default AccountPopover;
