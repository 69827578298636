import {useFormContext, useWatch} from "react-hook-form";
// @mui
import {Box} from "@mui/material";
// components
import {RHFRadioGroup, RHFSwitch} from "../../../../../components/hook-form";
import {createSegment, createSplit} from "../../../../../components/WorkoutCreator/utils";
import {SPLIT_SEGMENT_OPTIONS} from "../utils";

// ----------------------------------------------------------------------

const SplitSegmentFields = ({baseName}) => {
  const {setValue, getValues} = useFormContext();

  const setBaseName = baseName.split('segments')[0] || '';

  const [isSplit, segmentCount] = useWatch({
    name: [baseName + 'isSplit', setBaseName + 'segmentCount']
  });

  const handleOnSwitchSplit = (isSplit) => {
    if (isSplit) {
      const [distance, stroke, effort, isDrill, repeat, targetTime] = getValues([
        baseName + 'distance',
        baseName + 'stroke',
        baseName + 'effort',
        baseName + 'isDrill',
        baseName + 'repeat',
        baseName + 'targetTime'
      ]);
      // Set segment stroke to drill type if drill because segment stroke is now aggregate of splits
      if (isDrill) {
        setValue(baseName + 'stroke', "drill");
      }
      // Set segment repeat to 1 because repeat is handled at set level if split
      if (repeat > 1) {
        setValue(baseName + 'repeat', 1);
      }
      // Create split defaults from segment values
      const defaults = {
        distance: distance / 2,
        stroke,
        effort,
        isDrill,
        targetTime: Math.round(targetTime / 2),
      };
      // Create the minimum 2 splits from defaults
      setValue(baseName + 'splits', [createSplit(defaults), createSplit(defaults)]);
      setValue(baseName + 'splitCount', 2);
    }

    if (!isSplit) {
      const [splits, stroke] = getValues([baseName + 'splits', baseName + 'stroke']);
      // If segment value is drill or mixed, reset segment values to non aggregate ones based on first split
      if (["drill", "mixed"].includes(stroke)) {
        setValue(baseName + 'isDrill', splits[0].isDrill);
        setValue(baseName + 'stroke', splits[0].stroke);
        // If first split is drill, set segment to easy and update targetTime
        if (splits[0].isDrill) {
          setValue(baseName + 'effort', "a");
        }
      }
      // Remove all splits
      setValue(baseName + 'splits', []);
      setValue(baseName + 'splitCount', 0);
    }
  }

  const handleOnChangeSplitCount = (e) => {
    const splitCount = Number(e.target.value);
    const splits = getValues(baseName + 'splits');
    setValue(baseName + 'splitCount', splitCount);
    const newSplitsValue = [...Array(splitCount)].map((_, i) => splits[i] || createSegment());
    setValue(baseName + 'splits', newSplitsValue);
  }

  if (segmentCount > 1) {
    return null;
  }

  return (
    <Box display="flex" flexDirection={{xs: 'column', sm: 'row'}} gap={2}>
      <RHFSwitch
        sx={{pl: 1, mr: 0}}
        onChange={handleOnSwitchSplit}
        name={baseName + 'isSplit'}
        label="Split Segment"
        size="small"
      />
      {isSplit && (
        <RHFRadioGroup
          name={baseName + 'splitCount'}
          options={SPLIT_SEGMENT_OPTIONS}
          onChange={handleOnChangeSplitCount}
        />
      )}
    </Box>
  );
}

export default SplitSegmentFields;