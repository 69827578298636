import { Box } from "@mui/material";

import Crossover from "./modals/Crossover/index";
import HandEntry from "./modals/HandEntry/index";
import PullForce from "./modals/PullForce/index";
import StrokePeriod from "./modals/StrokePeriod/index";
import HandEntrySplash from "./modals/HandEntrySplash/index";
import StrokeCount from "./modals/StrokeCount/index";

const ACTIVITY_TYPES = {
	CROSSOVER: 'crossoverAngle',
	HAND_ENTRY: 'entryAngle',
	PULL_FORCE: 'forceScore',
	STROKE_PERIOD: 'strokePeriod',
	HAND_ENTRY_SPLASH: 'splashScore',
}

const MODAL_VARIANTS = {
	STROKE_COUNT: 'stroke count',
	STROKE_PERIOD: 'stroke period',
	HAND_ENTRY_SPLASH: 'hand entry splash',
	PULL_FORCE: 'pull force',
	CROSSOVER: 'crossover',
	HAND_ENTRY: 'hand entry',
}

const ActivityMotionModal = ({ activity, modalVariant, isMobile }) => {

	const features = activity.analytics.sensorFeatures
	const data = features.iOSData
	const hand = activity.analytics.sensorFeatures.annotations.metadata.wristLocation

	const renderContent = (modalVariant) => {
		switch (modalVariant) {
			case MODAL_VARIANTS.CROSSOVER:
				return <Crossover
					crossover={ ACTIVITY_TYPES.CROSSOVER }
					data={ data }
					features={ features }
					hand={ hand }
					isMobile={ isMobile }
				/>
			case MODAL_VARIANTS.HAND_ENTRY:
				return <HandEntry
					handEntry={ ACTIVITY_TYPES.HAND_ENTRY }
					data={ data }
					features={ features }
					hand={ hand }
					isMobile={ isMobile }
				/>
			case MODAL_VARIANTS.STROKE_COUNT:
				return <StrokeCount
					data={ data }
					features={ features }
					hand={ hand }
					isMobile={ isMobile }
				/>
			case MODAL_VARIANTS.PULL_FORCE:
				return <PullForce
					pullForce={ ACTIVITY_TYPES.PULL_FORCE }
					data={ data }
					features={ features }
					hand={ hand }
					isMobile={ isMobile }
				/>
			case MODAL_VARIANTS.STROKE_PERIOD:
				return <StrokePeriod
					strokePeriod={ ACTIVITY_TYPES.STROKE_PERIOD }
					data={ data }
					features={ features }
					hand={ hand }
					isMobile={ isMobile }
				/>
			case MODAL_VARIANTS.HAND_ENTRY_SPLASH:
				return <HandEntrySplash
					handEntrySplash={ ACTIVITY_TYPES.HAND_ENTRY_SPLASH }
					data={ data }
					features={ features }
					hand={ hand }
					isMobile={ isMobile }
				/>
		}
	}

	return (
		<Box sx={ {
			padding: isMobile ? '10px' : '40px', paddingBottom: isMobile ? '10px' : '24px'
		} }>
			{ renderContent(modalVariant) }
		</Box>

	);
}

export default ActivityMotionModal;

