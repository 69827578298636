import SvgIcon from '@mui/material/SvgIcon';
const BatonIcon = props => {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M14.1392 6.63558C13.5417 6.87455 12.785 7 12 7C11.215 7 10.4583 6.87455 9.86084 6.63558C9.7408 6.58756 9.619 6.53172 9.5 6.46657V8C9.5 8.55228 10.6193 9 12 9C13.3807 9 14.5 8.55228 14.5 8V6.46657C14.381 6.53172 14.2592 6.58756 14.1392 6.63558ZM12 4C10.6193 4 9.5 4.44772 9.5 5C9.5 5.55228 10.6193 6 12 6C13.3807 6 14.5 5.55228 14.5 5C14.5 4.44772 13.3807 4 12 4ZM10 20V9.68817C10.5736 9.89291 11.2748 9.99999 12 9.99999C12.7252 9.99999 13.4264 9.89291 14 9.68817V20C14 20.5523 13.1046 21 12 21C10.8954 21 10 20.5523 10 20Z" fill="#EBEBF5" fillOpacity="0.6"/>
    </SvgIcon>
  );
}

export default BatonIcon;
