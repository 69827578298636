import SvgIcon from '@mui/material/SvgIcon';

const StrideIcon = props => {
	return (
		<svg width="32" height="15" viewBox="0 0 32 15" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M32.2 3.0001C32.2 1.3802 31.1881 9.72748e-05 29.9 9.72748e-05L2.3 9.72748e-05C1.05808 9.72748e-05 0 1.32 0 3.0001L0 12.6001C0 14.22 1.01192 15.6001 2.3 15.6001L29.9 15.6001C31.1419 15.6001 32.2 14.2802 32.2 12.6001V3.0001ZM29.9 13.2001H28.06V9.0001H26.22V13.2001H24.84V9.0001H23V13.2001H21.16V6.0001H19.32V13.2001L17.48 13.2001V9.0001H15.64V13.2001H14.26V9.0001H12.42V13.2001H10.58V6.0001H8.74V13.2001H6.9V9.0001H5.06V13.2001H2.3C2.07 13.2001 1.84 12.9601 1.84 12.6001L1.84 3.0001C1.84 2.7001 2.02403 2.4001 2.3 2.4001L29.9 2.4001C30.13 2.4001 30.36 2.64014 30.36 3.0001V12.6001C30.36 12.9601 30.176 13.2001 29.9 13.2001Z"
				fill="#FF59B9"/>
		</svg>
	);
}

export default StrideIcon;
