import {useEffect, useState} from "react";
import {useFormContext, useWatch} from "react-hook-form";
import {useQuery, useQueryClient} from "react-query";
import Image from "@jy95/material-ui-image";
// @mui
import {
  Box,
  Typography,
  Checkbox,
  ButtonBase,
  Dialog
} from '@mui/material';
// icons
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
// components
import ThumbnailEditorDialog from "./ThumbnailEditorDialog";
import api from "../../../../api";

// ----------------------------------------------------------------------

const thumbnailNames = {
  pool: {
    "workout-pool-1": "workout-pool-1",
    "workout-pool-2": "workout-pool-2",
    "workout-pool-3": "workout-pool-3",
    "workout-pool-4": "workout-pool-4",
    "workout-pool-5": "workout-pool-5",
    "workout-pool-6": "workout-pool-6",
    "workout-pool-7": "workout-pool-7",
  },
  open: {
    "workout-open-1": "workout-open-1",
    "workout-open-2": "workout-open-2",
    "workout-open-3": "workout-open-3",
    "workout-open-4": "workout-open-4",
    "workout-open-5": "workout-open-5",
    "workout-open-6": "workout-open-6",
    "workout-open-7": "workout-open-7",
  },
};

const PreviewButton = ({src, ...other}) => (
  <Box
    component={ButtonBase}
    sx={(theme) => ({
      width: 88,
      height: 88,
      border: 'solid',
      borderWidth: 1,
      borderColor: 'transparent',
      position: 'relative',
      ...(!src && {
        borderColor: theme.palette.primary.main
      }),
      ...(src && {
        'svg': {
          position: 'absolute',
          fill: theme.palette.common.black,
          opacity: 0,
        },
        '&:hover svg': {
          opacity: 1,
        }
      }),
    })}
    {...other}
  >
    {src ? (
      <>
        <Image
          src={src}
          style={{background: "none", width: '100%'}}
          cover
        />
        <EditIcon/>
      </>
    ) : (
      <AddIcon color="primary"/>
    )}
  </Box>
);

const SelectableThumbnailWrap = ({children, ...other}) => (
  <Box textAlign="center">
    <Box width={88} height={88} marginBottom={1}>
      {children}
    </Box>
    <Checkbox {...other}/>
  </Box>
)

const WorkoutThumbnailDialog = () => {
  const {setValue} = useFormContext();

  const queryClient = useQueryClient();

  const [environment, imageThumbnail, imageThumbnailUrl] = useWatch({
    name: ['environment', 'imageThumbnail', 'imageThumbnailUrl']
  });

  const [open, setOpen] = useState(false);

  const [editDialog, setEditDialog] = useState({open: false, currenFile: undefined});

  const {data: tilesData, refetch: refetchTiles} = useQuery("coachingTiles", api.coaching.profile.getTiles);

  const handleOpenEditDialog = (currenFile) => {
    setEditDialog({open: true, currenFile});
  }

  const handleCloseEditDialog = () => {
    setEditDialog({open: false, currenFile: undefined});
  }

  useEffect(() => {
    const isCustomTile = Object.keys(thumbnailNames).findIndex(env => !!thumbnailNames[env][imageThumbnail]) === -1;
    if (
      environment
      && !isCustomTile
      && !imageThumbnail.includes(environment)
    ) {
      let thumbnail = imageThumbnail.split("-");
      thumbnail[1] = environment;
      thumbnail = thumbnail.join("-");
      setValue("imageThumbnail", thumbnail, {shouldDirty: true});
      setValue("imageThumbnailUrl", window.location.origin + `/images/workouts/${thumbnail}.png`, {shouldDirty: true});
    }
  }, [environment, imageThumbnail]);

  const handleOnSaveTile = (imgUrl) => {
    const fileName = getFileNameFromUrl(imgUrl);
    setValue("imageThumbnail", fileName, {shouldDirty: true});
    setValue("imageThumbnailUrl", imgUrl, {shouldDirty: true});
    refetchTiles();
  }

  const handleOnEditTile = () => {
    refetchTiles();
    queryClient.invalidateQueries({queryKey: ['coachingTiles']});
  }


  return (
    <>
      <PreviewButton src={imageThumbnailUrl} onClick={() => setOpen(true)}/>

      <Dialog
        PaperProps={{sx: {backgroundColor: 'background.default'}}}
        fullWidth
        maxWidth="md"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, p: 2}}>
          <Box>
            <Typography variant="h5">Workout Thumbnails</Typography>
            <Typography variant="body2" color="text.secondary">
              Select from the Simma collection or add up to 10 branded thumbnails of your own that can be used with any
              workout.
            </Typography>
          </Box>

          <Box sx={{display: 'flex', flexFlow: 'wrap', gap: 1}}>
            {Object.keys(thumbnailNames[environment] || thumbnailNames.open).map(thumbnail => (
              <SelectableThumbnailWrap
                key={thumbnail}
                checked={imageThumbnail === thumbnail}
                onChange={() => {
                  setValue("imageThumbnailUrl", `${window.location.origin}/images/workouts/${thumbnail}.png`, {shouldDirty: true});
                  setValue("imageThumbnail", thumbnail, {shouldDirty: true});
                }}
              >
                <Image
                  src={process.env.PUBLIC_URL + `/images/workouts/${thumbnail}.png`}
                  style={{background: "none"}}
                  cover
                />
              </SelectableThumbnailWrap>
            ))}
          </Box>

          <Typography variant="h6" color="text.secondary">Your branded tiles</Typography>

          <Box sx={{display: 'flex', flexFlow: 'wrap', gap: 1}}>
            {tilesData?.map(({fileName, fileUrl}) => (
              <SelectableThumbnailWrap
                key={fileName}
                checked={imageThumbnail === getImageThumbnailFromFileName(fileName)}
                onChange={() => {
                  setValue("imageThumbnailUrl", fileUrl, {shouldDirty: true});
                  setValue("imageThumbnail", getImageThumbnailFromFileName(fileName), {shouldDirty: true});
                }}
              >
                <PreviewButton src={fileUrl} onClick={() => handleOpenEditDialog({fileName, fileUrl})}/>
              </SelectableThumbnailWrap>
            ))}

            <PreviewButton onClick={handleOpenEditDialog}/>
          </Box>

          <ThumbnailEditorDialog
            {...editDialog}
            onClose={handleCloseEditDialog}
            onSave={handleOnSaveTile}
            onEdit={handleOnEditTile}
          />
        </Box>
      </Dialog>
    </>
  );
}

const getImageThumbnailFromFileName = (str) => str.split('.')[0];

const getFileNameFromUrl = (str) => str.substring(str.lastIndexOf('/') + 1).split('.')[0];

export default WorkoutThumbnailDialog;