import { Fragment, useState } from 'react';
// @mui
import { Stack, Typography } from '@mui/material';
// components
import ToggleButtons from '../toggle-buttons';
import SwimSegmentCard from './SwimSegmentCard';
// utils
import { secondsToDisplayTime } from '../../util';
import { getMaxLapValues, VIEW_OPTIONS, VIEW_TYPES } from './utils';
import RunSegmentCard from './RunSegmentCard';

const ActivitySegments = ({ activity }) => {
  const [view, setView] = useState(VIEW_OPTIONS[0].value);
  const isSwim = activity.activityType === 'swimming';

  const { maxLapSplit, maxHeartRate } = getMaxLapValues(
    activity?.sets || activity?.segmentsMetric,
    isSwim
  );

  const segments = isSwim ? activity?.sets?.flatMap((set) => set?.segments) || [] : null;

  const maxValues = {
    [VIEW_TYPES.split]: maxLapSplit,
    [VIEW_TYPES.heartRate]: maxHeartRate
  };

  return (
    <>
      <ToggleButtons
        sx={{ mb: 2 }}
        options={VIEW_OPTIONS}
        view={view}
        onChange={(newView) => setView(newView)}
      />
      <Stack gap={2}>
        {isSwim
          ? segments.map((segment, i) => (
              <Fragment key={segment.id}>
                <SwimSegmentCard
                  data={segment}
                  lapUnit={activity.lapUnit}
                  lapLength={activity.lapLength}
                  view={view}
                  maxValues={maxValues}
                />

                {!!segment.rest && (
                  <Typography variant='caption' color='text.secondary' textAlign='center'>
                    {secondsToDisplayTime(segment.rest)} rest
                  </Typography>
                )}

                {i === segments?.length - 1 && (
                  <Typography variant='caption' color='text.secondary' textAlign='center'>
                    Finish
                  </Typography>
                )}
              </Fragment>
            ))
          : activity.segmentsMetric.map((segment, i, self) => (
              <Fragment key={i}>
                <RunSegmentCard
                  data={segment}
                  lapUnit={activity.unitsMetric.lapSize}
                  view={view}
                  maxValues={maxValues}
                  self={self}
                />

                {!!segment.timeRestedSec && (
                  <Typography variant='caption' color='text.secondary' textAlign='center'>
                    {secondsToDisplayTime(segment.timeRestedSec)} rest
                  </Typography>
                )}
                {i === activity.segmentsMetric.length - 1 && (
                  <Typography variant='caption' color='text.secondary' textAlign='center'>
                    Finish
                  </Typography>
                )}
              </Fragment>
            ))}
      </Stack>
    </>
  );
};

export default ActivitySegments;
