// @mui
import { TableRow, TableCell, Skeleton } from '@mui/material';

// ----------------------------------------------------------------------

const TableSkeleton = ({ ...other }) => {
  return (
    <TableRow {...other}>
      <TableCell><Skeleton variant="text" width="100%" height={20} /></TableCell>
      <TableCell><Skeleton variant="text" width="100%" height={20} /></TableCell>
      <TableCell><Skeleton variant="text" width="100%" height={20} /></TableCell>
      <TableCell><Skeleton variant="text" width="100%" height={20} /></TableCell>
      <TableCell><Skeleton variant="text" width="100%" height={20} /></TableCell>
      <TableCell><Skeleton variant="text" width="100%" height={20} /></TableCell>
      <TableCell><Skeleton variant="text" width="100%" height={20} /></TableCell>
    </TableRow>
  );
}

export default TableSkeleton;