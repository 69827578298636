import {useCallback, useContext, useEffect} from "react";
import {useFieldArray, useFormContext, useWatch} from "react-hook-form";
// @mui
import {Box, Card, Chip, Grid, Stack, TextField, Typography} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
// icons
import FlagIcon from "@mui/icons-material/Flag";
import MenuItem from "@mui/material/MenuItem";
// components
import {RHFAutocomplete, RHFSelect, RHFTextField} from "../../../../components/hook-form";
import SplitSegmentFields from "./formFields/SplitSegmentFields";
import StrokeDrillField from "./formFields/StrokeDrillField";
import TimeField from "./formFields/TimeField";
import SplitSection from "./SplitSection";
import {
  calculateEffort,
  calculateTargetTime,
} from "../../../../components/WorkoutCreator/utils";
import {workoutEquipments} from "./utils";
import {secondsToHMS} from "../../../../util";
import {CoachContext} from "../../../../context";


const SegmentInfoSection = ({baseName}) => {
  const [distance, repeat, cycleTime] = useWatch({
    name: [baseName + 'distance', baseName + 'repeat', baseName + 'cycleTime']
  });

  return (
    <Box display="flex" justifyContent="flex-end" marginBottom={1} gap={2}>
      <Box display="flex" gap={.5}>
        <FlagIcon opacity={.55}/>
        <Typography variant="body1">{(distance || 0) * (repeat || 0)}</Typography>
      </Box>
      <Box display="flex" gap={.5}>
        <AccessTimeIcon opacity={.55}/>
        <Typography variant="body1">{secondsToHMS((cycleTime || 0) * (repeat || 0))}</Typography>
      </Box>
    </Box>
  );
}

const SegmentSection = ({item, baseName = '', updateSetDistance, updateSetTime, updateSetEquipment}) => {
  const {setValue, control, getValues} = useFormContext();

  const {coach} = useContext(CoachContext);

  const {fields} = useFieldArray({
    control,
    name: baseName + 'splits',
  });

  const getTargetTime = useCallback(({distance, effort}) => {
    const baseFtp = getValues('baseFtp')
    return calculateTargetTime(baseFtp, distance, effort);
  }, []);

  const getEffort = useCallback(({distance, time}) => {
    const baseFtp = getValues('baseFtp')
    return calculateEffort(baseFtp, distance, time);
  }, []);

  const updateCycleTime = () => {
    const [targetTime, rest] = getValues([baseName + 'targetTime', baseName + 'rest']);
    const total = targetTime + rest;
    setValue(baseName + 'cycleTime', total);
    setValue(baseName + 'cycleTimeDisplay', secondsToHMS(total));
    updateSetTime();
  }

  // splits
  const handleUpdateSegmentsDistance = (data) => {
    const splits = getValues(baseName + 'splits')
    const segmentDistance = (data || splits).reduce((initVal, split) => initVal + (split.distance || 0), 0);
    setValue(baseName + 'distance', segmentDistance);
    updateSetDistance();
  };

  const handleUpdateSegmentTargetTime = (data) => {
    const splits = getValues(baseName + 'splits')
    const segmentTime = (data || splits).reduce((initVal, split) => initVal + (split.targetTime || 0), 0);
    setValue(baseName + 'targetTime', segmentTime);
    setValue(baseName + 'targetTimeDisplay', secondsToHMS(segmentTime));
    updateCycleTime();
  };

  const handleUpdateSegmentStroke = (data) => {
    const splits = getValues(baseName + 'splits')
    const isDrills = (data || splits).map((split) => split.isDrill);
    const strokes = (data || splits).map((split) => split.stroke);
    let stroke, isDrill;
    if (isDrills.includes(true)) {
      stroke = "drill";
      isDrill = true;
    } else if (new Set(strokes).size === 1) {
      stroke = strokes[0];
      isDrill = false;
    } else {
      stroke = "mixed";
      isDrill = false;
    }
    setValue(baseName + 'stroke', stroke);
    setValue(baseName + 'isDrill', isDrill);
  };

  const updateTargetTime = (time) => {
    setValue(baseName + 'targetTime', time);
    setValue(baseName + 'targetTimeDisplay', secondsToHMS(time));
    updateCycleTime();
  };

  const handleOnUpdateStroke = (isDrill) => {
    setValue(baseName + 'isDrill', isDrill);
    if (isDrill) {
      const distance = getValues(baseName + 'distance');
      const effort = 'a';
      setValue(baseName + 'effort', effort);
      updateTargetTime(getTargetTime({distance, effort}));
    }
  }

  const handleOnUpdateEffort = () => {
    const [distance, effort] = getValues([baseName + 'distance', baseName + 'effort']);
    updateTargetTime(getTargetTime({distance, effort}));
  }

  const handleOnUpdateTargetTime = ({time}) => {
    const distance = getValues(baseName + 'distance')
    setValue(baseName + 'effort', getEffort({distance, time}));
    setValue(baseName + 'targetTimeDisplay', secondsToHMS(time));
    updateCycleTime();
  }

  // on add/remove splits
  useEffect(() => {
    if (!!fields.length) {
      handleUpdateSegmentsDistance(fields);
      handleUpdateSegmentTargetTime(fields);
      handleUpdateSegmentStroke(fields);
    }
  }, [fields])


  return (
    <Card sx={{p: 1.5, display: 'flex', flexDirection: 'column', gap: 1.5, backgroundColor: "background.secondary"}}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body2" color="text.secondary">Segments</Typography>
        <Box>
          <SegmentInfoSection baseName={baseName}/>

          <SplitSegmentFields item={item} baseName={baseName}/>
        </Box>
      </Stack>

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <RHFTextField
            name={baseName + 'repeat'}
            onChange={(event) => {
              setValue(baseName + 'repeat', Math.abs(Number(event.target.value) || 1));
              updateSetDistance();
              updateSetTime();
            }}
            label="Repeat segment"
            variant="filled"
            type="number"
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <RHFAutocomplete
            name={baseName + 'equipment'}
            multiple
            freeSolo
            onChange={(event, newValue) => {
              setValue(baseName + 'equipment', newValue)
              updateSetEquipment();
            }}
            options={workoutEquipments.map((option) => option)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip {...getTagProps({index})} key={option} size="small" label={option}/>
              ))
            }
            renderInput={(params) => (
              <TextField label="Equipment" variant="filled" {...params} />
            )}
          />
        </Grid>

        {!fields?.length && (
          <>
            <Grid item xs={12} md={3}>
              <RHFTextField
                name={baseName + 'distance'}
                onChange={(event) => {
                  const value = Math.abs(Number(event.target.value) || 1);
                  setValue(baseName + 'distance', value);
                  const effort = getValues(baseName + 'effort');
                  updateTargetTime(getTargetTime({distance: value, effort}));
                  updateSetDistance();
                }}
                label="Distance"
                variant="filled"
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <StrokeDrillField
                name={baseName + 'stroke'}
                onChange={(event, {isDrill}) => {
                  setValue(baseName + 'stroke', event.target.value);
                  handleOnUpdateStroke(isDrill);
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <RHFSelect
                name={baseName + 'effort'}
                label="Effort"
                variant="filled"
                SelectProps={{
                  native: false,
                  MenuProps: {
                    PaperProps: {
                      sx: {maxHeight: 220},
                    },
                  },
                  sx: {textTransform: 'capitalize'},
                }}
                onChange={(event) => {
                  setValue(baseName + 'effort', event.target.value);
                  handleOnUpdateEffort();
                }}
              >
                {Object.keys(coach?.effortZoneNames || {}).map((key) => (
                  <MenuItem
                    key={key}
                    value={key}
                    sx={{
                      my: 0.5,
                      typography: 'body2',
                      textTransform: 'capitalize',
                      '&:first-of-type': {mt: 0},
                      '&:last-of-type': {mb: 0},
                    }}
                  >
                    {coach.effortZoneNames[key]}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
            <Grid item xs={12} md={3}>
              <TimeField
                name={baseName + 'targetTime'}
                label="Target time"
                variant="filled"
                onChangeCallback={handleOnUpdateTargetTime}
              />
            </Grid>
          </>
        )}

        {fields.map((item, index) => (
          <SplitSection
            key={item.id}
            baseName={`${baseName}splits[${index}].`}
            updateSegmentsDistance={handleUpdateSegmentsDistance}
            updateSegmentsTargetTime={handleUpdateSegmentTargetTime}
            updateSegmentStroke={handleUpdateSegmentStroke}
            getTargetTime={getTargetTime}
            getEffort={getEffort}
          />
        ))}

        <Grid item xs={12} md={6}>
          <TimeField
            name={baseName + 'rest'}
            label="Rest"
            variant="filled"
            onChangeCallback={updateCycleTime}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <RHFTextField
            name={baseName + 'cycleTimeDisplay'}
            label="Cycle Time"
            variant="filled"
            disabled
          />
        </Grid>
      </Grid>
    </Card>
  )
}

export default SegmentSection;