import SvgIcon from '@mui/material/SvgIcon';

const SpeedIcon = props => {
  return (
    <svg width="50" height="44" viewBox="0 0 50 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.7 18.0001C36.7 16.3802 35.6881 15.0001 34.4 15.0001L6.8 15.0001C5.55808 15.0001 4.5 16.32 4.5 18.0001L4.5 27.6001C4.5 29.22 5.51192 30.6001 6.8 30.6001L34.4 30.6001C35.6419 30.6001 36.7 29.2802 36.7 27.6001V18.0001ZM34.4 28.2001H32.56V24.0001H30.72V28.2001H29.34V24.0001H27.5V28.2001H25.66V21.0001H23.82V28.2001H21.98V24.0001H20.14V28.2001H18.76V24.0001H16.92V28.2001H15.08V21.0001H13.24V28.2001H11.4V24.0001H9.56V28.2001H6.8C6.57 28.2001 6.34 27.9601 6.34 27.6001L6.34 18.0001C6.34 17.7001 6.52403 17.4001 6.8 17.4001H34.4C34.63 17.4001 34.86 17.6401 34.86 18.0001V27.6001C34.86 27.9601 34.676 28.2001 34.4 28.2001Z"
        fill="#FF59B9"/>
    </svg>
  );
}

export default SpeedIcon;
