import { useState } from "react";
import Input from "../forms/Input.jsx";
import { useMutation } from "react-query";
import api from "api";
import {
  Button,
  // Dialog,
  DialogActions,
  DialogContent,
  // DialogContentText,
  DialogTitle,
} from "@mui/material";
// import PropTypes from 'prop-types';

const ProfileDetailsForm = ({ open, handleClose, user, refetch }) => {
  const defaultForm = {
    username: user.username,
    firstName: user.firstName,
    lastName: user.lastName,
  };
  const [form, setForm] = useState(defaultForm);
  const mutation = useMutation((data) => api.user.update(data), {
    onSuccess: () => {
      refetch();
      handleClose();
    },
  });

  const submit = () => {
    mutation.mutate(form);
  };

  return (
    <>
      <DialogTitle className="title">Profile Details</DialogTitle>
      <DialogContent sx={{ minWidth: { md: 450 } }}>
        <Input
          label="Username"
          value={form.username}
          onUpdate={(val) => setForm({ ...form, username: val })}
        />
        <Input
          label="First name"
          value={form.firstName}
          onUpdate={(val) => setForm({ ...form, firstName: val })}
        />
        <Input
          label="Last name"
          value={form.lastName}
          onUpdate={(val) => setForm({ ...form, lastName: val })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <button
          className="button"
          onClick={submit}
          disabled={mutation.isLoading}
        >
          Save
        </button>
      </DialogActions>
    </>
  );
};

// ProfileDetailsForm.propTypes = {
//   : PropTypes.
// };

export default ProfileDetailsForm;
