import { userApi } from "./axios";

export const pushWorkout = async (body) => {
  const { data } = await userApi.post("garmin/workouts", body);
  return data;
};

export const requestToken = async () => {
  const { data } = await userApi.get("garmin/request-token");
  return data;
};

export const connectAccount = async (body) => {
  const { data } = await userApi.post("garmin/connect", body);
  return data;
};

export const disconnectAccount = async () => {
  const { data } = await userApi.delete("garmin/connect");
  return data;
};

