import { useState } from "react";
import { Stack, FormControl, Select, MenuItem } from "@mui/material";
import WorkoutFilters from "components/SortFilters/WorkoutFilters";

const WorkoutSortFilterBar = ({
  workouts,
  visibleWorkouts,
  setWorkouts,
  tags,
}) => {
  const [sort, setSort] = useState("date");
  const handleSortChange = (event) => {
    setSort(event.target.value);
    setWorkouts(sortWorkouts([...visibleWorkouts], event.target.value));
  };

  const handleFilterWorkouts = (filteredWorkouts) => {
    setWorkouts(sortWorkouts([...filteredWorkouts], sort));
  };

  return (
    <Stack
      direction="row"
      sx={{ justifyContent: "space-between", alignItems: "center" }}
      pt={2}
    >
      <FormControl>
        <Select value={sort} label="" onChange={handleSortChange} size="small">
          <MenuItem value={"date"}>Recently added</MenuItem>
          <MenuItem value={"alphabetical"}>Alphabetical</MenuItem>
          <MenuItem value={"distanceDesc"}>Distance long to short</MenuItem>
          <MenuItem value={"distanceAsc"}>Distance short to long</MenuItem>
        </Select>
      </FormControl>

      <WorkoutFilters
        workouts={workouts}
        setWorkouts={handleFilterWorkouts}
        tags={tags}
      />
    </Stack>
  );
};
export default WorkoutSortFilterBar;

const sortWorkoutsByDate = (workouts) => {
  return workouts.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });
};

const sortWorkoutsByAlphabetical = (workouts) => {
  return workouts.sort((a, b) => {
    return a.title.localeCompare(b.title);
  });
};

const sortWorkoutsByDistanceDesc = (workouts) => {
  return workouts.sort((a, b) => {
    return b.distance - a.distance;
  });
};

const sortWorkoutsByDistanceAsc = (workouts) => {
  return workouts.sort((a, b) => {
    return a.distance - b.distance;
  });
};

const sortWorkouts = (workouts, sort) => {
  switch (sort) {
    case "date":
      return sortWorkoutsByDate(workouts);
    case "alphabetical":
      return sortWorkoutsByAlphabetical(workouts);
    case "distanceDesc":
      return sortWorkoutsByDistanceDesc(workouts);
    case "distanceAsc":
      return sortWorkoutsByDistanceAsc(workouts);
    default:
      return workouts;
  }
};
