import { Box, Stack, Typography } from "@mui/material";
import {
	Bar,
	Line,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
	BarChart, LineChart, ReferenceArea
} from "recharts";

import CustomToolTip from "../../components/charts/CustomToolTip";
import { CHART_CONSTANTS } from "../../components/charts/chartUtilities";
import { heartRateColors } from "../../style/colors";
import { useContext } from "react";
import { UserContext } from "../../context";
import HeartRateZonesWidget from "../../components/common/HeartRateZonesWidget";

const labelHeight = CHART_CONSTANTS.labelHeight;

const RunChartsTab = ({ activity }) => {

	const { user } = useContext(UserContext);

	const paceGraphData = activity.paceGraph.paceMinKm.map(i => ({ pace: i }))

	const heartRateData = activity.heartRateGraph.heartRate.map(i => ({heartRate: i}))
	const heartRateTimeData = activity.heartRateGraph.timeSinceStartSec.map(i => ({timeSinceStartSec: i}))
	const heartRateGraphData = heartRateData.map((i, index) => ({...i, ...( heartRateTimeData[index] )}))

	return (
		<Stack gap={ 2 }>
			<Box>
				<Typography variant="h6" sx={ { mb: 1 } }>Pace</Typography>
				<ResponsiveContainer width="100%" height={ 247 }>
					<BarChart
						data={ paceGraphData }
						margin={ { top: 0, right: 0, left: 0, bottom: 0 } }
						syncId="anyId"
					>
						<XAxis  tick={false}/>
						<YAxis dataKey="pace" />
						<Tooltip offset={ 25 } content={ <CustomToolTip/> }/>
						<Bar
							isAnimationActive={ false }
							dataKey="pace"
							fill="#78848D"
						/>
					</BarChart>
				</ResponsiveContainer>
			</Box>

			<Box>
				<Typography variant="h6" sx={ { mb: 1 } }>Heart rate</Typography>
				<ResponsiveContainer width="100%" height={247}>
					<LineChart
						data={heartRateGraphData}
						margin={{ top: 5, right: 0, left: 10, bottom: 0 }}
					>
						<XAxis
							dataKey="timeSinceStartSec"
							height={labelHeight}
							tick={false}
						/>
						<YAxis domain={[40, user.maxHeartRate]} width={40} tick={{fontSize: 2}} />
						<Tooltip content={<CustomToolTip />} />
						<ReferenceArea y1={user.hearRateZoneBounds.rest[0]} y2={user.hearRateZoneBounds.rest[1]} fillOpacity={.3} fill={heartRateColors.blue} />
						<ReferenceArea y1={user.hearRateZoneBounds.a[0]} y2={user.hearRateZoneBounds.a[1]} fillOpacity={.3} fill={heartRateColors.green} />
						<ReferenceArea y1={user.hearRateZoneBounds.b[0]} y2={user.hearRateZoneBounds.b[1]} fillOpacity={.3} fill={heartRateColors.yellow} />
						<ReferenceArea y1={user.hearRateZoneBounds.c[0]} y2={user.hearRateZoneBounds.c[1]} fillOpacity={.3} fill={heartRateColors.orange} />
						<ReferenceArea y1={user.hearRateZoneBounds.d[0]} fillOpacity={.3} fill={heartRateColors.red} />
						<Line type="monotone" dataKey="heartRate" stroke="rgba(241, 88, 79, 1)" strokeWidth={2} dot={false} activeDot={{ strokeWidth: 0, r: 5 }} />
					</LineChart>
				</ResponsiveContainer>
			</Box>
			{!!activity.timeInHeartRateZonesSec && <HeartRateZonesWidget timeInHeartRateZonesSec={activity.timeInHeartRateZonesSec}/>} 
		</Stack>
	);
};

export default RunChartsTab;
