// @mui
import { Box, Card, LinearProgress, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
// components
import EquipmentIconList from '../EquipmentIconList';
// utils
import { bgGradient, getPercent, numberWithLeadingZeros, secondsToDisplayTime } from '../../util';
import { LAP_LABELS, STROKE_ICONS, STROKE_TYPES, SWIM_VALUES } from './utils';

const StrokeIcon = ({ type }) => (
  <Box
    sx={{
      p: 0.5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 2,
      width: 40,
      height: 40,
      backgroundColor: 'background.secondaryLight'
    }}>
    {STROKE_ICONS[type]}
  </Box>
);

const SegmentCardHeader = ({ icon, distance, duration, equipment }) => (
  <Box
    sx={(theme) => ({
      p: 1,
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: 1,
      borderBottomColor: alpha(theme.palette.common.white, 0.11)
    })}>
    <Stack direction='row' alignItems='center' gap={1}>
      {icon}
      <Typography variant='h6'>{distance}</Typography>
    </Stack>
    <Stack direction='row' alignItems='center' gap={1} sx={{ mr: 1 }}>
      <EquipmentIconList
        alignItems={'center'}
        gap={1}
        fontSize={'24px'}
        keyPrefix={'activity'}
        equipment={equipment}
      />
      <Typography variant='body1'>{duration}</Typography>
    </Stack>
  </Box>
);

const SegmentCardLap = ({ lap, length, valuePercent, valueDisplay, strokeLabels }) => (
  <Stack
    sx={{
      p: 1,
      borderRadius: 2,
      '&:nth-of-type(even)': {
        backgroundColor: 'background.secondaryLight'
      }
    }}
    direction='row'
    justifyContent='space-between'
    alignItems='center'>
    <Stack flexGrow={1} direction='row' gap={1} alignItems='center'>
      <Typography variant='body1'>{lap}</Typography>
      <Typography variant='body1' color='text.secondary'>
        {lap && length && '\\'}
      </Typography>
      <Typography variant='body1' color='text.secondary'>
        {length}
      </Typography>
      {strokeLabels?.map((stroke) => (
        <Typography
          key={stroke}
          color='text.secondary'
          variant='caption'
          textTransform='uppercase'
          fontWeight={500}
          minWidth={30}>
          {LAP_LABELS[stroke]}
        </Typography>
      ))}
      <LinearProgress
        sx={(theme) => ({
          ml: 2,
          width: '100%',
          height: 16,
          borderRadius: 0.5,
          backgroundColor: alpha(theme.palette.common.black, 0.11),
          '.MuiLinearProgress-bar': {
            transition: theme.transitions.create('transform', { duration: 225, easing: 'ease' }),
            ...bgGradient({
              direction: 'to right',
              startColor: theme.palette.action.gradientStart,
              endColor: theme.palette.action.gradientEnd
            })
          }
        })}
        variant='determinate'
        value={valuePercent}
      />
    </Stack>
    <Typography sx={{ minWidth: 66, textAlign: 'right' }} variant='body1'>
      {valueDisplay}
    </Typography>
  </Stack>
);

const SwimSegmentCard = ({ data, lapUnit, lapLength, view, maxValues }) => {
  const distance = (data?.distanceN?.toFixed(0) || 0) + lapUnit;
  const valueDisplayKey = SWIM_VALUES[view].keyDisplay;
  const valueKey = SWIM_VALUES[view].key;
  const lapLengthLabel = (lapLength || 0) + lapUnit;
  const isMixed = data?.stroke === STROKE_TYPES.mixed;

  return (
    <Card sx={{ backgroundColor: 'background.secondary' }}>
      <SegmentCardHeader
        icon={
          data?.stroke &&
          STROKE_ICONS.hasOwnProperty(data.stroke) && <StrokeIcon type={data.stroke} />
        }
        equipment={data.equipment}
        distance={distance}
        duration={secondsToDisplayTime(data.duration)}
      />

      <Box sx={{ px: 1, py: 2 }}>
        <Stack
          sx={{ px: 1, mb: 1 }}
          direction='row'
          justifyContent='space-between'
          alignItems='center'>
          <Typography variant='caption' color='text.secondary'>
            LAP
          </Typography>
          <Typography variant='caption' color='text.secondary'>
            {SWIM_VALUES[view]?.label}
          </Typography>
        </Stack>

        {data?.laps.map((lap, i) => {
          const lapActivity = numberWithLeadingZeros(lap.activityLap, 2);
          const valuePercent = getPercent(lap[valueKey] || 0, maxValues[view] || 100);
          const lapValue = lap[valueDisplayKey];
          const strokeLabels = isMixed ? lap.stroke : [];

          return (
            <SegmentCardLap
              key={i}
              lap={lapActivity}
              length={lapLengthLabel}
              valuePercent={valuePercent}
              valueDisplay={lapValue}
              strokeLabels={strokeLabels}
            />
          );
        })}
      </Box>
    </Card>
  );
};

export default SwimSegmentCard;
