import { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// import Modal from "../components/Modal";
import { BsChevronRight } from 'react-icons/bs';
import { secondsToDisplayTime, getAge } from '../util';
import ProfileDetailsForm from '../components/profile/ProfileDetailsForm';
import ProfilePoolForm from '../components/profile/ProfilePoolForm';
import ProfileFTPForm from '../components/profile/ProfileFTPForm';
import ProfileFTPRunForm from '../components/profile/ProfileFTPRunForm';
import ProfileGarminForm from '../components/profile/ProfileGarminForm';
import ProfileAgeForm from '../components/profile/ProfileAgeForm';
import FindCoach from '../components/profile/FindCoach';
import ProfileDeleteCoaching from '../components/profile/ProfileDeleteCoaching';
import ProfileBecomeACoachForm from 'components/profile/ProfileBecomeACoachForm';
// import ProfileCoaching from "../components/profile/ProfileCoaching";
import ProfileCurrencyForm from '../components/profile/ProfileCurrencyForm';
import ProfileUnitesForm from '../components/profile/ProfileUnitesForm';
import ProfileCoaches from 'components/profile/ProfileCoaches';

import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { Container } from '@mui/material';
import { UserContext } from '../context';
import ScrollWrapper from '../layouts/ScrollWrapper';

// import * as tracking from "../tracking";
import Subscriptions from 'components/profile/Subscriptions';
import { useAuthContext } from '../auth/useAuthContext';

const Profile = () => {
  const { logout } = useAuthContext();
  const history = useHistory();
  const { user: data, refetch } = useContext(UserContext);
  const [modal, setModal] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (modal) {
      window.FreshworksWidget('hide');
    } else {
      window.FreshworksWidget('show');
    }
  }, [modal]);

  return (
    <>
      <ScrollWrapper>
        <Container sx={{ paddingTop: '2rem' }} maxWidth='sm'>
          <h1 className='title-large'>Profile</h1>

          <div className='profile-section' onClick={() => setModal('name')}>
            <div className='profile-section__sub--left'>
              <div>
                {data.firstName ? (
                  <p>
                    {data.firstName} {data.lastName}
                  </p>
                ) : null}
                <p>{data.username}</p>
              </div>
            </div>
            <div className='profile-section__sub--right'>
              <div className='profile-arrow'>
                <BsChevronRight />
              </div>
            </div>
          </div>

          <div className='profile-section' onClick={() => setModal('pool')}>
            <div className='profile-section__sub--left'>
              <div>Pool preferences</div>
            </div>
            <div className='profile-section__sub--right'>
              <div>
                {data.lapLength}
                {data.lapUnit}
              </div>
              <div className='profile-arrow'>
                <BsChevronRight />
              </div>
            </div>
          </div>

          <div className='profile-section' onClick={() => setModal('age')}>
            <div className='profile-section__sub--left'>
              <div>Age</div>
            </div>
            <div className='profile-section__sub--right'>
              <div>
                {data.birthdate
                  ? getAge(data.birthdate)
                  : !!data.ageRange?.length
                    ? `${data.ageRange[0]}-${data.ageRange[1]}`
                    : 'Not set'}
              </div>
              <div className='profile-arrow'>
                <BsChevronRight />
              </div>
            </div>
          </div>

          <div className='profile-section' onClick={() => setModal('ftp')}>
            <div className='profile-section__sub--left'>
              <div>Current Swim FTP</div>
            </div>
            <div className='profile-section__sub--right'>
              <div>{secondsToDisplayTime(data.functionalThresholdPace)}</div>
              <div className='profile-arrow'>
                <BsChevronRight />
              </div>
            </div>
          </div>

          <div className='profile-section' onClick={() => setModal('ftp-run')}>
            <div className='profile-section__sub--left'>
              <div>Current Run FTP</div>
            </div>
            <div className='profile-section__sub--right'>
              <div>{secondsToDisplayTime(data.functionalThresholdPaceRun)}</div>
              <div className='profile-arrow'>
                <BsChevronRight />
              </div>
            </div>
          </div>

          <div className='profile-section' onClick={() => setModal('currency')}>
            <div className='profile-section__sub--left'>
              <div>Preferred Currency</div>
            </div>
            <div className='profile-section__sub--right'>
              <div>{data.currency ? data.currency.toUpperCase() : 'Not set'}</div>
              <div className='profile-arrow'>
                <BsChevronRight />
              </div>
            </div>
          </div>

          <div className='profile-section' onClick={() => setModal('unites')}>
            <div className='profile-section__sub--left'>
              <div>Preferred Units</div>
            </div>
            <div className='profile-section__sub--right'>
              <div>{data.preferredUnits?.toUpperCase() ?? 'Metric'}</div>
              <div className='profile-arrow'>
                <BsChevronRight />
              </div>
            </div>
          </div>

          <h2 className='caption'>DEVICES & CONNECTIONS</h2>
          <div className='profile-section' onClick={() => setModal('garmin')}>
            <div className='profile-section__sub--left'>
              <div>Garmin</div>
            </div>
            <div className='profile-section__sub--right'>
              <div>{data.garminConnected ? 'Connected' : 'Not connected'}</div>
              <div className='profile-arrow'>
                <BsChevronRight />
              </div>
            </div>
          </div>

          <ProfileCoaches />

          <Subscriptions />

          <h2 className='caption'>LEGAL</h2>
          <div className='profile-section' onClick={() => history.push('/privacy-policy')}>
            <div className='profile-section__sub--left'>
              <div>Privacy Policy</div>
            </div>
            <div className='profile-section__sub--right'>
              <div className='profile-arrow'>
                <BsChevronRight />
              </div>
            </div>
          </div>

          <div
            className='profile-section'
            onClick={() => history.push('/athlete-terms-and-conditions')}>
            <div className='profile-section__sub--left'>
              <div>Terms & Conditions</div>
            </div>
            <div className='profile-section__sub--right'>
              <div className='profile-arrow'>
                <BsChevronRight />
              </div>
            </div>
          </div>

          {!!data.isCoach && (
            <div
              className='profile-section'
              onClick={() => history.push('/coach-terms-and-conditions')}>
              <div className='profile-section__sub--left'>
                <div>Coach Terms & Conditions</div>
              </div>
              <div className='profile-section__sub--right'>
                <div className='profile-arrow'>
                  <BsChevronRight />
                </div>
              </div>
            </div>
          )}

          {/* <h2 className="caption">SWIM COACH</h2>
          <ProfileCoaching
            coaching={data.coaching}
            coachId={!!data.coaching && data.coaching.coachId}
            setModal={setModal}
          /> */}

          {!data.isCoach && (
            <div>
              <h2 className='caption'>COACHING</h2>
              <div className='profile-section' onClick={() => setModal('becomeACoach')}>
                <div className='profile-section__sub--left'>
                  <div>Become a coach</div>
                </div>
                <div className='profile-section__sub--right'>
                  <BsChevronRight />
                </div>
              </div>
            </div>
          )}

          <div>
            <h2 className='caption'>SIGN OUT</h2>
            <a
              className='profile-section'
              target='_self'
              rel='noopener noreferrer'
              onClick={logout}>
              <div className='profile-section__sub--left'>
                <div className='color-red'>Sign out</div>
              </div>
              <div className='profile-section__sub--right'>
                <BsChevronRight />
              </div>
            </a>
          </div>
        </Container>
      </ScrollWrapper>
      <Dialog open={!!modal} onClose={() => setModal(null)} fullScreen={fullScreen}>
        {modal === 'name' && (
          <ProfileDetailsForm
            user={data}
            open={modal === 'name'}
            handleClose={() => setModal(null)}
            refetch={refetch}
          />
        )}
        {modal === 'pool' && (
          <ProfilePoolForm
            user={data}
            open={modal === 'pool'}
            handleClose={() => setModal(null)}
            refetch={refetch}
          />
        )}
        {modal === 'currency' && (
          <ProfileCurrencyForm
            user={data}
            open={modal === 'currency'}
            handleClose={() => setModal(null)}
            refetch={refetch}
          />
        )}
        {modal === 'ftp' && (
          <ProfileFTPForm
            user={data}
            open={modal === 'ftp'}
            handleClose={() => setModal(null)}
            refetch={refetch}
          />
        )}
        {modal === 'ftp-run' && (
          <ProfileFTPRunForm
            user={data}
            open={modal === 'ftp-run'}
            handleClose={() => setModal(null)}
            refetch={refetch}
          />
        )}
        {modal === 'garmin' && (
          <ProfileGarminForm
            user={data}
            open={modal === 'garmin'}
            handleClose={() => setModal(null)}
            refetch={refetch}
          />
        )}
        {modal === 'age' && (
          <ProfileAgeForm
            user={data}
            open={modal === 'age'}
            handleClose={() => setModal(null)}
            refetch={refetch}
          />
        )}
        {modal === 'findCoach' && (
          <FindCoach
            user={data}
            open={modal === 'findCoach'}
            handleClose={() => setModal(null)}
            refetch={refetch}
          />
        )}
        {modal === 'stopCoaching' && (
          <ProfileDeleteCoaching
            user={data}
            open={modal === 'stopCoaching'}
            handleClose={() => setModal(null)}
            refetch={refetch}
            accepted
          />
        )}
        {modal === 'cancelCoachRequest' && (
          <ProfileDeleteCoaching
            user={data}
            open={modal === 'cancelCoachRequest'}
            handleClose={() => setModal(null)}
            refetch={refetch}
          />
        )}
        {modal === 'unites' && (
          <ProfileUnitesForm
            user={data}
            open={modal === 'unites'}
            handleClose={() => setModal(null)}
            refetch={refetch}
          />
        )}

        {modal === 'becomeACoach' && (
          <ProfileBecomeACoachForm
            user={data}
            open={modal === 'becomeACoach'}
            handleClose={() => setModal(null)}
            refetch={refetch}
          />
        )}
      </Dialog>
    </>
  );
};

// Profile.propTypes = {
//   : PropTypes.
// };

export default Profile;
