import React, { useRef, useEffect } from 'react';

import { resolveHandIcon, resolveIcon } from "./utils";


const numbers = {
	180: '-90',
	210: '-60',
	240: '-30',
	270: '0',
	300: '30',
	330: '60',
	360: '90',
}

const CrossoverRadialGraph = ({ hand = 'right', data, bins, rotation = 0 }) => {

	const canvasRef = useRef(null);
	const drawIcon = (ctx, svgString, onLoad) => {

		const icon = new Image();

		const blob = new Blob([svgString], { type: 'image/svg+xml' });
		const url = URL.createObjectURL(blob);
		icon.src = url;

		icon.onload = () => onLoad(icon)
	}

	useEffect(() => {
		const canvas = canvasRef.current;
		const ctx = canvas.getContext('2d');

		const centerX = canvas.width / 2;
		const centerY = canvas.height / 2 + 110;
		const radius = 302;
		const startAngle = 180;
		let counter = 0;

		ctx.font = '22px Arial';
		ctx.textBaseline = 'middle';
		ctx.textAlign = 'center';
		ctx.fillStyle = '#FFFFFF';

		drawIcon(ctx, resolveIcon(hand),  (icon) => {
			const angle = (rotation + 270) * Math.PI / 180

			ctx.save();
			ctx.translate(centerX, centerY);
			ctx.rotate(angle + Math.PI / 2);
			ctx.drawImage(icon,  - 60 / 2, - 200 / 2 );
			ctx.restore();
		})

		drawIcon(ctx, resolveHandIcon(hand),  (icon) => {
			const angle = (rotation + 270) * Math.PI / 180
			const x = centerX + (radius + 40) * Math.cos(angle);
			const y = centerY + (radius + 40) * Math.sin(angle);

			ctx.save();
			ctx.translate(x, y);
			ctx.rotate(angle + Math.PI / 2);
			ctx.drawImage(icon, -24 / 2, -44 / 2 );
			ctx.restore();
		})



		for (let i = startAngle; i <= 360; i += 30) {
			const angle = i * Math.PI / 180;
			const x = centerX + (radius - 130) * Math.cos(angle);
			const y = centerY + (radius - 130) * Math.sin(angle);

			ctx.save();
			ctx.translate(x, y);
			ctx.rotate(angle + Math.PI / 2);
			ctx.fillText(Math.abs(numbers[Math.round(i)].toString()), 0, 0);
			ctx.restore();
		}


		for (let i = startAngle; i <= 360; i += 5) {
			const angle = i * Math.PI / 180;

			const tickLength = 82;
			const tickWidth = 8;

			const smallTickLength = i === 270 ? 16 : 8;
			const smallTickWidth = 4;

			const tickX = centerX + radius * Math.cos(angle);
			const tickY = centerY + radius * Math.sin(angle);

			const smallTickX = centerX + (radius - tickLength - 16) * Math.cos(angle);
			const smallTickY = centerY + (radius - tickLength - 16) * Math.sin(angle);

			if (i >= 255 && i <= 285) {
				ctx.lineWidth = smallTickWidth;
				ctx.strokeStyle = "rgba(26, 222, 163, 0.2)";
				ctx.beginPath();
				ctx.moveTo(smallTickX, smallTickY);
				ctx.lineTo(smallTickX - smallTickLength * Math.cos(angle), smallTickY - smallTickLength * Math.sin(angle));
				ctx.stroke();


			} else {
				ctx.strokeStyle = "rgba(27, 45, 53, 0.86)";

			}
			ctx.lineWidth = tickWidth;
			ctx.lineCap = 'round';
			ctx.beginPath();
			ctx.moveTo(tickX, tickY);
			ctx.lineTo(tickX - tickLength * Math.cos(angle), tickY - tickLength * Math.sin(angle));
			ctx.stroke();

			if (bins[counter] !== 0) {

				const binLength = tickLength - tickLength * bins[counter];
				ctx.lineCap = 'round';
				if (i >= 255 && i <= 285) {
					ctx.strokeStyle = "#1ADEA3";
				} else {
					ctx.strokeStyle = "rgba(235, 235, 245, 0.6)";
				}

				ctx.beginPath();
				ctx.moveTo(tickX - binLength * Math.cos(angle), tickY - binLength * Math.sin(angle));
				ctx.lineTo(tickX - tickLength * Math.cos(angle), tickY - tickLength * Math.sin(angle));
				ctx.stroke();
			}
			counter++
		}
		const lineX = centerX + (radius + 10) * Math.cos((270 + rotation) * Math.PI / 180);
		const lineY = centerY + (radius + 10) * Math.sin((270 + rotation) * Math.PI / 180);
		ctx.lineWidth = 2;
		ctx.strokeStyle = "#FF5082";
		ctx.beginPath();
		ctx.moveTo(centerX, centerY);
		ctx.lineTo(lineX, lineY);
		ctx.stroke();


	}, []);


	return (
		<canvas ref={ canvasRef } width={ 604 } height={ 540 } style={{ width: '302px', height: '270px' }}/>
	);
};

export default CrossoverRadialGraph;
