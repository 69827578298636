import {Box, Chip, Stack, TextField, Typography} from "@mui/material";
import {useFormContext} from "react-hook-form";
import {RHFAutocomplete, RHFButtonGroup, RHFTextField} from "../../../../components/hook-form";
import WorkoutThumbnailDialog from "./WorkoutThumbnailDialog";


const GeneralInformationStep = ({workoutTags}) => {
  const {setValue} = useFormContext();

  return (
    <Stack sx={{mb: 2}} gap={2}>
      <Box>
        <RHFTextField
          name="title"
          label="Add workout name"
          variant="filled"
        />
        <Typography sx={{px: 1.5}} variant="caption" color="text.secondary">
          Your workout needs a name to be created
        </Typography>
      </Box>

      <Box>
        <RHFTextField
          name="shortDescription"
          label="Add a short description telling the swimmers what to expect in this workout"
          variant="filled"
          multiline
        />
        <Typography sx={{px: 1.5}} variant="caption" color="text.secondary">
          A description is not necessary but is recommended to add some personality to your workouts
        </Typography>
      </Box>

      <Stack direction={{xs: 'column', sm: 'row'}} gap={{xs: 2, sm: 4}}>
        <Box>
          <Typography variant="caption" color="text.secondary">Environment</Typography>
          <RHFButtonGroup
            sx={{mt: 1}}
            name="environment"
            options={[
              {label: "Pool", value: "pool"},
              {label: "Open water", value: "open"},
            ]}
          />
        </Box>

        <Box>
          <Typography variant="caption" color="text.secondary">Workout Level</Typography>
          <RHFButtonGroup
            sx={{mt: 1}}
            valueAsNumber
            name="level"
            onChange={(event, value) => {
              setValue('level', value, {shouldDirty: true});
              setValue('levels', [value]);
            }}
            options={[
              {label: "Beginner", value: 2},
              {label: "Intermediate", value: 3},
              {label: "Advanced", value: 4},
              {label: "Elite", value: 5},
            ]}
          />
        </Box>
      </Stack>

      {workoutTags && (
        <Box>
          <RHFAutocomplete
            name="tags"
            multiple
            freeSolo
            onChange={(event, newValue) => setValue('tags', newValue, {shouldDirty: true})}
            options={workoutTags.map((option) => option)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip {...getTagProps({index})} key={option} size="small" label={option}/>
              ))
            }
            renderInput={(params) => (
              <TextField label="Add a tag to group this workout with" variant="filled" {...params} />
            )}
          />
          <Typography sx={{px: 1.5}} variant="caption" color="text.secondary">
            Add a new tag or start typing to see existing tags for your coach profile.
          </Typography>
        </Box>
      )}

      <WorkoutThumbnailDialog/>
    </Stack>
  );
}

export default GeneralInformationStep;