import SvgIcon from '@mui/material/SvgIcon';
const SnorkelIcon = props => {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M13 11.256V5.61148C13.4365 5.86863 13.964 6 14.5 6C15.036 6 15.5635 5.86863 16 5.61148V15.4934L16 15.5C16 17.9853 13.9853 20 11.5 20C9.12832 20 7.18516 18.1652 7.01248 15.8377C7.47031 15.9447 7.97893 16 8.5 16C9.03938 16 9.5654 15.9408 10.0356 15.8262C10.1845 16.4978 10.7836 17 11.5 17C12.3284 17 13 16.3284 13 15.5V15.496V11.256ZM14.5 3C15.3284 3 16 3.44772 16 4C16 4.55228 15.3284 5 14.5 5C13.6716 5 13 4.55228 13 4C13 3.44772 13.6716 3 14.5 3ZM11 14C11 14.5523 9.88071 15 8.5 15C7.11929 15 6 14.5523 6 14C6 13.4477 7.11929 13 8.5 13C9.88071 13 11 13.4477 11 14Z" fill="#EBEBF5" fillOpacity="0.6"/>
    </SvgIcon>
  );
}

export default SnorkelIcon;
