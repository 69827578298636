import {Link as RouterLink, matchPath, useLocation} from 'react-router-dom';
// @mui
import {Link, styled, ListItemButton, ListItemText, ListItemIcon} from '@mui/material';
import {alpha} from "@mui/material/styles";

// ----------------------------------------------------------------------


const StyledItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'caption',
})(({theme, active}) => {

  const activeStyle = {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, .16),
  };

  return ({
    display: 'flex',
    color: '#fff',
    '.MuiListItemIcon-root': {
      color: alpha('#fff', .56),
      transitions: theme.transitions.create('color', {duration: 225}),
    },
    // Active item
    ...(active && {
      ...activeStyle,
      '.MuiListItemIcon-root': {
        color: theme.palette.primary.main,
      },
      '&:hover': {
        backgroundColor: 'red',
        ...activeStyle,
      },
    }),
  })
});

const NavItem = ({item, ...other}) => {
  const {text, path, icon: Icon} = item;
  const isActive = useActiveLink(path);

  const renderContent = (
    <StyledItem active={isActive} {...other}>
      {Icon && (
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
      )}

      <ListItemText primary={text} />
    </StyledItem>
  );

  return (
    <Link component={RouterLink} to={path} underline="none">
      {renderContent}
    </Link>
  );
}

const useActiveLink = (path) => {
  const { pathname } = useLocation();

  return path ? !!matchPath(pathname, { path }) : false;
}

export default NavItem;