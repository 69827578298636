// Packages
import {useState, useEffect, useRef, useMemo} from "react";
import {
  Typography,
  Grid,
  Box,
  Autocomplete,
  FormHelperText,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import parse from "autosuggest-highlight/parse";
import {useFormContext, useWatch} from "react-hook-form";

// Local Modules
import {throttle} from "../../util";

const GOOGLE_MAPS_API_KEY = "AIzaSyDW2dN9_jaQwbMCbRMeuN5thQOs0ZPoUpg";

const loadScript = (src, position, id) => {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
};

const autocompleteService = {current: null};

const LocationInput = (params) => {
  const {setValue: setFormValue, getValues} = useFormContext();
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [blurred, setBlurred] = useState(false);
  const loaded = useRef(false);
  const isOptional = !!params?.optional;

  const location = useWatch({name: "location"});

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const handleSelect = (data) => {
    if (!data) {
      setFormValue("location", null, {shouldDirty: true});
      setFormValue("locationDisplay", null, {
        shouldDirty: true,
      });
    } else {
      setFormValue("location", data, {shouldDirty: true});
      setFormValue("locationDisplay", data.description, {
        shouldDirty: true,
      });
    }
  };

  const updateLocation = (object) => {
    if (!object) {
      handleSelect(null);
      return;
    }
    const placeId = object.place_id;
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({placeId}).then(({results}) => {
      const result = results[0];
      result.lat = result.geometry.location.lat();
      result.lng = result.geometry.location.lng();
      handleSelect({
        ...result,
        ...object,
      });
    });
  };

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(
          {...request, types: ["(cities)"]},
          callback
        );
      }, 200),
    []
  );

  useEffect(() => {
    const locationDisplay = getValues().locationDisplay;
    setInputValue(locationDisplay);
    setValue(locationDisplay);
  }, []);

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({input: inputValue}, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      style={{}}
      id="google-map-demo"
      sx={{width: "100%"}}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        updateLocation(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue || "");
      }}
      renderInput={(params) => (
        <>
          <div ref={params.InputProps.ref}>
            <input
              {...params.inputProps}
              type="text"
              placeholder="Add your location"
              onBlur={() => setBlurred(true)}
              style={{
                background: "transparent",
                padding: "0 4px",
                fontSize: "0.875rem",
                opacity: 0.7,
              }}
            />
          </div>
          {blurred && !isOptional && (!location || Object.keys(location).length === 0) && (
            <FormHelperText error={true} sx={{paddingLeft: "4px"}}>
              Location is required
            </FormHelperText>
          )}
        </>
      )}
      renderOption={(props, option) => {
        if (!option.structured_formatting) return null; // Added to fix clear location bug
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{color: "text.secondary", mr: 2}}
                />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
      {...params}
    />
  );
};
export default LocationInput;
