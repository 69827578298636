// import PropTypes from 'prop-types';

const Drill = ({size = 34}) => {
  return (
    <svg width={`${size}`} height={`${size}`} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_104_3813)">
      <path d="M1.44299 29.4172H4.34875C6.66906 29.4172 7.99572 27.9939 7.99572 25.5125V25.5017C7.99572 23.074 6.65295 21.6667 4.34875 21.6667H1.44299V29.4172ZM2.82873 28.2517V22.8323H4.16613C5.68615 22.8323 6.58312 23.8206 6.58312 25.5232V25.5339C6.58312 27.2742 5.70764 28.2517 4.16613 28.2517H2.82873ZM9.64557 29.4172H11.0313V26.5007H12.5674L14.0875 29.4172H15.6719L13.98 26.2913C14.8985 25.9583 15.441 25.1096 15.441 24.0837V24.073C15.441 22.5959 14.442 21.6667 12.7769 21.6667H9.64557V29.4172ZM11.0313 25.4373V22.7893H12.605C13.4859 22.7893 14.0176 23.2942 14.0176 24.1052V24.116C14.0176 24.9485 13.5074 25.4373 12.6319 25.4373H11.0313ZM17.1821 29.4172H18.5679V21.6667H17.1821V29.4172ZM20.54 29.4172H25.4814V28.2517H21.9257V21.6667H20.54V29.4172ZM27.0345 29.4172H31.976V28.2517H28.4203V21.6667H27.0345V29.4172Z" fill="#E4F14F"/>
      <g clipPath="url(#clip1_104_3813)">
      <path fillRule="evenodd" clipRule="evenodd" d="M20.0523 5.35437C19.6979 4.7334 19.0407 4.35176 18.3285 4.35337L13.6982 4.36381C12.9861 4.36542 12.3272 4.75002 11.9701 5.3726L9.64361 9.42866C9.28715 10.0501 9.28546 10.8144 9.63918 11.4343L11.9478 15.4799C12.3021 16.1009 12.9593 16.4825 13.6715 16.4809L18.3018 16.4705C19.014 16.4689 19.6728 16.0843 20.0299 15.4617L22.3564 11.4056C22.7129 10.7842 22.7146 10.0198 22.3608 9.39998L20.0523 5.35437ZM17.3207 12.7316C18.5907 11.9898 19.0288 10.3522 18.2994 9.07397C17.57 7.79572 15.9492 7.36085 14.6793 8.10266C13.4094 8.84447 12.9712 10.4821 13.7006 11.7603C14.43 13.0386 16.0508 13.4734 17.3207 12.7316Z" fill="#E4F14F"/>
      </g>
      </g>
      <defs>
      <clipPath id="clip0_104_3813">
      <rect width="34" height="30.9664" fill="white" transform="translate(0 0.417236)"/>
      </clipPath>
      <clipPath id="clip1_104_3813">
      <rect width="32" height="25" fill="white" transform="translate(0 -0.582764)"/>
      </clipPath>
      </defs>
    </svg>
  );
}
//
// Drill.propTypes = {
//   : PropTypes.
// };

export default Drill;
