// import PropTypes from 'prop-types';

const Any = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.44586 29H4.89605L5.54059 26.9966H8.40875L9.05865 29H10.5088L7.75885 21.2495H6.19586L3.44586 29ZM6.9317 22.689H7.02301L8.065 25.9277H5.88971L6.9317 22.689ZM11.9009 29H13.2383V23.6611H13.3242L17.1323 29H18.3301V21.2495H16.998V26.5884H16.9121L13.104 21.2495H11.9009V29ZM22.4506 29H23.831V25.9438L26.5488 21.2495H25.0341L23.1865 24.5742H23.0952L21.2475 21.2495H19.7329L22.4506 25.9438V29Z"
        fill="white"
      />
      <circle cx="20" cy="6" r="3" fill="white" />
      <path d="M11 3L14.4641 9H7.5359L11 3Z" fill="white" />
      <rect x="8" y="12" width="6" height="6" fill="white" />
      <rect
        x="20"
        y="11.5"
        width="5"
        height="5"
        transform="rotate(45 20 11.5)"
        fill="white"
      />
    </svg>
  );
};

// Mixed.propTypes = {
//   : PropTypes.
// };

export default Any;
