// @mui
import { CssBaseline } from '@mui/material';
import { createTheme, StyledEngineProvider, ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import colors, {loginFormColors} from './colors';
import {Drawer, Skeleton, Button, Dialog, Input, Card} from "./overrides";

const ThemeProvider = ({children}) => {
  const theme = createTheme({
    typography: {
      fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif`,
    },
    palette: {
      mode: "dark",
      background: {
        default: colors.backgroundPrimary,
        secondary: colors.backgroundSecondary,
        secondaryLight: colors.backgroundTertiary,
        dialog: loginFormColors.backgroundDialog,
      },
      text: {
        primary: '#fff',
      },
      primary: {
        main: colors.pink,
      },
      secondary: {
        main: colors.pinkTint,
      },
      error: {
        main: colors.red,
      },
      warning: {
        light: colors.yellow,
        main: colors.orange,
      },
      info: {
        main: colors.blue,
      },
      success: {
        main: colors.green,
        dark: colors.greenDark,
      },
      scrim: {
        main: colors.scrim,
      },
      separator: {
        main: colors.separator,
      },
      label: {
        main: colors.label,
      },
      danger: {
        main: colors.red,
      },
      neutral: {
        main: "#64748B",
        contrastText: "#fff",
      },

      action: {
        gradientStart: loginFormColors.buttonGradientStart,
        gradientEnd: loginFormColors.buttonGradientEnd,
      },

      backgrounds: {
        1: colors.backgroundPrimary,
        "1t": colors.backgroundPrimaryTransparent,
        2: colors.backgroundSecondary,
        "2t": colors.backgroundSecondaryTransparent,
        3: colors.backgroundTertiary,
        "3t": colors.backgroundTertiaryTransparent,
      },
      strokes: {
        freestyle: "green",
      },
      common: { black: '#000', white: '#fff' },
    },
  });

  theme.components = Object.assign(
    {
      MuiInputLabel: {
        styleOverrides: {
          '&:not(.MuiInputLabel-standard)': {
            root: {
              fontSize: "0.95rem",
              color: "white",
              // fontWeight: 600,
              lineHeight: "1.25rem",
              letterSpacing: "-0.5px",
            },
            shrink: {
              transform: "translate(14px, -8px) scale(1) !important",
            },
            outlined: {
              transform: "translate(14px, 16px) scale(1)",
            },
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          dense: {
            height: 56,
            minHeight: 56,
          },
        },
      },
    },
    Input(theme),
    Button(theme),
    Drawer(theme),
    Skeleton(theme),
    Dialog(theme),
    Card(theme),
  );


  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  )
}

export default ThemeProvider