// @mui
import { TableRow, TableCell, Checkbox, Link } from '@mui/material';

// ----------------------------------------------------------------------

const SwimmerTableRow = ({ row, onClickRow, selected, onSelectSwimmer }) => {
  const { id, name, lastSwim, ftp, formScore, currentFitness, currentFatigue } =
    row;

  return (
    <TableRow hover>
      <TableCell padding='checkbox'>
        <Checkbox
          color='primary'
          onChange={(event) => onSelectSwimmer(event.target.checked, id)}
          checked={selected}
        />
      </TableCell>
      <TableCell>
        <Link onClick={() => onClickRow(id)}>{name}</Link>
      </TableCell>

      <TableCell align='left'>{lastSwim}</TableCell>

      <TableCell align='left'>{ftp}</TableCell>

      <TableCell align='left'>{formScore}</TableCell>

      <TableCell align='left'>{currentFitness}</TableCell>

      <TableCell align='left'>{currentFatigue}</TableCell>
    </TableRow>
  );
};

export default SwimmerTableRow;
