// import PropTypes from 'prop-types';
import Fins from '../Icons/Fins'
import Paddles from '../Icons/Paddles'
import Buoy from '../Icons/Buoy'
import Kickboard from '../Icons/Kickboard'
import Snorkel from '../Icons/Snorkel'
import { secondsToHMS } from '../../util'

const iconSize = 16

const SegmentLabel = ({segment}) => {
  if (!segment) return null
  return (
    <>
      <div>{segment.distanceN}{segment.lapUnit}</div>
      <div style={{textTransform: 'capitalize'}}>{segment.stroke}</div>
      <div>{secondsToHMS(segment.duration)}</div>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        {segment.equipment.map(eq => renderEquipmentIcon(eq))}
      </div>
    </>
  );
}

// SegmentLabel.propTypes = {
//   : PropTypes.
// };

export default SegmentLabel;

const renderEquipmentIcon = eq => {
  if (eq === 'fins') {
    return <Fins key={eq} size={iconSize}/>
  } else if (eq === 'paddles') {
    return <Paddles key={eq} size={iconSize}/>
  } else if (eq === 'pullbuoy') {
    return <Buoy key={eq} size={iconSize}/>
  } else if (eq === 'kickboard') {
    return <Kickboard key={eq} size={iconSize}/>
  } else if (eq === 'snorkel') {
    return <Snorkel key={eq} size={iconSize}/>
  } else if (eq === 'bands') {
    return 'B'
  } else {
    return null
  }
}
