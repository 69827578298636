export const resolveIcon = (type) => {
	return (
		`<svg width="96" height="128" viewBox="0 0 24 64" fill="none" xmlns="http://www.w3.org/2000/svg">
			${
			type === 'right' ? (
				`<path fill-rule="evenodd" clip-rule="evenodd" d="M23.5 2.5C23.5 1.11929 22.3807 0 21 0C19.6193 0 18.5 1.11929 18.5 2.5V24C18.5 27.866 15.366 31 11.5 31H7.5C3.63401 31 0.5 34.134 0.5 38V57C0.5 60.171 2.60851 62.8496 5.5 63.7101L5.5 40C5.5 39.4477 5.94772 39 6.5 39C7.05229 39 7.5 39.4477 7.5 40L7.5 64H16.5C20.366 64 23.5 60.866 23.5 57V42V31V2.5ZM10.5 13C7.18629 13 4.5 15.6863 4.5 19V23C4.5 26.3137 7.18629 29 10.5 29C13.8137 29 16.5 26.3137 16.5 23V19C16.5 15.6863 13.8137 13 10.5 13Z" fill="#EBEBF5" fill-opacity="0.6"/>`
			) : (
				`<path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 2.5C0.5 1.11929 1.61929 0 3 0C4.38071 0 5.5 1.11929 5.5 2.5V24C5.5 27.866 8.63401 31 12.5 31H16.5C20.366 31 23.5 34.134 23.5 38V57C23.5 60.171 21.3915 62.8496 18.5 63.7101L18.5 40C18.5 39.4477 18.0523 39 17.5 39C16.9477 39 16.5 39.4477 16.5 40L16.5 64H7.5C3.63401 64 0.5 60.866 0.5 57V42V31V2.5ZM13.5 13C16.8137 13 19.5 15.6863 19.5 19V23C19.5 26.3137 16.8137 29 13.5 29C10.1863 29 7.5 26.3137 7.5 23V19C7.5 15.6863 10.1863 13 13.5 13Z" fill="#EBEBF5" fill-opacity="0.6"/>`
			)
		}
		</svg>`
	)
}

export const resolveHandIcon = (type) => {
	return (
		`<svg width="38" height="44" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
			${
			type === 'right' ? (
				`<path d="M13.4932 12.7627C13.4932 16.4233 11.0942 18.5898 7.79883 18.5898C5.15918 18.5898 3.52393 17.4858 2.43652 15.2695C1.79736 14.041 1.30762 12.5635 0.784668 10.8535C0.568848 10.1562 0.261719 9.26807 0.261719 8.86963C0.261719 8.42139 0.585449 8.12256 1.05859 8.12256C1.58154 8.12256 1.89697 8.45459 2.27881 9.24316L3.43262 11.667C3.54883 11.9326 3.68164 12.0322 3.81445 12.0322C3.98877 12.0322 4.10498 11.9326 4.10498 11.6421V2.93457C4.10498 2.44482 4.50342 2.04639 4.99316 2.04639C5.48291 2.04639 5.88135 2.44482 5.88135 2.93457V9.16016C6.10547 9.07715 6.35449 9.01074 6.62012 8.96094V1.73926C6.62012 1.24951 7.01855 0.842773 7.5083 0.842773C7.99805 0.842773 8.40479 1.24951 8.40479 1.73926V8.86963C8.66211 8.88623 8.92773 8.92773 9.18506 8.96924V2.44482C9.18506 1.95508 9.5835 1.55664 10.0732 1.55664C10.563 1.55664 10.9614 1.95508 10.9614 2.44482V9.53369C11.2354 9.6665 11.4844 9.80762 11.7085 9.95703V5.02637C11.7085 4.53662 12.1069 4.13818 12.5967 4.13818C13.0864 4.13818 13.4932 4.53662 13.4932 5.02637V12.7627Z" fill="#FF5082"/>`
			) : (
				`<path d="M0.506836 12.7627C0.506836 16.4233 2.90576 18.5898 6.20117 18.5898C8.84082 18.5898 10.4761 17.4858 11.5635 15.2695C12.2026 14.041 12.6924 12.5635 13.2153 10.8535C13.4312 10.1562 13.7383 9.26807 13.7383 8.86963C13.7383 8.42139 13.4146 8.12256 12.9414 8.12256C12.4185 8.12256 12.103 8.45459 11.7212 9.24316L10.5674 11.667C10.4512 11.9326 10.3184 12.0322 10.1855 12.0322C10.0112 12.0322 9.89502 11.9326 9.89502 11.6421V2.93457C9.89502 2.44482 9.49658 2.04639 9.00684 2.04639C8.51709 2.04639 8.11865 2.44482 8.11865 2.93457V9.16016C7.89453 9.07715 7.64551 9.01074 7.37988 8.96094V1.73926C7.37988 1.24951 6.98145 0.842773 6.4917 0.842773C6.00195 0.842773 5.59521 1.24951 5.59521 1.73926V8.86963C5.33789 8.88623 5.07227 8.92773 4.81494 8.96924V2.44482C4.81494 1.95508 4.4165 1.55664 3.92676 1.55664C3.43701 1.55664 3.03857 1.95508 3.03857 2.44482V9.53369C2.76465 9.6665 2.51562 9.80762 2.2915 9.95703V5.02637C2.2915 4.53662 1.89307 4.13818 1.40332 4.13818C0.913574 4.13818 0.506836 4.53662 0.506836 5.02637V12.7627Z" fill="#FF5082"/>`
			)
		}
		</svg>`
	)
}
