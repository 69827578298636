// @mui
import { Box, Stack, Typography, Breadcrumbs } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
//
import LinkItem from './LinkItem';

// ----------------------------------------------------------------------

const CustomBreadcrumbs = ({ links, heading, sx, ...other }) => {
  const hasLinks = !!links?.length;
  const lastLink = hasLinks && links[links.length - 1].name;

  return (
    <Box sx={{mb:2, ...sx}}>
      <Stack gap={2}>
        {/* BREADCRUMBS */}
        {hasLinks && (
          <Breadcrumbs separator={<KeyboardArrowRightIcon sx={{fontSize: 20}} />} {...other}>
            {links.map((link) => (
              <LinkItem key={link.name || ''} link={link} disabled={link.name === lastLink} />
            ))}
          </Breadcrumbs>
        )}

        {/* HEADING */}
        {heading && (
          <Typography variant="h4">
            {heading}
          </Typography>
        )}
      </Stack>
    </Box>
  );
}

export default CustomBreadcrumbs;