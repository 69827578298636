import { Typography, Link, List, ListItem, Container } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Container>
      <Typography variant="h5" align="center" gutterBottom pt={5}>
        Simma Privacy Policy
      </Typography>

      <Typography paragraph>
        This Privacy Policy of Butler88 Pty Ltd ACN 653 388 157 (
        <strong>us, we, our</strong>) sets out how we treat the personal
        information that we collect, use and disclose and our procedures
        regarding the handling of personal, including the collection, use,
        disclosure and storage of information, as well as the right of
        individuals to access and correct that information. We operate the
        Platform called Simma, which includes the website with domain name{" "}
        <Link href="http://www.getsimma.com">www.getsimma.com</Link> and
        ancillary platforms which facilitate the operation of the Simma
        applications (<strong>Platform</strong>).
      </Typography>

      <Typography paragraph>
        From time to time, we may revise or update this Privacy Policy or our
        information handling practices. If we do so, the revised Privacy Policy
        will be published on the Platform.
      </Typography>

      <Typography paragraph>
        We may collect personal information in order to conduct our business, to
        provide and market our services and to meet our legal obligations. By
        using the Platform or our services, or by providing any personal
        information to us, you consent to the collection, use and disclosure of
        your personal information as set out in this Privacy Policy.
      </Typography>

      <Typography paragraph></Typography>

      <Typography paragraph>
        <strong>The types of personal information we collect and hold</strong>
        <br />
        The types of personal information we may collect and hold includes (but
        is not limited to) personal information about:
      </Typography>

      <List>
        <ListItem>
          your name, address, contact telephone number and other contact details
          such as your email address;
        </ListItem>
        <ListItem>
          payment information (such as credit card or bank details);
        </ListItem>
        <ListItem>sensitive information as set out below;</ListItem>
        <ListItem>
          other personal information required to provide our services in
          specific cases; and
        </ListItem>
        <ListItem>details of your use of our products or services.</ListItem>
      </List>

      <Typography paragraph>
        You are not obliged to provide personal information to us. However, in
        many cases, if you do not provide personal information to us, we may not
        be able to supply the relevant functionality of the Platform.
      </Typography>

      <Typography paragraph>
        In some circumstances, you may provide to us, and we may collect from
        you, personal information about a third party. Where you provide the
        personal information of a third party, you must ensure that the third
        party is aware of this Privacy Policy, understands it and agrees to
        accept it. If you are providing us with personal or sensitive
        information on behalf of a person who is under the age of 18 that you
        are the parent or guardian of, you understand that you are consenting to
        this Privacy Policy on their behalf as their legal guardian.
      </Typography>

      <Typography paragraph>
        If it is necessary to do so, or if you decide to provide it, we may also
        collect sensitive information (<strong>Sensitive Information</strong>)
        from you. Sensitive Information includes but is not limited to
        information about or pertaining to your:
      </Typography>

      <List>
        <ListItem>health;</ListItem>
        <ListItem>gender;</ListItem>
        <ListItem>biography;</ListItem>
        <ListItem>socioeconomic status;</ListItem>
        <ListItem>
          medical, health or clinical information and records;
        </ListItem>
        <ListItem>
          medical history pursuant to forms on the Platform you may be requested
          to complete;
        </ListItem>
        <ListItem>ethnicity; or</ListItem>
        <ListItem>
          other personal, clinical or health related information, reasonably
          required by us to provide our services to you.
        </ListItem>
      </List>

      <Typography paragraph>
        We will only collect Sensitive Information from you if you agree to
        provide it to us. We will only use Sensitive Information in accordance
        with the Privacy Act and for the purposes for which it is provided. Any
        Sensitive Information that we collect will be automatically
        de-identified when it is no longer required to provide our services, and
        will remain so unless you elect to disclose it to us or another party,
        unless we state otherwise at the time of collection.
      </Typography>

      <Typography paragraph>
        <strong>How personal information is collected and held by us</strong>
        <br />
        We collect personal information in the following ways:
      </Typography>

      <List>
        <ListItem>
          when you fill in and return to us a signed client form;
        </ListItem>
        <ListItem>
          when you submit personal information through the Platform (such as
          when you send us a message or fill out an online form);
        </ListItem>
        <ListItem>
          in person, for example, when you engage with our employees, agents, or
          customer service representatives; and
        </ListItem>
        <ListItem>in the course of providing services to you.</ListItem>
      </List>

      <Typography paragraph>
        We store personal information in computer storage facilities. We take
        steps to protect your personal information against loss, unauthorised
        access, use modification or disclosure. Some examples of the steps we
        take to protect your personal information include:
      </Typography>

      <List>
        <ListItem>
          ensuring there are suitable password protection measures and access
          privileges in place to monitor and control access to our IT systems;
        </ListItem>
        <ListItem>
          imposing restrictions on physical access to paper files;
        </ListItem>
        <ListItem>
          requiring any third parties engaged by us to provide appropriate
          assurances to handle your personal information in a manner consistent
          with Australian law; and
        </ListItem>
        <ListItem>
          taking reasonable steps to destroy or de-identify personal information
          after we no longer need it for our business or to comply with the law.
        </ListItem>
      </List>

      <Typography paragraph>
        <strong>Collection of personal information through activity</strong>
        <br />
        Information that may identify you as a user may be gathered during your
        access with the Platform.
      </Typography>

      <Typography paragraph>
        The Platform may include pages that use ‘cookies’. A cookie is a unique
        identification number that allows the server to identify and interact
        more effectively with your computer or device. The cookie assists us in
        identifying what our users find interesting on the Platform.
      </Typography>

      <Typography paragraph>
        A cookie may be allocated each time you use the Platform. The cookie
        does not identify you as an individual in our data collection process,
        however it does identify your Internet Service Provider.
      </Typography>

      <Typography paragraph>
        You can configure your access to the Platform to refuse cookies. If you
        do so, you may not be able to use all or part of the Platform.
      </Typography>

      <Typography paragraph>
        <strong>Analytics and Anonymisation</strong>
        <br />
        We may use Google Analytics, a web analytics service provided by Google,
        Inc. (<strong>Google</strong>). Google Analytics uses cookies (see
        above), to help us analyse how users use the Platform. Although Google
        Analytics records data such as your geographical location, device,
        internet browser and operating system, none of this information
        personally identifies you with us.
      </Typography>

      <Typography paragraph>
        The information generated by the cookie about your use of the Platform
        will be transmitted to and stored by Google on servers in your country
        of residence. Google will use the information on behalf of us for the
        purpose of evaluating your use of the Platform, compiling reports on
        activity for us and providing us with other services relating to
        activity and internet usage. The IP address that your browser conveys
        within the scope of Google Analytics will not be associated with any
        other data held by Google. You may refuse the use of these cookies. You
        can also opt out of being tracked by Google Analytics in the future by
        downloading and installing Google Analytics Opt-out Browser Add-on for
        your current web browser:
        <Link href="http://tools.google.com/dlpage/gaoptout?hl+en">
          http://tools.google.com/dlpage/gaoptout?hl+en
        </Link>
      </Typography>

      <Typography paragraph>
        We may use other data analytics tools as permitted by Australian law
        from time to time.
      </Typography>

      <Typography paragraph>
        <strong>
          The purposes for which we collect, hold, use and disclose personal
          information
        </strong>
        <br />
        We collect, hold, use and disclose personal information for a variety of
        business purposes including:
      </Typography>

      <List>
        <ListItem>
          to provide the products or services you have requested from us;
        </ListItem>
        <ListItem>to improve our business, products and services;</ListItem>
        <ListItem>to promote our business to you;</ListItem>
        <ListItem>to market our other services or products to you;</ListItem>
        <ListItem>
          to handle and respond to your enquiries, complaints or concerns; and
        </ListItem>
        <ListItem>
          to provide personal information to third parties as set out in this
          Privacy Policy.
        </ListItem>
      </List>

      <Typography paragraph>
        We may use Sensitive Information for the purpose of providing you with
        our services. We only use your Sensitive Information for other purposes
        if:
      </Typography>

      <List>
        <ListItem>You have consented;</ListItem>
        <ListItem>
          You would reasonably expect your information to be used for that
          purpose;
        </ListItem>
        <ListItem>
          A failure in disclosing the information would result in a serious risk
          to life, health or safety; or
        </ListItem>
        <ListItem>
          Your information is required or authorised by law to be disclosed.
        </ListItem>
      </List>

      <Typography paragraph>
        <strong>Direct marketing</strong>
        <br />
        We also collect, hold, use and disclose your personal information to:
      </Typography>

      <List>
        <ListItem>
          notify you about the details of new services and products offered by
          us;
        </ListItem>
        <ListItem>
          send you our newsletters and other marketing publications;
        </ListItem>
        <ListItem>
          administer our databases for client service, marketing and financial
          accounting purposes; and
        </ListItem>
        <ListItem>
          to comply with our legal requirements regarding the collection and
          retention of information concerning the products and services that we
          provide.
        </ListItem>
      </List>

      <Typography paragraph>
        If you do not wish to disclose your personal information for the purpose
        of direct marketing or you would like to opt-out of receiving direct
        marketing communications, you can do so by contacting us using the
        contact details set out below, or by following the instructions to
        unsubscribe which are contained in a communication that you receive from
        us.
      </Typography>

      <Typography paragraph>
        <strong>Third Party Service Providers</strong>
        <br />
        We may disclose your personal information to third parties who work with
        us in our business to promote, market or improve the services that we
        provide, including:
      </Typography>

      <List>
        <ListItem>
          providers of customer relations management database services and
          marketing database services;
        </ListItem>
        <ListItem>
          marketing consultants, promotion companies and website hosts;
        </ListItem>
        <ListItem>partnered businesses; and</ListItem>
        <ListItem>consultants and professional advisers.</ListItem>
      </List>

      <Typography paragraph>
        We may also combine your personal information with information available
        from other sources, including the entities mentioned above, to help us
        provide better services to you.
      </Typography>

      <Typography paragraph>
        Where we do share information with third parties, we require that there
        are contracts in place that only allow use and disclosure of personal
        information to provide the service and that protect your personal
        information in accordance with Australian law. Otherwise, we will
        disclose personal information to others if you've given us permission,
        or if the disclosure relates to the main purpose for which we collected
        the information and you would reasonably expect us to do so.
      </Typography>

      <Typography paragraph>
        <strong>
          How we handle requests to access your personal information
        </strong>
        <br />
        You have a right to request access to your personal information which we
        hold about you and to request its correction. You can make such a
        request by contacting us using the contact details set out in this
        policy.
      </Typography>

      <Typography paragraph>
        We will respond to any such request for access as soon as reasonably
        practicable. Where access is to be given, we will provide you with a
        copy or details of your personal information in the manner requested by
        you where it is reasonable and practicable to do so.
      </Typography>

      <Typography paragraph>
        We will not charge you a fee for making a request to access your
        personal information. However, we may charge you a reasonable fee for
        giving you access to your personal information.
      </Typography>

      <Typography paragraph>
        In some cases, we may refuse to give you access to the information you
        have requested or only give you access to certain information. If we do
        this, we will provide you with a written statement setting out our
        reasons for refusal, except where it would be unreasonable to do so.
      </Typography>

      <Typography paragraph>
        <strong>
          How we handle requests to correct your personal information
        </strong>
        <br />
        We will take such steps (if any) as are reasonable in the circumstances
        to make sure that the personal information we collect, use or disclose
        is accurate, complete, up to date and relevant.
      </Typography>

      <Typography paragraph>
        If you believe the personal information we hold about you is inaccurate,
        irrelevant, out of date or incomplete, you can ask us to update or
        correct it. To do so, please contact us using the contact details listed
        below.
      </Typography>

      <Typography paragraph>
        <strong>How to contact us or make a complaint</strong>
        <br />
        If you have any questions about this Privacy Policy, if you wish to
        correct or update information we hold about you or if you wish to
        request access or correction of your personal information or Sensitive
        Information or make a complaint about a breach by us of our privacy
        obligations (including the way we have collected, disclosed or used your
        personal information or Sensitive Information), please contact:
      </Typography>
      {/* 
      <Typography paragraph>
        [insert name and/or title]
        <br />
        [insert address]
        <br />
        <Link href="mailto:coaching@getsimma.com">coaching@getsimma.com</Link>
        <br />
        [insert phone number]
      </Typography> */}

      <Typography paragraph>
        <Link href="mailto:coaching@getsimma.com">coaching@getsimma.com</Link>
      </Typography>

      <Typography paragraph>
        We will acknowledge and investigate any complaint about the way we
        manage personal information and Sensitive Information as soon as
        practicable. We will take reasonable steps to remedy any failure to
        comply with our privacy obligations.
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;
