import {useContext, useState} from "react";
import {useMutation} from "react-query";
// @mui
import {Box, Container} from "@mui/material";
// components
import {useAlertContext} from "../../../components/alert/AlertContext";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
import CoachProfileForm from "./CoachProfileForm";
import AvatarModal from "./AvatarModal";
import {CoachContext} from "../../../context";
import api from "../../../api";


const CoachProfilePage = () => {
  const [open, setOpen] = useState(false);

  const { showAlert } = useAlertContext();

  const { coach, refetch: refetchCoach } = useContext(CoachContext);

  const updateCoachProfileMutation = useMutation(api.coaching.profile.update);

  const handleUpdateCoachProfileMutation = (data) => updateCoachProfileMutation.mutate(data, {
    onSuccess: () => {
      refetchCoach()
      showAlert('Coach profile saved successfully.', {severity: 'success'});
    },
    onError: () => showAlert('Something went wrong.', {severity: 'error'}),
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <Box sx={{py: 1}}>
      <Container>
        <CustomBreadcrumbs heading="Coaching Profile" />

        <CoachProfileForm
          currentCoach={coach}
          onEditAvatar={handleOpen}
          onSubmit={handleUpdateCoachProfileMutation}
          isSubmitting={updateCoachProfileMutation.isLoading}
        />

        <AvatarModal
          open={open}
          onClose={handleClose}
          imageUrl={coach?.avatar}
          onSubmit={refetchCoach}
        />
      </Container>
    </Box>
  );
};

export default CoachProfilePage;
