import { Box, Container, Stack, styled, Typography } from '@mui/material';
import { bgGradient, pxToRem } from '../../../util';
import { Link } from 'react-router-dom';

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '100px 0',
  minHeight: 800,
  ...bgGradient({
    color: 'rgba(0,0,0, 0.2)',
    imgUrl: '/images/landing/landing-footer-bg.png'
  }),
  [theme.breakpoints.down('sm')]: {
    minHeight: 375,
    '.MuiTypography-root': {
      fontSize: pxToRem(24)
    }
  }
}));

const StyledLink = styled('a')(({ theme }) => ({
  transition: theme.transitions.create('opacity', { duration: 200 }),
  '&, img': {
    height: 50,
    width: 50
  },
  [theme.breakpoints.down('sm')]: {
    '&, img': {
      height: 30,
      width: 30
    }
  }
}));

const IconLink = ({ iconSrc, alt, ...other }) => (
  <StyledLink {...other}>
    <img src={iconSrc} alt={alt} />
  </StyledLink>
);

const LandingFooterSection = ({ form }) => {
  const renderContent = (
    <>
      <Typography sx={{ mb: 4 }} variant='h3' align='center'>
        Connect with us
      </Typography>
      <Stack sx={{ justifyContent: 'center' }} direction='row' gap={2}>
        <IconLink
          href='https://www.instagram.com/get_simma/'
          iconSrc='/images/landing/instagram.png'
          alt='instagram'
          target='_blank'
        />
        <IconLink
          href='https://www.facebook.com/people/Simma/100087385193270/'
          iconSrc='/images/landing/facebook.png'
          alt='facebook'
          target='_blank'
        />
        <IconLink
          href='mailto:info@getsimma.com'
          iconSrc='/images/landing/mail.png'
          alt='mail'
          target='_blank'
        />
      </Stack>
    </>
  );

  if (form) {
    return (
      <StyledRoot>
        <Container>
          <Stack gap={{ xs: 6, lg: 12 }}>
            {form}
            <Box>{renderContent}</Box>
          </Stack>
        </Container>
      </StyledRoot>
    );
  }

  return (
    <StyledRoot>
      <Container>{renderContent}</Container>
      <Stack sx={{ justifyContent: 'center' }} direction='row' gap={2}>
        <Link to='/privacy-policy'>Privacy Policy</Link>
        <Link to='/athlete-terms-and-conditions'>Athlete Terms of Service</Link>
        <Link to='/coach-terms-and-conditions'>Coach Terms of Service</Link>
      </Stack>
    </StyledRoot>
  );
};
export default LandingFooterSection;
