import { userApi } from "./axios";

export const getToken = async (body) => {
  const { data } = await userApi.post("auth/pubnub-token", body);
  return data;
};

export const getChannelUserData = async (channelId) => {
  const { data } = await userApi.get(`pubnub/channel-user-data/${channelId}`);
  return data;
};
