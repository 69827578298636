import { Box, Container, Stack, styled, Typography } from '@mui/material';
import { useResponsive } from '../../../hooks';

const StyledRoot = styled('div')(({ theme }) => ({
  padding: '100px 0',
  textAlign: 'center',
  backgroundColor: theme.palette.backgrounds['2t'],
  overflowX: 'hidden'
}));

const Image = styled('img')({
  height: 'auto',
  margin: 'auto',
  maxWidth: '100%'
});

const Feature = ({ title, description, imageSrc, bgImageSrc, imageWidth }) => {
  const defaultImageWidth = imageWidth || 1015;
  const renderContent = (
    <>
      <Typography sx={{ mb: 4 }} variant='h4'>
        {title}
      </Typography>
      <Typography sx={{ maxWidth: 980, mx: 'auto', mb: 4 }} variant='body1'>
        {description}
      </Typography>
    </>
  );

  if (bgImageSrc) {
    return (
      <Box>
        <Box
          sx={(theme) => ({
            position: 'relative',
            py: 10,
            '&:after': {
              content: '""',
              position: 'absolute',
              background: `no-repeat center/contain url(${bgImageSrc})`,
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              zIndex: 0
            },
            [theme.breakpoints.down('md')]: {
              py: 0,
              '&:after': {
                width: '150%',
                height: '125%',
                left: '50%',
                transform: 'translate(-50%, -25%)'
              }
            }
          })}>
          <Container sx={{ position: 'relative', zIndex: 1 }}>
            <Image sx={{ mb: 4 }} width={defaultImageWidth} src={imageSrc} alt='feature' />
          </Container>
        </Box>
        <Container sx={{ position: 'relative' }}>{renderContent}</Container>
      </Box>
    );
  }

  return (
    <Container>
      <Image sx={{ mb: 4 }} width={defaultImageWidth} src={imageSrc} alt='feature' />
      {renderContent}
    </Container>
  );
};

const LandingFeaturesSection = ({ data }) => {
  const isMobile = useResponsive('down', 'md');
  return (
    <StyledRoot>
      <Stack gap={isMobile ? 10 : 20}>
        {data.map((item, index) => (
          <Feature key={index} {...item} />
        ))}
      </Stack>
    </StyledRoot>
  );
};

export default LandingFeaturesSection;
