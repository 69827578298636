import {Box, Typography, Stack} from "@mui/material";
import {useHistory} from "react-router-dom";
import {useQuery} from "react-query";
import api from "../../../api";
import CoachCard from "../../../components/coach/CoachCard";
import {SkeletonCoachItem} from "../../../components/skeleton";


const FindCoach = () => {
  const history = useHistory();

  const {
    data,
    isLoading
  } = useQuery(
    ["coachesListFindData"],
    () => api.coaches.list({limit: 2}),
    {staleTime: 1000 * 60 * 10}
  );

  const coachData = data ?? [];

  const isNothingFound = !isLoading && !coachData.length;

  const handleOnClick = (id) => {
    history.push(`/coach/${id}`);
  }


  return (
    <Stack gap={2}>
      <Typography variant="h5">
        Find a Coach
      </Typography>

      <Box columnGap={1} rowGap={1.5} display="grid" gridTemplateColumns={{md: 'repeat(2, 1fr)'}}>
        {(isLoading && !isNothingFound ? [...Array(2)] : coachData).map((item, index) =>
          item ? (
            <CoachCard key={item.id} data={item} onClick={() => handleOnClick(item.id)}/>
          ) : (
            <SkeletonCoachItem key={index}/>
          )
        )}
      </Box>
    </Stack>
  )
}

export default FindCoach;