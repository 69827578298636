// Note: This is the stepper for the coach signup process. It is used on the landing page.

// External modules
import { useState, useEffect } from 'react';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  TextField,
  InputLabel,
  Stack
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useQuery, useMutation } from 'react-query';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useHistory } from 'react-router-dom';

// Internal modules
import api from 'api';
import { useAlertContext } from 'components/alert/AlertContext';
import { useAuthContext } from '../../../auth/useAuthContext';

// Components
import AuthRegisterForm from 'components/auth/AuthRegisterForm';
import AuthLoginForm from 'components/auth/AuthLoginForm';

const commonSteps = ['Personalize', 'Invite Athletes'];
const loginSteps = ['Login', ...commonSteps];
const registerSteps = ['Create Account', ...commonSteps];

const CoachSignupStepper = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [isLogin, setIsLogin] = useState(false);
  const [loginData, setLoginData] = useState(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const steps = isLogin ? loginSteps : registerSteps;

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box>
        {activeStep === 0 && (
          <>
            {isVerifying ? (
              <VerifyStep
                loginData={loginData}
                onSuccess={() => {
                  setActiveStep(1);
                  setIsVerifying(false);
                  setLoginData(null);
                }}
              />
            ) : isLogin ? (
              <AuthLoginForm
                onRegister={() => setIsLogin(false)}
                onVerifyEmail={() => setActiveStep(1)}
                showForgotPassword={false}
                navigateAfterLogin={false}
                afterLogin={() => setActiveStep(1)}
                createCoachProfile={true}
              />
            ) : (
              <AuthRegisterForm
                onLogin={() => setIsLogin(true)}
                onVerifyEmail={(loginData) => {
                  setLoginData(loginData);
                  setIsVerifying(true);
                }}
                createCoachProfile={true}
              />
            )}
          </>
        )}
        {activeStep === 1 && (
          <>
            <ChangeLinkStep handleNext={() => setActiveStep(2)} />
          </>
        )}

        {activeStep === 2 && (
          <>
            <InviteAthletesStep isLogin={isLogin} />
          </>
        )}
      </Box>
    </Box>
  );
};

export default CoachSignupStepper;

export const VerifyStep = ({ loginData, onSuccess }) => {
  const { login } = useAuthContext();
  const { showAlert } = useAlertContext();
  const [loading, setLoading] = useState(false);
  const [verifyWarning, setVerifyWarning] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    try {
      await login(loginData);
      onSuccess();
    } catch (error) {
      if (error.response?.status === 403) {
        setVerifyWarning(true);
      }
      showAlert(error.response?.data?.message || 'Something went wrong. Please try again.', {
        severity: 'error'
      });
    }
    setLoading(false);
  };

  const resendVerification = async () => {
    try {
      await api.auth.resendVerification({ email: loginData.email });
      setVerifyWarning(false);
      showAlert('Verification email sent!', { severity: 'success' });
    } catch (error) {
      showAlert(error.response?.data?.message || 'Something went wrong. Please try again.', {
        severity: 'error'
      });
    }
  };

  return (
    <Box p={3}>
      <Typography textAlign={'center'} variant='h6'>
        Please click on the link in the email we sent to verify your account.
      </Typography>
      <Box textAlign={'center'} pt={3}>
        <LoadingButton variant='contained' onClick={onSubmit} loading={loading}>
          I verified
        </LoadingButton>
      </Box>
      {verifyWarning && (
        <Box pt={3}>
          <Typography textAlign={'center'} fontSize='0.8rem'>
            Oops! It looks like you haven't verified your email yet.
          </Typography>
          <Typography
            textAlign={'center'}
            fontSize='0.8rem'
            color='primary'
            sx={{ cursor: 'pointer' }}
            onClick={resendVerification}>
            Resend Link
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const ChangeLinkStep = ({ handleNext }) => {
  const authorized = !!localStorage.getItem('refresh_token');
  const { showAlert } = useAlertContext();
  const [form, setForm] = useState({
    inviteCode: '',
    firstName: '',
    lastName: ''
  });
  const [errorState, setErrorState] = useState(null);
  const { data, isLoading, error, refetch } = useQuery('coachProfile', api.coaching.profile.get, {
    enabled: authorized,
    onSuccess: (data) => {
      setForm({
        inviteCode: data.inviteCode,
        firstName: data.firstName,
        lastName: data.lastName
      });
    }
  });

  const mutation = useMutation(api.coaching.profile.update, {
    onSuccess: () => {
      showAlert('Updated successfully!', { severity: 'success' });
      refetch();
      handleNext();
    },
    onError: (error) => {
      if (error.response.status === 409) {
        setErrorState(error.response.data.message);
      } else {
        showAlert(error.response?.data?.message || 'Something went wrong. Please try again.', {
          severity: 'error'
        });
      }
    }
  });

  useEffect(() => {
    if (/^[a-z0-9-_]*$/.test(form.inviteCode)) {
      setErrorState(null);
    } else {
      setErrorState('Not compatible');
    }
  }, [form.inviteCode]);

  if (error) {
    return (
      <>
        <Typography variant='h6'>Error loading coach profile</Typography>
        <Typography>Please try again in a moment.</Typography>
      </>
    );
  }

  const handleNextClick = () => {
    const updateData = {};
    if (data.inviteCode !== form.inviteCode) {
      updateData.inviteCode = form.inviteCode;
    }
    if (data.firstName !== form.firstName) {
      updateData.firstName = form.firstName;
    }
    if (data.lastName !== form.lastName) {
      updateData.lastName = form.lastName;
    }

    if (Object.keys(updateData).length > 0) {
      mutation.mutate(updateData);
    } else {
      handleNext();
    }
  };

  return (
    <Box>
      <Typography variant='body1' pt={3} pb={1}>
        Add your name so athletes will know which squad they are joining.
      </Typography>
      <Stack direction='row' spacing={2}>
        <Box>
          <InputLabel>FirstName</InputLabel>
          <TextField
            value={form.firstName}
            onChange={(e) => setForm({ ...form, firstName: e.target.value })}
            variant='outlined'
            size='small'
            sx={{ width: '100%' }}
          />
        </Box>
        <Box>
          <InputLabel>LastName</InputLabel>
          <TextField
            value={form.lastName}
            onChange={(e) => setForm({ ...form, lastName: e.target.value })}
            variant='outlined'
            size='small'
            sx={{ width: '100%' }}
          />
        </Box>
      </Stack>
      <Typography variant='body1' pt={5} pb={1}>
        We've created a magic link that you will use to connect your athletes to your squad. You can
        personalize it if you like. e.g 'spartan-swimming'
      </Typography>

      <InputLabel>Unique code</InputLabel>
      <TextField
        label={''}
        size='small'
        value={form.inviteCode}
        onChange={(e) => setForm({ ...form, inviteCode: e.target.value.toLowerCase() })}
        error={errorState}
        helperText={errorState}
        disabled={mutation.isLoading || isLoading}
      />
      <Box pt={3}>
        <InputLabel pt={3}>Your Magic Link</InputLabel>
        <Typography>
          <span style={{ opacity: 0.5 }}> {`${window.location.origin}/join-squad/`}</span>
          {`${form.inviteCode}`}
        </Typography>
      </Box>
      <StepperFooter
        onNext={handleNextClick}
        disabled={!form.inviteCode || errorState}
        loading={mutation.isLoading}
      />
    </Box>
  );
};

const StepperFooter = ({ onNext, loading, disabled, buttonText = 'Next' }) => {
  return (
    <Box pt={3} textAlign='right'>
      <LoadingButton variant='contained' onClick={onNext} loading={loading} disabled={disabled}>
        {buttonText}
      </LoadingButton>
    </Box>
  );
};

const InviteAthletesStep = ({ isLogin }) => {
  const history = useHistory();
  const { showAlert } = useAlertContext();
  const { data } = useQuery('coachProfile', api.coaching.profile.get);
  const link = `${window.location.origin}/join-squad/${data.inviteCode}`;
  const redirectPath = isLogin ? '/coaching/swimmers' : '/coaching/profile';

  return (
    <Box>
      <Typography variant='body1' pt={3} pb={1}>
        Invite swimmers by sending them the link below. You will be able to see their activity data,
        chat with them and give them workouts. They will also get access to full analytics.
      </Typography>
      <Typography sx={{ fontSize: '0.8rem', opacity: 0.6, textAlign: 'center', pb: 1 }} pt={3}>
        Click the link to copy it to your clipboard.
      </Typography>
      <Stack
        direction='row'
        alignContent='center'
        justifyContent='center'
        spacing={1}
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          navigator.clipboard.writeText(link);
          showAlert('Link copied to clipboard', { severity: 'success' });
        }}>
        <ContentCopyIcon fontSize='small' color='primary' />

        <Typography pb={3} color='primary'>
          {link}
        </Typography>
      </Stack>
      <StepperFooter onNext={() => history.push(redirectPath)} buttonText={'Enter Simma'} />
    </Box>
  );
};
