// import PropTypes from 'prop-types';

const Breaststroke = ({size = 34}) => {
  return (
    <svg width={`${size}`} height={`${size}`} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.17074 29H5.48471C7.139 29 8.14877 28.1621 8.14877 26.8032V26.7925C8.14877 25.7881 7.42904 25.0361 6.38705 24.9395V24.8428C7.16586 24.7192 7.76742 23.9995 7.76742 23.1885V23.1777C7.76742 21.9907 6.88119 21.2495 5.40951 21.2495H2.17074V29ZM5.07113 22.3291C5.90902 22.3291 6.39779 22.7319 6.39779 23.4194V23.4302C6.39779 24.1338 5.8768 24.5151 4.89389 24.5151H3.55648V22.3291H5.07113ZM5.1141 25.498C6.17221 25.498 6.7308 25.9116 6.7308 26.6958V26.7065C6.7308 27.4961 6.18832 27.9204 5.16781 27.9204H3.55648V25.498H5.1141ZM9.71805 29H11.1038V26.0835H12.6399L14.1599 29H15.7444L14.0525 25.874C14.971 25.541 15.5135 24.6924 15.5135 23.6665V23.6558C15.5135 22.1787 14.5144 21.2495 12.8494 21.2495H9.71805V29ZM11.1038 25.02V22.3721H12.6775C13.5584 22.3721 14.0901 22.877 14.0901 23.688V23.6987C14.0901 24.5312 13.5799 25.02 12.7044 25.02H11.1038ZM19.9455 29.1934C21.8039 29.1934 22.9695 28.248 22.9695 26.7388V26.7334C22.9695 25.5625 22.2873 24.9072 20.6545 24.5635L19.822 24.3862C18.8552 24.1821 18.4631 23.8384 18.4631 23.2905V23.2852C18.4631 22.6245 19.0754 22.2217 19.9348 22.2217C20.821 22.2163 21.3957 22.646 21.4924 23.2476L21.5031 23.312H22.8298L22.8244 23.2422C22.7278 21.9961 21.6589 21.0562 19.9402 21.0562C18.2644 21.0562 17.072 21.9907 17.072 23.3765V23.3818C17.072 24.5366 17.7595 25.2778 19.344 25.6108L20.1765 25.7827C21.1755 25.9976 21.5783 26.3359 21.5783 26.8945V26.8999C21.5783 27.5659 20.9231 28.0278 19.9885 28.0278C19.0056 28.0278 18.3127 27.5981 18.2483 26.9482L18.2429 26.8945H16.9001L16.9055 26.9697C17.0022 28.3071 18.1462 29.1934 19.9455 29.1934ZM26.5153 29H27.901V22.415H30.2912V21.2495H24.1305V22.415H26.5153V29Z" fill="#FF5082"/>
      <path d="M21.83 6.67763C21.2842 6.31171 20.6567 6.08588 20.0029 6.0201C19.3491 5.95432 18.6892 6.05061 18.0815 6.30046C17.4738 6.55032 16.937 6.94604 16.5185 7.45265C16.1 7.95926 15.8128 8.56117 15.6823 9.20515C15.5517 9.84913 15.5818 10.5154 15.7698 11.1449C15.9579 11.7745 16.2982 12.3481 16.7606 12.8149C17.223 13.2818 17.7934 13.6275 18.4211 13.8216C19.0489 14.0156 19.7148 14.0521 20.36 13.9276C21.1382 13.7775 21.8543 13.3996 22.4174 12.8419C22.9805 12.2841 23.3652 11.5716 23.5227 10.7949C23.6802 10.0181 23.6034 9.21206 23.3021 8.47902C23.0007 7.74598 22.4883 7.11899 21.83 6.67763Z" fill="#FF5082"/>
      <path d="M26.47 12.8777C24.29 13.7977 21.95 14.8777 19.78 15.8777C19.4011 16.0258 19.0044 16.1233 18.6 16.1677L18.91 16.3377C19.3661 16.601 19.8834 16.7396 20.41 16.7396C20.9366 16.7396 21.454 16.601 21.91 16.3377L22.91 15.7577C23.3661 15.4944 23.8834 15.3558 24.41 15.3558C24.9366 15.3558 25.454 15.4944 25.91 15.7577L27.03 16.3977L27.97 15.8877C28.3692 15.6888 28.6729 15.3394 28.8145 14.9166C28.9561 14.4937 28.9239 14.0318 28.725 13.6327C28.5261 13.2335 28.1767 12.9298 27.7538 12.7882C27.331 12.6466 26.8692 12.6788 26.47 12.8777Z" fill="#FF5082"/>
      <path d="M5.40002 16.6177L6.91003 15.7477C7.36609 15.4844 7.88342 15.3457 8.41003 15.3457C8.93664 15.3457 9.45398 15.4844 9.91003 15.7477L10.91 16.3277C11.3661 16.591 11.8834 16.7296 12.41 16.7296C12.9366 16.7296 13.454 16.591 13.91 16.3277L14.91 15.7477C15.3126 15.5256 15.7608 15.399 16.22 15.3777C15.67 13.6077 14.42 11.7077 10.9 12.3777C7.99556 13.1162 5.29515 14.5006 3 16.4277C3.13911 16.4696 3.27326 16.5267 3.40002 16.5977C3.7023 16.7763 4.04626 16.8721 4.39734 16.8756C4.74841 16.8792 5.09423 16.7902 5.40002 16.6177Z" fill="#FF5082"/>
    </svg>
  );
}

// Breaststroke.propTypes = {
//   : PropTypes.
// };

export default Breaststroke;
