import { Stack, Box, Typography } from '@mui/material';
import { useQuery, useMutation } from 'react-query';
import api from 'api';
import { timeStringToReadable } from '../../util';
import { Link } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';

const Subscriptions = () => {
  const { data: subscriptions, refetch } = useQuery(['subscriptions'], api.user.purchases.list, {
    select: (data) => {
      return data.filter((sub) => sub.vendor === 'simma');
    }
  });

  if (!subscriptions?.length) return null;

  return (
    <>
      <h2 className='caption'>SUBSCRIPTIONS</h2>
      <Stack
        sx={{
          borderRadius: '0.5rem',
          overflow: 'hidden',
          marginBottom: '1.5rem'
        }}>
        {subscriptions.map((sub) => {
          return (
            <Stack
              key={sub.purchaseId}
              direction='row'
              bgcolor={'backgrounds.2t'}
              sx={{
                justifyContent: 'space-between',
                padding: '0.75rem',
                borderBottom: '1px solid',
                borderColor: 'separator.main',
                '&:last-child': {
                  border: 'none'
                }
              }}>
              <Stack>
                {sub.vendor === 'coach' && (
                  <Typography
                    sx={{ fontSize: '11px' }}
                    component={Link}
                    to={`/coach/${sub.coachId}`}
                    color='primary'>
                    {sub.coachName}
                  </Typography>
                )}
                <Typography>{sub.title}</Typography>
                <Typography
                  sx={{
                    fontSize: '0.8rem',
                    opacity: sub.renewing ? 0.6 : 1,
                    color: sub.renewing ? '' : 'error.main'
                  }}>{`${sub.periodEndAction} on ${timeStringToReadable(
                  sub.periodEnd
                )}`}</Typography>
              </Stack>
              {!sub.isCoupon && (
                <Box>
                  <CancelRenewButton sub={sub} refetch={refetch} />
                </Box>
              )}
            </Stack>
          );
        })}
      </Stack>
    </>
  );
};
export default Subscriptions;

const CancelRenewButton = ({ sub, refetch }) => {
  const { mutate: cancel, isLoading: cancelling } = useMutation(api.user.purchases.cancel, {
    onSuccess: () => {
      refetch();
    }
  });

  const { mutate: renew, isLoading: renewing } = useMutation(api.user.purchases.renew, {
    onSuccess: () => {
      refetch();
    }
  });

  return (
    <LoadingButton
      variant='outlined'
      size='small'
      sx={{ color: 'white', borderColor: 'rgba(255,255,255,0.3)' }}
      onClick={() => {
        if (sub.renewing) {
          cancel(sub.purchaseId);
        } else {
          renew(sub.purchaseId);
        }
      }}
      loading={cancelling || renewing}>
      {sub.renewing ? 'Cancel' : 'Renew'}
    </LoadingButton>
  );
};
