import SvgIcon from '@mui/material/SvgIcon';
const BandIcon = props => {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M5 12.8211C5.01976 12.8387 5.0396 12.8564 5.05951 12.8743C5.40132 13.1806 5.76768 13.5236 6.17281 13.903L6.17283 13.903C6.92552 14.6078 7.81202 15.4379 8.92352 16.3906C10.5069 17.7477 12.6095 18.2004 14.6285 18.2965C15.6328 18.3442 16.5999 18.3031 17.4505 18.2489C17.6904 18.2336 17.9246 18.2169 18.1491 18.2009C18.4835 18.1771 18.7964 18.1548 19.0747 18.1409V12.4243C18.8734 12.4284 18.6541 12.4341 18.42 12.4403L18.4198 12.4403H18.4198C17.2172 12.4718 15.6222 12.5136 14.0479 12.3919C11.9077 12.2266 9.60393 11.752 8.24457 10.3926C7.80247 9.95054 7.40284 9.49487 7.02691 9.06622L6.87535 8.89357C6.45008 8.40997 6.05225 7.96871 5.62729 7.58847C5.42545 7.40788 5.21807 7.2415 5 7.09276V12.8211ZM18.6208 11.4341L18.3342 11.4415C17.1411 11.4729 15.6431 11.5122 14.125 11.3949C11.9964 11.2305 10.0316 10.7654 8.95168 9.68554C8.53434 9.2682 8.15733 8.83842 7.7795 8.40771L7.7795 8.4077L7.62629 8.2332C7.20164 7.75031 6.76859 7.2678 6.29409 6.84323C6.02766 6.60485 5.74757 6.38426 5.44667 6.1892C5.64939 6.17285 5.84618 6.15609 6.03781 6.13965L6.11588 6.13294C7.089 6.04939 7.91073 5.97884 8.72912 6.00586C10.3698 6.06002 12.0378 6.50843 14.735 8.1942C15.3632 8.58681 16.3337 9.30266 17.2221 10.0716C17.6652 10.4551 18.0812 10.8461 18.4204 11.211C18.4917 11.2877 18.5586 11.3621 18.6208 11.4341Z" fill="#EBEBF5" fillOpacity="0.6"/>
    </SvgIcon>
  );
}

export default BandIcon;
