import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const RunningIcon = () => {
	return (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M4.34869 29H5.73443V26.0835H7.27057L8.79059 29H10.3751L8.68316 25.874C9.60162 25.541 10.1441 24.6924 10.1441 23.6665V23.6558C10.1441 22.1787 9.14508 21.2495 7.48004 21.2495H4.34869V29ZM5.73443 25.02V22.3721H7.30816C8.18902 22.3721 8.72076 22.877 8.72076 23.688V23.6987C8.72076 24.5312 8.21051 25.02 7.33502 25.02H5.73443ZM15.0649 29.1934C17.0361 29.1934 18.25 28.0386 18.25 26.3306V21.2495H16.8643V26.2178C16.8643 27.2866 16.2197 27.9956 15.0703 27.9956C13.9155 27.9956 13.2656 27.2866 13.2656 26.2178V21.2495H11.8799V26.3306C11.8799 28.0386 13.1045 29.1934 15.0649 29.1934ZM20.2167 29H21.5541V23.6611H21.6401L25.4482 29H26.6459V21.2495H25.3139V26.5884H25.228L21.4199 21.2495H20.2167V29Z"
				fill="#1ADEA3"/>
			<path
				d="M22.2815 5.27404C22.2815 6.17832 21.5484 6.91137 20.6442 6.91137C19.7399 6.91137 19.0068 6.17833 19.0068 5.27404C19.0068 4.36975 19.7399 3.63672 20.6442 3.63672C21.5484 3.63672 22.2815 4.36975 22.2815 5.27404Z"
				fill="#1ADEA3"/>
			<path
				d="M23.6003 11.0608C23.6003 11.2965 23.5066 11.5226 23.3399 11.6893C23.1732 11.856 22.9471 11.9496 22.7114 11.9496H20.2225C20.0087 11.9522 19.8011 11.8775 19.6378 11.7395C19.4745 11.6013 19.3665 11.4089 19.3336 11.1976L19.2856 10.8794L20.7612 8.88296V8.87231L20.9639 10.1754H22.7114C22.9466 10.1754 23.1721 10.2686 23.3387 10.4345C23.5053 10.6005 23.5994 10.8257 23.6003 11.0608L23.6003 11.0608Z"
				fill="#1ADEA3"/>
			<path
				d="M14.3894 14.0348L12.8854 15.9441C12.717 16.1561 12.4611 16.2798 12.1903 16.2801H8.48899C8.17139 16.2801 7.878 16.1107 7.7192 15.8357C7.5604 15.5606 7.5604 15.2218 7.7192 14.9468C7.878 14.6717 8.17139 14.5023 8.48899 14.5023H11.7583L13.2125 12.6624C13.2402 13.0281 13.425 13.364 13.7192 13.5832L13.7334 13.5939L14.3894 14.0348Z"
				fill="#1ADEA3"/>
			<path
				d="M19.3495 10.2303L20.3308 8.90234L20.4872 8.69077H20.4873C20.5937 8.50975 20.6495 8.30344 20.6491 8.09344C20.6508 8.06798 20.6508 8.04246 20.6491 8.01699C20.647 7.94662 20.6362 7.87677 20.6171 7.80901V7.79304C20.5642 7.58581 20.4546 7.39738 20.3006 7.249C20.2093 7.1626 20.1049 7.09119 19.9913 7.03749L18.946 6.50415C18.7841 6.41897 18.6043 6.37383 18.4215 6.37256H18.2669L15.3229 6.40809V6.40815C15.0627 6.40855 14.8159 6.5229 14.6473 6.72099L12.7753 8.94142C12.6272 9.11046 12.5467 9.32828 12.5494 9.55299C12.552 9.77776 12.6375 9.99361 12.7895 10.1592C12.9639 10.3386 13.2058 10.4362 13.4559 10.4281C13.7059 10.42 13.9409 10.3069 14.1033 10.1165L15.7389 8.19121H16.7397L13.7833 11.9459L13.7069 12.0419C13.5714 12.2409 13.5177 12.4844 13.5568 12.722C13.5958 12.9596 13.7247 13.173 13.9166 13.3183L14.0215 13.3876L16.5104 15.0321L16.9762 15.3521L15.0419 18.1965C14.9102 18.3843 14.854 18.6149 14.8847 18.8422C14.9153 19.0695 15.0305 19.277 15.2073 19.4232C15.4024 19.5734 15.6511 19.6358 15.8941 19.5958C16.1371 19.5556 16.3525 19.4165 16.489 19.2116L18.9459 15.601C19.0809 15.4045 19.1313 15.162 19.0858 14.928C19.0404 14.6941 18.9028 14.4881 18.7041 14.3565L17.081 13.2899L19.2143 10.3957L19.3495 10.2303Z"
				fill="#1ADEA3"/>
			<path
				d="M19.3495 10.2303L20.3308 8.90234L20.4872 8.69077H20.4873C20.5937 8.50975 20.6495 8.30344 20.6491 8.09344C20.6508 8.06798 20.6508 8.04246 20.6491 8.01699C20.647 7.94662 20.6362 7.87677 20.6171 7.80901V7.79304C20.5642 7.58581 20.4546 7.39738 20.3006 7.249C20.2093 7.1626 20.1049 7.09119 19.9913 7.03749L18.946 6.50415C18.7841 6.41897 18.6043 6.37383 18.4215 6.37256H18.2669L15.3229 6.40809V6.40815C15.0627 6.40855 14.8159 6.5229 14.6473 6.72099L12.7753 8.94142C12.6272 9.11046 12.5467 9.32828 12.5494 9.55299C12.552 9.77776 12.6375 9.99361 12.7895 10.1592C12.9639 10.3386 13.2058 10.4362 13.4559 10.4281C13.7059 10.42 13.9409 10.3069 14.1033 10.1165L15.7389 8.19121H16.7397L13.7833 11.9459L13.7069 12.0419C13.5714 12.2409 13.5177 12.4844 13.5568 12.722C13.5958 12.9596 13.7247 13.173 13.9166 13.3183L14.0215 13.3876L16.5104 15.0321L16.9762 15.3521L15.0419 18.1965C14.9102 18.3843 14.854 18.6149 14.8847 18.8422C14.9153 19.0695 15.0305 19.277 15.2073 19.4232C15.4024 19.5734 15.6511 19.6358 15.8941 19.5958C16.1371 19.5556 16.3525 19.4165 16.489 19.2116L18.9459 15.601C19.0809 15.4045 19.1313 15.162 19.0858 14.928C19.0404 14.6941 18.9028 14.4881 18.7041 14.3565L17.081 13.2899L19.2143 10.3957L19.3495 10.2303Z"
				fill="#1ADEA3"/>
		</svg>
	);
};

export default RunningIcon;
