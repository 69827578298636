import { alpha } from '@mui/material/styles';
import { Box, Stack, styled, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useResponsive } from '../../hooks';
import { PoolSwimIcon, OpenWaterSwimIcon, RunIcon, GeneralIcon } from '../../assets/icons';
import { relativeTime, secondsToDisplayTime } from '../../util';

const StyledActivityItem = styled(Box)(({ theme }) => ({
  borderBottom: 1,
  borderStyle: 'solid',
  borderBottomColor: alpha(theme.palette.common.white, 0.14),
  transition: theme.transitions.create('border-color', { duration: 200 }),
  '&:hover': {
    borderBottomColor: 'transparent'
  },
  '> .MuiBox-root': {
    border: 1,
    borderStyle: 'solid',
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    borderRadius: Number(theme.shape.borderRadius) * 3,
    transition: theme.transitions.create(['border-color', 'background-color'], {
      duration: 200
    }),
    cursor: 'pointer',
    '&:hover': {
      borderColor: alpha(theme.palette.common.white, 0.14)
    },
    '&:active': {
      backgroundColor: alpha(theme.palette.common.white, 0.14)
    }
  },
  userSelect: 'none'
}));

const activityIcons = {
  openWaterSwim: <OpenWaterSwimIcon />,
  open: <OpenWaterSwimIcon />,
  pool: <PoolSwimIcon />,
  poolSwim: <PoolSwimIcon />,
  swimming: <PoolSwimIcon />,
  outdoorRun: <RunIcon />,
  running: <RunIcon />,
  general: <GeneralIcon />
};

const ActivityItem = ({ data, unitSystem, ...other }) => {
  const isDesktop = useResponsive('up', 'sm');
  const { name, duration, stressScore, activitySubtype, endDate, activityType } = data;

  const icon =
    activityIcons[activitySubtype] || activityIcons[activityType] || activityIcons.general;

  return (
    <StyledActivityItem {...other}>
      <Box sx={{ p: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
        {icon}
        <Box sx={{ width: '100%' }}>
          <Box
            display='flex'
            justifyContent='space-between'
            flexDirection={{ xs: 'row', sm: 'row' }}>
            <Typography variant='subtitle1' color='text.secondary' sx={{ flex: 1 }}>
              {name}
            </Typography>
            <Typography variant='subtitle1' color='text.secondary'>
              {endDate && relativeTime(endDate)}
            </Typography>
          </Box>
          <Stack
            gap={2}
            display='grid'
            gridTemplateColumns={{
              alignItems: 'center',
              xs: 'repeat(2, 1fr)',
              sm: 'repeat(3, 1fr) 20px'
            }}>
            <Box>
              {data.distanceM ? (
                <Typography variant='h4'>{getDistanceDisplay(data, unitSystem)}</Typography>
              ) : (
                <Typography variant='h4'>{Math.round(data.duration / 60)}min</Typography>
              )}
            </Box>
            {isDesktop && (
              <Box>
                {!!data.distanceM && (
                  <>
                    <Typography variant='h5'>{secondsToDisplayTime(duration, false)}</Typography>
                    <Typography variant='caption' color='text.secondary'>
                      {duration && 'Total time'}
                    </Typography>
                  </>
                )}
              </Box>
            )}
            <Box
              sx={{ display: 'flex' }}
              alignItems={{ xs: 'center', sm: 'start' }}
              flexDirection={{ xs: 'row-reverse', sm: 'column' }}
              gap={{ xs: 1, sm: 0 }}>
              {' '}
              {!!stressScore && (
                <>
                  <Typography variant='h5'>{Math.round(stressScore)}</Typography>
                  <Typography variant='caption' color='text.secondary'>
                    {stressScore && 'Training stress'}
                  </Typography>
                </>
              )}
            </Box>
            {isDesktop && <KeyboardArrowRightIcon />}
          </Stack>
        </Box>
      </Box>
    </StyledActivityItem>
  );
};

const getDistanceDisplay = (data, system) => {
  let distance = '';
  let unit = '';
  system = system || 'metric';
  if (system === 'imperial') {
    distance = data?.distanceDisplayValueImperial || distance;
    unit = data?.distanceDisplayUnitImperial || unit;
  }
  if (system === 'metric') {
    distance = data?.distanceDisplayValueMetric || distance;
    unit = data?.distanceDisplayUnitMetric || unit;
  }
  return distance + unit;
};

export default ActivityItem;
