// @mui
import { Box, Container } from '@mui/material';
// components
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';

import WorkoutsList from './WorkoutsList';

const TrainingPage = () => {
  return (
    <Box sx={{ py: 1 }}>
      <Container>
        <CustomBreadcrumbs heading='My Workouts' />
        <WorkoutsList />
      </Container>
    </Box>
  );
};

export default TrainingPage;
