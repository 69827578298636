import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

// @mui
import { Box, Container, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
// components
import PaceIcon from '../../components/Icons/PaceIcon';
import ElevationIcon from '../../components/Icons/ElevationIcon';
import StrideIcon from '../../components/Icons/StrideIcon';
import SpeedIcon from '../../components/Icons/SpeedIcon';
import HeartRatecon from '../../components/Icons/HeartRatecon';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
import Spinner from '../../components/common/Spinner';
import ActivityMap from '../../components/Activities/ActivityMap';
import ActivitySegments from '../../components/activity-segments';
import UpgradeState from '../../components/common/UpgradeState';
import UpgradeStateGraph from '../../components/common/UpgradeStateGraph';
import HeartRateZonesWidget from '../../components/common/HeartRateZonesWidget';
import ActivityFeedback from '../../components/Activities/ActivityFeedback';
import SimpleHeartRateChart from '../../components/charts/SimpleHeartRateChart';
import Metric from '../../components/Metric';
import AnalyticsTab from './AnalyticsTab';
import SwimChartsTab from './SwimChartsTab';
import RunChartsTab from './RunChartsTab';
import api from '../../api';
import { timeStringToReadable } from '../../util';
import { UserContext } from '../../context';

const ACTIVITY_TYPES = {
  open: 'open',
  pool: 'pool',
  running: 'running'
};

const SWIM_ACTIVITY_TYPES = ['swimming'];

const SWIM_METRIC_TITLES = {
  distance: 'Distance',
  pace: 'Avg. pace/Lap',
  duration: 'Total time',
  strokeRate: 'Strokes/min',
  heartRate: 'Avg. heart rate',
  maxHeartRate: 'Max heart rate',
  stressScore: 'Stress score',
  energy: 'Calories',
  activeDistance: 'Active distance'
};

const GENERAL_ACTIVITY_TITLES_ICONS = {
  elevation: {
    title: 'Elevation',
    icon: <ElevationIcon />
  },
  pace: {
    title: 'Pace',
    icon: <PaceIcon />
  },
  stride: {
    title: 'Stride',
    icon: <StrideIcon />
  },
  heartRate: {
    title: 'Heart Rate',
    icon: <HeartRatecon />
  },
  speed: {
    title: 'Speed',
    icon: <SpeedIcon />
  }
};

const ActivityHeader = ({ title, date }) => (
  <Box>
    <Typography variant='h5'>{title}</Typography>
    <Typography variant='caption' color='text.secondary'>
      {timeStringToReadable(date)}
    </Typography>
  </Box>
);

const RunActivityMetric = ({ metricsMetric }) => {
  const refactoredData = Object.entries(metricsMetric);

  return (
    <Grid container rowSpacing={6} columnSpacing={12}>
      {refactoredData.map((item, index) => (
        <Grid key={index} item sm={6} md={6} xs={12}>
          <Stack direction={'row'} gap={'12px'} alignItems={'center'} sx={{ marginBottom: '10px' }}>
            {GENERAL_ACTIVITY_TITLES_ICONS[item[0]].icon}
            <Typography variant={'h6'}>{GENERAL_ACTIVITY_TITLES_ICONS[item[0]].title}</Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            {item[1].map((i) => (
              <Metric key={i.value} value={i.value + (i.unit || '')} title={i.title} />
            ))}
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
};

const GeneralActivityMetric = ({ metricsMetric }) => {
  return (
    <Grid container rowSpacing={6} columnSpacing={12}>
      {Object.keys(metricsMetric).map((metricKey) => {
        const icon = GENERAL_ACTIVITY_TITLES_ICONS[metricKey]?.icon;
        const title = GENERAL_ACTIVITY_TITLES_ICONS[metricKey]?.title || metricKey;
        const items = metricsMetric[metricKey] || [];
        const params = items.length > 2 ? { sm: 12, xs: 12 } : { sm: 6, xs: 12 };

        return (
          <Grid key={metricKey} item {...params}>
            <Stack
              direction={'row'}
              gap={'12px'}
              alignItems={'center'}
              sx={{ marginBottom: '10px' }}>
              {icon}
              <Typography variant={'h6'}>{title}</Typography>
            </Stack>

            <Box
              display='grid'
              gridTemplateColumns={{
                ...(items.length > 2 && { sm: `repeat(4, 1fr)` }),
                xs: `repeat(2, 1fr)`
              }}>
              {items.map((item) => (
                <Metric
                  key={item.value}
                  value={item.value + (item.unit || '')}
                  title={item.title}
                />
              ))}
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

const RunActivitySummary = ({ general }) => (
  <Grid container spacing={2}>
    {general?.map((item) => (
      <Grid key={item.type} item md={3} sm={6} xs={6}>
        <Metric title={item.title} value={item.value + (item.unit || '')} />
      </Grid>
    ))}
  </Grid>
);

const SwimActivitySummary = ({ summary }) => (
  <Grid container spacing={2}>
    {summary?.map((item) => (
      <Grid key={item.type} item xs={6} sm={6} md={3}>
        <Metric title={SWIM_METRIC_TITLES[item.type] || '-'} value={item.text} />
      </Grid>
    ))}
  </Grid>
);

const SpecificActivity = ({ data, isPremium, unitSystem }) => {
  const dataWithUnitSystem = updateDataWithUnitSystem(data, unitSystem);

  const [currentTab, setCurrentTab] = useState('segments');

  const activityType = data?.activityType;
  const platform = data?.platform;

  const isShowPremiumAnalytics = !(isPremium && !data?.isPremium);

  const TABS = [
    {
      value: 'segments',
      label: 'Segments',
      component: <ActivitySegments activity={dataWithUnitSystem} />
    },
    {
      value: 'charts',
      label: 'Charts',
      component: SWIM_ACTIVITY_TYPES.includes(activityType) ? (
        <SwimChartsTab activity={dataWithUnitSystem} />
      ) : (
        <RunChartsTab activity={dataWithUnitSystem} />
      )
    },
    SWIM_ACTIVITY_TYPES.includes(activityType) &&
      platform === 'ios' &&
      isShowPremiumAnalytics && {
        value: 'analytics',
        label: 'Analytics',
        component: !isPremium ? (
          <Box sx={{ position: 'relative' }}>
            <UpgradeStateGraph />
            <Box
              sx={{
                position: 'absolute',
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }}>
              <UpgradeState />
            </Box>
          </Box>
        ) : (
          <AnalyticsTab activity={dataWithUnitSystem} />
        )
      }
  ];

  return (
    <>
      {(data.type === ACTIVITY_TYPES.open || activityType === 'running') && !!data.gps && (
        <ActivityMap activity={data} />
      )}

      <Container sx={{ py: 3 }} maxWidth='md'>
        <Stack gap={3}>
          <ActivityHeader title={dataWithUnitSystem?.name} date={dataWithUnitSystem?.endDate} />

          {SWIM_ACTIVITY_TYPES.includes(activityType) ? (
            <SwimActivitySummary summary={data.summary} />
          ) : (
            <RunActivitySummary general={dataWithUnitSystem.summaryMetric} />
          )}

          {activityType === 'running' && (
            <RunActivityMetric metricsMetric={dataWithUnitSystem.metricsMetric} />
          )}

          <Tabs
            value={currentTab}
            onChange={(event, newValue) => setCurrentTab(newValue)}
            sx={(theme) => ({
              mb: 2,
              [theme.breakpoints.up('sm')]: {
                '& .MuiButtonBase-root': {
                  width: '100%',
                  maxWidth: 160
                }
              }
            })}>
            {TABS.map((tab) => (
              <Tab key={tab.value} value={tab.value} label={tab.label} />
            ))}
          </Tabs>
          {TABS.map(
            (tab) => tab.value === currentTab && <Box key={tab.value}> {tab.component} </Box>
          )}
        </Stack>
      </Container>
    </>
  );
};

const GeneralActivity = ({ data, unitSystem }) => {
  const summary = unitSystem === 'imperial' ? data.summaryImperial : data.summaryMetric;
  const metrics = unitSystem === 'imperial' ? data.metricsImperial : data.metricsMetric;

  return (
    <>
      {data.gps && <ActivityMap activity={data} />}

      <Container sx={{ py: 3 }} maxWidth='md'>
        <Stack gap={3}>
          <ActivityHeader title={data?.name} date={data?.endDate} />

          <RunActivitySummary general={summary?.filter((item) => item.type !== 'stressScore')} />

          {metrics && <GeneralActivityMetric metricsMetric={metrics} />}

          {data.heartRateGraph && (
            <SimpleHeartRateChart
              heartRate={data.heartRateGraph.heartRate}
              timeSinceStartSec={data.heartRateGraph.timeSinceStartSec}
            />
          )}

          {!!data.timeInHeartRateZonesSec && (
            <HeartRateZonesWidget timeInHeartRateZonesSec={data.timeInHeartRateZonesSec} />
          )}
        </Stack>
      </Container>
    </>
  );
};

const ActivityDetailsPage = () => {
  const { activityId, swimmerId } = useParams();

  if (swimmerId) {
    return <CoachActivityDetailsView swimmerId={swimmerId} activityId={activityId} />;
  }

  return <UserActivityDetailsView activityId={activityId} />;
};

const CoachActivityDetailsView = ({ swimmerId, activityId }) => {
  const {
    data: swimmerData,
    isLoading: swimmerDataIsLoading,
    error: swimmerDataError
  } = useQuery(['swimmerData', swimmerId], () => api.coaching.swimmers.get(swimmerId), {
    staleTime: Infinity
  });

  const {
    data: activityData,
    isLoading: activityIsLoading,
    error: activityDataError,
    refetch
  } = useQuery(
    ['activityData', swimmerId, activityId],
    () => api.activities.get(activityId, swimmerId),
    { staleTime: 1000 * 60 * 10 }
  );

  if (activityIsLoading || swimmerDataIsLoading) {
    return <Spinner container windowHeight />;
  }

  if (activityDataError) {
    return <Box sx={{ px: 2 }}>There was an error fetching the activity</Box>;
  }

  if (swimmerDataError) {
    return <Box sx={{ px: 2 }}>There was an error fetching the swimmer's data</Box>;
  }

  return (
    <ActivityDetailDisplay
      data={activityData}
      isPremium={swimmerData.isPremium}
      unitSystem={swimmerData.preferredUnits || 'metric'}
      refetch={refetch}
      links={[
        {
          name: 'My Swimmers',
          href: '/coaching/swimmers'
        },
        {
          name: swimmerData?.name,
          href: `/coaching/swimmers/${swimmerId}`
        },
        {
          name: activityData?.name
        }
      ]}
    />
  );
};

const UserActivityDetailsView = ({ activityId }) => {
  const {
    user: { isPremium, preferredUnits }
  } = useContext(UserContext);
  const unitSystem = preferredUnits || 'metric';

  const { isLoading, error, data, refetch } = useQuery(
    ['activity', activityId],
    () => api.activities.get(activityId),
    { staleTime: 1000 * 60 * 10 }
  );

  if (isLoading) {
    return <Spinner container windowHeight />;
  }

  if (error) {
    return <Box sx={{ px: 2 }}>There was an error fetching the activity</Box>;
  }

  return (
    <ActivityDetailDisplay
      data={data}
      isPremium={isPremium}
      unitSystem={unitSystem}
      refetch={refetch}
      links={[
        {
          name: 'Activities',
          href: '/activity'
        },
        {
          name: data?.name
        }
      ]}
    />
  );
};

const ActivityDetailDisplay = ({ data, isPremium, unitSystem, links, refetch }) => {
  return (
    <Box sx={{ py: 1 }}>
      <Container>
        <CustomBreadcrumbs links={links} />
      </Container>

      {['swimming', 'running'].includes(data.activityType) ? (
        <SpecificActivity data={data} isPremium={isPremium} unitSystem={unitSystem} />
      ) : (
        <GeneralActivity data={data} unitSystem={unitSystem} />
      )}

      <ActivityFeedback activity={data} refetch={refetch} />
    </Box>
  );
};

const updateDataWithUnitSystem = (data, unitSystem) => {
  if (!unitSystem) {
    return data;
  }
  const modifiedData = { ...data };
  const summary = unitSystem === 'imperial' ? data.summaryImperial : data.summaryMetric;
  const metrics = unitSystem === 'imperial' ? data.metricsImperial : data.metricsMetric;
  modifiedData.summaryMetric = summary;
  modifiedData.metricsMetric = metrics;
  return modifiedData;
};

export default ActivityDetailsPage;
