import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import Image from '@jy95/material-ui-image';
// @mui
import {
  Box,
  Chip,
  Container,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
// icons
import DistanceIcon from '../../components/Icons/DistanceIcon';
import DurationIcon from '../../components/Icons/DurationIcon';
import LockIcon from '../../assets/icons/LockIcon';
// components
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
import ProductBanner from '../../components/coach/ProductBanner';
import WorkoutSet from '../../components/workout/WorkoutSet';
import Spinner from '../../components/common/Spinner';
import {
  environmentIcons,
  equipmentIcons,
} from '../../components/workout/utils';
import { UserContext } from '../../context';
import { useAlertContext } from '../../components/alert/AlertContext';
import api from '../../api';

const levelsData = {
  2: {
    label: 'Beginner',
    props: { color: 'success' },
  },
  3: {
    label: 'Beginner',
    props: { sx: { color: 'warning.light', borderColor: 'warning.light' } },
  },
  4: {
    label: 'Beginner',
    props: { color: 'warning' },
  },
  5: {
    label: 'Beginner',
    props: { color: 'error' },
  },
};

const IconTypography = ({ icon, variant, sx, children }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, ...sx }}>
    {icon}
    <Typography variant={variant}>{children}</Typography>
  </Box>
);

export const WorkoutInfo = ({
  title,
  distance,
  duration,
  tags,
  equipments,
  environment,
  text,
}) => (
  <Stack gap={1}>
    <Typography variant='h5'>{title}</Typography>

    <Stack direction='row' alignItems='center' gap={2}>
      <IconTypography icon={<DistanceIcon />} variant='body1'>
        {distance}
      </IconTypography>
      <IconTypography icon={<DurationIcon />} variant='body1'>
        {typeof duration === 'number'
          ? `${Math.floor(duration / 60)}-${Math.floor(duration / 60) + 5} min`
          : duration}
      </IconTypography>
      <IconTypography
        sx={{ textTransform: 'capitalize' }}
        icon={environmentIcons[environment]}
        variant='body1'
      >
        {environment}
      </IconTypography>
    </Stack>

    <Box sx={{ display: 'flex', flexFlow: 'wrap', gap: 1 }}>
      {equipments?.map((key) => {
        const EquipmentIcon = equipmentIcons[key];
        return EquipmentIcon && <EquipmentIcon key={key} />;
      })}
    </Box>

    <Box sx={{ display: 'flex', flexFlow: 'wrap', gap: 1 }}>
      {tags?.map((tag, index) => (
        <Chip key={index} label={tag} variant='outlined' size='small' />
      ))}
    </Box>

    {text && (
      <Box>
        <Typography variant='caption' color='text.secondary'>
          About this workout:
        </Typography>
        <Typography>{text}</Typography>
      </Box>
    )}
  </Stack>
);

const LockMessage = ({ coachName }) => (
  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
    <LockIcon />
    <Typography>
      This workout is locked - to access this workout subscribe to {coachName}
    </Typography>
  </Box>
);

const WorkoutSets = ({ sets }) => (
  <Stack gap={3}>
    {sets?.map((set) => (
      <WorkoutSet key={set.id} set={set} />
    ))}
  </Stack>
);

const WorkoutDetailsPage = () => {
  const { workoutId } = useParams();

  const { user } = useContext(UserContext);

  const { showAlert } = useAlertContext();

  const { data, isLoading } = useQuery(['workout', workoutId], () =>
    api.workouts.get(workoutId)
  );

  const pushWorkoutMutation = useMutation(api.garmin.pushWorkout);

  const isLocked = !!data?.locked;

  const hasGarminAccountConnected = !!user?.garminConnected;

  const handleSendWorkout = () =>
    pushWorkoutMutation.mutate(data, {
      onSuccess: () =>
        showAlert('Workout succesfully sent to Garmin Connect.', {
          severity: 'success',
        }),
      onError: () =>
        showAlert(
          'There was an error sending the workout to Garmin. Please try again.',
          { severity: 'error' }
        ),
    });

  if (isLoading) {
    return <Spinner window />;
  }

  if (!data) {
    return null;
  }

  return (
    <Box sx={{ py: 1 }}>
      <Container>
        <CustomBreadcrumbs
          links={[
            {
              name: 'Training',
              href: '/training',
            },
            {
              name: `${data.coach.firstName} ${data.coach.lastName}`,
              href: `/coach/${data.coach.id}`,
            },
            {
              name: data.title,
            },
          ]}
        />

        <Box
          sx={{
            display: 'flex',
            minHeight: { lg: 1 },
            gap: 4,
          }}
          flexDirection={{ xs: 'column', md: 'row' }}
        >
          <Stack alignItems='center' gap={2}>
            <Box sx={{ width: 160, height: 160 }}>
              <Image
                src={data.imageThumbnailUrl}
                style={{ background: 'none' }}
                cover
              />
            </Box>
            {data?.levels && (
              <Chip
                label={levelsData[data?.levels[0]].label}
                variant='outlined'
                {...levelsData[data?.levels[0]].props}
                size='small'
              />
            )}
          </Stack>

          <Stack flexGrow={1} gap={2}>
            <WorkoutInfo
              title={data.title}
              distance={data.distance + (user?.lapUnit || '')}
              duration={data.durationDisplay}
              tags={data.tags}
              environment={data.environment}
              equipments={data.equipment}
              text={data.briefing}
            />

            <Divider />

            {!isLocked && hasGarminAccountConnected && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <LoadingButton
                  sx={(theme) => ({
                    [theme.breakpoints.down('sm')]: { width: '100%' },
                  })}
                  variant='contained'
                  onClick={handleSendWorkout}
                  loading={pushWorkoutMutation.isLoading}
                >
                  Send to Garmin Connect
                </LoadingButton>
                <Typography variant='body1'>
                  This workout can be sent to your Apple Watch from the Simma
                  iOS application.
                </Typography>
              </Box>
            )}

            {!isLocked && <WorkoutSets sets={data.sets} />}

            {isLocked && (
              <Stack gap={1}>
                <LockMessage
                  coachName={`${data.coach?.firstName} ${data.coach?.lastName}`}
                />
                <SubscribeBox coachId={data.coach.id} />
              </Stack>
            )}
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

const SubscribeBox = ({ coachId }) => {
  const [product, setProduct] = useState(null);
  useQuery(['products', coachId], () => api.coaches.getCoachProducts(coachId), {
    onSuccess: (data) => {
      const workoutSubscriptionProduct = data.find(
        (product) => product.id === `SIMMA-WORKOUTS-SUB_${coachId}`
      );
      setProduct(workoutSubscriptionProduct);
    },
  });
  if (!product) return null;
  return (
    <Box mt={6}>
      <ProductBanner
        title={'Subscribe to access workout'}
        product={product}
        coachId={coachId}
        productType='COACH_WORKOUTS_SUBSCRIPTION'
        showDescription={false}
      />
    </Box>
  );
};

export default WorkoutDetailsPage;
