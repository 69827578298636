import { useMemo, useState } from 'react';
import { useQueries, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
// @mui
import {
  Box,
  Container,
  LinearProgress,
  Table,
  TableBody,
  TableContainer,
  Typography,
  Stack
} from '@mui/material';
// components
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSkeleton,
  useTable
} from '../../../components/table';
import { SwimmerTableRow } from './list';
import { secondsToHMS, timeDifference } from '../../../util';
import { applyFilter } from './utils';
import api from '../../../api';
import SwimmerReportsButton from './components/SwimmerReportsButton';
import SwimmersInviteButton from './components/SwimmersInviteButton';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', align: 'left' },
  // { id: 'email', label: 'Email', align: 'left' },
  { id: 'lastSwim', label: 'Last Swim', align: 'left' },
  { id: 'ftp', label: 'FTP', align: 'left' },
  { id: 'formScore', label: 'Current Form', align: 'left' },
  { id: 'currentFitness', label: 'Current Fitness', align: 'left' },
  { id: 'currentFatigue', label: 'Current Fatigue', align: 'left' }
];

const NotFoundMessage = () => (
  <Box sx={{ mb: 2 }}>
    <Typography variant='h6'>You do not have any swimmers yet.</Typography>
    {/* <Typography variant='body2'>
      When swimmers buy a chat or squad subscription with you they will appear here.
    </Typography> */}
    <Typography variant='body2'>
      Click the invite swimmers button and send your link to your swimmers to add them to your
      squad.
    </Typography>
  </Box>
);

const SwimmersListPage = () => {
  const history = useHistory();

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage
  } = useTable();

  const {
    isLoading,
    error,
    data: coachSwimmersData
  } = useQuery('coachSwimmers', api.coaching.swimmers.list);

  const swimmerQueries = useQueries(
    (coachSwimmersData || []).map((swimmerId) => ({
      queryKey: ['swimmer', swimmerId],
      queryFn: () => api.coaching.swimmers.get(swimmerId),
      staleTime: Infinity
    }))
  );

  const [selectedSwimmerIds, setSelectedSwimmerIds] = useState([]); // [swimmerId, ...]

  const tableData = useMemo(
    () =>
      swimmerQueries?.map(({ data }, index) => ({
        id: coachSwimmersData[index],
        name: coachSwimmersData[index],
        ...getRowData(data)
      })) || [],
    [coachSwimmersData, swimmerQueries]
  );

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(order, orderBy)
  });

  const denseHeight = dense ? 33 : 53;

  const isNotFound = !isLoading && !coachSwimmersData?.length;

  const handleOnClickRow = (id) => {
    history.push(`/coaching/swimmers/${id}`);
  };

  const handleSelectAll = (event) => {
    setSelectedSwimmerIds(event.target.checked ? coachSwimmersData : []);
  };

  const handleSelectSwimmer = (selected, id) => {
    let newSelectedSwimmerIds;
    if (!selected) {
      newSelectedSwimmerIds = selectedSwimmerIds.filter((item) => item !== id);
    } else {
      newSelectedSwimmerIds = [...selectedSwimmerIds, id];
    }
    setSelectedSwimmerIds(newSelectedSwimmerIds);
  };

  if (error) {
    return <Box sx={{ px: 2 }}>There was an error fetching the activity</Box>;
  }

  return (
    <Box sx={{ py: 1 }}>
      <Container>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <CustomBreadcrumbs heading='My Athletes' />
          <Stack direction='row' spacing={1}>
            <SwimmerReportsButton selectedSwimmerIds={selectedSwimmerIds} />
            <SwimmersInviteButton />
          </Stack>
        </Stack>

        {isNotFound && <NotFoundMessage />}

        <TableContainer sx={{ position: 'relative', overflowX: 'auto' }}>
          {isLoading && <LinearProgress />}

          <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              onSort={onSort}
              showSelectAll
              handleSelectAll={handleSelectAll}
            />

            <TableBody>
              {dataFiltered
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) =>
                  !!swimmerQueries?.[index]?.isLoading ? (
                    <TableSkeleton key={row.id} />
                  ) : (
                    <SwimmerTableRow
                      key={row.id}
                      row={row}
                      onClickRow={handleOnClickRow}
                      selected={selectedSwimmerIds.indexOf(row.id) !== -1}
                      onSelectSwimmer={handleSelectSwimmer}
                    />
                  )
                )}

              <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
              />

              <TableNoData isNotFound={isNotFound} />
            </TableBody>
          </Table>
        </TableContainer>

        <TablePaginationCustom
          count={dataFiltered.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          labelRowsPerPage='Per page:'
          //
          dense={dense}
          onChangeDense={onChangeDense}
        />
      </Container>
    </Box>
  );
};

const parseFormScoreText = (score) => {
  if (score < -30) {
    return 'straining';
  } else if (score < -10) {
    return 'grinding';
  } else if (score < 15) {
    return 'coasting';
  } else if (score < 25) {
    return 'tapering';
  } else {
    return 'wasting';
  }
};

const getRowData = (row) => {
  if (!row) {
    return {};
  }
  return {
    name: row?.name,
    lastSwim: row?.lastActivity?.endDate && timeDifference(row.lastActivity.endDate, true),
    ftp: row?.ftp && secondsToHMS(row.ftp),
    formScore: row?.formScore && `${row.formScore.toFixed(1)} ${parseFormScoreText(row.formScore)}`,
    currentFitness: row?.fitnessScore && Math.round(row.fitnessScore),
    currentFatigue: row?.fatigueScore && Math.round(row.fatigueScore)
  };
};

export default SwimmersListPage;
