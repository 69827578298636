import { useState } from "react";
import {
	Box,
	Typography,
	Table,
	TableBody,
	TableContainer,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Button,
	Stack,
} from "@mui/material";
import { useQuery, useMutation } from "react-query";
import api from "api";
import ConfirmDialog from "../../components/ConfirmDialog";
import Spinner from "components/common/Spinner";
import Modal from "components/Modal";
import { Link } from "react-router-dom";

const Admin = () => {
	const [rows, setRows] = useState([]);
	const [deleteCoach, setDeleteCoach] = useState(null);
	const [open, setOpen] = useState(false);

	const { refetch, isLoading } = useQuery(
		["adminCoaches"],
		api.admin.coaches.list,
		{
			onSuccess: (data) => {
				setRows(
					data.map((coach) => ({
						...coach,
						name: `${coach.firstName} ${coach.lastName}`,
						location: coach.location?.address_components?.find((component) =>
							component.types.includes("country")
						)?.long_name,
					}))
				);
			},
		}
	);

	const mutation = useMutation(api.admin.coaches.remove, {
		onSuccess: () => {
			setDeleteCoach(null);
			refetch();
		},
	});

	const handleDeletCoach = () => {
		mutation.mutate(deleteCoach.id);
	};

	return (
		<>
			<Box>
				<Stack direction="row" sx={{ justifyContent: "space-between" }} pb={3}>
					<Typography variant="h4">Coaches</Typography>
					<Button
						onClick={() => setOpen(true)}
						variant="contained"
						size="small"
					>
						Invite Coach
					</Button>
				</Stack>

				<Box>
					<TableContainer>
						<Table sx={{ minWidth: 650 }} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>Name</TableCell>
									<TableCell align="left">Email</TableCell>
									<TableCell align="left">Location</TableCell>
									<TableCell align="right">Workouts</TableCell>
									<TableCell align="right">Workout Subs</TableCell>
									<TableCell align="right">Squad Subs</TableCell>
									<TableCell align="right">Chat Subs</TableCell>

									<TableCell align="right"></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{isLoading ? (
									<Spinner />
								) : (
									rows.map((row) => (
										<TableRow
											key={row.id}
											sx={{
												"&:last-child td, &:last-child th": { border: 0 },
											}}
										>
											<TableCell component="th" scope="row">
												<Link to={`/coach/${row.id}`}>{row.name}</Link>
											</TableCell>
											<TableCell align="left">
												{row.coachEmail || row.email}
											</TableCell>
											<TableCell align="left">{row.location}</TableCell>
											<TableCell align="right">{row.workoutsCount}</TableCell>
											<TableCell align="right">
												{row.workoutsSubCount}
											</TableCell>
											<TableCell align="right">{row.squadSubCount}</TableCell>
											<TableCell align="right">{row.chatSubCount}</TableCell>

											<TableCell align="right">
												<Button onClick={() => setDeleteCoach(row)}>
													Delete
												</Button>
											</TableCell>
										</TableRow>
									))
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Box>

			<ConfirmDialog
				open={!!deleteCoach}
				title={`Permanently delete ${deleteCoach?.name} as a coach?`}
				text="This cannot be undone. Their profile, swimmer relationships, sets, workouts and programs will all be permanently deleted."
				confirmText="Delete Coach"
				handleConfirm={handleDeletCoach}
				handleClose={() => setDeleteCoach(null)}
				verifyText={deleteCoach?.name.trim()}
				verifyLabel="Type coach name to confirm deletion"
			/>
			<Modal open={open} handleClose={() => setOpen(false)}>
				<CreateCoachField
					onSuccess={() => {
						refetch();
						setOpen(false);
					}}
				/>
			</Modal>
		</>
	);
};
export default Admin;

const CreateCoachField = ({ onSuccess }) => {
	const [email, setEmail] = useState("");
	const { mutate } = useMutation(api.admin.coaches.create, {
		onSuccess: () => {
			setEmail("");
			onSuccess();
		},
		onError: (error) => {
			console.log("ERROR: ", error);
			alert("There was an error creating the coach. Check the console");
		},
	});
	const handleSubmit = () => {
		mutate({ email });
	};
	return (
		<Stack p={"24px"}>
			<Typography variant="h5" mb={2}>
				Invite Coach
			</Typography>
			<TextField
				label="email"
				value={email}
				onChange={(event) => setEmail(event.target.value)}
			/>
			<Box pt={2}>
				<Button
					onClick={handleSubmit}
					variant="contained"
					pt={2}
					disabled={!email}
				>
					Add coach
				</Button>
			</Box>
		</Stack>
	);
};
