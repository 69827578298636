import useStyles from "./index.styles";

import { Box, Stack, Typography } from "@mui/material";

import { resolveEfficiencyPullForceText, resolveTextColor } from "../../utils";
import CrossoverRadialGraph from "./CrossoverRadialGraph";

import { HandIcon } from "../../../../assets/icons";

const Crossover = ({ data, hand, crossover, isMobile }) => {
  const { classes } = useStyles({ isMobile });

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Typography variant={isMobile ? "h5" : "h4"}>Crossover</Typography>
        <Typography variant={"body2"} className={classes.greyText}>
          Crossover measure how far from being inline with your shoulder your
          wrist is at the point of entry into the water. It is measured in
          degrees with 0 being in line with your shoulder.{" "}
        </Typography>
      </Box>
      <Box className={classes.contentContainer}>
        <Box>
          {/* <Box className={classes.subHeader}>
            <Typography variant={"h6"}>Analysis</Typography>
            <Typography variant={"body2"} className={classes.greyText}>
              Data is compared to your "normal"
            </Typography>
          </Box> */}
          <Box className={classes.buttonsContainer}>
            <Box className={classes.handContainer}>
              <HandIcon type={hand} />
              <Typography className={classes.handText}>
                {hand.charAt(0).toUpperCase() + hand.slice(1)} hand
              </Typography>
            </Box>
          </Box>
          <Box className={classes.statsWrapper}>
            <Box className={classes.statsContainer}>
              <Stack>
                <Typography variant={"h5"}>
                  {`${data[crossover].mean}°`}
                </Typography>
                <Typography
                  variant={"caption"}
                  sx={{ color: "rgba(235, 235, 245, 0.6)" }}
                >
                  Avg. crossover
                </Typography>
              </Stack>
              <Stack>
                <Typography
                  sx={{
                    color: resolveTextColor(data[crossover].similarityColor),
                  }}
                  variant={"h5"}
                >
                  {`${data[crossover].scorePercent}%`}
                </Typography>
                <Typography
                  variant={"caption"}
                  sx={{ color: "rgba(235, 235, 245, 0.6)" }}
                >
                  In target range
                </Typography>
              </Stack>
            </Box>
          </Box>
          <Box className={classes.chartContainer}>
            <CrossoverRadialGraph
              hand={hand}
              data={data.crossoverAngle}
              rotation={data.crossoverAngle.mean}
              bins={data.crossoverAngle.bins.values}
            />
          </Box>
        </Box>
        <Stack gap={"8px"}>
          <Typography variant={"h6"}>About Crossover</Typography>
          <Typography variant={"body1"} className={classes.greyText}>
            Crossover causes inefficiency in the pull through, affects your
            ability to swim in a straight line and in excess can lead to
            shoulder injury.
            <br />
            <br />
            Think about your hand at entry, is it in line with your shoulder out
            in front of you?
            <br />
            <br />
            The data above shows you your average crossover for this swim and
            the % of strokes that were in the target range for good swimming.
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

export default Crossover;
