import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Box, Dialog } from '@mui/material';
import { useParams } from 'react-router-dom';
import LandingHeroSection from './Landing/sections/LandingHeroSection';
import { StyledRoot } from './Landing/LandingPage';
import logo from '../images/logo.svg';
import AuthLoginForm from 'components/auth/AuthLoginForm';
import AuthRegisterForm from 'components/auth/AuthRegisterForm';
import AuthResetPasswordForm from 'components/auth/AuthResetPasswordForm';
import AuthVerifyCodeForm from 'components/auth/AuthVerifyCodeForm';
import AuthNewPasswordForm from 'components/auth/AuthNewPasswordForm';
// import AuthVerifyEmailForm from 'components/auth/AuthVerifyEmailForm';
import { useQuery } from 'react-query';
import api from 'api';
import { Link } from 'react-router-dom';
import { VerifyStep } from './Landing/components/CoachSignupStepper';

const defaultDialogsState = {
  login: false,
  register: false,
  forgotPassword: false,
  verifyCodeForm: false,
  verifyEmailForm: false,
  newPasswordForm: false
};

const JoinCoachSquad = () => {
  const { inviteCode } = useParams();
  const [loginData, setLoginData] = useState(null);
  const history = useHistory();

  const { data: coach } = useQuery(['coachPublic', inviteCode], () =>
    api.coaches.getPublic(inviteCode)
  );

  const [openDialogs, setOpenDialogs] = useState(defaultDialogsState);

  const handleOnLogin = () => setOpenDialogs({ ...defaultDialogsState, login: true });
  const handleOnRegister = () => setOpenDialogs({ ...defaultDialogsState, register: true });
  const handleOnForgotPassword = () =>
    setOpenDialogs({ ...defaultDialogsState, forgotPassword: true });
  const handleOnResetPassword = () =>
    setOpenDialogs({ ...defaultDialogsState, verifyCodeForm: true });
  const handleOnVerifyEmail = () =>
    setOpenDialogs({ ...defaultDialogsState, verifyEmailForm: true });
  const handleOnClose = () => setOpenDialogs(defaultDialogsState);

  const createCoachNameString = () => {
    if (!coach) return null;
    if (!coach.firstName && !coach.lastName) return null;
    let name = 'by';
    if (coach.firstName) name += ` ${coach.firstName}`;
    if (coach.lastName) name += ` ${coach.lastName}`;
    return name;
  };

  return (
    <>
      <StyledRoot>
        <Box sx={{ position: 'fixed', zIndex: 1 }} p={3} component={Link} to={'/'}>
          <img src={logo} alt='logo' />
        </Box>
        <LandingHeroSection
          title={[`You've been invited to join Simma`, createCoachNameString()]}
          action={
            <Button onClick={handleOnRegister} variant='contained'>
              Get Started
            </Button>
          }
        />
      </StyledRoot>

      <Dialog
        fullWidth
        sx={{ maxWidth: 440, m: 'auto' }}
        open={openDialogs.register}
        onClose={handleOnClose}>
        <AuthRegisterForm
          onLogin={handleOnLogin}
          onVerifyEmail={(loginData) => {
            setLoginData(loginData);
            handleOnVerifyEmail();
          }}
          coachInviteCode={inviteCode}
        />
      </Dialog>

      <Dialog
        fullWidth
        sx={{ maxWidth: 440, m: 'auto' }}
        open={openDialogs.login}
        onClose={handleOnClose}>
        <AuthLoginForm
          onRegister={handleOnRegister}
          onForgotPassword={handleOnForgotPassword}
          coachInviteCode={inviteCode}
        />
      </Dialog>

      <Dialog
        fullWidth
        sx={{ maxWidth: 440, m: 'auto' }}
        open={openDialogs.forgotPassword}
        onClose={handleOnClose}>
        <AuthResetPasswordForm onResetPassword={handleOnResetPassword} />
      </Dialog>

      <Dialog
        fullWidth
        sx={{ maxWidth: 440, m: 'auto' }}
        open={openDialogs.verifyCodeForm}
        onClose={handleOnClose}>
        <AuthVerifyCodeForm onVerify={handleOnLogin} />
      </Dialog>

      <Dialog
        fullWidth
        sx={{ maxWidth: 440, m: 'auto' }}
        open={openDialogs.newPasswordForm}
        onClose={handleOnClose}>
        <AuthNewPasswordForm />
      </Dialog>

      <Dialog
        fullWidth
        sx={{ maxWidth: 440, m: 'auto' }}
        open={openDialogs.verifyEmailForm}
        onClose={handleOnClose}>
        {/* <AuthVerifyEmailForm onContinue={handleOnLogin} /> */}
        <VerifyStep
          loginData={loginData}
          onSuccess={() => {
            setLoginData(null);
            handleOnClose();
            history && history.push('/progress');
          }}
        />
      </Dialog>
    </>
  );
};

export default JoinCoachSquad;
