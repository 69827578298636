
// import PropTypes from 'prop-types';
import { CHART_CONSTANTS } from './chartUtilities'
const labelHeight = CHART_CONSTANTS.labelHeight

const ReferenceLabel = ({
  viewBox,
  active,
  children
}) => {
  const labelWidth = 50
  const x = viewBox.x + (viewBox.width / 2) - (labelWidth / 2)
  const y = viewBox.height + 4

  if (active || viewBox.width >= labelWidth) {
    return (
      <g>
        <foreignObject x={x} y={y} width={labelWidth} height={labelHeight}>
            <div className="chart-label" style={{fontSize: '.7rem', textAlign: 'center'}}>
              {children}
            </div>
        </foreignObject>
      </g>
    )
  }
  return null
}

// ReferenceLabel.propTypes = {
//   : PropTypes.
// };

export default ReferenceLabel;
