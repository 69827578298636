import {useMemo} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
// @mui
import {
  Avatar,
  Box,
  ButtonBase,
  Card,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import {alpha} from "@mui/material/styles";
// icons
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import LaunchIcon from "@mui/icons-material/Launch";
import LoadingButton from "@mui/lab/LoadingButton";
// components
import FormProvider, {RHFTextField} from "../../../components/hook-form";
import {
  FocusAreasCheckboxGroup,
  FullNameTextField,
  LevelsCheckboxGroup,
  SearchableSwitch,
} from "./formFields";
import {coachingLevels} from "../../../components/coach/utils";
import LocationInput from "../../../components/coachProfile/LocationInput";

const LINK_PATTERNS = {
  instagram:
    /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/,
  facebook:
    /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/,
  website:
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
};

const StyledCard = styled(Card)(({theme}) => ({
  backgroundColor: alpha(theme.palette.common.white, 0.05),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

const StyledSocialIconWrap = styled(Box)(({theme}) => ({
  minWidth: 36,
  minHeight: 36,
  color: theme.palette.background.default,
  backgroundColor: theme.palette.common.white,
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const focusAreas = [
  "Masters",
  "Youth",
  "Open Water",
  "Triathlon",
  "IronPerson",
  "Water Confidence",
  "New Swimmers",
  "Technique",
];

const CoachProfileForm = (
  {
    currentCoach,
    onEditAvatar,
    onSubmit,
    isSubmitting,
  }
) => {
  const UpdateCoachProfileSchema = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    location: Yup.object().nullable(),
    locationDisplay: Yup.string().nullable(),
    description: Yup.string(),
    coachEmail: Yup.string().email("Email must be a valid email address"),
    levels: Yup.array(),
    focusAreas: Yup.array().max(3),
    coachLinks: Yup.object().shape({
      website: Yup.string().matches(LINK_PATTERNS.website, {
        message: "Website must be a valid URL",
        excludeEmptyString: true,
      }),
      instagram: Yup.string().matches(LINK_PATTERNS.instagram, {
        message: "Instagram must be a valid URL",
        excludeEmptyString: true,
      }),
      facebook: Yup.string().matches(LINK_PATTERNS.facebook, {
        message: "Facebook must be a valid URL",
        excludeEmptyString: true,
      }),
    }),
  });

  const defaultValues = useMemo(
    () => ({
      avatar: currentCoach?.avatar || "",
      firstName: currentCoach?.firstName || "",
      lastName: currentCoach?.lastName || "",
      description: currentCoach?.description || "",
      focusAreas: currentCoach?.focusAreas || [],
      location: currentCoach?.location || {},
      locationDisplay: currentCoach?.locationDisplay || "",
      levels: currentCoach?.levels || [],
      searchable: currentCoach?.searchable || false,
      coachLinks: currentCoach?.coachLinks || {
        website: "",
        instagram: "",
        facebook: "",
      },
      coachEmail: currentCoach?.coachEmail || "",
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentCoach]
  );

  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(UpdateCoachProfileSchema),
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    formState: {isDirty, isValid},
  } = methods;

  const handleOnSubmit = (data) => {
    if (
      !!data?.coachLinks?.website &&
      !data.coachLinks.website.includes("https://")
    ) {
      data.coachLinks.website = "https://" + data.coachLinks.website;
    }
    onSubmit(data);
  };


  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(handleOnSubmit)}>
      <Stack gap={1.5}>
        <Box
          sx={{
            p: 1.5,
            gap: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: {xs: "column", md: "row"},
          }}
        >
          <Stack direction="row" gap={2}>
            <Avatar
              component={ButtonBase}
              onClick={onEditAvatar}
              alt={`${currentCoach?.firstName} ${currentCoach?.lastName}`}
              src={currentCoach?.avatar}
              sx={{
                height: 100,
                width: 100,
                ".MuiSvgIcon-root": {width: 20},
              }}
              imgProps={{crossOrigin: "anonymous"}}
            />
            <Box display="flex" flexDirection="column" justifyContent="center">
              <FullNameTextField control={control}/>
              <LocationInput optional="true"/>
            </Box>
          </Stack>

          <Stack
            direction={{sm: "row", xs: "column"}}
            alignItems="center"
            gap={2}
          >
            <SearchableSwitch
              control={control}
              name="searchable"
              label="People can search for me on Simma"
            />
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              disabled={!isValid || !isDirty}
            >
              save profile
            </LoadingButton>
          </Stack>
        </Box>

        <StyledCard>
          <Typography variant="h5">Coaching Bio</Typography>
          <RHFTextField
            name="description"
            label="Add details about your coaching experience and the type of people you have experience working with."
            variant="filled"
            multiline
          />
        </StyledCard>

        <StyledCard>
          <Box>
            <Typography variant="h5">Coaching Focus and Levels</Typography>
            <Typography variant="body2" color="text.secondary">
              Tell people on Simma what areas you focus on and the level of
              athlete you like to work with.{" "}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1">Coaching Levels</Typography>
            <LevelsCheckboxGroup
              control={control}
              name="levels"
              options={Object.keys(coachingLevels).map((key) => ({
                value: Number(key),
                label: coachingLevels[key],
              }))}
              sx={{"&.Mui-error .MuiCheckbox-root": {color: "error.main"}}}
            />
            <Typography variant="caption">
              Pick up to 3 swimmer level you work with or set to ‘All Levels’
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1">Focus Areas</Typography>
            <FocusAreasCheckboxGroup
              name="focusAreas"
              options={focusAreas.map((value) => ({
                value,
                label: value,
              }))}
              sx={{"&.Mui-error .MuiCheckbox-root": {color: "error.main"}}}
            />
            <Typography variant="caption">
              Pick 3 areas you like to focus on with your coaching.
            </Typography>
          </Box>
        </StyledCard>

        <StyledCard>
          <Box>
            <Typography variant="h5">Social Links</Typography>
            <Typography variant="body2" color="text.secondary">
              Add the URL for your profile on each of these social networks so
              people can link back to these sites from Simma.
            </Typography>
          </Box>
          <Stack direction="row" alignItems="center" gap={2}>
            <StyledSocialIconWrap>
              <InstagramIcon/>
            </StyledSocialIconWrap>
            <RHFTextField
              name="coachLinks.instagram"
              label="Instagram URL"
              variant="filled"
            />
          </Stack>
          <Stack direction="row" alignItems="center" gap={2}>
            <StyledSocialIconWrap>
              <FacebookIcon/>
            </StyledSocialIconWrap>
            <RHFTextField
              name="coachLinks.facebook"
              label="Facebook URL"
              variant="filled"
            />
          </Stack>
          <Stack direction="row" alignItems="center" gap={2}>
            <StyledSocialIconWrap>
              <EmailIcon/>
            </StyledSocialIconWrap>
            <RHFTextField
              name="coachEmail"
              label="Email Address"
              variant="filled"
            />
          </Stack>
          <Box>
            <Typography variant="h5">Website</Typography>
            <Typography variant="body2" color="text.secondary">
              Add the URL for the website you want to include on your Simma
              profile for people to link back to from Simma.{" "}
            </Typography>
          </Box>
          <Stack direction="row" alignItems="center" gap={2}>
            <Box minWidth={36} textAlign="center">
              <LaunchIcon/>
            </Box>
            <RHFTextField
              name="coachLinks.website"
              label="Website URL"
              variant="filled"
            />
          </Stack>
        </StyledCard>
      </Stack>
    </FormProvider>
  );
};

export default CoachProfileForm;
