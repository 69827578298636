import { useContext } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { secondsToHMS } from '../../util';
import { UserContext } from 'context';
import Card from '../../pages/Progress/components/Card';
import { getFTPText } from './utils';

const ProgressFTPCard = ({ title, functionalThresholdPace, ftpAccuracy, activityType }) => (
  <Card title={title} titleVariant={'h5'}>
    <Typography sx={{ fontSize: '28px' }}>
      {secondsToHMS(functionalThresholdPace)}
      {activityType === 'running' ? '/km' : '/100m'}
    </Typography>
    <Box style={{ display: 'flex' }}>
      <Box className={`ftp-bar${!!ftpAccuracy && ftpAccuracy > 0 ? '--' + ftpAccuracy : ''}`} />
      <Box className={`ftp-bar${!!ftpAccuracy && ftpAccuracy > 1 ? '--' + ftpAccuracy : ''}`} />
      <Box className={`ftp-bar${!!ftpAccuracy && ftpAccuracy > 2 ? '--' + ftpAccuracy : ''}`} />
    </Box>

    <Typography className='small-text' style={{ paddingTop: '1rem' }}>
      FTP estimation:{' '}
      <Box className={`ftp-text${ftpAccuracy ? '--' + ftpAccuracy : ''}`}>
        {getFTPText(ftpAccuracy, activityType)}
      </Box>
    </Typography>
    <Typography className='small-text' style={{ paddingTop: '1rem' }}>
      Your FTP is estimated based on qualifying segments of your swims in post processing.
    </Typography>

    {ftpAccuracy < 3 && (
      <>
        <Typography className='small-text' style={{ paddingTop: '1rem' }}>
          Manually setting your FTP in your profile will better scale workouts and personalise your
          swim analytics.
        </Typography>
        <Typography className='small-text' style={{ paddingTop: '1rem' }}>
          <Link to='/profile' className='ftp-link'>
            Set FTP in profile
          </Link>
        </Typography>
      </>
    )}
  </Card>
);

const ProgressFTP = ({ activityType }) => {
  const { user: data } = useContext(UserContext);

  return (
    <Stack gap={1}>
      {activityType === 'swimming' && (
        <ProgressFTPCard
          title='Swim functional Threshold Pace'
          functionalThresholdPace={data.functionalThresholdPace}
          ftpAccuracy={data.ftpAccuracy}
          activityType={activityType}
        />
      )}
      {activityType === 'running' && (
        <ProgressFTPCard
          title='Run functional threshold pace'
          functionalThresholdPace={data.functionalThresholdPaceRun}
          ftpAccuracy={data.ftpAccuracyRun || 0}
          activityType={activityType}
        />
      )}
    </Stack>
  );
};

export default ProgressFTP;
