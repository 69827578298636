import Freestyle from "../Icons/Freestyle";
import Backstroke from "../Icons/Backstroke";
import Breaststroke from "../Icons/Breaststroke";
import Butterfly from "../Icons/Butterfly";
import Drill from "../Icons/Drill";
import Mixed from "../Icons/Mixed";
import { useQuery } from "react-query";
import api from "api";
import Spinner from "../common/Spinner";
import Card from "../../pages/Progress/components/Card";
import { Box, Grid } from "@mui/material";
import { useResponsive } from "../../hooks";

const ProgressStrokeCounts = ({ strokes }) => {

	return (
		<Grid container justifyContent={ 'flex-start' } spacing={3}>
			{ strokes.map((stroke, index) => (
				<Grid xs={ 3 } md={ 2 } item key={ stroke.key }>
					{ parseIcon(stroke.key) }
					<p>{ stroke.totalDisplay }</p>
					<p className="small-text">{ stroke.percentDisplay }</p>
				</Grid>
			)) }
		</Grid>
	);
};

export default ProgressStrokeCounts;

const parseIcon = (stroke) => {
	if (stroke === "freestyle") {
		return <Freestyle/>;
	} else if (stroke === "backstroke") {
		return <Backstroke/>;
	} else if (stroke === "butterfly") {
		return <Butterfly/>;
	} else if (stroke === "breaststroke") {
		return <Breaststroke/>;
	} else if (stroke === "mixed") {
		return <Mixed/>;
	} else if (stroke === "drill") {
		return <Drill/>;
	} else {
		return null;
	}
};
