import BandIcon from "../Icons/BandIcon";
import BatonIcon from "../Icons/BatonIcon";
import BuoyIcon from "../Icons/BuoyIcon";
import DrillIcon from "../Icons/DrillIcon";
import FinsIcon from "../Icons/FinsIcon";
import KickboardIcon from "../Icons/KickboardIcon";
import PaddlesIcon from "../Icons/PaddlesIcon";
import SnorkelIcon from "../Icons/SnorkelIcon";
import TechtocIcon from "../Icons/TechIcon";
import Pool from "../Icons/Pool";
import Open from "../Icons/Open";

export const equipmentIcons = {
	bands: BandIcon,
	baton: BatonIcon,
	pullbuoy: BuoyIcon,
	drill: DrillIcon,
	fins: FinsIcon,
	kickboard: KickboardIcon,
	paddles: PaddlesIcon,
	snorkel: SnorkelIcon,
	techtoc: TechtocIcon,
};

export const environmentIcons = {
	pool: <Pool />,
	open: <Open />,
};
