import { useState, useRef, useContext } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { Helmet } from "react-helmet";
import Div100vh from "react-div-100vh";
import { NavLink, Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { Collapse } from "react-collapse";
import { useOnClickOutside } from "../../hooks";
import { UserContext } from "../../context";
import { HiUserCircle } from "react-icons/hi";
import { BsCalendar3, BsGridFill, BsChevronLeft } from "react-icons/bs";
import { AiFillHome } from "react-icons/ai";
import { FaSwimmer } from "react-icons/fa";
import { MdExpandMore } from "react-icons/md";

import * as tracking from "../../tracking.js";

const logoutUrl = `${process.env.REACT_APP_COGNITO_URL}/logout?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&logout_uri=${process.env.REACT_APP_COGNITO_LOGOUT_URI}`;

const Layout = ({
  title,
  headerTitle,
  description,
  children,
  backLink,
  coaching,
}) => {
  const history = useHistory();

  return (
    <>
      <Helmet>
        {title && <title>{title}</title>}
        {description && <meta name="description" content={description} />}
      </Helmet>

      <MobileView>
        <Div100vh className="mobile-layout">
          <header className={coaching ? "header-coaching" : "header"}>
            <div className="mobile-header">
              {backLink ? (
                <>
                  <div className="mobile-header__section--left">
                    <button onClick={() => history.goBack()}>
                      <span>
                        <BsChevronLeft size={12} />
                      </span>{" "}
                      {backLink.text}
                    </button>
                  </div>
                  <div className="mobile-header__section--center"></div>
                  <div className="mobile-header__section--right"></div>
                </>
              ) : (
                <>
                  <div className="mobile-header__section--left">
                    <BsCalendar3 size={20} />
                  </div>
                  <div className="mobile-header__section--center">
                    {headerTitle}
                  </div>
                  <div className="mobile-header__section--right">
                    <HiUserCircle size={25} />
                  </div>
                </>
              )}
            </div>
          </header>
          <main>{children}</main>
          <footer>
            <div className="mobile-footer">
              <div className="mobile-footer__section">
                <NavLink
                  to="/activity"
                  className="mobile-footer__link"
                  activeClassName="mobile-footer__link--active"
                >
                  <AiFillHome size={25} />
                  <p>Swims</p>
                </NavLink>
              </div>
              <div className="mobile-footer__section">
                <NavLink
                  to="/sessions"
                  className="mobile-footer__link"
                  activeClassName="mobile-footer__link--active"
                >
                  <BsGridFill size={25} />
                  <p>Sessions</p>
                </NavLink>
              </div>
            </div>
          </footer>
        </Div100vh>
      </MobileView>

      <BrowserView className="browser-layout">
        <header className={coaching ? "header-coaching" : "header"}>
          <div className="browser-header-section--left">
            <div style={{ display: "flex", alignItems: "center" }}>
              <FaSwimmer size={32} />{" "}
              {coaching ? (
                <h2
                  style={{
                    marginLeft: "1rem",
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                >
                  Coaching
                </h2>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="browser-header-section--center">
            {!coaching && (
              <>
                <NavLink
                  to="/progress"
                  className="browser-header-link"
                  activeClassName="browser-header-link--active"
                >
                  <BsGridFill size={20} />
                  <p>Progress</p>
                </NavLink>
                <NavLink
                  to="/activity"
                  className="browser-header-link"
                  activeClassName="browser-header-link--active"
                >
                  <AiFillHome size={20} />
                  <p>Swims</p>
                </NavLink>
              </>
            )}
          </div>
          <div className="browser-header-section--right">
            <User isCoach={!!coaching} />
          </div>
        </header>
        <main>
          {coaching ? (
            <div className="left-menu-layout">
              <div className="left-menu-layout__menu">
                {/* <NavLink
                  to="/coaching/training"
                  className="left-menu-layout__menu__item"
                  activeClassName="left-menu-layout__menu__item color-pink"
                >
                  Programs
                </NavLink> */}
                <NavLink
                  to="/coaching/workouts"
                  className="left-menu-layout__menu__item"
                  activeClassName="left-menu-layout__menu__item color-pink"
                >
                  Workouts
                </NavLink>
                <NavLink
                  to="/coaching/sets"
                  className="left-menu-layout__menu__item"
                  activeClassName="left-menu-layout__menu__item color-pink"
                >
                  Sets
                </NavLink>
                <NavLink
                  to="/coaching/swimmers"
                  className="left-menu-layout__menu__item"
                  activeClassName="left-menu-layout__menu__item color-pink"
                >
                  Swimmers
                </NavLink>
                <NavLink
                  to="/coaching/profile"
                  className="left-menu-layout__menu__item"
                  activeClassName="left-menu-layout__menu__item color-pink"
                >
                  Profile
                </NavLink>
              </div>
              <div className="left-menu-layout__content">{children}</div>
            </div>
          ) : (
            <>{children}</>
          )}
        </main>
      </BrowserView>
    </>
  );
};

// Layout.propTypes = {
//   : PropTypes.
// }

export default Layout;

const User = ({ isCoach }) => {
  const { user } = useContext(UserContext);
  const [isOpened, toggle] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => toggle(false));
  return (
    <div className="header-profile">
      <div ref={ref} className="header__profile-wrapper">
        <div className="header__profile" onClick={() => toggle(!isOpened)}>
          {/*<img src={user.extended.avatar} alt='' />*/}
          <p className="header__profile__name">{user.username}</p>
          <MdExpandMore className="header__profile__icon" size={20} />
        </div>
        <Collapse isOpened={isOpened}>
          <div className="header__profile__menu">
            <HeaderMenuLink
              title={isCoach ? "Swimmer Profile" : "Profile"}
              path={"/profile"}
            />
            {!!user.isCoach && (
              <>
                <HeaderMenuLink
                  title={"Coaching"}
                  path={"/coaching/swimmers"}
                />
                <HeaderMenuLink title={"Training"} path={"/progress"} />
              </>
            )}
            {!!user.isAdmin && (
              <HeaderMenuLink title={"Admin"} path={"/admin"} />
            )}
            <div className="header__profile__menu-divider" />
            <div className="header__profile__menu-link">
              <a
                className="header__link-title"
                href={logoutUrl}
                target="_self"
                rel="noopener noreferrer"
                onClick={() => {
                  tracking.trackUserLogout();
                  localStorage.clear();
                }}
              >
                Logout
              </a>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

const HeaderMenuLink = ({ title, icon, path }) => (
  <Link className="header__profile__menu-link" to={path}>
    <p className="header__link-title">{title}</p>
  </Link>
);
