import useStyles from './index.styles'

import { useState } from 'react';

import {
	Bar,
	BarChart,
	CartesianGrid,
	ReferenceLine,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from "recharts";
import { Box, Chip, Stack, Typography } from "@mui/material";

import {
	resolveTextColor,
	parseChartData,
	resolveEfficiencyStrokePeriodText,
	CHART_TEXT
} from "../../utils";

import { HandIcon } from "../../../../assets/icons";
import CustomToolTip from "../../../charts/CustomToolTip";

const StrokePeriod = ({ data, isMobile, features, hand, strokePeriod }) => {

	const { classes } = useStyles({ isMobile })

	const blah = parseChartData(features, strokePeriod, CHART_TEXT.STROKE_PERIOD)

	return (
		<Box className={ classes.root }>
			<Box className={ classes.header }>
				<Typography variant={ isMobile ? 'h5' : 'h4' }>Stroke Period</Typography>
				<Typography variant={ 'body2' } className={ classes.greyText }>Stroke Period is the measure of time
					taken for your arm to complete a single stroke, say from catch to catch. </Typography>
			</Box>
			<Box className={ classes.subHeader }>
				<Typography variant={ 'h6' }>Analysis</Typography>
				<Typography variant={ 'body2' } className={ classes.greyText }>Data is compared to your
					"normal"</Typography>
			</Box>
			<Box className={ classes.buttonsContainer }>
				<Box className={ classes.chipContainer }>
					<Chip
						key={ 'Free' }
						className={ classes.activeChip }
						label={ 'Free' }
					/>
				</Box>
				<Box className={ classes.handContainer }>
					<HandIcon type={ hand }/>
					<Typography
						className={ classes.handText }>{ hand.charAt(0).toUpperCase() + hand.slice(1) } hand</Typography>
				</Box>
			</Box>
			<Box className={ classes.statsWrapper }>
				<Box className={ classes.statsContainer }>
					<Stack>
						<Typography variant={ 'h5' }>
							{ `${ data[strokePeriod].mean } seconds` }
						</Typography>
						<Typography variant={ 'caption' } sx={ { color: 'rgba(235, 235, 245, 0.6)' } }>
							Distance
						</Typography>
					</Stack>
					<Stack>
						<Typography sx={ { color: resolveTextColor(data[strokePeriod].similarityColor) } }
									variant={ 'h5' }>
							{ resolveEfficiencyStrokePeriodText(data[strokePeriod].similarityColor) }
						</Typography>
						<Typography variant={ 'caption' } sx={ { color: 'rgba(235, 235, 245, 0.6)' } }>
							{ `${ (data[strokePeriod].similarityPercent) }% p-value` }
						</Typography>
					</Stack>
				</Box>
				<Box className={ classes.graphStatsContainer }>
					<Box className={ classes.square }></Box>
					<Typography sx={ { fontSize: '11px', marginRight: '17px' } }>Stroke Period</Typography>
					<Typography
						sx={ { fontSize: '11px', marginRight: '8px' } }>{ data[strokePeriod].normal + 's' }</Typography>
					<Typography sx={ { fontSize: '11px', marginRight: '8px' } }>Your ‘normal’</Typography>
				</Box>
			</Box>
			<Box className={ classes.chartContainer }>
				<ResponsiveContainer width="100%" height="100%">
					<BarChart
						data={ blah.data }
						margin={ { top: 0, right: 0, left: 0, bottom: 0 } }
					>
						<CartesianGrid stroke={ 'rgba(255, 255, 255, 0.14)' }/>
						<XAxis ticks={ blah.ticks }/>
						<YAxis width={ 22 }/>
						<Tooltip content={ <CustomToolTip/> }/>
						<Bar dataKey={ CHART_TEXT.STROKE_PERIOD } fill="#FF5082"/>
						<ReferenceLine y={ blah.normal } stroke="white" strokeDasharray="3 3"/>
					</BarChart>
				</ResponsiveContainer>
			</Box>
			<Stack gap={ '8px' }>
				<Typography variant={ 'h6' }>About Stroke Period</Typography>
				<Typography variant={ 'body1' } className={ classes.greyText }>Stroke Period will change throughout your
					swim based on your stroke rate and the effort you are swimming both of which willl shorten your
					stroke period. Using equipment, in particular fins will increase your stroke period, fins allow you
					to maintain propulstion and slow your stroke down giving you more time to think about arm, body and
					head position to name some examples. Paddles will also lengthen your stroke as the added resistance
					increases the time taken to pull through the water. Try it for yourself. </Typography>
			</Stack>
		</Box>
	);
};

export default StrokePeriod;
