import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useMutation } from 'react-query';
import api from 'api';

const ProfileBecomeACoachForm = ({ handleClose, user }) => {
  const { mutate, isLoading } = useMutation(
    (data) => api.user.requestToBeACoach(data),
    {
      onSuccess: () => {
        handleClose();
      },
    }
  );

  const submit = () => {
    const body = {
      name:
        user.firstName || user.lastName
          ? `${user.firstName} ${user.lastName}`
          : user.email,
      email: user.email,
      type: 'coachContactForm',
      message: 'In app request to become a coach',
    };
    mutate(body);
  };

  return (
    <>
      <DialogTitle className='title'>Become a coach</DialogTitle>
      <DialogContent sx={{ minWidth: { md: 450 } }}>
        <DialogContentText mb={2}>
          Request to become a coach to connect with athletes, create and publish
          workouts, and open up a store.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <button className='button' onClick={submit} disabled={isLoading}>
          Request
        </button>
      </DialogActions>
    </>
  );
};
export default ProfileBecomeACoachForm;
