import Freestyle from '../Icons/Freestyle';
import Backstroke from '../Icons/Backstroke';
import Butterfly from '../Icons/Butterfly';
import Breaststroke from '../Icons/Breaststroke';
import Mixed from '../Icons/Mixed';
import Drill from '../Icons/Drill';
import Any from '../Icons/Any';

export const VIEW_TYPES = {
  split: 'split',
  heartRate: 'heartRate'
};

export const VIEW_OPTIONS = [
  {
    value: VIEW_TYPES.split,
    label: 'Split'
  },
  {
    value: VIEW_TYPES.heartRate,
    label: 'Heart rate'
  }
];

export const STROKE_TYPES = {
  freestyle: 'freestyle',
  backstroke: 'backstroke',
  butterfly: 'butterfly',
  breaststroke: 'breaststroke',
  mixed: 'mixed',
  drill: 'drill',
  kickboard: 'kickboard',
  any: 'any'
};

export const STROKE_ICONS = {
  [STROKE_TYPES.freestyle]: <Freestyle />,
  [STROKE_TYPES.backstroke]: <Backstroke />,
  [STROKE_TYPES.butterfly]: <Butterfly />,
  [STROKE_TYPES.breaststroke]: <Breaststroke />,
  [STROKE_TYPES.mixed]: <Mixed />,
  [STROKE_TYPES.drill]: <Drill />,
  [STROKE_TYPES.drill]: <Any />,
  [STROKE_TYPES.kickboard]: <Drill />,
  [STROKE_TYPES.any]: <Any />
};

export const LAP_LABELS = {
  [STROKE_TYPES.freestyle]: 'FREE',
  [STROKE_TYPES.backstroke]: 'BACK',
  [STROKE_TYPES.butterfly]: 'FLY',
  [STROKE_TYPES.breaststroke]: 'BRST',
  [STROKE_TYPES.drill]: 'DRILL',
  [STROKE_TYPES.kickboard]: 'KICK',
  [STROKE_TYPES.mixed]: 'MIX',
  [STROKE_TYPES.any]: 'ANY'
};

export const SWIM_VALUES = {
  [VIEW_TYPES.split]: {
    keyDisplay: 'durationDisplay',
    key: 'duration',
    label: 'SPLIT'
  },
  [VIEW_TYPES.heartRate]: {
    keyDisplay: 'avgHeartRate',
    key: 'avgHeartRate',
    label: 'BPM'
  }
};

export const RUN_VALUES = {
  [VIEW_TYPES.split]: {
    keyDisplay: 'lapTimeSec',
    key: 'lapTimeSec',
    label: 'SPLIT'
  },
  [VIEW_TYPES.heartRate]: {
    keyDisplay: 'avgHeartRate',
    key: 'avgHeartRate',
    label: 'BPM'
  }
};

export const getMaxLapValues = (sets, isSwim) => {
  let maxLapSplit = 0;
  let maxHeartRate = 0;

  try {
    if (!isSwim) {
      sets.forEach((segment) => {
        segment.laps.forEach((lap) => {
          if (lap.lapTimeSec > maxLapSplit) {
            maxLapSplit = lap.lapTimeSec;
          }

          if (lap.avgHeartRate > maxHeartRate) {
            maxHeartRate = lap.avgHeartRate;
          }
        });
      });

      return { maxLapSplit, maxHeartRate };
    }

    sets.forEach((set) => {
      set.segments.forEach((segment) => {
        segment.laps.forEach((lap) => {
          if (lap.duration > maxLapSplit) {
            maxLapSplit = lap.duration;
          }

          if (lap.avgHeartRate > maxHeartRate) {
            maxHeartRate = lap.avgHeartRate;
          }
        });
      });
    });
  } catch (error) {
    console.log(error);
  }
  return { maxLapSplit, maxHeartRate };
};
