// Packages
import {useState, createRef} from "react";
import Editor from "react-avatar-editor";
import {Slider, Box, Typography, FormHelperText} from "@mui/material";
import Dropzone from "react-dropzone";
import axios from "axios";
import {useMutation} from "react-query";

// Local Modules
import api from "api";

const AvatarEditor = (
  {
    imageUrl,
    width = 250,
    handleClose,
    refetch,
    maskShape = "round",
    fileName = "avatar.png",
    objectKey = "avatar",
    helperText,
  }
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [scale, setScale] = useState(1);
  const [image, setImage] = useState(imageUrl);
  const ref = createRef();

  const {mutate} = useMutation(api.coaching.profile.update, {
    onSuccess: () => {
      if (!!refetch) refetch();
      if (!!handleClose) handleClose();
    },
    onSettled: () => setIsLoading(false),
  });

  const onClickSave = async () => {
    setIsLoading(true);
    const canvas = ref.current.getImageScaledToCanvas();
    canvas.toBlob(async (blob) => {
      try {
        const {presignedUrl, fileUrl} =
          await api.coaching.profile.assetUploadUrl(fileName, blob.type, 'avatar');
        await axios.put(presignedUrl, blob);
        const url = fileUrl + `?${Date.now()}`;
        let mutateData = {[objectKey]: url};
        // TODO: remove this conditional after iOS update
        if (objectKey === "image") {
          mutateData = {avatar: url, image: url};
        }
        mutate(mutateData);
      } catch (err) {
        console.log(err, err.message);
        alert("There was an error uploading. Please try again.");
      }
    });
  };

  const handleDrop = (dropped) => {
    setImage(dropped[0]);
  };

  return (
    <Box width={width}>
      <Typography>Drop file onto editor to upload</Typography>
      {!!helperText && (
        <FormHelperText sx={{marginTop: 0, marginBottom: ".5rem"}}>
          {helperText}
        </FormHelperText>
      )}
      <Dropzone
        onDrop={handleDrop}
        noClick
        noKeyboard
        maxFiles={1}
        style={{
          width: `${width}px`,
          height: `${width}px`,
        }}
      >
        {({getRootProps, getInputProps}) => (
          <div {...getRootProps()}>
            <Editor
              ref={ref}
              image={image}
              width={width}
              height={width}
              border={1}
              borderRadius={maskShape === "round" ? width / 2 : 0}
              color={[255, 255, 255, 0.2]}
              scale={scale}
              rotate={0}
              crossOrigin="anonymous"
            />
            <input {...getInputProps()} />
          </div>
        )}
      </Dropzone>

      <Slider
        aria-label="Scale"
        value={scale}
        onChange={(event, value) => setScale(value)}
        step={0.1}
        min={0.5}
        max={3}
      />
      <div style={{textAlign: "right"}}>
        <button onClick={onClickSave} disabled={isLoading}>
          SAVE
        </button>
      </div>
    </Box>
  );
};

export default AvatarEditor;
