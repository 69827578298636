import {useContext, useMemo} from "react";
import {useWatch} from "react-hook-form";
// @mui
import {Box, Chip, Stack, Typography} from "@mui/material";
import {createSvgIcon} from "@mui/material/utils";
// icons
import AutorenewIcon from "@mui/icons-material/Autorenew";
import FlagIcon from "@mui/icons-material/Flag";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Freestyle from "../../../../components/Icons/Freestyle";
import Backstroke from "../../../../components/Icons/Backstroke";
import Breaststroke from "../../../../components/Icons/Breaststroke";
import Butterfly from "../../../../components/Icons/Butterfly";
import Drill from "../../../../components/Icons/Drill";
import Any from "../../../../components/Icons/Any";
// components
import EquipmentIconList from "../../../../components/EquipmentIconList";
import {colors} from "../../../../style/colors";
import {secondsToHMS} from "../../../../util";
import {CoachContext} from "../../../../context";


const segmentStyle = {
  borderRadius: "0.5rem",
  overflow: "hidden",
  bgcolor: "backgrounds.2",
  mb: .5,
};

const defaultEfforts = {
  a: "Easy",
  b: "Endurance",
  c: "Tempo",
  d: "Threshold",
  e: "Sprint",
};

const flavors = {
  warmup: {
    label: 'Warm Up',
    color: 'success',
  },
  main: {
    label: 'Main Set',
    color: 'warning',
  },
  cooldown: {
    label: 'Cool Down',
    color: 'info',
  },
};

const lapUnit = "m"

const SetViewSection = ({baseName = '', toolbar}) => {
  const {coach} = useContext(CoachContext);

  const set = useWatch({name: baseName});

  const totalTime = useMemo(() => {
    const segmentsTime = set?.segments?.reduce((initVal, segment) => initVal + ((segment.cycleTime || 0) * segment.repeat), 0);
    return set.repeat * (segmentsTime || 0)
  }, [set]);

  const efforts = coach?.effortZoneNames || defaultEfforts;


  return (
    <Stack gap={1}>
      <Box display="flex" alignItems="start" justifyContent="space-between">
        <Box display="flex" flexDirection={{xs: 'column', sm: 'row'}} alignItems={{xs: 'start', sm: 'center'}} gap={2}>
          {set.flavor && (
            <Chip
              label={flavors[set.flavor]?.label}
              color={flavors[set.flavor]?.color || 'default'}
              variant="outlined"
              size="small"
            />
          )}
          <Typography variant="h6">{set.name}</Typography>
        </Box>
        {toolbar}
      </Box>

      {set.description && <Typography variant="body2" color="text.secondary">{set.description}</Typography>}

      <Box display="flex" gap={2}>
        {set.repeat > 1 && (
          <Stack
            direction="row"
            sx={{color: "primary.main", alignItems: "center"}}
          >
            <AutorenewIcon sx={{fontSize: 18}}/>
            <Typography>x{set.repeat}</Typography>
          </Stack>
        )}
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography variant="body2" color="text.secondary">Set Totals</Typography>
          <Box display="flex" gap={.5}>
            <FlagIcon opacity={.55}/>
            <Typography variant="body1">{set?.totalDistance ?? '0'}</Typography>
          </Box>
          <Box display="flex" gap={.5}>
            <AccessTimeIcon opacity={.55}/>
            <Typography variant="body1">{secondsToHMS(totalTime)}</Typography>
          </Box>
        </Stack>
      </Box>

      {set.segments.map((segment) => <Segment key={segment.id} segment={segment} lapUnit={lapUnit} efforts={efforts}/>)}
    </Stack>
  );
}

const Segment = ({segment, lapUnit, efforts}) => {
  if (!segment.isSplit) {
    return (
      <Box sx={segmentStyle}>
        <Line
          stroke={segment.stroke}
          topLine={
            segment.repeat > 1
              ? `${segment.repeat}x${segment.distance}${lapUnit}`
              : `${segment.distance}${lapUnit}`
          }
          subLine={segment.isDrill ? segment.stroke : efforts[segment.effort]}
          targetTime={
            segment.stroke === "freestyle" && !segment.equipment?.length
              ? segment.targetTimeDisplay
              : null
          }
          cycleTime={segment.cycleTimeDisplay}
          equipment={segment.equipment}
          isDrill={segment.isDrill}
          lapUnit={lapUnit}
        />
      </Box>
    );
  }
  return (
    <Box sx={segmentStyle}>
      <Stack
        pt={1}
        pb={1}
        pl={2}
        pr={2}
        direction="row"
        sx={{justifyContent: "space-between"}}
      >
        <Stack direction="row" spacing={2} sx={{alignItems: "center"}}>
          <Typography>
            {segment.distance}
            {lapUnit}
          </Typography>
          <Stack
            direction="row"
            spacing={0.5}
            sx={{alignItems: "center", opacity: 0.7}}
          >
            <CycleIcon sx={{fontSize: 16}}/>
            <Typography>{segment.cycleTimeDisplay}</Typography>
          </Stack>
        </Stack>
        <EquipmentIconList equipment={segment.equipment} fontSize={21}/>
      </Stack>
      {segment.splits.map((split) => (
        <Line
          key={split.id}
          stroke={split.stroke}
          topLine={`${split.distance}${lapUnit}`}
          subLine={split.isDrill ? split.stroke : efforts[split.effort]}
          targetTime={
            split.stroke === "freestyle" && !split.equipment?.length
              ? split.targetTimeDisplay
              : null
          }
          cycleTime={split.cycleTimeDisplay}
          equipment={split.equipment}
          isDrill={split.isDrill}
          isSplit
          lapUnit={lapUnit}
        />
      ))}
    </Box>
  );
};

const strokeBackgrounds = {
  freestyle: colors.greenTint,
  backstroke: colors.blueTint,
  drill: colors.yellowTint,
  butterfly: colors.redTint,
  breaststroke: colors.pinkTint,
  any: "backgrounds.3",
};

const flavorIcons = {
  freestyle: <Freestyle/>,
  backstroke: <Backstroke/>,
  breaststroke: <Breaststroke/>,
  butterfly: <Butterfly/>,
  drill: <Drill/>,
  any: <Any/>,
}

const TargetIcon = createSvgIcon(
  <path
    d="M 12.449219 23.683594 C 18.671875 23.683594 23.828125 18.527344 23.828125 12.304688 C 23.828125 6.082031 18.660156 0.925781 12.4375 0.925781 C 6.214844 0.925781 1.058594 6.082031 1.058594 12.304688 C 1.058594 18.527344 6.214844 23.683594 12.449219 23.683594 Z M 12.449219 21.609375 C 7.28125 21.609375 3.15625 17.460938 3.15625 12.304688 C 3.15625 7.148438 7.28125 3 12.4375 3 C 17.59375 3 21.742188 7.148438 21.753906 12.304688 C 21.753906 17.460938 17.605469 21.609375 12.449219 21.609375 Z M 12.449219 19.3125 C 16.28125 19.3125 19.457031 16.125 19.457031 12.304688 C 19.457031 8.472656 16.269531 5.285156 12.449219 5.285156 C 8.617188 5.285156 5.441406 8.472656 5.441406 12.304688 C 5.441406 16.125 8.628906 19.3125 12.449219 19.3125 Z M 12.449219 17.390625 C 9.683594 17.390625 7.363281 15.070312 7.363281 12.304688 C 7.363281 9.527344 9.671875 7.207031 12.449219 7.207031 C 15.203125 7.207031 17.535156 9.539062 17.535156 12.304688 C 17.535156 15.082031 15.214844 17.390625 12.449219 17.390625 Z M 12.460938 15.117188 C 13.996094 15.117188 15.273438 13.863281 15.273438 12.292969 C 15.273438 10.757812 13.996094 9.492188 12.460938 9.492188 C 10.902344 9.492188 9.625 10.757812 9.625 12.292969 C 9.625 13.851562 10.902344 15.117188 12.460938 15.117188 Z M 12.460938 15.117188 "/>,
  "Target"
);

const CycleIcon = createSvgIcon(
  <path
    d="M 14.339844 0.925781 C 8.953125 0.925781 4.398438 4.898438 3.386719 10.101562 L 1.84375 10.101562 C 1.027344 10.101562 0.808594 10.699219 1.269531 11.34375 L 3.8125 14.976562 C 4.167969 15.492188 4.71875 15.480469 5.066406 14.976562 L 7.609375 11.332031 C 8.042969 10.710938 7.835938 10.101562 7.03125 10.101562 L 5.46875 10.101562 C 6.421875 6.023438 10.011719 3.011719 14.339844 3.011719 C 17.339844 3.023438 19.976562 4.476562 21.632812 6.75 C 22.011719 7.230469 22.597656 7.382812 23.09375 7.054688 C 23.566406 6.726562 23.667969 6.070312 23.242188 5.519531 C 21.207031 2.777344 17.925781 0.925781 14.339844 0.925781 Z M 13.933594 5.847656 C 13.417969 5.847656 13.015625 6.257812 13.015625 6.761719 L 13.015625 12.867188 C 13.015625 13.125 13.09375 13.359375 13.28125 13.617188 L 15.925781 17.15625 C 16.292969 17.660156 16.835938 17.742188 17.292969 17.414062 C 17.71875 17.109375 17.753906 16.546875 17.421875 16.089844 L 14.832031 12.550781 L 14.832031 6.761719 C 14.832031 6.257812 14.429688 5.847656 13.933594 5.847656 Z M 14.339844 23.683594 C 19.722656 23.683594 24.277344 19.710938 25.300781 14.507812 L 26.832031 14.507812 C 27.648438 14.507812 27.867188 13.910156 27.417969 13.265625 L 24.867188 9.632812 C 24.507812 9.117188 23.96875 9.128906 23.609375 9.632812 L 21.070312 13.277344 C 20.632812 13.898438 20.839844 14.507812 21.644531 14.507812 L 23.207031 14.507812 C 22.253906 18.585938 18.664062 21.609375 14.339844 21.609375 C 11.335938 21.597656 8.699219 20.132812 7.042969 17.859375 C 6.664062 17.378906 6.078125 17.226562 5.582031 17.554688 C 5.109375 17.882812 5.007812 18.539062 5.433594 19.089844 C 7.46875 21.832031 10.75 23.683594 14.339844 23.683594 Z M 14.339844 23.683594 "/>,
  "Cycle"
);

const Line = (
  {
    stroke,
    isDrill,
    topLine,
    subLine,
    targetTime,
    cycleTime,
    equipment,
    isSplit,
  }
) => {
  const lineStyle = {};
  if (isSplit) {
    lineStyle.borderTop = "1px solid";
    lineStyle.borderColor = "separator.main";
  }
  return (
    <Stack direction="row" sx={lineStyle}>
      <Box
        sx={{
          bgcolor: strokeBackgrounds[isDrill ? "drill" : stroke],
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 48,
        }}
      >
        {flavorIcons[isDrill ? "drill" : stroke]}
      </Box>
      <Box pt={1} pb={1} pl={2} sx={{display: 'flex', flexDirection: 'column', flex: 1, gap: .5}}>
        <Stack direction="row" sx={{alignItems: "center"}} spacing={1}>
          <Typography color="text.secondary">{topLine}</Typography>
          {!!targetTime && (
            <Stack direction="row" spacing={0.5} sx={{alignItems: "center"}}>
              <TargetIcon sx={{fontSize: 16, color: 'text.secondary'}}/>{" "}
              <Typography>{targetTime}</Typography>
            </Stack>
          )}
          {!!cycleTime && (
            <Stack
              direction="row"
              spacing={0.5}
              sx={{alignItems: "center"}}
            >
              <CycleIcon sx={{fontSize: 16, color: 'text.secondary'}}/>
              <Typography>{cycleTime}</Typography>
            </Stack>
          )}
        </Stack>
        <Typography sx={{lineHeight: 1, fontSize: "0.9rem", color: 'text.secondary'}}>
          {subLine}
        </Typography>
      </Box>
      <Box pr={2} sx={{display: "flex", alignItems: "center", fill: "red"}}>
        <EquipmentIconList equipment={equipment} fontSize={21}/>
      </Box>
    </Stack>
  );
};


export default SetViewSection;