import {useContext, useMemo, useState} from "react";
import {useMutation, useQuery} from "react-query";
import {useWatch} from "react-hook-form";
// @mui
import {Box, Button, Divider, LinearProgress, ListSubheader, styled, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
// icons
import DeleteIcon from "@mui/icons-material/Delete";
import MenuItem from "@mui/material/MenuItem";
// utils
import {RHFSelect} from "../../../../../components/hook-form";
import {ConfirmDialog} from "../../../../../components/confirm-dialog";
import {useAlertContext} from "../../../../../components/alert/AlertContext";
import {StyledIconButton} from "../../../WorkoutCreator/form/WorkoutSetsStep";
import {CoachContext} from "../../../../../context";
import {camelCaseToWords} from "../../../../../util";
import api from "../../../../../api";

// ----------------------------------------------------------------------

const MenuItemStyled = styled(MenuItem)(({theme}) => ({
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  fontSize: theme.typography.body2.fontSize,
  textTransform: 'capitalize',
  '&:first-of-type': {marginTop: 0},
  '&:last-of-type': {marginTop: 0},
  justifyContent: 'space-between',
}));

const strokeExceptions = {
  drill: "drill", mixed: "mixed", unknown: "unknown", openWater: "openWater"
};

const StrokeDrillField = ({name, onChange, ...other}) => {
  const {coach, refetch: refetchCoach} = useContext(CoachContext);

  const {showAlert} = useAlertContext();

  const stroke = useWatch({name});

  const [enabled, setEnabled] = useState(false);

  const {data, isLoading} = useQuery(
    "listItems",
    api.coaching.workouts.getWorkoutMenuItems,
    {
      staleTime: 1000 * 60 * 24,
      enabled
    }
  );

  const updateCoachProfileMutation = useMutation(api.coaching.profile.update);

  const [deleteDialog, setDeleteDialog] = useState({open: false, id: undefined});

  const [addDialog, setAddDialog] = useState({open: false, id: undefined});

  const strokeOptions = useMemo(() => data?.strokes?.reduce((options, stroke) => {
    if (!strokeExceptions[stroke]) {
      options.push({
        value: stroke,
        label: camelCaseToWords(stroke),
      });
    }
    return options;
  }, []) || [], [data]);

  const handleOpenDeleteDialog = (name) => {
    setDeleteDialog({open: true, name});
  }

  const handleCloseDeleteDialog = () => {
    setDeleteDialog(prevState => ({...prevState, open: false}));
  }

  const handleOpenAddDialog = () => {
    setAddDialog({open: true, name: ''});
  }

  const handleCloseAddDialog = () => {
    setAddDialog(prevState => ({...prevState, open: false}));
  }

  const handleAddCoachWorkoutDrillNames = (data) => updateCoachProfileMutation.mutate(
    {workoutDrillNames: [...(coach?.workoutDrillNames || []), data]},
    {
      onSuccess: () => {
        handleCloseAddDialog();
        refetchCoach();
        showAlert('Coach profile saved successfully.', {severity: 'success'});
      },
      onError: () => showAlert('Something went wrong.', {severity: 'error'}),
    }
  );

  const handleDeleteCoachWorkoutDrillNames = (data) => updateCoachProfileMutation.mutate(
    {workoutDrillNames: (coach?.workoutDrillNames || []).filter((drill) => drill !== data)},
    {
      onSuccess: () => {
        handleCloseDeleteDialog();
        refetchCoach();
        showAlert('Coach profile saved successfully.', {severity: 'success'});
      },
      onError: () => showAlert('Something went wrong.', {severity: 'error'}),
    }
  );

  const handleOpen = () => {
    setEnabled(true);
  }

  const handleClose = () => {
    setEnabled(false);
  }


  return (
    <>
      <RHFSelect
        name={name}
        label="Stroke / Drill"
        variant="filled"
        SelectProps={{
          native: false,
          sx: {textTransform: 'capitalize'},
          renderValue: (selected) => selected,
          onOpen: handleOpen,
          onClose: handleClose
        }}
        onChange={(event) => onChange && onChange(event, {isDrill: !!coach?.workoutDrillNames?.includes(event.target.value)})}
        {...other}
      >
        <ListSubheader inset sx={{py: .5, px: 2, typography: 'overline'}}>
          Strokes
        </ListSubheader>

        <MenuItemStyled value="any">
          Any
        </MenuItemStyled>

        {isLoading && <LinearProgress/>}

        {strokeOptions.map((option) => (
          <MenuItemStyled
            key={option.value}
            value={option.value}
          >
            {option.label}
          </MenuItemStyled>
        ))}

        <ListSubheader inset sx={{py: .5, px: 2, typography: 'overline'}}>
          My Drills
        </ListSubheader>

        {!coach.workoutDrillNames.includes(stroke) && stroke && (
          <MenuItemStyled
            value={stroke}
            sx={{opacity: 0.5}}
            disabled
          >
            {stroke}
          </MenuItemStyled>
        )}

        {coach.workoutDrillNames.map((option) => (
          <MenuItemStyled
            key={option + ''}
            value={option}
          >
            {option}

            <StyledIconButton size="small" onClick={(e) => {
              e.stopPropagation();
              handleOpenDeleteDialog(option);
            }}>
              <DeleteIcon fontSize="small"/>
            </StyledIconButton>
          </MenuItemStyled>
        ))}

        <Divider/>

        <Box sx={{px: 1}}>
          <Button fullWidth onClick={handleOpenAddDialog} variant="text" size="small">
            Add a drill
          </Button>
        </Box>
      </RHFSelect>

      <ConfirmDialog
        open={addDialog.open}
        onClose={handleCloseAddDialog}
        title="Add a new drill"
        content={(
          <TextField
            label="Name"
            onChange={(e) => setAddDialog(prevState => ({...prevState, name: e.target.value}))}
            variant="filled"
          />
        )}
        action={
          <LoadingButton
            loading={updateCoachProfileMutation.isLoading}
            disabled={updateCoachProfileMutation.isLoading}
            onClick={() => handleAddCoachWorkoutDrillNames(addDialog.name)}
            variant="contained"
          >
            Add drill
          </LoadingButton>
        }
      />

      <ConfirmDialog
        open={deleteDialog.open}
        onClose={handleCloseDeleteDialog}
        title={`Delete "${deleteDialog.name}"?`}
        content={`"${deleteDialog.name}" will be removed from your available drills list. Other workouts that already use this drill will not be affected.`}
        action={
          <LoadingButton
            loading={updateCoachProfileMutation.isLoading}
            disabled={updateCoachProfileMutation.isLoading}
            onClick={() => handleDeleteCoachWorkoutDrillNames(deleteDialog.name)}
            variant="contained"
          >
            Delete
          </LoadingButton>
        }
      />
    </>
  );
};
export default StrokeDrillField;