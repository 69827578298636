import * as React from 'react';
import {
  Alert,
  Snackbar as MuiSnackbar
} from '@mui/material';

const SnackbarAlert = ({open, onClose, autoHideDuration = 6000, message, action, severity, ...props}) => (
  <MuiSnackbar
    open={open}
    autoHideDuration={6000}
    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
    onClose={onClose}
    {...props}>
    <Alert
      variant="filled"
      severity={severity}
      sx={{width: '100%'}}
      onClose={onClose}>
      {message}
    </Alert>
  </MuiSnackbar>
);

export default SnackbarAlert;