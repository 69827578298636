const Snorkel = ({size = 41}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M27.1249 9.2005C26.4769 9.61854 25.6419 9.8444 24.7708 9.8444C23.8998 9.8444 23.0647 9.61854 22.4168 9.2005C22.3462 9.15501 22.2765 9.10614 22.2083 9.05396V19.1866V26.1964V26.2025C22.2083 27.572 21.0611 28.6823 19.6458 28.6823C18.2306 28.6823 17.0833 27.572 17.0833 26.2025V26.2025H16.5204C15.8997 26.3158 15.2217 26.3759 14.5208 26.3759C13.82 26.3759 13.1419 26.3158 12.5213 26.2025H11.9583C11.9583 30.3111 15.4002 33.6417 19.6458 33.6417C23.8915 33.6417 27.3333 30.3111 27.3333 26.2025L27.3333 26.1907V9.05396C27.2651 9.10614 27.1954 9.15501 27.1249 9.2005ZM24.7708 5.53809C23.3556 5.53809 22.2083 6.27823 22.2083 7.19124C22.2083 8.10425 23.3556 8.8444 24.7708 8.8444C26.1861 8.8444 27.3333 8.10425 27.3333 7.19124C27.3333 6.27823 26.1861 5.53809 24.7708 5.53809ZM18.7917 23.7228C18.7917 24.6358 16.8795 25.3759 14.5208 25.3759C12.1621 25.3759 10.25 24.6358 10.25 23.7228C10.25 22.8098 12.1621 22.0696 14.5208 22.0696C16.8795 22.0696 18.7917 22.8098 18.7917 23.7228Z" fill="#EBEBF5" fillOpacity="0.6"/>
    </svg>

  );
}

// Fins.propTypes = {
//   : PropTypes.
// };

export default Snorkel;
