import { useState } from 'react';
import { Box, Typography, Stack, Card, Button, Dialog } from '@mui/material';
import ProfileFTPForm from '../../../components/profile/ProfileFTPForm';
import ProfileFTPRunForm from '../../../components/profile/ProfileFTPRunForm';
import HelpIconTooltip from '../../../components/common/HelpIconTooltip';
import { secondsToHMS } from '../../../util';
import { getFTPText } from '../../../components/progress/utils';

const helpText = {
  swimming:
    "This is your average pace per 100m for a full-effort swim that you could sustain for about an hour. If you're unsure, a good starting point is to time yourself swimming 1000m at your best sustainable effort and divide that time by 10.",
  running:
    "This is your average pace per km for a full-effort run that you could sustain for about an hour. If you're unsure, a good starting point is to time yourself running 10km at your best sustainable effort and divide that time by 10."
};

const FTPBar = ({ accuracy = 0 }) => (
  <Box style={{ display: 'flex' }}>
    {[...Array(3)].map((_, i) => (
      <Box key={i} className={`ftp-bar${accuracy > i ? '--' + accuracy : ''}`} />
    ))}
  </Box>
);

export const SwimFTP = ({ user, refetch }) => {
  const [open, setOpen] = useState(false);

  const isSet = user.onboarded && user.ftpAccuracy;

  const handleClose = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  return (
    <>
      <Card sx={{ backgroundColor: 'background.secondary', p: 2 }}>
        <Stack gap={2}>
          <Box display='flex'>
            <Typography variant='h5'>Swim - Functional Threshold Pace</Typography>
            <HelpIconTooltip content={helpText.swimming} />
          </Box>
          <Typography variant='caption' color='text.secondary'>
            Functional Threshold Pace or CSS in swimming is used by Simma to calculate your training
            stress score for each swim and to scale workouts and sets to your pace. Pace Zones will
            also be colourised for your based on FTP if you use the Simma Apple Watch app.
          </Typography>

          <Box
            display='flex'
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'start', sm: 'center' }}
            justifyContent='space-between'
            gap={1}>
            <Stack gap={1}>
              <Typography sx={{ fontSize: '28px' }}>
                {secondsToHMS(user.functionalThresholdPace)} /100m
              </Typography>
              <FTPBar accuracy={user.ftpAccuracy} />
              <Typography variant='caption' color='text.secondary'>
                {getFTPText(user.ftpAccuracy, 'swimming')}
              </Typography>
            </Stack>

            <Button variant={isSet ? 'outlined' : 'contained'} onClick={handleOpen}>
              {isSet ? 'edit' : 'set'} your swim ftp
            </Button>
          </Box>

          <Typography variant='caption' color='text.secondary'>
            Manually setting your FTP in your profile will better scale workouts and personalise
            your swim analytics. If you do not know this Simma will estimate it based on your
            onboarding answers and analytics of qualifying segments in your swim activity history
            with Simma.
          </Typography>
        </Stack>
      </Card>

      <Dialog open={open} onClose={handleClose}>
        <ProfileFTPForm user={user} handleClose={handleClose} refetch={refetch} />
      </Dialog>
    </>
  );
};

export const RunFTP = ({ user, refetch }) => {
  const [open, setOpen] = useState(false);

  const isSet = user.onboarded && user.ftpAccuracyRun;

  const handleClose = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  return (
    <>
      <Card sx={{ backgroundColor: 'background.secondary', p: 2 }}>
        <Stack gap={2}>
          <Box display='flex'>
            <Typography variant='h5'>Run - Functional Threshold Pace</Typography>
            <HelpIconTooltip content={helpText.run} />
          </Box>
          <Typography variant='caption' color='text.secondary'>
            Functional Threshold Pace in running is used by Simma to calculate your training stress
            score for each run. Pace Zones will also be colourised for your based on FTP if you use
            the Simma Apple Watch app.
          </Typography>

          <Box
            display='flex'
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'start', sm: 'center' }}
            justifyContent='space-between'
            gap={1}>
            <Stack gap={1}>
              <Typography sx={{ fontSize: '28px' }}>
                {secondsToHMS(user.functionalThresholdPaceRun)} /km
              </Typography>
              <FTPBar accuracy={user.ftpAccuracyRun} />
              <Typography variant='caption' color='text.secondary'>
                {getFTPText(user.ftpAccuracyRun, 'running')}
              </Typography>
            </Stack>

            <Button variant={isSet ? 'outlined' : 'contained'} onClick={handleOpen}>
              {isSet ? 'edit' : 'set'} your run ftp
            </Button>
          </Box>

          <Typography variant='caption' color='text.secondary'>
            Manually setting your FTP in your profile will improve your run analytics in Simma.
          </Typography>
        </Stack>
      </Card>

      <Dialog open={open} onClose={handleClose}>
        <ProfileFTPRunForm user={user} handleClose={handleClose} refetch={refetch} />
      </Dialog>
    </>
  );
};
