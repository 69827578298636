import useStyles from './index.styles';

import React, { useContext, useEffect, useState } from 'react';

import {
  Box,
  Container,
  FormControl,
  InputLabel,
  Select,
  Tab,
  Tabs,
  Typography,
  MenuItem
} from '@mui/material';
import { useQuery } from 'react-query';

import ProgressFormNew from '../../components/progress/ProgressFormNew';
import ProgressFTP from '../../components/progress/ProgressFTP';
import { UserContext } from '../../context';
import Stats from './components/Stats';
import api from '../../api';
import Spinner from '../../components/common/Spinner';
import { useResponsive } from '../../hooks';
import ProgressEmpty from './ProgressEmpty';

const Progress = ({ swimmerId }) => {
  const isMobile = useResponsive('down', 'sm');
  const { classes } = useStyles({ isMobile });

  const { user } = useContext(UserContext);
  const system = user.preferredUnits || 'metric';

  const [activityType, setActivityType] = React.useState('swimming');
  const isAllActivity = activityType === 'all';

  let {
    isLoading,
    error,
    data: { data } = {}
  } = useQuery(
    ['progressAll', activityType, system, swimmerId],
    () => api.progress.getProgressData(swimmerId, activityType, system),
    { staleTime: 1000 * 60 * 5 }
  );

  const [currentTab, setCurrentTab] = useState('form');

  const handleChange = (event) => {
    setActivityType(event.target.value);
  };

  useEffect(() => {
    if (activityType === 'all') {
      setCurrentTab('form');
    }
  }, [activityType]);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    console.log('USER STATISTICS ERROR: ', error);
    return <div>Error</div>;
  }

  const TABS = [
    {
      value: 'form',
      label: 'Form',
      component: (
        <ProgressFormNew
          activityType={activityType}
          isMobile={isMobile}
          form={data.form}
          distanceComparison={data?.userDistanceComparison || []}
          fitness={data.fitness}
          cardioReport={data.cardioReport}
          allUserActivityTypes={data.allUserActivityTypes}
        />
      )
    },
    ...(!isAllActivity
      ? [
          {
            value: 'stats',
            label: 'Stats',
            component: (
              <Stats
                activityType={activityType}
                statistics={data.statistics}
                strokes={data.strokes}
                data={data}
              />
            )
          },
          {
            value: 'ftp',
            label: 'FTP',
            component: <ProgressFTP activityType={activityType} />
          }
        ]
      : [])
  ];

  if (!user.hasActivities) {
    return <ProgressEmpty />;
  }

  return (
    <Box sx={{ py: 1, paddingTop: '17px' }}>
      <Container sx={{ padding: `0 ${isMobile ? '4px' : '16px'}` }} maxWidth='md'>
        <Box className={classes.header}>
          <Typography variant={isMobile ? 'h5' : 'h4'}>My Progress</Typography>
          <FormControl fullWidth className={classes.activitySelect}>
            <InputLabel id='select-activity-type-label'>Activity Type</InputLabel>
            <Select
              labelId='select-activity-type-label'
              id='select-activity-type'
              value={activityType}
              label='Activity Type'
              onChange={handleChange}
              variant={'standard'}
              size='small'>
              <MenuItem value={'all'}>All Activities</MenuItem>
              <MenuItem value={'swimming'}>Swimming</MenuItem>
              <MenuItem value={'running'}>Running</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Tabs
          value={currentTab}
          onChange={(event, newValue) => setCurrentTab(newValue)}
          sx={{
            mb: 2,
            '& .MuiButtonBase-root': {
              width: '100%',
              maxWidth: 160
            }
          }}>
          {TABS.map((tab) => (
            <Tab sx={{ flexShrink: '1' }} key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </Tabs>
        {TABS.map(
          (tab) => tab.value === currentTab && <Box key={tab.value}> {tab.component} </Box>
        )}
      </Container>
    </Box>
  );
};

export default Progress;

// const ftpHelpContent =
//   "Your FTP is your functional threshold pace, sometimes called CSS. We use this for scaling workouts for you and for determining your activity stress scores. Manually setting this to something tested with your coach will ensure the best results for you in Simma. Click here to read more about FTP.";
