import {useHistory, useParams} from 'react-router-dom'
import {useMutation, useQuery, useQueryClient} from "react-query";
// @mui
import {Box, Container} from "@mui/material";
// components
import {useAlertContext} from "../../../components/alert/AlertContext";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
import SetCreatorForm from "./SetCreatorForm";
import Spinner from "../../../components/common/Spinner";
import api from "../../../api";
import {trackSaveSet} from "../../../tracking";


const SetEditorPage = () => {
  const {setId} = useParams();

  const history = useHistory();

  const queryClient = useQueryClient();

  const {showAlert} = useAlertContext();

  const {isLoading, data} = useQuery(
    ["coachWorkout", setId],
    () => api.coaching.workoutSets.get(setId),
    {
      enabled: !!setId,
    }
  );

  const updateCoachWorkoutSetMutation = useMutation((data) => api.coaching.workoutSets.update(setId, data));

  const handleUpdateCoachSetWorkout = (data) => updateCoachWorkoutSetMutation.mutate(data, {
    onSuccess: (data) => {
      const {
        baseFtp: setBaseFtp,
        distance: setDistance,
        repeat: setRepeat,
        totalDistance: setTotalDistance,
        coachId,
        flavor: setFlavor,
        segmentCount: setSegmentCount,
        equipment: setEquipment,
      } = data;

      trackSaveSet({
        coachId,
        setBaseFtp,
        setDistance,
        setRepeat,
        setTotalDistance,
        setFlavor,
        setSegmentCount,
        setHasEquipment: !!setEquipment?.length,
        setEquipment,
        isEdit: true,
        setIsSplit: data.segments?.[0]?.isSplit,
      });
      queryClient.invalidateQueries({queryKey: ['coachSetsListData']});
      history.push('/coaching/training?tab=sets');
      showAlert('Coach workout set updated successfully.', {severity: 'success'});
    },
    onError: () => showAlert('Something went wrong.', {severity: 'error'}),
  });

  if (isLoading) {
    return <Spinner container/>;
  }

  if (!isLoading && !data) {
    history.push('/coaching/training');
  }


  return (
    <Box sx={{py: 1}}>
      <Container>
        <CustomBreadcrumbs
          heading="Set Creator"
          links={[
            {
              name: 'Training Tools',
              href: '/coaching/training'
            },
            {
              name: 'Create New Set',
            }
          ]}
        />

        <SetCreatorForm
          currenSet={data}
          onSubmit={handleUpdateCoachSetWorkout}
          isSubmitting={updateCoachWorkoutSetMutation.isLoading}
        />
      </Container>
    </Box>
  );
};

export default SetEditorPage;
