import * as coaches from "./coaches";
import * as coupons from "./coupons";
import * as errors from "./errors";

export const adminApi = {
  coaches,
  coupons,
  errors,
};

export default adminApi;
