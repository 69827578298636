// ----------------------------------------------------------------------

export default function Dialog(theme) {
  return {
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: theme.palette.background.dialog,
          backgroundImage: 'none',
          '&.MuiPaper-rounded': {
            borderRadius: Number(theme.shape.borderRadius) * 3,
          },
        },
      },
    },
  };
}
