import { useState } from "react";
import {
  Box,
  Typography,
  Stack,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { parsePrices } from "./utils";
import SubscribeButton from "./SubscribeButton";

const ProductBanner = ({
  title,
  product,
  coachId,
  productType,
  showDescription = true,
}) => {
  const prices = parsePrices(product);
  const [selectedPriceId, setSelectedPriceId] = useState(
    prices.year?.id || prices.month?.id
  );
  const handleCheck = (value, price) => {
    if (!!value) {
      setSelectedPriceId(price.id);
    }
  };

  return (
    <Box>
      <Stack
        direction="row"
        mb={4}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack gap={1}>
          <Typography variant="h5">
            {title}
          </Typography>

          {!!showDescription && !!product.description && (
            <Typography variant="body1">
              {product.description}
            </Typography>
          )}

          <FormGroup>
            <Stack direction="row">
              {!!prices.month && (
                <PriceCheckBox
                  price={prices.month}
                  onChange={handleCheck}
                  selectedPriceId={selectedPriceId}
                />
              )}
              {!!prices.year && (
                <PriceCheckBox
                  price={prices.year}
                  onChange={handleCheck}
                  selectedPriceId={selectedPriceId}
                />
              )}
            </Stack>
          </FormGroup>
        </Stack>
        <Stack direction="row" spacing={2}>
          <SubscribeButton
            coachId={coachId}
            text="Subscribe"
            priceId={selectedPriceId}
            productType={productType}
          />
        </Stack>
      </Stack>
    </Box>
  );
};
export default ProductBanner;

const PriceCheckBox = ({ price, onChange, selectedPriceId }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={price.id === selectedPriceId}
          onChange={(event, value) => onChange(value, price)}
        />
      }
      label={
        <Typography
          sx={{ fontSize: "1.25rem", lineHeight: "160%", opacity: 0.7 }}
        >
          {`${price.currency.toUpperCase()} ${price.unit_amount / 100} / ${
            price.recurring.interval
          }`}
        </Typography>
      }
    />
  );
};
