import {bgGradient} from "../../util";

// ----------------------------------------------------------------------

export default function Button(theme) {
  const rootStyle = (ownerState) => {
    const gradientVariant = ownerState.variant === 'gradient';

    const smallSize = ownerState.size === 'small';

    const largeSize = ownerState.size === 'large';

    const defaultStyle = {
      ...(gradientVariant && {
        color: theme.palette.text.primary,
        transition: theme.transitions.create('opacity', {duration: 250}) + '!important',
        borderRadius: Number(theme.shape.borderRadius) * 2,
        ...bgGradient({
          direction: 'to left',
          startColor: theme.palette.action.gradientStart,
          endColor: theme.palette.action.gradientEnd,
        }),
      }),
    };

    const size = {
      ...(smallSize && {
        height: 30,
        fontSize: 13,
      }),
      ...(largeSize && {
        height: 48,
        fontSize: 15,
      }),
    };

    return [defaultStyle, size];
  }

  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },

      styleOverrides: {
        root: ({ ownerState }) => rootStyle(ownerState),
      },
    },
  };
}
