import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme, { isMobile }) => {
	return {
		root: {
			paddingRight: '32px',
			paddingLeft: '16px'
		},
		header: {
			display: 'flex',
			flexDirection: 'column',
			marginBottom: isMobile ? '20px' : '24px',
			gap: '6px',
		},
		contentContainer: {
			display: 'flex',
			gap: '80px',
			[theme.breakpoints.down('md')]: {
				display: 'block'
			},
		},
		subHeader: {
			marginBottom: '15px',
			...(isMobile && {
				display: 'flex',
				flexDirection: 'column',
				gap: '6px',
			})
		},
		buttonsContainer: {
			display: 'flex',
			alignItems: 'center',
			gap: '40px',
			marginBottom: '20px'
		},
		chipContainer: {
			display: 'flex',
			gap: '8px'
		},
		chip: {
			backgroundColor: theme.palette.background.neutral,
			borderRadius: '8px',
			fontSize: '15px'
		},
		activeChip: {
			color: theme.palette.primary.main
		},
		greyText: {
			color: 'rgba(245, 245, 250, 0.72)',
		},
		handContainer: {
			display: 'flex',
			alignItems: 'center',
			gap: '5px'
		},
		handText: {
			fontSize: '15px',
			color: '#B0B0B6',
		},
		statsWrapper: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
		},
		statsContainer: {
			display: 'flex',
			gap: '40px',
		},
		square: {
			width: '16px',
			height: '16px',
			backgroundColor: theme.palette.primary.main,
			marginRight: '8px'
		},
		chartContainer: {
			height: '270px',
			width: isMobile ? '100%' : '342px',
			...(isMobile && { display: 'flex', justifyContent: 'center', marginBottom: '15px' })
		}

	}
})
