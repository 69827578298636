import React, {createContext, useCallback, useContext, useMemo, useState} from "react";
import PropTypes from "prop-types";
import SnackbarAlert from "./SnackbarAlert";


const initialState = {
  showAlert: () => {},
};

const defaultConfig = {
  open: false,
  message: '',
  severity: 'info',
};

export const AlertContext = createContext(initialState);

export const useAlertContext = () => {
  const context = useContext(AlertContext);

  if (!context) throw new Error('useAlertContext must be use inside AlertProvider');

  return context;
}

AlertProvider.propTypes = {
  children: PropTypes.node,
};

export function AlertProvider({children}) {
  const [alertData, setAlertData] = useState(defaultConfig);

  const handleClose = () => {
    setAlertData((prevState) => ({...prevState, open: false}));
  };

  const showAlert = useCallback((message, config) => {
    setAlertData({
      ...defaultConfig,
      open:true,
      message,
      ...config,
    });
  }, []);

  const value = useMemo(() => ({
    showAlert,
  }), [showAlert])


  return (
    <>
      <SnackbarAlert {...alertData} onClose={handleClose} />
      <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
    </>
  );
}