import { useState } from 'react';
// @mui
import { Box, Container, Tab, Tabs } from '@mui/material';
// components
import { useQueryParams } from '../../../hooks';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import WorkoutsList from './WorkoutsList';
import SetsList from './SetsList';
import Branding from './Branding';

const TrainingToolsPage = () => {
  const { tab } = useQueryParams();

  const [currentTab, setCurrentTab] = useState(tab || 'workouts');

  const TABS = [
    {
      value: 'workouts',
      label: 'Workouts',
      component: <WorkoutsList />
    },
    {
      value: 'sets',
      label: 'Sets',
      component: <SetsList />
    },
    {
      value: 'branding',
      label: 'Personalization',
      component: <Branding />
    }
  ];

  return (
    <Box sx={{ py: 1 }}>
      <Container>
        <CustomBreadcrumbs heading='Training Tools' />

        <Tabs
          value={currentTab}
          onChange={(event, newValue) => setCurrentTab(newValue)}
          sx={(theme) => ({
            mb: 2,
            '& .MuiButtonBase-root': {
              [theme.breakpoints.up('sm')]: {
                width: '100%'
              },
              maxWidth: 160
            }
          })}
          variant='scrollable'>
          {TABS.map((tab) => (
            <Tab key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </Tabs>

        {TABS.map(
          (tab) => tab.value === currentTab && <Box key={tab.value}> {tab.component} </Box>
        )}
      </Container>
    </Box>
  );
};

export default TrainingToolsPage;
