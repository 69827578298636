import { Box, styled, Typography } from '@mui/material';

const StyledRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.backgrounds[2]
}));

const Image = styled('img')({
  height: 'auto',
  //   maxHeight: '500px',
  maxWidth: '100%',
  width: '100%',
  objectFit: 'cover'
});

const LandingTestimonialSection = ({ title, subtitles, description, imageSrc }) => {
  return (
    <StyledRoot>
      <Box
        gap={{ xs: 3, md: 0 }}
        display='grid'
        alignItems='center'
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)'
        }}>
        {imageSrc && <Image src={imageSrc} alt='testimonial' />}
        <Box sx={{ px: 2, pb: { xs: 4, md: 0 } }}>
          <Box sx={{ maxWidth: 500, textAlign: 'center', margin: 'auto' }}>
            <Typography variant='h3'>{title}</Typography>
            {subtitles &&
              subtitles.map((subtitle, index) => (
                <Typography key={index} sx={{ display: 'block' }} variant='body3'>
                  {subtitle}
                </Typography>
              ))}
            <Typography variant='h6' sx={{ mt: { xs: 2, md: 4 } }}>
              {description}
            </Typography>
          </Box>
        </Box>
      </Box>
    </StyledRoot>
  );
};
export default LandingTestimonialSection;
