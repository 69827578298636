import { adminApi } from "../axios";

export const list = async () => {
	const { data } = await adminApi.get(`admin/coaches`);
	return data;
};

export const create = async (body) => {
	const { data } = await adminApi.post(`admin/coaches`, body);
	return data;
};

export const remove = async (id) => {
	const { data } = await adminApi.delete(`admin/coaches/${id}`);
	return data;
};

// Coach requests
export const listCoachRequests = async () => {
	const { data } = await adminApi.get(`admin/coach-requests`);
	return data;
};

export const deleteCoachRequest = async (email) => {
	const { data } = await adminApi.delete(`admin/coach-requests/${email}`);
	return data;
};

export const approveCoachRequest = async (email) => {
	const { data } = await adminApi.post(`admin/coach-requests/${email}/accept`);
	return data;
};

export const coachRequests = {
	list: listCoachRequests,
	remove: deleteCoachRequest,
	approve: approveCoachRequest,
};
