import {Box, Typography, Stack, Card, Button, Dialog} from "@mui/material";
import {useState} from "react";
import ProfileAgeForm from "../../../components/profile/ProfileAgeForm";


const AgeRange = ({user, refetch}) => {
  const [open, setOpen] = useState(false);

  const isSet = !!user.ageRange?.length;

  const handleClose = () => setOpen(false);

  const handleOpen = () => setOpen(true);


  return (
    <>
      <Card sx={{backgroundColor: "background.secondary", p: 2}}>
        <Stack gap={2}>
          <Typography variant="h5">
            Set your Age for Heart Rate Zones
          </Typography>
          <Typography variant="caption" color="text.secondary">
            Your age or age range is used by Simma to calculate your heart rate zones. Heart Rate zones are included on
            any heart rate charts in Simma based on your age and your heart rate zone will also be colourised for you if
            you use the Simma Apple Watch app.
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h5">
              {user?.ageRange?.join('-')}
            </Typography>
            <Button variant={isSet ? 'outlined' : 'contained'} onClick={handleOpen}>
              {isSet ? 'edit' : 'set'} your AGE
            </Button>
          </Box>
        </Stack>
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <ProfileAgeForm
          user={user}
          handleClose={handleClose}
          refetch={refetch}
        />
      </Dialog>
    </>
  )
}

export default AgeRange;