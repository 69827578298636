import * as React from "react";
// @mui
import {Stack, AppBar, Toolbar, IconButton, Typography} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
// config
import { HEADER } from '../config';
// hooks
import {useResponsive} from "../../../hooks";
import {UserContext} from "../../../context";
// components
import AccountPopover from "./AccountPopover";
import UpgradeState from "../../../components/common/UpgradeState";
import CoachChat from "../../../components/CoachChat";
import {ReactComponent as Logo} from "../../../images/logo.svg";

// ----------------------------------------------------------------------

const Header = ({ onOpenNav, isCoachLayout }) => {
  const isDesktop = useResponsive('up', 'lg');

  const { user } = React.useContext(UserContext);

  const renderContent = (
    <>
      <IconButton onClick={onOpenNav} sx={{ mr: 2, color: 'primary.main' }}>
        <MenuIcon />
      </IconButton>

      {isCoachLayout ? (
        <Typography variant="h6" color="primary">Simma: Coaching</Typography>
      ) : (
        <Logo height={36} width={120} />
      )}

      <Stack flexGrow={1} direction="row" alignItems="center" justifyContent="flex-end" spacing={{ xs: 0.5, sm: 1.5 }}>
        <UpgradeState buttonOnly />

        {!isCoachLayout && !!user?.coachChatChannel && (
          <CoachChat
            channel={user.coachChatChannel}
            chatUserId={user.id}
            size={22}
          />
        )}

        <AccountPopover user={user} />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        ...(isDesktop && ({
          height: HEADER.H_DESKTOP,
        }))
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 2 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}

export default Header;