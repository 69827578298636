import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Skeleton,
  Grid,
  Box,
  Stack,
  Typography,
  Button,
  Checkbox,
} from "@mui/material";
import { useQuery, useMutation } from "react-query";
import api from "api";
import { Edit, Delete } from "@mui/icons-material";

// Local modules
import { secondsToHMS } from "../../util";
import {
  trackEditSet,
  trackStartCreateSet,
  trackDeleteSet,
} from "../../tracking";

import ConfirmDialog from "../ConfirmDialog";
import EquipmentIconList from "../EquipmentIconList";

const CoachingSets = ({ selector, handleClose, processSelect }) => {
  const { data, isLoading, refetch } = useQuery(
    "coachSets",
    api.coaching.workoutSets.list
  );
  const [selected, setSelected] = useState(null);

  const handleSelect = (id) => {
    if (selected === id) {
      setSelected(null);
    } else {
      setSelected(id);
    }
  };

  const closeSelect = () => {
    if (!!selected) {
      processSelect(selected);
    }
    handleClose();
  };

  return (
    <Container>
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
        pt={2}
      >
        <Typography variant="h4">Sets Library</Typography>
        {!!selector ? (
          <div>
            <Button variant="neutral" onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button disabled={!selected} onClick={closeSelect}>
              Add Set
            </Button>
          </div>
        ) : (
          <Link
            to="/coaching/sets/create"
            className="gradient-text"
            style={{ fontSize: "1.25rem" }}
            onClick={() => trackStartCreateSet()}
          >
            + Create New Set
          </Link>
        )}
      </Stack>
      <Grid
        container
        columns={12}
        spacing={3}
        sx={{ flexGrow: 1 }}
        pt={3}
        pb={3}
      >
        {isLoading ? (
          <>
            <LoaderCard />
            <LoaderCard />
            <LoaderCard />
          </>
        ) : (
          data.map((set) => (
            <SetCard
              selector={selector}
              key={set.id}
              set={set}
              refetch={refetch}
              selected={selected}
              handleSelect={handleSelect}
            />
          ))
        )}
      </Grid>
    </Container>
  );
};

export default CoachingSets;

const SetCard = ({ set, refetch, selector, selected, handleSelect }) => {
  const [open, setOpen] = useState(false);
  const mutation = useMutation(api.coaching.workoutSets.remove, {
    onSuccess: () => {
      setOpen(false);
      refetch();
      trackDeleteSet();
    },
  });

  const handleDelete = () => {
    mutation.mutate(set.id);
  };
  const handleChange = () => {
    handleSelect(set.id);
  };

  return (
    <>
      <Grid item xs={12} md={4}>
        <Stack p={2} sx={{ bgcolor: "backgrounds.2" }}>
          <Stack
            direction={"row"}
            pb={1}
            sx={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Typography variant="h5">{set.name}</Typography>
            {!!selector && (
              <Box>
                <Checkbox
                  checked={set.id === selected}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Box>
            )}
          </Stack>
          <Grid container columns={2} spacing={1} sx={{ flexGrow: 1 }}>
            <InfoItem title="Flavor" text={set.flavor} />
            <InfoItem title="Base FTP" text={secondsToHMS(set.baseFtp)} />
            <InfoItem title="Distance" text={set.distance} />
            <InfoItem title="Segments" text={set.segmentCount} />
            <Grid item xs={2} md={2}>
              <Stack direction="row" sx={{ alignItems: "center" }}>
                <Box
                  pr={1}
                  sx={{
                    fontSize: "0.875rem",
                    fontWeight: "600",
                    opacity: ".6",
                  }}
                >
                  Equipment:
                </Box>
                <EquipmentIconList equipment={set.equipment} fontSize={20} />
              </Stack>
            </Grid>
          </Grid>
          <Stack
            direction="row"
            spacing={1}
            sx={{ justifyContent: "flex-end" }}
          >
            {!selector && (
              <>
                <Link
                  to={`/coaching/sets/edit/${set.id}`}
                  onClick={() => trackEditSet()}
                >
                  <Edit sx={{ fontSize: 16, opacity: 0.6 }} />
                </Link>
                <button onClick={() => setOpen(true)}>
                  <Delete sx={{ fontSize: 16, opacity: 0.6, color: "white" }} />
                </button>
              </>
            )}
          </Stack>
        </Stack>
      </Grid>
      <ConfirmDialog
        title={`Delete ${set.name}?`}
        text="You can't undo this action. Workouts you imported this set into will not be affected."
        confirmText="Delete Set"
        open={open}
        handleClose={() => setOpen(false)}
        handleConfirm={handleDelete}
        disabled={mutation.isLoading}
      />
    </>
  );
};

const InfoItem = ({ title, text, fullWidth }) => (
  <Grid item xs={2} md={fullWidth ? 2 : 1}>
    <p>
      <span style={{ fontSize: "0.875rem", fontWeight: "600", opacity: ".6" }}>
        {title}:
      </span>{" "}
      {text}
    </p>
  </Grid>
);

const LoaderCard = () => (
  <Grid item xs={12} md={4}>
    <Stack sx={{ background: "black" }} height={184} direction="row">
      <Box width={184} height={184}>
        <Skeleton variant="rectangular" height={"100%"} width={"100%"} />
      </Box>
      <Box sx={{ flex: 1 }} p={2}>
        <Typography variant="h3">
          <Skeleton />
        </Typography>
        <Skeleton type="text" />
        <Skeleton type="text" />
      </Box>
    </Stack>
  </Grid>
);
