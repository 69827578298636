import useStyles from './index.styles'

import { useState } from 'react';

import {
	Bar,
	BarChart,
	CartesianGrid,
	ReferenceLine,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from "recharts";
import { Box, Chip, Stack, Typography } from "@mui/material";

import {
	resolveTextColor,
	parseChartData,
	resolveEfficiencyEntrySplashText,
	CHART_TEXT,
} from "../../utils";

import { HandIcon } from "../../../../assets/icons";
import CustomToolTip from "../../../charts/CustomToolTip";

const HandEntrySplash = ({ data, isMobile, features, hand, handEntrySplash }) => {

	const { classes } = useStyles({ isMobile })

	const blah = parseChartData(features, handEntrySplash, CHART_TEXT.HAND_ENTRY_SPLASH)

	return (
		<Box className={ classes.root }>
			<Box className={ classes.header }>
				<Typography variant={ isMobile ? 'h5' : 'h4' }>Hand Entry Splash</Typography>
				<Typography variant={ 'body2' } className={ classes.greyText }>Entry Splash measures the acceleration of
					your hand as it enters the water expressed as a measure of g-force. </Typography>
			</Box>
			<Box className={ classes.subHeader }>
				<Typography variant={ 'h6' }>Analysis</Typography>
				<Typography variant={ 'body2' } className={ classes.greyText }>Data is compared to your
					"normal"</Typography>
			</Box>
			<Box className={ classes.buttonsContainer }>
				<Box className={ classes.chipContainer }>
					<Chip
						key={ 'Free' }
						className={ classes.activeChip }
						label={ 'Free' }
					/>
				</Box>
				<Box className={ classes.handContainer }>
					<HandIcon type={ hand }/>
					<Typography
						className={ classes.handText }>{ hand.charAt(0).toUpperCase() + hand.slice(1) } hand</Typography>
				</Box>
			</Box>
			<Box className={ classes.statsWrapper }>
				<Box className={ classes.statsContainer }>
					<Stack>
						<Typography variant={ 'h5' }>
							{ `${ data[handEntrySplash].mean }g` }
						</Typography>
						<Typography variant={ 'caption' } sx={ { color: 'rgba(235, 235, 245, 0.6)' } }>
							Avg. this swim
						</Typography>
					</Stack>
					<Stack>
						<Typography sx={ { color: resolveTextColor(data[handEntrySplash].similarityColor) } }
									variant={ 'h5' }>
							{ resolveEfficiencyEntrySplashText(data[handEntrySplash].similarityColor) }
						</Typography>
						<Typography variant={ 'caption' } sx={ { color: 'rgba(235, 235, 245, 0.6)' } }>
							{ `${ (data[handEntrySplash].similarityPercent) }% p-value` }
						</Typography>
					</Stack>
				</Box>
				<Box className={ classes.graphStatsContainer }>
					<Box className={ classes.square }></Box>
					<Typography sx={ { fontSize: '11px', marginRight: '17px' } }>Hand entry splash</Typography>
					<Typography sx={ {
						fontSize: '11px',
						marginRight: '8px'
					} }>{ data[handEntrySplash].normal + 'g' }</Typography>
					<Typography sx={ { fontSize: '11px', marginRight: '8px' } }>Your ‘normal’</Typography>
				</Box>
			</Box>
			<Box className={ classes.chartContainer }>
				<ResponsiveContainer width="100%" height="100%">
					<BarChart
						data={ blah.data }
						margin={ { top: 0, right: 0, left: 0, bottom: 0 } }
					>
						<CartesianGrid stroke={ 'rgba(255, 255, 255, 0.14)' }/>
						<XAxis ticks={ blah.ticks }/>
						<YAxis width={ 35 } axisLine={ false } tickLine={ false }/>
						<Tooltip content={ <CustomToolTip/> }/>
						<Bar dataKey={ CHART_TEXT.HAND_ENTRY_SPLASH } fill="#FF5082"/>
						<ReferenceLine y={ blah.normal } stroke="white" strokeDasharray="3 3"/>
					</BarChart>
				</ResponsiveContainer>
			</Box>
			<Stack gap={ '8px' }>
				<Typography variant={ 'h6' }>About Hand Entry Splash</Typography>
				<Typography variant={ 'body1' } className={ classes.greyText }>Hand Entry splash will change as you add
					more force to the catch of your stroke, often when sprinting. In general a smoother entry creates
					less resistance to the forward propulstion you are looking to achieve with your swimming. Some
					amount of force at entry is necessary for a longer glide after entry before beginning the pull
					through. </Typography>
			</Stack>
		</Box>
	);
};

export default HandEntrySplash;
