import {useState} from "react";
import {useMutation, useQuery} from "react-query";
import {useHistory} from "react-router-dom";
import {v4 as uuid} from "uuid";
// @mui
import {
  Box,
  Button,
  Drawer,
  Checkbox,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
// icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
// components
import {SkeletonWorkoutItem} from "../../../../components/skeleton";
import {useAlertContext} from "../../../../components/alert/AlertContext";
import {ConfirmDialog} from "../../../../components/confirm-dialog";
import SetCard from "../../../../components/coaching/SetCard";
import {trackDeleteSet} from "../../../../tracking";
import {StyledIconButton} from "./WorkoutSetsStep";
import api from "../../../../api";

// ----------------------------------------------------------------------

const SetActions = ({onEdit, onDelete}) => (
  <Box display="flex" height="100%" alignItems="end" justifyContent="end" gap={2}>
    <StyledIconButton size="small" onClick={onEdit}>
      <EditIcon fontSize="small"/>
    </StyledIconButton>
    <StyledIconButton size="small" onClick={onDelete}>
      <DeleteIcon fontSize="small"/>
    </StyledIconButton>
  </Box>
);

const CoachingSetsDrawer = ({open, onClose, onAdd}) => {
  const history = useHistory();

  const {showAlert} = useAlertContext();

  const {data, isLoading, refetch} = useQuery(
    "coachSets",
    api.coaching.workoutSets.list,
    {
      enabled: !!open,
    }
  );


  const deleteWorkoutSetMutation = useMutation(api.coaching.workoutSets.remove);

  const [selected, setSelected] = useState([]);

  const [isLoadingLibrary, setIsLoadingLibrary] = useState(false);

  const [deleteDialog, setDeleteDialog] = useState({open: false, id: undefined});

  const isNothingFound = !isLoading && !data?.length;

  const handleOpenDialog = (id) => {
    setDeleteDialog({open: true, id});
  }

  const handleCloseDialog = () => {
    setDeleteDialog({open: false, id: undefined});
  }

  const handleCloseDrawer = () => {
    setSelected([]);
    onClose();
  }

  const handleDeleteWorkoutSet = (data) => deleteWorkoutSetMutation.mutate(data, {
    onSuccess: () => {
      refetch();
      trackDeleteSet();
      setSelected([]);
      handleCloseDialog();
      showAlert('Coach set successfully deleted.', {severity: 'success'});
    },
    onError: () => showAlert('Something went wrong.', {severity: 'error'}),
  });

  const handleOnSelect = (selectedId) => {
    setSelected((prevState) => prevState.includes(selectedId)
      ? prevState.filter(id => id !== selectedId)
      : [...prevState, selectedId]);
  }

  const handleOnAdd = async () => {
    setIsLoadingLibrary(true);
    const newSets = await Promise
      .all(selected.map((id) => api.coaching.workoutSets.get(id)))
      .then((response) => response.map(set => {
        set.setTemplateId = set.id;
        set.id = uuid();
        return set;
      }))
      .finally(() => setIsLoadingLibrary(false));
    onAdd(newSets);
    handleCloseDrawer();
  }

  const handleOnEdit = (id) => {
    history.push(`/coaching/sets/edit/${id}`)
  }


  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={handleCloseDrawer}
      BackdropProps={{
        invisible: true,
      }}
    >
      <Box sx={{
        backgroundColor: "background.default",
        height: "100vh",
        display: 'flex',
        flexDirection: 'column',
        p: 2,
        gap: 2,
      }}>
        <Box display="flex" justifyContent="space-between">
          <Button variant="outlined" onClick={handleCloseDrawer}>close</Button>
          <LoadingButton
            variant="contained"
            disabled={!selected.length || isLoadingLibrary}
            loading={isLoadingLibrary}
            onClick={handleOnAdd}
          >
            add to workout
          </LoadingButton>
        </Box>

        {open && (
          <Box
            sx={{
              gridTemplateColumns: {md: 'repeat(3, 1fr)'},
              display: "grid",
              columnGap: 1,
              rowGap: 1.5,
              maxWidth: 1097,
              width: "100%",
              margin: "0 auto",
            }}
          >
            {(isLoading && !isNothingFound ? [...Array(12)] : data).map((item, index) =>
              item ? (
                <SetCard
                  key={item.id}
                  data={item}
                  toolbar={(
                    <Checkbox checked={selected.includes(item.id)} onChange={() => handleOnSelect(item.id)}/>
                  )}
                  actions={(
                    <SetActions onEdit={() => handleOnEdit(item.id)} onDelete={() => handleOpenDialog(item.id)}/>
                  )}
                />
              ) : (
                <SkeletonWorkoutItem key={index}/>
              )
            )}
          </Box>
        )}

        <ConfirmDialog
          open={deleteDialog.open}
          onClose={handleCloseDialog}
          title="Delete set?"
          content="You can't undo this action. Workouts you imported this set into will not be affected."
          action={
            <LoadingButton
              loading={deleteWorkoutSetMutation.isLoading}
              disabled={deleteWorkoutSetMutation.isLoading}
              onClick={() => handleDeleteWorkoutSet(deleteDialog.id)}
              variant="contained"
            >
              Delete
            </LoadingButton>
          }
        />
      </Box>
    </Drawer>
  );
}

export default CoachingSetsDrawer;
