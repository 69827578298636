import {useContext, useState} from "react";
import {useMutation} from "react-query";
import {useHistory} from "react-router-dom";
// @mui
import {Box, Container, Step, StepLabel, Stepper} from "@mui/material";
// components
import {useAlertContext} from "../../../components/alert/AlertContext";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
import {WorkoutCreatorForm} from "./form";
import api from "../../../api";
import {CoachContext} from "../../../context";
import {trackSaveWorkout} from "../../../tracking";


const steps = ['General Information', 'Workout Sets'];

const WorkoutCreatorStepper = ({activeStep}) => (
  <Stepper sx={{mb: 3}} activeStep={activeStep}>
    {steps.map((label) => (
      <Step key={label}>
        <StepLabel>{label}</StepLabel>
      </Step>
    ))}
  </Stepper>
);

const WorkoutCreatorPage = () => {
  const history = useHistory();

  const {showAlert} = useAlertContext();

  const {coach, refetch: refetchCoach} = useContext(CoachContext);

  const createCoachWorkoutMutation = useMutation(api.coaching.workouts.create);

  const [activeStep, setActiveStep] = useState(0);

  const handleOnNextStep = () => setActiveStep(prevStep => prevStep + 1 < steps.length ? prevStep + 1 : prevStep);

  const handleOnPrevStep = () => setActiveStep(prevStep => prevStep - 1 <= 0 ? prevStep - 1 : prevStep);

  const handleCreateCoachWorkout = (data) => createCoachWorkoutMutation.mutate(data, {
    onSuccess: (data) => {
      refetchCoach();
      const {
        coachId,
        distance: workoutDistance,
        duration: workoutDuration,
        environment: workoutEnvironment,
        equipment: workoutEquipment,
        isTest: workoutIsTest,
        levels: workoutLevels,
        published: workoutIsPublished,
        tags: workoutTags,
      } = data;

      trackSaveWorkout({
        isEdit: false,
        coachId,
        workoutDistance,
        workoutDuration,
        workoutEnvironment,
        workoutIsTest,
        workoutLevels,
        workoutIsPublished,
        workoutSetCount: data.sets?.length,
        workoutHasEquipment: !!workoutEquipment.length,
        workoutEquipment,
        workoutHasTags: !!workoutTags.length,
        workoutTags,
      });
      history.push('/coaching/training');
      showAlert('Coach workout created successfully.', {severity: 'success'});
    },
    onError: () => showAlert('Something went wrong.', {severity: 'error'}),
  });


  return (
    <Box sx={{py: 1}}>
      <Container>
        <CustomBreadcrumbs
          heading="Workout Creator"
          links={[
            {
              name: 'Training Tools',
              href: '/coaching/training'
            },
            {
              name: 'Create New Workout',
            }
          ]}
        />

        <WorkoutCreatorStepper activeStep={activeStep}/>

        <WorkoutCreatorForm
          onSubmit={handleCreateCoachWorkout}
          isSubmitting={createCoachWorkoutMutation.isLoading}
          workoutTags={coach?.workoutTags}
          activeStep={activeStep}
          onNextStep={handleOnNextStep}
          onPrevStep={handleOnPrevStep}
        />
      </Container>
    </Box>
  );
};

export default WorkoutCreatorPage;
