import { useState } from 'react';
import {
  Container,
  Skeleton,
  Grid,
  Box,
  Stack,
  Typography,
  Chip,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Image from '@jy95/material-ui-image';
import { useQuery, useMutation } from 'react-query';
import api from 'api';
import { Link } from 'react-router-dom';
import { Edit, Delete, Circle } from '@mui/icons-material';
import WorkoutSortFilterBar from 'components/SortFilters/WorkoutSortFilterBar';

import colors from '../../style/colors';
import {
  trackStartCreateWorkout,
  trackEditWorkout,
  trackDeleteWorkout,
} from '../../tracking';

const uniqueTags = (workouts) => {
  const tags = [];
  workouts.forEach((workout) => {
    workout.tags.forEach((tag) => {
      if (!tags.includes(tag)) {
        tags.push(tag);
      }
    });
  });
  return tags.sort((a, b) => a.localeCompare(b));
};

const CoachingWorkouts = () => {
  const [visibleWorkouts, setVisibleWorkouts] = useState([]);
  const [tags, setTags] = useState([]);
  const { data, isLoading, refetch } = useQuery(
    'coachWorkouts',
    api.coaching.workouts.list,
    {
      onSuccess: (data) => {
        if (!visibleWorkouts.length) {
          setVisibleWorkouts(data);
        }
        setTags(uniqueTags(data));
      },
    }
  );

  return (
    <Container>
      <Stack
        direction='row'
        sx={{ justifyContent: 'space-between', alignItems: 'center' }}
        pt={2}
      >
        <Typography variant='h4'>Workouts Library</Typography>
        <Link
          to='/coaching/workouts/create'
          className='gradient-text'
          style={{ fontSize: '1.25rem' }}
          onClick={() => trackStartCreateWorkout()}
        >
          + Create New Workout
        </Link>
      </Stack>
      <WorkoutSortFilterBar
        workouts={data}
        visibleWorkouts={visibleWorkouts}
        setWorkouts={setVisibleWorkouts}
        tags={tags}
      />
      <Grid
        container
        columns={12}
        spacing={3}
        sx={{ flexGrow: 1 }}
        pt={3}
        pb={3}
      >
        {isLoading ? (
          <>
            <LoaderCard />
            <LoaderCard />
          </>
        ) : (
          visibleWorkouts.map((workout) => (
            <WorkoutItem key={workout.id} workout={workout} refetch={refetch} />
          ))
        )}
      </Grid>
    </Container>
  );
};

export default CoachingWorkouts;

const WorkoutItem = ({ workout, refetch }) => {
  const [open, setOpen] = useState(false);
  const mutation = useMutation(api.coaching.workouts.remove, {
    onSuccess: (data) => {
      setOpen(false);
      refetch();
      trackDeleteWorkout();
    },
  });
  const levels = [null, null, 'Beginner', 'Intermediate', 'Advanced'];
  const chipColors = [
    null,
    null,
    colors.greenTint,
    colors.blueTint,
    colors.pinkTint,
  ];
  return (
    <>
      <Grid item xs={12} md={6}>
        <Stack
          sx={{ background: 'rgba(44, 61, 68, 0.85)' }}
          height={184}
          direction='row'
        >
          <Box width={184} height={184}>
            <Image
              src={workout.imageThumbnailUrl}
              style={{ background: 'none' }}
              cover
            />
          </Box>
          <Stack sx={{ flex: 1 }} p={2}>
            <Stack
              direction='row'
              sx={{ justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Typography variant='h5'>{workout.title}</Typography>
              <Box>
                {workout.levels.map((level) => (
                  <Chip
                    label={levels[level]}
                    size='small'
                    sx={{ background: chipColors[level], fontSize: '.7rem' }}
                  />
                ))}
              </Box>
            </Stack>

            <Box sx={{ flex: 1 }}>
              <Grid
                container
                columns={12}
                spacing={1}
                sx={{ flexGrow: 1 }}
                pt={1}
              >
                <InfoItem title='Environment' text={workout.environment} />
                <InfoItem title='Time' text={workout.durationDisplay} />
                <InfoItem title='Distance' text={workout.distance} />
                <InfoItem title='Base FTP' text={workout.baseFtpDisplay} />
                {!!workout.tags && (
                  <InfoItem
                    title='Tags'
                    text={workout.tags.join(', ')}
                    fullWidth
                  />
                )}
              </Grid>
            </Box>
            <Stack
              direction='row'
              spacing={1}
              // color="error"
              sx={{
                alignItems: 'center',
                fontSize: '14px',
                color: workout.published ? 'success.main' : 'error.main',
              }}
            >
              <Circle sx={{ fontSize: 16 }} />{' '}
              <Stack
                direction='row'
                sx={{
                  justifyContent: 'space-between',
                  alignContent: 'center',
                  width: '100%',
                }}
              >
                <Box>{!workout.published && 'Not '}Published</Box>
                <Stack direction='row' spacing={1}>
                  <Link
                    to={`/coaching/workouts/edit/${workout.id}`}
                    onClick={() => trackEditWorkout()}
                  >
                    <Edit sx={{ fontSize: 16, opacity: 0.6 }} />
                  </Link>
                  <Link
                    to={`/coaching/workouts/edit/${workout.id}`}
                    onClick={() => trackEditWorkout()}
                  >
                    <Edit sx={{ fontSize: 16, opacity: 0.6 }} />
                  </Link>
                  <button onClick={() => setOpen(true)}>
                    <Delete
                      sx={{ fontSize: 16, opacity: 0.6, color: 'white' }}
                    />
                  </button>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Grid>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Delete this workout?</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            You can't undo this action and workout will be no longer be
            available to your swimmers.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color='neutral'
            disabled={mutation.isLoading}
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            disabled={mutation.isLoading}
            onClick={() => mutation.mutate(workout.id)}
            autoFocus
            color='danger'
          >
            Delete workout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const InfoItem = ({ title, text, fullWidth }) => (
  <Grid item xs={12} md={fullWidth ? 12 : 6}>
    <p>
      <span style={{ fontSize: '0.875rem', fontWeight: '600', opacity: '.6' }}>
        {title}:
      </span>{' '}
      {text}
    </p>
  </Grid>
);

const LoaderCard = () => (
  <Grid item xs={12} md={6}>
    <Stack sx={{ background: 'black' }} height={184} direction='row'>
      <Box width={184} height={184}>
        <Skeleton variant='rectangular' height={'100%'} width={'100%'} />
      </Box>
      <Box sx={{ flex: 1 }} p={2}>
        <Typography variant='h3'>
          <Skeleton />
        </Typography>
        <Skeleton type='text' />
        <Skeleton type='text' />
      </Box>
    </Stack>
  </Grid>
);
