import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme, { isMobile }) => ({
	browserContainer: {
		maxWidth: '100%',
		margin: '0 auto',
		padding: '2rem 1rem',
		width: '70rem'
	},
	emptyActivitiesContainer: {
		display: "flex",
		justifyContent: "center"
	},
	header: {
		display: 'flex',
		gap: '24px',
		marginBottom: '38px'

	},

	activitySelect: {
		width: isMobile ? '205px' : '240px',
	}

}))
