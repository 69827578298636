import { useRef } from 'react';

import { Dialog, DialogContent, IconButton } from '@mui/material';

import { useOnClickOutside } from '../hooks';
import colors from '../style/colors';

import { CrossIcon } from '../assets/icons';

const Modal = ({ open, handleClose, styles, children, fullScreen = false }) => {
  const ref = useRef();
  useOnClickOutside(ref, () => handleClose());

  return (
    <Dialog
      fullScreen={fullScreen}
      sx={{
        width: 'auto',
        '& .MuiPaper-rounded': {
          borderRadius: fullScreen ? '0 !important' : '8px !important',
          backgroundColor: colors.backgroundTertiary,
          minWidth: '375px',
          maxWidth: '880px',
          p: '0'
        },
        ...styles
      }}
      open={open}
      onClose={handleClose}>
      <IconButton
        disableRipple
        onClick={handleClose}
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          top: fullScreen ? '2px' : '12px',
          right: fullScreen ? '4px' : '12px',
          zIndex: 10000
        }}>
        <CrossIcon />
      </IconButton>
      <DialogContent sx={{ p: '0' }}>{children}</DialogContent>
    </Dialog>
  );
};

export default Modal;

{
  /*<div className="modal-background" style={ { display: open ? 'block' : 'none' } }>*/
}
{
  /*	<div className="modal-inner-wrapper">*/
}
{
  /*		<div className="modal-content" ref={ ref } style={ { ...styles } }>*/
}
{
  /*			<Box onClick={ handleClose }*/
}
{
  /*				 sx={ {*/
}
{
  /*					 position: 'absolute',*/
}
{
  /*					 top: '20px',*/
}
{
  /*					 right: '20px',*/
}
{
  /*					 zIndex: '10000',*/
}
{
  /*					 cursor: 'pointer'*/
}
{
  /*				 } }>*/
}
{
  /*				<CrossIcon/>*/
}
{
  /*			</Box>*/
}
{
  /*			{ children }*/
}
{
  /*		</div>*/
}
{
  /*	</div>*/
}
{
  /*</div>*/
}
