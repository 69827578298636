// @mui
import { Typography, Box, Stack, Card, CardMedia, Chip, ButtonBase, Link } from '@mui/material';
import { LockOutlined } from '@mui/icons-material';
// icons
import DurationIcon from '../Icons/DurationIcon';
import DistanceIcon from '../Icons/DistanceIcon';
import { environmentIcons, equipmentIcons } from './utils';

const levelChip = {
  2: <Chip label='Beginner' variant='outlined' color='success' size='small' />,
  3: (
    <Chip
      label='Intermediate'
      variant='outlined'
      sx={{ color: 'warning.light', borderColor: 'warning.light' }}
      size='small'
    />
  ),
  4: <Chip label='Advanced' variant='outlined' color='warning' size='small' />,
  5: <Chip label='Elite' variant='outlined' color='error' size='small' />
};

const IconTypography = ({ icon, variant, sx, children }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, ...sx }}>
    {icon}
    <Typography variant={variant}>{children}</Typography>
  </Box>
);

const WorkoutCard = ({
  data,
  lapUnit,
  showCoachLink,
  isLocked = false,
  onCoachClick,
  toolbar,
  sx,
  ...other
}) => {
  const distance = data.distance + (lapUnit || '');

  const renderContent = (
    <Box display='flex' height='100%' gap={2}>
      <Stack gap={1.5}>
        <CardMedia component='img' image={data.imageThumbnailUrl} sx={{ width: 90, height: 90 }} />
        {levelChip[data.level || data.levels?.[0]]}
      </Stack>
      <Stack gap={1.25} width='100%'>
        <Box>
          <Stack flexDirection={'row'} gap={1.25} alignItems={'center'}>
            <Typography variant='h6'>{data.title}</Typography>
            {isLocked && <LockOutlined />}
          </Stack>
          {showCoachLink && data.coach && (
            // <Link
            //   onClick={(e) => {
            //     e.stopPropagation();
            //     onCoachClick(data.coach.id);
            //   }}>
            //   {data.coach.firstName} {data.coach.lastName}
            // </Link>
            <Typography sx={{ opacity: 0.5 }}>
              {data.coach.firstName} {data.coach.lastName}
            </Typography>
          )}
        </Box>
        <Stack direction='row' justifyContent='space-between'>
          <IconTypography icon={<DistanceIcon />} variant='body1'>
            {distance}
          </IconTypography>
          <IconTypography icon={<DurationIcon />} variant='body1'>
            {data.durationDisplay}
          </IconTypography>
          <IconTypography
            sx={{
              textTransform: 'capitalize',
              display: { xs: 'none', sm: 'inherit' }
            }}
            icon={environmentIcons[data.environment]}
            variant='body1'>
            {data.environment}
          </IconTypography>
        </Stack>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexFlow: 'wrap',
            gap: 1
          }}>
          {data.equipment?.map((key) => {
            const EquipmentIcon = equipmentIcons[key];
            return EquipmentIcon && <EquipmentIcon key={key} />;
          })}
          <IconTypography
            sx={{
              textTransform: 'capitalize',
              display: { xs: 'inherit', sm: 'none' }
            }}
            icon={environmentIcons[data.environment]}
            variant='body1'>
            {data.environment}
          </IconTypography>
        </Box>
        <Box sx={{ display: 'flex', flexFlow: 'wrap', gap: 1 }}>
          {data.tags.map((tag, index) => (
            <Chip key={index} label={tag} variant='outlined' size='small' />
          ))}
        </Box>
        {toolbar}
      </Stack>
    </Box>
  );

  if (other.href || other.onClick) {
    return (
      <Card
        component={ButtonBase}
        sx={{
          textAlign: 'left',
          display: 'block',
          p: 2,
          backgroundColor: 'background.secondary',
          ...sx
        }}
        {...other}>
        {renderContent}
      </Card>
    );
  }

  return (
    <Card sx={{ p: 2, backgroundColor: 'background.secondary', ...sx }} {...other}>
      {renderContent}
    </Card>
  );
};

export default WorkoutCard;
