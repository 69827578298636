import useStyles from './index.styles'

import React from 'react';
import { Box, Stack, Typography } from "@mui/material";
import HelpIconTooltip from "../../../../components/common/HelpIconTooltip";
import Divider from "@mui/material/Divider";
import { useResponsive } from "../../../../hooks";

const Card = ({ children, helpContent, title, titleVariant = 'h4', divider = false }) => {

	const isMobile = useResponsive('down', 'sm');
	const { classes } = useStyles()

	return (
		<Box className={ classes.root }>
			<Box className={ classes.content }>
				{ !!title && (
					<Stack className={ classes.title }>
						<Typography variant={ isMobile ? 'h5' : titleVariant }>{ title }</Typography>
						{ helpContent && <HelpIconTooltip content={ helpContent }/> }
					</Stack>
				) }
				{ children }
				{ divider && !isMobile && <Box sx={ { width: '100%', display: 'flex', justifyContent: 'center' } }>
					<Divider sx={ { width: '85%' } }/>
				</Box> }
			</Box>
		</Box>
	);
};

export default Card;
