import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import { isBrowser, isMobile } from 'react-device-detect';

const ActivityMap = ({ activity }) => {
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [polylines, setPolylines] = useState([]);

  const isSwimActivity = activity.activityType === 'swimming';

  useEffect(() => {
    if (!!map && !!maps) {
      setupMap(map, maps);
    }
  }, [activity]);

  const fitBounds = (map, maps, markers) => {
    let bounds = new maps.LatLngBounds();
    for (let marker of markers) {
      bounds.extend(new maps.LatLng(marker.lat, marker.lng));
    }
    map.fitBounds(bounds);
  };

  const setupMap = (map, maps) => {
    polylines.forEach((line) => line.setMap(null)); // Clear exising lines if map already mounted
    const markers = []; // create full marker array to set bounds of map

    activity.gps.trace.lat.forEach((lat, i) => {
      markers.push({ lat, lng: activity.gps.trace.lon[i] });
    });

    const polyline = new maps.Polyline({
      path: markers,
      strokeColor: '#FF5082',
      strokeWeight: 2
    });

    polyline.setMap(map);

    setPolylines([polyline]); // Set new polylines to state to be able to clear them
    fitBounds(map, maps, markers); // Reset bounds/zoom of map
  };

  const onMapLoaded = (map, maps) => {
    setMap(map);
    setMaps(maps);
    setupMap(map, maps);
  };

  const getMapOptions = (map) => {
    return {
      mapTypeId: map.MapTypeId.SATELLITE
    };
  };

  return (
    <div
      className='activity-map'
      style={{
        width: '100%',
        height: isMobile ? '225px' : '500px',
        position: 'relative'
      }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
        defaultCenter={[activity.gps.start.lat, activity.gps.start.lon]}
        defaultZoom={16}
        onGoogleApiLoaded={({ map, maps }) => onMapLoaded(map, maps)}
        options={getMapOptions}>
        <Start lat={activity.gps.start.lat} lng={activity.gps.start.lon} />
        <End lat={activity.gps.end.lat} lng={activity.gps.end.lon} />
      </GoogleMapReact>
    </div>
  );
};

ActivityMap.propTypes = {
  activity: PropTypes.object.isRequired
};

export default ActivityMap;

const Start = () => {
  return (
    <div style={{ transform: 'translate(-50%, -50%)' }}>
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M9.96884 18.2804C14.6327 18.2804 18.4142 14.5013 18.4142 9.8385C18.4142 5.1757 14.6327 1.39655 9.96884 1.39655C5.30501 1.39655 1.52344 5.1757 1.52344 9.8385C1.52344 14.5013 5.30501 18.2804 9.96884 18.2804Z'
          fill='#1ADEA3'
          stroke='white'
          strokeWidth='2'
        />
      </svg>
    </div>
  );
};

const End = () => {
  return (
    <div style={{ transform: 'translate(-50%, -50%)' }}>
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'>
        <circle cx='10' cy='10' r='9' fill='url(#pattern0)' stroke='white' strokeWidth='2' />
        <defs>
          <pattern id='pattern0' patternContentUnits='objectBoundingBox' width='1' height='1'>
            <use xlinkHref='#image0_836_4398' transform='scale(0.015625)' />
          </pattern>
          <image
            id='image0_836_4398'
            width='64'
            height='64'
            xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAA40lEQVR4Xu3bQQ6EQAhEUbj/oXsO8Sdh4XOvJAi/qkF3Zt6E6710++xuiD6T40uACtACqYlzD2IACFKBkoHcgmSQDJJBMngKIT6ADygF6DSYfcCLTzg/z0eGrASogDbT0gKxB2MB5pkiBoBgrEEMwIBjLx9fAAiCIAhygmkkRgYjhWMHditsL2AvYC+QIHjdwzk+BmAABmBAWc1kCF0bKRAEQRAEQRAMGaACbaCUz/P5BRiKxhQaiV07uRjfYgQDMKDpGAhGCMUCzD4CBEEw1iAGYIBPZMJh+g8/P8cKpAJfV4EfMee/sLtaEFIAAAAASUVORK5CYII='
          />
        </defs>
      </svg>
    </div>
  );
};
