import { useState, useEffect, createRef, useRef } from 'react';

import { Box, Stack } from '@mui/material';
import AddLinkIcon from '@mui/icons-material/AddLink';
import { useLocation } from 'react-router-dom';
import { usePubNub } from 'pubnub-react';
import api from 'api';

import Message from './Message';
import * as regex from './regex';

const ChatWindow = ({ channel, open }) => {
  const PubNubClient = usePubNub();
  const messagesEndRef = createRef();
  const location = useLocation();
  const inputRef = useRef();

  const [messages, addMessage] = useState([]);
  const [message, setMessage] = useState('');
  const [channelData, setChannelData] = useState(null);
  const [page, setPage] = useState(null);
  const [activityId, setActivityId] = useState(null);

  const sendMessage = (message) => {
    PubNubClient.publish({
      channel,
      message: {
        type: 'text',
        text: message
      }
    }).then(() => {
      setMessage('');
    });
  };

  const handleMessage = (event) => {
    if (event.channel === channel) {
      event.uuid = event.publisher;
      addMessage((messages) => [...messages, event]);
    }
  };

  const sendLink = async () => {
    const data = { page };
    let text = '';
    if (page === 'activity') {
      const query = await api.activities.getActivityForChat(activityId);
      data.activityId = activityId;
      data.date = query.endDate;
      text = query.name || 'Activity';
    }

    if (page === 'progress') {
      text = 'Progress';
    }

    PubNubClient.publish({
      channel,
      message: {
        type: 'link',
        text,
        data
      }
    }).then(() => {
      console.log('Link sent');
    });
  };

  useEffect(() => {
    const { pathname } = location;
    if (regex.matchUserActivityPage(pathname)) {
      // console.log("USER ACTIVITY PAGE");
      const results = regex.matchUserActivityPage(pathname);
      setPage(results.page);
      setActivityId(results.activityId);
    } else if (regex.matchUserProgressPage(pathname)) {
      // console.log("USER PROGRESS PAGE");
      const results = regex.matchUserProgressPage(pathname);
      setPage(results.page);
      setActivityId(null);
    } else if (regex.matchCoachingActivityPage(pathname)) {
      // console.log("COACHING ACTIVITY PAGE");
      const results = regex.matchCoachingActivityPage(pathname);
      setPage(results.page);
      setActivityId(results.activityId);
    } else if (regex.matchCoachingProgressPage(pathname)) {
      // console.log("COACHING PROGRESS PAGE");
      const results = regex.matchCoachingProgressPage(pathname);
      setPage(results.page);
      setActivityId(null);
    } else {
      setPage(null);
      setActivityId(null);
    }
  }, [location]);

  useEffect(() => {
    const fetchChannelData = async () => {
      const data = await api.pubnub.getChannelUserData(channel);
      setChannelData(data);
    };

    setChannelData(null);

    const listener = { message: handleMessage };
    PubNubClient.addListener(listener);

    PubNubClient.fetchMessages({ channels: [channel] }, (status, response) => {
      const messages = response?.channels[channel];
      if (!!messages) {
        addMessage(messages);
      } else {
        addMessage([]);
      }
    });

    setMessage('');
    fetchChannelData();

    return () => {
      PubNubClient.removeListener(listener);
    };
  }, [channel, setMessage, PubNubClient]);

  useEffect(() => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [messages, open, messagesEndRef]);

  return (
    <Box className='activity-chat'>
      <div className='activity-chat__list'>
        {!!channelData && !!messages.length ? (
          messages.map((object) => (
            <Message key={object.timetoken} object={object} channelData={channelData} />
          ))
        ) : (
          <div className='activity-chat__empty-list'>
            <p>No conversation for this session yet.</p>
            <p>Write a message to start one.</p>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <Stack direction='row' sx={{ width: '100%', alignItems: 'center' }} mt={1} spacing={1}>
        <Box className='activity-chat__footer' sx={{ flex: 1 }} m={0}>
          <input
            type='text'
            placeholder='Write a message'
            value={message}
            ref={inputRef}
            onKeyPress={(e) => {
              if (e.key !== 'Enter') return;
              sendMessage(message);
            }}
            onTouchStart={(e) => {
              inputRef.current.focus();
              e.stopPropagation();
            }}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button
            className='activity-chat__send-button'
            disabled={!message}
            onClick={(e) => {
              e.preventDefault();
              sendMessage(message);
            }}
            onTouchStart={(e) => {
              e.preventDefault();
              sendMessage(message);
            }}>
            Send
          </button>
        </Box>
        {!!page && (
          <AddLinkIcon
            color='primary'
            sx={{ cursor: 'pointer' }}
            onClick={sendLink}
            onTouchStart={sendLink}
          />
        )}
      </Stack>
    </Box>
  );
};
export default ChatWindow;
