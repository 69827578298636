import { useContext } from "react";
// import PropTypes from 'prop-types';
import api from "api";
import { UserContext } from "context";

const GarminConnectButton = ({ location, match }) => {
  const { user } = useContext(UserContext);

  const connectGarmin = async () => {
    try {
      const data = await api.garmin.requestToken();
      const encodedCallback = encodeURIComponent(`${window.location.origin}/garmin?userId=${user.id}`)
      window.location = `https://connect.garmin.com/oauthConfirm?oauth_token=${data.oauthToken}&oauth_callback=${encodedCallback}`;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <button className="button" type="button" onClick={connectGarmin}>
      Connect Garmin
    </button>
  );
};

// GarminConnectButton.propTypes = {
//   : PropTypes.
// };

export default GarminConnectButton;
