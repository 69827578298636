import PropTypes from "prop-types";
import { Stack } from "@mui/material";

import FinsIcon from "../components/Icons/FinsIcon";
import PaddlesIcon from "../components/Icons/PaddlesIcon";
import BuoyIcon from "../components/Icons/BuoyIcon";
import TechtocIcon from "./Icons/TechIcon";
import BatonIcon from "../components/Icons/BatonIcon";
import SnorkelIcon from "../components/Icons/SnorkelIcon";
import DrillIcon from "../components/Icons/DrillIcon";
import KickboardIcon from "../components/Icons/KickboardIcon";
import BandIcon from "../components/Icons/BandIcon";

const renderIcon = (eq, iconStyle, keyPrefix) => {
	const key = `${keyPrefix}-${eq}`;
	if (eq === "fins") {
		return <FinsIcon key={key} sx={iconStyle} />;
	} else if (eq === "paddles") {
		return <PaddlesIcon key={key} sx={iconStyle} />;
	} else if (eq === "pullbuoy") {
		return <BuoyIcon key={key} sx={iconStyle} />;
	} else if (eq === "techtoc") {
		return <TechtocIcon key={key} sx={iconStyle} />;
	} else if (eq === "baton") {
		return <BatonIcon key={key} sx={iconStyle} />;
	} else if (eq === "snorkel") {
		return <SnorkelIcon key={key} sx={iconStyle} />;
	} else if (eq === "drill") {
		return <DrillIcon key={key} sx={iconStyle} />;
	} else if (eq === "kickboard") {
		return <KickboardIcon key={key} sx={iconStyle} />;
	} else if (eq === "bands") {
		return <BandIcon key={key} sx={iconStyle} />;
	} else {
		return null;
	}
};

const EquipmentIconList = ({
	equipment = [],
	iconStyle = {},
	fontSize = 15,
	keyPrefix = "",
	...rest
}) => {
	iconStyle.fontSize = fontSize;
	return (
		<Stack direction="row" { ...rest }>
			{equipment.map((eq) => renderIcon(eq, iconStyle, keyPrefix))}
		</Stack>
	);
};

EquipmentIconList.propTypes = {
	equipment: PropTypes.array,
};

export default EquipmentIconList;
