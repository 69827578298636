import {Avatar, Box, ButtonBase, Card, Checkbox, Chip, FormControlLabel, Stack, Typography} from "@mui/material";
import {getCoachSwimmersByLvl} from "./utils";
import {getUserCharacters} from "../../util";

const CoachCard = ({data, sx, ...other}) => {
  const nameDisplay = data.firstName + ' ' + data.lastName;
  const swimmers = getCoachSwimmersByLvl(data.levels);

  const renderContent = (
    <Stack gap={1.5}>
      <Stack direction="row" gap={2}>
        <Avatar
          src={data.avatar}
          sx={{width: 100, height: 100}}
          alt={nameDisplay}
        >
          {getUserCharacters(nameDisplay)}
        </Avatar>
        <Box>
          <Typography variant="h5">{nameDisplay}</Typography>
          <Typography variant="body1" color="text.secondary">{data.locationDisplay}</Typography>
        </Box>
      </Stack>
      <Box sx={{display: 'flex', flexFlow: 'wrap', gap: 1}}>
        <Chip label="Open Water" color="primary" size="small"/>
        <Chip label="Triathlon" color="primary" size="small"/>
        <Chip label="Technique" color="primary" size="small"/>
      </Box>
      <Box sx={{display: 'flex', flexFlow: 'wrap'}}>
        {swimmers.map((label, index) => (
          <FormControlLabel key={index} control={<Checkbox checked size="small"/>} label={label}/>
        ))}
      </Box>
    </Stack>
  );

  if (other.href || other.onClick) {
    return (
      <Card
        component={ButtonBase}
        sx={{textAlign: 'left', display: 'block', p: 1.5, backgroundColor: "background.secondary", ...sx}}
        {...other}
      >
        {renderContent}
      </Card>
    );
  }

  return (
    <Card sx={{p: 1.5, backgroundColor: "background.secondary", ...sx}} {...other}>
      {renderContent}
    </Card>
  );
};

export default CoachCard;