import {useContext} from "react";
import {useInfiniteQuery} from "react-query";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
// @mui
import {Box, LinearProgress, Stack, Typography} from "@mui/material";
// components
import FormProvider from "../../components/hook-form";
import {SkeletonWorkoutItem} from "../../components/skeleton";
import {WorkoutFiltersPopover, WorkoutSortBySelector} from "../../components/filters";
import WorkoutCard from "../../components/workout/WorkoutCard";
import {UserContext} from "../../context";
import api from "../../api";


const WorkoutsList = () => {
  const history = useHistory();

  const {user} = useContext(UserContext);

  const defaultValues = {
    pageSize: 12,
    environment: '',
    levels: '',
    minDistance: undefined,
    maxDistance: undefined,
    equipment: '',
    sortBy: 'createdAt',
    sortOrder: 'desc',
  };

  const methods = useForm({
    defaultValues,
  });

  const {
    reset,
    watch,
  } = methods;

  const values = watch();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ["workoutsListData", values],
    queryFn: ({pageParam = 0}) => api.workouts.getList({page: pageParam, ...values}),
    getNextPageParam: (lastPage) => lastPage.nextPage,
  });

  const workoutData = data?.pages.flatMap((page) => page.results.map((item) => item)) ?? [];

  const isNothingFound = !isLoading && !workoutData.length;

  const dataLength = data?.pages ? data.pages.length * data.pages[0].results.length : 0;

  const handleResetFilter = () => {
    reset();
  };

  const handleOnClick = (id) => {
    history.push(`/workout/${id}`);
  }

  const handleOnCoachClick = (coachId) => {
    history.push(`/coach/${coachId}`);
  }


  return (
    <FormProvider methods={methods}>
      <Stack mb={4} direction="row" alignItems="center" justifyContent="space-between">
        <WorkoutSortBySelector/>
        <WorkoutFiltersPopover onResetFilter={handleResetFilter}/>
      </Stack>

      <InfiniteScroll
        dataLength={dataLength}
        next={fetchNextPage}
        hasMore={hasNextPage}
        scrollableTarget="scrollContainer"
        loader={<LinearProgress sx={{borderRadius: 4}}/>}
      >
        <Box columnGap={1} rowGap={1.5} display="grid" gridTemplateColumns={{md: 'repeat(2, 1fr)'}}>
          {(isLoading && !isNothingFound ? [...Array(12)] : workoutData).map((item, index) =>
            item ? (
              <WorkoutCard
                isLocked={item.locked}
                showCoachLink
                key={item.id}
                data={item}
                lapUnit={user.lapUnit}
                onClick={() => handleOnClick(item.id)}
                onCoachClick={handleOnCoachClick}
              />
            ) : (
              <SkeletonWorkoutItem key={index}/>
            )
          )}
        </Box>
      </InfiniteScroll>

      {isNothingFound && <Typography variant='body2' sx={{color: 'text.secondary'}}>Nothing found...</Typography>}
    </FormProvider>
  );
};

export default WorkoutsList;
