import PropTypes from 'prop-types';
// form
import {useFormContext, Controller} from 'react-hook-form';
// @mui
import {
  FormHelperText,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material';

// ----------------------------------------------------------------------

RHFButtonGroup.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
};

export default function RHFButtonGroup({name, options, valueAsNumber, ...other}) {
  const {control} = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState: {error}}) => (
        <div>
          <ToggleButtonGroup
            exclusive
            color="primary"
            size="small"
            value={field.value}
            onChange={(e) => field.onChange(valueAsNumber ? +e.target.value : e.target.value)}
            {...other}
          >
            {options.map((option) => (
              <ToggleButton key={option.value} value={option.value}>{option.label}</ToggleButton>
            ))}
          </ToggleButtonGroup>

          {!!error && (
            <FormHelperText error sx={{px: 2}}>
              {error.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
}
