import SvgIcon from '@mui/material/SvgIcon';
const BuoyIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M6.10364 16.9983C6.07023 16.9994 6.03569 17 6 17C3.5 17 2 14.7614 2 12C2 9.23858 3.5 7 6 7C6.03569 7 6.07023 7.00058 6.10364 7.00175C6.12025 7.00058 6.13698 7 6.15385 7C7.10952 7 7.77142 7.46956 8.5215 8.00168C9.3772 8.60873 10.5 9.46279 12 9.46279C13.5 9.46279 14.6228 8.60873 15.4785 8.00168C16.2286 7.46956 16.8905 7 17.8462 7C17.863 7 17.8798 7.00058 17.8964 7.00175C17.9298 7.00058 17.9643 7 18 7C20.5 7 22 9.23858 22 12C22 14.7614 20.5 17 18 17C17.9643 17 17.9298 16.9994 17.8964 16.9983C17.8798 16.9994 17.863 17 17.8462 17C16.8905 17 16.2286 16.5304 15.4785 15.9983C14.5895 15.3676 13.5 14.5203 11.804 14.5203C10.108 14.5203 9.38644 15.3232 8.56357 15.9388C7.8202 16.4949 7.14503 17 6.15385 17C6.13698 17 6.12025 16.9994 6.10364 16.9983Z" fill="#EBEBF5" fillOpacity="0.6"/>
    </SvgIcon>
  );
}

export default BuoyIcon;
