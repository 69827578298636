import {useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {AppBar, Container, Stack, Tab, Tabs, Toolbar} from "@mui/material";
import logo from "../../../images/logo.svg";



const nav = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Coaches',
    path: '/for-coaches',
  },
];

const LandingNavSection = ({action}) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const [tab, setTab] = useState(nav.findIndex((page) => page.path === pathname));

  const handleOnChange = (event, newValue) => {
    setTab(newValue);
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    delay(300).then(() => history.push(nav[newValue].path));
  }


  return (
    <AppBar color="transparent" sx={(theme) => ({
      boxShadow: 0,
      minHeight: 148,
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        minHeight: 88,
      },
    })}>
      <Toolbar disableGutters>
        <Container sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          'nav': {
            mr: 4
          },
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            'img': {
              alignSelf: 'flex-start',
              width: 56
            },
            'nav': {
              mr: 0
            },
          },
        })}>
          <img src={logo} alt="logo"/>

          <Stack component="nav" direction="row" alignItems="center" spacing={4}>
            <Tabs
              value={tab}
              onChange={handleOnChange}
              aria-label="landing page"
            >
              {nav.map((link, index) => (
                <Tab key={index} label={link.title} {...a11yProps(index)} />
              ))}
            </Tabs>
            {action}
          </Stack>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const a11yProps = (index) => {
  return {
    id: `tab-${index}`,
    "aria-controls": `tab-${index}`,
  };
};

export default LandingNavSection;
