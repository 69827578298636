import SvgIcon from '@mui/material/SvgIcon';

const PaceIcon = (props) => {
  return (
    <svg
      width='36'
      height='30'
      viewBox='0 0 36 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.4999 0.899414C13.7248 0.899414 7.39941 7.22478 7.39941 14.9999C7.39941 22.7751 13.7248 29.1005 21.4999 29.1005C29.2747 29.1005 35.6001 22.7751 35.6001 14.9999C35.6001 7.22478 29.2747 0.899414 21.4999 0.899414ZM21.9388 28.2095V25.7796C21.9388 25.5364 21.7419 25.3396 21.4988 25.3396C21.2558 25.3396 21.0588 25.5364 21.0588 25.7796V28.2095C14.1189 27.9803 8.51844 22.3795 8.29038 15.4388H10.7201C10.9632 15.4388 11.1601 15.2421 11.1601 14.9988C11.1601 14.7556 10.9632 14.5588 10.7201 14.5588H8.29052C8.51969 7.61895 14.1209 2.01848 21.0612 1.79078V4.22068C21.0612 4.46388 21.2581 4.66068 21.5012 4.66068C21.7442 4.66068 21.9412 4.46388 21.9412 4.22068V1.79078C28.8811 2.01995 34.4815 7.62078 34.7092 14.5614H32.2795C32.0364 14.5614 31.8395 14.7582 31.8395 15.0014C31.8395 15.2446 32.0364 15.4414 32.2795 15.4414H34.7091C34.4801 22.3813 28.8791 27.9818 21.9388 28.2095Z'
        fill='#FF59B9'
      />
      <path
        d='M25.9827 17.0631L21.9394 14.7449L21.9396 9.97271C21.9396 9.72951 21.7426 9.53271 21.4996 9.53271C21.2565 9.53271 21.0596 9.72951 21.0596 9.97271V14.9997C21.0596 15.1574 21.1439 15.3031 21.2806 15.3813L25.5449 17.8262C25.6141 17.8657 25.6893 17.8846 25.7633 17.8846C25.9159 17.8846 26.0641 17.8051 26.1455 17.6634C26.2665 17.4528 26.1936 17.1838 25.9828 17.0631L25.9827 17.0631Z'
        fill='#FF59B9'
      />
      <path
        d='M6.05161 20.4773H2.55182C2.30876 20.4773 2.11182 20.6741 2.11182 20.9173C2.11182 21.1605 2.30876 21.3573 2.55182 21.3573H6.05161C6.29467 21.3573 6.49161 21.1605 6.49161 20.9173C6.49161 20.6741 6.29467 20.4773 6.05161 20.4773Z'
        fill='#FF59B9'
      />
      <path
        d='M5.43387 14.9986C5.43387 14.7554 5.23693 14.5586 4.99387 14.5586H0.839902C0.596843 14.5586 0.399902 14.7554 0.399902 14.9986C0.399902 15.2418 0.596843 15.4386 0.839902 15.4386H4.99387C5.23693 15.4386 5.43387 15.2418 5.43387 14.9986Z'
        fill='#FF59B9'
      />
      <path
        d='M6.05161 8.64038H2.55182C2.30876 8.64038 2.11182 8.83718 2.11182 9.08038C2.11182 9.32358 2.30876 9.52038 2.55182 9.52038H6.05161C6.29467 9.52038 6.49161 9.32358 6.49161 9.08038C6.49161 8.83718 6.29467 8.64038 6.05161 8.64038Z'
        fill='#FF59B9'
      />
    </svg>
  );
};

export default PaceIcon;
