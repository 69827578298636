import { useState, useContext } from "react";
import { Box, Tab, Container } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CoachesAdmin from "./CoachesAdmin";
import CouponAdmin from "./CouponAdmin";
import ErrorsAdmin from "./ErrorsAdmin";
import CoachRequestAdmin from "./CoachRequestAdmin";
import { UserContext } from "context";

const Admin = () => {
	const { user } = useContext(UserContext);
	const [value, setValue] = useState("0");

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const tabs = [
		...(user.isSuperAdmin
			? [
					{ label: "Coaches", component: CoachesAdmin },
					{ label: "Coach Requests", component: CoachRequestAdmin },
					{ label: "Coupons", component: CouponAdmin },
			  ]
			: []),
		...(user.isAdmin ? [{ label: "Errors", component: ErrorsAdmin }] : []),
	];

	return (
		<Container>
			<Box pt={10}>
				<TabContext value={value}>
					<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
						<TabList onChange={handleChange} aria-label="lab API tabs example">
							{tabs.map((tab, index) => (
								<Tab label={tab.label} value={index.toString()} key={index} />
							))}
						</TabList>
					</Box>
					{tabs.map((tab, index) => (
						<TabPanel value={index.toString()} key={index}>
							<tab.component />
						</TabPanel>
					))}
				</TabContext>
			</Box>
		</Container>
	);
};

export default Admin;
