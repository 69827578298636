import {useEffect, useMemo, useState} from "react";
import {useQuery} from "react-query";
import {useParams} from "react-router-dom";
// @mui
import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  Container,
  Divider,
  FormControlLabel,
  IconButton,
  Link,
  Stack,
  styled,
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import {alpha} from "@mui/material/styles";
// icons
import LaunchIcon from '@mui/icons-material/Launch';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
// components
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import Spinner from "../../components/common/Spinner";
import ProductBanner from "../../components/coach/ProductBanner";
import CoachWorkouts from "./CoachWorkouts";
import api from "../../api";
import {getCoachSwimmersByLvl} from "../../components/coach/utils";
import {getUserCharacters} from "../../util";


const StyledSocialIcon = styled(IconButton)(({theme}) => ({
  width: 36,
  height: 36,
  color: theme.palette.background.default,
  backgroundColor: theme.palette.common.white,
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.8),
  },
}));

const PRODUCT_TYPES = {
  workouts: 'workouts',
  squad: 'squad',
  chat: 'chat',
};

const productKeyByPrefix = {
  'SIMMA-WORKOUTS-SUB_': PRODUCT_TYPES.workouts,
  'SIMMA-SQUAD-SUB_': PRODUCT_TYPES.squad,
  'SIMMA-CHAT-SUB_': PRODUCT_TYPES.chat,
};

const CoachHeader = ({image, name, location, coachLinks, coachEmail}) => (
  <Stack direction={{xs: 'column', sm: 'row'}} justifyContent="space-between" gap={2}>
    <Stack direction="row" gap={2}>
      <Avatar src={image} sx={{width: 100, height: 100}}>{getUserCharacters(name)}</Avatar>
      <Box>
        <Typography variant="h5">{name}</Typography>
        <Typography variant="h6" color="text.secondary">{location}</Typography>
      </Box>
    </Stack>
    {(coachLinks?.instagram || coachLinks?.facebook || coachEmail) && (
      <Box>
        <Typography variant="h6" color="text.secondary">Follow me here:</Typography>
        <Stack direction="row" gap={1}>
          {coachLinks.instagram && (
            <StyledSocialIcon component={Link} href={coachLinks.instagram} target="_blank">
              <InstagramIcon/>
            </StyledSocialIcon>
          )}
          {coachLinks.facebook && (
            <StyledSocialIcon component={Link} href={coachLinks.facebook} target="_blank">
              <FacebookIcon/>
            </StyledSocialIcon>
          )}
          {coachEmail && (
            <StyledSocialIcon component={Link} href={`mailto:${coachEmail}`} target="_blank">
              <EmailIcon/>
            </StyledSocialIcon>
          )}
        </Stack>
      </Box>
    )}
    {coachLinks?.website && (
      <Box>
        <Typography variant="h6" color="text.secondary">Find out more here:</Typography>
        <Link href={coachLinks.website} target="_blank"><LaunchIcon sx={{verticalAlign: 'bottom', mr: .5}}/>
          {coachLinks.website}
        </Link>
      </Box>
    )}
  </Stack>
);

const CoachAbout = ({text, focusAreas, swimmers}) => (
  <Stack gap={1}>
    <Stack direction={{xs: 'column', sm: 'row'}} gap={{xs: 2, sm: 4}}>
      {focusAreas && (
        <Box>
          <Typography variant="h6" color="text.secondary">I focus on:</Typography>
          <Box sx={{display: 'flex', flexFlow: 'wrap', gap: 1}}>
            {focusAreas.map((item, index) => <Chip key={index} label={item} color="primary"/>)}
          </Box>
        </Box>
      )}
      {swimmers && (
        <Box>
          <Typography variant="h6" color="text.secondary">My swimmers are:</Typography>
          <Box sx={{display: 'flex', flexFlow: 'wrap'}}>
            {swimmers.map((label, index) => (
              <FormControlLabel key={index} control={<Checkbox checked size="small"/>} label={label}/>
            ))}
          </Box>
        </Box>
      )}
    </Stack>
    {text && (
      <Box>
        <Typography variant="caption" color="text.secondary">About me:</Typography>
        <Typography>{text}</Typography>
      </Box>
    )}
  </Stack>
);

const CoachDetailsPage = () => {
  const {coachId} = useParams();

  const {data, isLoading} = useQuery(
    ["coach", coachId],
    () => api.coaches.get(coachId)
  );

  const {data: productsData} = useQuery(
    ["products", coachId],
    () => api.coaches.getCoachProducts(coachId)
  );

  const normalizedProductsData = useMemo(() => productsData?.reduce((acc, product) => {
    const {coachId} = product.metadata;
    const productPrefix = product.id.replace(coachId, '');
    acc[productKeyByPrefix[productPrefix]] = product;
    return acc;
  }, {}) || {}, [productsData]);

  const [currentTab, setCurrentTab] = useState(PRODUCT_TYPES.workouts);

  useEffect(() => {
    console.log(data)
  }, [data])
  if (isLoading) {
    return <Spinner window/>;
  }

  if (!data) {
    return null;
  }


  return (
    <Box sx={{py: 1}}>
      <Container>
        <CustomBreadcrumbs
          links={[
            {
              name: 'Training',
              href: '/training',
            },
            {
              name: data.firstName + ' ' + data.lastName,
            }
          ]}
        />

        <Stack gap={2}>
          <CoachHeader
            image={data.avatar}
            name={data.firstName + ' ' + data.lastName}
            location={data.locationDisplay}
            coachLinks={data.coachLinks}
            coachEmail={data.coachEmail}
          />

          <Divider/>

          <CoachAbout
            focusAreas={data.focusAreas}
            swimmers={getCoachSwimmersByLvl(data.levels)}
            text={data.description}
          />

          <Divider/>

          {productsData && <Typography variant="h4">My Store</Typography>}

          {productsData && (
            <Tabs
              value={currentTab}
              onChange={(event, newValue) => setCurrentTab(newValue)}
              sx={{
                mb: 2,
                '& .MuiButtonBase-root': {
                  width: '100%',
                  maxWidth: 160,
                },
              }}
            >
              {normalizedProductsData.workouts && <Tab value={PRODUCT_TYPES.workouts} label="Workouts"/>}
              {normalizedProductsData.squad && <Tab value={PRODUCT_TYPES.squad} label="online squad"/>}
              {normalizedProductsData.chat && <Tab value={PRODUCT_TYPES.chat} label="chat"/>}
            </Tabs>
          )}

          {PRODUCT_TYPES.workouts === currentTab && (
            <CoachWorkouts coachId={coachId} product={normalizedProductsData.workouts}/>
          )}
          {PRODUCT_TYPES.squad === currentTab && (
            <ProductBanner
              title={"Join my squad"}
              product={normalizedProductsData.squad}
              coachId={coachId}
              productType="SIMMA-SQUAD-SUB"
            />
          )}
          {PRODUCT_TYPES.chat === currentTab && (
            <ProductBanner
              title={"Subscribe to chat"}
              product={normalizedProductsData.chat}
              coachId={coachId}
              productType="SIMMA-CHAT-SUB"
            />
          )}
        </Stack>
      </Container>
    </Box>
  );
};

export default CoachDetailsPage;
