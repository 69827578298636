import { userApi, publicApi } from './axios';

export const getCoachProducts = async (coachId) => {
  const { data } = await userApi.get(`coaches/${coachId}/products`);
  return data;
};

export const list = async (params) => {
  const { data } = await userApi.get('coaches', { params });
  return data;
};

export const get = async (id) => {
  const { data } = await userApi.get(`coaches/${id}`);
  return data;
};

export const getPublic = async (id) => {
  const { data } = await publicApi.get(`coaches/${id}/public`);
  return data;
};
