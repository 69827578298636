import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const OpenWaterSwimIcon = ({sx, ...props}) => {
  return (
    <SvgIcon sx={{fill: '#1ADEA3', fontSize: 48, ...sx}} {...props} viewBox="0 0 48 48">
      <rect width="48" height="48" rx="24" />
      <path d="M14 30.4167C14 30.4167 16.5 32.0834 19 32.0834C21.5 32.0834 24 30.4167 24 30.4167C24 30.4167 26.5 32.0834 29 32.0834C31.5 32.0834 34 30.4167 34 30.4167" stroke="black" strokeOpacity="0.7" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14 26.25C14 26.25 16.5 27.9167 19 27.9167C21.5 27.9167 24 26.25 24 26.25C24 26.25 26.5 27.9167 29 27.9167C31.5 27.9167 34 26.25 34 26.25" stroke="black" strokeOpacity="0.7" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M16.5 15.25C15.9477 15.25 15.5 15.6977 15.5 16.25C15.5 16.8023 15.9477 17.25 16.5 17.25C17.5647 17.25 18.6102 17.5728 19.364 18.1005C20.1111 18.6234 20.5 19.2851 20.5 20C20.5 20.5523 20.9477 21 21.5 21C22.0523 21 22.5 20.5523 22.5 20C22.5 19.2851 22.8889 18.6234 23.636 18.1005C24.3898 17.5728 25.4353 17.25 26.5 17.25C27.0523 17.25 27.5 16.8023 27.5 16.25C27.5 15.6977 27.0523 15.25 26.5 15.25C25.0647 15.25 23.6102 15.6772 22.489 16.462C22.1249 16.7169 21.7885 17.016 21.5 17.3558C21.2115 17.016 20.8751 16.7169 20.511 16.462C19.3898 15.6772 17.9353 15.25 16.5 15.25Z" fill="black" fillOpacity="0.7"/>
    </SvgIcon>
  );
};

export default OpenWaterSwimIcon;