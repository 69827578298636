import { userApi, coachApi } from "./axios";

export const createConnectedAccount = async (body) => {
  const { data } = await coachApi.post("stripe/create-user-account", body);
  return await createConnectedAccountLink(data.account, "account_onboarding");
};

export const createConnectedAccountLink = async (
  account,
  type = "account_login"
) => {
  const body = {
    account,
    type,
    refresh_url: window.location.origin + "/coaching/store?step=1",
    return_url: window.location.origin + "/coaching/store?step=1",
  };
  const { data } = await coachApi.post("stripe/account-link", body);
  return data;
};

export const getConnectedAccount = async (id) => {
  const { data } = await coachApi.get(`stripe/account/${id}`);
  return data;
};

export const createSubscriptionProduct = async (body) => {
  const { data } = await coachApi.post("stripe/create-product", body);
  return data;
};

export const createCheckoutSession = async (body) => {
  const { data } = await userApi.post("stripe/create-checkout-session", body);
  return data;
};

export const createSimmaCheckoutSession = async (body) => {
  const { data } = await userApi.post(
    "stripe/create-checkout-session/simma",
    body
  );
  return data;
};

export const getSimmaProducts = async () => {
  const { data } = await userApi.get(`stripe/products`);
  return data;
};
