import {Box, Button} from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const FreshdeskWidgetButton = () => {
  const handleOnClick = () => {
    window.FreshworksWidget('open');
  };


  return (
    <Box sx={{px: 2, pb: 2, alignSelf: 'center'}}>
      <Button onClick={handleOnClick} size="small" variant="contained" startIcon={<HelpOutlineIcon />}>Support</Button>
    </Box>
  );
}

export default FreshdeskWidgetButton;