// @mui
import {Card, Modal} from "@mui/material";
// components
import AvatarEditor from "../../../components/coaching/AvatarEditor";


const AvatarModal = ({open, onClose, imageUrl, onSubmit}) => (
  <Modal
    open={open}
    onClose={onClose}
    aria-labelledby="Avatar Editor"
    aria-describedby="Upload and edit your avatar"
  >
    <Card sx={{
      p: 2,
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)'
    }}>
      <AvatarEditor
        imageUrl={imageUrl}
        handleClose={onClose}
        refetch={onSubmit}
        helperText="Use an image that is at least 250x250px"
      />
    </Card>
  </Modal>
);

export default AvatarModal;
