import * as workouts from './workouts';
import * as swimmers from './swimmers';
import * as workoutSets from './workoutSets';
import * as profile from './profile';
import * as products from './products';
import * as reports from './reports';

export const coachingApi = {
  workouts,
  workoutSets,
  swimmers,
  profile,
  products,
  reports,
};

export default coachingApi;
