import { Box, Container, styled, Typography } from '@mui/material';
import { bgGradient } from '../../../util';

const StyledRoot = styled('div')({
  position: 'relative',
  padding: '148px 0',
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  ...bgGradient({
    color: 'rgba(0,0,0, 0.2)',
    imgUrl: '/images/landing/landing-hero-bg.jpg'
  })
});

const StyledVideoBg = styled('video')({
  position: 'absolute',
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  '+ .overlay': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    ...bgGradient({
      color: 'rgba(0,0,0, 0.2)'
    }),
    '& + *': {
      position: 'relative'
    }
  }
});

const VideoBg = ({ videoSrc, videoPoster }) => (
  <>
    <StyledVideoBg autoPlay muted loop id='hero-bg-video' poster={videoPoster}>
      <source src={videoSrc} type='video/mp4' />
    </StyledVideoBg>
    <Box className='overlay' />
  </>
);

const LandingHeroSection = ({ title, action, containerMaxWidth, videoBg, videoPoster }) => {
  const renderContent = (
    <Container maxWidth={containerMaxWidth}>
      <Typography sx={{ mb: 4 }} variant='h2' textAlign='center'>
        {typeof title === 'string'
          ? title
          : title.map((line, index) => (
              <>
                {!!index && !!line && <br />}
                {line}
              </>
            ))}
      </Typography>
      {action}
    </Container>
  );

  return (
    <StyledRoot>
      {videoBg && <VideoBg videoSrc={videoBg} videoPoster={videoPoster} />}
      {renderContent}
    </StyledRoot>
  );
};

export default LandingHeroSection;
