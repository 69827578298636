import {useEffect, useState} from "react";
import {useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {Draggable} from "react-beautiful-dnd";
// @mui
import {Box, Card, Stack, Typography} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
// components
import {RHFRadioGroup, RHFSelect, RHFTextField} from "../../../../components/hook-form";
import {createSegment} from "../../../../components/WorkoutCreator/utils";
import SegmentSection from "./SegmentSection";
import SetViewSection from "./SetViewSection";
import {FLAVOR_OPTIONS, SEGMENT_COUNT_OPTION} from "./utils";


const SegmentCountField = ({baseName, ...other}) => {
  const segments = useWatch({name: baseName + 'segments'});

  const hasSplit = !!segments?.[0]?.isSplit;

  return (
    <Box>
      <Typography variant="body1" color="text.secondary">Number of Segments</Typography>
      <RHFRadioGroup
        options={SEGMENT_COUNT_OPTION}
        {...other}
        disabled={hasSplit}
      />
    </Box>
  );
}

const SetSection = ({renderActions, toolbar, baseName = '', defaultLock, item, index}) => {
  const [isLocked, setIsLocked] = useState(!!defaultLock);

  const {setValue, control, getValues, trigger, clearErrors} = useFormContext();

  const {fields, append} = useFieldArray({
    control,
    name: baseName + 'segments',
  });

  // Calculate set distance based on segment distances, total set distance accounting for set repeat
  const handleUpdateSetDistance = () => {
    const [segments, repeat] = getValues([baseName + 'segments', baseName + 'repeat']);
    const distance = segments.reduce((initVal, segment) => {
      const distance = segment.splits.reduce((initVal, split) => initVal + (split.distance || 0), 0) || segment.distance;
      const totalSegment = distance * segment.repeat || 0;
      return initVal + totalSegment;
    }, 0);
    setValue(baseName + 'distance', distance);
    setValue(baseName + 'totalDistance', distance * repeat);
  };

  // Calculate set time based on segment cycleTime
  const handleUpdateSetTime = () => {
    const [segments, repeat] = getValues([baseName + 'segments', baseName + 'repeat']);
    const totalTime = segments.reduce((initVal, segment) => initVal + ((segment.cycleTime || 0) * segment.repeat), 0) || 0;
    setValue(baseName + 'totalTime', totalTime * repeat);
  };

  // Aggregate and reduce set equipment from segments equipment
  const handleUpdateSetEquipment = () => {
    const segments = getValues(baseName + 'segments');
    let equipment = [];
    segments.forEach(
      (segment) => (equipment = [...equipment, ...segment.equipment])
    );
    equipment = equipment.filter(
      (item, index) => equipment.indexOf(item) === index
    );
    setValue(baseName + 'equipment', equipment);
  };

  // create segments according to segmentsCount
  const handleUpdateSegments = (segmentCount) => {
    const segments = getValues(baseName + 'segments');
    if (fields.length !== segmentCount) {
      const newSegmentsValue = [...Array(segmentCount)].map((_, i) => segments[i] || createSegment());
      setValue(baseName + 'segments', newSegmentsValue, {shouldDirty: true});
      handleUpdateSetDistance();
      handleUpdateSetTime();
    }
  }

  // create segment if it doesn't exist
  useEffect(() => {
    if (!fields.length) {
      append(createSegment())
    }
  }, [fields])

  // avoid locking invalid set
  useEffect(() => {
    const checkSetValidationOnDefaultLocked = async () => {
      const isValid = await trigger(baseName);
      clearErrors(baseName);
      if (!isValid) {
        setIsLocked(false);
      }
    }
    if (isLocked) {
      checkSetValidationOnDefaultLocked();
    }
  }, [isLocked])


  const renderContent = (
    <>
      <Box maxWidth={856}>
        <RHFTextField
          name={baseName + 'name'}
          label="Add name of Set"
          variant="filled"
        />
        <Typography sx={{px: 1.5}} variant="caption" color="text.secondary">
          A set must have a name.
        </Typography>
      </Box>

      <Box maxWidth={856}>
        <RHFTextField
          name={baseName + 'description'}
          label="Add description of Set"
          variant="filled"
          multiline
        />
        <Typography sx={{px: 1.5}} variant="caption" color="text.secondary">
          Description is optional, lets you add information to swimmers about what to expect with this set.
        </Typography>
      </Box>

      <Stack direction={{xs: 'column', sm: 'row'}} gap={2}>
        <RHFTextField
          sx={{maxWidth: 136}}
          onChange={(event) => {
            setValue(baseName + 'repeat', Math.abs(Number(event.target.value) || 1));
            handleUpdateSetDistance();
            handleUpdateSetTime();
          }}
          name={baseName + 'repeat'}
          label="Set repeats"
          variant="filled"
          type="number"
        />

        <RHFSelect
          sx={{minWidth: 186, width: 'auto'}}
          name={baseName + 'flavor'}
          label="Flavour"
          variant="filled"
          SelectProps={{
            native: false,
            MenuProps: {
              PaperProps: {
                sx: {maxHeight: 220},
              },
            },
            sx: {textTransform: 'capitalize'},
          }}
        >
          {FLAVOR_OPTIONS.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{
                my: 0.5,
                typography: 'body2',
                textTransform: 'capitalize',
                '&:first-of-type': {mt: 0},
                '&:last-of-type': {mb: 0},
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </RHFSelect>

        <SegmentCountField
          baseName={baseName}
          name={baseName + 'segmentCount'}
          onChange={(e) => {
            const segmentCount = Number(e.target.value);
            setValue(baseName + 'segmentCount', segmentCount);
            handleUpdateSegments(segmentCount);
          }}
        />
      </Stack>

      {fields?.map((item, index) => (
        <SegmentSection
          key={item.id}
          item={item}
          baseName={`${baseName}segments[${index}].`}
          updateSetDistance={handleUpdateSetDistance}
          updateSetTime={handleUpdateSetTime}
          updateSetEquipment={handleUpdateSetEquipment}
        />
      ))}
    </>
  );


  if (isLocked) {
    return (
      <Draggable draggableId={item.id} index={index}>
        {(provided) => (
          <Stack ref={provided.innerRef} {...provided.draggableProps} sx={{p: 1.5}} component={Card} gap={2}>
            <SetViewSection
              toolbar={typeof toolbar === 'function'
                ? toolbar({isLocked, dragHandleProps: provided.dragHandleProps, baseName})
                : toolbar}
              baseName={baseName}
            />

            {renderActions && typeof renderActions === 'function' ? renderActions({
              isLocked,
              setIsLocked
            }) : renderActions}
          </Stack>
        )}
      </Draggable>
    );
  }

  return (
    <Stack sx={{p: 1.5}} component={Card} gap={2}>
      {toolbar && typeof toolbar === 'function' ? toolbar({isLocked, baseName}) : toolbar}

      {renderContent}

      {renderActions && typeof renderActions === 'function' ? renderActions({isLocked, setIsLocked}) : renderActions}
    </Stack>
  );
}

export default SetSection;