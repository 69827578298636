import {useFormContext, useWatch} from "react-hook-form";
import {useEffect, useMemo} from "react";
import {RHFMultiCheckbox} from "../../../../components/hook-form";


const LevelsCheckboxGroup = ({control, options, name, ...other}) => {
  const { setValue } = useFormContext();
  const levels = useWatch({
    control,
    name,
  });

  const validatedOptions = useMemo(() => levels.indexOf(0) !== -1
    ? options.map(o => ({...o, disabled: o.value !== 0}))
    : levels.length > 2 ? options.map(o => ({...o, disabled: !levels.includes(o.value)})) : options, [options, levels])

  useEffect(() => {
    const hasAllLevels = levels.indexOf(0) !== -1;
    if (hasAllLevels && levels.length !== options.length) {
      setValue(name, options.map(o => o.value));
    }
    if (!hasAllLevels && levels.length > 3) {
      setValue(name, []);
    }
  }, [levels, options])

  return <RHFMultiCheckbox name={name} options={validatedOptions} {...other} />;
};

export default LevelsCheckboxGroup;