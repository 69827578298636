import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Input(theme) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.backgrounds[2],
          borderRadius: Number(theme.shape.borderRadius) * 2,
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: 2,
            borderColor: 'transparent',
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: 2,
              borderColor: theme.palette.primary.main,
            },
          },
          '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
              backgroundColor: alpha(theme.palette.error.main, .2),
              borderColor: theme.palette.error.main,
            },
          },
        },
      },
    },
  };
}
