export const parsePrices = (product, currency) => {
  const month = product.prices.find(
    (price) => price.recurring.interval === "month"
  );
  const year = product.prices.find(
    (price) => price.recurring.interval === "year"
  );

  return {month, year};
};

const COACHING_LEVEL_TYPES = {
  beginner: 'beginner',
  intermediate: 'intermediate',
  advanced: 'advanced',
  elite: 'elite',
};

export const COACHING_LEVEL_NUMBERS = {
  [COACHING_LEVEL_TYPES.beginner]: 2,
  [COACHING_LEVEL_TYPES.intermediate]: 3,
  [COACHING_LEVEL_TYPES.advanced]: 4,
  [COACHING_LEVEL_TYPES.elite]: 5,
};

export const coachingLevels = {
  0: "All Levels",
  1: "Learn to Swim",
  2: "Beginner",
  3: "Intermediate",
  4: "Advanced",
  5: "Elite",
};


export const getCoachSwimmersByLvl = (levels) => levels.includes(0) ? [coachingLevels[0]] : levels.map(level => coachingLevels[level]);