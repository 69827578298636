import {useState} from "react";
import {useQuery} from "react-query";
import {useParams} from "react-router-dom";
// @mui
import {Box, Container, Tab, Tabs} from "@mui/material";
// components
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
import SwimmerActivityList from "./SwimmerActivityList";
import api from "../../../api";
import Progress from "../../Progress";


const SwimmerDetailsPage = () => {
  const {swimmerId} = useParams();

  const {data: swimmerData} = useQuery(
    ["swimmerData", swimmerId],
    () => api.coaching.swimmers.get(swimmerId),
    {
      staleTime: Infinity
    }
  );

  const [currentTab, setCurrentTab] = useState('activities');

  const TABS = [
    {
      value: 'activities',
      label: 'Activities',
      component: <SwimmerActivityList swimmerId={swimmerId}/>
    },
    {
      value: 'progress',
      label: 'Progress',
      component: <Progress swimmerId={swimmerId}/>
    },
  ];

  return (
    <Box sx={{py: 1}}>
      <Container>
        <CustomBreadcrumbs
          links={[
            {
              name: 'My Swimmers',
              href: '/coaching/swimmers'
            },
            {
              name: swimmerData?.name,
            }
          ]}
        />

        <Tabs
          value={currentTab}
          onChange={(event, newValue) => setCurrentTab(newValue)}
          sx={{
            mb: 2,
            '& .MuiButtonBase-root': {
              width: '100%',
              maxWidth: 160,
            },
          }}
        >
          {TABS.map((tab) => <Tab key={tab.value} value={tab.value} label={tab.label}/>)}
        </Tabs>

        {TABS.map((tab) => tab.value === currentTab && <Box key={tab.value}> {tab.component} </Box>)}
      </Container>
    </Box>
  );
};

export default SwimmerDetailsPage;
