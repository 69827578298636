import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
// components
import FormProvider, { RHFTextField } from '../../components/hook-form';
import { Box, Stack, Typography } from '@mui/material';
import api from '../../api';

// ----------------------------------------------------------------------

const AuthResetPasswordForm = ({ onResetPassword }) => {
  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required')
  });

  const methods = useForm({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues: { email: '' }
  });

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const onSubmit = async (data) => {
    try {
      await api.auth.forgotPassword(data);

      sessionStorage.setItem('email-recovery', data.email);

      onResetPassword();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ px: 2, py: 3 }}>
        <Stack gap={3}>
          <Typography variant='body1'>Forgot your password?</Typography>

          <Box>
            <Typography variant='body1' sx={{ mb: 0.5 }}>
              Enter your Email below and we will mail a code to reset your password.
            </Typography>
            <RHFTextField name='email' placeholder='name@domain.com' />
          </Box>

          <LoadingButton
            sx={{ textTransform: 'capitalize' }}
            size='large'
            fullWidth
            type='submit'
            variant='gradient'
            loading={isSubmitting}>
            Reset my password
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  );
};

export default AuthResetPasswordForm;
