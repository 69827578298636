// @mui
import { Box } from '@mui/material';
// hooks
import { useResponsive } from '../../hooks/index';
// config
import { HEADER, NAV } from './config';

// ----------------------------------------------------------------------

const SPACING = 8;
const Main = ({ children, sx, ...other }) => {
  const isDesktop = useResponsive('up', 'lg');

  return (
    <Box
      component="main"
      sx={{
        overflowX: 'hidden',
        flexGrow: 1,
        py: `${HEADER.H_MOBILE + SPACING}px`,
        ...(isDesktop && {
          py: `${HEADER.H_DESKTOP + SPACING}px`,
          width: `calc(100% - ${NAV.W_DASHBOARD}px)`,
        }),
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
}

export default Main;