import { Container, Stack, Box, Typography, Divider } from '@mui/material';
import Metric from 'components/Metric';
import { capitalize, secondsToHoursMinutesMetricDisplay } from 'util';
import HeartRateZonesWidget from 'components/common/HeartRateZonesWidget';
import { parseTimeZoneInSecs } from 'components/progress/ProgressCardio';
import ProgressFitnessHIstory from 'components/progress/ProgressFitnessHistory';

// Icons
import PaceIcon from 'components/Icons/PaceIcon';
import DistancePinsIcon from 'components/Icons/DistancePinsIcon';
import AttendanceIcon from 'components/Icons/AttendanceIcon';
import TrainingStressIcon from 'components/Icons/TrainingStressIcon';
import Pool from 'components/Icons/Pool';
import RunIcon from 'assets/icons/RunIcon';

const SwimmerReport = ({ report }) => {
  const paceData = report.activityType === 'swimming' ? report.avgPaceSec100M : report.avgPaceMinKm;
  return (
    <Container>
      <Stack direction='row' sx={{ justifyContent: 'space-between' }}>
        <Stack direction='row' spacing={1}>
          {report.activityType === 'swimming' ? (
            <Pool />
          ) : report.activityType === 'running' ? (
            <RunIcon sx={{ fontSize: 32 }} />
          ) : null}
          <Typography fontWeight={'bold'} variant='h6'>
            {report.activityType ? capitalize(report.activityType) : null} Report
          </Typography>
        </Stack>
        {!!report.user && <Typography>For: {report.user.name || report.user.email}</Typography>}
      </Stack>
      <Stack direction='row' spacing={3}>
        <Typography sx={{ opacity: 0.7 }}>
          {new Date(report.reportFrom).toLocaleDateString()} -{' '}
          {new Date(report.reportTo).toLocaleDateString()}
        </Typography>
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <ReportSection title='Attendance' icon={<AttendanceIcon />}>
          <Metric title={`${capitalize(report.activityType)}s`} value={report.activityCount} />
          <Metric
            title='Total time'
            value={secondsToHoursMinutesMetricDisplay(report.durationSec?.total)}
          />
        </ReportSection>
        {paceData ? (
          <ReportSection title='Pace' icon={<PaceIcon />}>
            <Metric title='Average pace/activity' value={paceData.median.toFixed(1)} />
            <Metric title='Best pace activity' value={paceData.min.toFixed(1)} />
          </ReportSection>
        ) : (
          <Typography opacity={0.7}>No pace data</Typography>
        )}
      </Stack>
      <Divider />
      <ReportSection title='Distance' icon={<DistancePinsIcon />}>
        {report.distanceM ? (
          <>
            <Metric
              title='Total distance'
              value={`${(report.distanceM.total / 1000).toFixed(1)}km`}
            />
            <Metric
              title='Average distance/activity'
              value={`${(report.distanceM.median / 1000).toFixed(1)}km`}
            />
            <Metric
              title='Min distance activity'
              value={`${(report.distanceM.min / 1000).toFixed(1)}km`}
            />
            <Metric
              title='Max distance activity'
              value={`${(report.distanceM.max / 1000).toFixed(1)}km`}
            />
          </>
        ) : (
          <Typography opacity={0.7}>No distance data</Typography>
        )}
      </ReportSection>
      <ReportSection title='Training Stress' icon={<TrainingStressIcon />}>
        {report.stressScore ? (
          <>
            <Metric title='Total Stress' value={Math.round(report.stressScore?.total)} />
            <Metric title='Avg. Stress/ activity' value={Math.round(report.stressScore?.median)} />
            <Metric title='Min Stress activity' value={Math.round(report.stressScore?.min)} />
            <Metric title='Max Stress activity' value={Math.round(report.stressScore?.max)} />
          </>
        ) : (
          <Typography opacity={0.7}>No stress data</Typography>
        )}
      </ReportSection>
      <Box pt={4} pb={4}>
        <Divider />
      </Box>
      {report.heartRateReport?.data ? (
        <HeartRateZonesWidget
          timeInHeartRateZonesSec={parseTimeZoneInSecs(report.heartRateReport.data)}
        />
      ) : null}
      <Box pt={4} pb={4}>
        <Divider />
      </Box>

      {report.trainingScores ? <ProgressFitnessHIstory fitness={report.trainingScores} /> : null}
    </Container>
  );
};
export default SwimmerReport;

const ReportSection = ({ icon, title, children }) => {
  return (
    <Box pt={4} pb={4}>
      <Stack direction='row' spacing={1} alignItems={'center'}>
        {icon ? icon : null}
        <Typography fontWeight={'bold'}>{title}</Typography>
      </Stack>
      <Stack direction='row' spacing={5}>
        {children}
      </Stack>
    </Box>
  );
};
