// Colors
const yellow200 = "#FFF59D";
const deepOrange600 = "#F4511E";
const lime300 = "#DCE775";
const lightGreen500 = "#8BC34A";
const teal700 = "#00796B";
const cyan900 = "#006064";
const colors = [
  deepOrange600,
  yellow200,
  lime300,
  lightGreen500,
  teal700,
  cyan900,
];
const blueGrey50 = "#ECEFF1";
const blueGrey300 = "#90A4AE";
const blueGrey700 = "#455A64";
const grey900 = "#212121";

// Typography
const sansSerif = "'Helvetica Neue', 'Helvetica', sans-serif";
const letterSpacing = "normal";
const fontSize = 16;

// Layout
const padding = 4;
const baseProps = {
  //   width: 350,
  //   height: 500,
  //   padding: 40,
};

// * Labels
const baseLabelStyles = {
  fontFamily: sansSerif,
  fontSize,
  letterSpacing,
  padding,
  fill: blueGrey700,
  stroke: "transparent",
  strokeWidth: 0,
};

const centeredLabelStyles = { textAnchor: "middle", ...baseLabelStyles };

// Strokes
const strokeDasharray = "10, 5";
const strokeLinecap = "round";
const strokeLinejoin = "round";
const axisColor = "rgba(18, 18, 18, 1)";

// Put it all together...
export const theme = {
  area: {
    style: {
      data: {
        fill: grey900,
      },
      labels: baseLabelStyles,
    },
    ...baseProps,
  },
  axis: {
    style: {
      axis: {
        fill: "transparent",
        stroke: axisColor,
        strokeWidth: 0.5,
        strokeLinecap,
        strokeLinejoin,
      },
      axisLabel: { ...centeredLabelStyles, padding, stroke: "transparent" },
      grid: {
        fill: "none",
        stroke: axisColor,
        strokeWidth: 0.5,
        strokeLinecap,
        strokeLinejoin,
      },
      ticks: {
        fill: "transparent",
        size: 5,
        stroke: axisColor,
        strokeWidth: 1,
        strokeLinecap,
        strokeLinejoin,
      },
      tickLabels: { ...baseLabelStyles, fill: 'rgba(245, 245, 250, 0.72)' },
    },
    ...baseProps,
  },
  line: {
    style: {
      data: {
        fill: "transparent",
        opacity: 1,
        stroke: blueGrey700,
        strokeWidth: 1,
      },
      labels: baseLabelStyles,
    },
    ...baseProps,
  },
  chart: baseProps,
};
