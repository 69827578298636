import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const LockIcon = ({sx, ...props}) => {
  return (
    <SvgIcon sx={{fill: '#fff', fillOpacity: .56, ...sx}} {...props} viewBox="0 0 38 38">
      <path d="M28.9997 12.6663H27.4163V9.49967C27.4163 5.12967 23.8697 1.58301 19.4997 1.58301C15.1297 1.58301 11.583 5.12967 11.583 9.49967V12.6663H9.99967C8.25801 12.6663 6.83301 14.0913 6.83301 15.833V31.6663C6.83301 33.408 8.25801 34.833 9.99967 34.833H28.9997C30.7413 34.833 32.1663 33.408 32.1663 31.6663V15.833C32.1663 14.0913 30.7413 12.6663 28.9997 12.6663ZM19.4997 26.9163C17.758 26.9163 16.333 25.4913 16.333 23.7497C16.333 22.008 17.758 20.583 19.4997 20.583C21.2413 20.583 22.6663 22.008 22.6663 23.7497C22.6663 25.4913 21.2413 26.9163 19.4997 26.9163ZM24.408 12.6663H14.5913V9.49967C14.5913 6.79217 16.7922 4.59134 19.4997 4.59134C22.2072 4.59134 24.408 6.79217 24.408 9.49967V12.6663Z" />
    </SvgIcon>
  );
};

export default LockIcon;