import {useState} from "react";
import {Box} from "@mui/material";
import Main from "./Main";
import Header from "./header/Header";
import NavVertical from "./nav/NavVertical";


const DashboardLayout = ({ children, isCoachLayout }) => {
  const [open, setOpen] = useState(true);

  const handleOpen = () => setOpen(prevState => !prevState);

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <>
      <Header isCoachLayout={isCoachLayout} onOpenNav={handleOpen} />

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        <NavVertical isCoachLayout={isCoachLayout} openNav={open} onCloseNav={handleClose} />

        <Main>
          {children}
        </Main>
      </Box>
    </>
  );
};
export default DashboardLayout;
