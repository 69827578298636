import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import api from "api";

import { Link } from "react-router-dom";

const Garmin = () => {
  const [connected, setConnected] = useState(false);
  const [error, setError] = useState(false);

  const history = useHistory();
  const query = useQuery();
  const token = query.get("oauth_token");
  const verifier = query.get("oauth_verifier");
  const iosUserId = query.get("iosUserId");
  let userId = query.get("userId");

  if (query.has("iosUserId")) {
    userId = iosUserId;
  }

  useEffect(() => {
    const connect = async () => {
      try {
        await api.garmin.connectAccount({ token, verifier, userId });
        setConnected(true);
      } catch (err) {
        console.log("GARMIN CONNECT ERROR", err);
        setError(true);
      }
    };

    connect();
  }, []);

  if (!query.has("oauth_verifier") || !query.has("oauth_token")) {
    history.replace("/");
  }

  return (
    <div className="full-center">
      <div style={{ textAlign: "center" }}>
        <h1>
          {connected
            ? "Garmin is connected!"
            : error
            ? "There was a problem connecting the Garmin account. Please try again."
            : "Connecting your Garmin account..."}
        </h1>
        {connected && !query.has("iosUserId") ? (
          <div className="button" style={{ margin: "2rem auto" }}>
            <Link to="/activities">Click To Go To Activities</Link>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Garmin;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
