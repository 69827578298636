import {useState} from "react";
import {useFormContext, useWatch} from "react-hook-form";
// @mui
import {Box, Button, FormControlLabel, Radio, RadioGroup, Stack} from '@mui/material';
import Typography from "@mui/material/Typography";
// components
import MenuPopover from "../menu-popover";
import {RHFMultiCheckbox, RHFRadioGroup} from "../hook-form";

// ----------------------------------------------------------------------

const FLAVORS_LEVEL_OPTIONS = [
  {value: '', label: 'Any'},
  {value: 'warmup', label: 'Warm Up'},
  {value: 'main', label: 'Main Set'},
  {value: 'cooldown', label: 'Cool Down'},
];

const FILTERS_DISTANCE_OPTIONS = [
  {value: '', label: 'Any'},
  {value: ',500', label: '<500m'},
  {value: '500,1000', label: '500-1000m'},
  {value: '1000,2000', label: '1km-2km'},
  {value: '2000,3000', label: '2km-3km'},
  {value: '3000,', label: '>3km'},
];

const FILTERS_EQUIPMENT_RADIO_OPTIONS = [
  {value: '', label: 'Any'},
  {value: 'none', label: 'None'},
];

const FILTERS_EQUIPMENT_OPTIONS = [
  {value: 'fins', label: 'Fins'},
  {value: 'kickboard', label: 'Kickboard'},
  {value: 'snorkel', label: 'Snorkel'},
  {value: 'pullbuoy', label: 'Pull Buoy'},
  {value: 'paddles', label: 'Paddles'},
  {value: 'baton', label: 'Baton'},
  {value: 'band', label: 'Band'},
  {value: 'techtoc', label: 'Techtoc'},
];

const ScrollWrap = ({children}) => (
  <Box sx={{overflowY: 'auto', maxHeight: 'calc(90vh - 46px)'}}>
    {children}
  </Box>
);

const SetFiltersPopover = ({onResetFilter}) => {
  const [openPopover, setOpenPopover] = useState(null);

  const {control, setValue} = useFormContext();

  const [minDistance, maxDistance] = useWatch({
    control,
    name: ['minDistance', 'maxDistance'],
  });

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleOnReset = () => {
    onResetFilter();
    handleClosePopover();
  }


  return (
    <>
      <Button sx={{textTransform: 'Capitalize'}} variant="text" onClick={handleOpenPopover}>Filters</Button>

      <MenuPopover
        disabledArrow
        arrow="top-left"
        open={openPopover}
        onClose={handleClosePopover}
        sx={{minWidth: 200, px: 0, py: .5}}
      >
        <ScrollWrap>
          <Stack sx={{px: 2, py: 1}} gap={2}>
            <Box>
              <Typography variant="body1" color="text.secondary">Flavour</Typography>
              <RHFRadioGroup
                name="flavor"
                options={FLAVORS_LEVEL_OPTIONS}
                sx={{display: 'grid', gridTemplateColumns: {md: 'repeat(3, 1fr)'}}}
              />
              <Typography variant="caption" color="text.secondary">
                Select the level of workout you want to swim
              </Typography>
            </Box>

            <Box>
              <Typography variant="body1" color="text.secondary">Total Distance</Typography>
              <RadioGroup
                value={!minDistance && !maxDistance ? '' : [minDistance, maxDistance].join(',')}
                sx={{display: 'grid', gridTemplateColumns: {md: 'repeat(3, 1fr)'}}}
                onChange={(event) => {
                  const value = event.target.value.split(',');
                  setValue('minDistance', value[0] ? Number(value[0]) : undefined);
                  setValue('maxDistance', value[1] ? Number(value[1]) : undefined);
                }}
                row>
                {FILTERS_DISTANCE_OPTIONS.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio/>}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
              <Typography variant="caption" color="text.secondary">
                Select the distance of the workout you want to swim
              </Typography>
            </Box>

            <Box>
              <Typography variant="body1" color="text.secondary">Equipment</Typography>
              <RHFRadioGroup
                name="equipment"
                options={FILTERS_EQUIPMENT_RADIO_OPTIONS}
                sx={{display: 'grid', gridTemplateColumns: {md: 'repeat(3, 1fr)'}}}
              />
              <RHFMultiCheckbox
                name="equipment"
                options={FILTERS_EQUIPMENT_OPTIONS}
                formGroupProps={{sx: {display: 'grid', gridTemplateColumns: {md: 'repeat(3, 1fr)'}}}}
                transform={{
                  input: (value) => value.split(','),
                  output: (value, values) => {
                    if (values === 'none') {
                      return value;
                    }
                    const valuesArr = values ? values.split(',') : [];
                    const output = valuesArr.includes(value) ? valuesArr.filter((v) => v !== value) : [...valuesArr, value];
                    return output.join(',');
                  }
                }}
              />
              <Typography variant="caption" color="text.secondary">
                Select any equipment you want the workout to require
              </Typography>
            </Box>
          </Stack>
        </ScrollWrap>

        <Stack padding={1} direction="row" spacing={1} justifyContent="space-between">
          <Button onClick={handleOnReset} size="small" variant="outlined">Reset</Button>
        </Stack>
      </MenuPopover>
    </>
  );
}

export default SetFiltersPopover;
