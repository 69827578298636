import { useContext, useState } from 'react';
import { useInfiniteQuery, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
// @mui
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Stack,
  styled,
  Switch,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
// icons
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import TvIcon from '@mui/icons-material/Tv';
// hook form
import FormProvider from '../../../components/hook-form';
// components
import { useAlertContext } from '../../../components/alert/AlertContext';
import { UserContext } from '../../../context';
import { SkeletonWorkoutItem } from '../../../components/skeleton';
import { ConfirmDialog } from '../../../components/confirm-dialog';
import WorkoutCard from '../../../components/workout/WorkoutCard';
import {
  WorkoutFiltersPopover,
  WorkoutSortBySelector,
} from '../../../components/filters';
import api from '../../../api';
import { trackDeleteWorkout } from '../../../tracking';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  transitions: theme.transitions.create('opacity'),
  '&:not(:hover)': {
    opacity: 0.6,
  },
}));

const WorkoutCardToolbar = ({
  checked,
  isUpdating,
  onChecked,
  onEdit,
  onDelete,
  onFullScreen,
}) => {
  const [isChecked, setIsChecked] = useState(!!checked);
  const handleOnChange = (e, value) => {
    setIsChecked(value);
    onChecked(value);
  };

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'flex-end',
      }}
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        gap={2}
        width='100%'
      >
        <FormControlLabel
          checked={isChecked}
          disabled={!!isUpdating}
          onChange={handleOnChange}
          control={<Switch color='success' size='small' />}
          label='Published'
        />
        <Box display='flex' gap={1}>
          <StyledIconButton size='small' onClick={onFullScreen}>
            <TvIcon fontSize='small' />
          </StyledIconButton>
          <StyledIconButton size='small' onClick={onEdit}>
            <ModeEditIcon fontSize='small' />
          </StyledIconButton>
          <StyledIconButton size='small' onClick={onDelete}>
            <DeleteIcon fontSize='small' />
          </StyledIconButton>
        </Box>
      </Stack>
    </Box>
  );
};

const WorkoutsList = () => {
  const history = useHistory();

  const { user } = useContext(UserContext);

  const { showAlert } = useAlertContext();

  const defaultValues = {
    pageSize: 12,
    environment: '',
    levels: '',
    minDistance: undefined,
    maxDistance: undefined,
    equipment: '',
    sortBy: 'createdAt',
    sortOrder: 'desc',
  };

  const methods = useForm({
    defaultValues,
  });

  const { reset, watch } = methods;

  const values = watch();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    refetch: refetchCoachWorkoutsListData,
  } = useInfiniteQuery({
    queryKey: ['coachWorkoutsListData', values],
    queryFn: ({ pageParam = 0 }) =>
      api.coaching.workouts.list({ page: pageParam, ...values }),
    getNextPageParam: (lastPage) => lastPage.nextPage,
  });

  const deleteWorkoutMutation = useMutation(api.coaching.workouts.remove);

  const updateWorkoutMutation = useMutation(({ id, published }) =>
    api.coaching.workouts.update(id, { published })
  );

  const [deleteDialog, setDeleteDialog] = useState({ open: false, id: null });

  const workoutData =
    data?.pages.flatMap((page) => page.results.map((item) => item)) ?? [];

  const isNothingFound = !isLoading && !workoutData.length;

  const dataLength = data?.pages
    ? data.pages.length * data.pages[0].results.length
    : 0;

  const handleResetFilter = () => {
    reset();
  };

  const handleOpenDialog = (id) => {
    setDeleteDialog({ open: true, id });
  };

  const handleCloseDialog = () => {
    setDeleteDialog({ open: false, id: null });
  };

  const handleDeleteWorkoutMutation = (data) =>
    deleteWorkoutMutation.mutate(data, {
      onSuccess: () => {
        refetchCoachWorkoutsListData();
        trackDeleteWorkout();
        showAlert('Workout successfully deleted.', { severity: 'success' });
        handleCloseDialog();
      },
      onError: () => showAlert('Something went wrong.', { severity: 'error' }),
    });

  const handleUpdateWorkoutPublishMutation = (data) =>
    updateWorkoutMutation.mutate(data, {
      onSuccess: () => {
        refetchCoachWorkoutsListData();
        showAlert('Workout successfully updated.', { severity: 'success' });
      },
      onError: () => showAlert('Something went wrong.', { severity: 'error' }),
    });

  const handleOnCreateWorkout = () => {
    history.push('/coaching/workouts/create');
  };

  const handleOnEditWorkout = (id) => {
    history.push(`/coaching/workouts/edit/${id}`);
  };

  const handleOnFullScreenWorkout = (id) => {
    history.push(`/coaching/workouts/fullscreen/${id}`);
  };

  return (
    <FormProvider methods={methods}>
      <Stack
        mb={4}
        direction={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'start', sm: 'center' }}
        justifyContent='space-between'
        gap={2}
      >
        <Box display='flex' alignItems='end' gap={2}>
          <WorkoutSortBySelector />
          <WorkoutFiltersPopover onResetFilter={handleResetFilter} />
        </Box>
        <Button onClick={handleOnCreateWorkout} variant='contained'>
          New workout
        </Button>
      </Stack>

      <InfiniteScroll
        dataLength={dataLength}
        next={fetchNextPage}
        hasMore={hasNextPage}
        scrollableTarget='scrollContainer'
        loader={<LinearProgress sx={{ borderRadius: 4 }} />}
      >
        <Box
          columnGap={1}
          rowGap={1.5}
          display='grid'
          gridTemplateColumns={{ md: 'repeat(2, 1fr)' }}
        >
          {(isLoading && !isNothingFound ? [...Array(12)] : workoutData).map(
            (item, index) =>
              item ? (
                <WorkoutCard
                  key={item.id}
                  data={item}
                  lapUnit={user.lapUnit}
                  toolbar={
                    <WorkoutCardToolbar
                      checked={!!item.published}
                      isUpdating={
                        updateWorkoutMutation.isLoading &&
                        updateWorkoutMutation.variables.id === item.id
                      }
                      onChecked={(published) =>
                        handleUpdateWorkoutPublishMutation({
                          id: item.id,
                          published,
                        })
                      }
                      onEdit={() => handleOnEditWorkout(item.id)}
                      onDelete={() => handleOpenDialog(item.id)}
                      onFullScreen={() => handleOnFullScreenWorkout(item.id)}
                    />
                  }
                />
              ) : (
                <SkeletonWorkoutItem key={index} />
              )
          )}
        </Box>
      </InfiniteScroll>

      {isNothingFound && (
        <Typography variant='body2' sx={{ color: 'text.secondary' }}>
          Nothing found...
        </Typography>
      )}

      <ConfirmDialog
        open={deleteDialog.open}
        onClose={handleCloseDialog}
        title='Delete this workout?'
        content="You can't undo this action and workout will be no longer be available to your swimmers."
        action={
          <LoadingButton
            variant='contained'
            onClick={() => handleDeleteWorkoutMutation(deleteDialog.id)}
            loading={deleteWorkoutMutation.isLoading}
            disabled={deleteWorkoutMutation.isLoading}
          >
            Delete
          </LoadingButton>
        }
      />
    </FormProvider>
  );
};

export default WorkoutsList;
