import {useMutation, useQueryClient} from "react-query";
import {useHistory} from "react-router-dom";
// @mui
import {Box, Container} from "@mui/material";
// components
import {useAlertContext} from "../../../components/alert/AlertContext";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
import SetCreatorForm from "./SetCreatorForm";
import api from "../../../api";
import {trackSaveSet} from "../../../tracking";


const SetCreatorPage = () => {
  const history = useHistory();

  const {showAlert} = useAlertContext();

  const queryClient = useQueryClient();

  const createCoachWorkoutSetMutation = useMutation(api.coaching.workoutSets.create);

  const handleCreateCoachSetWorkout = (data) => createCoachWorkoutSetMutation.mutate(data, {
    onSuccess: (data) => {
      const {
        baseFtp: setBaseFtp,
        distance: setDistance,
        repeat: setRepeat,
        totalDistance: setTotalDistance,
        coachId,
        flavor: setFlavor,
        segmentCount: setSegmentCount,
        equipment: setEquipment,
      } = data;

      trackSaveSet({
        coachId,
        setBaseFtp,
        setDistance,
        setRepeat,
        setTotalDistance,
        setFlavor,
        setSegmentCount,
        setHasEquipment: !!setEquipment?.length,
        setEquipment,
        isEdit: false,
        setIsSplit: data.segments?.[0]?.isSplit,
      });
      queryClient.invalidateQueries({queryKey: ['coachSetsListData']});
      history.push('/coaching/training?tab=sets');
      showAlert('Coach workout set created successfully.', {severity: 'success'});
    },
    onError: () => showAlert('Something went wrong.', {severity: 'error'}),
  });


  return (
    <Box sx={{py: 1}}>
      <Container>
        <CustomBreadcrumbs
          heading="Set Creator"
          links={[
            {
              name: 'Training Tools',
              href: '/coaching/training'
            },
            {
              name: 'Create New Set',
            }
          ]}
        />

        <SetCreatorForm
          onSubmit={handleCreateCoachSetWorkout}
          isSubmitting={createCoachWorkoutSetMutation.isLoading}
        />
      </Container>
    </Box>
  );
};

export default SetCreatorPage;
