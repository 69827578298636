import useStyles from "./index.styles";

import { Box, Stack, Typography } from "@mui/material";

import HandEntryRadialGraph from "./HandEntryRadialGraph";

import { HandIcon } from "../../../../assets/icons";

const HandEntry = ({ data, isMobile, hand, handEntry }) => {
  const { classes } = useStyles({ isMobile });

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Typography variant={isMobile ? "h5" : "h4"}>Hand Entry</Typography>
        <Typography variant={"body2"} className={classes.greyText}>
          Hand Entry angle measures the roll of your wrist at the point of entry
          on your catch. You are targeting a hand entry angle of 0°. Wrist flat,
          fingertips entering the water first.{" "}
        </Typography>
      </Box>
      <Box className={classes.contentContainer}>
        <Box>
          {/* <Box className={classes.subHeader}>
            <Typography variant={"h6"}>Analysis</Typography>
            <Typography variant={"body2"} className={classes.greyText}>
              Data is compared to your "normal"
            </Typography>
          </Box> */}
          <Box className={classes.buttonsContainer}>
            <Box className={classes.handContainer}>
              <HandIcon type={hand} />
              <Typography className={classes.handText}>
                {hand.charAt(0).toUpperCase() + hand.slice(1)} hand
              </Typography>
            </Box>
          </Box>
          <Box className={classes.statsWrapper}>
            <Box className={classes.statsContainer}>
              <Stack>
                <Typography variant={"h5"}>
                  {`${data[handEntry].mean}°`}
                </Typography>
                <Typography
                  variant={"caption"}
                  sx={{ color: "rgba(235, 235, 245, 0.6)" }}
                >
                  Hand entry angle
                </Typography>
              </Stack>
              <Stack>
                <Typography variant={"h5"}>
                  {`${data[handEntry].scorePercent}%`}
                </Typography>
                <Typography
                  variant={"caption"}
                  sx={{ color: "rgba(235, 235, 245, 0.6)" }}
                >
                  In target range
                </Typography>
              </Stack>
            </Box>
          </Box>
          <Box className={classes.chartContainer}>
            <HandEntryRadialGraph
              hand={hand}
              data={data.entryAngle}
              rotation={data.entryAngle.mean}
              bins={data.entryAngle.bins.values}
            />
          </Box>
        </Box>
        <Stack gap={"8px"}>
          <Typography variant={"h6"}>About Hand Entry</Typography>
          <Typography variant={"body1"} className={classes.greyText}>
            Hand Entry affects the amount of water you ‘catch’ at hand entry and
            the effective force can apply with a smaller applied surface area.
            <br />
            <br />
            A thumb first entry can also lead to an increased risk of shoulder
            pain in swimming.
            <br />
            <br />
            Concentrate on a having a flat wrist at entry so the pads of your
            fingers enter the water first and you have your entire palm
            positioned to apply force to the water.
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

export default HandEntry;
