import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import {alpha} from "@mui/material/styles";

const ToggleButtons = ({options, view, onChange, sx}) => {
  const handleOnChange = (event, newValue) => {
    if (newValue !== null) {
      onChange(newValue);
    }
  };
  return (
    <ToggleButtonGroup sx={(theme) => ({
      p: .5,
      gap: .5,
      color: theme.palette.common.white,
      backgroundColor: alpha(theme.palette.common.white, .11),
      borderRadius: Number(theme.shape.borderRadius) / 2,
      '.MuiToggleButtonGroup-grouped': {
        width: 120,
        [theme.breakpoints.up('sm')]: {
          width: 187,
        },
        border: 'none',
        '&.MuiToggleButton-secondary.MuiToggleButton-root': {
          borderRadius: Number(theme.shape.borderRadius) / 3,
        },
        '&.Mui-selected': {
          color: theme.palette.common.white,
          backgroundColor: alpha(theme.palette.common.white, .31),
        }
      },
      ...sx
    })} size="small" exclusive value={view} onChange={handleOnChange} color="secondary">
      {options.map(option => (
        <ToggleButton
          key={option.value}
          value={option.value}
          aria-label={option.label}
        >
          {option.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default ToggleButtons;