import {useState} from "react";
import {useInfiniteQuery, useMutation} from "react-query";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
// @mui
import {Box, Button, LinearProgress, Stack, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
// icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
// react hook form
import FormProvider from "../../../components/hook-form";
// components
import {useAlertContext} from "../../../components/alert/AlertContext";
import {ConfirmDialog} from "../../../components/confirm-dialog";
import {SkeletonWorkoutItem} from "../../../components/skeleton";
import SetCard from "../../../components/coaching/SetCard";
import {SetSortBySelector, SetFiltersPopover} from "../../../components/filters";
import {trackDeleteSet, trackSaveSet} from "../../../tracking";
import {StyledIconButton} from "../WorkoutCreator/form/WorkoutSetsStep";
import api from "../../../api";
import {v4 as uuid} from "uuid";


const SetActions = ({onCopy, onEdit, onDelete}) => (
  <Box display="flex" height="100%" alignItems="end" justifyContent="end" gap={2}>
    <StyledIconButton size="small" onClick={onCopy}>
      <ContentCopyRoundedIcon fontSize="small"/>
    </StyledIconButton>
    <StyledIconButton size="small" onClick={onEdit}>
      <EditIcon fontSize="small"/>
    </StyledIconButton>
    <StyledIconButton size="small" onClick={onDelete}>
      <DeleteIcon fontSize="small"/>
    </StyledIconButton>
  </Box>
);

const SetsList = () => {
  const history = useHistory();

  const {showAlert} = useAlertContext();

  const defaultValues = {
    pageSize: 15,
    flavor: '',
    minDistance: undefined,
    maxDistance: undefined,
    equipment: '',
    sortBy: 'createdAt',
    sortOrder: 'desc',
  };

  const methods = useForm({
    defaultValues,
  });

  const {
    reset,
    watch,
  } = methods;

  const values = watch();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["coachSetsListData", values],
    queryFn: ({pageParam = 0}) => api.coaching.workoutSets.list({page: pageParam, ...values}),
    getNextPageParam: (lastPage) => lastPage.nextPage,
  });

  const deleteWorkoutSetMutation = useMutation(api.coaching.workoutSets.remove);

  const createWorkoutSetMutation = useMutation(api.coaching.workoutSets.create);

  const [deleteDialog, setDeleteDialog] = useState({open: false, id: undefined});

  const setData = data?.pages.flatMap((page) => page.results.map((item) => item)) ?? [];

  const isNothingFound = !isLoading && !setData.length;

  const dataLength = data?.pages ? data.pages.length * data.pages[0].results.length : 0;

  const handleOpenDialog = (id) => {
    setDeleteDialog({open: true, id});
  }

  const handleCloseDialog = () => {
    setDeleteDialog({open: false, id: undefined});
  }

  const handleResetFilter = () => {
    reset();
  };

  const handleOnCreateSet = () => {
    history.push('/coaching/sets/create');
  }

  const handleDeleteWorkoutSet = (data) => deleteWorkoutSetMutation.mutate(data, {
    onSuccess: () => {
      refetch();
      trackDeleteSet();
      handleCloseDialog();
      showAlert('Coach set successfully deleted.', {severity: 'success'});
    },
    onError: () => showAlert('Something went wrong.', {severity: 'error'}),
  });

  const handleCopyWorkoutSet = async (data) => {
    const {updatedAt, createdAt, id, ...copyData} = await api.coaching.workoutSets.get(data.id);
    const newData = {
      ...copyData,
      name: copyData.name + ' - Copy',
      id: uuid()
    };
    createWorkoutSetMutation.mutate(newData, {
      onSuccess: (data) => {
        refetch();
        const {
          baseFtp: setBaseFtp,
          distance: setDistance,
          repeat: setRepeat,
          totalDistance: setTotalDistance,
          coachId,
          flavor: setFlavor,
          segmentCount: setSegmentCount,
          equipment: setEquipment,
        } = data;

        trackSaveSet({
          coachId,
          setBaseFtp,
          setDistance,
          setRepeat,
          setTotalDistance,
          setFlavor,
          setSegmentCount,
          setHasEquipment: !!setEquipment?.length,
          setEquipment,
          isEdit: false,
          setIsSplit: data.segments?.[0]?.isSplit,
        });
        showAlert('Coach set successfully duplicated.', {severity: 'success'});
      },
      onError: () => showAlert('Something went wrong.', {severity: 'error'}),
    });
  }

  const handleOnEdit = (id) => {
    history.push(`/coaching/sets/edit/${id}`)
  }


  return (
    <FormProvider methods={methods}>
      <Stack
        mb={4}
        direction={{xs: 'column', sm: 'row'}}
        alignItems={{xs: 'start', sm: 'center'}}
        justifyContent="space-between"
        gap={2}
      >
        <Box display="flex" alignItems="end" gap={2}>
          <SetSortBySelector/>
          <SetFiltersPopover onResetFilter={handleResetFilter}/>
        </Box>
        <Button onClick={handleOnCreateSet} variant="contained">New Set</Button>
      </Stack>

      <InfiniteScroll
        dataLength={dataLength}
        next={fetchNextPage}
        hasMore={hasNextPage}
        scrollableTarget="scrollContainer"
        loader={<LinearProgress sx={{borderRadius: 4}}/>}
      >
        <Box columnGap={1} rowGap={1.5} display="grid" gridTemplateColumns={{md: 'repeat(3, 1fr)'}}>
          {(isLoading && !isNothingFound ? [...Array(9)] : setData).map((item, index) =>
            item ? (
              <SetCard
                key={item.id}
                data={item}
                actions={(
                  <SetActions
                    onCopy={() => handleCopyWorkoutSet(item)}
                    onEdit={() => handleOnEdit(item.id)}
                    onDelete={() => handleOpenDialog(item.id)}
                  />
                )}
              />
            ) : (
              <SkeletonWorkoutItem key={index}/>
            )
          )}
        </Box>
      </InfiniteScroll>

      {isNothingFound && <Typography variant='body2' sx={{color: 'text.secondary'}}>Nothing found...</Typography>}

      <ConfirmDialog
        open={deleteDialog.open}
        onClose={handleCloseDialog}
        title="Delete set?"
        content="You can't undo this action. Workouts you imported this set into will not be affected."
        action={
          <LoadingButton
            loading={deleteWorkoutSetMutation.isLoading}
            disabled={deleteWorkoutSetMutation.isLoading}
            onClick={() => handleDeleteWorkoutSet(deleteDialog.id)}
            variant="contained"
          >
            Delete
          </LoadingButton>
        }
      />
    </FormProvider>
  );
};

export default SetsList;
