// @mui
import { MenuItem, TextField } from '@mui/material';

// ----------------------------------------------------------------------

const SortBySelector = ({ sortBy, sortOptions, onChange }) => {
  return (
    <TextField
      label="Sort by:"
      select
      variant="standard"
      size="small"
      value={sortBy}
      onChange={onChange}
      sx={{
        minWidth: 200,
        '.MuiInputLabel-root': {
          fontSize: 12,
          color: 'text.secondary',
          fontWeight: 400,
        }
      }}
    >
      {sortOptions.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          sx={{my: 0.5}}
        >
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SortBySelector;
