import { useState } from "react";

import { Box, Card, Grid, Stack, Typography } from "@mui/material";

import { FaChevronDown, FaChevronUp, FaMinus } from "react-icons/fa";
import { chartColors } from "../../style/colors";
import Modal from "../Modal";
import ActivityMotionModal from "../ActivityMotionModal";
import { useResponsive } from "../../hooks";

const MODAL_VARIANTS = {
  STROKE_COUNT: "stroke count",
  STROKE_PERIOD: "stroke period",
  HAND_ENTRY_SPLASH: "hand entry splash",
  PULL_FORCE: "pull force",
  CROSSOVER: "crossover",
  HAND_ENTRY: "hand entry",
};

const motionItemsData = {
  entryAngle: {
    title: "Hand Entry",
    strPattern: "%s% on target",
    modal: MODAL_VARIANTS.HAND_ENTRY,
  },
  crossoverAngle: {
    title: "Crossover",
    strPattern: "%s% on target",
    modal: MODAL_VARIANTS.CROSSOVER,
  },
  strokeCountFreestyle: {
    title: "Stroke Count",
    strPattern: "avg. %s s/l",
    modal: MODAL_VARIANTS.STROKE_COUNT,
  },
  strokePeriod: {
    title: "Stroke Period",
    strPattern: "avg. %ss",
    modal: MODAL_VARIANTS.STROKE_PERIOD,
  },
  forceScore: {
    title: "Pull Force",
    strPattern: "avg. %sg",
    modal: MODAL_VARIANTS.PULL_FORCE,
  },
  splashScore: {
    title: "Entry Splash",
    strPattern: "avg. %sg",
    modal: MODAL_VARIANTS.HAND_ENTRY_SPLASH,
  },
};

const zoneIcons = {
  0: <FaChevronDown size={22} />,
  1: <FaMinus size={22} />,
  2: <FaChevronUp size={22} />,
};

const colors = {
  0: chartColors.red,
  1: chartColors.orange,
  2: chartColors.green,
};

const WidgetCard = ({ title, value, icon, color, onClick }) => (
  <Card
    onClick={onClick}
    sx={{
      p: 1,
      backgroundColor: "background.secondary",
      cursor: "pointer",
    }}
  >
    <Stack direction="row" alignItems="center" gap={1.5}>
      {icon && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            backgroundColor: "background.secondaryLight",
            width: 40,
            height: 40,
            ...(color && {
              svg: {
                fill: color,
              },
            }),
          }}
        >
          {icon}
        </Box>
      )}
      <Box>
        <Typography variant="body1">{title}</Typography>
        <Typography sx={{ color }} variant="body1">
          {value}
        </Typography>
      </Box>
    </Stack>
  </Card>
);

const MotionAnalytics = ({ data, activity, sx }) => {
  const [modal, setModal] = useState(null);
  const isMobile = useResponsive("down", "md");

  const handleOpenModal = (metricKey, modalVariant, title) => {
    setModal({ variant: modalVariant, title });
  };

  console.log("Motion Analytics DATA", data);

  return (
    <>
      <Modal
        title={modal?.title}
        open={!!modal}
        handleClose={() => setModal(null)}
        fullScreen={!!isMobile}
      >
        {!!modal && (
          <ActivityMotionModal
            isMobile={isMobile}
            activity={activity}
            modalVariant={modal?.variant}
          />
        )}
      </Modal>
      <Grid sx={sx} container spacing={2}>
        {Object.keys(motionItemsData).map(
          (key, index) =>
            data[key] && (
              <Grid
                key={key}
                item
                xs={12}
                sm={6}
                md={[0, 1].includes(index) ? 6 : 3}
              >
                <WidgetCard
                  onClick={() =>
                    handleOpenModal(
                      "entryAngle",
                      motionItemsData[key].modal,
                      motionItemsData[key].title
                    )
                  }
                  title={motionItemsData[key].title}
                  value={motionItemsData[key].strPattern.replace(
                    /%s/g,
                    ["entryAngle", "crossoverAngle"].includes(key)
                      ? data[key].scorePercent
                      : data[key].mean
                  )}
                  icon={
                    [0, 1].includes(index)
                      ? null
                      : zoneIcons[data[key].similarityColor]
                  }
                  color={colors[data[key].similarityColor]}
                />
              </Grid>
            )
        )}
      </Grid>
    </>
  );
};
export default MotionAnalytics;
