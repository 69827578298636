import SvgIcon from '@mui/material/SvgIcon';
const DistanceIcon = props => {
  return (
    <SvgIcon {...props} fillOpacity={.56} fill="white">
      <path d="M14.9 6.90234L14.5 4.90234H5.5V21.9023H7.5V14.9023H13.1L13.5 16.9023H20.5V6.90234H14.9Z" />
    </SvgIcon>
  );
}

export default DistanceIcon;
