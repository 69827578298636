import { useState, useEffect } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ReferenceArea,
  Tooltip,
  ResponsiveContainer
} from 'recharts'

import ReferenceLabel from './ReferenceLabel'
import SegmentLabel from './SegmentLabel'
import CustomToolTip from './CustomToolTip'
import { heartRateColors } from '../../style/colors.js'

import { CHART_CONSTANTS } from './chartUtilities'
const labelHeight = CHART_CONSTANTS.labelHeight

const HeartRateChart = ({
  activity,
  analytics,
  heartRateZones,
  maxHeartRate,
  segments,
  activeSegment,
  setActiveSegment
}) => {
  const [data, setData] = useState(null)
  const [refAreas, setRefAreas] = useState(null)

  useEffect(() => {
    const heartRate = activity.analytics.sensorFeatures.timeSeries.heartRate
    const data = heartRate.x.map((x, i) => ({x: x, 'BPM': heartRate.y[i]}))
    const refAreas = analytics.sensorFeatures.annotations.timeSeries.segmentStartAndEndTimes
    setData(data)
    setRefAreas(refAreas)
  }, [activity])

  if(!data || !refAreas) {
    return null
  }

  return (
    <ResponsiveContainer width="100%" height={247}>
      <LineChart
        data={data}
        margin={{ top: 0, right: 40, left: 0, bottom: 0 }}
      >
        <XAxis
          dataKey="x"
          tick={false}
          height={labelHeight}
          type="number"
          scale="linear"
        />
      <YAxis domain={[40, maxHeartRate]} width={40} tick={{fontSize: 2}} />
        <Tooltip content={<CustomToolTip />} />
        <ReferenceArea y1={heartRateZones.rest} y2={heartRateZones.a} fillOpacity={.3} fill={heartRateColors.blue} />
        <ReferenceArea y1={heartRateZones.a} y2={heartRateZones.b} fillOpacity={.3} fill={heartRateColors.green} />
        <ReferenceArea y1={heartRateZones.b} y2={heartRateZones.c} fillOpacity={.3} fill={heartRateColors.yellow} />
        <ReferenceArea y1={heartRateZones.c} y2={heartRateZones.d} fillOpacity={.3} fill={heartRateColors.orange} />
        <ReferenceArea y1={heartRateZones.d} fillOpacity={.3} fill={heartRateColors.red} />
        {refAreas.map((arr, i) => (
          <ReferenceArea
            key={arr[0]}
            x1={arr[0]}
            x2={`${arr[1]}`}
            fillOpacity={activeSegment === i ? .5 : .1}
            label={<ReferenceLabel active={activeSegment === i}><SegmentLabel segment={segments[i]} /></ReferenceLabel>}
            onMouseOver={() => setActiveSegment(i)}
            onMouseOut={() => setActiveSegment(null)}
          />
        ))}
        <Line type="monotone" dataKey="BPM" stroke="rgba(241, 88, 79, 1)" strokeWidth={2} dot={false} activeDot={{ strokeWidth: 0, r: 5 }} />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default HeartRateChart;