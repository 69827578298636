// ----------------------------------------------------------------------

export default function Card(theme) {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          position: 'relative',
          borderRadius: Number(theme.shape.borderRadius) * 3,
          zIndex: 0, // Fix Safari overflow: hidden with border radius
          backgroundImage: 'none',
        },
      },
    },
  };
}
