// Packages
import PropTypes from "prop-types";
import { Modal, Box } from "@mui/material";
import { useWatch } from "react-hook-form";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { createSvgIcon } from "@mui/material/utils";

// Local modules
import { camelCaseToWords } from "../../util";

// components
import EquipmentIconList from "../EquipmentIconList";

const parseStrokeDisplay = (stroke, short) => {
  if (stroke === "freestyle") {
    return short ? "Fr" : "Free";
  } else if (stroke === "backstroke") {
    return short ? "Bk" : "Back";
  } else if (stroke === "butterfly") {
    return short ? "Fl" : "Fly";
  } else if (stroke === "breaststroke") {
    return short ? "Br" : "Breast";
  } else if (stroke === "any") {
    return "Any";
  } else {
    return camelCaseToWords(stroke);
  }
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const watchStyle = {
  height: "195px",
  width: "156px",
  background: "black",
  borderRadius: "24px",
  fontSize: "15px",
  display: "flex",
  flexDirection: "column",
  border: "1px solid grey",
};

const headerStyle = {
  height: "30px",
  padding: "6px 10px",
  display: "flex",
  justifyContent: "space-between",
  fontWeight: "600",
};

const contentStyle = {
  flex: "1",
  padding: "2px",
};

const contentLineStyle = {
  display: "flex",
  justifyContent: "space-between",
  lineHeight: "17.5px",
  padding: "2px 0",
  marginBottom: "4px",
  maxWidth: "100%",
};

const footerStyle = {
  height: "30px",
  padding: "0px 8px",
};

const footerInnerStyle = {
  display: "flex",
  justifyContent: "space-between",
  borderTop: "1px solid grey",
};

const renderSetType = (type) => {
  if (type === "warmup") {
    return "Warm Up";
  } else if (type === "main") {
    return "Main Set";
  } else if (type === "cooldown") {
    return "Cool Down";
  } else {
    return "";
  }
};

const effortColors = {
  a: "#00CDFF",
  b: "#1ADEA3",
  c: "#E4F14F",
  d: "#F38D2E",
  e: "#F1584F",
};

const SetPreviewModal = ({ open, setIndex, onClose, isSetCreator }) => {
  const set = useWatch(isSetCreator ? null : { name: `sets[${setIndex}]` });

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Box sx={watchStyle}>
          <Box sx={headerStyle}>
            <Box sx={{ color: "primary.main" }}>
              {renderSetType(set.flavor)}
            </Box>
            <Box>9:55</Box>
          </Box>
          <Box
            sx={{
              ...contentLineStyle,
              borderTop: "1px solid rgba(255,255,255,.2)",
              background: "rgba(255,255,255,.2)",
              padding: "2px",
            }}
          >
            <Box>{set.distance}</Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <EquipmentIconList equipment={set.equipment} keyPrefix={set.id} />
              {set.repeat > 1 && ` ${set.repeat}x`}
            </Box>
          </Box>

          <Box sx={contentStyle}>
            {set.segments.map((segment) => {
              if (segment.isSplit) {
                return segment.splits.map((split) => (
                  <SplitLine
                    key={split.id}
                    split={split}
                    equipment={
                      segment.equipment?.length ? segment.equipment : null
                    }
                  />
                ));
              } else {
                return <SegmentLine key={segment.id} segment={segment} />;
              }
            })}
          </Box>

          <Box sx={footerStyle}>
            <Box sx={footerInnerStyle}>
              <Box>PACE</Box>
              <Box>
                134 <FavoriteIcon sx={{ fontSize: 18 }} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

SetPreviewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  set: PropTypes.object,
};

export default SetPreviewModal;

const SegmentLine = ({ segment }) => {
  return (
    <Box key={segment.id} sx={contentLineStyle}>
      <Box sx={{ color: effortColors[segment.effort], paddingRight: "5px" }}>
        {segment.repeat > 1 && `${segment.repeat}x`}
        {segment.distance}
      </Box>
      <Box sx={{ display: "flex", flex: 1 }}>
        <Box
          sx={{
            color: effortColors[segment.effort],
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            flex: 1,
          }}
        >
          {segment.stroke !== "freestyle" && (
            <div className="truncate-wrap">
              <div className="truncate-inner">
                {parseStrokeDisplay(segment.stroke)}
              </div>
            </div>
          )}
        </Box>
        <Box
          sx={{
            marginLeft: "2px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {!!segment.equipment?.length ? (
            <EquipmentIconList
              equipment={segment.equipment}
              keyPrefix={segment.id}
            />
          ) : (
            segment.stroke === "freestyle" && (
              <Box
                sx={{
                  color: effortColors[segment.effort],
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  marginRight: "2px",
                }}
              >
                <TargetIcon sx={{ fontSize: 13, marginRight: "2px" }} />
                {segment.targetTimeDisplay}
              </Box>
            )
          )}
          <CycleIcon
            sx={{
              fontSize: 13,
              marginRight: "2px",
              color: "rgba(235, 235, 245, 0.6)",
            }}
          />
          {segment.cycleTimeDisplay}
        </Box>
      </Box>
    </Box>
  );
};

const SplitLine = ({ split, equipment }) => {
  return (
    <Box sx={{ ...contentLineStyle, color: effortColors[split.effort] }}>
      <Box>{split.distance}</Box>
      <Box sx={{ flex: 1, marginLeft: "4px" }}>
        {split.stroke !== "freestyle" && (
          <div className="truncate-wrap">
            <div className="truncate-inner">
              {parseStrokeDisplay(split.stroke)}
            </div>
          </div>
        )}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {equipment && (
          <EquipmentIconList equipment={split.equipment} keyPrefix={split.id} />
        )}
        {split.stroke === "freestyle" && (
          <>
            <TargetIcon sx={{ fontSize: 13, marginRight: "2px" }} />
            {split.targetTimeDisplay}
          </>
        )}
      </Box>
    </Box>
  );
};

const TargetIcon = createSvgIcon(
  <path d="M 12.449219 23.683594 C 18.671875 23.683594 23.828125 18.527344 23.828125 12.304688 C 23.828125 6.082031 18.660156 0.925781 12.4375 0.925781 C 6.214844 0.925781 1.058594 6.082031 1.058594 12.304688 C 1.058594 18.527344 6.214844 23.683594 12.449219 23.683594 Z M 12.449219 21.609375 C 7.28125 21.609375 3.15625 17.460938 3.15625 12.304688 C 3.15625 7.148438 7.28125 3 12.4375 3 C 17.59375 3 21.742188 7.148438 21.753906 12.304688 C 21.753906 17.460938 17.605469 21.609375 12.449219 21.609375 Z M 12.449219 19.3125 C 16.28125 19.3125 19.457031 16.125 19.457031 12.304688 C 19.457031 8.472656 16.269531 5.285156 12.449219 5.285156 C 8.617188 5.285156 5.441406 8.472656 5.441406 12.304688 C 5.441406 16.125 8.628906 19.3125 12.449219 19.3125 Z M 12.449219 17.390625 C 9.683594 17.390625 7.363281 15.070312 7.363281 12.304688 C 7.363281 9.527344 9.671875 7.207031 12.449219 7.207031 C 15.203125 7.207031 17.535156 9.539062 17.535156 12.304688 C 17.535156 15.082031 15.214844 17.390625 12.449219 17.390625 Z M 12.460938 15.117188 C 13.996094 15.117188 15.273438 13.863281 15.273438 12.292969 C 15.273438 10.757812 13.996094 9.492188 12.460938 9.492188 C 10.902344 9.492188 9.625 10.757812 9.625 12.292969 C 9.625 13.851562 10.902344 15.117188 12.460938 15.117188 Z M 12.460938 15.117188 " />,
  "Target"
);

const CycleIcon = createSvgIcon(
  <path d="M 14.339844 0.925781 C 8.953125 0.925781 4.398438 4.898438 3.386719 10.101562 L 1.84375 10.101562 C 1.027344 10.101562 0.808594 10.699219 1.269531 11.34375 L 3.8125 14.976562 C 4.167969 15.492188 4.71875 15.480469 5.066406 14.976562 L 7.609375 11.332031 C 8.042969 10.710938 7.835938 10.101562 7.03125 10.101562 L 5.46875 10.101562 C 6.421875 6.023438 10.011719 3.011719 14.339844 3.011719 C 17.339844 3.023438 19.976562 4.476562 21.632812 6.75 C 22.011719 7.230469 22.597656 7.382812 23.09375 7.054688 C 23.566406 6.726562 23.667969 6.070312 23.242188 5.519531 C 21.207031 2.777344 17.925781 0.925781 14.339844 0.925781 Z M 13.933594 5.847656 C 13.417969 5.847656 13.015625 6.257812 13.015625 6.761719 L 13.015625 12.867188 C 13.015625 13.125 13.09375 13.359375 13.28125 13.617188 L 15.925781 17.15625 C 16.292969 17.660156 16.835938 17.742188 17.292969 17.414062 C 17.71875 17.109375 17.753906 16.546875 17.421875 16.089844 L 14.832031 12.550781 L 14.832031 6.761719 C 14.832031 6.257812 14.429688 5.847656 13.933594 5.847656 Z M 14.339844 23.683594 C 19.722656 23.683594 24.277344 19.710938 25.300781 14.507812 L 26.832031 14.507812 C 27.648438 14.507812 27.867188 13.910156 27.417969 13.265625 L 24.867188 9.632812 C 24.507812 9.117188 23.96875 9.128906 23.609375 9.632812 L 21.070312 13.277344 C 20.632812 13.898438 20.839844 14.507812 21.644531 14.507812 L 23.207031 14.507812 C 22.253906 18.585938 18.664062 21.609375 14.339844 21.609375 C 11.335938 21.597656 8.699219 20.132812 7.042969 17.859375 C 6.664062 17.378906 6.078125 17.226562 5.582031 17.554688 C 5.109375 17.882812 5.007812 18.539062 5.433594 19.089844 C 7.46875 21.832031 10.75 23.683594 14.339844 23.683594 Z M 14.339844 23.683594 " />,
  "Cycle"
);
