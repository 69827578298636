import { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { usePubNub } from 'pubnub-react';
import api from 'api';
import { UserContext, CoachContext } from './context';
import Spinner from './components/common/Spinner';

const TokenManager = ({ children }) => {
  const authorized = !!localStorage.getItem('refresh_token');
  const history = useHistory();
  const location = useLocation();
  const pubnub = usePubNub();
  const isCoachRoute = !!location.pathname.includes('/coaching');
  const [currentView, setCurrentView] = useState(isCoachRoute ? 'coach' : 'swimmer');
  const [pubnubLoading, setPubnubLoading] = useState(true);

  const { isLoading, isSuccess, error, data, refetch, remove } = useQuery('user', api.user.get, {
    enabled: authorized,
    onSuccess: async (data) => {
      window.FreshworksWidget('identify', 'ticketForm', {
        name: data.firstName,
        email: data.email
      });

      if (data.isAdmin) {
        localStorage.setItem('isAdmin', 'true');
      } else {
        localStorage.removeItem('isAdmin');
      }

      if (!!data.id && !data.onboarded && !isCoachRoute) {
        history.push('/onboard');
      }
    }
  });

  useEffect(() => {
    const setPubnub = async (user) => {
      pubnub.setUUID(isCoachRoute ? user.coachProfile.id : user.id);

      const body = { uuid: pubnub.getUUID() };
      if (isCoachRoute) {
        body.channel = `coaching.${user.coachProfile.id}.*`;
      } else {
        body.channel = user.coachChatChannel;
      }

      const token = await api.pubnub.getToken(body);
      pubnub.setToken(token);
      pubnub.subscribe({ channels: [body.channel] });

      setPubnubLoading(false);
    };

    if (isSuccess) {
      if (isCoachRoute || !!data.coachChatChannel) {
        setPubnub(data);
      } else {
        setPubnubLoading(false);
      }
    }
  }, [isSuccess, data, isCoachRoute, pubnub]);

  // useEffect(() => {
  //   pubnub.addListener(pubnubMessageHandler);
  //   return () => {
  //     pubnub.removeListener(pubnubMessageHandler);
  //     pubnub.unsubscribeAll();
  //     console.log("CLEANUP TOKEN MANAGER");
  //   };
  // }, []);

  useEffect(() => {
    if (isCoachRoute && currentView === 'swimmer') {
      console.log('SWITCHING TO COACH VIEW');
      setPubnubLoading(true);
      pubnub.unsubscribeAll();
      remove();
      refetch();
      setCurrentView('coach');
    } else if (!isCoachRoute && currentView === 'coach') {
      console.log('SWITCHING TO SWIMMER VIEW');
      setPubnubLoading(true);
      pubnub.unsubscribeAll();
      remove();
      refetch();
      setCurrentView('swimmer');
    }
  });

  if (authorized && (isLoading || pubnubLoading)) {
    return <Spinner window />;
  }

  if (error) {
    return 'There was an error fetching user data. Please refresh the page';
  }

  return (
    <>
      {isCoachRoute ? (
        <UserContext.Provider value={{ user: data, refetch: refetch }}>
          <CoachContext.Provider value={{ coach: data.coachProfile, refetch: refetch }}>
            {children}
          </CoachContext.Provider>
        </UserContext.Provider>
      ) : (
        <UserContext.Provider value={{ user: data, refetch: refetch }}>
          {children}
        </UserContext.Provider>
      )}
    </>
  );
};

// TokenManager.propTypes = {
//   : PropTypes.
// };

export default TokenManager;
