import { Typography, Stack, Card, Link } from '@mui/material';

const IOS = () => (
  <Card sx={{ backgroundColor: 'background.secondary', p: 2 }}>
    <Stack gap={2}>
      <Typography variant='h5'>Getting started as an iPhone user</Typography>
      <Typography variant='body1' color='text.secondary'>
        Simma for iOS is available in the app store:{' '}
        <Link
          href='https://apps.apple.com/us/app/simma/id1599744272?platform=iphone'
          target='_blank'
          rel='noopener'>
          Download App
        </Link>
      </Typography>
    </Stack>
  </Card>
);

export default IOS;
