import './style/app.scss';
import Router from './Router';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';

import ReactGA from 'react-ga4';
import ThemeProvider from './style/theme';
import AuthProvider from './auth/AuthProvider';
import { AlertProvider } from './components/alert/AlertContext';

if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
}

const queryClient = new QueryClient({
  staleTime: 1000 * 60 * 10,
  refetchOnMount: false
});

const pubnub = new PubNub({
  publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
  subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
  uuid: `simma-web-${process.env.REACT_APP_STAGE}`
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AlertProvider>
        <AuthProvider>
          <PubNubProvider client={pubnub}>
            <ThemeProvider>
              <Router />
              <ReactQueryDevtools initialIsOpen={false} />
            </ThemeProvider>
          </PubNubProvider>
        </AuthProvider>
      </AlertProvider>
    </QueryClientProvider>
  );
}

export default App;
