import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
// @mui
import { Link, Stack, Alert, Box, InputLabel, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useAuthContext } from '../../auth/useAuthContext';
import FormProvider, { RHFTextField } from '../hook-form';

// ----------------------------------------------------------------------

const AuthLoginForm = ({
  onRegister,
  onForgotPassword,
  afterLogin,
  navigateAfterLogin = true,
  createCoachProfile,
  coachInviteCode
}) => {
  const history = useHistory();

  const { login, sendVerification } = useAuthContext();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const defaultValues = {
    email: '',
    password: ''
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues
  });

  const {
    reset,
    watch,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful }
  } = methods;

  const { email } = watch();

  const onSubmit = async (data) => {
    try {
      await login({ ...data, createCoachProfile, coachInviteCode });
      if (afterLogin) {
        afterLogin();
      }
      if (navigateAfterLogin) {
        history.push('/progress');
      }
    } catch (error) {
      console.error(error);

      const message =
        typeof error?.response?.data?.message === 'string'
          ? error.response.data.message
          : error.message;

      if (error.response.status === 403) {
        // User is not confirmed
        sendVerification({ email });
      }

      reset();

      setError('afterSubmit', {
        ...error,
        message
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ px: 2, py: 3 }}>
        <Stack gap={3}>
          <Typography variant='body1'>Log into Simma</Typography>

          {!!errors.afterSubmit && <Alert severity='error'>{errors.afterSubmit.message}</Alert>}

          <Box>
            <InputLabel sx={{ mb: 0.5 }}>Email</InputLabel>
            <RHFTextField name='email' placeholder='name@domain.com' />
          </Box>

          <Box>
            <InputLabel sx={{ mb: 0.5 }}>Password</InputLabel>
            <RHFTextField name='password' placeholder='Password' type='password' />
          </Box>

          {!!onForgotPassword && (
            <Stack alignItems='flex-end'>
              <Link onClick={onForgotPassword} variant='body1' underline='none'>
                Forgot password?
              </Link>
            </Stack>
          )}

          <LoadingButton
            sx={{ textTransform: 'capitalize' }}
            size='large'
            fullWidth
            type='submit'
            variant='gradient'
            loading={isSubmitSuccessful || isSubmitting}>
            Log In
          </LoadingButton>

          <Stack direction='row' gap={1} justifyContent='center' alignItems='center'>
            <Typography variant='body1' color='text.secondary'>
              Need an account?
            </Typography>

            <Link onClick={onRegister} variant='body1' underline='none'>
              Join Simma
            </Link>
          </Stack>
        </Stack>
      </Box>
    </FormProvider>
  );
};

export default AuthLoginForm;
