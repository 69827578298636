// @mui
import {MenuItem, TextField} from '@mui/material';
// components
import {useFormContext, useWatch} from "react-hook-form";

// ----------------------------------------------------------------------

const OPTIONS = [
  {value: 'createdAt,desc', label: 'Latest first'},
  {value: 'createdAt,asc', label: 'Oldest first'},
  {value: 'title,desc', label: 'Alphabetical (Z to A)'},
  {value: 'title,asc', label: 'Alphabetical (A to Z)'},
];

// ----------------------------------------------------------------------

const WorkoutSortBySelector = () => {
  const {control, setValue} = useFormContext();

  const [sortBy, sortOrder] = useWatch({
    control,
    name: ['sortBy', 'sortOrder'],
  });

  return (
    <TextField
      name="sort"
      label="Sort by:"
      variant="standard"

      value={`${sortBy},${sortOrder}`}
      onChange={(e) => {
        const value = e.target.value.split(',');
        setValue('sortBy', value[0]);
        setValue('sortOrder', value[1]);
      }}

      select
      fullWidth
      SelectProps={{
        native: false,
        MenuProps: {
          PaperProps: {
            sx: {maxHeight: 220},
          },
        },
        sx: {
          textTransform: 'capitalize',
        },
      }}
      sx={{
        minWidth: 186,
        width: 'auto',
        '.MuiInputLabel-root': {
          fontSize: 12,
          color: 'text.secondary',
          fontWeight: 400,
        }
      }}
    >
      {OPTIONS.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          sx={{
            my: 0.5,
            typography: 'body2',
            textTransform: 'capitalize',
            '&:first-of-type': {mt: 0},
            '&:last-of-type': {mb: 0},
          }}
        >
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default WorkoutSortBySelector;