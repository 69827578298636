import SvgIcon from '@mui/material/SvgIcon';
const FinsIcon = props => {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.5 17C9.5 17 18 15.5 20 15C22 14.5 23.5 13.433 23.5 11.5C23.5 9.567 22 8.5 20 8C18 7.5 9.5 6 5.5 6H2.61804C1.87465 6 1.3795 6.78913 1.63524 7.48714C2.14448 8.87705 2.5 10.4127 2.5 11.5C2.5 12.5873 2.14448 14.1229 1.63524 15.5129C1.3795 16.2109 1.87465 17 2.61804 17H5.5ZM22 11.5C22 13.5 19.5 14 16.5 14C15.1193 14 14 12.8807 14 11.5C14 10.1193 15.1193 9 16.5 9C19 9 22 9.5 22 11.5ZM4.5 11C4.22386 11 4 11.2239 4 11.5C4 11.7761 4.22386 12 4.5 12H11.5C11.7761 12 12 11.7761 12 11.5C12 11.2239 11.7761 11 11.5 11H4.5Z" fill="#EBEBF5" fillOpacity="0.6"/>
    </SvgIcon>
  );
}

export default FinsIcon;
