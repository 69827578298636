// import PropTypes from 'prop-types';

const Butterfly = ({size = 34}) => {
  return (
    <svg width={`${size}`} height={`${size}`} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.169 29H7.55475V25.8364H10.8043V24.7085H7.55475V22.415H11.1104V21.2495H6.169V29ZM12.7065 29H17.6479V27.8345H14.0923V21.2495H12.7065V29ZM20.3022 29H21.6826V25.9438L24.4003 21.2495H22.8857L21.038 24.5742H20.9467L19.0991 21.2495H17.5844L20.3022 25.9438V29Z" fill="#F1584F"/>
      <path d="M23.77 13.3199C23.5917 12.4109 23.1034 11.592 22.3885 11.0029C21.6736 10.4138 20.7764 10.0912 19.85 10.09C19.5917 10.088 19.3338 10.1114 19.08 10.1599L18.85 10.2299C18.0467 10.7442 17.3643 11.4266 16.85 12.2299C16.58 12.7199 16.22 13.3599 15.85 14.0399C15.8385 14.3218 15.8621 14.6039 15.92 14.8799C15.92 14.9599 15.92 15.0299 15.98 15.1099C16.5384 15.0851 17.0925 15.2167 17.58 15.49L18.58 16.0699C19.0361 16.3332 19.5534 16.4719 20.08 16.4719C20.6066 16.4719 21.124 16.3332 21.58 16.0699L22.58 15.49C22.9165 15.3037 23.2874 15.1881 23.67 15.15C23.8483 14.5566 23.8825 13.9292 23.77 13.3199V13.3199Z" fill="#F1584F"/>
      <path d="M5.07999 16.33L6.60001 15.46C7.05606 15.1967 7.5734 15.0581 8.10001 15.0581C8.62662 15.0581 9.14395 15.1967 9.60001 15.46L10.6 16.04C11.0561 16.3033 11.5734 16.4419 12.1 16.4419C12.6266 16.4419 13.1439 16.3033 13.6 16.04L13.8 15.93L15.87 12.2C16.32 11.25 17.6 10.47 18.41 9.81004L21.11 7.70002C21.455 7.42657 21.6813 7.03058 21.7417 6.59449C21.8021 6.15841 21.6919 5.71583 21.4342 5.3589C21.1765 5.00196 20.7911 4.75818 20.3581 4.6783C19.9252 4.59841 19.4781 4.68858 19.11 4.93004L16.27 6.85002L14.85 7.85002C13.6863 8.63975 12.724 9.6912 12.04 10.92L10.8 12.85L10.46 12.91C7.75045 13.5294 5.18877 14.6744 2.92001 16.28C2.98927 16.3039 3.05622 16.334 3.12003 16.37C3.42268 16.5322 3.76198 16.6136 4.10526 16.6066C4.44853 16.5996 4.7842 16.5044 5.07999 16.33Z" fill="#F1584F"/>
      <path d="M28.3 5.54003C28.0791 5.2015 27.7331 4.96416 27.3378 4.87985C26.9424 4.79553 26.5298 4.87111 26.19 5.09005L23.3 6.95004L21.97 7.80004L20.26 9.13003C21.1094 9.19292 21.9286 9.47173 22.64 9.94003C23.0664 10.2332 23.4415 10.5947 23.75 11.01L27.98 7.55004C28.2687 7.31127 28.4605 6.97543 28.5194 6.60543C28.5783 6.23544 28.5003 5.85666 28.3 5.54003V5.54003Z" fill="#F1584F"/>
    </svg>
  );
}

// Butterfly.propTypes = {
//   : PropTypes.
// };

export default Butterfly;
