// @mui
import {Dialog, Stack, Typography, Box, Button, DialogTitle, DialogContent, DialogActions} from '@mui/material';

// ----------------------------------------------------------------------

export const SimpleConfirmDialog = ({title, content, action, open, onClose, ...other}) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <Box sx={{px: 2, py: 3}}>
        <Stack gap={2}>
          <Typography variant="body1">{title}</Typography>
          <Typography variant="body1">{content}</Typography>
          {action}
        </Stack>
      </Box>
    </Dialog>
  );
}

export const ConfirmDialog = ({title, content, action, open, onClose, ...other}) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{pb: 2}}>{title}</DialogTitle>

      {content && <DialogContent sx={{typography: 'body2', opacity: .7}}> {content} </DialogContent>}

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        {action}
      </DialogActions>
    </Dialog>
  );
}