import { useState } from 'react';
import { Button, Dialog, Link, styled } from '@mui/material';
import LandingHeroSection from './sections/LandingHeroSection';
// import LandingSecondHeroSection from './sections/LandingSecondHeroSection';
import LandingFeaturesSection from './sections/LandingFeaturesSection';
import LandingCTASection from './sections/LandingCTASection';
import LandingFooterSection from './sections/LandingFooterSection';
import LandingTestimonialSection from './sections/LandingTestimonialSection';
import CoachContactForm from './sections/CoachContactForm';
import LandingNavSection from './sections/LandingNavSection';
// import LandingCoachStartSection from './sections/LandingCoachStartSection';
import { pxToRem, responsiveFontSizes } from '../../util';
// dialogs
import AuthLoginForm from '../../components/auth/AuthLoginForm';
import AuthRegisterForm from '../../components/auth/AuthRegisterForm';
import AuthResetPasswordForm from '../../components/auth/AuthResetPasswordForm';
import AuthVerifyCodeForm from '../../components/auth/AuthVerifyCodeForm';
// import AuthNewPasswordForm from '../../components/auth/AuthNewPasswordForm';
import AuthVerifyEmailForm from '../../components/auth/AuthVerifyEmailForm';
import { useAuthContext } from '../../auth/useAuthContext';

import CoachSignupButton from './components/CoachSignupButton';

const StyledRoot = styled('div')({
  h2: {
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ sm: 48, md: 54, lg: 60 })
  },
  h3: {
    fontSize: pxToRem(34),
    ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 })
  },
  h4: {
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 30, md: 32, lg: 34 })
  },
  h6: {
    fontWeight: 400,
    fontSize: pxToRem(14),
    ...responsiveFontSizes({ sm: 16, md: 18, lg: 20 })
  },
  '.MuiTypography-body1': {
    lineHeight: 1.5,
    fontSize: pxToRem(14),
    ...responsiveFontSizes({ sm: 16, md: 16, lg: 16 })
  },
  '.MuiTypography-subtitle1': {
    lineHeight: 1.5,
    fontSize: pxToRem(14),
    ...responsiveFontSizes({ sm: 16, md: 16, lg: 16 })
  },
  '.MuiButton-contained': {
    color: '#000000DE'
  }
});

const featureData = [
  {
    title: 'Create quickly',
    description:
      'Our auto calculating workout creator speeds up the previously tedious process of calculating and creating workouts. You can even add your own drills. All workouts automatically scale to each swimmer’s FTP so you only have to create a workout once.',
    imageSrc: '/images/landing/coaches/landing-feature-2.png'
  },
  {
    title: 'Reduce repetition',
    description:
      'Create a library of reusable sets to put in your workouts. No more writing the same thing over and over. Make variations quickly.',
    imageSrc: '/images/landing/coaches/landing-feature-3.png',
    bgImageSrc: '/images/landing/coaches/landing-feature-3-bg.png'
  },
  {
    title: 'Organize',
    description:
      'A swimming analytical platform offers swimmers and coaches valuable insights by tracking performance metrics like stroke rate and distance per stroke. It can be used to create customised training plans and provide real-time feedback to help swimmers reach their goals faster.',
    imageSrc: '/images/landing/coaches/landing-feature-4.png'
  },
  //   {
  //     title: 'Sell with Stripe',
  //     description:
  //       'Simma has partnered with Stripe to power your coaching store. Connect your existing Stripe account or start a new one from within the app.',
  //     imageSrc: '/images/landing/coaches/landing-feature-5.png',
  //     bgImageSrc: '/images/landing/coaches/landing-feature-5-bg.png'
  //   },
  {
    title: 'Connect and inspect',
    description:
      'Connect with swimmers in your pool or around the world. See everyone’s data in detail with your personal coaching dashboard. See all the details of their sessions and training history.',
    imageSrc: '/images/landing/coaches/landing-feature-6.png'
  },
  {
    title: 'Chat',
    description:
      'Sell chat relationships on Simma to have conversations off the pool deck or across the world. You can add any swim activity or link to the swimmer’s progress page from within the chat.',
    imageSrc: '/images/landing/coaches/landing-feature-7.png',
    bgImageSrc: '/images/landing/coaches/landing-feature-7-bg.png',
    imageWidth: 296
  }
];

const ctaData = [
  {
    title: 'Start using Simma',
    description:
      'Gain exclusive access to personalised swim tracking and advanced analytics tailored to enhance your individual skills and requirements.'
  },
  {
    title: 'iPhone',
    description: 'Connect with your Apple or Garmin Watch by downloading our free iOS app.',
    imageSrc: '/images/landing/landing-apps-ios.png',
    buttonText: 'Download iOS App',
    link: 'https://apps.apple.com/us/app/simma/id1599744272?platform=iphone'
  },
  {
    title: 'Android',
    description:
      "Sign up for Simma's web application for free and connect seamlessly with your Garmin Watch.",
    imageSrc: '/images/landing/landing-apps-android.png'
  }
];

const defaultDialogsState = {
  login: false,
  register: false,
  forgotPassword: false,
  verifyCodeForm: false,
  verifyEmailForm: false,
  newPasswordForm: false
};

const CoachesLandingPage = () => {
  const { isAuthenticated } = useAuthContext();

  const [openDialogs, setOpenDialogs] = useState(defaultDialogsState);

  const handleOnLogin = () => setOpenDialogs({ ...defaultDialogsState, login: true });
  const handleOnRegister = () => setOpenDialogs({ ...defaultDialogsState, register: true });
  const handleOnForgotPassword = () =>
    setOpenDialogs({ ...defaultDialogsState, forgotPassword: true });
  const handleOnResetPassword = () =>
    setOpenDialogs({ ...defaultDialogsState, verifyCodeForm: true });
  const handleOnVerifyEmail = () =>
    setOpenDialogs({ ...defaultDialogsState, verifyEmailForm: true });
  const handleOnClose = () => setOpenDialogs(defaultDialogsState);

  return (
    <StyledRoot>
      <LandingNavSection
        action={
          isAuthenticated ? (
            <Button href='/progress' variant='outlined'>
              Go to app
            </Button>
          ) : (
            <Button onClick={handleOnLogin} variant='outlined' rel='noopener noreferrer'>
              Log In
            </Button>
          )
        }
      />

      <LandingHeroSection
        title='Expand your swim coaching business for free with Simma'
        videoBg='/images/landing/video/landing-bg-video.mp4'
        videoPoster='/images/landing/video/landing-bg-video-poster.jpg'
        action={<CoachSignupButton />}
      />

      {/* <LandingCoachStartSection /> */}
      <LandingTestimonialSection
        title='Hear from Jason'
        subtitles={['Former World Champion', 'Commonwealth Games Gold Medalist']}
        description={
          '"Simma is a game changer. It allows my clients to get instant feedback, to have all the data right there where we can connect it up, have a look and see what the results are straight away. That\'s going next level to what the elite athletes are dealing with."'
        }
        imageSrc='/images/landing/coaches/landing-testimonial-1.png'
      />

      {/* <LandingSecondHeroSection
        title='Setup your store'
        description='Upload a photo, select the products you want to sell and connect a Stripe account. You can be set up in minutes. Anyone around the world can buy your products from your profile page.'
        imageSrc='/images/landing/coaches/landing-feature-1.png'
        bgImageSrc='/images/landing/coaches/landing-feature-1-bg.png'
      /> */}

      <LandingFeaturesSection data={featureData} />

      <LandingCTASection
        data={ctaData}
        action={
          isAuthenticated ? (
            <Button component={Link} href='/progress' variant='contained'>
              Sign Up Now
            </Button>
          ) : (
            <Button onClick={handleOnRegister} variant='contained'>
              Sign Up Now
            </Button>
          )
        }
      />

      <LandingFooterSection form={<CoachContactForm sx={{ maxWidth: 615, margin: 'auto' }} />} />

      <Dialog
        fullWidth
        sx={{ maxWidth: 440, m: 'auto' }}
        open={openDialogs.login}
        onClose={handleOnClose}>
        <AuthLoginForm onRegister={handleOnRegister} onForgotPassword={handleOnForgotPassword} />
      </Dialog>

      <Dialog
        fullWidth
        sx={{ maxWidth: 440, m: 'auto' }}
        open={openDialogs.register}
        onClose={handleOnClose}>
        <AuthRegisterForm onLogin={handleOnLogin} onVerifyEmail={handleOnVerifyEmail} />
      </Dialog>

      <Dialog
        fullWidth
        sx={{ maxWidth: 440, m: 'auto' }}
        open={openDialogs.forgotPassword}
        onClose={handleOnClose}>
        <AuthResetPasswordForm />
      </Dialog>

      <Dialog
        fullWidth
        sx={{ maxWidth: 440, m: 'auto' }}
        open={openDialogs.forgotPassword}
        onClose={handleOnClose}>
        <AuthResetPasswordForm onResetPassword={handleOnResetPassword} />
      </Dialog>

      <Dialog
        fullWidth
        sx={{ maxWidth: 440, m: 'auto' }}
        open={openDialogs.verifyCodeForm}
        onClose={handleOnClose}>
        <AuthVerifyCodeForm onVerify={handleOnLogin} />
      </Dialog>

      <Dialog
        fullWidth
        sx={{ maxWidth: 440, m: 'auto' }}
        open={openDialogs.verifyEmailForm}
        onClose={handleOnClose}>
        <AuthVerifyEmailForm onContinue={handleOnLogin} />
      </Dialog>
    </StyledRoot>
  );
};

export default CoachesLandingPage;
