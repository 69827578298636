import { useState, useRef } from "react";
import { Box, Stack, Typography } from "@mui/material";
import Draggable from "react-draggable";
import ChatWindow from "./ChatWindow";
import CloseIcon from "@mui/icons-material/Close";
import { BsChatFill, BsChat } from "react-icons/bs";
import colors from "style/colors";

const CoachChat = ({ channel, chatUserId, size = 18 }) => {
	const [open, setOpen] = useState(false);
	const nodeRef = useRef(null);


	return (
		<>
			{ open ? (
				<BsChat
					onClick={ () => setOpen(!open) }
					style={ { cursor: "pointer" } }
					size={ size }
					color={ colors.pink }
				/>
			) : (
				<BsChatFill
					onClick={ () => setOpen(!open) }
					style={ { cursor: "pointer" } }
					size={ size }
					color={ colors.pink }
				/>
			) }
			<Box
				sx={ {
					position: "fixed",
					height: "100vh",
					width: "100vw",
					top: 0,
					left: 0,
					zIndex: 10,
					pointerEvents: "none",
					display: open ? 'block' : 'none'
				} }
			>
				<Draggable
					bounds="parent"
					nodeRef={ nodeRef }>
					<Box className="activity-chat-wrapper" ref={ nodeRef }>
						<Stack direction="row" sx={ { justifyContent: "space-between" } }>
							<Typography>Coach Chat</Typography>
							<CloseIcon
								sx={ { cursor: "pointer" } }
								onClick={ (e) => setOpen(false) }
								onTouchStart={ (e) => setOpen(false) }
							/>
						</Stack>
						<ChatWindow channel={ channel } chatUserId={ chatUserId } open={ open }/>
					</Box>
				</Draggable>
			</Box>
		</>
	);
};
export default CoachChat;
