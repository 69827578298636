import {createRef, useCallback, useEffect, useState} from "react";
import Editor from "react-avatar-editor";
import {v4 as uuid} from "uuid";
import axios from "axios";
// @mui
import {Box, Dialog, Slider, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
// icons
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from "@mui/material/IconButton";
// components
import {useResponsive} from "../../../../hooks";
import {Upload} from "../../../../components/upload";
import api from "../../../../api";

// ----------------------------------------------------------------------

const ThumbnailEditorDialog = ({currenFile, open, onClose, onSave, onEdit}) => {
  const isDesktop = useResponsive('up', 'sm');

  const [image, setImage] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [scale, setScale] = useState(1);

  const ref = createRef();

  const size = isDesktop ? 330 : 240;

  const isEdit = !!currenFile?.fileUrl && !!currenFile?.fileName;

  const handleClose = () => {
    onClose();
    setImage(null);
    setScale(1);
  }

  const handleDrop = useCallback((acceptedFiles) => {
    setImage(acceptedFiles[0]);
  }, []);

  const handleSave = () => {
    if (image) {
      setIsLoading(true);
      const fileName = isEdit ? currenFile.fileName : `${uuid()}.png`;
      const canvasScaled = ref.current.getImageScaledToCanvas();
      canvasScaled.toBlob(async (blob) => {
        const {presignedUrl, fileUrl} = await api.coaching.profile.assetUploadUrl(fileName, blob.type, 'tiles');
        await axios.put(presignedUrl, blob);
        isEdit ? onEdit(fileUrl) : onSave(fileUrl);
        handleClose();
        setIsLoading(false);
      });
    }
  }

  useEffect(() => {
    if (currenFile) {
      setImage(currenFile.fileUrl);
    }
  }, [currenFile])


  return (
    <Dialog
      fullWidth
      sx={{
        maxWidth: size + 32,
        m: 'auto'
      }}
      PaperProps={{
        sx: {
          m: 0,
          width: '100%',
        }
      }}
      open={open}
      onClose={handleClose}
    >
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, p: 2}}>
        <Box>
          <Typography variant="body2" color="text.secondary">Drag and drop photo onto editor to upload</Typography>
          <Typography variant="caption" color="text.secondary">Use an image that is at least 500x500px</Typography>
        </Box>
        <Box position="relative" height={size} width={size}>
          {image && !isEdit && (
            <IconButton sx={{
              position: 'absolute',
              top: 6,
              right: 6,
              zIndex: 1,
            }} onClick={() => setImage(null)} color="error" size="small">
              <ClearIcon/>
            </IconButton>
          )}

          <Upload
            multiple
            accept={{'image/*': []}}
            onDrop={handleDrop}
            file={image}
            noClick={!!image}
            noKeyboard={!!image}
            sx={{width: size, height: size}}
          >
            {image && <Editor
              ref={ref}
              image={image}
              width={size}
              height={size}
              border={0}
              borderRadius={size / 2}
              color={[255, 255, 255, 0.2]}
              scale={scale}
              rotate={0}
              crossOrigin="anonymous"
            />}
          </Upload>
        </Box>
        <Slider
          aria-label="Scale"
          value={scale}
          onChange={(event, value) => setScale(value)}
          step={0.1}
          min={0.5}
          max={3}
        />
        <Box textAlign="right">
          <LoadingButton disabled={isLoading || !image} loading={isLoading} variant="text" onClick={handleSave}>
            {isEdit ? 'update' : 'save'}
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  );
}

export default ThumbnailEditorDialog;