import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { UserContext } from "context";
import api from "api";
import UpgradeState from "components/common/UpgradeState";
import Spinner from "components/common/Spinner";
import {
	VictoryChart,
	VictoryLabel,
	VictoryAxis,
	VictoryArea,
	VictoryScatter,
	VictoryZoomContainer,
} from "victory";
import { theme } from "./ProgressFormNew/theme";
import colors from "style/colors";
import { Box, Stack, Typography } from "@mui/material";
import useDimensions from "react-use-dimensions";

const height = 185;

const ProgressFitnessHIstory = ({ swimmerId, isMobile, fitness }) => {

	const [zoomDomain, setZoomDomain] = useState(null);
	const [ref, { height }] = useDimensions();


	useEffect(() => {
		setZoomDomain({ x: [0, fitness.length - 1] });
	}, [fitness]);


	const fitnessData = fitness.map((d, i) => ({
		x: i,
		y: d.fitnessScore,
	}));

	const fatigueData = fitness.map((d, i) => ({
		x: i,
		y: d.fatigueScore,
	}));

	const stressData = fitness.map((d, i) => ({
		x: i,
		y: d.stressScore || 0,
	}));

	return (
		<>
			<Stack
				direction={ isMobile ? 'column' : "row" }
				sx={ isMobile ? { gap: '10px' } : { alignItems: "center", justifyContent: "space-between" } }
				pb={ 1 }
			>
				<Typography fontSize={ 22 }>
					Historical Fitness, Fatigue & Activity Stress
				</Typography>
				<Stack direction="row" spacing={ 2 } sx={ isMobile ? { gap: "30px", justifyContent: 'center' } : {} }>
					<Stack direction="row"
						   sx={ { alignItems: "center" } }
						   spacing={ 1 }>
						<Box
							sx={ {
								height: "10px",
								width: "10px",
								bgcolor: "primary.main",
								borderRadius: "50%",
							} }
						/>
						<Typography sx={ { fontSize: "12px", opacity: 0.6 } }>
							Stress
						</Typography>
					</Stack>
					<Stack direction="row" sx={ { alignItems: "center" } } spacing={ 1 }>
						<Box
							sx={ {
								height: "13px",
								width: "37px",
								bgcolor: "#F1584F",
								opacity: 0.33,
							} }
						/>
						<Typography sx={ { fontSize: "12px", opacity: 0.6 } }>
							Fatigue
						</Typography>
					</Stack>
					<Stack direction="row" sx={ { alignItems: "center" } } spacing={ 1 }>
						<Box
							sx={ {
								height: "13px",
								width: "37px",
								bgcolor: "#02C2F1",
								opacity: 0.33,
							} }
						/>
						<Typography sx={ { fontSize: "12px", opacity: 0.6 } }>
							Fitness
						</Typography>
					</Stack>
				</Stack>
			</Stack>
			<Box sx={ { position: "relative", width: "100%", height: height } }>
				<ChartWrapper ref={ ref } zoomDomain={ zoomDomain }>
					<VictoryArea
						data={ fatigueData }
						style={ { data: { fill: "#F1584F", opacity: 0.3 } } }
					/>
					<VictoryArea
						data={ fitnessData }
						style={ { data: { fill: "#00CDFF", opacity: 0.3 } } }
					/>

					<VictoryAxis
						width={ "100%" }
						height={ height }
						theme={ theme }
						style={ {
							grid: { strokeWidth: 0.5, opacity: 0.3 },
							ticks: { strokeWidth: 0 },
						} }
						tickValues={ fitness.map((d, i) => i) }
						tickCount={ 10 }
						tickFormat={ (x) => {
							const date = fitness[x].date.split("T")[0].split("-");
							return `${ date[1] }/${ date[2] }`;
						} }
					/>
					<VictoryAxis
						label={ "Fitness & Fatigue" }
						axisLabelComponent={ <VictoryLabel dy={ 5 }/> }
						dependentAxis
						width={ "100%" }
						height={ height }
						theme={ theme }
						style={ {
							grid: { strokeWidth: 0.5, opacity: 0.3 },
							ticks: { strokeWidth: 0 },
						} }
					/>
				</ChartWrapper>

				<ChartWrapper data={ fitness } setZoomDomain={ setZoomDomain }>
					<VictoryScatter
						data={ stressData }
						style={ {
							data: {
								fill: ({ datum }) => (!!datum.y ? colors.pink : "transparent"),
							},
						} }
						size={ 2.5 }
					/>

					<VictoryAxis
						orientation="right"
						dependentAxis
						width={ "100%" }
						height={ height }
						theme={ theme }
						style={ {
							grid: { strokeWidth: 0 },
							ticks: { strokeWidth: 0 },
							axisLabel: { angle: 90 },
						} }
						label={ "Stress" }
						axisLabelComponent={ <VictoryLabel dy={ -3 }/> }
					/>
				</ChartWrapper>
			</Box>
		</>
	);
};
export default ProgressFitnessHIstory;

const ChartWrapper = forwardRef(({ children, zoomDomain, setZoomDomain }, ref) => {
	return (
		<Box
			ref={ref}
			sx={ {
				position: "absolute",
				top: 0,
				left: 0,
				width: "100%",
			} }
		>
			<VictoryChart
				theme={ theme }
				height={ height }
				domainPadding={ { y: 10 } }
				padding={ { top: 10, bottom: 25, left: 30, right: 30 } }
				containerComponent={
					<VictoryZoomContainer
						zoomDimension="x"
						zoomDomain={ zoomDomain }
						onZoomDomainChange={ (coord) => {
							if (!!setZoomDomain) {
								setZoomDomain({ x: coord.x });
							}
						} }
					/>
				}
			>
				{ children }
			</VictoryChart>
		</Box>
	);
})
