import { heartRateColors } from '../../style/colors';

export const strokes = [
  { text: 'Free', value: 'strokeCountFreestyle' },
  { text: 'Back', value: 'strokeCountBackstroke' },
  { text: 'Breast', value: 'strokeCountBreaststroke' },
  { text: 'Fly', value: 'strokeCountButterfly' },
  { text: 'Mixed', value: 'strokeCountMixed' }
];

export const CHART_TEXT = {
  CROSSOVER: 'Crossover',
  HAND_ENTRY: 'Hand entry',
  PULL_FORCE: 'Pull force',
  STROKE_PERIOD: 'stroke period',
  HAND_ENTRY_SPLASH: 'Hand entry splash',
  STROKE_COUNT: 'Stroke count'
};

export const parseStrokeCountChartData = (features, stroke, chartText) => {
  let data = features.featuresLap.interpretable.raw[stroke];
  let normal = features.iOSData[stroke].normal;
  let ticks = [];
  let tick = 0;
  while (tick < data.length) {
    ticks.push(tick);
    tick += 10;
  }

  data = data.map((d) => ({ [chartText]: d }));
  return { data, ticks, normal };
};

export const parseStrokePeriodChartData = (features, strokePeriod) => {
  let data = features.featuresLap.interpretable.raw[strokePeriod];
  let normal = features.iOSData[strokePeriod].normal;
  let ticks = [];
  let tick = 0;
  while (tick < data.length) {
    ticks.push(tick);
    tick += 10;
  }

  data = data.map((d) => ({ 'Stroke period': d }));
  return { data, ticks, normal };
};

export const parseChartData = (features, type, chartText) => {
  let data = features.featuresLap.interpretable.raw[type];
  let normal = features.iOSData[type].normal;
  let ticks = [];
  let tick = 0;
  while (tick < data.length) {
    ticks.push(tick);
    tick += 10;
  }

  data = data.map((d) => ({ [chartText]: d }));
  return { data, ticks, normal };
};

export const resolveTextColor = (color) => {
  switch (color) {
    case 1:
      return heartRateColors.orange;
    case 0:
      return heartRateColors.red;
    case 2:
      return heartRateColors.green;
    default:
      return 'white';
  }
};

export const resolveEfficiencyText = (color) => {
  switch (color) {
    case 1:
      return 'Normal for you';
    case 0:
      return 'Less efficient';
    case 2:
      return 'More efficient';
    default:
      return 'Normal for you';
  }
};

export const resolveEfficiencyStrokePeriodText = (color) => {
  switch (color) {
    case 1:
      return 'Normal for you';
    case 0:
      return 'Shorter strokes';
    case 2:
      return 'Longer strokes';
    default:
      return 'Normal for you';
  }
};

export const resolveEfficiencyEntrySplashText = (color) => {
  switch (color) {
    case 1:
      return 'Normal for you';
    case 0:
      return 'Splashier';
    case 2:
      return 'Smoother';
    default:
      return 'Normal for you';
  }
};

export const resolveEfficiencyPullForceText = (color) => {
  switch (color) {
    case 1:
      return 'Normal for you';
    case 0:
      return 'Weaker normal';
    case 2:
      return 'Stronger normal';
    default:
      return 'Normal for you';
  }
};
