import { useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect, useHistory } from 'react-router-dom';

// Pages/components
import TokenManager from './TokenManager';
// import LoggingIn from './pages/LoggingIn';
// import Welcome from "./pages/Welcome";
import Onboard from './pages/Onboard';
// import Activities from "./pages/Activities";
// import Activity from "./pages/Activity";
import Sessions from './pages/Sessions';
import Garmin from './pages/Garmin';
import Workouts from './pages/Workouts';
// import WorkoutCreator from "./pages/WorkoutCreator";
import Progress from './pages/Progress/Progress';
import Profile from './pages/Profile';
// import Coaching from "./pages/Coaching";
// import CoachingSwimmer from "./pages/CoachingSwimmer";
// import CoachingTraining from "./pages/CoachingTraining";
import CoachingWorkouts from './pages/CoachingWorkouts';
import CoachingSets from './pages/CoachingSets';
// import CoachingProfile from "./pages/CoachingProfile";
// import CoachingStore from "pages/CoachingStore/CoachingStorePage";
// import SetCreator from "./components/WorkoutCreator/SetCreator";
// import {default as SetCreatorPageOld} from "./pages/SetCreatorPage";
import Admin from './pages/Admin/index.jsx';
// import AppLayout from "./layouts/AppLayout";
import DashboardLayout from './layouts/dashboard';
// import CoachingLayout from "./layouts/CoachingLayout";
// import ActivitiesNew from "./pages/ActivitiesNew";
import ActivityListPage from './pages/ActivityList';
import ActivityDetailsPage from './pages/ActivityDetails';
import TrainingPage from './pages/Training';
// import Coach from "./pages/Coach";
import CoachDetailsPage from './pages/CoachDetails';
// import Workout from "./pages/Workout";
import WorkoutDetailsPage from './pages/WorkoutDetails';
import { LandingPage, CoachesLandingPage } from './pages/Landing';

import { trackNavigateTo } from './tracking';
import AuthGuard from './auth/AuthGuard';
import RoleBasedGuard from './auth/RoleBasedGuard';
import SwimmerDetailsPage from './pages/coaching/SwimmerDetails';
import SwimmersListPage from './pages/coaching/SwimmersList';
// import SwimmerActivityDetailsPage from "./pages/coaching/SwimmerActivityDetails";
import TrainingToolsPage from './pages/coaching/TrainingTools';
import CoachProfilePage from './pages/coaching/CoachProfile';
import { WorkoutCreatorPage, WorkoutEditorPage } from './pages/coaching/WorkoutCreator';
import { SetCreatorPage, SetEditorPage } from './pages/coaching/SetCreator';
// import CoachStorePage from './pages/coaching/CoachStore';
import WorkoutFullScreen from 'pages/coaching/WorkoutFullScreen';

import PrivacyPolicy from 'pages/PrivacyPolicy';
import CoachTermsAndConditions from 'pages/CoachTermsAndConditions';
import AthleteTermsAndConditions from 'pages/AthleteTermsAndConditions';

import JoinCoachSquad from 'pages/JoinCoachSquad';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};

const Routes = () => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location) => {
      trackNavigateTo(location.pathname);
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <Switch>
      <Route exact path='/' component={LandingPage} />
      <Route exact path='/for-coaches' component={CoachesLandingPage} />
      {/* <Route exact path='/logging-in' component={LoggingIn} /> */}
      <Route exact path='/garmin' component={Garmin} />
      <Route path='/coaching' component={CoachingRouter} />
      <Route path='/privacy-policy' component={PrivacyPolicy} />
      <Route path='/coach-terms-and-conditions' component={CoachTermsAndConditions} />
      <Route path='/athlete-terms-and-conditions' component={AthleteTermsAndConditions} />
      <Route path='/join-squad/:inviteCode' component={JoinCoachSquad} />
      <Route component={ProtectedRouter} />
    </Switch>
  );
};

const ProtectedRouter = () => {
  return (
    <AuthGuard>
      <TokenManager>
        <DashboardLayout>
          <Switch>
            <Route path='/admin' component={Admin} />
            <Route path='/activity/:activityId' component={ActivityDetailsPage} />
            <Route path='/activity' component={ActivityListPage} />
            <Route path='/sessions' component={Sessions} />
            <Route path='/workouts' component={Workouts} />
            <Route path='/progress' component={Progress} />
            <Route path='/profile' component={Profile} />
            <Route path='/onboard' component={Onboard} />
            <Route path='/training' component={TrainingPage} />
            <Route path='/coach/:coachId' component={CoachDetailsPage} />
            <Route path='/workout/:workoutId' component={WorkoutDetailsPage} />

            <Redirect to={'/progress'} />
          </Switch>
        </DashboardLayout>
      </TokenManager>
    </AuthGuard>
  );
};

const CoachingRouter = () => {
  return (
    <AuthGuard>
      <TokenManager>
        <RoleBasedGuard role='isCoach'>
          <Switch>
            <Route
              path={['/coaching/workouts/fullscreen/:workoutId']}
              component={WorkoutFullScreen}
            />
            <Route>
              <DashboardLayout isCoachLayout>
                <Switch>
                  <Route path={['/coaching/training']} component={TrainingToolsPage} />
                  <Route path={['/coaching/workouts/create']} component={WorkoutCreatorPage} />

                  <Route
                    path={['/coaching/workouts/edit/:workoutId']}
                    component={WorkoutEditorPage}
                  />
                  <Route path={['/coaching/sets/create']} component={SetCreatorPage} />
                  <Route path={['/coaching/sets/edit/:setId']} component={SetEditorPage} />
                  <Route path={['/coaching/profile']} component={CoachProfilePage} />
                  {/* <Route path={['/coaching/store']} component={CoachStorePage} /> */}
                  <Route path={['/coaching/workouts']} component={CoachingWorkouts} />
                  <Route path={['/coaching/sets']} component={CoachingSets} />
                  {/* <Route
                path="/coaching/swimmers/:swimmerId/activity/:activityId"
                component={SwimmerActivityDetailsPage}
              /> */}
                  <Route
                    path='/coaching/swimmers/:swimmerId/activity/:activityId'
                    component={ActivityDetailsPage}
                  />
                  <Route path='/coaching/swimmers/:swimmerId' component={SwimmerDetailsPage} />
                  <Route path={['/coaching/swimmers']} component={SwimmersListPage} />
                  <Redirect to={'/progress'} />
                </Switch>
              </DashboardLayout>
            </Route>
          </Switch>
        </RoleBasedGuard>
      </TokenManager>
    </AuthGuard>
  );
};

export default Router;
