import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {Stack, Typography, Box, InputLabel} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import FormProvider, { RHFTextField } from '../../components/hook-form';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {PASSWORD_PATTERNS} from "./utils";

// ----------------------------------------------------------------------

const ValidationMessage = ({match, str, message}) => {
  const color = str.match(match) ? 'success.main' : 'text.disabled';
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <CheckCircleOutlineIcon sx={{fontSize: 14, color}} />
      <Typography sx={{fontSize: 12}} variant="body2" color={color}>{message}</Typography>
    </Stack>
  );
};

const AuthNewPasswordForm = () => {
  const emailRecovery = typeof window !== 'undefined' ? sessionStorage.getItem('email-recovery') : '';

  const NewPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must contain at least 8 characters')
      .matches(PASSWORD_PATTERNS.lowerCase, 'Password must contain a lower case letter')
      .matches(PASSWORD_PATTERNS.upperCase, 'Password must contain an upper case letter')
      .matches(PASSWORD_PATTERNS.specialCharacter, 'Password must contain a special character')
      .matches(PASSWORD_PATTERNS.number, 'Password must contain a number')
      .matches(PASSWORD_PATTERNS.space, 'Password cannot contain spaces')
  });

  const defaultValues = {
    email: emailRecovery || '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(NewPasswordSchema),
    defaultValues,
  });

  const {
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const {password} = watch();

  const onSubmit = async (data) => {
    try {
      console.log('New password:', data)

      // sessionStorage.removeItem('email-recovery');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{px: 2, py: 3}}>
        <Stack gap={3}>
          <Typography variant="body1">Create new password</Typography>

          <Typography variant="body1">Your new password must be different from the previously used passwords</Typography>

          <Box>
            <InputLabel sx={{mb:.5}}>Enter New Password</InputLabel>
            <RHFTextField
              name="password"
              placeholder="Password"
              type="password"
            />
          </Box>

          {password && (
            <Stack gap={.5}>
              <ValidationMessage match={PASSWORD_PATTERNS.lowerCase} str={password} message="Password must contain a lower case letter" />
              <ValidationMessage match={PASSWORD_PATTERNS.upperCase} str={password} message="Password must contain an upper case letter" />
              <ValidationMessage match={PASSWORD_PATTERNS.minLength} str={password} message="Password must contain at least 8 characters" />
              <ValidationMessage match={PASSWORD_PATTERNS.specialCharacter} str={password} message="Password must contain a special character" />
              <ValidationMessage match={PASSWORD_PATTERNS.number} str={password} message="Password must contain a number" />
            </Stack>
          )}

          <LoadingButton
            sx={{textTransform: 'capitalize'}}
            size="large"
            fullWidth
            type="submit"
            variant="gradient"
            loading={isSubmitting}
          >
            Reset my password
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  );
}

export default AuthNewPasswordForm;