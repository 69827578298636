import {useState} from "react";
// @mui
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
} from '@mui/material';
import Typography from "@mui/material/Typography";
import MenuPopover from "../menu-popover";

// ----------------------------------------------------------------------

const ScrollWrap = ({children}) => (
  <Box sx={{overflowY: 'auto', maxHeight: 'calc(90vh - 46px)'}}>
    {children}
  </Box>
);

const FiltersPopover = ({ filterOptions, onApply, columns}) => {
  const [openPopover, setOpenPopover] = useState(null);
  const [selected, setSelected] = useState([]);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleOnSelect = (e) => {
    setSelected(prevState => prevState.includes(e.target.value)
      ? prevState.filter(item => item !== e.target.value)
      : [...prevState, e.target.value]
    );
  }

  const handleOnReset = () => {
    onApply([]);
    setSelected([]);
    handleClosePopover();
  };

  const handleOnApply = () => {
    onApply(selected);
    handleClosePopover();
  };

  const renderOption = (option) => (
    <FormControlLabel
      key={option.value}
      value={option.value}
      control={<Checkbox size="small" />}
      label={<Typography variant="body1">{option.label}</Typography>}
      sx={{ textTransform: 'capitalize' }}
      checked={selected.includes(option.value)}
    />
  );


  return (
    <>
      <Button sx={{textTransform: 'Capitalize'}} variant="text" onClick={handleOpenPopover}>{selected.length > 0 && selected.length + ' '}Filters</Button>

      <MenuPopover disabledArrow open={openPopover} onClose={handleClosePopover} sx={{ minWidth: 200, px: 0, py: .5 }}>
        <ScrollWrap>
          <FormGroup
            sx={{
              py: 1,
              px: 2,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
            onChange={handleOnSelect}
          >
            {filterOptions.map((option, i) => !option.children ? renderOption(option) : (
              <Box key={i} sx={{mb: filterOptions.length -1 !== i ? 2 : 0}}>
                <Typography variant="body1" color="text.secondary">{option.title}</Typography>
                <Box display="grid" gridTemplateColumns={{ md: `repeat(${columns || 2}, 1fr)` }}>
                  {option.children.map(childrenOption => renderOption(childrenOption))}
                </Box>
                <Typography variant="caption" color="text.secondary">{option.description}</Typography>
              </Box>
            ))}
          </FormGroup>
        </ScrollWrap>

        <Stack padding={1} direction="row" spacing={1} justifyContent="space-between">
          <Button onClick={handleOnReset} size="small" variant="outlined">Reset</Button>
          <Button onClick={handleOnApply} size="small" variant="contained">Apply</Button>
        </Stack>
      </MenuPopover>
    </>
  );
}

export default FiltersPopover;
