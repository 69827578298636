import {coachApi} from "../axios";

export const list = async (params) => {
  const {data} = await coachApi.get('/coaching/workouts', {params});
  return data;
};

export const get = async (id) => {
  const {data} = await coachApi.get(`/coaching/workouts/${id}`);
  return data;
};

export const create = async (body) => {
  const {data} = await coachApi.post(`/coaching/workouts`, body);
  return data;
};

export const update = async (id, body) => {
  const {data} = await coachApi.patch(`/coaching/workouts/${id}`, body);
  return data;
};

export const remove = async (id) => {
  const {data} = await coachApi.delete(`/coaching/workouts/${id}`);
  return data;
};

export const getWorkoutMenuItems = async () => {
  const {data} = await coachApi.get("/coaching/workout-options");
  return data;
};
