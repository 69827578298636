import GarminConnectButton from "../Garmin/GarminConnectButton.jsx";
import api from "api";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const ProfileGarminForm = ({ open, handleClose, user, refetch }) => {
  const disconnect = async () => {
    try {
      await api.garmin.disconnectAccount();
      refetch();
      handleClose();
    } catch (err) {
      console.log(err, err.response, err.message, err.data);
      alert("There was an error disconnecting Garmin account.");
    }
  };

  return (
    <>
      <DialogTitle className="title">
        Your Garmin account is{" "}
        {user.garminConnected ? "connected." : "not connected."}
      </DialogTitle>
      <DialogContent sx={{ minWidth: { md: 450 } }}>
        {user.garminConnected ? (
          <button className="button" onClick={disconnect}>
            Disconnect Garmin Account
          </button>
        ) : (
          <GarminConnectButton />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </>
  );
};

// ProfileGarminForm.propTypes = {
//   : PropTypes.
// };

export default ProfileGarminForm;
