// External Modules
import { useState, useContext } from 'react';
import { Button, Typography, Box, Stack } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// Internal Modules
import { CoachContext } from 'context';
import { useAlertContext } from 'components/alert/AlertContext';

// Components
import Modal from 'components/Modal';

const SwimmersInviteButton = () => {
  const { showAlert } = useAlertContext();
  const { coach } = useContext(CoachContext);
  const [open, setOpen] = useState(false);

  const urlToCopy = `${window.location.origin}/join-squad/${coach.inviteCode}`;

  return (
    <>
      <Button variant='contained' onClick={() => setOpen(true)}>
        Invite Swimmers
      </Button>
      <Modal open={open} handleClose={() => setOpen(false)}>
        <Box p={3} maxWidth={'500px'}>
          <Typography variant='h6' pb={2}>
            Invite Swimmers
          </Typography>
          <Typography variant='body2'>
            Invite swimmers to your squad by sending them the link below. You will be able to see
            their activity data, chat with them and give them workouts. They will also get access to
            full analytics.
          </Typography>
          {coach.inviteCode ? (
            <Box>
              <Typography
                sx={{ fontSize: '0.8rem', opacity: 0.6, textAlign: 'center', pb: 1 }}
                pt={3}>
                Click the link to copy it to your clipboard.
              </Typography>
              <Stack
                direction='row'
                alignContent='center'
                justifyContent='center'
                spacing={1}
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  navigator.clipboard.writeText(urlToCopy);
                  showAlert('Link copied to clipboard', { severity: 'success' });
                }}>
                <ContentCopyIcon fontSize='small' color='primary' />

                <Typography pb={3} color='primary'>
                  {urlToCopy}
                </Typography>
              </Stack>
            </Box>
          ) : (
            <Typography variant='body2' color='error'>
              Oops, there was an error generating your invite link. Reach out and we will fix it.
            </Typography>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default SwimmersInviteButton;
