import { useState } from "react";
import {
	Box,
	Typography,
	Table,
	TableBody,
	TableContainer,
	TableCell,
	TableHead,
	TableRow,
	Stack,
	Button,
	Card,
} from "@mui/material";
import { useQuery, useMutation } from "react-query";
import api from "api";
import { LoadingButton } from "@mui/lab";
import ConfirmDialog from "components/ConfirmDialog";
import Modal from "components/Modal";
import Spinner from "components/common/Spinner";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import WarningIcon from "@mui/icons-material/Warning";

const CoachRequestAdmin = () => {
	// Queries
	const { data, isLoading, refetch } = useQuery(
		["adminCoachRequests"],
		api.admin.coaches.coachRequests.list,
		{
			onSuccess: (data) => {
				console.log("COACH REQUESTS", data);
			},
		}
	);

	if (isLoading) return <Spinner />;

	if (!data?.length) {
		return <Typography variant="h4">No coach requests</Typography>;
	}

	return (
		<>
			<Stack direction="row" sx={{ justifyContent: "space-between" }} pb={3}>
				<Typography variant="h4">Coach Requests</Typography>
			</Stack>

			<Stack>
				{data.map((coachRequest) => (
					<CoachRequest coachRequest={coachRequest} refetch={refetch} />
				))}
			</Stack>
		</>
	);
};

export default CoachRequestAdmin;

const CoachRequest = ({ coachRequest, refetch }) => {
	const [deleteConfirm, setDeleteConfirm] = useState(false);
	const [acceptConfirm, setAcceptConfirm] = useState(false);

	// Mutations
	const { mutate: deleteMutation, isLoading: deleteIsLoading } = useMutation(
		api.admin.coaches.coachRequests.remove,
		{
			onSuccess: () => {
				setDeleteConfirm(false);
				refetch();
			},
		}
	);

	const { mutate: acceptMutation, isLoading: acceptIsLoading } = useMutation(
		api.admin.coaches.coachRequests.approve,
		{
			onSuccess: () => {
				setAcceptConfirm(false);
				refetch();
			},
		}
	);

	// Handlers
	const handleDeleteRequest = () => {
		deleteMutation(coachRequest.email);
	};

	const handleAcceptRequest = () => {
		console.log("ACCEPT", coachRequest);
		acceptMutation(coachRequest.email);
	};

	const renderIcon = () => {
		if (!coachRequest.hasSimmaAccount) {
			return (
				<>
					<HighlightOffIcon sx={{ color: "red" }} fontSize="10" />
					<Typography pl={1} variant="body2">
						No Simma account
					</Typography>
				</>
			);
		} else if (!!coachRequest.isCoach) {
			return (
				<>
					<WarningIcon sx={{ color: "yellow" }} fontSize="10" />
					<Typography pl={1} variant="body2">
						Is already a coach
					</Typography>
				</>
			);
		} else {
			return (
				<>
					<CheckCircleOutlineIcon sx={{ color: "green" }} fontSize="10" />
					<Typography pl={1} variant="body2">
						Has Simma account
					</Typography>
				</>
			);
		}
	};

	return (
		<>
			<Card variant="outlined">
				<Stack key={coachRequest.email} sx={{ mb: 2, p: 2 }} direction={"row"}>
					<Stack sx={{ flex: 1 }}>
						<Typography variant="h6">{coachRequest.name}</Typography>
						<Typography variant="body2">{coachRequest.email}</Typography>
						<Typography variant="body2">
							{new Date(coachRequest.date).toLocaleDateString()}
						</Typography>

						<Typography variant="body1" pt={1}>
							{coachRequest.message}
						</Typography>
					</Stack>
					<Stack>
						<Stack
							direction="row"
							sx={{ alignContent: "center", alignItems: "center" }}
						>
							{renderIcon()}
						</Stack>
						<Stack direction={"row"} sx={{ pt: 2 }}>
							<Button
								variant="contained"
								color="primary"
								sx={{ mr: 1 }}
								disabled={
									!coachRequest.hasSimmaAccount || !!coachRequest.isCoach
								}
								onClick={() => setAcceptConfirm(true)}
							>
								Accept
							</Button>
							<Button
								variant="contained"
								color="error"
								onClick={() => setDeleteConfirm(true)}
							>
								Delete
							</Button>
						</Stack>
					</Stack>
				</Stack>
			</Card>
			<ConfirmDialog
				open={!!deleteConfirm}
				handleClose={() => setDeleteConfirm(false)}
				title="Delete this request?"
				text="Are you sure you want to delete this request? The coach will not be notified and the request will be deleted."
				handleConfirm={handleDeleteRequest}
				confirmText="Delete Request"
				confirmColor="error"
				confirmIsLoading={deleteIsLoading}
			/>
			<ConfirmDialog
				open={!!acceptConfirm}
				handleClose={() => setAcceptConfirm(false)}
				title="Accept this request?"
				text="This user will be made into a coach and notified."
				handleConfirm={handleAcceptRequest}
				confirmText="Accept Request"
				confirmColor="success"
				confirmIsLoading={acceptIsLoading}
			/>
		</>
	);
};
