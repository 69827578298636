import {useState} from "react";
// @mui
import {TextField} from "@mui/material";
// utils
import {getSecondsFromHHMMSS, secondsToHMS} from "../../util";

// ----------------------------------------------------------------------

const TimeField = ({onChange, onBlur, value, ...other}) => {
  const [tempValue, setTempValue] = useState(undefined);

  const handleOnChange = (e) => {
    const value = e.target.value;
    const formattedValue = formatValue(value);
    const seconds = getSeconds(value);
    onChange(seconds)
    setTempValue(formattedValue);
  }


  return (
    <TextField
      fullWidth
      value={tempValue ?? formatDisplayValue(value)}
      onChange={handleOnChange}
      onBlur={(e) => {
        setTempValue(undefined);
        onBlur && onBlur(e);
      }}
      {...other}
    />
  );
};

const getSeconds = (value) => {
  const isNumber = !isNaN(Number(value));
  const currValue = isNumber ? Number(value) : getSecondsFromHHMMSS(value);
  return Math.max(0, currValue);
}

const formatDisplayValue = (value) => {
  if (!value) {
    return value;
  }
  return secondsToHMS(value);
}

const formatValue = (value) => {
  return value.replace(/[^\d.:]/g, '')
}

export default TimeField;