import React from 'react';
// import PropTypes from 'prop-types';

const Metric = ({value, title, align = 'left', style = {} }) => {
  style.textAlign = align

  return (
    <div className="metric" style={style}>
      <div className="metric__value">
        {value}
      </div>
      <div className="metric__title">
        {title}
      </div>
    </div>
  );
}

// Metric.propTypes = {
//   : PropTypes.
// };

export default Metric;
