const msPerMinute = 60 * 1000;
const msPerHour = msPerMinute * 60;
const msPerDay = msPerHour * 24;
const msPerWeek = msPerDay * 7;
const msPerMonth = msPerDay * 30;
const msPerYear = msPerDay * 365;

export const relativeTime = (date) => {
  const current = Date.now();
  const previous = new Date(date).getTime();
  const elapsed = current - previous;
  let options;

  if (elapsed < msPerDay) {
    return 'Today';
  }

  if (elapsed < msPerDay * 2) {
    return 'Yesterday';
  }

  if (elapsed < msPerWeek) {
    options = { weekday: 'long' };
    return new Date(date).toLocaleDateString(undefined, options);
  }

  if (elapsed < msPerYear) {
    options = { month: 'short', day: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
  }

  return timeStringToReadable(date);
};

// const parseDistanceDisplay = (distance, unit = "m", system = "metric") => {
//   if (distance < 10000) {
//     return distance + unit;
//   } else if (system === "imperial") {
//     return `${(distance / 1760).toFixed(1)} miles`;
//   } else {
//     return `${(distance / 1000).toFixed(1)}km`;
//   }
// };

export const parseMessageTime = (timetoken) => {
  const date = new Date(Number(timetoken) / 10000).toISOString();
  const options = { hour: 'numeric', minute: 'numeric' };
  return relativeTime(date) + ' ' + new Date(date).toLocaleTimeString(undefined, options);
};

export const timeDifference = (date, today) => {
  const current = Date.now();
  const previous = new Date(date).getTime();

  let elapsed = current - previous;

  if (elapsed < 0) {
    elapsed = elapsed * -1;
    let val;

    if (today && elapsed < msPerDay) {
      return 'Today';
    }

    if (elapsed < msPerMinute) {
      val = Math.round(elapsed / 1000);
      return 'in ' + val + ` second${val > 1 ? 's' : ''}`;
    } else if (elapsed < msPerHour) {
      val = Math.round(elapsed / msPerMinute);
      return 'in ' + val + ` minute${val > 1 ? 's' : ''}`;
    } else if (elapsed < msPerDay) {
      val = Math.round(elapsed / msPerHour);
      return 'in ' + val + ` hour${val > 1 ? 's' : ''}`;
    } else if (elapsed < msPerMonth) {
      val = Math.round(elapsed / msPerDay);
      return 'in ' + val + ` day${val > 1 ? 's' : ''}`;
    } else if (elapsed < msPerYear) {
      val = Math.round(elapsed / msPerMonth);
      return 'in ' + val + ` month${val > 1 ? 's' : ''}`;
    } else {
      val = Math.round(elapsed / msPerYear);
      return 'in ' + val + ` year${val > 1 ? 's' : ''}`;
    }
  }

  if (today && elapsed < msPerDay) {
    return 'Today';
  }

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + ' seconds ago';
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + ' minutes ago';
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + ' hours ago';
  } else if (elapsed < msPerMonth) {
    return Math.round(elapsed / msPerDay) + ' days ago';
  } else if (elapsed < msPerYear) {
    return Math.round(elapsed / msPerMonth) + ' months ago';
  } else {
    return Math.round(elapsed / msPerYear) + ' years ago';
  }
};

export const timeStringToReadable = (dateString) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

export const secondsToDisplayTime = (seconds, withDecimal = true) => {
  const x = withDecimal ? 3 : 0;
  if (seconds < 3600) {
    return new Date(seconds * 1000).toISOString().substr(14, 5 + x);
  } else {
    return new Date(seconds * 1000).toISOString().substr(11, 8 + x);
  }
};

export const secondsToHoursMinutesMetricDisplay = (seconds) => {
  if (!seconds) return '0m';
  // First, convert the total seconds into whole minutes and whole hours
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  // Adjust minutes to remain after extracting hours
  minutes = minutes % 60;

  // Build the formatted string
  let formattedTime = '';
  if (hours > 0) {
    formattedTime += `${hours}h `;
  }
  if (minutes > 0 || hours === 0) {
    formattedTime += `${minutes}m`;
  }

  return formattedTime;
};

export const secondsToHMS = (secs, withDecimal) => {
  const sec_num = parseInt(secs, 10);
  if (isNaN(sec_num)) return '-';
  const hours = Math.floor(sec_num / 3600);
  const minutes = Math.floor(sec_num / 60) % 60;
  let seconds = sec_num % 60;

  let formatted = [hours, minutes, seconds]
    .map((v) => (v < 10 ? '0' + v : v))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':')
    .replace(/^0/, '');

  if (!!withDecimal) {
    const ms = secs % 1;
    formatted += `${ms.toFixed(2)}`.replace(/^0/, '');
  }

  return formatted;
};

export const getSecondsFromHHMMSS = (value) => {
  const [str1, str2, str3] = value.split(':');

  const val1 = Number(str1);
  const val2 = Number(str2);
  const val3 = Number(str3);

  if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
    return val1;
  }

  if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
    return val1 * 60 + val2;
  }

  if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
    return val1 * 60 * 60 + val2 * 60 + val3;
  }

  return 0;
};

export const camelCaseToWords = (text) => {
  if (!text || typeof text !== 'string') return '';
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const getAge = (date) => {
  let today = new Date();
  let birthDate = typeof date.getMonth === 'function' ? date : new Date(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const capitalize = (string) => {
  if (!string || typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const throttle = (func, limit = 200) => {
  let lastFunc;
  let lastRan;
  return function () {
    const context = this;
    const args = arguments;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(
        function () {
          if (Date.now() - lastRan >= limit) {
            func.apply(context, args);
            lastRan = Date.now();
          }
        },
        limit - (Date.now() - lastRan)
      );
    }
  };
};

export const getUserCharacters = (user) =>
  user &&
  user
    .split(/[.\-_\s]/)
    .map((s) => s.charAt(0).toUpperCase())
    .join('');

export function bgGradient(props) {
  const direction = props?.direction || 'to bottom';
  const startColor = props?.startColor;
  const endColor = props?.endColor;
  const imgUrl = props?.imgUrl;
  const color = props?.color;

  if (imgUrl) {
    return {
      background: `linear-gradient(${direction}, ${startColor || color}, ${
        endColor || color
      }), url(${imgUrl})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center'
    };
  }

  return {
    background: `linear-gradient(${direction}, ${startColor || color}, ${endColor || color})`
  };
}

export function pxToRem(value) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({ sm, md, lg }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm)
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md)
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg)
    }
  };
}

export const numberWithLeadingZeros = (num, totalLength) => String(num).padStart(totalLength, '0');

export const getPercent = (num, max) => Math.round((100 * num) / max);

export const arraysHaveSameElements = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  let sortedArr1 = [...arr1].sort();
  let sortedArr2 = [...arr2].sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
};
