import {Box, Button, Stack, styled} from "@mui/material";
import {alpha} from "@mui/material/styles";
import {selectorMetricOptions} from "./utils";


const SelectorButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'inactive' && prop !== 'textColor',
})(({theme, textColor, inactive}) => ({
  ...(textColor && {color: textColor}),
  textTransform: 'capitalize',
  backgroundColor: theme.palette.background.secondary,
  ...(inactive && {
    color: alpha(theme.palette.common.white, .3),
    backgroundColor: alpha(theme.palette.common.white, .12),
  }),
  '&:hover': {
    backgroundColor: theme.palette.background.secondaryLight,
  }
}));

const MetricsToolbar = ({
  metrics,
  selected,
  onSelect,
  isStroke,
  setIsStroke,
  hasStrokeData,
  disableMetrics,
}) => {
  return (
    <Stack direction={{ md: "row", xs: "column" }} justifyContent="space-between">
      <Box>
        {!disableMetrics && (
          <Stack direction="row" gap={.5}>
            {metrics.map((key) => (
              <SelectorButton
                inactive={!selected.includes(key)}
                key={key}
                variant="contained"
                size="small"
                textColor={selectorMetricOptions[key].color}
                onClick={() => onSelect(key)}
              >
                {selectorMetricOptions[key].label}
              </SelectorButton>
            ))}
          </Stack>
        )}
      </Box>
      <Box>
        {hasStrokeData && (
          <Stack direction="row" spacing={1.5} mb={1}>
            <Button
              variant="text"
              color={isStroke ? 'primary' : 'secondary'}
              onClick={() => setIsStroke(true)}
            >
              Strokes
            </Button>
            <Button
              variant="text"
              color={isStroke ? 'secondary' : 'primary'}
              onClick={() => setIsStroke(false)}
            >
              Laps
            </Button>
          </Stack>
        )}
      </Box>
    </Stack>
  );
};

export default MetricsToolbar;
