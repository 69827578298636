export const EFFORT_OPTIONS = [
  {value: "a", label: "Easy"},
  {value: "b", label: "Endurance"},
  {value: "c", label: "Tempo"},
  {value: "d", label: "Aerobic"},
  {value: "e", label: "Sprint"},
];

export const FLAVOR_OPTIONS = [
  {value: "warmup", label: "Warm up"},
  {value: "main", label: "Main set"},
  {value: "cooldown", label: "Cool down"},
];

export const SEGMENT_COUNT_OPTION = [
  {value: 1, label: "1"},
  {value: 2, label: "2"},
  {value: 3, label: "3"},
  {value: 4, label: "4"},
];

export const SPLIT_SEGMENT_OPTIONS = [
  {value: 2, label: "2"},
  {value: 3, label: "3"},
  {value: 4, label: "4"},
];

export const workoutEquipments = [
  "fins",
  "paddles",
  "pullbuoy",
  "snorkel",
  "kickboard",
  "bands",
];

