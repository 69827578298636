import { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { BrowserView, MobileView } from "react-device-detect";
import { Link } from "react-router-dom";
// import { deleteWorkout } from "../api";
import api from "api";
// import PropTypes from 'prop-types';

const Workouts = () => {
  const [list, setList] = useState([]);
  // console.log(list);
  useEffect(() => {
    const fetchWorkouts = async () => {
      const workouts = await api.workouts.list();
      setList(workouts);
    };
    fetchWorkouts();
  }, []);

  const handleDeleteWorkout = async (id) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this workout?"
    );
    if (confirm) {
      const response = await api.coaching.workouts.remove(id);
      const newWorkouts = list.filter((item) => item.id !== id);
      setList(newWorkouts);
    }
  };

  return (
    <Layout>
      <MobileView>
        <h2>No mobile workout view yet</h2>
      </MobileView>
      <BrowserView>
        <div className="workouts-browser-wrapper">
          <div className="browser-container">
            <div>
              <Link to="/workouts/create" className="button">
                Create a workout
              </Link>
            </div>
            <div className="workout-list">
              {list.map((item) => (
                <div key={item.id} className="workout-list-item">
                  <div>
                    <img
                      className="workout-list-item__image"
                      src={item.imageThumbnail}
                      alt="1"
                    />
                  </div>
                  <div>
                    <h2>
                      {item.title} <span style={{ opacity: ".4" }}>|</span>{" "}
                      {item.distance}m <span style={{ opacity: ".4" }}>|</span>{" "}
                      {item.durationDisplay}
                    </h2>
                    <p>{item.description}</p>
                    <Link to={`/workouts/create/${item.id}`}>edit</Link>
                    <button onClick={() => handleDeleteWorkout(item.id)}>
                      delete
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </BrowserView>
    </Layout>
  );
};

// Workouts.propTypes = {
//   : PropTypes.
// };

export default Workouts;
