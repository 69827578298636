export const colors = {
  pink: "#FF5082",
  pinkTint: "rgba(255, 80, 130, 0.5)",
  green: "#1ADEA3",
  greenDark: "#4CAF50",
  greenTint: "rgba(26, 222, 163, 0.5)",
  blue: "#00CDFF",
  blueTint: "rgba(0, 205, 255, 0.2)",
  red: "#F1584F",
  redTint: "rgba(241, 88, 79, 0.2)",
  yellow: "#E4F14F",
  yellowTint: "rgba(228, 241, 79, 0.2)",
  orange: "#F38D2E",
  backgroundPrimary: "#121212",
  backgroundPrimaryTransparent: "rgba(10, 29, 38, 0.85)",
  backgroundSecondary: "#1B2D35",
  backgroundSecondaryTransparent: "rgba(27, 45, 53, 0.86)",
  backgroundTertiary: "#2C3D44",
  backgroundTertiaryTransparent: "rgba(44, 61, 68, 0.85)",
  backgroundQuarternary: "#3D4C54",
  backgroundQuarternaryTransparent: "rgba(61, 76, 84, 0.85)",
  scrim: "rgba(10, 29, 38, 0.6)",
  separator: "rgba(255, 255, 255, 0.14)",
  label: "rgba(245, 245, 250, 0.72)",
};

export const loginFormColors = {
  backgroundDialog: '#0A1D26',
  buttonGradientStart: '#FF506D',
  buttonGradientEnd: '#FF50D9',
};

export const heartRateColors = {
  blue: "#00CDFF",
  green: "#1ADEA3",
  yellow: "#E4F14F",
  orange: "#F38D2E",
  red: "#F1584F",
};

export const chartColors = heartRateColors;

export default colors;
