import {useEffect, useRef, useState} from "react";
// @mui
import {Box} from '@mui/material';

// ----------------------------------------------------------------------

const StickyWrap = ({children, sx, ...other}) => {
  const ref = useRef(null);
  const [wrapWidth, setWrapWidth] = useState(null);

  useEffect(() => {
    const element = ref?.current;

    if (!element) return;

    const resizeObserver = new ResizeObserver(() => {
      setWrapWidth(element?.offsetWidth);
    });

    resizeObserver.observe(element);

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref]);

  return (
    <Box
      ref={ref}
      sx={{
        width: '100%',
        ...(ref.current && {
          height: ref.current?.offsetHeight
        })
      }}
      {...other}
    >
      <Box sx={(theme) => ({
        zIndex: 1,
        width: '100%',
        backgroundColor: theme.palette.background.default,
        ...(ref.current && {
          position: 'fixed',
          width: wrapWidth ?? ref.current?.offsetWidth,
          height: ref.current?.offsetHeight
        }),
        ...sx
      })}>{children}</Box>
    </Box>
  );
};

export default StickyWrap;