import { useState, useEffect } from "react";
import {
  Button,
  Popover,
  Typography,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Stack,
  Grid,
  Checkbox,
} from "@mui/material";
import { renderIcon } from "components/workout/EquipmentList";

const equipmentItems = [
  {
    value: "bands",
    label: "Band",
  },
  {
    value: "baton",
    label: "Baton",
  },
  {
    value: "pullbuoy",
    label: "Buoy",
  },
  {
    value: "fins",
    label: "Fins",
  },
  {
    value: "kickboard",
    label: "Kickboard",
  },
  {
    value: "paddles",
    label: "Paddles",
  },
  {
    value: "snorkel",
    label: "Snorkel",
  },
  {
    value: "techtoc",
    label: "Techtoc",
  },
];

const GridContainer = ({ children }) => {
  return (
    <Grid container spacing={0} width="100%">
      {children}
    </Grid>
  );
};

const GridItem = ({ children }) => {
  return (
    <Grid item xs={6} sm={4}>
      {children}
    </Grid>
  );
};

const Section = ({ children }) => {
  return (
    <Box mb={3} sx={{ "&:last-child": { margin: 0 } }}>
      {children}
    </Box>
  );
};

const WorkoutFilters = ({ workouts, setWorkouts, tags }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [environment, setEnvironment] = useState("");
  const [level, setLevel] = useState("");
  const [distance, setDistance] = useState("");
  const [equipmentSelect, setEquipmentSelect] = useState("");
  const [equipment, setEquipment] = useState([]);
  const [tag, setTag] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  const filterResults = () => {
    let results = [...workouts];
    if (!!environment) {
      results = results.filter((result) => result.environment === environment);
    }

    if (!!level) {
      results = results.filter((result) =>
        result.levels.includes(Number(level))
      );
    }

    if (!!distance) {
      if (distance === "<500") {
        results = results.filter((result) => result.distance < 500);
      }
      if (distance === "500-1000") {
        results = results.filter(
          (result) => result.distance >= 500 && result.distance <= 1000
        );
      }
      if (distance === "1000-2000") {
        results = results.filter(
          (result) => result.distance >= 1000 && result.distance <= 2000
        );
      }
      if (distance === "2000-3000") {
        results = results.filter(
          (result) => result.distance >= 2000 && result.distance <= 3000
        );
      }
      if (distance === ">3000") {
        results = results.filter((result) => result.distance > 3000);
      }
    }
    if (!!equipmentSelect) {
      if (equipmentSelect === "none") {
        results = results.filter((result) => result.equipment.length === 0);
      } else if (equipmentSelect === "some") {
        equipment.forEach((item) => {
          results = results.filter((result) => result.equipment.includes(item));
        });
      }
    }

    if (!!tag) {
      results = results.filter((result) => result.tags.includes(tag));
    }

    setWorkouts(results);
  };

  useEffect(() => {
    if (!!workouts) filterResults();
  }, [environment, distance, equipment, tag, level, equipmentSelect, workouts]);

  return (
    <div>
      <Button aria-describedby={id} onClick={handleClick}>
        Filter
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack p={3} sx={{ maxWidth: "95vw", width: "500px" }}>
          <Section>
            <FormControl sx={{ width: "100%" }}>
              <FormLabel>Environment:</FormLabel>
              <RadioGroup
                row
                value={environment}
                onChange={(event) => setEnvironment(event.target.value)}
              >
                <GridContainer>
                  <GridItem>
                    <FormControlLabel
                      value=""
                      control={<Radio />}
                      label="Any"
                    />
                  </GridItem>
                  <GridItem>
                    <FormControlLabel
                      value="pool"
                      control={<Radio />}
                      label="Pool"
                    />
                  </GridItem>
                  <GridItem>
                    <FormControlLabel
                      value="open"
                      control={<Radio />}
                      label="Open Water"
                    />
                  </GridItem>
                </GridContainer>
              </RadioGroup>
            </FormControl>
          </Section>

          <Section>
            <FormControl sx={{ width: "100%" }}>
              <FormLabel>Level:</FormLabel>
              <RadioGroup
                row
                value={level}
                onChange={(event) => setLevel(event.target.value)}
              >
                <GridContainer>
                  <GridItem>
                    <FormControlLabel
                      value=""
                      control={<Radio />}
                      label="Any"
                    />
                  </GridItem>
                  <GridItem>
                    <FormControlLabel
                      value={2}
                      control={<Radio />}
                      label="Beginner"
                    />
                  </GridItem>
                  <GridItem>
                    <FormControlLabel
                      value={3}
                      control={<Radio />}
                      label="Intermediate"
                    />
                  </GridItem>
                  <GridItem>
                    <FormControlLabel
                      value={4}
                      control={<Radio />}
                      label="Advanced"
                    />
                  </GridItem>
                </GridContainer>
              </RadioGroup>
            </FormControl>
          </Section>

          <Section>
            <FormControl sx={{ width: "100%" }}>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Distance:
              </FormLabel>
              <RadioGroup
                row
                value={distance}
                onChange={(event) => setDistance(event.target.value)}
              >
                <GridContainer>
                  <GridItem>
                    <FormControlLabel
                      value=""
                      control={<Radio />}
                      label="Any"
                    />
                  </GridItem>
                  <GridItem>
                    <FormControlLabel
                      value="<500"
                      control={<Radio />}
                      label="<500m"
                    />
                  </GridItem>
                  <GridItem>
                    <FormControlLabel
                      value="500-1000"
                      control={<Radio />}
                      label="500-1000m"
                    />
                  </GridItem>
                  <GridItem>
                    <FormControlLabel
                      value="1000-2000"
                      control={<Radio />}
                      label="1km-2km"
                    />
                  </GridItem>
                  <GridItem>
                    <FormControlLabel
                      value="2000-3000"
                      control={<Radio />}
                      label="2km-3km"
                    />
                  </GridItem>
                  <GridItem>
                    <FormControlLabel
                      value=">3000"
                      control={<Radio />}
                      label=">3km"
                    />
                  </GridItem>
                </GridContainer>
              </RadioGroup>
            </FormControl>
          </Section>

          <Section>
            <FormControl sx={{ width: "100%" }}>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Equipment:
              </FormLabel>
              <RadioGroup
                row
                value={equipmentSelect}
                onChange={(event) => {
                  setEquipmentSelect(event.target.value);
                  setEquipment([]);
                }}
              >
                <GridContainer>
                  <GridItem>
                    <FormControlLabel
                      value=""
                      control={<Radio />}
                      label="Any"
                    />
                  </GridItem>
                  <GridItem>
                    <FormControlLabel
                      value="none"
                      control={<Radio />}
                      label="None"
                    />
                  </GridItem>
                </GridContainer>
              </RadioGroup>
              <GridContainer>
                {equipmentItems.map((item) => (
                  <GridItem>
                    <FormControlLabel
                      control={<Checkbox />}
                      checked={equipment.includes(item.value)}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setEquipment([...equipment, item.value]);
                          setEquipmentSelect("some");
                        } else {
                          const newEquipment = equipment.filter(
                            (i) => i !== item.value
                          );
                          setEquipment(newEquipment);
                          if (newEquipment.length === 0) {
                            setEquipmentSelect("");
                          }
                        }
                      }}
                      label={
                        <Stack direction="row" sx={{ alignItems: "center" }}>
                          <Typography pr={0.5}>{item.label}</Typography>
                          {renderIcon(item.value)}
                        </Stack>
                      }
                    />
                  </GridItem>
                ))}
              </GridContainer>
            </FormControl>
          </Section>

          {!!tags ? (
            <Section>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel>Tags:</FormLabel>
                <RadioGroup
                  row
                  value={tag}
                  onChange={(event) => setTag(event.target.value)}
                >
                  <GridContainer>
                    <GridItem>
                      <FormControlLabel
                        value=""
                        control={<Radio />}
                        label="Any"
                      />
                    </GridItem>
                    {tags.map((item) => (
                      <GridItem>
                        <FormControlLabel
                          value={item}
                          control={<Radio />}
                          label={item}
                        />
                      </GridItem>
                    ))}
                  </GridContainer>
                </RadioGroup>
              </FormControl>
            </Section>
          ) : null}

          <Box>
            <Button
              onClick={() => {
                setEnvironment("");
                setDistance("");
                setEquipment([]);
                setEquipmentSelect("");
                setTag("");
                setLevel("");
              }}
            >
              Clear All
            </Button>
          </Box>
        </Stack>
      </Popover>
    </div>
  );
};
export default WorkoutFilters;
