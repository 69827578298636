import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone';
// @mui
import {styled, alpha} from '@mui/material/styles';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
//

// ----------------------------------------------------------------------

const StyledDropZone = styled('div')(({theme}) => ({
  maxWidth: 330,
  maxHeight: 330,
  width: '100%',
  height: '100%',
  fontSize: 24,
  display: 'flex',
  flexShrink: 0,
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.disabled,
  borderRadius: theme.shape.borderRadius,
  border: `dashed 1px ${theme.palette.divider}`,
  backgroundColor: alpha(theme.palette.grey[500], 0.08),
  '&:hover': {
    opacity: 0.72,
  },
}));

// ----------------------------------------------------------------------

UploadBox.propTypes = {
  sx: PropTypes.object,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.node,
};

export default function UploadBox({placeholder, error, disabled, file, sx, children, ...other}) {
  const {getRootProps, getInputProps, isDragActive, isDragReject} = useDropzone({
    disabled,
    ...other,
  });

  const isError = isDragReject || !!error;

  const hasFile = !!file;

  return (
    <StyledDropZone
      {...getRootProps()}
      sx={{
        ...(isDragActive && {
          opacity: 0.72,
        }),
        ...(isError && {
          color: 'error.main',
          bgcolor: 'error.lighter',
          borderColor: 'error.light',
        }),
        ...(disabled && {
          opacity: 0.48,
          pointerEvents: 'none',
        }),
        ...(!hasFile && {
          'svg': {
            position: 'absolute'
          },
        }),
        ...sx,
      }}
    >
      <input {...getInputProps()} />

      {!hasFile && (placeholder || <ImageOutlinedIcon sx={{fontSize: {md: 75}}}/>)}

      {children}
    </StyledDropZone>
  );
}
