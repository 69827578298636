const Kickboard = ({size = 41}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.25 16.1912C10.25 11.2206 14.2794 7.19116 19.25 7.19116H21.75C26.7206 7.19116 30.75 11.2206 30.75 16.1912V31.7779C30.75 32.5757 29.8336 33.097 29.1115 32.7581C27.3391 31.9264 24.2568 30.8077 20.5 30.8077C16.7432 30.8077 13.6609 31.9264 11.8885 32.7581C11.1664 33.097 10.25 32.5757 10.25 31.7779V16.1912Z" fill="#EBEBF5" fillOpacity="0.6"/>
    </svg>
  );
}

// Fins.propTypes = {
//   : PropTypes.
// };

export default Kickboard;
