import { createContext, useState } from 'react';
import { useQueryClient } from 'react-query';
//
import * as tracking from '../tracking';
import api from '../api';
import { setSession } from './utils';
import { trackUserSignup, identifyUser } from '../tracking';
import { jwtDecode } from 'jwt-decode';

export const AuthContext = createContext(null);
import { useAlertContext } from 'components/alert/AlertContext';

// ----------------------------------------------------------------------

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('refresh_token'));
  const { showAlert } = useAlertContext();

  const queryClient = useQueryClient();

  // LOGIN
  const login = async (data) => {
    // AccessToken, ExpiresIn, IdToken, RefreshToken, TokenType
    const response = await api.auth.login(data);
    setSession(response?.data);
    setIsAuthenticated(true);

    // Alert Secondary Errors
    if (response?.data?.errorAlerts?.length) {
      response.data.errorAlerts.forEach((error) => {
        showAlert(error.message, { severity: 'error' });
      });
    }

    // Alert Successes
    if (response?.data?.successAlerts?.length) {
      response.data.successAlerts.forEach((success) => {
        showAlert(success.message, { severity: 'success' });
      });
    }

    // Identify user in mixpanel
    const {
      data: { IdToken }
    } = response;
    const parsedToken = jwtDecode(IdToken);
    identifyUser(parsedToken.sub);
  };

  // REGISTER
  const register = async (data) => {
    const response = await api.auth.register(data);
    setSession(null);
    setIsAuthenticated(false);

    // Alert Secondary Errors
    if (response?.data?.errorAlerts?.length) {
      response.data.errorAlerts.forEach((error) => {
        showAlert(error.message, { severity: 'error' });
      });
    }

    // Alert Successes
    if (response?.data?.successAlerts?.length) {
      response.data.successAlerts.forEach((success) => {
        showAlert(success.message, { severity: 'success' });
      });
    }

    // Track user signup in mixpanel
    const {
      data: { id }
    } = response;
    trackUserSignup(id);
  };

  // LOGOUT
  const logout = async () => {
    const refreshToken = localStorage.getItem('refresh_token');
    await api.auth.logout({ refreshToken });
    setSession(null);
    setIsAuthenticated(false);
    queryClient.clear();
    tracking.trackUserLogout();
  };

  const sendVerification = async (data) => {
    await api.auth.resendVerification(data);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logout,
        register,
        sendVerification
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
