import {Box, Card, Chip, Stack, Typography} from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag";
import {flavorsChip} from "../WorkoutCreator/utils";
import {secondsToHMS} from "../../util";
import {equipmentIcons} from "../workout/utils";


const SetCard = ({data, toolbar, actions}) => {
  const {name, flavor, distance, baseFtp, equipment} = data;

  return (
    <Card sx={{p: 1.5, backgroundColor: "background.secondaryLight"}}>
      <Stack height="100%" gap={1}>
        <Box display="flex" alignItems="start" gap={1.5}>
          {toolbar}
          <Typography sx={{mt: .7}} variant="h6">{name}</Typography>
        </Box>
        <Stack direction="row" alignItems="center" gap={2}>
          {flavorsChip[flavor] && <Chip {...flavorsChip[flavor]} variant="outlined" size="small"/>}
          <Box display="flex" gap={.5}>
            <FlagIcon opacity={.55}/>
            <Typography variant="body1">{distance ?? '0'}</Typography>
          </Box>
          <Box display="flex" gap={.5}>
            <Typography variant="body1" sx={{opacity: .7}}>FTP:</Typography>
            <Typography variant="body1">{baseFtp ? secondsToHMS(baseFtp) : '0:00'}</Typography>
          </Box>
        </Stack>
        <Box sx={{display: 'flex', alignItems: 'center', flexFlow: 'wrap', gap: 1}}>
          {equipment?.map(key => {
            const EquipmentIcon = equipmentIcons[key];
            return EquipmentIcon && <EquipmentIcon key={key}/>
          })}
        </Box>
        {actions}
      </Stack>
    </Card>
  )
}

export default SetCard;