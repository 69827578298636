import { Box, Typography } from "@mui/material";


const ProgressStatistics = ({ type = "totals", statistics }) => {

	return (
		<Box className="metrics">
			{ statistics[type].map((metric, i) => (
				<Box key={ metric.key }>
					<Typography variant={ 'h5' }>
						{ metric.value }
					</Typography>
					<Typography variant={ 'caption' }
								sx={ { color: 'rgba(235, 235, 245, 0.6)' } }>{ metric.title }</Typography>
				</Box>
			)) }
		</Box>
	);
};

export default ProgressStatistics;
