import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Box, Stack, Drawer } from '@mui/material';
// hooks
import { useResponsive } from '../../../hooks';
import { UserContext } from 'context';
// config
import { HEADER, NAV } from '../config';
// components
import {
  ActivitiesIcon,
  ProgressIcon,
  WorkoutsIcon,
  CoachProfileIcon,
  CoachStoreIcon,
  CoachSwimmersIcon,
  CoachTrainingToolsIcon
} from '../../../assets/icons';
import NavItem from './NavItem';
import FreshdeskWidgetButton from '../../../components/freshdesk-widget-btn';

// ----------------------------------------------------------------------

const pages = [
  {
    text: 'Progress',
    path: '/progress',
    icon: ProgressIcon
  },
  {
    text: 'Activities',
    path: '/activity',
    icon: ActivitiesIcon
  },
  {
    text: 'Training',
    path: '/training',
    icon: WorkoutsIcon
  }
];

const coachingPages = [
  {
    text: 'My Swimmers',
    path: '/coaching/swimmers',
    icon: CoachSwimmersIcon
  },
  {
    text: 'Training Tools',
    path: '/coaching/training',
    icon: CoachTrainingToolsIcon
  },
  {
    text: 'Coach Profile',
    path: '/coaching/profile',
    icon: CoachProfileIcon
  }
  // {
  // 	text: "My Store",
  // 	path: "/coaching/store",
  // 	icon: CoachStoreIcon,
  // },
];

const NavSectionVertical = ({ data, sx, ...other }) => {
  return (
    <Stack sx={{ py: 1, ...sx }} {...other}>
      {data.map((page, index) => (
        <NavItem key={index} item={page} />
      ))}
    </Stack>
  );
};

const NavVertical = ({ openNav, onCloseNav, isCoachLayout }) => {
  const { pathname } = useLocation();
  const isMobile = useResponsive('down', 'lg');
  const { user } = useContext(UserContext);
  const userPages = user.hasCoach ? pages : pages.filter((page) => page.text !== 'Training');

  useEffect(() => {
    if (openNav && isMobile) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isMobile]);

  const renderContent = (
    <Stack
      spacing={3}
      justifyContent='space-between'
      sx={{
        py: 1,
        height: '100%'
      }}>
      <NavSectionVertical data={isCoachLayout ? coachingPages : userPages} />

      <FreshdeskWidgetButton />
    </Stack>
  );

  return (
    <Box
      component='nav'
      sx={(theme) => ({
        flexShrink: { lg: 0 },
        transition: theme.transitions.create('width', { duration: 225 }),
        width: { lg: openNav ? NAV.W_DASHBOARD : 0 },
        zIndex: 1
      })}>
      {isMobile ? (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD
            }
          }}>
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          variant='persistent'
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
              pt: `${HEADER.H_DESKTOP}px`
            }
          }}>
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
};

export default NavVertical;
