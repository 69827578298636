import { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import api from "api";
import { trackSendPostSwimLog } from "../../tracking";
import { useParams } from "react-router-dom";

const ActivityFeedback = ({ activity, refetch }) => {
	const { swimmerId } = useParams();
	const [sentiment, setSentiment] = useState(null);
	const [comment, setComment] = useState("");
	const { mutate, isLoading } = useMutation(api.activities.update, {
		onSuccess: () => {
			refetch();
		},
		onError: (error) => console.log(error),
	});

	const submit = () => {
		mutate({
			id: activity.id,
			data: { userSentiment: sentiment, userComment: comment },
		});
		trackSendPostSwimLog(sentiment, comment);
	};

	if (!!activity.userComment || !!activity.userSentiment) {
		let emoji = emojis.find((emoji) => emoji.value === activity.userSentiment);
		return (
			<div
				className="activity-container activity-section"
				style={{ display: "flex" }}
			>
				<div
					className="activity-feedback"
					style={{ width: !!activity.userComment ? "100%" : "inherit" }}
				>
					<h2 className="title" style={{ marginBottom: "1rem" }}>
						Post activity log
					</h2>
					<div style={{ display: "flex" }}>
						{!!activity.userSentiment && (
							<Emoji
								key={emoji.value}
								symbol={emoji.unicode}
								label={emoji.text}
								emoji={emoji}
								handleSelect={setSentiment}
								selected={activity.userSentiment}
								style={{ minWidth: "0" }}
							/>
						)}
						{!!activity.userComment && <p>{activity.userComment}</p>}
					</div>
				</div>
			</div>
		);
	}

	if (!!swimmerId) {
		return null;
	}

	return (
		<div className="activity-container activity-section activity-feedback">
			<div className="activity-feedback__header">
				<h2 className="title">{`Post ${activity.activityType} log:`}</h2>
			</div>
			<div className="activity-feedback__icons">
				{emojis.map((emoji) => (
					<Emoji
						key={emoji.value}
						symbol={emoji.unicode}
						label={emoji.text}
						emoji={emoji}
						handleSelect={setSentiment}
						selected={sentiment}
					/>
				))}
			</div>
			<textarea
				style={{ color: "black" }}
				value={comment}
				onChange={(event) => setComment(event.target.value)}
				rows={2}
				placeholder={`What was good or what needs improvement? (optional)`}
				maxLength="140"
			/>
			<button
				className="button button--gradient button--lg"
				onClick={submit}
				disabled={isLoading}
			>
				Submit
			</button>
		</div>
	);
};

ActivityFeedback.propTypes = {
	activity: PropTypes.object.isRequired,
	refetch: PropTypes.func.isRequired,
	coachView: PropTypes.bool,
};

ActivityFeedback.defaultProps = {
	coachView: false,
};

export default ActivityFeedback;

const Emoji = ({ emoji, selected, handleSelect, style = {} }) => (
	<div
		className={`activity-emoji${emoji.value === selected ? "--active" : ""}`}
		style={style}
		onClick={() => handleSelect(emoji.value)}
	>
		<div className="activity-emoji__emoji-container flex-center-all">
			<span
				className="emoji"
				role="img"
				aria-label={emoji.text ? emoji.text : ""}
				aria-hidden={emoji.text ? "false" : "true"}
			>
				{emoji.unicode}
			</span>
		</div>
		<p className="activity-emoji__text">{emoji.text}</p>
	</div>
);

const emojis = [
	{
		text: "Awful",
		unicode: `\u{1f62b}`,
		value: 1,
	},
	{
		text: "Bored",
		unicode: `\u{1f61e}`,
		value: 2,
	},
	{
		text: "Normal",
		unicode: `\u{1f610}`,
		value: 3,
	},
	{
		text: "Good",
		unicode: `\u{1f642}`,
		value: 4,
	},
	{
		text: "Great",
		unicode: `\u{1f600}`,
		value: 5,
	},
];
