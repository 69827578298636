import * as Yup from 'yup';
// form
import {useForm, useWatch} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
// @mui
import {Stack, Typography, Box, InputLabel, Alert} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// components
import FormProvider, {RHFTextField} from '../../components/hook-form';
import {hideEmail, PASSWORD_PATTERNS} from "./utils";
import api from "../../api";

// ----------------------------------------------------------------------

const ValidationMessage = ({match, str, message}) => {
  const color = str.match(match) ? 'success.main' : 'text.disabled';
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <CheckCircleOutlineIcon sx={{fontSize: 14, color}}/>
      <Typography sx={{fontSize: 12}} variant="body2" color={color}>{message}</Typography>
    </Stack>
  );
};

const CodeFormSection = () => {
  const email = useWatch({name: 'email'});

  return (
    <>
      <Typography variant="body1">Confirm code</Typography>
      <Box>
        <Typography variant="body1" sx={{mb: .5}}>We have sent a password reset code by email
          to {email && hideEmail(email)}.
          Enter it below to reset your password.</Typography>
        <RHFTextField
          type="number"
          name="code"
          placeholder="6-digit Code"
        />
      </Box>
    </>
  );
}

const PasswordFormSection = () => {
  const password = useWatch({name: 'password'});

  return (
    <>
      <Typography variant="body1">Create new password</Typography>

      <Typography variant="body1">Your new password must be different from the previously used passwords</Typography>

      <Box>
        <InputLabel sx={{mb: .5}}>Enter New Password</InputLabel>
        <RHFTextField
          name="password"
          placeholder="Password"
          type="password"
        />
      </Box>

      {password && (
        <Stack gap={.5}>
          <ValidationMessage match={PASSWORD_PATTERNS.lowerCase} str={password}
                             message="Password must contain a lower case letter"/>
          <ValidationMessage match={PASSWORD_PATTERNS.upperCase} str={password}
                             message="Password must contain an upper case letter"/>
          <ValidationMessage match={PASSWORD_PATTERNS.minLength} str={password}
                             message="Password must contain at least 8 characters"/>
          <ValidationMessage match={PASSWORD_PATTERNS.specialCharacter} str={password}
                             message="Password must contain a special character"/>
          <ValidationMessage match={PASSWORD_PATTERNS.number} str={password} message="Password must contain a number"/>
        </Stack>
      )}
    </>
  );
}

const AuthVerifyCodeForm = ({onVerify}) => {
  const emailRecovery = typeof window !== 'undefined' ? sessionStorage.getItem('email-recovery') : '';

  const VerifyCodeSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must contain at least 8 characters')
      .matches(PASSWORD_PATTERNS.lowerCase, 'Password must contain a lower case letter')
      .matches(PASSWORD_PATTERNS.upperCase, 'Password must contain an upper case letter')
      .matches(PASSWORD_PATTERNS.specialCharacter, 'Password must contain a special character')
      .matches(PASSWORD_PATTERNS.number, 'Password must contain a number')
      .matches(PASSWORD_PATTERNS.space, 'Password cannot contain spaces'),
    code: Yup.number()
      .required('Code is required')
      .integer('Distance must be an integer')
      .test({
        name: 'length',
        message: 'Must be exactly 6 characters.',
        test: (value) => value == null || value.toString().length === 6,
      }),
  });

  const defaultValues = {
    email: emailRecovery || '',
    password: '',
    code: '',
  };

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(VerifyCodeSchema),
    defaultValues,
  });

  const {
    watch,
    reset,
    setError,
    handleSubmit,
    formState: {isSubmitting, errors},
  } = methods;

  const {code} = watch();

  const isCodeValid = code && !errors.code;

  const onSubmit = async (data) => {
    try {
      await api.auth.resetPassword(data);

      onVerify();
    } catch (error) {
      console.error(error);

      const message = typeof error?.response?.data?.message === 'string' ? error.response.data.message : error.message;

      reset();

      setError('afterSubmit', {
        ...error,
        message,
      });
    }
  };


  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{px: 2, py: 3}}>
        <Stack gap={3}>
          {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

          {!isCodeValid && <CodeFormSection/>}

          {isCodeValid && <PasswordFormSection/>}

          <LoadingButton
            sx={{textTransform: 'capitalize'}}
            size="large"
            fullWidth
            type="submit"
            variant="gradient"
            loading={isSubmitting}
          >
            Reset my password
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  );
}

export default AuthVerifyCodeForm;