import {Fragment} from "react";
import {
  Line,
  XAxis,
  YAxis,
  ReferenceArea,
  ReferenceLine,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Bar,
} from "recharts";
import ReferenceLabel from "./ReferenceLabel";
import SegmentLabel from "./SegmentLabel";
import CustomToolTip from "./CustomToolTip";

import { secondsToHMS } from "../../util";
import {metricLinesData} from "./utils";
import {CHART_CONSTANTS} from "./chartUtilities";
const labelHeight = CHART_CONSTANTS.labelHeight;



const PaceChart = ({
  data,
  segments,
  selectedMetrics,
  isStroke,
  activeSegment,
  setActiveSegment,
  disableMetrics,
}) => {
  const metrics = data.correlationKeys;

  const selectData = () => {
    const d = `${isStroke ? "stroke" : "lap"}${
      metrics.length < 2 ||
      (metrics.length === 2 &&
        metrics.includes("crossoverAngle") &&
        metrics.includes("entryAngle"))
        ? "Raw"
        : "Standardised"
    }`;
    return data[d];
  };


  return (
    <ResponsiveContainer width="100%" height={247}>
      <ComposedChart
        data={selectData()}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        syncId="anyId"
      >
        <XAxis
          type="number"
          dataKey="x"
          domain={data[`${isStroke ? "stroke" : "lap"}Domain`]}
          tick={false}
          height={labelHeight}
        />
        <YAxis
          yAxisId="left"
          width={40}
          tickFormatter={(value) => secondsToHMS(value)}
        />
        <YAxis yAxisId="right" orientation="right" width={40} />
        <Tooltip offset={25} content={<CustomToolTip />} />
        <Bar
          isAnimationActive={false}
          dataKey="Pace"
          yAxisId="left"
          fill="#78848D"
        />
        {!disableMetrics && (selectedMetrics || metrics).map(key => (
          <Line
            key={key}
            activeDot={{ strokeWidth: 0, r: 5 }}
            isAnimationActive={false}
            yAxisId="right"
            type="monotone"
            dataKey={metricLinesData[key].label}
            stroke={metricLinesData[key].color}
            strokeWidth={2}
            dot={false}
          />
        ))}
        {data.annotations[
          `features${isStroke ? "Stroke" : "Lap"}`
        ].segmentStartIndices.map((index, i, arr) => {
          return (
            <Fragment key={"l" + index}>
              <ReferenceLine
                x={index - 0.5}
                yAxisId="left"
                stroke="#78848D"
                strokeOpacity={0.5}
              />
              <ReferenceArea
                x1={arr[i] - 0.5}
                x2={`${arr[i + 1] - 0.5}`}
                yAxisId="left"
                fillOpacity={activeSegment === i ? 0.2 : 0}
                label={
                  <ReferenceLabel active={activeSegment === i}>
                    <SegmentLabel segment={segments[i]} />
                  </ReferenceLabel>
                }
                onMouseOver={() => setActiveSegment(i)}
                onMouseOut={() => setActiveSegment(null)}
              />
            </Fragment>
          );
        })}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default PaceChart;
