import {useContext} from "react";
import {useFormContext} from "react-hook-form";
// @mui
import {Grid} from "@mui/material";
// icons
import MenuItem from "@mui/material/MenuItem";
// components
import {RHFSelect, RHFTextField} from "../../../../components/hook-form";
import StrokeDrillField from "./formFields/StrokeDrillField";
import TimeField from "./formFields/TimeField";
import {secondsToHMS} from "../../../../util";
import {CoachContext} from "../../../../context";


const SplitSection = (
  {
    baseName = '',
    updateSegmentsDistance,
    updateSegmentsTargetTime,
    updateSegmentStroke,
    getTargetTime,
    getEffort
  }
) => {
  const {setValue, getValues} = useFormContext();

  const {coach} = useContext(CoachContext);

  const updateTargetTime = (time) => {
    setValue(baseName + 'targetTime', time);
    setValue(baseName + 'targetTimeDisplay', secondsToHMS(time));
    updateSegmentsTargetTime();
  };

  const handleOnUpdateDistance = () => {
    const [distance, effort] = getValues([baseName + 'distance', baseName + 'effort']);
    updateTargetTime(getTargetTime({distance, effort}));
  }

  const handleOnUpdateEffort = () => {
    const [distance, effort] = getValues([baseName + 'distance', baseName + 'effort']);
    updateTargetTime(getTargetTime({distance, effort}));
  }

  const handleOnUpdateTargetTime = ({time}) => {
    const distance = getValues(baseName + 'distance');
    setValue(baseName + 'effort', getEffort({distance, time}));
    updateSegmentsTargetTime();
  }

  const handleOnUpdateStroke = (isDrill) => {
    setValue(baseName + 'isDrill', !!isDrill);
    if (isDrill) {
      const distance = getValues(baseName + 'distance');
      const effort = 'a';
      setValue(baseName + 'effort', effort);
      updateTargetTime(getTargetTime({distance, effort}));
    }
  }


  return (
    <>
      <Grid item xs={12} md={3}>
        <RHFTextField
          name={baseName + 'distance'}
          onChange={(event) => {
            setValue(baseName + 'distance', Math.abs(Number(event.target.value) || 1));
            handleOnUpdateDistance();
            updateSegmentsDistance();
          }}
          label="Distance"
          variant="filled"
          type="number"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <StrokeDrillField
          name={baseName + 'stroke'}
          onChange={(event, {isDrill}) => {
            setValue(baseName + 'stroke', event.target.value);
            handleOnUpdateStroke(isDrill);
            updateSegmentStroke();
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <RHFSelect
          name={baseName + 'effort'}
          label="Effort"
          variant="filled"
          SelectProps={{
            native: false,
            MenuProps: {
              PaperProps: {
                sx: {maxHeight: 220},
              },
            },
            sx: {textTransform: 'capitalize'},
          }}
          onChange={(event) => {
            setValue(baseName + 'effort', event.target.value);
            handleOnUpdateEffort();
          }}
        >
          {Object.keys(coach?.effortZoneNames || {}).map((key) => (
            <MenuItem
              key={key}
              value={key}
              sx={{
                my: 0.5,
                typography: 'body2',
                textTransform: 'capitalize',
                '&:first-of-type': {mt: 0},
                '&:last-of-type': {mb: 0},
              }}
            >
              {coach.effortZoneNames[key]}
            </MenuItem>
          ))}
        </RHFSelect>
      </Grid>
      <Grid item xs={12} md={3}>
        <TimeField
          name={baseName + 'targetTime'}
          label="Target time"
          variant="filled"
          onChangeCallback={handleOnUpdateTargetTime}
        />
      </Grid>
    </>
  );
}

export default SplitSection;