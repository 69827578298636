import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme, { isMobile }) => {
	return {
		header: {
			display: 'flex',
			flexDirection: 'column',
			marginBottom: isMobile ? '20px' : '24px',
			gap: '6px',
		},
		subHeader: {
			marginBottom: '15px',
			...(isMobile && {
				display: 'flex',
				flexDirection: 'column',
				gap: '6px',
			})
		},
		buttonsContainer: {
			display: 'flex',
			alignItems: 'center',
			gap: '40px',
			marginBottom: '20px',
			...(isMobile && {
				flexDirection: 'column-reverse',
				gap: '15px'
			})
		},
		chipContainer: {
			display: 'flex',
			gap: '8px',
			...(isMobile && {
				alignSelf: 'start'
			})
		},
		chip: {
			backgroundColor: theme.palette.background.neutral,
			borderRadius: '8px',
			fontSize: '15px'
		},
		activeChip: {
			color: theme.palette.primary.main
		},
		greyText: {
			color: 'rgba(245, 245, 250, 0.72)',
		},
		handContainer: {
			display: 'flex',
			alignItems: 'center',
			gap: '5px',
			...(isMobile && {
				alignSelf: 'start'
			})
		},
		handText: {
			fontSize: '15px',
			color: '#B0B0B6',
		},
		statsWrapper: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			marginBottom: '40px',
			[theme.breakpoints.down('md')]: {
				display: 'block',
				marginBottom: '20px'
			},
		},
		statsContainer: {
			display: 'flex',
			gap: '40px',
			[theme.breakpoints.down('md')]: {
				marginBottom: '20px',
				justifyContent: 'center',
				gap: '60px',
			},
		},
		graphStatsContainer: {
			display: 'flex',
			alignItems: 'center',
			...(isMobile && {
				justifyContent: 'center'
			})
		},
		square: {
			width: '16px',
			height: '16px',
			backgroundColor: theme.palette.primary.main,
			marginRight: '8px'
		},
		chartContainer: {
			marginBottom: isMobile ? '20px' : '30px',
			height: '300px',
			...(isMobile && {
				padding: '0 10px'
			})
		}

	}
})
