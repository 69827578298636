// import PropTypes from 'prop-types';

const Mixed = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.41705 29H6.66852V23.4893H6.75982L8.90826 29H9.85357L12.002 23.4893H12.0987V29H13.3448V21.2495H11.7549L9.42926 27.2114H9.33795L7.00689 21.2495H5.41705V29ZM15.3169 29H16.7026V21.2495H15.3169V29ZM18.1913 29H19.706L21.5375 26.1479H21.5859L23.4174 29H25.018L22.4667 25.1328V25.1167L25.061 21.2495H23.5034L21.7094 24.166H21.6503L19.851 21.2495H18.2128L20.7211 25.0791V25.0952L18.1913 29Z" fill="white"/>
      <circle cx="20" cy="6" r="3" fill="white"/>
      <path d="M11 3L14.4641 9H7.5359L11 3Z" fill="white"/>
      <rect x="8" y="12" width="6" height="6" fill="white"/>
      <rect x="20" y="11.5" width="5" height="5" transform="rotate(45 20 11.5)" fill="white"/>
    </svg>
  );
}

// Mixed.propTypes = {
//   : PropTypes.
// };

export default Mixed;
