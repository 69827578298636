// @mui
import { Box, Card, LinearProgress, Stack, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";

import { bgGradient, getPercent, numberWithLeadingZeros, secondsToDisplayTime, secondsToHMS } from "../../util";
import { RUN_VALUES } from "./utils";

import { RunningIcon } from "../../assets/icons";

const SegmentCardHeader = ({ distance, duration }) => (
	<Box sx={ (theme) => ({
		p: 1,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderBottom: 1,
		borderBottomColor: alpha(theme.palette.common.white, .11),
	}) }>
		<Stack direction="row" alignItems="center" gap={ 1 }>
			<Box sx={ {
				p: .5,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				borderRadius: 2,
				width: 40,
				height: 40,
				backgroundColor: 'background.secondaryLight'
			} }>
				<RunningIcon/>
			</Box>
			<Typography variant="h6">{ distance }</Typography>
		</Stack>
		<Typography sx={ { mr: 1 } } variant="body1">{ duration }</Typography>
	</Box>
);

const SegmentCardLap = ({ lap, length, valuePercent, valueDisplay }) => (
	<Stack sx={ {
		p: 1,
		borderRadius: 2,
		'&:nth-of-type(even)': {
			backgroundColor: 'background.secondaryLight'
		}
	} } direction="row" justifyContent="space-between" alignItems="center">
		<Stack flexGrow={ 1 } direction="row" gap={ 1 } alignItems="center">
			<Typography variant="body1">{ lap }</Typography>
			<Typography variant="body1" color="text.secondary">{ lap && length && '\\' }</Typography>
			<Typography variant="body1" color="text.secondary">{ length }</Typography>
			<LinearProgress
				sx={ (theme) => ({
					ml: 2,
					width: '100%',
					height: 16,
					borderRadius: .5,
					backgroundColor: alpha(theme.palette.common.black, .11),
					'.MuiLinearProgress-bar': {
						transition: theme.transitions.create('transform', { duration: 225, easing: 'ease' }),
						...bgGradient({
							direction: 'to right',
							startColor: theme.palette.action.gradientStart,
							endColor: theme.palette.action.gradientEnd,
						}),
					},
				}) }
				variant="determinate"
				value={ valuePercent }
			/>
		</Stack>
		<Typography sx={ { minWidth: 66, textAlign: 'right' } } variant="body1">{ valueDisplay }</Typography>
	</Stack>
);

const RunSegmentCard = ({ data, lapUnit, view, maxValues, self }) => {
	const distance = (data?.segmentSize?.toFixed(0) || 0) + lapUnit;
	const valueDisplayKey = RUN_VALUES[view].keyDisplay;
	const valueKey = RUN_VALUES[view].key;

	return (
		<Card sx={ { backgroundColor: "background.secondary" } }>
			<SegmentCardHeader
				distance={ distance }
				duration={ secondsToDisplayTime(data.segmentTimeSec) }
			/>

			<Box sx={ { px: 1, py: 2 } }>
				<Stack sx={ { px: 1, mb: 1 } } direction="row" justifyContent="space-between" alignItems="center">
					<Typography variant="caption" color="text.secondary">LAP</Typography>
					<Typography variant="caption" color="text.secondary">{ RUN_VALUES[view]?.label }</Typography>
				</Stack>

				{ data?.laps.map((lap, i) => {
					const lapActivity = numberWithLeadingZeros(i + 1, 2);
					const valuePercent = getPercent(lap[valueKey] || 0, maxValues[view] || 100);
					const lapValue = view === 'split' ? secondsToHMS(lap[valueDisplayKey]) : lap[valueDisplayKey].toFixed();

					const lapLengthLabel = (lap.lapSize.toFixed() || 0) + lapUnit;
					return (
						<SegmentCardLap
							key={ i }
							lap={ lapActivity }
							length={ lapLengthLabel }
							valuePercent={ valuePercent }
							valueDisplay={ lapValue }
						/>
					);
				}) }
			</Box>
		</Card>
	);
}

export default RunSegmentCard;
