import coaching from "./coachingApi";
import admin from "./adminApi";
import auth from "./auth";
import * as user from "./user";
import * as stripe from "./stripe";
import * as pubnub from "./pubnub";
import * as activities from "./activities";
import * as coaches from "./coaches";
import * as garmin from "./garmin";
import * as progress from "./progress";
import * as workouts from "./workouts";
import * as coupons from "./coupons";
import * as contact from "./contact";

export const api = {
  coaching,
  admin,
  auth,
  user,
  stripe,
  pubnub,
  activities,
  coaches,
  garmin,
  progress,
  workouts,
  coupons,
  contact,
};

export default api;

// export {} from // getUser,
// getUserProgress,
// updateUser,
// getProgressForm,
// getProgressFitness,
// getUserBestTimes,
// getProgressActivityStatistics,
// getProgressStrokeStatistics,
// getActivities,
// getActivity,
// updateActivity,
// getGarminRequestToken,
// connectGarmin,
// disconnectGarmin,
// getStrokes,
// listCoachWorkouts,
// createWorkout,
// getCoachWorkout,
// deleteWorkout,
// listWorkoutSets,
// getWorkoutSet,
// createSet,
// deleteWorkoutSet,
// getWorkouts,
// getWorkout,
// getCoaches,
// getAdminCoaches,
// getCoach,
// createCoach,
// deleteCoach,
// updateCoach,
// getCoachSwimmers,
// getCoachSwimmer,
// getCoachSwims,
// getCoachAssetUploadUrl,
// getPubNubToken,
// setupChat,
// getChannelUnreadMessageCount,
// getGroupHasUnreadMessages,
// listChannelGroup,
// createCoaching,
// deleteCoaching,
// updateCoaching,
// getCoaching,
// subscribeToCoachWorkouts,
// createConnectedAcount,
// createConnectedAcountLink,
// getConnectedAccount,
// createWorkoutSubscriptionProduct,
// createCheckoutSession,
// "./index2";
