import SvgIcon from '@mui/material/SvgIcon';
const DurationIcon = props => {
  return (
    <SvgIcon {...props} fillOpacity={.56} fill="white">
      <path d="M12.49 2.90234C6.97 2.90234 2.5 7.38234 2.5 12.9023C2.5 18.4223 6.97 22.9023 12.49 22.9023C18.02 22.9023 22.5 18.4223 22.5 12.9023C22.5 7.38234 18.02 2.90234 12.49 2.90234ZM12.5 20.9023C8.08 20.9023 4.5 17.3223 4.5 12.9023C4.5 8.48234 8.08 4.90234 12.5 4.90234C16.92 4.90234 20.5 8.48234 20.5 12.9023C20.5 17.3223 16.92 20.9023 12.5 20.9023Z" />
      <path d="M13 7.90234H11.5V13.9023L16.75 17.0523L17.5 15.8223L13 13.1523V7.90234Z" />
    </SvgIcon>
  );
}

export default DurationIcon;
