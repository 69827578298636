import { useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Stack,
  Button,
  TextField,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  FormControlLabel,
} from "@mui/material";
import { useQuery, useMutation } from "react-query";
import api from "api";
import { LoadingButton } from "@mui/lab";
import ConfirmDialog from "components/ConfirmDialog";
import Modal from "components/Modal";
import Spinner from "components/common/Spinner";

const CouponAdmin = () => {
  const [deleteCoupon, setDeleteCoupon] = useState(null);
  const [open, setOpen] = useState(false);
  const {
    data: rows,
    refetch,
    isLoading,
  } = useQuery(["adminCoupons"], api.admin.coupons.list);

  const deleteMutation = useMutation(api.admin.coupons.remove, {
    onSuccess: () => {
      setDeleteCoupon(null);
      refetch();
    },
  });

  const handleDeleteCoupon = () => {
    deleteMutation.mutate(deleteCoupon.code);
  };

  return (
    <>
      <Stack direction="row" sx={{ justifyContent: "space-between" }} pb={3}>
        <Typography variant="h4">Coupons</Typography>
        <Button onClick={() => setOpen(true)} variant="contained" size="small">
          Create Coupon
        </Button>
      </Stack>

      <Box>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Code</TableCell>
                <TableCell>Product</TableCell>
                <TableCell>Months</TableCell>
                <TableCell align="right">Type</TableCell>
                <TableCell align="right">Remaining Available</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <Spinner />
              ) : (
                !!rows?.length &&
                rows.map((row) => (
                  <TableRow
                    key={row.code}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.code}
                    </TableCell>
                    <TableCell>{row.productType}</TableCell>
                    <TableCell>{row.months}</TableCell>
                    <TableCell align="right">{row.type}</TableCell>
                    <TableCell align="right">{row.available}</TableCell>

                    <TableCell align="right">
                      <LoadingButton onClick={() => setDeleteCoupon(row)}>
                        Delete
                      </LoadingButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <ConfirmDialog
        open={!!deleteCoupon}
        title={`Permanently delete ${deleteCoupon?.code} coupon code?`}
        text="This cannot be undone. This will delete the coupon code for future use. Users who have used this code will not be affected."
        confirmText="Delete Coupon"
        handleConfirm={handleDeleteCoupon}
        handleClose={() => setDeleteCoupon(null)}
        verifyText={deleteCoupon?.code}
        verifyLabel="Type code to confirm deletion"
      />
      <Modal open={open} handleClose={() => setOpen(false)}>
        <CreateCouponForm
          onSuccess={() => {
            refetch();
            setOpen(false);
          }}
        />
      </Modal>
    </>
  );
};
export default CouponAdmin;

const defaultForm = {
  code: "",
  productType: "SIMMA-ANALYTICS-SUB",
  months: "",
  type: "multi",
  available: "",
};

const CreateCouponForm = ({ onSuccess }) => {
  const [form, setForm] = useState(defaultForm);
  const [error, setError] = useState(null);

  const { mutate } = useMutation(api.admin.coupons.create, {
    onSuccess: () => {
      setError(null);
      setForm(defaultForm);
      onSuccess();
    },
    onError: (error) => {
      setError(error.response.data);
    },
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    mutate(form);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2} p={'80px 24px 24px 24px'}>
        <TextField
          label="Code"
          name="code"
          value={form.code}
          onChange={handleChange}
          error={!!error}
          helperText={error || "Leave blank to generate a random code"}
        />
        <TextField
          label="Months"
          name="months"
          value={form.months}
          onChange={handleChange}
        />
        <FormControl>
          <FormLabel>Product</FormLabel>
          <RadioGroup
            row
            defaultValue="SIMMA-ANALYTICS-SUB"
            name="productType"
            value={form.productType}
            onChange={handleChange}
          >
            <FormControlLabel
              value="SIMMA-ANALYTICS-SUB"
              control={<Radio />}
              label="Simma Analytics"
            />
          </RadioGroup>
        </FormControl>

        <FormControl>
          <FormLabel>Type</FormLabel>
          <RadioGroup
            row
            defaultValue="multi"
            name="type"
            value={form.type}
            onChange={handleChange}
          >
            <FormControlLabel
              value="multi"
              control={<Radio />}
              label="Multi use"
            />
            <FormControlLabel
              value="single"
              control={<Radio />}
              label="Single use"
            />
          </RadioGroup>
        </FormControl>
        <TextField
          label="Available Amount"
          name="available"
          value={form.available}
          onChange={handleChange}
          disabled={form.type === "single"}
          fullWidth
          sx={{
            display: form.type === "single" ? "none" : "block",
          }}
        />
        <Button
          type="submit"
          variant="contained"
          disabled={!form.months || (form.type === "multi" && !form.available)}
        >
          Create Coupon
        </Button>
      </Stack>
    </form>
  );
};
