import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { capitalize } from 'util';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectCheckmarks({
  label,
  items = [],
  value = [],
  onUpdate,
  onClose,
  disabled = false,
  renderValue,
}) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    onUpdate(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel>{label}</InputLabel>
        <Select
          multiple
          value={value}
          onChange={handleChange}
          onClose={onClose}
          input={<OutlinedInput label={label} size='small' />}
          renderValue={(selected) =>
            renderValue ?? selected.map((str) => capitalize(str)).join(', ')
          }
          MenuProps={MenuProps}
          disabled={disabled}
        >
          {items.map((item) => (
            <MenuItem key={item} value={item}>
              <Checkbox checked={value.indexOf(item) > -1} />
              <ListItemText primary={capitalize(item)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
