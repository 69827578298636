import { useState } from "react";
// import PropTypes from 'prop-types';
import DatePickerField from "../forms/DatePickerField";
import Selector from "../forms/Selector";
import { useMutation } from "react-query";
import api from "api";
import { getAge } from "../../util";
import {
  Button,
  // Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const ProfileAgeForm = ({ user, handleClose, refetch }) => {
  const [birthdate, setBirthdate] = useState(
    !!user.birthdate ? new Date(user.birthdate) : null
  );
  const [ageRange, setAgeRange] = useState(
    user.ageRange ? user.ageRange.join("-") : null
  );

  const mutation = useMutation((data) => api.user.update(data), {
    onSuccess: () => {
      refetch();
      handleClose();
    },
  });

  const handleBirthdateChange = (date) => {
    if (!date) {
      setBirthdate("");
      return;
    }
    date.setHours(12, 0, 0, 0);
    const age = getAge(date);
    const ageRange = ageRanges.find((item) => {
      if (item.value === null) return false;
      const arr = item.value.split("-");
      return age >= arr[0] && age <= arr[1];
    });
    setAgeRange(ageRange?.value || "81-100");
    setBirthdate(date);
  };

  const submit = () => {
    const data = {
      ageRange: !!ageRange ? ageRange.split("-") : [],
      birthdate: !!birthdate ? birthdate.toISOString() : "",
    };
    mutation.mutate(data);
  };

  return (
    <>
      <DialogTitle className="title">Age</DialogTitle>

      <DialogContent sx={{ minWidth: { md: 450 } }}>
        <DialogContentText mb={2}>
          Enter birthday or age range for more accurate heart rate calculations.
        </DialogContentText>
        <DatePickerField
          label="Birthday"
          value={birthdate}
          onUpdate={(date) => handleBirthdateChange(date)}
          showYearDropdown
        />
        <Selector
          items={ageRanges}
          value={ageRange}
          label="Age range"
          onUpdate={(value) => {
            setAgeRange(value);
            setBirthdate(null);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <button
          className="button"
          onClick={submit}
          disabled={mutation.isLoading}
        >
          Submit
        </button>
      </DialogActions>
    </>
  );
};

// ProfileAgeForm.propTypes = {
//   : PropTypes.
// };

export default ProfileAgeForm;

const ageRanges = [
  { text: "-", value: null },
  { text: "0-10", value: "0-10" },
  { text: "11-15", value: "11-15" },
  { text: "16-20", value: "16-20" },
  { text: "21-25", value: "21-25" },
  { text: "26-30", value: "26-30" },
  { text: "31-35", value: "31-35" },
  { text: "36-40", value: "36-40" },
  { text: "41-45", value: "41-45" },
  { text: "46-50", value: "46-50" },
  { text: "51-55", value: "51-55" },
  { text: "56-60", value: "56-60" },
  { text: "61-65", value: "61-65" },
  { text: "66-70", value: "66-70" },
  { text: "71-75", value: "71-75" },
  { text: "76-80", value: "76-80" },
  { text: "80+", value: "81-100" },
];
