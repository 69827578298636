import {useContext} from "react";
// @mui
import { Container, Typography } from '@mui/material';
//
import {UserContext} from "../context";
import LockIcon from "../assets/icons/LockIcon";

// ----------------------------------------------------------------------

const RoleBasedGuard = ({ role, children }) =>  {
  const { user } = useContext(UserContext);

  if (typeof role !== 'undefined' && user.hasOwnProperty(role) && !user[role]) {
    return (
      <Container sx={{ height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
        <LockIcon />
        <Typography color="text.secondary">Permission Denied</Typography>
      </Container>
    );
  }

  return <>{children}</>;
}

export default RoleBasedGuard;
