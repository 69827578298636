import { parseMessageTime } from "../../util";
import { usePubNub } from "pubnub-react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import * as regex from "./regex";
import { Typography } from "@mui/material";
import api from "api";
import { useQuery } from "react-query";

const Message = ({ object, channelData }) => {
  const pubnub = usePubNub();
  const own = object.uuid === pubnub.getUUID();

  return (
    <div className={`activity-chat-message${own ? "--own" : ""}`}>
      <p className="activity-chat-message-date">
        {own ? "You" : channelData[object.uuid]?.name}
      </p>
      <div className={`activity-chat-message-content${own ? "--own" : ""}`}>
        <img
          src={
            !!channelData[object.uuid]?.avatar
              ? channelData[object.uuid].avatar
              : own
              ? "/images/workouts/workout-default.png"
              : "/images/workouts/workout-open-2.png"
          }
          className={`activity-chat-message-content__icon${own ? "--own" : ""}`}
          alt="icon"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: own ? "flex-end" : "flex-start",
          }}
        >
          <div style={{ minWidth: "1.5rem" }}>
            <MessageBody messageBody={object.message} />
          </div>

          <p
            className={`activity-chat-message-content__time${
              own ? "--own" : ""
            }`}
          >
            {parseMessageTime(object.timetoken)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Message;

const MessageBody = ({ messageBody }) => {
  const location = useLocation();

  if (typeof messageBody === "string") {
    return messageBody;
  }

  if (messageBody.type === "text") {
    return messageBody.text;
  }
  if (messageBody.type === "link") {
    // If coaching view
    if (!!regex.matchCoachingView(location.pathname)) {
      const { swimmerId } = regex.matchCoachingView(location.pathname);
      if (messageBody.data.page === "activity") {
        return (
          <Link
            to={{
              pathname: `/coaching/swimmers/${swimmerId}/swims/${messageBody.data.activityId}`,
              state: { isSwimmerView: true },
            }}
          >
            <Typography color={"primary"}>
              {messageBody.text || "Activity"}
            </Typography>
          </Link>
        );
      } else if (messageBody.data.page === "progress") {
        return (
          <Link
            to={{
              pathname: `/coaching/swimmers/${swimmerId}/progress`,
              state: { isSwimmerView: true },
            }}
          >
            <Typography color={"primary"}>
              {messageBody.text || "Progress"}
            </Typography>
          </Link>
        );
      } else {
        return "Haven't parsed that kind of link yet";
      }
    } else {
      if (messageBody.data.page === "activity") {
        return (
          <Link to={`/activity/${messageBody.data.activityId}`}>
            <Typography color={"primary"}>
              {messageBody.text || "Activity"}
            </Typography>
          </Link>
        );
      } else if (messageBody.data.page === "progress") {
        return (
          <Link to={`/progress`}>
            <Typography color={"primary"}>
              {messageBody.text || "Progress"}
            </Typography>
          </Link>
        );
      } else {
        return "Haven't parsed that kind of link yet";
      }
    }
  }

  return "";
};
