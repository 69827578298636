import SvgIcon from '@mui/material/SvgIcon';
const DrillIcon = props => {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M16.0522 6.93724C15.6979 6.31627 15.0407 5.93463 14.3285 5.93624L9.69823 5.94668C8.98604 5.94829 8.32717 6.33289 7.97008 6.95547L5.64359 11.0115C5.28713 11.633 5.28545 12.3973 5.63916 13.0172L7.94775 17.0628C8.3021 17.6837 8.95927 18.0654 9.67146 18.0638L14.3018 18.0533C15.014 18.0517 15.6728 17.6671 16.0299 17.0445L18.3564 12.9885C18.7129 12.367 18.7145 11.6027 18.3608 10.9829L16.0522 6.93724ZM13.3207 14.3145C14.5907 13.5727 15.0288 11.9351 14.2994 10.6568C13.57 9.37859 11.9492 8.94372 10.6793 9.68553C9.40934 10.4273 8.97118 12.0649 9.7006 13.3432C10.43 14.6214 12.0508 15.0563 13.3207 14.3145Z" fill="#EBEBF5" fillOpacity="0.6"/>
    </SvgIcon>
  );
}

export default DrillIcon;
