import { useState } from 'react';
import {
  Button,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Container
} from '@mui/material';
import Modal from 'components/Modal';
import { capitalize } from 'util';
import DatePickerField from 'components/forms/DatePickerField';
import api from 'api';
import { LoadingButton } from '@mui/lab';
import SwimmerReport from './SwimmerReport';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DownloadPdfButton from 'components/common/DownloadPdfButton';

const SwimmerReportsButton = ({ selectedSwimmerIds }) => {
  const [open, setOpen] = useState(false);
  const [reports, setReports] = useState(null);
  const [loading, setLoading] = useState(false);

  const submitCreateReports = async (data) => {
    setLoading(true);
    api.coaching.reports
      .create({ ...data, userIds: selectedSwimmerIds })
      .then((res) => {
        setReports(res);
      })
      .catch((err) => {
        console.log('err', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        disabled={!selectedSwimmerIds.length}
        variant='contained'
        onClick={() => setOpen(true)}>
        Create Reports
      </Button>
      <Modal
        open={open}
        handleClose={() => {
          setReports(null);
          setOpen(false);
        }}>
        {reports ? (
          <ReportsCarousel reports={reports} />
        ) : (
          <CreateReports handleSubmit={submitCreateReports} loading={loading} />
        )}
      </Modal>
    </>
  );
};

export default SwimmerReportsButton;

const activityTypes = ['swimming', 'running'];

const CreateReports = ({ loading, handleSubmit }) => {
  const pt = 4;
  const [activityType, setActivityType] = useState('');
  const [reportTo, setreportTo] = useState(new Date());
  const [reportFrom, setreportFrom] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );

  const handleChange = (event) => {
    setActivityType(event.target.value);
  };

  const onSubmit = () => {
    const data = {
      activityType,
      reportFrom: reportFrom.toISOString(),
      reportTo: reportTo.toISOString()
    };
    handleSubmit(data);
  };

  return (
    <Box p={2}>
      <Typography variant='h4'>Create Reports</Typography>
      {/* <Box>
        <Typography variant='h6'>For:</Typography>
        <Typography>Swimmer 1</Typography>
      </Box> */}
      <Box pt={pt}>
        <Typography variant='h6' pb={1}>
          Select Activity:
        </Typography>
        <FormControl variant='standard' sx={{ minWidth: 160 }}>
          <InputLabel>Activity Type:</InputLabel>
          <Select value={activityType} onChange={handleChange} label='Activity Type:'>
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {activityTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {capitalize(type)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box pt={pt}>
        <Typography variant='h6' pb={1}>
          Set report data range:
        </Typography>
        <Stack direction='row' spacing={4}>
          <DatePickerField
            label='From:'
            onUpdate={(val) => setreportFrom(val)}
            value={reportFrom}
          />
          <DatePickerField label='To:' onUpdate={(val) => setreportTo(val)} value={reportTo} />
        </Stack>
      </Box>
      <Box textAlign={'right'} pt={pt}>
        <LoadingButton
          disabled={!activityType || !reportTo || !reportFrom}
          variant='contained'
          onClick={onSubmit}
          loading={loading}>
          Create Reports
        </LoadingButton>
      </Box>
    </Box>
  );
};

const constructReportFileName = (report) => {
  return `simma-${report.activityType}-report-${
    report.user?.name || report.user?.email || report.userId
  }-${new Date(report.reportFrom).toLocaleDateString()}-${new Date(
    report.reportTo
  ).toLocaleDateString()}`;
};

const ReportsCarousel = ({ reports }) => {
  const [index, setIndex] = useState(0);
  const handleBack = () => {
    const newIndex = index === 0 ? reports.length - 1 : index - 1;
    setIndex(newIndex);
  };
  const handleNext = () => {
    const newIndex = index === reports.length - 1 ? 0 : index + 1;
    setIndex(newIndex);
  };

  return (
    <Stack direction='row'>
      {reports.length > 1 && (
        <Box onClick={handleBack} pt={12} pl={1} pr={1} sx={{ cursor: 'pointer' }}>
          <ArrowBackIcon />
        </Box>
      )}
      <Box sx={{ flex: 1 }} pt={4}>
        <Container>
          <Typography variant='h4' mb={2}>
            View Reports
          </Typography>
          <Box textAlign='right'>
            <DownloadPdfButton
              fileName={constructReportFileName(reports[index])}
              component={SwimmerReport}
              componentProps={{ report: reports[index] }}
            />
          </Box>
        </Container>
        <SwimmerReport report={reports[index]} />
      </Box>
      {reports.length > 1 && (
        <Box onClick={handleNext} pt={12} pl={1} pr={1} sx={{ cursor: 'pointer' }}>
          <ArrowForwardIcon />
        </Box>
      )}
    </Stack>
  );
};
