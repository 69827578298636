import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const ActivitiesIcon = (props) => {
	return (
		<SvgIcon
			{ ...props }
			style={ {
				fillRule: "evenodd",
				clipRule: "evenodd",
			} }
		>
			<path
				d="M7 0C6.44772 0 6 0.447715 6 1V4H8V1C8 0.447715 7.55228 0 7 0ZM3 2C1.34315 2 0 3.34315 0 5V19C0 20.6569 1.34315 22 3 22H21C22.6569 22 24 20.6569 24 19V5C24 3.34315 22.6569 2 21 2H19V4C19 4.55228 18.5523 5 18 5H16C15.4477 5 15 4.55228 15 4V2H9V4C9 4.55228 8.55229 5 8 5H6C5.44772 5 5 4.55228 5 4V2H3ZM16 1C16 0.447715 16.4477 0 17 0C17.5523 0 18 0.447715 18 1V4H16V1ZM22 8H2V19C2 19.5523 2.44772 20 3 20H21C21.5523 20 22 19.5523 22 19V8Z"/>
		</SvgIcon>
	);
};

export default ActivitiesIcon;
