import { useState } from 'react';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import api from 'api';
import { useAlertContext } from 'components/alert/AlertContext';

const defaultFormData = {
  name: '',
  email: '',
  message: '',
  type: 'coachContactForm'
};

const CoachContactForm = ({ sx, ...other }) => {
  const { showAlert } = useAlertContext();
  const [formData, setFormData] = useState(defaultFormData);
  const [isLoading, setIsLoading] = useState(false);
  const handleOnChange = (e) =>
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));

  const handleOnSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      await api.contact.send(formData);
      showAlert("Message received! We'll get back to you shortly.", { severity: 'success' });
      setFormData(defaultFormData);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      showAlert('Something went wrong. Please try again.', { severity: 'error' });
    }
  };

  return (
    <Stack
      sx={{ textAlign: 'center', ...sx }}
      onSubmit={handleOnSubmit}
      component='form'
      spacing={4}
      {...other}>
      <Box>
        <Typography sx={{ mb: 2 }} variant='h3'>
          Want to learn more?
        </Typography>
        <Typography variant='subtitle1'>
          Send us a message and we'll clear up an questions you might have about coaching on Simma.
        </Typography>
      </Box>

      <Stack spacing={3}>
        <Box>
          <TextField
            required
            fullWidth
            label='Name'
            name='name'
            variant='filled'
            value={formData.name}
            onChange={handleOnChange}
          />
        </Box>

        <Box>
          <TextField
            required
            fullWidth
            type='email'
            label='Email'
            name='email'
            variant='filled'
            value={formData.email}
            onChange={handleOnChange}
          />
        </Box>

        <Box>
          <TextField
            required
            fullWidth
            label='Tell us what you are looking to achieve?'
            name='message'
            variant='filled'
            multiline
            rows={4}
            value={formData.message}
            onChange={handleOnChange}
          />
        </Box>
      </Stack>

      <Box>
        <LoadingButton
          loading={isLoading}
          fullWidth
          variant='contained'
          onClick={handleOnSubmit}
          disabled={!formData.email || !formData.name || !formData.message}>
          Send
        </LoadingButton>
      </Box>
    </Stack>
  );
};

export default CoachContactForm;
