const correlationKeys = ['crossoverAngle', 'entryAngle', 'strokeRate', 'splashScore', 'forceScore'];

const strokeTimingKeys = [
  'glideTime',
  'downsweepTime',
  'insweepTime',
  'upsweepTime',
  'recoveryTime'
];

export const CHART_CONSTANTS = {
  labelHeight: 60
};

export const parseChartData = (activity) => {
  if (!activity.analytics || !Object.keys(activity.analytics.sensorFeatures).length) return null;
  const data = {};
  data.keys = Object.keys(activity.analytics.sensorFeatures.featuresLap.interpretable.raw);
  data.correlationKeys = correlationKeys.filter((key) => data.keys.includes(key));
  data.hasCorrelation = !!data.correlationKeys.length;
  data.strokeTimingKeys = strokeTimingKeys.filter((key) => data.keys.includes(key));
  data.hasStrokeTiming = !!data.strokeTimingKeys.length;

  data.lapDomain = [
    -0.5,
    activity.analytics.sensorFeatures.featuresLap.interpretable.raw.pace.length - 1 + 0.5
  ];
  data.lapRaw = parseLapRaw(activity);
  data.lapStandardised = parseLapStandardised(activity);

  data.hasStrokeData = !!activity.analytics.sensorFeatures.featuresStroke;

  if (data.hasStrokeData) {
    data.strokeDomain = [
      -0.5,
      activity.analytics.sensorFeatures.featuresStroke.interpretable.raw.pace.length - 1 + 0.5
    ];
    data.strokeRaw = parseStrokeRaw(activity);
    data.strokeStandardised = parseStrokeStandardised(activity);
  }

  data.annotations = activity.analytics.sensorFeatures.annotations;

  return data;
};

const parseLapRaw = (activity) => {
  let data = [];

  const key = activity.analytics.sensorFeatures.featuresLap.interpretable.raw;

  key.pace.forEach((item, i) => {
    data.push({ Pace: Math.round(item * 100) / 100, x: i });
  });

  key.hasOwnProperty('crossoverAngle') &&
    key.crossoverAngle.forEach((item, i) => {
      data[i]['Crossover angle'] = item;
    });

  key.hasOwnProperty('entryAngle') &&
    key.entryAngle.forEach((item, i) => {
      data[i]['Entry angle'] = item;
    });

  key.hasOwnProperty('strokeRate') &&
    key.strokeRate.forEach((item, i) => {
      data[i]['Stroke rate'] = item;
    });

  key.hasOwnProperty('splashScore') &&
    key.splashScore.forEach((item, i) => {
      data[i]['Splash score'] = item;
    });

  key.hasOwnProperty('forceScore') &&
    key.forceScore.forEach((item, i) => {
      data[i]['Force score'] = item;
    });

  key.hasOwnProperty('glideTime') &&
    key.glideTime.forEach((item, i) => {
      data[i].Glide = item;
    });

  key.hasOwnProperty('downsweepTime') &&
    key.downsweepTime.forEach((item, i) => {
      data[i].Downsweep = item;
    });

  key.hasOwnProperty('insweepTime') &&
    key.insweepTime.forEach((item, i) => {
      data[i].Insweep = item;
    });

  key.hasOwnProperty('upsweepTime') &&
    key.upsweepTime.forEach((item, i) => {
      data[i].Upsweep = item;
    });

  key.hasOwnProperty('recoveryTime') &&
    key.recoveryTime.forEach((item, i) => {
      data[i].Recovery = item;
    });

  return data;
};

const parseLapStandardised = (activity) => {
  let data = [];

  const key = activity.analytics.sensorFeatures.featuresLap.interpretable.standardised;

  activity.analytics.sensorFeatures.featuresLap.interpretable.raw.pace.forEach((item, i) => {
    data.push({ Pace: Math.round(item * 100) / 100, x: i });
  });

  if (!key) return data;

  key.hasOwnProperty('crossoverAngle') &&
    key.crossoverAngle.forEach((item, i) => {
      data[i]['Crossover angle'] = item;
    });

  key.hasOwnProperty('entryAngle') &&
    key.entryAngle.forEach((item, i) => {
      data[i]['Entry angle'] = item;
    });

  key.hasOwnProperty('strokeRate') &&
    key.strokeRate.forEach((item, i) => {
      data[i]['Stroke rate'] = item;
    });

  key.hasOwnProperty('splashScore') &&
    key.splashScore.forEach((item, i) => {
      data[i]['Splash score'] = item;
    });

  key.hasOwnProperty('forceScore') &&
    key.forceScore.forEach((item, i) => {
      data[i]['Force score'] = item;
    });

  key.hasOwnProperty('glideTime') &&
    key.glideTime.forEach((item, i) => {
      data[i].Glide = item;
    });

  key.hasOwnProperty('downsweepTime') &&
    key.downsweepTime.forEach((item, i) => {
      data[i].Downsweep = item;
    });

  key.hasOwnProperty('insweepTime') &&
    key.insweepTime.forEach((item, i) => {
      data[i].Insweep = item;
    });

  key.hasOwnProperty('upsweepTime') &&
    key.upsweepTime.forEach((item, i) => {
      data[i].Upsweep = item;
    });

  key.hasOwnProperty('recoveryTime') &&
    key.recoveryTime.forEach((item, i) => {
      data[i].Recovery = item;
    });

  return data;
};

const parseStrokeRaw = (activity) => {
  let data = [];

  const key = activity.analytics.sensorFeatures.featuresStroke.interpretable.raw;

  key.pace.forEach((item, i) => {
    data.push({ Pace: Math.round(item * 100) / 100, x: i });
  });

  key.hasOwnProperty('crossoverAngle') &&
    key.crossoverAngle.forEach((item, i) => {
      data[i]['Crossover angle'] = item;
    });

  key.hasOwnProperty('entryAngle') &&
    key.entryAngle.forEach((item, i) => {
      data[i]['Entry angle'] = item;
    });

  key.hasOwnProperty('strokeRate') &&
    key.strokeRate.forEach((item, i) => {
      data[i]['Stroke rate'] = item;
    });

  key.hasOwnProperty('splashScore') &&
    key.splashScore.forEach((item, i) => {
      data[i]['Splash score'] = item;
    });

  key.hasOwnProperty('forceScore') &&
    key.forceScore.forEach((item, i) => {
      data[i]['Force score'] = item;
    });

  key.hasOwnProperty('glideTime') &&
    key.glideTime.forEach((item, i) => {
      data[i].Glide = item;
    });

  key.hasOwnProperty('downsweepTime') &&
    key.downsweepTime.forEach((item, i) => {
      data[i].Downsweep = item;
    });

  key.hasOwnProperty('insweepTime') &&
    key.insweepTime.forEach((item, i) => {
      data[i].Insweep = item;
    });

  key.hasOwnProperty('upsweepTime') &&
    key.upsweepTime.forEach((item, i) => {
      data[i].Upsweep = item;
    });

  key.hasOwnProperty('recoveryTime') &&
    key.recoveryTime.forEach((item, i) => {
      data[i].Recovery = item;
    });

  return data;
};

const parseStrokeStandardised = (activity) => {
  let data = [];

  const key = activity.analytics.sensorFeatures.featuresStroke.interpretable.standardised;

  activity.analytics.sensorFeatures.featuresStroke.interpretable.raw.pace.forEach((item, i) => {
    data.push({ Pace: Math.round(item * 100) / 100, x: i });
  });

  if (!key) return data;

  key.hasOwnProperty('crossoverAngle') &&
    key.crossoverAngle.forEach((item, i) => {
      data[i]['Crossover angle'] = item;
    });

  key.hasOwnProperty('entryAngle') &&
    key.entryAngle.forEach((item, i) => {
      data[i]['Entry angle'] = item;
    });

  key.hasOwnProperty('strokeRate') &&
    key.strokeRate.forEach((item, i) => {
      data[i]['Stroke rate'] = item;
    });

  key.hasOwnProperty('splashScore') &&
    key.splashScore.forEach((item, i) => {
      data[i]['Splash score'] = item;
    });

  key.hasOwnProperty('forceScore') &&
    key.forceScore.forEach((item, i) => {
      data[i]['Force score'] = item;
    });

  key.hasOwnProperty('glideTime') &&
    key.glideTime.forEach((item, i) => {
      data[i].Glide = item;
    });

  key.hasOwnProperty('downsweepTime') &&
    key.downsweepTime.forEach((item, i) => {
      data[i].Downsweep = item;
    });

  key.hasOwnProperty('insweepTime') &&
    key.insweepTime.forEach((item, i) => {
      data[i].Insweep = item;
    });

  key.hasOwnProperty('upsweepTime') &&
    key.upsweepTime.forEach((item, i) => {
      data[i].Upsweep = item;
    });

  key.hasOwnProperty('recoveryTime') &&
    key.recoveryTime.forEach((item, i) => {
      data[i].Recovery = item;
    });

  return data;
};
