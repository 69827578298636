import CircularProgress from "@mui/material/CircularProgress";
const Spinner = ({ window, container, windowHeight }) => {
  const style = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "2rem",
  };

  if (!!window) {
    style.height = "100vh";
    style.width = "100vw";
  } else if (!!container) {
    style.height = "100%";
    style.width = "100%";
  }

  if (!!windowHeight) {
    style.height = "100vh";
  }

  return (
    <div style={style}>
      <CircularProgress color="primary" />
    </div>
  );
};
export default Spinner;
