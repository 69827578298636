const Fins = ({size = 41}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.39579 28.6823C16.2291 28.6823 30.75 26.2025 34.1666 25.3759C37.5833 24.5494 40.1458 22.7855 40.1458 19.5899C40.1458 16.3944 37.5833 14.6304 34.1666 13.8039C30.75 12.9773 16.2291 10.4976 9.39579 10.4976H4.47244C3.20249 10.4976 2.35661 11.8021 2.79349 12.956C3.66345 15.2538 4.2708 17.7924 4.2708 19.5899C4.2708 21.3874 3.66345 23.926 2.79349 26.2238C2.35661 27.3777 3.20249 28.6823 4.47244 28.6823H9.39579ZM37.5833 19.5899C37.5833 22.8962 33.3125 23.7228 28.1875 23.7228C25.8287 23.7228 23.9166 21.8724 23.9166 19.5899C23.9166 17.3074 25.8287 15.457 28.1875 15.457C32.4583 15.457 37.5833 16.2836 37.5833 19.5899ZM7.68746 18.7633C7.21572 18.7633 6.8333 19.1334 6.8333 19.5899C6.8333 20.0464 7.21572 20.4165 7.68746 20.4165H19.6458C20.1175 20.4165 20.5 20.0464 20.5 19.5899C20.5 19.1334 20.1175 18.7633 19.6458 18.7633H7.68746Z" fill="#EBEBF5" fillOpacity="0.6"/>
    </svg>
  );
}

// Fins.propTypes = {
//   : PropTypes.
// };

export default Fins;
