import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const ProgressIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
    >
      <g transform="matrix(0.921282,0,0,0.921282,0.023334,1.8659)">
        <path d="M2,15C2,14.448 2.448,14 3,14L5,14C5.552,14 6,14.448 6,15L6,18L2,18L2,15Z" />
      </g>
      <g transform="matrix(0.921282,0,0,0.921282,0.023334,1.8659)">
        <path d="M8,11C8,10.448 8.448,10 9,10L11,10C11.552,10 12,10.448 12,11L12,18L8,18L8,11Z" />
      </g>
      <g transform="matrix(0.921282,0,0,0.921282,0.023334,1.8659)">
        <path d="M14,7C14,6.448 14.448,6 15,6L17,6C17.552,6 18,6.448 18,7L18,18L14,18L14,7Z" />
      </g>
      <g transform="matrix(0.921282,0,0,0.921282,0.023334,1.8659)">
        <path d="M20,1C20,0.448 20.448,0 21,0L23,0C23.552,0 24,0.448 24,1L24,18L20,18L20,1Z" />
      </g>
      <g transform="matrix(0.921282,0,0,0.921282,0.023334,1.8659)">
        <path d="M26,21C26,20.448 25.552,20 25,20L1,20C0.448,20 0,20.448 0,21C0,21.552 0.448,22 1,22L25,22C25.552,22 26,21.552 26,21Z" />
      </g>
    </SvgIcon>
  );
};

export default ProgressIcon;