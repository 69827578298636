import React from 'react';
import {
	Bar,
	ComposedChart,
	Line,
	ResponsiveContainer,
	XAxis,
	YAxis
} from "recharts";
import { secondsToHMS } from "../../util";
import { metricLinesData } from "../charts/utils";
import { CHART_CONSTANTS } from "../charts/chartUtilities";
import { Box } from "@mui/material";

const labelHeight = CHART_CONSTANTS.labelHeight;

const UpgradeStateGraph = () => {
	return (
		<Box style={ { opacity: '0.4' } }>
			<ResponsiveContainer width="100%" height={ 247 }>
				<ComposedChart
					data={ mockedData }
					margin={ { top: 0, right: 0, left: 0, bottom: 0 } }
					syncId="anyId"
				>
					<XAxis
						type="number"
						dataKey="x"
						domain={ [-0.5, 61.5] }
						tick={ false }
						height={ labelHeight }
					/>
					<YAxis
						yAxisId="left"
						width={ 40 }
						tickFormatter={ (value) => secondsToHMS(value) }
					/>
					<YAxis yAxisId="right" orientation="right" width={ 40 }/>
					<Bar
						isAnimationActive={ false }
						dataKey="Pace"
						yAxisId="left"
						fill="#78848D"
					/>
					{ selectedMetrics.map(key => (
						<Line
							key={ key }
							activeDot={ { strokeWidth: 0, r: 5 } }
							isAnimationActive={ false }
							yAxisId="right"
							type="monotone"
							dataKey={ metricLinesData[key].label }
							stroke={ metricLinesData[key].color }
							strokeWidth={ 2 }
							dot={ false }
						/>
					)) }
				</ComposedChart>
			</ResponsiveContainer>
		</Box>
	);
};

export default UpgradeStateGraph;

const selectedMetrics = ['crossoverAngle', 'entryAngle', 'strokeRate', 'splashScore', 'forceScore']


const mockedData = [
	{
		"Pace": 54.08,
		"x": 0,
		"Crossover angle": 0.833,
		"Entry angle": 0.678,
		"Stroke rate": 0.2,
		"Splash score": 0.47,
		"Force score": 0.6,
		"Glide": 0.56,
		"Downsweep": 0.8,
		"Insweep": 0.17,
		"Upsweep": 0.27,
		"Recovery": 1.01
	},
	{
		"Pace": 79.95,
		"x": 1,
		"Crossover angle": 0.8,
		"Entry angle": 0.728,
		"Stroke rate": 0.2,
		"Splash score": 0.42,
		"Force score": 0.6,
		"Glide": 0.62,
		"Downsweep": 0.78,
		"Insweep": 0.17,
		"Upsweep": 0.28,
		"Recovery": 1.03
	},
	{
		"Pace": 89.81,
		"x": 2,
		"Crossover angle": 0.867,
		"Entry angle": 0.739,
		"Stroke rate": 0.2,
		"Splash score": 0.48,
		"Force score": 0.56,
		"Glide": 0.57,
		"Downsweep": 0.8,
		"Insweep": 0.17,
		"Upsweep": 0.27,
		"Recovery": 1.02
	},
	{
		"Pace": 83.87,
		"x": 3,
		"Crossover angle": 0.717,
		"Entry angle": 0.822,
		"Stroke rate": 0.1,
		"Splash score": 0.29,
		"Force score": 0.4,
		"Glide": 0.11,
		"Downsweep": 0.97,
		"Insweep": 0.17,
		"Upsweep": 0.47,
		"Recovery": 1.4
	},
	{
		"Pace": 89.59,
		"x": 4,
		"Crossover angle": 0.833,
		"Entry angle": 0.794,
		"Stroke rate": 0.2,
		"Splash score": 0.45,
		"Force score": 0.6,
		"Glide": 0.62,
		"Downsweep": 0.83,
		"Insweep": 0.16,
		"Upsweep": 0.27,
		"Recovery": 1.02
	},
	{
		"Pace": 94.49,
		"x": 5,
		"Crossover angle": 0.783,
		"Entry angle": 0.722,
		"Stroke rate": 0.2,
		"Splash score": 0.54,
		"Force score": 0.58,
		"Glide": 0.56,
		"Downsweep": 0.81,
		"Insweep": 0.16,
		"Upsweep": 0.27,
		"Recovery": 1.05
	},
	{
		"Pace": 67.03,
		"x": 6,
		"Crossover angle": 0.983,
		"Entry angle": 0.628,
		"Stroke rate": 0.2,
		"Splash score": 0.46,
		"Force score": 0.66,
		"Glide": 0.46,
		"Downsweep": 0.92,
		"Insweep": 0.21,
		"Upsweep": 0.21,
		"Recovery": 1.04
	},
	{
		"Pace": 87.6,
		"x": 7,
		"Crossover angle": 0.95,
		"Entry angle": 0.622,
		"Stroke rate": 0.217,
		"Splash score": 0.4,
		"Force score": 0.58,
		"Glide": 0.55,
		"Downsweep": 0.86,
		"Insweep": 0.17,
		"Upsweep": 0.2,
		"Recovery": 1.02
	},
	{
		"Pace": 88.06,
		"x": 8,
		"Crossover angle": 1.017,
		"Entry angle": 0.589,
		"Stroke rate": 0.217,
		"Splash score": 0.63,
		"Force score": 0.56,
		"Glide": 0.5,
		"Downsweep": 0.85,
		"Insweep": 0.13,
		"Upsweep": 0.2,
		"Recovery": 1.08
	},
	{
		"Pace": 93.94,
		"x": 9,
		"Crossover angle": 1.133,
		"Entry angle": 0.6,
		"Stroke rate": 0.233,
		"Splash score": 0.6,
		"Force score": 0.56,
		"Glide": 0.51,
		"Downsweep": 0.82,
		"Insweep": 0.14,
		"Upsweep": 0.15,
		"Recovery": 1.06
	},
	{
		"Pace": 80.08,
		"x": 10,
		"Crossover angle": 1.017,
		"Entry angle": 0.583,
		"Stroke rate": 0.283,
		"Splash score": 0.86,
		"Force score": 0.5,
		"Glide": 0.44,
		"Downsweep": 0.84,
		"Insweep": 0.2,
		"Upsweep": 0.2,
		"Recovery": 0.95
	},
	{
		"Pace": 79.25,
		"x": 11,
		"Crossover angle": 1.267,
		"Entry angle": 0.617,
		"Stroke rate": 0.25,
		"Splash score": 0.74,
		"Force score": 0.48,
		"Glide": 0.47,
		"Downsweep": 0.83,
		"Insweep": 0.19,
		"Upsweep": 0.15,
		"Recovery": 0.93
	},
	{
		"Pace": 81.06,
		"x": 12,
		"Crossover angle": 1.167,
		"Entry angle": 0.589,
		"Stroke rate": 0.267,
		"Splash score": 0.9,
		"Force score": 0.48,
		"Glide": 0.46,
		"Downsweep": 0.88,
		"Insweep": 0.17,
		"Upsweep": 0.18,
		"Recovery": 0.9
	},
	{
		"Pace": 79.05,
		"x": 13,
		"Crossover angle": 1.067,
		"Entry angle": 0.589,
		"Stroke rate": 0.267,
		"Splash score": 0.95,
		"Force score": 0.48,
		"Glide": 0.46,
		"Downsweep": 0.91,
		"Insweep": 0.15,
		"Upsweep": 0.19,
		"Recovery": 0.9
	},
	{
		"Pace": 95.31,
		"x": 14,
		"Crossover angle": 0.85,
		"Entry angle": 0.689,
		"Stroke rate": 0.417,
		"Splash score": 0.69,
		"Force score": 0.62,
		"Glide": 0.38,
		"Downsweep": 0.62,
		"Insweep": 0.17,
		"Upsweep": 0.26,
		"Recovery": 0.76
	},
	{
		"Pace": 96.13,
		"x": 15,
		"Crossover angle": 0.833,
		"Entry angle": 0.75,
		"Stroke rate": 0.367,
		"Splash score": 0.7,
		"Force score": 0.6,
		"Glide": 0.41,
		"Downsweep": 0.66,
		"Insweep": 0.17,
		"Upsweep": 0.27,
		"Recovery": 0.77
	},
	{
		"Pace": 85.94,
		"x": 16,
		"Crossover angle": 0.8,
		"Entry angle": 0.744,
		"Stroke rate": 0.383,
		"Splash score": 0.59,
		"Force score": 0.62,
		"Glide": 0.41,
		"Downsweep": 0.64,
		"Insweep": 0.17,
		"Upsweep": 0.3,
		"Recovery": 0.74
	},
	{
		"Pace": 98.78,
		"x": 17,
		"Crossover angle": 0.783,
		"Entry angle": 0.706,
		"Stroke rate": 0.483,
		"Splash score": 0.83,
		"Force score": 0.68,
		"Glide": 0.36,
		"Downsweep": 0.58,
		"Insweep": 0.15,
		"Upsweep": 0.29,
		"Recovery": 0.68
	},
	{
		"Pace": 110.28,
		"x": 18,
		"Crossover angle": 0.667,
		"Entry angle": 0.544,
		"Stroke rate": 0.433,
		"Splash score": 0.68,
		"Force score": 0.62,
		"Glide": 0.36,
		"Downsweep": 0.61,
		"Insweep": 0.15,
		"Upsweep": 0.27,
		"Recovery": 0.71
	},
	{
		"Pace": 97.03,
		"x": 19,
		"Crossover angle": 0.867,
		"Entry angle": 0.739,
		"Stroke rate": 0.45,
		"Splash score": 0.69,
		"Force score": 0.64,
		"Glide": 0.38,
		"Downsweep": 0.58,
		"Insweep": 0.17,
		"Upsweep": 0.29,
		"Recovery": 0.68
	},
	{
		"Pace": 89.33,
		"x": 20,
		"Crossover angle": 0.767,
		"Entry angle": 0.728,
		"Stroke rate": 0.417,
		"Splash score": 0.69,
		"Force score": 0.64,
		"Glide": 0.38,
		"Downsweep": 0.64,
		"Insweep": 0.16,
		"Upsweep": 0.29,
		"Recovery": 0.7
	},
	{
		"Pace": 89.35,
		"x": 21,
		"Crossover angle": 0.85,
		"Entry angle": 0.767,
		"Stroke rate": 0.5,
		"Splash score": 0.79,
		"Force score": 0.66,
		"Glide": 0.37,
		"Downsweep": 0.55,
		"Insweep": 0.16,
		"Upsweep": 0.3,
		"Recovery": 0.63
	},
	{
		"Pace": 86.6,
		"x": 22,
		"Crossover angle": 0.8,
		"Entry angle": 0.744,
		"Stroke rate": 0.45,
		"Splash score": 0.68,
		"Force score": 0.68,
		"Glide": 0.37,
		"Downsweep": 0.59,
		"Insweep": 0.14,
		"Upsweep": 0.27,
		"Recovery": 0.71
	},
	{
		"Pace": 91.6,
		"x": 23,
		"Crossover angle": 0.867,
		"Entry angle": 0.756,
		"Stroke rate": 0.467,
		"Splash score": 0.75,
		"Force score": 0.68,
		"Glide": 0.37,
		"Downsweep": 0.59,
		"Insweep": 0.15,
		"Upsweep": 0.3,
		"Recovery": 0.66
	},
	{
		"Pace": 75.68,
		"x": 24,
		"Crossover angle": 0.783,
		"Entry angle": 0.761,
		"Stroke rate": 0.6,
		"Splash score": 0.79,
		"Force score": 0.72,
		"Glide": 0.33,
		"Downsweep": 0.49,
		"Insweep": 0.12,
		"Upsweep": 0.34,
		"Recovery": 0.53
	},
	{
		"Pace": 93.44,
		"x": 25,
		"Crossover angle": 0.833,
		"Entry angle": 0.778,
		"Stroke rate": 0.583,
		"Splash score": 0.84,
		"Force score": 0.68,
		"Glide": 0.33,
		"Downsweep": 0.51,
		"Insweep": 0.14,
		"Upsweep": 0.3,
		"Recovery": 0.59
	},
	{
		"Pace": 87.98,
		"x": 26,
		"Crossover angle": 0.833,
		"Entry angle": 0.761,
		"Stroke rate": 0.467,
		"Splash score": 0.73,
		"Force score": 0.66,
		"Glide": 0.37,
		"Downsweep": 0.59,
		"Insweep": 0.14,
		"Upsweep": 0.28,
		"Recovery": 0.66
	},
	{
		"Pace": 94.04,
		"x": 27,
		"Crossover angle": 0.8,
		"Entry angle": 0.711,
		"Stroke rate": 0.483,
		"Splash score": 0.76,
		"Force score": 0.68,
		"Glide": 0.36,
		"Downsweep": 0.59,
		"Insweep": 0.15,
		"Upsweep": 0.29,
		"Recovery": 0.66
	},
	{
		"Pace": 80,
		"x": 28,
		"Crossover angle": 0.733,
		"Entry angle": 0.783,
		"Stroke rate": 0.617,
		"Splash score": 0.85,
		"Force score": 0.72,
		"Glide": 0.32,
		"Downsweep": 0.48,
		"Insweep": 0.13,
		"Upsweep": 0.32,
		"Recovery": 0.54
	},
	{
		"Pace": 88.14,
		"x": 29,
		"Crossover angle": 0.733,
		"Entry angle": 0.728,
		"Stroke rate": 0.6,
		"Splash score": 0.93,
		"Force score": 0.68,
		"Glide": 0.33,
		"Downsweep": 0.47,
		"Insweep": 0.14,
		"Upsweep": 0.3,
		"Recovery": 0.57
	},
	{
		"Pace": 83.49,
		"x": 30,
		"Crossover angle": 0.767,
		"Entry angle": 0.722,
		"Stroke rate": 0.483,
		"Splash score": 0.74,
		"Force score": 0.68,
		"Glide": 0.36,
		"Downsweep": 0.58,
		"Insweep": 0.13,
		"Upsweep": 0.33,
		"Recovery": 0.63
	},
	{
		"Pace": 87.9,
		"x": 31,
		"Crossover angle": 0.833,
		"Entry angle": 0.728,
		"Stroke rate": 0.5,
		"Splash score": 0.84,
		"Force score": 0.66,
		"Glide": 0.36,
		"Downsweep": 0.57,
		"Insweep": 0.15,
		"Upsweep": 0.3,
		"Recovery": 0.63
	},
	{
		"Pace": 79.75,
		"x": 32,
		"Crossover angle": 0.767,
		"Entry angle": 0.783,
		"Stroke rate": 0.65,
		"Splash score": 0.83,
		"Force score": 0.72,
		"Glide": 0.31,
		"Downsweep": 0.46,
		"Insweep": 0.12,
		"Upsweep": 0.33,
		"Recovery": 0.52
	},
	{
		"Pace": 91.62,
		"x": 33,
		"Crossover angle": 0.767,
		"Entry angle": 0.739,
		"Stroke rate": 0.6,
		"Splash score": 0.97,
		"Force score": 0.68,
		"Glide": 0.33,
		"Downsweep": 0.49,
		"Insweep": 0.13,
		"Upsweep": 0.31,
		"Recovery": 0.56
	},
	{
		"Pace": 85.94,
		"x": 34,
		"Crossover angle": 0.783,
		"Entry angle": 0.733,
		"Stroke rate": 0.533,
		"Splash score": 0.81,
		"Force score": 0.68,
		"Glide": 0.35,
		"Downsweep": 0.53,
		"Insweep": 0.15,
		"Upsweep": 0.28,
		"Recovery": 0.64
	},
	{
		"Pace": 95.31,
		"x": 35,
		"Crossover angle": 0.85,
		"Entry angle": 0.733,
		"Stroke rate": 0.517,
		"Splash score": 0.77,
		"Force score": 0.64,
		"Glide": 0.35,
		"Downsweep": 0.55,
		"Insweep": 0.15,
		"Upsweep": 0.3,
		"Recovery": 0.62
	},
	{
		"Pace": 78.71,
		"x": 36,
		"Crossover angle": 0.767,
		"Entry angle": 0.767,
		"Stroke rate": 0.65,
		"Splash score": 0.89,
		"Force score": 0.72,
		"Glide": 0.28,
		"Downsweep": 0.48,
		"Insweep": 0.13,
		"Upsweep": 0.33,
		"Recovery": 0.52
	},
	{
		"Pace": 90.49,
		"x": 37,
		"Crossover angle": 0.75,
		"Entry angle": 0.75,
		"Stroke rate": 0.633,
		"Splash score": 0.92,
		"Force score": 0.7,
		"Glide": 0.32,
		"Downsweep": 0.47,
		"Insweep": 0.13,
		"Upsweep": 0.31,
		"Recovery": 0.54
	},
	{
		"Pace": 36.08,
		"x": 38,
		"Crossover angle": 0.733,
		"Entry angle": 0.794,
		"Stroke rate": 0.083,
		"Splash score": 0.29,
		"Force score": 0.4,
		"Glide": 0.13,
		"Downsweep": 1.09,
		"Insweep": 0.21,
		"Upsweep": 0.9,
		"Recovery": 1.13
	},
	{
		"Pace": 98.02,
		"x": 39,
		"Crossover angle": 0.9,
		"Entry angle": 0.75,
		"Stroke rate": 0.233,
		"Splash score": 0.51,
		"Force score": 0.56,
		"Glide": 0.56,
		"Downsweep": 0.78,
		"Insweep": 0.16,
		"Upsweep": 0.28,
		"Recovery": 0.94
	},
	{
		"Pace": 83.57,
		"x": 40,
		"Crossover angle": 0.8,
		"Entry angle": 0.8,
		"Stroke rate": 0.433,
		"Splash score": 0.71,
		"Force score": 0.66,
		"Glide": 0.39,
		"Downsweep": 0.63,
		"Insweep": 0.15,
		"Upsweep": 0.32,
		"Recovery": 0.68
	},
	{
		"Pace": 91.11,
		"x": 41,
		"Crossover angle": 0.85,
		"Entry angle": 0.711,
		"Stroke rate": 0.467,
		"Splash score": 0.79,
		"Force score": 0.68,
		"Glide": 0.38,
		"Downsweep": 0.6,
		"Insweep": 0.14,
		"Upsweep": 0.31,
		"Recovery": 0.67
	},
	{
		"Pace": 78.39,
		"x": 42,
		"Crossover angle": 0.733,
		"Entry angle": 0.767,
		"Stroke rate": 0.667,
		"Splash score": 0.77,
		"Force score": 0.74,
		"Glide": 0.32,
		"Downsweep": 0.44,
		"Insweep": 0.13,
		"Upsweep": 0.34,
		"Recovery": 0.5
	},
	{
		"Pace": 86.94,
		"x": 43,
		"Crossover angle": 0.8,
		"Entry angle": 0.783,
		"Stroke rate": 0.6,
		"Splash score": 0.99,
		"Force score": 0.7,
		"Glide": 0.31,
		"Downsweep": 0.5,
		"Insweep": 0.12,
		"Upsweep": 0.33,
		"Recovery": 0.54
	},
	{
		"Pace": 82.87,
		"x": 44,
		"Crossover angle": 0.8,
		"Entry angle": 0.794,
		"Stroke rate": 0.55,
		"Splash score": 0.74,
		"Force score": 0.7,
		"Glide": 0.35,
		"Downsweep": 0.52,
		"Insweep": 0.12,
		"Upsweep": 0.32,
		"Recovery": 0.57
	},
	{
		"Pace": 91.94,
		"x": 45,
		"Crossover angle": 0.8,
		"Entry angle": 0.772,
		"Stroke rate": 0.55,
		"Splash score": 0.76,
		"Force score": 0.66,
		"Glide": 0.34,
		"Downsweep": 0.54,
		"Insweep": 0.13,
		"Upsweep": 0.33,
		"Recovery": 0.57
	},
	{
		"Pace": 75,
		"x": 46,
		"Crossover angle": 0.733,
		"Entry angle": 0.783,
		"Stroke rate": 0.667,
		"Splash score": 0.97,
		"Force score": 0.74,
		"Glide": 0.29,
		"Downsweep": 0.47,
		"Insweep": 0.13,
		"Upsweep": 0.33,
		"Recovery": 0.51
	},
	{
		"Pace": 86.42,
		"x": 47,
		"Crossover angle": 0.833,
		"Entry angle": 0.806,
		"Stroke rate": 0.633,
		"Splash score": 0.93,
		"Force score": 0.68,
		"Glide": 0.31,
		"Downsweep": 0.49,
		"Insweep": 0.13,
		"Upsweep": 0.33,
		"Recovery": 0.53
	},
	{
		"Pace": 85.27,
		"x": 48,
		"Crossover angle": 0.8,
		"Entry angle": 0.806,
		"Stroke rate": 0.5,
		"Splash score": 0.79,
		"Force score": 0.68,
		"Glide": 0.36,
		"Downsweep": 0.59,
		"Insweep": 0.13,
		"Upsweep": 0.3,
		"Recovery": 0.63
	},
	{
		"Pace": 89.21,
		"x": 49,
		"Crossover angle": 0.8,
		"Entry angle": 0.778,
		"Stroke rate": 0.5,
		"Splash score": 0.78,
		"Force score": 0.66,
		"Glide": 0.37,
		"Downsweep": 0.58,
		"Insweep": 0.15,
		"Upsweep": 0.31,
		"Recovery": 0.61
	},
	{
		"Pace": 74.04,
		"x": 50,
		"Crossover angle": 0.7,
		"Entry angle": 0.828,
		"Stroke rate": 0.7,
		"Splash score": 0.81,
		"Force score": 0.72,
		"Glide": 0.28,
		"Downsweep": 0.46,
		"Insweep": 0.12,
		"Upsweep": 0.32,
		"Recovery": 0.5
	},
	{
		"Pace": 103.82,
		"x": 51,
		"Crossover angle": 0.833,
		"Entry angle": 0.817,
		"Stroke rate": 0.633,
		"Splash score": 0.89,
		"Force score": 0.7,
		"Glide": 0.32,
		"Downsweep": 0.49,
		"Insweep": 0.13,
		"Upsweep": 0.33,
		"Recovery": 0.51
	},
	{
		"Pace": 47.18,
		"x": 52,
		"Crossover angle": 1.067,
		"Entry angle": 0.806,
		"Stroke rate": 0.067,
		"Splash score": 0.28,
		"Force score": 0.34,
		"Glide": 0.12,
		"Downsweep": 1.08,
		"Insweep": 0.13,
		"Upsweep": 0.72,
		"Recovery": 1.41
	},
	{
		"Pace": 97.26,
		"x": 53,
		"Crossover angle": 0.983,
		"Entry angle": 0.806,
		"Stroke rate": 0.267,
		"Splash score": 0.5,
		"Force score": 0.58,
		"Glide": 0.52,
		"Downsweep": 0.78,
		"Insweep": 0.15,
		"Upsweep": 0.28,
		"Recovery": 0.91
	},
	{
		"Pace": 83.59,
		"x": 54,
		"Crossover angle": 0.783,
		"Entry angle": 0.744,
		"Stroke rate": 0.5,
		"Splash score": 0.66,
		"Force score": 0.7,
		"Glide": 0.37,
		"Downsweep": 0.56,
		"Insweep": 0.13,
		"Upsweep": 0.33,
		"Recovery": 0.59
	},
	{
		"Pace": 91.46,
		"x": 55,
		"Crossover angle": 0.833,
		"Entry angle": 0.706,
		"Stroke rate": 0.467,
		"Splash score": 0.81,
		"Force score": 0.66,
		"Glide": 0.38,
		"Downsweep": 0.6,
		"Insweep": 0.15,
		"Upsweep": 0.3,
		"Recovery": 0.65
	},
	{
		"Pace": 79.53,
		"x": 56,
		"Crossover angle": 0.733,
		"Entry angle": 0.839,
		"Stroke rate": 0.683,
		"Splash score": 0.92,
		"Force score": 0.74,
		"Glide": 0.29,
		"Downsweep": 0.47,
		"Insweep": 0.12,
		"Upsweep": 0.33,
		"Recovery": 0.48
	},
	{
		"Pace": 87.06,
		"x": 57,
		"Crossover angle": 0.833,
		"Entry angle": 0.822,
		"Stroke rate": 0.6,
		"Splash score": 0.79,
		"Force score": 0.66,
		"Glide": 0.32,
		"Downsweep": 0.51,
		"Insweep": 0.12,
		"Upsweep": 0.33,
		"Recovery": 0.53
	},
	{
		"Pace": 84.67,
		"x": 58,
		"Crossover angle": 0.783,
		"Entry angle": 0.778,
		"Stroke rate": 0.533,
		"Splash score": 0.71,
		"Force score": 0.7,
		"Glide": 0.35,
		"Downsweep": 0.55,
		"Insweep": 0.13,
		"Upsweep": 0.32,
		"Recovery": 0.58
	},
	{
		"Pace": 89.93,
		"x": 59,
		"Crossover angle": 0.8,
		"Entry angle": 0.778,
		"Stroke rate": 0.533,
		"Splash score": 0.69,
		"Force score": 0.68,
		"Glide": 0.36,
		"Downsweep": 0.56,
		"Insweep": 0.14,
		"Upsweep": 0.31,
		"Recovery": 0.61
	},
	{
		"Pace": 79.37,
		"x": 60,
		"Crossover angle": 0.717,
		"Entry angle": 0.817,
		"Stroke rate": 0.667,
		"Splash score": 0.86,
		"Force score": 0.76,
		"Glide": 0.31,
		"Downsweep": 0.45,
		"Insweep": 0.13,
		"Upsweep": 0.32,
		"Recovery": 0.51
	},
	{
		"Pace": 84.43,
		"x": 61,
		"Crossover angle": 0.833,
		"Entry angle": 0.833,
		"Stroke rate": 0.717,
		"Splash score": 0.93,
		"Force score": 0.72,
		"Glide": 0.29,
		"Downsweep": 0.45,
		"Insweep": 0.12,
		"Upsweep": 0.33,
		"Recovery": 0.49
	}

]
