import mixpanel from 'mixpanel-browser';

if (process.env.REACT_APP_MIXPANEL_TOKEN) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
  mixpanel.register({ appPlatform: 'web' });
}

let track = !!process.env.REACT_APP_MIXPANEL_TOKEN;

export const identifyUser = (userId) => {
  if (!track) return;
  mixpanel.identify(userId);
};

export const trackUserSignup = (userId) => {
  if (!track) return;
  mixpanel.alias(userId);
  mixpanel.track('signUp');
};

export const trackUserLogout = () => {
  if (track) {
    mixpanel.track('logout');
    mixpanel.reset();
  }
};

export const trackSendPostSwimLog = (sentiment, comment) => {
  if (!track) return;
  mixpanel.track('sendPostSwimLog', {
    swimLogSentiment: sentiment,
    swimLogComment: !!comment
  });
};

export const trackViewMotionMetric = (motionMetric, isCoach = false) => {
  if (!track) return;
  mixpanel.track('viewMotionMetric', { motionMetric, isCoach });
};

export const trackOpenChat = (coachId) => {
  if (!track) return;
  mixpanel.track('openChat');
};

export const trackSendMessage = (props) => {
  if (!track) return;
  mixpanel.track('sendChatMessage', props);
};

export const trackUpdateFtp = (ftp) => {
  if (!track) return;
  mixpanel.track('editFTP', { ftp });
};

export const trackUpdatePoolPreference = (lapLength, lapUnit) => {
  if (!track) return;
  const props = {};
  if (!!lapLength) {
    props.lapLength = lapLength;
  }
  if (!!lapUnit) {
    props.lapUnit = lapUnit;
  }
  mixpanel.track('editPoolPreference', props);
};

export const trackSendCoachRequest = (coachId) => {
  if (!track) return;
  mixpanel.track('sendCoachRequest', { coachId });
};

export const trackRespondToCoachRequest = (acceptCoachRequest, coachId) => {
  if (!track) return;
  mixpanel.track('respondToCoachRequest', { coachId, acceptCoachRequest });
};

export const trackNavigateTo = (path) => {
  if (!track) return;
  const uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;
  const pathName = path.replaceAll(uuidRegex, 'uuid');
  mixpanel.track('navigateTo', { pathName });
};

// Coaches
//Sets
export const trackStartCreateSet = () => {
  if (!track) return;
  // mixpanel.track("coachStartCreateSet");
};

export const trackEditSet = () => {
  if (!track) return;
  // mixpanel.track("coachEditSet");
};

export const trackSaveSet = (properties) => {
  if (!track) return;
  mixpanel.track('coachSaveSet', properties);
};

export const trackDeleteSet = () => {
  if (!track) return;
  mixpanel.track('coachDeleteSet');
};

// Workouts
export const trackStartCreateWorkout = () => {
  if (!track) return;
  // mixpanel.track("coachStartCreateWorkout");
};

export const trackAddSetToWorkout = (properties) => {
  if (!track) return;
  mixpanel.track('coachAddSetToWorkout', properties);
};

export const trackRemoveSetFromWorkout = () => {
  if (!track) return;
  mixpanel.track('coachRemoveSetFromWorkout');
};

export const trackEditWorkout = () => {
  if (!track) return;
  // mixpanel.track("coachEditWorkout");
};

export const trackSaveWorkout = (properties) => {
  if (!track) return;
  mixpanel.track('coachSaveWorkout', properties);
};

export const trackDeleteWorkout = () => {
  if (!track) return;
  mixpanel.track('coachDeleteWorkout');
};

export const trackSaveCoachProfile = (properties) => {
  if (!track) return;
  mixpanel.track('coachSaveProfile', properties);
};

export const trackUseCoupon = (couponCode) => {
  if (!track) return;
  mixpanel.track('useCouponCode', { couponCode });
};
