import {useRef, useEffect, useState} from "react";
import {Box, Stack, Typography} from "@mui/material";
import html2canvas from "html2canvas";

const gradient =
  "linear-gradient(90deg, #00CDFF 0%, #1ADEA3 14.28%, #E4F14F 28.57%, #F38D2E 71.42%, #F1584F 93%)";

const calcualteFormScorePercentage = (formScore) => {
  const percent = ((formScore * -1 + 35) / 70) * 100;
  if (percent < 0) {
    return 0;
  } else if (percent > 100) {
    return 100;
  } else {
    return percent;
  }
};

const FormMeter = ({formScore, isMobile}) => {
  const colorDiv = useRef();
  const [color, setColor] = useState("transparent");
  const percent = calcualteFormScorePercentage(formScore);

  useEffect(() => {
    html2canvas(colorDiv.current).then((canvas) => {
      const ctx = canvas.getContext("2d");
      const {width, height} = canvas;
      let x = (width * percent) / 100;
      if (x === 0) {
        x += 1;
      }
      if (x >= width) {
        x = width - 1;
      }
      const data = ctx.getImageData(x, height / 2, 1, 1).data;
      setColor(`rgb(${data[0]}, ${data[1]}, ${data[2]})`);
    });
  }, [formScore, percent]);

  return (
    <>
      <Box sx={{position: "relative", paddingX: isMobile ? '0' : '3rem'}}>
        <Box
          sx={{
            transform: "translate(-50%)",
            display: "inline-block",
            marginLeft: `${percent}%`,
          }}
        >
          <Stack sx={{alignItems: "center"}}>
            <Typography
              color={color}
              fontWeight={400}
              textAlign={"center"}
              letterSpacing={"0.35px"}
              lineHeight={"150%"}
              variant={isMobile ? 'subtitle2' : 'h6'}
            >
              {parseFormScoreLabel(formScore)}
            </Typography>
            <Typography
              color={color}
              fontWeight={400}
              textAlign={"center"}
              letterSpacing={"0.35px"}
            >
              {formScore.toFixed(2)}
            </Typography>
            <Box
              sx={{
                height: "24px",
                width: "20px",
                background: color,
                borderRadius: "4px 4px 0px 0px",
              }}
            />
            <Box
              sx={{
                width: 0,
                height: 0,
                borderLeft: "10px solid transparent",
                borderRight: "10px solid transparent",
                borderTop: `10px solid ${color}`,
              }}
            />
          </Stack>
        </Box>
      </Box>
      <Box sx={{paddingX: isMobile ? '0' : '3rem'}}>
        <Box
          ref={colorDiv}
          sx={{
            background: gradient,
            borderRadius: "30px",
            height: "42.88px",
            mb: '10px',
          }}
        />
        <Stack direction="row" mb={'3px'}>
          <LabelSection isMobile={isMobile} label="35" left/>
          <LabelSection isMobile={isMobile} label="25"/>
          <LabelSection isMobile={isMobile} label="15"/>
          <LabelSection isMobile={isMobile} label="5"/>
          <LabelSection isMobile={isMobile} label="-5"/>
          <LabelSection isMobile={isMobile} label="-15"/>
          <LabelSection isMobile={isMobile} label="-25" endLabel="-35"/>
        </Stack>
        <Stack direction="row" justifyContent={'space-between'}>
          <LabelArea isMobile={isMobile} range={10} label="Decline" left/>
          <LabelArea isMobile={isMobile} range={10} label="Taper"/>
          <LabelArea isMobile={isMobile} range={25} label="Coast"/>
          <LabelArea isMobile={isMobile} range={20} label="Grind"/>
          <LabelArea isMobile={isMobile} range={5} label="Strain" right/>
        </Stack>
      </Box>
      <Typography variant="body1" color="text.secondary" sx={{mt: '8px', textAlign: 'center'}}>
        {parseFormScoreText(formScore)}
      </Typography>
    </>
  );
};
export default FormMeter;

const LabelSection = ({label, endLabel, left, isMobile}) => {
  return (
    <Stack
      direction="row"
      sx={{
        width: `${100 / 7}%`,
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "inline-block",
          transform: left ? null : "translate(-50%)",
        }}
      >
        <Label label={label} isMobile={isMobile}/>
      </Box>
      {!!endLabel && (
        <Box sx={{display: "inline-block"}}>
          <Label label={endLabel} isMobile={isMobile}/>
        </Box>
      )}
    </Stack>
  );
};

const LabelArea = ({range, label, left, right, isMobile}) => {
  return (
    <Stack
      direction="row"
      sx={{
        // width: `${ (range / 70) * 100 }%`,
        justifyContent: left ? "flex-start" : right ? "flex-end" : "center",
      }}
    >
      <Box
        sx={{
          display: "inline-block",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        <Label label={label} isMobile={isMobile}/>
      </Box>
    </Stack>
  );
};

const Label = ({label, isMobile}) => {
  return (
    <Typography
      sx={{opacity: 0.8}}
      variant={isMobile ? 'caption' : 'body2'}
    >
      {label}
    </Typography>
  );
};

const parseFormScoreLabel = (score) => {
  if (score < -30) {
    return "Straining";
  } else if (score < -10) {
    return "Grinding";
  } else if (score < 15) {
    return "Coasting";
  } else if (score < 25) {
    return "Tapering";
  } else {
    return "Deconditioning";
  }
};

const parseFormScoreText = (score) => {
  if (score < -30) {
    return "You've been pushing too hard, take it easy or you risk injury.";
  } else if (score < -10) {
    return "You're in the golden zone of rapid fitness improvement.";
  } else if (score < 15) {
    return "You can maintain your fitness level without getting fatigued if you stay in this zone.";
  } else if (score < 25) {
    return "You're ready to race!";
  } else {
    return "You're losing fitness in this zone, time to get back out there.";
  }
};
