import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => {
	return {
		root: {
			maxWidth: '910px',
			backgroundColor: theme.palette.backgrounds['2t'],
			padding: '20px',
			borderRadius: '14px'
		},
		title: {
			marginBottom: '24px',
			flexDirection: 'row'
		}
	}
})
