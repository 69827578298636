import {
    Box, Container, Stack, styled, Typography
  } from "@mui/material";
  import {useResponsive} from "../../../hooks";

  const StyledRoot = styled('div')(({ theme }) => ({
    padding: '100px 0',
    textAlign: 'center',
    backgroundColor: theme.palette.backgrounds['2t'],
    overflowX: 'hidden',
  }));

  const Image = styled('img')({
    height: 'auto',
    margin: 'auto',
    maxWidth: '100%',
  });

  const activityData = [
    {
        title: "Swimming",
        description: "Pool or open water native swim tracking with automatic stroke, rest and segment detection.",
        imageSrc: "/images/landing/activity-swimming.png",
    },
    {
        title: "Running",
        description: "See your splits, elevation gain and loss, and a map trace.",
        imageSrc: "/images/landing/activity-running.png",
    },
    {
        title: "Cycling",
        description: "See your routes, elevation gain/loss, segment times and where you gain or lose power.",
        imageSrc: "/images/landing/activity-cycling.png",
    },
    {
        title: "General",

        description: "Track your hikes, walks, and other activities. Import from Apple Health and Garmin.",
        imageSrc: "/images/landing/activity-general.png",
    },
    ]

const LandingActivitiesSection = () => {
  return (
    <StyledRoot>
  
      <Typography sx={{mb: 4}} variant="h3">All your activities in one place</Typography>
      <Typography sx={{maxWidth: 980, mx: 'auto', mb: 4}} variant="body1">Track within the app or import your activities from Apple and Garmin to see all your training together.</Typography>

      <Stack direction={"row"} sx={{justifyContent: "center"}} spacing={3}>
        {activityData.map((activity) => (
            <ActivityTypeElement imageSrc={activity.imageSrc} title={activity.title} description={activity.description} />
        ))
        }
        
      </Stack>
    
    </StyledRoot>
  )
}
export default LandingActivitiesSection

const ActivityTypeElement = ({imageSrc, title, description}) => {
    return (
        <Box width={250}>
            <Image sx={{mb: 2}}  src={imageSrc} alt="feature" />
            <Typography sx={{mb: 2}} variant="h4">{title}</Typography>
            <Typography>{description}</Typography>
        </Box>
    )
}