import {useContext, useState} from "react";
import {useMutation} from "react-query";
// @mui
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Stack,
  styled,
  TextField,
  Typography
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {alpha} from "@mui/material/styles";
// icons
import WhatshotIcon from '@mui/icons-material/Whatshot';
import DeleteIcon from "@mui/icons-material/Delete";
// components
import {CoachContext} from "../../../context";
import {useAlertContext} from "../../../components/alert/AlertContext";
import {ConfirmDialog} from "../../../components/confirm-dialog";
import {effortColors} from "../../../components/WorkoutCreator/utils";
import api from "../../../api";


const StyledCard = styled(Card)(({theme}) => ({
  backgroundColor: alpha(theme.palette.common.white, .05),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export const StyledIconButton = styled(IconButton)(({theme}) => ({
  transitions: theme.transitions.create('opacity'),
  '&:not(:hover)': {
    opacity: .6,
  }
}));

const effortInfo = {
  a: ['FTP +20s', 'FTP +17.5s', 'Infinite'],
  b: ['FTP +12.5s', 'FTP +7.5s', 'FTP +17.5'],
  c: ['FTP +5s', 'FTP +2.5s', 'FTP +7.5s'],
  d: ['FTP', 'FTP -2.5s', 'FTP +2.5s'],
  e: ['FTP -5s', '--', 'FTP -2.5s'],
}

const DrillItem = ({label, onDelete}) => (
  <Box display="flex" alignItems="center" justifyContent="space-between" maxWidth={250}>
    <Typography variand="body1">{label}</Typography>
    <StyledIconButton size="small" onClick={onDelete}>
      <DeleteIcon fontSize="small"/>
    </StyledIconButton>
  </Box>
);

const EffortItem = ({index, value, label}) => (
  <Stack gap={.5} textAlign="center">
    <Typography variant="body1" color="text.secondary">Zone {++index}</Typography>
    <Box>
      <WhatshotIcon sx={{fill: effortColors[value]}}/>
      <Typography sx={{letterSpacing: .15, color: effortColors[value]}} variant="h6">“{label}”</Typography>
    </Box>
    {effortInfo[value]?.map((str, index) => (
      <Typography key={index} variant="body1" color="text.secondary">{str}</Typography>
    ))}
  </Stack>
);

const Branding = () => {
  const {showAlert} = useAlertContext();

  const {coach, refetch: refetchCoach} = useContext(CoachContext);

  const updateCoachProfileMutation = useMutation(api.coaching.profile.update);

  const hasDrills = !!coach?.workoutDrillNames?.length;

  const [addDialog, setAddDialog] = useState({open: false, id: undefined});

  const [deleteDialog, setDeleteDialog] = useState({open: false, id: undefined});

  const [openEditDialog, setOpenEditDialog] = useState(false);

  const handleOpenDeleteDialog = (name) => {
    setDeleteDialog({open: true, name});
  }

  const handleCloseDeleteDialog = () => {
    setDeleteDialog(prevState => ({...prevState, open: false}));
  }

  const handleOpenAddDialog = () => {
    setAddDialog({open: true, name: ''});
  }

  const handleCloseAddDialog = () => {
    setAddDialog(prevState => ({...prevState, open: false}));
  }

  const handleAddCoachWorkoutDrillNames = (data) => updateCoachProfileMutation.mutate(
    {workoutDrillNames: [...(coach?.workoutDrillNames || []), data]},
    {
      onSuccess: () => {
        handleCloseAddDialog();
        refetchCoach();
        showAlert('Coach profile saved successfully.', {severity: 'success'});
      },
      onError: () => showAlert('Something went wrong.', {severity: 'error'}),
    }
  );

  const handleDeleteCoachWorkoutDrillNames = (data) => updateCoachProfileMutation.mutate(
    {workoutDrillNames: (coach?.workoutDrillNames || []).filter((drill) => drill !== data)},
    {
      onSuccess: () => {
        handleCloseDeleteDialog();
        refetchCoach();
        showAlert('Coach profile saved successfully.', {severity: 'success'});
      },
      onError: () => showAlert('Something went wrong.', {severity: 'error'}),
    }
  );

  const handleEditCoachEffortZoneNames = (data) => updateCoachProfileMutation.mutate(
    {effortZoneNames: data},
    {
      onSuccess: () => {
        setOpenEditDialog(false);
        refetchCoach();
        showAlert('Coach profile saved successfully.', {severity: 'success'});
      },
      onError: () => showAlert('Something went wrong.', {severity: 'error'}),
    }
  );


  return (
    <Stack gap={2}>
      <StyledCard>
        <Box>
          <Typography variant="h5">Effort Zones</Typography>
          <Typography variant="body2" color="text.secondary">
            As a coach you may have a preferred set of terms for the various pace zones of swimming. Simma is set up to
            work
            with 5 pace zones, each has a default name in Simma but you can override the defaults and set your own to
            further personalise workouts. You can also add your own drill sets when you create a workout or set.
          </Typography>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection={{xs: 'column', md: 'row'}} gap={{xs: 2, md: 4}}>
            <Stack gap={.5} textAlign="center" justifyContent="end">
              <Typography variant="body1" color="text.secondary">Avg.</Typography>
              <Typography variant="body1" color="text.secondary">Lower Bound</Typography>
              <Typography variant="body1" color="text.secondary">Upper Bound</Typography>
            </Stack>
            {Object.keys(coach?.effortZoneNames || {}).map((key, index) => (
              <EffortItem key={key} index={index} value={key} label={coach.effortZoneNames[key]}/>
            ))}
          </Box>

          <LoadingButton onClick={() => setOpenEditDialog(true)} variant="outlined" sx={{alignSelf: 'end'}}>
            EDIT
          </LoadingButton>
        </Box>
      </StyledCard>

      <StyledCard>
        <Box>
          <Typography variant="h5">{hasDrills ? 'Drill Lists' : 'Personalised Drill Lists'}</Typography>
          <Typography variant="body2" color="text.secondary">
            As a coach you may have a preferred set of terms for the drills you use as part of your swim coaching. All
            of
            the drill names in Simma are added by you so you can customise the product to your brand. You can review and
            add to the list below. You can also add new drills to the list in the set creator when making sets for your
            set library or as part of adding a drill to a workout set.
          </Typography>
        </Box>

        {hasDrills && (
          <Stack gap={1}>
            {coach.workoutDrillNames.map((label, index) => (
              <DrillItem key={index} label={label} onDelete={() => handleOpenDeleteDialog(label)}/>
            ))}
          </Stack>
        )}

        <Box>
          <LoadingButton onClick={handleOpenAddDialog} variant="outlined">
            add drill to list
          </LoadingButton>
        </Box>
      </StyledCard>

      <EditDialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        data={coach?.effortZoneNames}
        onSave={handleEditCoachEffortZoneNames}
        isLoading={updateCoachProfileMutation.isLoading}
      />

      <ConfirmDialog
        open={addDialog.open}
        onClose={handleCloseAddDialog}
        title="Add a new drill"
        content={(
          <TextField
            label="Type drill name"
            onChange={(e) => setAddDialog(prevState => ({...prevState, name: e.target.value}))}
            variant="filled"
          />
        )}
        action={
          <LoadingButton
            loading={updateCoachProfileMutation.isLoading}
            disabled={updateCoachProfileMutation.isLoading}
            onClick={() => handleAddCoachWorkoutDrillNames(addDialog.name)}
            variant="contained"
          >
            Add drill
          </LoadingButton>
        }
        PaperProps={{sx: {backgroundColor: 'background.default'}}}
      />

      <ConfirmDialog
        open={deleteDialog.open}
        onClose={handleCloseDeleteDialog}
        title={`Delete "${deleteDialog.name}"?`}
        content={`"${deleteDialog.name}" will be removed from your available drills list. Other workouts that already use this drill will not be affected.`}
        action={
          <LoadingButton
            loading={updateCoachProfileMutation.isLoading}
            disabled={updateCoachProfileMutation.isLoading}
            onClick={() => handleDeleteCoachWorkoutDrillNames(deleteDialog.name)}
            variant="contained"
          >
            Delete
          </LoadingButton>
        }
        PaperProps={{sx: {backgroundColor: 'background.default'}}}
      />
    </Stack>
  );
};

const EditDialog = ({isLoading, onSave, open, onClose, data}) => {
  const [currentData, setCurrentData] = useState(data);

  const isChanged = data ? Object.keys(data).findIndex((key) => currentData[key] !== data[key]) !== -1 : true;

  const handleClose = () => {
    onClose();
    setCurrentData(data);
  }

  const handleSave = () => {
    if (isChanged) {
      onSave(currentData);
    }
  }


  return (
    <Dialog
      PaperProps={{sx: {backgroundColor: 'background.default'}}}
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle sx={{pb: 2}}>Personalise Effort Zones</DialogTitle>

      <Stack gap={1} px={2} py={1}>
        <Box
          columnGap={1}
          rowGap={1.5}
          mb={1}
          display="grid"
          gridTemplateColumns={{sm: '1fr .8fr 2fr 3fr'}}
          alignItems="center"
        >
          <Typography variant="body1" color="text.secondary">Zone</Typography>
          <WhatshotIcon sx={{fill: effortColors.a}}/>
          <Typography variant="body1" color="text.secondary">Current Label</Typography>
          <Typography variant="body1" color="text.secondary">Preferred Label</Typography>
        </Box>
        {Object.keys(data).map((key, index) => (
          <Box
            key={key}
            columnGap={1}
            rowGap={1.5}
            display="grid"
            gridTemplateColumns={{sm: '1fr .8fr 2fr 3fr'}}
            alignItems="center"
          >
            <Typography variant="body1" color="text.secondary">Zone {++index}</Typography>
            <WhatshotIcon sx={{fill: effortColors[key]}}/>
            <Typography sx={{letterSpacing: .15, color: effortColors[key]}} variant="h6">“{data[key]}”</Typography>
            <TextField
              label="Label"
              value={currentData[key] || ''}
              onChange={(e) => setCurrentData(prevState => ({...prevState, [key]: e.target.value}))}
              variant="filled"
            />
          </Box>
        ))}
      </Stack>

      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          disabled={isLoading || !isChanged}
          onClick={handleSave}
          variant="contained"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default Branding;
