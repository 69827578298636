import React from 'react';
// import PropTypes from 'prop-types';

const Backstroke = ({size = 34}) => {
  return (
    <svg width={`${size}`} height={`${size}`} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.06381 29H5.37777C7.03207 29 8.04184 28.1621 8.04184 26.8032V26.7925C8.04184 25.7881 7.32211 25.0361 6.28012 24.9395V24.8428C7.05893 24.7192 7.66049 23.9995 7.66049 23.1885V23.1777C7.66049 21.9907 6.77426 21.2495 5.30258 21.2495H2.06381V29ZM4.9642 22.3291C5.80209 22.3291 6.29086 22.7319 6.29086 23.4194V23.4302C6.29086 24.1338 5.76986 24.5151 4.78695 24.5151H3.44955V22.3291H4.9642ZM5.00717 25.498C6.06527 25.498 6.62387 25.9116 6.62387 26.6958V26.7065C6.62387 27.4961 6.08139 27.9204 5.06088 27.9204H3.44955V25.498H5.00717ZM9.03641 29H10.4866L11.1311 26.9966H13.9993L14.6492 29H16.0994L13.3494 21.2495H11.7864L9.03641 29ZM12.5222 22.689H12.6136L13.6555 25.9277H11.4803L12.5222 22.689ZM20.3596 29.1934C22.1482 29.1934 23.4319 28.1567 23.636 26.6045V26.5669H22.2771L22.2717 26.5938C22.073 27.4478 21.3425 27.9956 20.3596 27.9956C19.0383 27.9956 18.2165 26.8945 18.2165 25.1274V25.1167C18.2165 23.355 19.0383 22.2539 20.3542 22.2539C21.3318 22.2539 22.073 22.8608 22.2717 23.7793L22.2771 23.8008H23.636V23.7578C23.4533 22.1733 22.1159 21.0562 20.3542 21.0562C18.1574 21.0562 16.7986 22.6084 16.7986 25.1221V25.1328C16.7986 27.6357 18.1628 29.1934 20.3596 29.1934ZM25.2428 29H26.6286V26.5078L27.4557 25.5464L29.9049 29H31.5968L28.444 24.6279L31.3659 21.2495H29.776L26.7199 24.832H26.6286V21.2495H25.2428V29Z" fill="#00CDFF"/>
      <path d="M27.24 4.22988C27.0363 4.11305 26.8113 4.03795 26.5783 4.00893C26.3452 3.97991 26.1087 3.99755 25.8825 4.06084C25.6564 4.12413 25.4451 4.23178 25.261 4.37755C25.0768 4.52332 24.9235 4.70427 24.81 4.90987C24.07 6.22987 23.42 7.55987 22.81 8.90987C23.4994 9.15938 24.1255 9.55748 24.644 10.0759C25.1624 10.5944 25.5605 11.2204 25.81 11.9099C25.95 11.5899 26.1 11.2599 26.24 10.9099C26.89 9.38987 27.51 7.84987 28.02 6.28987C28.1437 5.909 28.1339 5.49729 27.9921 5.12276C27.8503 4.74823 27.585 4.43327 27.24 4.22988Z" fill="#00CDFF"/>
      <path d="M5.42001 16.3299L6.94003 15.4599C7.39609 15.1966 7.91342 15.0579 8.44003 15.0579C8.96664 15.0579 9.48398 15.1966 9.94003 15.4599L10.94 16.0398C11.3961 16.3031 11.9134 16.4418 12.44 16.4418C12.9666 16.4418 13.484 16.3031 13.94 16.0398L14.94 15.4599C15.3336 15.2421 15.771 15.1157 16.22 15.0899C16.1614 14.5992 15.9998 14.1264 15.7458 13.7025C15.4919 13.2786 15.1513 12.9131 14.7463 12.6299C14.3413 12.3467 13.8812 12.1522 13.3958 12.0591C12.9105 11.9661 12.411 11.9766 11.93 12.0899C8.62002 12.4999 3.93002 14.3098 2.43002 16.0398C2.78128 16.0405 3.12618 16.1336 3.43002 16.3099C3.73112 16.4866 4.07321 16.5814 4.42233 16.5849C4.77145 16.5885 5.11542 16.5005 5.42001 16.3299Z" fill="#00CDFF"/>
      <path d="M21.16 9.60986C20.0991 9.60986 19.0817 10.0313 18.3316 10.7814C17.5814 11.5316 17.16 12.549 17.16 13.6099C17.1648 14.1949 17.298 14.7718 17.55 15.2999C17.68 15.3599 17.81 15.3899 17.94 15.4599L18.94 16.0399C19.3961 16.3032 19.9134 16.4418 20.44 16.4418C20.9666 16.4418 21.484 16.3032 21.94 16.0399L22.94 15.4599C23.5186 15.1403 24.1865 15.0208 24.84 15.1199C25.0409 14.642 25.1429 14.1283 25.14 13.6099C25.14 12.5525 24.7213 11.5381 23.9755 10.7885C23.2297 10.0389 22.2174 9.61515 21.16 9.60986Z" fill="#00CDFF"/>
    </svg>
  );
}

// Backstroke.propTypes = {
//   : PropTypes.
// };

export default Backstroke;
