import { userApi } from "./axios";

export const getForm = async (swimmerId) => {
	let path = `user/progress?type=form`;
	if (!!swimmerId) {
		path += `&swimmerId=${swimmerId}`;
	}
	return userApi.get(path);
};

export const getFitness = async (swimmerId) => {
	let path = `user/progress?type=fitness`;
	if (!!swimmerId) {
		path += `&swimmerId=${swimmerId}`;
	}
	return userApi.get(path);
};

export const getProgressData = async (swimmerId, activityType, system) => {
	const isAllActivity = activityType === "all";
	let path = `user/progress?type=all&system=${system}`;
	if (!isAllActivity) {
		path += `&activityType=${activityType}`;
	}
	if (swimmerId) {
		path += `&swimmerId=${swimmerId}`;
	}
	return userApi.get(path);
};

export const getBestTimes = async (swimmerId) => {
	let path = `user/progress?type=bestTimes`;
	if (!!swimmerId) {
		path += `&swimmerId=${swimmerId}`;
	}
	return userApi.get(path);
};

export const getCardioReport = async (swimmerId, activityTypes) => {
	let path = `user/progress?type=cardioReport&cardioActivityTypes=${activityTypes.join(
		","
	)}`;
	if (!!swimmerId) {
		path += `&swimmerId=${swimmerId}`;
	}
	return userApi.get(path);
};
