import {useState} from "react";
import Selector from "../forms/Selector";
// import PropTypes from 'prop-types';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {useMutation} from "react-query";
import api from "../../api";

const ProfileUnitesForm = ({user, handleClose, refetch}) => {
  const [form, setForm] = useState(user.preferredUnits || "metric");
  const mutation = useMutation((data) => api.user.update(data), {
    onSuccess: () => {
      refetch();
      handleClose();
    },
  });
  const submit = () => {
    mutation.mutate({
      preferredUnits: form,
    });
  };

  return (
    <>
      <DialogTitle className="title">Preferred Units</DialogTitle>
      <DialogContent sx={{minWidth: {md: 450}}}>
        <Selector
          items={[
            {text: "Metric", value: "metric"},
            {text: "Imperial", value: "imperial"},
          ]}
          label="Units"
          value={form}
          onUpdate={(val) => setForm(val)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <button
          className="button"
          onClick={submit}
          disabled={mutation.isLoading}
        >
          Submit
        </button>
      </DialogActions>
    </>
  );
};

// ProfileCurrencyForm.propTypes = {
//   : PropTypes.
// };

export default ProfileUnitesForm;
