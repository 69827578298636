import { Typography, Container, Box } from "@mui/material";

const CoachTermsAndConditions = () => {
  return (
    <Container>
      <Typography variant="h5" align="center" gutterBottom pt={5}>
        Simma Coach’s Terms and Conditions
      </Typography>
      <Box sx={{ display: "table" }}>
        <Section numerator={1}>
          <Text>
            <strong>Overview</strong>
          </Text>
        </Section>
        <Section numerator={1.1}>
          <Text>
            Butler88 Pty Ltd ACN 653 388 157 (we, us, our) owns and operates the
            platform (and all software and products made available through the
            platform) called “Simma” with domain name www.getsimma.com
            (Platform). These Terms and Conditions (Terms and Conditions) record
            an agreement between you (you, your, Coach), as the user and us for
            the purpose of your use of the Platform.
          </Text>
        </Section>

        <Section numerator={1.2}>
          <Text>
            We and our third-party licensors reserve the right to update,
            modify, replace, limit, and expand the Platform and its
            functionality.
          </Text>
        </Section>

        <Section numerator={1.3}>
          <Text>
            Prior to using the Platform, you should read these Terms and
            Conditions carefully.
          </Text>
        </Section>

        <Section numerator={2}>
          <Text>
            <strong>Acceptance of Terms and Conditions</strong>
          </Text>
        </Section>

        <Section numerator={2.1}>
          <Text>
            By using the Platform, you are accepting these Terms and Conditions
            and agree to be bound by them.
          </Text>
        </Section>

        <Section numerator={2.2}>
          <Text>
            We reserve the right to make changes to these Terms and Conditions
            from time to time without notice.
          </Text>
        </Section>

        <Section numerator={2.3}>
          <Text>
            These Terms and Conditions apply continuously and anew each time you
            use the Platform. We reserve the right to make changes to these
            Terms and Conditions from time to time without notice, and you
            should check the Terms and Conditions upon each use of the Platform.
          </Text>
        </Section>

        <Section numerator={3}>
          <Text>Use</Text>
        </Section>

        <Section numerator={3.1}>
          <Text>
            To use the Platform, you may be required to supply the following
            information:
          </Text>
        </Section>

        <Section numerator={null}>
          <Section numerator={"(a)"}>
            <Text>Your name;</Text>
          </Section>
          <Section numerator={"(b)"}>
            <Text>Your email address;</Text>
          </Section>
          <Section numerator={"(c)"}>
            <Text>Bank account details;</Text>
          </Section>
          <Section numerator={"(d)"}>
            <Text>A Stripe account; and</Text>
          </Section>
          <Section numerator={"(e)"}>
            <Text>
              Any other onboarding information required to set the Platform up
              for you to use.
            </Text>
          </Section>
          <Section numerator={"(User Data)"} />
        </Section>
        <Section numerator={3.2}>
          <Text>
            Failure to supply correct User Data may result in you being unable
            to use the Platform as intended.
          </Text>
        </Section>
        <Section numerator={3.3}>
          <Text>
            We may use the User Data for the purpose of providing the
            functionality of the Platform and any related purpose. In order to
            do so, we may share the User Data with other parties, including
            stakeholders and contractors.
          </Text>
        </Section>
        <Section numerator={3.4}>
          <Text>
            Our use of any personal information provided within the User Data
            will be in accordance with our Privacy Policy available at
            www.getsimma.com/privacy_policy.
          </Text>
        </Section>
        <Section numerator={3.5}>
          <Text>
            You must not use the Platform for any unconscionable behaviour or
            activity, including but not limited to:
          </Text>
        </Section>

        <Section numerator={null}>
          <Section numerator={"(a)"}>
            <Text>
              Engaging in abusive, derogatory, discriminatory, or vexatious
              behaviour;
            </Text>
          </Section>
          <Section numerator={"(b)"}>
            <Text>
              Engaging in any activity that disrupts or corrupts the Platform or
              the networks that host the Platform; or
            </Text>
          </Section>
          <Section numerator={"(c)"}>
            <Text>
              Attempting to hack, reverse-engineer, disable, circumvent, or
              otherwise gain access to any security, database, or other secure
              aspect of the Platform.
            </Text>
          </Section>
        </Section>

        <Section numerator={3.6}>
          <Text>You may link to the Platform only:</Text>
        </Section>

        <Section numerator={null}>
          <Section numerator={"(a)"}>
            <Text>With our prior express permission;</Text>
          </Section>
          <Section numerator={"(b)"}>
            <Text>
              In a way that is fair, unbiased, legal and does not damage or
              exploit our reputation; or
            </Text>
          </Section>
          <Section numerator={"(c)"}>
            <Text>
              In a way that does not suggest either express or implied any
              association with or endorsement by us.
            </Text>
          </Section>
        </Section>

        <Section numerator={3.7}>
          <Text>
            You must remove or procure the removal of any link to the Platform
            immediately upon receiving notice from us to do so. We may give such
            notice at our absolute discretion without justification.
          </Text>
        </Section>

        <Section numerator={3.8}>
          <Text>
            You must not modify, adapt or hack the Platform or modify another
            website or software so as to falsely imply that it is associated
            with the Platform or us. You must use the Platform for its intended
            purpose. You may not use the Platform for any illegal or
            unauthorised purpose, including the transmission of malware. You
            must not use the Platform to violate any laws in any jurisdiction,
            including but not limited to copyright laws. For the avoidance of
            doubt, you must not infringe upon the copyrights of any other person
            in using the Platform.
          </Text>
        </Section>

        <Section numerator={4}>
          <Text>Marketplace only</Text>
        </Section>

        <Section numerator={4.1}>
          <Text>
            The Platform provides a marketplace through which athletes
            (Athletes) can access coaching services from Coaches (Coaching
            Services) via the Platform. Our involvement is limited to
            facilitating transactions between Coaches and Athletes via the
            Platform including facilitating payment by Athletes to Coaches in
            consideration of Coaching Services (Transaction). We are not
            responsible or in any way liable for the performance or otherwise of
            either party’s obligations under the Transaction.
          </Text>
        </Section>

        <Section numerator={4.2}>
          <Text>
            In consideration of facilitating the Transaction, we deduct a
            commission as a percentage of the value of the Transaction. Please
            see clause 5 for further details.
          </Text>
        </Section>

        <Section numerator={5}>
          <Text>Fees and Payment</Text>
        </Section>
        <Section numerator={5.1}>
          <Text>
            You are entitled to charge Athletes a fee for completed and approved
            Coaching Services (Coaching Fee). The amount that will be remitted
            to you (Amount Payable) will be the Coaching Fee less:
          </Text>
        </Section>
        <Section numerator={null}>
          <Section numerator={"(a)"}>
            <Text>
              Our commission, the details of which can be found at (insert link
              to page setting out commission rates) (Simma Commission);{" "}
            </Text>
          </Section>
          <Section numerator={"(b)"}>
            <Text>Any other applicable transaction or third party fees.</Text>
          </Section>
        </Section>

        <Section numerator={5.2}>
          <Text>
            We may update the Simma Commission at any time. All applicable Simma
            Commission rates are displayed on the Platform at (insert link), as
            updated from time to time.
          </Text>
        </Section>

        <Section numerator={5.3}>
          <Text>
            Within 5 days of the start of each month, we will facilitate payment
            of the Amount Payable to you through Stripe (please see clause 6.1
            for more information).
          </Text>
        </Section>
        <Section numerator={5.4}>
          <Text>
            You are responsible for providing accurate and up to date
            information with respect to your bank account. We accept no
            liability, and will not be required to reimburse the Coach for any
            Amount Payable entitlement that is not received by the Coach, as a
            result of the Coach providing inaccurate or outdated information.
          </Text>
        </Section>

        <Section numerator={5.5}>
          <Text>
            You are responsible for notifying us if your payment information
            changes, and we will not be liable or required to reimburse you for
            any Amount Payable that is lost because of your failure to notify us
            that you have updated your payment details.
          </Text>
        </Section>

        <Section numerator={6}>
          <Text>Third party payment service</Text>
        </Section>

        <Section numerator={6.1}>
          <Text>
            We utilise Stripe to process payments on the Platforms. Payments
            submitted through the Stripe payment processing service are subject
            to Stripe’s terms and conditions which can be viewed at
            https://stripe.com/au.
          </Text>
        </Section>

        <Section numerator={6.2}>
          <Text>
            We may use other third party payment service provider to process
            payments on the Platform. Payments submitted through the third party
            payment service provider is subject to their terms and conditions.
          </Text>
        </Section>

        <Section numerator={6.3}>
          <Text>
            We are not liable for any issues, including charges from your
            financial institution, which may arise as a result of this service.
          </Text>
        </Section>

        <Section numerator={7}>
          <Text>Refunds</Text>
        </Section>

        <Section numerator={7.1}>
          <Text>
            If an Athlete requests a refund, they are instructed to make the
            request to you directly, and all refunds are as between the Athlete
            and the Coach only. To avoid doubt, we are under no obligation to
            provide a refund, or make a decision on whether or not any Coaching
            Fee is refundable.
          </Text>
        </Section>

        <Section numerator={7.2}>
          <Text>
            All refunds and requests for refunds are to be dealt with by the
            Athlete and the Coach in accordance with our third party payment
            service provider’s instructions, which may be accessed through the
            Platform.
          </Text>
        </Section>

        <Section numerator={7.3}>
          <Text>
            Notwithstanding clauses 7.1 and 7.2 we may, at our absolute
            discretion, take any of the following action if an Athlete requests
            a refund:
          </Text>
        </Section>
        <Section numerator={null}>
          <Section numerator={"(a)"}>
            <Text>
              We may review the request and, at our discretion, determine
              whether or not a refund is appropriate.{" "}
            </Text>
          </Section>
          <Section numerator={"(b)"}>
            <Text>
              If we decide that a refund is appropriate, you must comply with
              our reasonable instructions to refund any amount to an Athlete.
            </Text>
          </Section>
          <Section numerator={"(c)"}>
            <Text>
              If we decide at our discretion to refund a Coaching Fee for any
              reason whatsoever, you will not be entitled to receive the Amount
              Payable for that Transaction. For the avoidance of doubt, your
              entitlement to the Amount Payable lapses if any Transactions are
              refunded, whether or not the Coaching Services was delivered.{" "}
            </Text>
          </Section>
          <Section numerator={"(d)"}>
            <Text>
              If we decide to refund an Athlete for a Coaching Fee for any
              reason whatsoever, and you have already been paid the Amount
              Payable for that Transaction, we retain the right to deduct the
              value of the refund from any future Amounts Payable.
            </Text>
          </Section>
        </Section>

        <Section numerator={8}>
          <Text>Our Intellectual Property</Text>
        </Section>

        <Section numerator={8.1}>
          <Text>
            Your use of the Platform is pursuant to a non-exclusive license
            between you and us which is granted subject to these Terms and
            Conditions. Nothing in these Terms and Conditions or otherwise
            creates an assignment of any rights in the Platform or the Product
            beyond the ability to use it for its intended use.
          </Text>
        </Section>

        <Section numerator={8.2}>
          <Text>
            Nothing in these Terms and Conditions or otherwise creates the right
            for you to sublicense the Platform.
          </Text>
        </Section>

        <Section numerator={8.3}>
          <Text>
            You undertake not to reverse engineer, replicate, or otherwise copy
            the Platform. We reserve all our rights to seek compensation,
            damages, injunctions, or any other remedy available to us at law if
            any attempt to do so, whether successful or unsuccessful, is made by
            you or any of your affiliates.
          </Text>
        </Section>

        <Section numerator={8.4}>
          <Text>
            You must not reproduce, duplicate, copy, sell, resell or exploit any
            portion of the Platform or any products ordered through the
            Platform, use of the Platform, or access to the Platform without our
            prior express written permission. We claim copyright and all other
            intellectual property rights over the Platform, its other products
            and services and reserve all of our rights.
          </Text>
        </Section>

        <Section numerator={8.5}>
          <Text>
            All intellectual property and ownership rights subsisting in the
            Platform (including the user interface and the software comprising
            the Platform), the data derived from use of the Platform, and any
            other documentation, information or materials that are supplied by
            us to you, remain our or our third party licensors’ exclusive
            property. For the avoidance of doubt, nothing in this agreement
            assigns any ownership rights to you.
          </Text>
        </Section>

        <Section numerator={9}>
          <Text>Your Intellectual Property</Text>
        </Section>

        <Section numerator={9.1}>
          <Text>
            The Platform may enable you to upload coaching programs (including
            training sets, workout regimes, etc) that you have designed and
            created (Coach IP).
          </Text>
        </Section>

        <Section numerator={9.2}>
          <Text>
            We dot claim any intellectual property rights in the Coach IP, and
            you do not transfer or assign copyright or intellectual property
            rights in the Coach IP to us.
          </Text>
        </Section>

        <Section numerator={9.3}>
          <Text>
            By uploading Coach IP onto the Platform, you agree to allow other
            users, including Athletes and Coaches, to view, share, and make use
            of Coach IP. You have the right to remove your Coach IP from the
            Platform at any time.
          </Text>
        </Section>

        <Section numerator={9.4}>
          <Text>
            You grant us a royalty-free, non-exclusive licence to use the Coach
            IP and display it on the Platform for as long as it is on the
            Platform for the purposes of providing the Platform and facilitating
            Transactions.
          </Text>
        </Section>

        <Section numerator={9.5}>
          <Text>
            You must ensure, and you warrant and guarantee that, your Coach IP
            does not infringe on the intellectual property or other proprietary
            rights of any third party (including other coaches utilising the
            Platform). You fully indemnify us and hold us harmless against any
            loss or damage, including reasonable legal costs, arising from a
            third party claim or allegation that any Coach IP infringes on the
            intellectual property or other proprietary rights of any entity.
          </Text>
        </Section>

        <Section numerator={9.6}>
          <Text>
            All Coach IP is likely to be automatically destroyed upon you
            notifying us (whether by deleting your account or otherwise) that
            you are no longer using the Platform (however we do retain the right
            to display any Coach IP on the Platform even after you have ceased
            using the Platform. In such a case, we will provide you with an
            acknowledgment.
          </Text>
        </Section>

        <Section numerator={9.7}>
          <Text>
            We may or may not review Coach IP, however we reserve the right to
            remove any Coach IP from the Platform, or refuse any Coach IP to be
            displayed on the Platform, for any reason at our absolute
            discretion.
          </Text>
        </Section>

        <Section numerator={10}>
          <Text>Operation of the Platform</Text>
        </Section>

        <Section numerator={10.1}>
          <Text>
            You warrant that you will comply fully with all the Platform
            operating instructions. Any failure to comply fully with operating
            instructions may result in the sub-par or faulty performance of the
            Platform.
          </Text>
        </Section>

        <Section numerator={10.2}>
          <Text>
            We accept no liability, and you release us from any such liability,
            associated with faults or errors caused by your non-compliance with
            all operating instructions.
          </Text>
        </Section>

        <Section numerator={10.3}>
          <Text>
            We may suspend the Platform for any one or more periods of time if
            we are unable to provide the Platform due to a fault or dysfunction
            with our servers.
          </Text>
        </Section>

        <Section numerator={11}>
          <Text>Modifications to the Platform</Text>
        </Section>

        <Section numerator={11.1}>
          <Text>
            We reserve the right to modify or discontinue, temporarily or
            permanently the Platform and its other products and services with or
            without notice.
          </Text>
        </Section>

        <Section numerator={12}>
          <Text>Warranties</Text>
        </Section>

        <Section numerator={12.1}>
          <Text>
            You represent and warrant that we will not be liable for any direct
            or indirect expense, damage or injury (including indirect loss of
            revenue, profits, goodwill or business opportunity) howsoever
            caused, including arising directly or indirectly from or in
            connection with your use of the Platform.
          </Text>
        </Section>

        <Section numerator={12.2}>
          <Text>
            Subject to any of the rights you may have under Australian Consumer
            Protection Law, we exclude to the fullest extent possible all
            implied terms and warranties, whether statutory or otherwise,
            relating to the Platform or any other matter under these Terms and
            Conditions.
          </Text>
        </Section>

        <Section numerator={12.3}>
          <Text>
            The Platform is made available to you on an “as is” basis. We make
            no warranties, representations or conditions of any kind, express,
            statutory or implied as to:
          </Text>
        </Section>
        <Section numerator={null}>
          <Section numerator={"(a)"}>
            <Text>The operation and functionality of the platform;</Text>
          </Section>
          <Section numerator={"(b)"}>
            <Text>
              the accuracy, integrity, completeness, quality, legality,
              usefulness, safety and IP rights of any of the software content;
              and/or
            </Text>
          </Section>
          <Section numerator={"(c)"}>
            <Text>
              the products and services associated with the Platform or its
              content.
            </Text>
          </Section>
        </Section>

        <Section numerator={12.4}>
          <Text>
            We further disclaim all warranties, express, statutory or implied,
            including, but not limited to, implied warranties of
            merchantability, merchantable quality, durability, fitness for a
            particular purpose and/or non-infringement. No advice or
            information, whether oral or written, that you obtain from us shall
            create any warranty, representation or condition not expressly
            stated herein.
          </Text>
        </Section>

        <Section numerator={13}>
          <Text>Limitation of liability</Text>
        </Section>

        <Section numerator={13.1}>
          <Text>We disclaim all liability for any:</Text>
        </Section>

        <Section numerator={null}>
          <Section numerator={"(a)"}>
            <Text>
              indirect, special, incidental, punitive, exemplary, reliance or
              consequential damages;
            </Text>
          </Section>
          <Section numerator={"(b)"}>
            <Text>loss of profits;</Text>
          </Section>
          <Section numerator={"(c)"}>
            <Text>business interruption;</Text>
          </Section>
          <Section numerator={"(d)"}>
            <Text>network interruptions;</Text>
          </Section>
          <Section numerator={"(e)"}>
            <Text>
              injury, disability, or death arising from an Athlete’s use of your
              Coaching Services;
            </Text>
          </Section>
          <Section numerator={"(f)"}>
            <Text>loss or damage to reputation of us or any third party;</Text>
          </Section>
          <Section numerator={"(g)"}>
            <Text>loss of information or data;</Text>
          </Section>
          <Section numerator={"(h)"}>
            <Text>all liability for any loss or damage,</Text>
          </Section>
        </Section>
        <Section numerator={null}>
          <Section
            numerator={
              "relating to or arising out of your use of the Platform."
            }
          />
        </Section>

        <Section numerator={13.2}>
          <Text>
            Your use of the Platform is at your own discretion and risk, and you
            will be solely responsible for any resulting loss or damage,
            including but not limited to, any loss of data or damage to your
            computer(s) or networks from viruses that may be downloaded to your
            computer in the course of using the software. Some jurisdictions do
            not allow the exclusion of certain warranties, the limitation or
            exclusion of implied warranties, or limitations on how long an
            implied warranty may last, so the above limitations may not apply to
            you. If you reside in such a jurisdiction, the above limitations
            shall apply to you to the fullest extent permitted under applicable
            law.
          </Text>
        </Section>

        <Section numerator={13.3}>
          <Text>
            You are wholly responsible and liable for the Coaching Services you
            provide. It is your responsibility to ensure that Coaching Services
            provided are appropriate, useful, safe, and suitable for individual
            Athletes, and you release us from any liability arising from loss,
            damage, or injury arising from Coaching Services provided by you.
          </Text>
        </Section>

        <Section numerator={13.4}>
          <Text>
            Our maximum aggregate liability to you for any claims, damages,
            injuries or causes whatsoever, and regardless of the form of action
            (whether such liability arises due to negligence, breach of
            contract, misrepresentation or for any other reason), will at all
            times be limited to the amount paid, if any, by you to us in
            connection with the Platform in the 6 months prior to the action
            giving rise to liability.
          </Text>
        </Section>

        <Section numerator={14}>
          <Text>Indemnity</Text>
        </Section>

        <Section numerator={14.1}>
          <Text>
            You defend, indemnify and hold harmless us, our members, managers,
            subsidiaries, affiliates, any related companies, contractors,
            licensors and partners, and the officers, directors, employees,
            agents and representatives of each of them, including costs,
            liabilities and legal fees, from any claim or demand made by any
            third party due to or arising out of:
          </Text>
        </Section>

        <Section numerator={null}>
          <Section numerator={"(a)"}>
            <Text>Your access to or use of the Platform;</Text>
          </Section>
          <Section numerator={"(b)"}>
            <Text>Your violation of this agreement;</Text>
          </Section>
          <Section numerator={"(c)"}>
            <Text>
              Any claims brought against you or us by an Athlete due to loss,
              damage, or injury arising from Coaching Services you have
              provided;
            </Text>
          </Section>
          <Section numerator={"(d)"}>
            <Text>
              Any infringement by you of any intellectual property or other
              right of any person or entity.
            </Text>
          </Section>
        </Section>

        <Section numerator={14.2}>
          <Text>
            We reserve the right, at your expense, to assume the exclusive
            defence and control of any matter for which you are required to
            indemnify us and you will cooperate with our defence of these
            claims. You will not to settle any matter without our prior written
            consent. We will use reasonable efforts to notify you of any such
            claim, action or proceeding upon becoming aware of it.
          </Text>
        </Section>

        <Section numerator={15}>
          <Text>Transfer of rights and obligations</Text>
        </Section>

        <Section numerator={15.1}>
          <Text>
            These Terms and Conditions are binding on you and us and on our
            respective successors and assignees.
          </Text>
        </Section>

        <Section numerator={15.2}>
          <Text>
            You may not transfer, assign, charge or otherwise dispose of your
            rights or liabilities under these Terms and Conditions, or any of
            your rights or obligations arising under it, without our prior
            written consent.
          </Text>
        </Section>

        <Section numerator={15.3}>
          <Text>
            We may transfer, assign, charge, sub-contract or otherwise dispose
            of any of our rights or obligations arising under these Terms and
            Conditions, at any time without your prior consent.
          </Text>
        </Section>

        <Section numerator={16}>
          <Text>Force Majeure Events</Text>
        </Section>

        <Section numerator={16.1}>
          <Text>
            We will not be liable or responsible for any failure to perform or
            delay in performance of any of our obligations under these Terms and
            Conditions that is caused by events outside our control (Force
            Majeure Event).
          </Text>
        </Section>

        <Section numerator={16.2}>
          <Text>
            A Force Majeure Event includes any act, event, non- happening,
            omission or accident beyond our reasonable control and includes in
            particular (without limitation) the following:
          </Text>
        </Section>

        <Section numerator={null}>
          <Section numerator={"(a)"}>
            <Text>Strikes, lock-outs or other industrial action;</Text>
          </Section>
          <Section numerator={"(b)"}>
            <Text>
              Civil commotion, riot, invasion, terrorist attack or threat of
              terrorist attack, war (whether declared or not) or threat or
              preparation for war;
            </Text>
          </Section>
          <Section numerator={"(c)"}>
            <Text>
              Fire, explosion, storm, flood, earthquake, subsidence, epidemic or
              other natural disaster;
            </Text>
          </Section>
          <Section numerator={"(d)"}>
            <Text>
              Impossibility of the use of railways, shipping, aircraft, motor
              transport or other means of public or private transport;
            </Text>
          </Section>
          <Section numerator={"(e)"}>
            <Text>
              Impossibility of the use of public or private telecommunications
              networks;
            </Text>
          </Section>
          <Section numerator={"(f)"}>
            <Text>
              Epidemic, pandemic or other health emergency (whether declared or
              not), including without limitation lockdowns or any other
              restrictions imposed as a result of the COVID-19 pandemic or any
              other pandemic or epidemic at any time; and
            </Text>
          </Section>
          <Section numerator={"(g)"}>
            <Text>
              The acts, decrees, legislation, regulations or restrictions of any
              government.
            </Text>
          </Section>
        </Section>

        <Section numerator={16.3}>
          <Text>
            Our performance under these Terms and Conditions is deemed to be
            suspended for the period that the Force Majeure Event continues, and
            we will have an extension of time for performance for the duration
            of that period. We will use our reasonable endeavours to bring the
            Force Majeure Event to a close or to find a solution by which our
            obligations under these Terms and Conditions may be performed
            despite the Force Majeure Event.
          </Text>
        </Section>

        <Section numerator={17}>
          <Text>General</Text>
        </Section>

        <Section numerator={17.1}>
          <Text>
            To the extent permitted by law, we reserve the right to create,
            collect and / or store any metadata resulting from your use of the
            Platform. To the extent permitted by law, we may use metadata for a
            variety of functions including (but not limited to) conducting
            investigations, helping us manage and administer the Platform, for
            purposes of search engine optimisation and to drive traffic to the
            Platform. To the extent permitted by law, we reserve the right to
            share your information (including metadata) with government agencies
            and authorities to the extent this is required or requested of us.
          </Text>
        </Section>

        <Section numerator={17.2}>
          <Text>
            We may use third party vendors and hosting partners to provide the
            necessary hardware, software, networking, storage, payment gateway
            and related technology required to run the Platform.
          </Text>
        </Section>

        <Section numerator={17.3}>
          <Text>
            Verbal, physical, written or other abuse (including threats of abuse
            or retribution) of any of our employees, members, representatives,
            or officers will result in immediate termination of your access to
            the Platform.
          </Text>
        </Section>

        <Section numerator={17.4}>
          <Text>We do not warrant that:</Text>
        </Section>

        <Section numerator={null}>
          <Section numerator={"(a)"}>
            <Text>The Platform will meet your specific requirements;</Text>
          </Section>
          <Section numerator={"(b)"}>
            <Text>
              The Platform will be uninterrupted, timely, secure, or error-free;
            </Text>
          </Section>
          <Section numerator={"(c)"}>
            <Text>Any errors in the Platform will be corrected.</Text>
          </Section>
        </Section>

        <Section numerator={17.5}>
          <Text>
            Our failure to exercise or enforce any right or provision of these
            Terms and Conditions shall not constitute a waiver of such right or
            provision. The Terms and Conditions constitutes the entire agreement
            between you and us and govern your use of the Platform, superseding
            any prior agreements between you and us.
          </Text>
        </Section>

        <Section numerator={17.6}>
          <Text>
            Any part of these Terms and Conditions that is invalid, prohibited
            or not enforceable will not void the balance of the Terms and
            Conditions. All other parts will be enforced without regard to the
            invalid provision and continue to be valid in accordance with their
            terms.
          </Text>
        </Section>

        <Section numerator={17.7}>
          <Text>
            We make no representations or warranties that the content of the
            Platform complies with the laws (including intellectual property
            laws) of any country outside of Australia.
          </Text>
        </Section>

        <Section numerator={17.8}>
          <Text>
            Any queries about the Terms and Conditions should be sent to us by
            email at coaching@getsimma.com.
          </Text>
        </Section>

        <Section numerator={17.9}>
          <Text>
            These Terms and Conditions are governed by the laws of the Western
            Australia in Australia. Disputes arising from your use of the
            Platform are exclusively subject to the jurisdiction of the courts
            of Western Australia.
          </Text>
        </Section>
      </Box>
    </Container>
  );
};

export default CoachTermsAndConditions;

const Section = ({ numerator, children }) => {
  return (
    <Box sx={{ display: "table-row" }}>
      <Box sx={{ display: "table-cell", pr: 5 }}>
        <Typography>{numerator}</Typography>
      </Box>
      <Box sx={{ display: "table-cell" }}>{children}</Box>
    </Box>
  );
};

const Text = ({ children }) => {
  return <Typography paragraph>{children}</Typography>;
};
