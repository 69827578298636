import {
  Box, Button, Container, styled, Typography
} from "@mui/material";
import {useResponsive} from "../../../hooks";


const StyledRoot = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridTemplateRows: 'repeat(2, 1fr)',
  '> div': {
    '&:nth-of-type(1)': {
      gridArea: '1 / 1 / 3 / 2',
      backgroundColor: theme.palette.backgrounds['3t'],
    },
    '&:nth-of-type(2)': {
      gridArea: '1 / 2 / 2 / 3',
      backgroundColor: theme.palette.backgrounds['1t'],
    },
    '&:nth-of-type(3)': {
      gridArea: '2 / 2 / 3 / 3',
      backgroundColor: theme.palette.backgrounds['2t'],
    }
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'auto',
    gridTemplateRows: 'auto',
    '& > div.MuiBox-root': {
      gridArea: 'auto',
    },
  }
}));

const Image = styled('img')({
  height: 'auto',
  maxWidth: '100%',
  width: '40%',
});

const Description = ({title, description}) => (
  <Box sx={{py: 6, display: 'flex', alignItems: 'center'}}>
    <Container maxWidth="sm">
      <Typography sx={{mb: 2}} variant="h3">{title}</Typography>
      <Typography variant="body1">{description}</Typography>
    </Container>
  </Box>
);

const BoxAction = ({title, description, imageSrc, buttonText, link, action}) => (
  <Box sx={{py: 6}}>
    <Container sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
      {imageSrc && <Image src={imageSrc} alt="action" />}
      <Box>
        <Typography sx={{mb: 2}} variant="h4">{title}</Typography>
        <Typography sx={{mb: 3, maxWidth: 240}} variant="body1">{description}</Typography>
        {link && <Button href={link} variant="contained">{buttonText}</Button>}
        {!link && action}
      </Box>
    </Container>
  </Box>
);

const LandingCTASection = ({data, action}) => {
  const isDesktop = useResponsive('up', 'md');
  if (!data?.length) {
    return null;
  }
  return (
    <StyledRoot>
      {isDesktop && <Description {...data[0]} />}
      {data.slice(1).map((item, index) => <BoxAction key={index} {...item} action={action} />)}
    </StyledRoot>
  );
};
export default LandingCTASection;
