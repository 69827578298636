import {Redirect} from 'react-router-dom';
//
import { useAuthContext } from './useAuthContext';

// ----------------------------------------------------------------------

const AuthGuard = ({ children }) => {
  const {isAuthenticated} = useAuthContext();

  if (!isAuthenticated) {
    return <Redirect to="/" />;
  }

  return <>{children}</>;
}

export default AuthGuard;