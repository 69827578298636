import { Stack, Box } from '@mui/material';

import FormMeter from './FormMeter';
import FormChart from './FormChart';
import Card from '../../../pages/Progress/components/Card';
import ProgressFitnessHIstory from '../ProgressFitnessHistory';
import ProgressDistance from '../ProgressDistance';
import ProgressCardio from '../ProgressCardio';

const ProgressForm = ({
  form,
  fitness,
  isMobile,
  activityType,
  distanceComparison,
  allUserActivityTypes,
  cardioReport
}) => {
  const activityTitle = activityType?.slice(0, 1).toUpperCase() + activityType?.slice(1);

  return (
    <Stack gap={'10px'}>
      {activityType === 'swimming' && (
        <Card
          divider
          title={`${activityTitle || 'Swim'} Training Form`}
          helpContent={formHelpContent}>
          <Box sx={{ mb: isMobile ? '16px' : '32px' }}>
            <FormMeter formScore={form.formScore} isMobile={isMobile} />
          </Box>
          <FormChart formHistory={form.formHistory} isMobile={isMobile} />
        </Card>
      )}
      {!!cardioReport && !!allUserActivityTypes && (
        <Card title={'Time in heart rate zone'}>
          <ProgressCardio
            allUserActivityTypes={allUserActivityTypes}
            cardioReport={cardioReport}
            activityType={activityType}
          />
        </Card>
      )}
      <Card title={'Monthly distance comparison'}>
        <ProgressDistance distanceComparison={distanceComparison} />
      </Card>
      {activityType === 'swimming' && (
        <Card divider title={'Training Charts'}>
          <ProgressFitnessHIstory fitness={fitness} isMobile={isMobile} />
        </Card>
      )}
    </Stack>
  );
};
export default ProgressForm;

const formHelpContent = [
  'Your form score indicates your current training condition. Are you grinding to build fitness or coasting?',
  'Editing your stress score for data anomalies in an activity will improve the accuracy of your form score and the resulting fitness charts below. Click here to read more about form score.'
];
