import {useWatch} from "react-hook-form";
import {RHFMultiCheckbox} from "../../../../components/hook-form";


const FocusAreasCheckboxGroup = ({control, options, name, ...other}) => {
  const levels = useWatch({
    control,
    name,
  });

  const validatedOptions = levels.length > 2
    ? options.map(o => ({...o, disabled: !levels.includes(o.value)}))
    : options;

  return <RHFMultiCheckbox name={name} options={validatedOptions} {...other} />;
};

export default FocusAreasCheckboxGroup;