import { useState, useContext } from "react";
import {
  Box,
  Typography,
  Button,
  Modal,
  Stack,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useQuery, useMutation } from "react-query";
import api from "api";
import { UserContext } from "context";
import { useQueryParams } from "hooks";
import { trackUseCoupon } from "tracking";

const UpgradeState = ({ buttonOnly }) => {
  const { analyticsPurchased } = useQueryParams();
  const { user, refetch: refetchUser } = useContext(UserContext);
  const [prices, setPrices] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedPriceId, setSelectedPriceId] = useState(null);
  const [codeFieldOpen, setCodeFieldOpen] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [couponError, setCouponError] = useState(null);

  const subscriptionPending =
    (analyticsPurchased === "true" && !user.premiumSubscription) ||
    (!!user.premiumSubscription && !user.premiumSubscription.isActive);

  useQuery(["simmaProducts", user.currency], api.stripe.getSimmaProducts, {
    onSuccess: (data) => {
      const product = data[0];
      const prices = {
        month: product.prices.find(
          (price) => price.recurring.interval === "month"
        ),
        year: product.prices.find(
          (price) => price.recurring.interval === "year"
        ),
      };
      setPrices(prices);
      setSelectedPriceId(prices.year?.id || prices.month?.id);
    },
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCheck = (value, price) => {
    if (!!value) {
      setSelectedPriceId(price.id);
    }
  };

  const { mutate: purchase, isLoading: purchaseIsLoading } = useMutation(
    api.stripe.createSimmaCheckoutSession,
    {
      onSuccess: (data) => {
        window.location.replace(data.url);
      },
    }
  );

  const { mutate: redeemCoupon, isLoading: couponIsLoading } = useMutation(
    api.coupons.redeemCoupon,
    {
      onSuccess: () => {
        try {
          trackUseCoupon(coupon);
        } catch (err) {
          console.log("TRACK USE COUPON ERROR", err);
        }
        refetchUser();
        setOpen(false);
      },
      onError: (error) => {
        const status = error?.response?.status;
        console.log("redeem coupon error", error, status);
        if (status === 404) {
          setCouponError("Invalid code");
        } else {
          setCouponError("Something went wrong");
        }
      },
    }
  );

  const handleClick = () => {
    if (coupon) {
      redeemCoupon(coupon);
    } else {
      purchase({
        priceId: selectedPriceId,
        success_url: window.location.href + "?analyticsPurchased=true",
        cancel_url: window.location.href,
      });
    }
  };

  if (subscriptionPending) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <Typography fontSize={20} fontWeight={600} textAlign="center">
          Your Simma Pro subscription is awaiting confirmation.
        </Typography>
        <Typography sx={{ opacity: 0.7 }} textAlign="center">
          Try refreshing the page.
        </Typography>
      </Box>
    );
  }

  const renderButtonModal = () => (
    <>
      <Button
        variant="contained"
        onClick={handleOpen}
        disabled={subscriptionPending}
        size={buttonOnly ? "small" : "large"}
        sx={{
          textTransform: "none",
          background:
            "linear-gradient(90.62deg, #FF506D 0.25%, #FF50D9 99.71%)",
          fontWeight: 600,
        }}
      >
        Go Pro
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Subscribe to premium analytics
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Simma Pro provides you with your fitness, fatigue and form metrics
            you can log over time to see how activities. Simma also provides
            guidance on what length and effort swims to stay in of improve your
            form.
          </Typography>
          <Typography mb={2} mt={2}>
            Apple Watch users get access to our beta services for advanced
            analytics on their freestyle technique.
          </Typography>
          <Typography sx={{ mb: 3 }}>
            If you are working with a coach these analytics are available for
            them to view as well so they can provide more tailored coaching.{" "}
          </Typography>
          {!!prices && (
            <>
              <Stack direction="row">
                {!!prices.month && (
                  <PriceCheckBox
                    price={prices.month}
                    onChange={handleCheck}
                    selectedPriceId={selectedPriceId}
                    disabled={!!coupon}
                  />
                )}
                {!!prices.year && (
                  <PriceCheckBox
                    price={prices.year}
                    onChange={handleCheck}
                    selectedPriceId={selectedPriceId}
                    disabled={!!coupon}
                  />
                )}
              </Stack>
              <Stack mb={2} sx={{ alignItems: "center" }}>
                <Button
                  onClick={() => {
                    if (codeFieldOpen) {
                      setCoupon("");
                      setCouponError(null);
                    }
                    setCodeFieldOpen(!codeFieldOpen);
                  }}
                >
                  {`I ${codeFieldOpen ? "don't" : ""} have a code`}
                </Button>
                <TextField
                  placeholder="Enter code"
                  size="small"
                  value={coupon}
                  onChange={(e) => {
                    setCoupon(e.target.value);
                    setCouponError(null);
                  }}
                  error={!!couponError}
                  helperText={couponError}
                  sx={{
                    maxWidth: "200px",
                    display: codeFieldOpen ? "block" : "none",
                  }}
                />
              </Stack>
              <LoadingButton
                variant="contained"
                onClick={handleClick}
                loading={purchaseIsLoading || couponIsLoading}
                sx={{
                  textTransform: "none",
                  background:
                    "linear-gradient(90.62deg, #FF506D 0.25%, #FF50D9 99.71%)",
                  fontWeight: 600,
                  minWidth: 200,
                }}
              >
                {coupon ? "Apply Code" : "Subscribe"}
              </LoadingButton>
            </>
          )}
        </Box>
      </Modal>
    </>
  );

  if (!!user.premiumSubscription) return null;

  return (
    <>
      {buttonOnly ? (
        renderButtonModal()
      ) : (
        <Box sx={{ textAlign: "center" }}>
          <Typography pb={3} fontSize={20} fontWeight={600} textAlign="center">
            Upgrade To Simma Pro for advanced analytics
          </Typography>
          {renderButtonModal()}
        </Box>
      )}
    </>
  );
};
export default UpgradeState;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  maxWidth: "100%",
  bgcolor: "backgrounds.2",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const PriceCheckBox = ({ price, onChange, selectedPriceId, disabled }) => {
  return (
    <FormControlLabel
      sx={{ margin: "0 auto" }}
      disabled={disabled}
      control={
        <Checkbox
          checked={price.id === selectedPriceId}
          onChange={(event, value) => onChange(value, price)}
        />
      }
      label={
        <Typography sx={{ fontSize: "1rem", lineHeight: "160%", opacity: 0.7 }}>
          {`${price.currency.toUpperCase()} ${price.unit_amount / 100} / ${
            price.recurring.interval
          }`}
        </Typography>
      }
    />
  );
};
