import { useState } from "react";
import Selector from "../forms/Selector";
import { useMutation } from "react-query";
import api from "api";
// import PropTypes from 'prop-types';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const ProfileCurrencyForm = ({ open, handleClose, user, refetch }) => {
  const defaultForm = {
    currency: user.currency,
  };
  const [form, setForm] = useState(defaultForm);
  const mutation = useMutation((data) => api.user.update(data), {
    onSuccess: () => {
      refetch();
      handleClose();
    },
  });

  const submit = () => {
    mutation.mutate(form);
  };

  return (
    <>
      <DialogTitle className="title">Preferred Currency</DialogTitle>
      <DialogContent sx={{ minWidth: { md: 450 } }}>
        <Selector
          items={[
            { text: "USD", value: "usd" },
            { text: "AUD", value: "aud" },
            { text: "EUR", value: "eur" },
            { text: "GBP", value: "gbp" },
          ]}
          label="Currency"
          value={form.currency}
          onUpdate={(val) => setForm({ ...form, currency: val })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <button
          className="button"
          onClick={submit}
          disabled={mutation.isLoading}
        >
          Submit
        </button>
      </DialogActions>
    </>
  );
};

// ProfileCurrencyForm.propTypes = {
//   : PropTypes.
// };

export default ProfileCurrencyForm;
