import {useContext} from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ReferenceArea,
  Tooltip,
  ResponsiveContainer
} from 'recharts'
import CustomToolTip from './CustomToolTip'
import {heartRateColors} from '../../style/colors.js'

import {CHART_CONSTANTS} from './chartUtilities'
import {UserContext} from "../../context";

const labelHeight = CHART_CONSTANTS.labelHeight

const SimpleHeartRateChart = ({heartRate, timeSinceStartSec}) => {
  const {user} = useContext(UserContext);

  const heartRateData = heartRate.map(i => ({heartRate: i}))
  const heartRateTimeData = timeSinceStartSec.map(i => ({timeSinceStartSec: i}))
  const heartRateGraphData = heartRateData.map((i, index) => ({...i, ...(heartRateTimeData[index])}))

  return (
    <ResponsiveContainer width="100%" height={247}>
      <LineChart
        data={heartRateGraphData}
        margin={{top: 5, right: 0, left: 10, bottom: 0}}
      >
        <XAxis
          dataKey="timeSinceStartSec"
          height={labelHeight}
          tick={false}
        />
        <YAxis domain={[40, user.maxHeartRate]} width={40} tick={{fontSize: 2}}/>
        <Tooltip content={<CustomToolTip/>}/>
        <ReferenceArea y1={user.hearRateZoneBounds.rest[0]} y2={user.hearRateZoneBounds.rest[1]} fillOpacity={.3}
                       fill={heartRateColors.blue}/>
        <ReferenceArea y1={user.hearRateZoneBounds.a[0]} y2={user.hearRateZoneBounds.a[1]} fillOpacity={.3}
                       fill={heartRateColors.green}/>
        <ReferenceArea y1={user.hearRateZoneBounds.b[0]} y2={user.hearRateZoneBounds.b[1]} fillOpacity={.3}
                       fill={heartRateColors.yellow}/>
        <ReferenceArea y1={user.hearRateZoneBounds.c[0]} y2={user.hearRateZoneBounds.c[1]} fillOpacity={.3}
                       fill={heartRateColors.orange}/>
        <ReferenceArea y1={user.hearRateZoneBounds.d[0]} fillOpacity={.3} fill={heartRateColors.red}/>
        <Line type="monotone" dataKey="heartRate" stroke="rgba(241, 88, 79, 1)" strokeWidth={2} dot={false}
              activeDot={{strokeWidth: 0, r: 5}}/>
      </LineChart>
    </ResponsiveContainer>
  );
}

export default SimpleHeartRateChart;