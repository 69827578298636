export const getFTPText = (accuracy, type) => {
  const text = {
    running: {
      0: 'FTP Estimation: This is a default FTP',
      1: `Run Level ${accuracy} Set at Onboarding`,
      2: 'Estimated based on your running history',
      3: 'Manually set FTP'
    },
    swimming: {
      0: 'FTP Estimation: This is a default FTP',
      1: `Swim Level ${accuracy} Set at Onboarding`,
      2: 'Estimated based on your swimming history',
      3: 'Manually set FTP'
    }
  };
  return text?.[type]?.[accuracy] || 'FTP Estimation: This is a default FTP';
};
