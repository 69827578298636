import {useContext, useState} from "react";
import {useHistory, useParams} from 'react-router-dom'
import {useMutation, useQuery, useQueryClient} from "react-query";
// @mui
import {Box, Container, Step, StepLabel, Stepper} from "@mui/material";
// components
import {useAlertContext} from "../../../components/alert/AlertContext";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
import Spinner from "../../../components/common/Spinner";
import {WorkoutCreatorForm} from "./form";
import api from "../../../api";
import {CoachContext} from "../../../context";


const steps = ['General Information', 'Workout Sets'];

const WorkoutCreatorStepper = ({activeStep}) => (
  <Stepper sx={{mb: 3}} activeStep={activeStep}>
    {steps.map((label) => (
      <Step key={label}>
        <StepLabel>{label}</StepLabel>
      </Step>
    ))}
  </Stepper>
);

const WorkoutEditorPage = () => {
  const {workoutId} = useParams();

  const history = useHistory();

  const queryClient = useQueryClient();

  const {showAlert} = useAlertContext();

  const {coach, refetch: refetchCoach} = useContext(CoachContext);

  const {isLoading, data,} = useQuery(
    ["coachWorkout", workoutId],
    () => api.coaching.workouts.get(workoutId),
    {
      enabled: !!workoutId,
    }
  );

  const updateCoachWorkoutMutation = useMutation((data) => api.coaching.workouts.update(workoutId, data));

  const [activeStep, setActiveStep] = useState(0);

  const handleOnNextStep = () => setActiveStep(prevStep => prevStep + 1 < steps.length ? prevStep + 1 : prevStep);

  const handleOnPrevStep = () => setActiveStep(prevStep => prevStep - 1 <= 0 ? prevStep - 1 : prevStep);

  const handleUpdateCoachWorkout = (data) => updateCoachWorkoutMutation.mutate(data, {
    onSuccess: () => {
      refetchCoach();
      queryClient.invalidateQueries({queryKey: ['coachWorkoutsListData']});
      history.push('/coaching/training');
      showAlert('Coach workout updated successfully.', {severity: 'success'});
    },
    onError: () => showAlert('Something went wrong.', {severity: 'error'}),
  });

  if (isLoading) {
    return <Spinner container/>;
  }

  if (!isLoading && !data) {
    history.push('/coaching/training');
  }


  return (
    <Box sx={{py: 1}}>
      <Container>
        <CustomBreadcrumbs
          heading="Workout Creator"
          links={[
            {
              name: 'Training Tools',
              href: '/coaching/training'
            },
            {
              name: 'Create New Workout',
            }
          ]}
        />

        <WorkoutCreatorStepper activeStep={activeStep}/>

        <WorkoutCreatorForm
          currenWorkout={data}
          onSubmit={handleUpdateCoachWorkout}
          isSubmitting={updateCoachWorkoutMutation.isLoading}
          workoutTags={coach?.workoutTags}
          activeStep={activeStep}
          onNextStep={handleOnNextStep}
          onPrevStep={handleOnPrevStep}
        />
      </Container>
    </Box>
  );
};

export default WorkoutEditorPage;
