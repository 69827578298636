import { useContext, useState } from 'react';
import { UserContext } from 'context';
import { Stack, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import ConfirmDialog from 'components/ConfirmDialog';
import { useMutation } from 'react-query';
import api from 'api';
import { useAlertContext } from 'components/alert/AlertContext';

const ProfileCoaches = () => {
  const { user, refetch } = useContext(UserContext);
  const { coaches } = user;

  if (!coaches?.length) return null;

  return (
    <>
      <h2 className='caption'>COACH{coaches.length > 1 ? 'ES' : null}</h2>
      <Stack
        sx={{
          borderRadius: '0.5rem',
          overflow: 'hidden',
          marginBottom: '1.5rem'
        }}>
        {coaches.map((coach) => (
          <CoachItem key={coach.id} coach={coach} refetch={refetch} />
        ))}
      </Stack>
    </>
  );
};

export default ProfileCoaches;

const CoachItem = ({ coach, refetch }) => {
  const [open, setOpen] = useState(false);
  const { showAlert } = useAlertContext();

  const { mutate, isLoading } = useMutation(api.user.purchases.leaveSquad, {
    onSuccess: () => {
      setOpen(false);
      refetch();
      showAlert('Disconnected from coach', { severity: 'success' });
    },
    onError: () => {
      showAlert('Error disconnecting from coach', { severity: 'error' });
    }
  });

  const onConfirm = () => {
    mutate(coach.id);
  };

  const subscriptions = [];
  if (coach.squad) subscriptions.push('Data');
  if (coach.workouts) subscriptions.push('Workouts');
  if (coach.chat) subscriptions.push('Chat');

  return (
    <>
      <Stack
        key={coach.coachId}
        direction='row'
        bgcolor={'backgrounds.2t'}
        sx={{
          justifyContent: 'space-between',
          padding: '0.75rem',
          borderBottom: '1px solid',
          borderColor: 'separator.main',
          '&:last-child': {
            border: 'none'
          }
        }}>
        <Stack>
          <Typography>
            {coach.firstName} {coach.lastName}
          </Typography>
          <Typography
            sx={{
              fontSize: '0.8rem',
              opacity: 0.6
            }}>
            {subscriptions.join(', ')}
          </Typography>
        </Stack>
        <LoadingButton
          variant='outlined'
          size='small'
          loading={false}
          onClick={() => setOpen(true)}>
          Disconnect
        </LoadingButton>
      </Stack>
      <ConfirmDialog
        open={open}
        handleClose={() => setOpen(false)}
        title='Disconnect from Coach?'
        text={`Are you sure you want to disconnect from ${coach.firstName} ${coach.lastName}? They will no longer have access to your training data and you will no longer have acces to their workouts.`}
        handleConfirm={onConfirm}
        confirmText='Disconnect'
        confirmIsLoading={isLoading}
      />
    </>
  );
};
