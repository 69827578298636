import { Chip, Stack, SvgIcon } from "@mui/material";
import { camelCaseToWords } from "../../util";
import TechtocIcon from "../Icons/TechIcon";

const EquipmentList = ({ equipment, sx = {}, keyPrefix }) => {
	return (
		<Stack direction="row" spacing={1} sx={sx}>
			{equipment.map((eq) => (
				<Chip
					key={`${keyPrefix}-${eq}`}
					icon={renderIcon(eq)}
					label={camelCaseToWords(eq)}
					size="small"
					sx={{ borderRadius: 1 }}
				/>
			))}
		</Stack>
	);
};

export default EquipmentList;

export const renderIcon = (eq) => {
	switch (eq) {
		case "bands":
			return <BandIcon />;
		case "baton":
			return <BatonIcon />;
		case "pullbuoy":
			return <BuoyIcon />;
		case "drill":
			return <DrillIcon />;
		case "fins":
			return <FinsIcon />;
		case "kickboard":
			return <KickboardIcon />;
		case "paddles":
			return <PaddlesIcon />;
		case "snorkel":
			return <SnorkelIcon />;
		case "techtoc":
			return <TechtocIcon />;
		default:
			return null;
	}
};

const BandIcon = (props) => (
	<SvgIcon
		{...props}
		style={{
			fillRule: "evenodd",
			clipRule: "evenodd",
			strokeLinejoin: "round",
			strokeMiterlimit: 2,
		}}
	>
		<path d="M5,12.821C5.02,12.839 5.04,12.856 5.06,12.874C5.401,13.181 5.768,13.524 6.173,13.903L6.173,13.903C6.926,14.608 7.812,15.438 8.924,16.391C10.507,17.748 12.61,18.2 14.629,18.297C15.633,18.344 16.6,18.303 17.451,18.249C17.69,18.234 17.925,18.217 18.149,18.201C18.484,18.177 18.796,18.155 19.075,18.141L19.075,12.424C18.873,12.428 18.654,12.434 18.42,12.44L18.42,12.44C17.217,12.472 15.622,12.514 14.048,12.392C11.908,12.227 9.604,11.752 8.245,10.393C7.802,9.951 7.403,9.495 7.027,9.066L6.875,8.894C6.45,8.41 6.052,7.969 5.627,7.588C5.425,7.408 5.218,7.242 5,7.093L5,12.821ZM18.621,11.434L18.334,11.442C17.141,11.473 15.643,11.512 14.125,11.395C11.996,11.23 10.032,10.765 8.952,9.686C8.534,9.268 8.157,8.838 7.779,8.408L7.779,8.408L7.626,8.233C7.202,7.75 6.769,7.268 6.294,6.843C6.028,6.605 5.748,6.384 5.447,6.189C5.649,6.173 5.846,6.156 6.038,6.14L6.116,6.133C7.089,6.049 7.911,5.979 8.729,6.006C10.37,6.06 12.038,6.508 14.735,8.194C15.363,8.587 16.334,9.303 17.222,10.072C17.665,10.455 18.081,10.846 18.42,11.211C18.492,11.288 18.559,11.362 18.621,11.434Z" />
	</SvgIcon>
);

const BatonIcon = (props) => (
	<SvgIcon
		{...props}
		style={{
			fillRule: "evenodd",
			clipRule: "evenodd",
			strokeLinejoin: "round",
			strokeMiterlimit: 2,
		}}
	>
		<path d="M14.139,6.636C13.542,6.875 12.785,7 12,7C11.215,7 10.458,6.875 9.861,6.636C9.741,6.588 9.619,6.532 9.5,6.467L9.5,8C9.5,8.552 10.619,9 12,9C13.381,9 14.5,8.552 14.5,8L14.5,6.467C14.381,6.532 14.259,6.588 14.139,6.636ZM12,4C10.619,4 9.5,4.448 9.5,5C9.5,5.552 10.619,6 12,6C13.381,6 14.5,5.552 14.5,5C14.5,4.448 13.381,4 12,4ZM10,20L10,9.688C10.574,9.893 11.275,10 12,10C12.725,10 13.426,9.893 14,9.688L14,20C14,20.552 13.105,21 12,21C10.895,21 10,20.552 10,20Z" />
	</SvgIcon>
);

const BuoyIcon = (props) => (
	<SvgIcon
		{...props}
		style={{
			fillRule: "evenodd",
			clipRule: "evenodd",
			strokeLinejoin: "round",
			strokeMiterlimit: 2,
		}}
	>
		<path d="M6.104,16.998C6.07,16.999 6.036,17 6,17C3.5,17 2,14.761 2,12C2,9.239 3.5,7 6,7C6.036,7 6.07,7.001 6.104,7.002C6.12,7.001 6.137,7 6.154,7C7.11,7 7.771,7.47 8.522,8.002C9.377,8.609 10.5,9.463 12,9.463C13.5,9.463 14.623,8.609 15.479,8.002C16.229,7.47 16.891,7 17.846,7C17.863,7 17.88,7.001 17.896,7.002C17.93,7.001 17.964,7 18,7C20.5,7 22,9.239 22,12C22,14.761 20.5,17 18,17C17.964,17 17.93,16.999 17.896,16.998C17.88,16.999 17.863,17 17.846,17C16.891,17 16.229,16.53 15.479,15.998C14.589,15.368 13.5,14.52 11.804,14.52C10.108,14.52 9.386,15.323 8.564,15.939C7.82,16.495 7.145,17 6.154,17C6.137,17 6.12,16.999 6.104,16.998Z" />
	</SvgIcon>
);

const DrillIcon = (props) => (
	<SvgIcon
		{...props}
		style={{
			fillRule: "evenodd",
			clipRule: "evenodd",
			strokeLinejoin: "round",
			strokeMiterlimit: 2,
		}}
	>
		<path d="M16.052,6.937C15.698,6.316 15.041,5.935 14.329,5.936L9.698,5.947C8.986,5.948 8.327,6.333 7.97,6.955L5.644,11.012C5.287,11.633 5.285,12.397 5.639,13.017L7.948,17.063C8.302,17.684 8.959,18.065 9.671,18.064L14.302,18.053C15.014,18.052 15.673,17.667 16.03,17.045L18.356,12.989C18.713,12.367 18.715,11.603 18.361,10.983L16.052,6.937ZM13.321,14.315C14.591,13.573 15.029,11.935 14.299,10.657C13.57,9.379 11.949,8.944 10.679,9.686C9.409,10.427 8.971,12.065 9.701,13.343C10.43,14.621 12.051,15.056 13.321,14.315Z" />
	</SvgIcon>
);

const FinsIcon = (props) => (
	<SvgIcon
		{...props}
		style={{
			fillRule: "evenodd",
			clipRule: "evenodd",
			strokeLinejoin: "round",
			strokeMiterlimit: 2,
		}}
	>
		<path d="M5.5,17C9.5,17 18,15.5 20,15C22,14.5 23.5,13.433 23.5,11.5C23.5,9.567 22,8.5 20,8C18,7.5 9.5,6 5.5,6L2.618,6C1.875,6 1.38,6.789 1.635,7.487C2.144,8.877 2.5,10.413 2.5,11.5C2.5,12.587 2.144,14.123 1.635,15.513C1.38,16.211 1.875,17 2.618,17L5.5,17ZM22,11.5C22,13.5 19.5,14 16.5,14C15.119,14 14,12.881 14,11.5C14,10.119 15.119,9 16.5,9C19,9 22,9.5 22,11.5ZM4.5,11C4.224,11 4,11.224 4,11.5C4,11.776 4.224,12 4.5,12L11.5,12C11.776,12 12,11.776 12,11.5C12,11.224 11.776,11 11.5,11L4.5,11Z" />
	</SvgIcon>
);

const KickboardIcon = (props) => (
	<SvgIcon
		{...props}
		style={{
			fillRule: "evenodd",
			clipRule: "evenodd",
			strokeLinejoin: "round",
			strokeMiterlimit: 2,
		}}
	>
		<path d="M6,10C6,6.686 8.686,4 12,4C15.314,4 18,6.686 18,10L18,18.102C18,18.906 17.056,19.443 16.31,19.142C15.248,18.714 13.749,18.286 12,18.286C10.251,18.286 8.752,18.714 7.69,19.142C6.944,19.443 6,18.906 6,18.102L6,10Z" />
	</SvgIcon>
);

const PaddlesIcon = (props) => (
	<SvgIcon
		{...props}
		style={{
			fillRule: "evenodd",
			clipRule: "evenodd",
			strokeLinejoin: "round",
			strokeMiterlimit: 2,
		}}
	>
		<path d="M8.911,18.369C10.366,17.426 12.107,17.01 13.839,17.201C15.749,17.412 17.199,15.515 16.494,13.727L16.45,13.615C15.851,12.094 14.833,9.51 13.471,7.41C13.446,7.373 13.422,7.336 13.398,7.299C14.075,6.954 14.797,6.836 15.56,7.04C19.387,8.066 18.264,15.576 17.773,18.863C17.769,18.895 17.764,18.926 17.759,18.958C17.586,20.114 16.246,20.672 15.304,19.98C13.578,18.714 11.44,18.141 9.313,18.375C9.174,18.39 9.04,18.387 8.911,18.369Z" />
		<path d="M7.701,4.872C11.527,3.847 14.311,10.913 15.528,14.004L15.564,14.094C15.992,15.182 15.111,16.335 13.948,16.207C11.821,15.973 9.683,16.546 7.957,17.812C7.015,18.504 5.675,17.946 5.502,16.79L5.488,16.695C4.999,13.424 3.875,5.898 7.701,4.872ZM10.279,8.727C10.529,8.61 10.827,8.719 10.943,8.969L13.175,13.754C13.291,14.005 13.183,14.302 12.933,14.419C12.683,14.536 12.385,14.427 12.268,14.177L10.037,9.392C9.92,9.141 10.029,8.844 10.279,8.727ZM6.941,10.041C6.917,9.766 7.121,9.524 7.396,9.5C7.671,9.476 7.913,9.679 7.937,9.954L8.398,15.214C8.422,15.489 8.218,15.732 7.943,15.756C7.668,15.78 7.425,15.576 7.401,15.301L6.941,10.041Z" />
	</SvgIcon>
);

const SnorkelIcon = (props) => (
	<SvgIcon
		{...props}
		style={{
			fillRule: "evenodd",
			clipRule: "evenodd",
			strokeLinejoin: "round",
			strokeMiterlimit: 2,
		}}
	>
		<path d="M13,15.5L13,5.611C13.437,5.869 13.964,6 14.5,6C15.036,6 15.564,5.869 16,5.611L16,15.5C16,17.985 13.985,20 11.5,20C9.128,20 7.185,18.165 7.012,15.838C7.47,15.945 7.979,16 8.5,16C9.039,16 9.565,15.941 10.036,15.826C10.185,16.498 10.784,17 11.5,17C12.328,17 13,16.328 13,15.5ZM14.5,3C15.328,3 16,3.448 16,4C16,4.552 15.328,5 14.5,5C13.672,5 13,4.552 13,4C13,3.448 13.672,3 14.5,3ZM11,14C11,14.552 9.881,15 8.5,15C7.119,15 6,14.552 6,14C6,13.448 7.119,13 8.5,13C9.881,13 11,13.448 11,14Z" />{" "}
	</SvgIcon>
);
