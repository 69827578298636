import { useState, useEffect, useLayoutEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import {parseChartData} from "../components/charts/chartUtilities";
export const useOnClickOutside = (ref, handler) => {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
};

export const useIntersection = (element, rootMargin) => {
  const [isVisible, setState] = useState(false);

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting);
      },
      { rootMargin }
    );

    element.current && observer.observe(element.current);

    return () => observer.unobserve(element.current);
  }, []);

  return isVisible;
};

export const useQueryParams = () => {
  const { search } = useLocation();

  return useMemo(() => {
    const params = new URLSearchParams(search);
    const entries = params.entries();
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }, [search]);
};

export const useResponsive = (query, start, end) => {
  const theme = useTheme();

  const mediaUp = useMediaQuery(theme.breakpoints.up(start));

  const mediaDown = useMediaQuery(theme.breakpoints.down(start));

  const mediaBetween = useMediaQuery(theme.breakpoints.between(start, end));

  const mediaOnly = useMediaQuery(theme.breakpoints.only(start));

  if (query === 'up') {
    return mediaUp;
  }

  if (query === 'down') {
    return mediaDown;
  }

  if (query === 'between') {
    return mediaBetween;
  }

  return mediaOnly;
}

export const useActivityChart = (activity) => {
  const [isStroke, setIsStroke] = useState(false)
  const [activeSegment, setIsActiveSegment] = useState(null)
  const [segments, setSegments] = useState(null)
  const [data, setData] = useState(null)
  const { analytics } = activity

  useEffect(() => {
    const segments = []
    activity.sets.forEach(set => {
      set.segments.forEach(segment => {
        segments.push(segment)
      })
    })
    setSegments(segments)

    const data = parseChartData(activity)
    setData(data)
  }, [activity])


  return {segments, data, analytics, isStroke, setIsStroke, activeSegment, setIsActiveSegment};
}