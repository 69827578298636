// @mui
import {Stack, Skeleton, Paper} from '@mui/material';

// ----------------------------------------------------------------------

const SkeletonActivityItem = () => {
  return (
    <Paper variant="outlined" sx={{ py: 3, px: 2, borderRadius: 0, borderTop: 'none', borderRight: 'none', borderLeft: 'none' }}>
      <Stack spacing={1.5}>
        <Skeleton variant="rectangular" sx={{ width: 0.32, height: 16,  borderRadius: 1 }} />
        <Skeleton variant="rectangular" sx={{ width: 0.25, height: 42, borderRadius: 1 }} />
      </Stack>
    </Paper>
  );
};

export default SkeletonActivityItem;
