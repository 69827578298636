import { useContext } from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { secondsToHMS } from 'util';
import { UserContext } from 'context';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MinimizeIcon from '@mui/icons-material/Minimize';

const pl = 2;
const pb = 1;

const HeartRateZonesWidget = ({
  timeInHeartRateZonesSec,
  timeInHeartRateZoneTrends,
  hideTitle,
  includedActivityTypes,
}) => {
  const secondsArray = Object.values(timeInHeartRateZonesSec);
  const arrowsArray =
    !!timeInHeartRateZoneTrends && Object.values(timeInHeartRateZoneTrends);
  const total = secondsArray.reduce((a, b) => a + b, 0);

  return (
    <Box>
      {!hideTitle && (
        <Typography variant='h6' sx={{ mb: 1 }}>
          Time in heart rate zone
        </Typography>
      )}
      <Box sx={{ display: 'table', width: '100%' }}>
        <Box sx={{ display: 'table-row' }}>
          <Box sx={{ display: 'table-cell', width: '100%' }}>
            <Typography variant='body1' component='p' align='left' />
          </Box>
          {!!arrowsArray && (
            <Box sx={{ display: 'table-cell', whiteSpace: 'nowrap' }} pl={pl}>
              <Typography variant='body1' component='p' align='center' />
            </Box>
          )}
          <Box sx={{ display: 'table-cell', whiteSpace: 'nowrap' }} pl={pl}>
            <Typography variant='body1' component='p' align='center'>
              mins
            </Typography>
          </Box>
          <Box sx={{ display: 'table-cell', whiteSpace: 'nowrap' }} pl={pl}>
            <Typography variant='body1' component='p' align='center' />
          </Box>
        </Box>
        {secondsArray
          .map((seconds, index) => {
            if (!seconds) return null;
            return (
              <HRZoneRow
                zone={index + 1}
                timeInZoneSec={seconds}
                total={total}
                arrow={!!arrowsArray && arrowsArray[index]}
              />
            );
          })
          .reverse()}
      </Box>
      <Stack direction='row' sx={{ justifyContent: 'space-between' }}>
        <Box>
          {!!includedActivityTypes && (
            <Typography variant='body1' component='p' align='left'>
              Includes: {includedActivityTypes.join(', ')}
            </Typography>
          )}
        </Box>
        <Typography align='right'>
          Total active time: {secondsToHMS(total)}
        </Typography>
      </Stack>
    </Box>
  );
};
export default HeartRateZonesWidget;

const getZoneBounds = (zone, hearRateZoneBounds) => {
  switch (zone) {
    case 1:
      return `<${Math.round(hearRateZoneBounds.a[1])}`;
    case 2:
      return `${Math.round(hearRateZoneBounds.b[0])}-${Math.round(
        hearRateZoneBounds.b[1]
      )}`;
    case 3:
      return `${Math.round(hearRateZoneBounds.c[0])}-${Math.round(
        hearRateZoneBounds.c[1]
      )}`;
    case 4:
      return `${Math.round(hearRateZoneBounds.d[0])}-${Math.round(
        hearRateZoneBounds.d[1]
      )}`;
    case 5:
      return `>${Math.round(hearRateZoneBounds.e[1])}`;
    default:
      return null;
  }
};

const HRZoneRow = ({ zone, timeInZoneSec, total, arrow }) => {
  const {
    user: { hearRateZoneBounds },
  } = useContext(UserContext);

  const colors = {
    1: '#00CDFF',
    2: '#1ADEA3',
    3: '#E4F14F',
    4: '#F38D2E',
    5: '#F1584F',
  };

  const trendIcons = {
    up: <ArrowDropUpIcon sx={{ color: colors['2'] }} fontSize='large' />,
    down: <ArrowDropDownIcon sx={{ color: colors['5'] }} fontSize='large' />,
    flat: '-',
  };

  return (
    <Box sx={{ display: 'table-row' }}>
      <Box
        sx={{ display: 'table-cell', width: '100%', verticalAlign: 'middle' }}
        pb={pb}
      >
        <Stack direction='row' spacing={0} sx={{ alignItems: 'center' }}>
          <Box
            sx={{
              background: colors[zone],
              width: `${(timeInZoneSec / total) * 100}%`,
              height: '24px',
            }}
          />
          <Box sx={{ display: 'table-cell', whiteSpace: 'nowrap' }} pl={pl}>
            <Typography align='left'>Zone {zone}</Typography>
            <Typography align='left' sx={{ fontSize: '0.7rem', opacity: 0.7 }}>
              {getZoneBounds(zone, hearRateZoneBounds)} bpm
            </Typography>
          </Box>
        </Stack>
      </Box>
      {!!arrow && (
        <Box
          sx={{
            display: 'table-cell',
            whiteSpace: 'nowrap',
            verticalAlign: 'middle',
          }}
          pl={pl}
          pb={pb}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {trendIcons[arrow]}
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: 'table-cell',
          whiteSpace: 'nowrap',
          verticalAlign: 'middle',
        }}
        pl={pl}
        pb={pb}
      >
        <Typography align='center' sx={{ fontSize: '1.4rem' }}>
          {secondsToHMS(timeInZoneSec)}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'table-cell',
          whiteSpace: 'nowrap',
          verticalAlign: 'middle',
        }}
        pl={pl}
        pb={pb}
      >
        <Typography align='center' sx={{ opacity: 0.7 }}>
          {Math.round((timeInZoneSec / total) * 100)}%
        </Typography>
      </Box>
    </Box>
  );
};
