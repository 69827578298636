import { useState } from 'react';
import { Stack, Box, Typography } from '@mui/material';
import HeartRateZonesWidget from 'components/common/HeartRateZonesWidget';
import Selector from 'components/forms/Selector';
import MultipleSelectCheckmarks from 'components/forms/MultipleSelectCheckMarks';
import { arraysHaveSameElements } from 'util';
import api from 'api';
import Spinner from 'components/common/Spinner';
import { capitalize } from 'util';

const formatTimeWindowString = (str) => {
  const regex = /days(\d+)/;
  return str.replace(regex, (match, number) => `Last ${number} days`);
};

const ProgressCardio = ({
  allUserActivityTypes,
  cardioReport,
  activityType,
}) => {
  //   console.log('cardioReport', cardioReport);
  const timeWindows = Object.keys(cardioReport);

  const [cardioProgress, setCardioProgress] = useState(cardioReport);
  const [timeWindow, setTimeWindow] = useState(timeWindows[0]);
  const [selectedActivityTypes, setSelectedActivityTypes] =
    useState(allUserActivityTypes);
  const [loading, setLoading] = useState(false);

  const onCloseActivitySelector = () => {
    if (
      !arraysHaveSameElements(
        cardioReport[timeWindow].selectedActivityTypes,
        selectedActivityTypes
      )
    ) {
      setLoading(true);
      api.progress
        .getCardioReport(null, selectedActivityTypes)
        .then((res) => {
          setCardioProgress(res.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onUpdateTimeWindow = (value) => {
    setTimeWindow(value);
  };

  return (
    <>
      <Stack direction='row' sx={{ justifyContent: 'space-between' }}>
        <Selector
          items={timeWindows.map((key) => ({
            text: formatTimeWindowString(key),
            value: key,
          }))}
          value={timeWindow}
          onUpdate={onUpdateTimeWindow}
        />
        {activityType === 'all' && (
          <MultipleSelectCheckmarks
            items={allUserActivityTypes}
            value={selectedActivityTypes}
            onUpdate={(value) => setSelectedActivityTypes(value)}
            onClose={onCloseActivitySelector}
            disabled={loading}
            renderValue={`${selectedActivityTypes.length} Activity Types`}
          />
        )}
      </Stack>
      <Box sx={{ position: 'relative' }}>
        {loading && (
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Spinner />
          </Box>
        )}
        {!!cardioProgress[timeWindow].data ? (
          <HeartRateZonesWidget
            timeInHeartRateZonesSec={parseTimeZoneInSecs(
              cardioProgress[timeWindow].data
            )}
            timeInHeartRateZoneTrends={parseZoneTrends(
              cardioProgress[timeWindow].data
            )}
            hideTitle
            includedActivityTypes={selectedActivityTypes
              .filter((type) =>
                cardioProgress[timeWindow].allActivityTypes.includes(type)
              )
              .map((str) => capitalize(str))}
          />
        ) : (
          <Box sx={{ p: 2 }}>
            <Typography textAlign='center' variant='h6'>
              No data for those activities in time period
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ProgressCardio;

export const parseTimeZoneInSecs = (data) => {
  return Object.keys(data).reduce((acc, key) => {
    acc[key] = data[key].totalTimeInZoneSec;
    return acc;
  }, {});
};

export const parseZoneTrends = (data) => {
  return Object.keys(data).reduce((acc, key) => {
    acc[key] = data[key].totalTimeInZoneTrend;
    return acc;
  }, {});
};
