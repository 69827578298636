import SvgIcon from '@mui/material/SvgIcon';

const ElevationIcon = props => {
	return (
		<svg width="36" height="38" viewBox="0 0 36 38" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M14.334 3.95898C14.8909 3.95898 15.3927 4.29414 15.6042 4.80977L24.8098 27.164L22.2678 28.2124L14.3342 8.94524L3.55419 35.1252H28.5413V37.8752H1.50027C1.04135 37.8752 0.613402 37.6466 0.357278 37.2651C0.102905 36.8835 0.0547774 36.4006 0.228371 35.976L13.0623 4.80994C13.2737 4.29431 13.7754 3.95898 14.334 3.95898Z" fill="#FF59B9"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M26.7109 16.8721C27.0649 16.9976 27.3502 17.2606 27.5032 17.6026L35.7532 35.9365C35.9457 36.3611 35.9079 36.8543 35.6552 37.2462C35.4009 37.6381 34.966 37.8753 34.5002 37.8753H25.7913V35.1253H32.3742L25.5832 20.0381L21.3654 22.1471L20.1348 19.6876L25.6348 16.9376C25.9699 16.7691 26.3585 16.7467 26.7109 16.8721Z" fill="#FF59B9"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M26.1949 0.694995C26.7312 0.157007 27.6025 0.157007 28.1388 0.694995L33.6938 6.25L31.7499 8.19391L27.166 3.61176L22.5838 8.19391L20.6382 6.25L26.1949 0.694995Z" fill="#FF59B9"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M25.791 13.5839V1.66602H28.541V13.5839H25.791Z" fill="#FF59B9"/>
		</svg>
	);
}

export default ElevationIcon;
