import PropTypes from 'prop-types';
// form
import {useFormContext, Controller} from 'react-hook-form';
// @mui
import {Switch, FormControlLabel} from '@mui/material';

// ----------------------------------------------------------------------

RHFSwitch.propTypes = {
  name: PropTypes.string,
};

export default function RHFSwitch({name, size, onChange, ...other}) {
  const {control} = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({field}) => (
        <FormControlLabel
          control={(
            <Switch
              {...field}
              onChange={(event) => {
                field.onChange(event);
                onChange && onChange(!field.value);
              }}
              checked={field.value}
              size={size}
            />
          )}
          {...other}
        />
      )}
    />
  );
}
