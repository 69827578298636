import { useState } from "react";
import Input from "../forms/Input";
import Selector from "../forms/Selector";
import { useMutation } from "react-query";
import api from "api";
// import PropTypes from 'prop-types';
import { trackUpdatePoolPreference } from "../../tracking";
import {
  Button,
  // Dialog,
  DialogActions,
  DialogContent,
  // DialogContentText,
  DialogTitle,
} from "@mui/material";

const ProfilePoolForm = ({ open, handleClose, user, refetch }) => {
  const defaultForm = {
    lapUnit: user.lapUnit,
    lapLength: user.lapLength,
  };
  const [form, setForm] = useState(defaultForm);
  const mutation = useMutation((data) => api.user.update(data), {
    onSuccess: () => {
      refetch();
      handleClose();
      const lapUnit =
        defaultForm.lapUnit !== form.lapUnit ? form.lapUnit : null;
      const lapLength =
        defaultForm.lapLength !== form.lapLength ? form.lapLength : null;
      trackUpdatePoolPreference(lapLength, lapUnit);
    },
  });

  const submit = () => {
    mutation.mutate(form);
  };

  return (
    <>
      <DialogTitle className="title">Pool Preferences</DialogTitle>
      <DialogContent sx={{ minWidth: { md: 450 } }}>
        <Input
          type="number"
          label="Lap length"
          value={form.lapLength}
          onUpdate={(val) => setForm({ ...form, lapLength: val })}
        />
        <Selector
          items={[
            { text: "Meters", value: "m" },
            { text: "Yards", value: "y" },
          ]}
          label="Lap unit"
          value={form.lapUnit}
          onUpdate={(val) => setForm({ ...form, lapUnit: val })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <button
          className="button"
          onClick={submit}
          disabled={mutation.isLoading}
        >
          Submit
        </button>
      </DialogActions>
    </>
  );
};

// ProfilePoolForm.propTypes = {
//   : PropTypes.
// };

export default ProfilePoolForm;
