import {useState} from "react";
import {Box, Typography, Stack, Card, Button} from "@mui/material";


const HowToUse = () => {
  const [isHide, setIsHide] = useState(localStorage.getItem("hideHowToUse"));

  if (isHide) {
    return null;
  }

  return (
    <Card sx={{backgroundColor: "background.secondary", p: 2}}>
      <Stack gap={2}>
        <Typography variant="h5">
          How to use Simma
        </Typography>
        <Box>
          <Typography variant="caption" color="text.secondary">
            Garmin watch users, record your activities with the Garmin watch applications and your activities
            will flow into Simma via Garmin Connect.<br/>

            Apple Watch users, record your swims and runs using the Apple Watch application available in beta from
            TestFlight.<br/>

            Setting your FTP for running and swimming will improve the statistics for these activities and scale
            workouts to your pace and pace zones.<br/>

            Setting your age range in your profile will let Simma apply heart rate zones to your activity views.
          </Typography>
        </Box>
        <Box sx={{textAlign: 'right'}}>
          <Button variant="outlined" onClick={() => {
            localStorage.setItem("hideHowToUse", "true");
            setIsHide("true");
          }}>
            GOT IT!
          </Button>
        </Box>
      </Stack>
    </Card>
  );
}

export default HowToUse;