import { useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
// @mui
import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import { RHFTextField } from '../../../../components/hook-form';

const MIN_WIDTH = 40;

const MAX_WIDTH = 200;

const CustomTextField = styled(RHFTextField, {
  shouldForwardProp: (prop) => prop !== 'width'
})(({ width, theme }) => ({
  '&, & .MuiInput-root': {
    minWidth: MIN_WIDTH,
    maxWidth: MAX_WIDTH,
    width: 'auto'
  },
  '& .MuiInput-underline': {
    minWidth: MIN_WIDTH,
    maxWidth: MAX_WIDTH,
    width,
    '&:not(.Mui-error):before': {
      borderBottomColor: 'transparent'
    },
    fontSize: theme.typography.h5.fontSize
  }
}));

const FullNameTextField = () => {
  const refFN = useRef(null);

  const refLN = useRef(null);

  const { control } = useFormContext();

  const [width, setWiths] = useState({
    firstName: 1,
    lastName: 1
  });

  const [firstName, lastName] = useWatch({
    control,
    name: ['firstName', 'lastName']
  });

  // const error = !firstName || !lastName;

  useEffect(() => {
    if (refFN.current) {
      setWiths((prevState) => ({ ...prevState, firstName: refFN.current.offsetWidth }));
    }
    if (refLN.current) {
      setWiths((prevState) => ({ ...prevState, lastName: refLN.current.offsetWidth }));
    }
  }, [firstName, lastName, refFN, refLN]);

  return (
    <Box position='relative'>
      <Stack direction='row' gap={1}>
        <CustomTextField
          width={width.firstName}
          name='firstName'
          variant='standard'
          helperText={null}
          placeholder='First name'
        />
        <CustomTextField
          width={width.lastName}
          name='lastName'
          variant='standard'
          helperText={null}
          placeholder='Last'
        />
      </Stack>
      <Stack
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1,
          opacity: 0
        }}
        direction='row'
        gap={1}>
        <Typography ref={refFN} variant='h5'>
          {firstName || 'First'}
        </Typography>
        <Typography ref={refLN} variant='h5'>
          {lastName || 'Last'}
        </Typography>
      </Stack>
      {/*{!!error && (*/}
      {/*  <FormHelperText error>*/}
      {/*    First name and Last name is required*/}
      {/*  </FormHelperText>*/}
      {/*)}*/}
    </Box>
  );
};

export default FullNameTextField;
