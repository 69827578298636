import { useState, useRef } from "react";
// import PropTypes from 'prop-types';
import { useOnClickOutside } from "../../hooks";
import { Select, MenuItem } from "@mui/material";

const Selector = ({
  // type = "text",
  label,
  name,
  items,
  value,
  onUpdate,
  updateWithName,
  // dividorIndex,
  // small,
  wrapperStyle = {},
  size = "small",
}) => {
  // const ref = useRef();
  // const [open, setOpen] = useState(false);
  // useOnClickOutside(ref, () => setOpen(false));

  const handleSelect = (event) => {
    const value = event.target.value;
    onUpdate(!!updateWithName && !!name ? { [name]: value } : value);
    // setOpen(false);
  };

  return (
    <div className="input-wrapper" style={wrapperStyle}>
      {label && <label>{label}</label>}
      <Select label="" value={value} onChange={handleSelect} size={size}>
        {items.map((item) => {
          return (
            <MenuItem key={item.text} value={item.value}>
              {item.text}
            </MenuItem>
          );
        })}
      </Select>
      {/* <div className={!!small ? "selector--small" : "selector"} ref={ref}>
        <div className="selector__input-wrapper" onClick={() => setOpen(!open)}>
          <input
            type={type}
            name={name}
            value={
              items.find(
                (item) =>
                  item.value === value ||
                  JSON.stringify(item.value) === JSON.stringify(value)
              ).text
            }
            disabled
          />
        </div>
        <div className={`selector__list${open ? "--open" : ""}`}>
          {items.map((item, i) => {
            const list = [];
            list.push(
              <div
                key={item.value}
                className="selector__item"
                onClick={() => handleSelect(item)}
              >
                {item.text || item.value}
              </div>
            );
            if (i === dividorIndex) {
              list.push(
                <div key={item.value + "d"} className="list-dividor" />
              );
            }
            return list;
          })}
        </div>
      </div> */}
    </div>
  );
};

// Selector.propTypes = {
//   : PropTypes.
// };

export default Selector;
