import { userApi } from './axios';

export const get = async () => {
  const { data } = await userApi.get('user');
  return data;
};

export const update = async (body) => {
  const { data } = await userApi.patch('user', body);
  return data;
};

// Request to be a coach
export const requestToBeACoach = async (body) => {
  const { data } = await userApi.post('contact/contact-form', body);
  return data;
};

// Purchases
const listPurchases = async () => {
  const { data } = await userApi.get('user/purchases');
  return data;
};

const cancelPurchase = async (id) => {
  const { data } = await userApi.get(`user/purchases/${id}/cancel`);
  return data;
};

const renewPurchase = async (id) => {
  const { data } = await userApi.get(`user/purchases/${id}/renew`);
  return data;
};

const joinSquad = async (coachId) => {
  const { data } = await userApi.post(`user/join-squad`, { coachId });
  return data;
};

const leaveSquad = async (coachId) => {
  console.log('coachId', coachId);
  const { data } = await userApi.delete(`/user/coach-connection/${coachId}`);
  return data;
};

export const purchases = {
  list: listPurchases,
  cancel: cancelPurchase,
  renew: renewPurchase,
  joinSquad,
  leaveSquad
};
