import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// form
import { useForm } from 'react-hook-form';
// @mui
import { Link, Stack, Alert, Box, InputLabel, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// components
import FormProvider, { RHFTextField } from '../hook-form';
import { useAuthContext } from '../../auth/useAuthContext';
import { PASSWORD_PATTERNS } from './utils';

// ----------------------------------------------------------------------

const ValidationMessage = ({ match, str, message }) => {
  const color = str.match(match) ? 'success.main' : 'text.disabled';
  return (
    <Stack direction='row' gap={1} alignItems='center'>
      <CheckCircleOutlineIcon sx={{ fontSize: 14, color }} />
      <Typography sx={{ fontSize: 12 }} variant='body2' color={color}>
        {message}
      </Typography>
    </Stack>
  );
};

const AuthRegisterForm = ({ onLogin, onVerifyEmail, createCoachProfile, coachInviteCode }) => {
  const { register } = useAuthContext();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email.').required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must contain at least 8 characters')
      .matches(PASSWORD_PATTERNS.lowerCase, 'Password must contain a lower case letter')
      .matches(PASSWORD_PATTERNS.upperCase, 'Password must contain an upper case letter')
      .matches(PASSWORD_PATTERNS.specialCharacter, 'Password must contain a special character')
      .matches(PASSWORD_PATTERNS.number, 'Password must contain a number')
      .matches(PASSWORD_PATTERNS.space, 'Password cannot contain spaces')
  });

  const defaultValues = {
    email: '',
    password: ''
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues
  });

  const {
    reset,
    watch,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful }
  } = methods;

  const { password } = watch();

  const onSubmit = async (data) => {
    try {
      await register({ ...data, createCoachProfile, coachInviteCode });

      sessionStorage.setItem('email-verify', data.email);

      onVerifyEmail(data);
    } catch (error) {
      console.error(error);

      const message =
        typeof error?.response?.data === 'string' ? error.response.data : error.message;

      reset();

      setError('afterSubmit', {
        ...error,
        message
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ px: 2, py: 3 }}>
        <Stack gap={3}>
          <Typography variant='body1'>Create an account with Simma</Typography>

          {!!errors.afterSubmit && <Alert severity='error'>{errors.afterSubmit.message}</Alert>}

          <Box>
            <InputLabel sx={{ mb: 0.5 }}>Email</InputLabel>
            <RHFTextField name='email' placeholder='name@domain.com' />
          </Box>

          <Box>
            <InputLabel sx={{ mb: 0.5 }}>Password</InputLabel>
            <RHFTextField name='password' placeholder='Password' type='password' />
          </Box>

          {password && (
            <Stack gap={0.5}>
              <ValidationMessage
                match={PASSWORD_PATTERNS.lowerCase}
                str={password}
                message='Password must contain a lower case letter'
              />
              <ValidationMessage
                match={PASSWORD_PATTERNS.upperCase}
                str={password}
                message='Password must contain an upper case letter'
              />
              <ValidationMessage
                match={PASSWORD_PATTERNS.minLength}
                str={password}
                message='Password must contain at least 8 characters'
              />
              <ValidationMessage
                match={PASSWORD_PATTERNS.specialCharacter}
                str={password}
                message='Password must contain a special character'
              />
              <ValidationMessage
                match={PASSWORD_PATTERNS.number}
                str={password}
                message='Password must contain a number'
              />
            </Stack>
          )}

          <LoadingButton
            sx={{ textTransform: 'capitalize' }}
            size='large'
            fullWidth
            type='submit'
            variant='gradient'
            loading={isSubmitSuccessful || isSubmitting}>
            Join Simma
          </LoadingButton>

          <Stack direction='row' gap={1} justifyContent='center' alignItems='center'>
            <Typography variant='body1' color='text.secondary'>
              Already have an account?
            </Typography>

            <Link onClick={onLogin} variant='body1' underline='none'>
              Log in
            </Link>
          </Stack>
        </Stack>
      </Box>
    </FormProvider>
  );
};

export default AuthRegisterForm;
