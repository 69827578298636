import {useEffect, useState} from "react";
import {useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
// @mui
import {Box, Button, IconButton, Stack, styled, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
// icons
import FlagIcon from '@mui/icons-material/Flag';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
// components
import {useAlertContext} from "../../../../components/alert/AlertContext";
import {RHFSwitch} from "../../../../components/hook-form";
import {ConfirmDialog} from "../../../../components/confirm-dialog";
import TimeField from "../../sections/formSections/formFields/TimeField";
import SetSection from "../../sections/formSections/SetSection";
import CoachingSetsDrawer from "./CoachingSetsDrawer";
import {trackAddSetToWorkout, trackSaveSet} from "../../../../tracking";
import {calculateTargetTime, createSet} from "../../../../components/WorkoutCreator/utils";
import {secondsToHMS} from "../../../../util";
import SetPreviewModal from "../../../../components/WorkoutCreator/SetPreviewModal";
import {useMutation, useQueryClient} from "react-query";
import api from "../../../../api";


export const StyledIconButton = styled(IconButton)(({theme}) => ({
  transitions: theme.transitions.create('opacity'),
  '&:not(:hover)': {
    opacity: .6,
  }
}));

const WorkoutFTPSection = ({hasSplits}) => {
  const [distance, duration] = useWatch({
    name: ['distance', 'duration']
  });


  return (
    <Stack direction={{xs: 'column', sm: 'row'}} alignItems={{sm: 'center'}} justifyContent="space-between" gap={2}>
      <Box maxWidth={400}>
        <TimeField
          disabled={hasSplits}
          name="baseFtp"
          label="Add workout FTP"
          variant="filled"
        />
        <Box sx={{px: 1.5}}>
          <Typography variant="caption" color="text.secondary">
            Workouts need an FTP so they can be scaled for each swimmer. Format of FTP is x:xx / 100m
          </Typography>
        </Box>
      </Box>
      <Typography variant="body2" color="text.secondary">Workout Totals</Typography>
      <Box display="flex" gap={.5}>
        <FlagIcon opacity={.55}/>
        <Typography variant="body1">{distance}</Typography>
      </Box>
      <Box display="flex" gap={.5}>
        <AccessTimeIcon opacity={.55}/>
        <Typography variant="body1">{secondsToHMS(duration)}</Typography>
      </Box>
      <RHFSwitch name="published" label="Publish Workout"/>
    </Stack>
  );
}

const WorkoutSetEditActions = ({onSave, onAdd, isAdding}) => (
  <Box display="flex" justifyContent="end" gap={2}>
    <LoadingButton loading={isAdding} variant="outlined" onClick={onAdd}>Add to Set library</LoadingButton>
    <Button variant="contained" onClick={onSave}>Save & close</Button>
  </Box>
);

const WorkoutSetViewActions = ({onEdit}) => (
  <Box display="flex" justifyContent="end" gap={2}>
    <Button variant="contained" onClick={onEdit}>edit</Button>
  </Box>
);

const WorkoutSetToolbar = ({baseName, onPreview, onDelete, isLocked, dragHandleProps}) => {
  const {setValue, getValues} = useFormContext();
  const [totalDistance, totalTime] = useWatch({name: [baseName + 'totalDistance', baseName + 'totalTime']});

  useEffect(() => {
    const sets = getValues('sets');
    const distance = getTotalWorkoutDistance(sets);
    setValue('distance', distance);
  }, [totalDistance])

  useEffect(() => {
    const sets = getValues('sets');
    const duration = getTotalWorkoutDuration(sets);
    setValue('duration', duration);
  }, [totalTime])


  return (
    <Box display="flex" justifyContent="space-between">
      {!isLocked && (
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography variant="body2" color="text.secondary">Set Totals</Typography>
          <Box display="flex" gap={.5}>
            <FlagIcon opacity={.55}/>
            <Typography variant="body1">{totalDistance ?? '0'}</Typography>
          </Box>
          <Box display="flex" gap={.5}>
            <AccessTimeIcon opacity={.55}/>
            <Typography variant="body1">{secondsToHMS(totalTime || 0)}</Typography>
          </Box>
        </Stack>
      )}

      <Box display="flex" gap={1}>
        {isLocked && (
          <StyledIconButton size="small" {...dragHandleProps}>
            <DragIndicatorIcon fontSize="small"/>
          </StyledIconButton>
        )}
        <StyledIconButton size="small" onClick={onPreview}>
          <VisibilityIcon fontSize="small"/>
        </StyledIconButton>
        <StyledIconButton size="small" onClick={onDelete}>
          <DeleteIcon fontSize="small"/>
        </StyledIconButton>
      </Box>
    </Box>
  );
}

const SetLibraryActions = ({onCreate, onAdd}) => {
  const baseFtp = useWatch({name: 'baseFtp'})

  return (
    <Box sx={{display: 'flex', gap: 2, px: 1, pb: 4, flexDirection: {xs: 'column', sm: 'row'}}}>
      <Button
        disabled={!baseFtp}
        onClick={onCreate}
        variant="outlined"
        startIcon={<AddIcon/>}
      >
        create new set
      </Button>
      <Button
        disabled={!baseFtp}
        onClick={onAdd}
        variant="outlined"
        startIcon={<AddIcon/>}
      >
        add set from library
      </Button>
    </Box>
  );
}

const DragAndDropWrap = ({onDragEnd, children}) => (
  <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId="all-columns" direction="vertical" type="column">
      {(provided) => (
        <Stack {...provided.droppableProps} ref={provided.innerRef} gap={3}>
          {children}
          {provided.placeholder}
        </Stack>
      )}
    </Droppable>
  </DragDropContext>
);

const WorkoutSetsStep = () => {
  const {showAlert} = useAlertContext();

  const queryClient = useQueryClient();

  const {control, trigger, setValue, getValues} = useFormContext();

  const {fields, append, remove, move} = useFieldArray({
    control,
    name: 'sets',
  });

  const createCoachWorkoutSetMutation = useMutation(api.coaching.workoutSets.create);

  const handleCreateCoachSetWorkout = (data) => createCoachWorkoutSetMutation.mutate(data, {
    onSuccess: (data) => {
      const {
        baseFtp: setBaseFtp,
        distance: setDistance,
        repeat: setRepeat,
        totalDistance: setTotalDistance,
        coachId,
        flavor: setFlavor,
        segmentCount: setSegmentCount,
        equipment: setEquipment,
      } = data;

      trackSaveSet({
        coachId,
        setBaseFtp,
        setDistance,
        setRepeat,
        setTotalDistance,
        setFlavor,
        setSegmentCount,
        setHasEquipment: !!setEquipment?.length,
        setEquipment,
        isEdit: false,
        setIsSplit: data.segments?.[0]?.isSplit,
      });
      queryClient.invalidateQueries({queryKey: ['coachSets']});
      showAlert('Coach workout set created successfully.', {severity: 'success'});
    },
    onError: () => showAlert('Something went wrong.', {severity: 'error'}),
  });

  const [deleteDialog, setDeleteDialog] = useState({open: false, index: undefined});

  const [previewDialog, setPreviewDialog] = useState({open: false, index: undefined});

  const [openLibrary, setOpenLibrary] = useState(false);

  const handleOpenDeleteDialog = (index) => {
    setDeleteDialog({open: true, index});
  }

  const handleCloseDeleteDialog = () => {
    setDeleteDialog({open: false, index: undefined});
  }

  const handleOpenPreviewDialog = (index) => {
    setPreviewDialog({open: true, index});
  }

  const handleClosePreviewDialog = () => {
    setPreviewDialog({open: false, index: undefined});
  }

  const recalculateFormData = () => {
    const sets = getValues('sets');
    const distance = getTotalWorkoutDistance(sets);
    const duration = getTotalWorkoutDuration(sets);
    const equipment = aggregateWorkoutEquipment(sets);
    setValue('distance', distance);
    setValue('duration', duration);
    setValue('equipment', equipment);
  }

  useEffect(() => {
    recalculateFormData();
  }, [fields.length])

  const handleAdd = () => {
    append(createSet(!fields.length));
    trackAddSetToWorkout({existingSet: false});
  };

  const handleRemove = (index) => {
    remove(index);
  };

  const handleOnAddFromLibrary = (sets) => {
    const updatedSets = recalculateSetsDataAccordingToBaseFTP(getValues('baseFtp'), sets);
    append(updatedSets);
    trackAddSetToWorkout({existingSet: true});
  }

  const handleOnDragEnd = (result) => {
    const {destination, source} = result;

    if (!destination) return;

    if (destination.droppableId === source.droppableId && destination.index === source.index) return;

    move(source.index, destination.index);
  }

  const handleOnAddSetToLibrary = async (baseName, index) => {
    const isValid = await trigger(baseName);
    if (isValid) {
      const [sets, baseFtp] = getValues(['sets', 'baseFtp']);
      const {updatedAt, createdAt, id, setTemplateId, ...newSet} = sets[index];
      newSet.baseFtp = baseFtp;
      handleCreateCoachSetWorkout(newSet);
    }
  }

  const handleOnSaveSet = async (baseName, setIsLocked) => {
    const isValid = await trigger(baseName);
    if (isValid) {
      setIsLocked(true);
      recalculateFormData();
    }
  }


  return (
    <Stack gap={4}>
      <WorkoutFTPSection hasSplits={!!fields?.length}/>

      <DragAndDropWrap onDragEnd={handleOnDragEnd}>
        {fields.map((item, index) => (
          <SetSection
            key={item.id}
            index={index}
            item={item}
            baseName={`sets[${index}].`}
            defaultLock={!!item?.setTemplateId}
            toolbar={(props) => (
              <WorkoutSetToolbar
                {...props}
                onDelete={() => handleOpenDeleteDialog(index)}
                onPreview={() => handleOpenPreviewDialog(index)}
              />
            )}
            renderActions={({isLocked, setIsLocked}) => isLocked ? (
              <WorkoutSetViewActions onEdit={() => setIsLocked(false)}/>
            ) : (
              <WorkoutSetEditActions
                onSave={() => handleOnSaveSet(`sets[${index}]`, setIsLocked)}
                onAdd={() => handleOnAddSetToLibrary(`sets[${index}]`, index)}
                isAdding={createCoachWorkoutSetMutation.isLoading}
              />
            )}
          />
        ))}
      </DragAndDropWrap>

      <SetLibraryActions onCreate={handleAdd} onAdd={() => setOpenLibrary(true)}/>

      <ConfirmDialog
        open={deleteDialog.open}
        onClose={handleCloseDeleteDialog}
        title="Delete set from workout?"
        action={
          <Button
            variant="contained"
            onClick={() => {
              handleRemove(deleteDialog.index);
              handleCloseDeleteDialog();
            }}
          >
            Delete
          </Button>
        }
      />

      <CoachingSetsDrawer
        open={openLibrary}
        onClose={() => setOpenLibrary(false)}
        onAdd={handleOnAddFromLibrary}
      />

      {previewDialog.index !== undefined && (
        <SetPreviewModal
          open={previewDialog.open}
          setIndex={previewDialog.index}
          onClose={handleClosePreviewDialog}
        />
      )}
    </Stack>
  )
}

const getTotalWorkoutDistance = (data) => {
  let distance = 0;
  data.forEach((set) => (distance += set.distance * set.repeat));
  return distance;
};

const getTotalWorkoutDuration = (data) => {
  let duration = 0;
  data.forEach((set) => {
    let setDuration = 0;
    set.segments.forEach(
      (segment) => (setDuration += segment.cycleTime * segment.repeat)
    );
    duration += setDuration * set.repeat;
  });
  return duration;
};

const aggregateWorkoutEquipment = (data) => {
  let equipment = [];
  data.forEach((set) => (equipment = [...equipment, ...set.equipment]));
  equipment = equipment.filter(
    (item, index) => equipment.indexOf(item) === index
  );
  return equipment;
};

const recalculateSetsDataAccordingToBaseFTP = (baseFtp, sets) => {
  if (!baseFtp) {
    return sets;
  }

  return sets.map(set => {
    const {baseFtp: defaultBaseFtp, coachId, createdAt, updatedAt, ...setData} = set;

    const shouldRecalculate = defaultBaseFtp !== baseFtp;

    if (shouldRecalculate) {
      setData.segments = setData.segments.map(segment => {
        if (setData.isSplit) {
          setData.splits = setData.splits.map(split => {
            const time = calculateTargetTime(baseFtp, split.distance, split.effort)
            split.targetTime = time;
            split.targetTimeDisplay = secondsToHMS(time);

            return split;
          })

          const segmentTime = setData.splits.reduce((initVal, split) => initVal + (split.targetTime || 0), 0);
          segment.targetTime = segmentTime;
          segment.targetTimeDisplay = secondsToHMS(segmentTime);
        } else {
          const time = calculateTargetTime(baseFtp, segment.distance, segment.effort)
          segment.targetTime = time;
          segment.targetTimeDisplay = secondsToHMS(time);
        }

        const total = segment.targetTime + segment.rest;
        segment.cycleTime = total;
        segment.cycleTimeDisplay = secondsToHMS(total);

        return segment;
      });

      setData.totalTime = setData.segments.reduce((initVal, segment) => initVal + ((segment.cycleTime || 0) * segment.repeat), 0) || 0;
    }

    return setData;
  });
}

export default WorkoutSetsStep;