import React, {useContext, useMemo} from "react";
// @mui
import {Box, Container, Typography, Stack, styled, LinearProgress} from "@mui/material";
import {alpha} from "@mui/material/styles";
// sections
import HowToUse from "./HowToUse";
import IOS from "./IOS";
import AgeRange from "./AgeRange";
import {RunFTP, SwimFTP} from "./FunctionalThresholdPace";
import LearnWithSimma from "./LearnWithSimma";
import FindSwimWorkout from "./FindSwimWorkout";
import FindCoach from "./FindCoach";
import {UserContext} from "../../../context";


const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
  height: 4,
  borderRadius: 4,
  maxWidth: 220,
  backgroundColor: alpha(theme.palette.common.white, .2),
}));

const Progress = ({user}) => {
  const profileProgress = useMemo(() => [
    !!user?.ageRange?.length,
    !!user?.ftpAccuracy,
    !!user?.ftpAccuracyRun
  ].reduce((count, el) => el ? ++count : count, 0), [user]);

  const progressValue = Math.round((profileProgress / 3) * 100);

  return (
    <Box>
      <Typography variant="h6" mb={.5}>Profile {progressValue}% complete</Typography>
      <BorderLinearProgress variant="determinate" value={progressValue}/>
    </Box>
  );
}

const ProgressEmpty = () => {
  const {user, refetch} = useContext(UserContext);


  return (
    <Box sx={{py: 1}}>
      <Container maxWidth="md">
        <Stack gap={3}>
          <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, justifyContent: 'space-between'}}>
            <Typography variant="h4">
              Simma Set Up
            </Typography>
            <Progress user={user}/>
          </Box>

          <HowToUse/>

          <IOS/>

          <SwimFTP user={user} refetch={refetch}/>

          <RunFTP user={user} refetch={refetch}/>

          <AgeRange user={user} refetch={refetch}/>

          <LearnWithSimma/>

          <FindSwimWorkout user={user} refetch={refetch}/>

          <FindCoach/>
        </Stack>
      </Container>
    </Box>
  );
};

export default ProgressEmpty;