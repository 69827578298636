import {Box, Typography, Stack, Card, Button} from "@mui/material";


const LearnWithSimma = () => {
  return (
    <Card sx={{backgroundColor: "background.secondary", p: 2}}>
      <Stack gap={2}>
        <Typography variant="h5">Learn with Simma</Typography>
        <Box>
          <Typography variant="caption" color="text.secondary">
            We are starting to build out a knowledge base for coaches and
            athletes. We are a small team so this sometimes falls behind the
            product but we endeavour to keep it up to date.
            <br/>
            <br/>
            You can always lodge a support ticket with our team and we will be
            back to you within a day or two, please drop us a line with any
            feedback or idea suggestions. We are continuously updating Simma
            based on your feedback and our abilities to push the boundaries for
            sports analytics as our data sets and machine learning models allow.
          </Typography>
        </Box>
        <Box sx={{textAlign: "right"}}>
          <Button
            variant="outlined"
            onClick={() => {
              window.open("https://simma.freshdesk.com/support/home", "_blank");
            }}
          >
            simma knowledge base
          </Button>
        </Box>
      </Stack>
    </Card>
  );
}

export default LearnWithSimma;