import {useEffect, useMemo} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Link} from "react-router-dom";
import * as Yup from 'yup';
// @mui
import {
  Box,
  Button,
  Card,
  Stack,
} from "@mui/material";
// icons
import LoadingButton from "@mui/lab/LoadingButton";
// components
import FormProvider from "../../../../components/hook-form";
import GeneralInformationStep from "./GeneralInformationStep";
import WorkoutSetsStep from "./WorkoutSetsStep";


const StepperActions = ({activeStep, onNextStep, onPrevStep, action}) => (
  <Box sx={{p: 1}}>
    <Stack direction={{xs: 'column', sm: 'row'}} justifyContent="space-between" gap={2}>
      {activeStep === 0 && (
        <>
          <Button to="/coaching/training" component={Link} variant="outlined">cancel</Button>
          <Box display="flex" flexDirection={{xs: 'column', sm: 'row'}} gap={1}>
            <Button onClick={onNextStep} variant="contained">Add Sets</Button>
            {action}
          </Box>
        </>
      )}
      {activeStep === 1 && (
        <>
          <Button onClick={onPrevStep} variant="outlined">back</Button>
          {action}
        </>
      )}
    </Stack>
  </Box>
);

const SplitsSchema = Yup.object().shape({
  distance: Yup.number()
    .required('Distance is required')
    .positive('Distance must be a positive number')
    .integer('Distance must be an integer')
    .min(1, 'Distance must be more than or equal to 1')
    .test({
      name: 'multiple',
      message: 'Segment distance needs to be a mulitple of 25.',
      test: (value) => value == null || !(value % 25),
    }),
  stroke: Yup.string().required('Stroke / Drill is required'),
  effort: Yup.string().required('Effort is required'),
  targetTime: Yup.number(),
});

const SegmentsSchema = Yup.object().shape({
  repeat: Yup.number()
    .required('Repeat is required')
    .positive('Repeat must be a positive number')
    .integer('Repeat must be an integer')
    .min(1, 'Repeat must be more than or equal to 1'),
  equipment: Yup.array(),
  distance: Yup.number()
    .required('Distance is required')
    .positive('Distance must be a positive number')
    .integer('Distance must be an integer')
    .min(1, 'Distance must be more than or equal to 1')
    .test({
      name: 'multiple',
      message: 'Segment distance needs to be a mulitple of 25.',
      test: (value) => value == null || !(value % 25),
    }),
  stroke: Yup.string().required('Stroke / Drill is required'),
  effort: Yup.string().required('Effort is required'),
  targetTime: Yup.number(),
  rest: Yup.number(),
  isSplit: Yup.boolean(),
  splitCount: Yup.number(),
  splits: Yup.array(SplitsSchema).when('isSplit', {
    is: true,
    then: (schema) => schema.min(2, 'Splits must be more than or equal to 2'),
  }),
});

const SetSchema = Yup.object().shape({
  name: Yup.string().required('Set name is required'),
  repeat: Yup.number()
    .required('Repeat is required')
    .positive('Repeat must be a positive number')
    .integer('Repeat must be an integer')
    .min(1, 'Repeat must be more than or equal to 1'),
  segments: Yup.array(SegmentsSchema).min(1, 'Sets is required'),
})

const WorkoutCreatorSchema = Yup.object().shape({
  title: Yup.string().required('Name is required'),
  shortDescription: Yup.string(),
  environment: Yup.string().required('Environment is required'),
  level: Yup.number().required('Level is required'),
  baseFtp: Yup.number().min(1, 'Base FTP is required'),
  published: Yup.boolean(),
  sets: Yup.array(SetSchema).min(1, 'Sets is required'),
});

const WorkoutCreatorForm = (
  {
    currenWorkout,
    workoutTags,
    onSubmit,
    isSubmitting,
    activeStep,
    onNextStep,
    onPrevStep
  }
) => {
  const defaultValues = useMemo(
    () => ({
      // General Information
      title: currenWorkout?.title || '',
      shortDescription: currenWorkout?.shortDescription || '',
      environment: currenWorkout?.environment || "pool",
      level: currenWorkout?.level || 2,
      levels: currenWorkout?.levels || [2],
      tags: currenWorkout?.tags || [],
      imageThumbnail: currenWorkout?.imageThumbnail || 'workout-pool-1',
      imageThumbnailUrl: currenWorkout?.imageThumbnailUrl || window.location.origin + '/images/workouts/workout-pool-1.png',
      // Workout Sets
      published: currenWorkout?.published || false,
      sets: currenWorkout?.sets || [],
      baseFtp: currenWorkout?.baseFtp || 0,
      distance: currenWorkout?.distance || 0,
      duration: currenWorkout?.duration || 0,
      equipment: currenWorkout?.equipment || [],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currenWorkout]
  );

  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(WorkoutCreatorSchema),
    defaultValues,
  });

  const {
    reset,
    trigger,
    handleSubmit,
    formState: {isDirty, isValid},
  } = methods;

  const handleOnSubmit = (data) => {
    onSubmit(data);
  }

  const handleOnNextStep = async () => {
    const isValid = await trigger(['title', 'environment', 'level', 'imageThumbnailUrl']);
    if (isValid) {
      onNextStep();
    }
  }

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currenWorkout]);


  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(handleOnSubmit)}>
      <Card sx={{p: 2, backgroundColor: "background.secondary"}}>

        {activeStep === 0 && <GeneralInformationStep workoutTags={workoutTags}/>}

        {activeStep === 1 && <WorkoutSetsStep/>}

        <StepperActions
          activeStep={activeStep}
          onNextStep={handleOnNextStep}
          onPrevStep={onPrevStep}
          action={(
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              disabled={!isValid || !isDirty}
            >
              save workout
            </LoadingButton>
          )}
        />
      </Card>
    </FormProvider>
  );
};

export default WorkoutCreatorForm;
