import {useFormContext, useWatch} from "react-hook-form";
import {useEffect, useMemo} from "react";
import {RHFSwitch} from "../../../../components/hook-form";


const dependentValues = ['firstName', 'lastName', 'avatar', 'location', 'locationDisplay', 'description', 'focusAreas', 'levels'];

const SearchableSwitch = ({control, name, ...other}) => {
  const {setValue} = useFormContext();
  const values = useWatch({
    control,
    name: dependentValues,
  });

  const isValid = useMemo(() => dependentValues.findIndex((value, index) => {
    return Array.isArray(values[index]) ? !values[index].length : !values[index];
  }) === -1, [values]);

  useEffect(() => {
    if (!isValid) {
      setValue(name, false);
    }
  }, [isValid])

  return <RHFSwitch name={name} disabled={!isValid} {...other} />;
};

export default SearchableSwitch;