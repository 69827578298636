import * as React from 'react';

const CrossIcon = (props) => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 32C24.8366 32 32 24.8364 32 16C32 7.1636 24.8366 0 16 0C7.16343 0 0 7.1636 0 16C0 24.8364 7.16343 32 16 32ZM23.0884 8.91177C23.6765 9.49954 23.6765 10.4531 23.0884 11.0414L18.1297 16L23.0884 20.9586C23.6764 21.5469 23.6764 22.5005 23.0884 23.0882C22.5003 23.6765 21.5468 23.6765 20.9587 23.0882L16 18.1296L11.0413 23.0882C10.4532 23.6765 9.49977 23.6765 8.91165 23.0882C8.32359 22.5005 8.32359 21.5469 8.91165 20.9586L13.8703 16L8.91165 11.0414C8.32359 10.4531 8.32359 9.49954 8.91165 8.91177C9.49971 8.32353 10.4532 8.32353 11.0413 8.91177L16 13.8704L20.9587 8.91177C21.5468 8.32353 22.5003 8.32353 23.0884 8.91177Z'
        fill='#EBEBF5'
        fillOpacity='0.3'
      />
    </svg>
  );
};

export default CrossIcon;
