import { useState } from 'react';
import { Button, Dialog, Box, Typography } from '@mui/material';
import CoachSignupStepper from './CoachSignupStepper';

const p = 2;

const CoachSignupButton = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <>
      <Button
        onClick={() => {
          setDialogOpen(true);
        }}
        variant='contained'
        size='large'>
        Start coaching
      </Button>
      <Dialog
        fullWidth
        sx={{ maxWidth: 550, m: 'auto' }}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}>
        <Box p={p}>
          <Box pb={p}>
            <Typography variant='h5'>Get started in 3 steps</Typography>
          </Box>
          <Box>
            <CoachSignupStepper />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default CoachSignupButton;
