import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const WorkoutsIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
    >
      <g transform="matrix(0.799873,0,0,0.799873,0.00191918,3.30146)">
        <path d="M4,16C2,16 2,14 0,14L0,16C2,16 2,18 4,18C6,18 6,16 8,16C10,16 10,18 12,18C14,18 14,16 16,16C18,16 18,18 20,18C22,18 22,16 24,16C26,16 26,18 28,18C29,18 30,17 30,17L30,15L30,15C29.5,15.5 29,16 28,16C26,16 26,14 24,14C22,14 22,16 20,16C18,16 18,14 16,14C14,14 14,16 12,16C10,16 10,14 8,14C6,14 6,16 4,16Z" />
      </g>
      <g transform="matrix(0.799873,0,0,0.799873,0.00191918,3.30146)">
        <path d="M4,20C2,20 2,18 0,18L0,20C2,20 2,22 4,22C6,22 6,20 8,20C10,20 10,22 12,22C14,22 14,20 16,20C18,20 18,22 20,22C22,22 22,20 24,20C26,20 26,22 28,22C29,22 30,21 30,21L30,19L30,19C29.5,19.5 29,20 28,20C26,20 26,18 24,18C22,18 22,20 20,20C18,20 18,18 16,18C14,18 14,20 12,20C10,20 10,18 8,18C6,18 6,20 4,20Z" />
      </g>
      <g transform="matrix(0.799873,0,0,0.799873,0.00191918,3.30146)">
        <path d="M18.35,9.094C18.655,9.72 18.835,10.399 18.88,11.094C18.898,11.374 19,13.5 19,14L3,14C1.5,14 1.816,12.295 3,11.374C4.85,9.934 8.72,8.304 13,7.154C12.873,6.757 12.745,6.369 12.622,5.996C12.495,5.608 12.372,5.235 12.26,4.884L6.6,7.354C6.275,7.489 5.912,7.498 5.581,7.377C5.251,7.257 4.978,7.017 4.816,6.704C4.655,6.391 4.617,6.03 4.711,5.691C4.804,5.351 5.021,5.06 5.32,4.874L9.49,2.404C9.717,2.282 10.062,2.074 10.398,1.871C10.698,1.69 10.992,1.512 11.19,1.404C11.885,1.015 12.701,0.902 13.475,1.087C14.25,1.273 14.926,1.743 15.37,2.404C15.59,2.756 15.79,3.12 15.97,3.494C16.891,5.304 17.686,7.175 18.35,9.094ZM24,14C26.209,14 28,12.209 28,10C28,7.791 26.209,6 24,6C21.791,6 20,7.791 20,10C20,12.209 21.791,14 24,14Z" />
      </g>
    </SvgIcon>
  );
};

export default WorkoutsIcon;