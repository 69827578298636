import {
  Box, Container, styled, Typography
} from "@mui/material";

const StyledRoot = styled('div')(({ theme }) => ({
  padding: '100px 0',
  textAlign: 'center',
  backgroundColor: theme.palette.backgrounds['2t'],
  overflowX: 'hidden',
}));

const Image = styled('img')({
  height: 'auto',
  margin: 'auto',
  maxWidth: '100%',
});

const LandingSecondHeroSection = ({title, description, imageSrc, bgImageSrc, imageWidth}) => {
  const defaultImageWidth = imageWidth || 1015;
  const renderContent = (
    <>
      <Typography sx={{mb: 4}} variant="h4">{title}</Typography>
      <Typography sx={{maxWidth: 980, mx: 'auto', mb: 4}} variant="body1">{description}</Typography>
    </>
  );

  if (bgImageSrc) {
    return (
      <StyledRoot>
        <Container sx={{position: 'relative', zIndex: 1}}>
          {renderContent}
        </Container>
        <Box sx={(theme) => ({
          position: 'relative',
          py: 10,
          '&:after': {
            content: '""',
            position: 'absolute',
            background: `no-repeat center/contain url(${bgImageSrc})`,
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
          },
          [theme.breakpoints.down('md')]: {
            py: 0,
            '&:after': {
              width: '150%',
              height: '150%',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            },
          }
        })}>
          <Container sx={{position: 'relative', zIndex: 1}}>
            <Image sx={{mb: 4}} width={defaultImageWidth} src={imageSrc} alt="feature" />
          </Container>
        </Box>
      </StyledRoot>
    );
  }

  return (
    <StyledRoot>
      <Container>
        {renderContent}
        {imageSrc && <Image src={imageSrc} alt="feature-hero" />}
      </Container>
    </StyledRoot>
  );
};

export default LandingSecondHeroSection;
