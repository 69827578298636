// @mui
import { TableRow, TableCell } from '@mui/material';

// ----------------------------------------------------------------------
const TableNoData = ({ isNotFound }) => {
  return (
    <TableRow>
      {isNotFound ? (
        <TableCell colSpan={12}>
          empty
        </TableCell>
      ) : (
        <TableCell colSpan={12} sx={{ p: 0 }} />
      )}
    </TableRow>
  );
}

export default TableNoData;