import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import { LoadingButton } from '@mui/lab';

const ConfirmDialog = ({
  title,
  text,
  confirmText,
  handleClose,
  disabled,
  open,
  handleConfirm,
  confirmColor = 'danger',
  verifyText,
  verifyLabel,
  confirmIsLoading
}) => {
  const [value, setValue] = useState('');

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{text}</DialogContentText>
        {!!verifyText && (
          <Box mt={3}>
            <InputLabel>{verifyLabel}</InputLabel>
            <TextField
              size='small'
              value={value}
              onChange={(event) => setValue(event.target.value)}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color='neutral'
          disabled={!!disabled}
          onClick={() => {
            setValue('');
            handleClose();
          }}>
          Cancel
        </Button>
        <LoadingButton
          disabled={!!disabled || (!!verifyText && value !== verifyText)}
          onClick={() => {
            setValue('');
            handleConfirm();
          }}
          autoFocus
          color={confirmColor}
          loading={confirmIsLoading}>
          {confirmText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  confirmText: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  confirmColor: PropTypes.string
};

export default ConfirmDialog;
