import {useQuery} from "react-query";
import {useHistory} from "react-router-dom";
import {Box, Typography, Stack} from "@mui/material";
import {SkeletonWorkoutItem} from "../../../components/skeleton";
import WorkoutCard from "../../../components/workout/WorkoutCard";
import api from "../../../api";


const FindSwimWorkout = ({user}) => {
  const history = useHistory();

  const {
    data,
    isLoading,
  } = useQuery(
    ["workoutsListFindData"],
    () => api.workouts.getList({pageSize: 2, page: 0}),
    {staleTime: 1000 * 60 * 10}
  );

  const workoutData = data?.results ?? [];

  const isNothingFound = !isLoading && !workoutData.length;

  const handleOnClick = (id) => {
    history.push(`/workout/${id}`);
  }


  return (
    <Stack gap={2}>
      <Typography variant="h5">
        Find a Swim Workout
      </Typography>

      <Box columnGap={1} rowGap={1.5} display="grid" gridTemplateColumns={{md: 'repeat(2, 1fr)'}}>
        {(isLoading && !isNothingFound ? [...Array(2)] : workoutData).map((item, index) =>
          item ? (
            <WorkoutCard
              isLocked={item.locked}
              key={item.id}
              data={item}
              lapUnit={user.lapUnit}
              onClick={() => handleOnClick(item.id)}
            />
          ) : (
            <SkeletonWorkoutItem key={index}/>
          )
        )}
      </Box>
    </Stack>
  )
}

export default FindSwimWorkout;