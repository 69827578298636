import { useState } from "react";
import { Box, Typography, Stack, Button, TextField } from "@mui/material";
import { useQuery, useMutation } from "react-query";
import api from "api";
import { LoadingButton } from "@mui/lab";
import ConfirmDialog from "components/ConfirmDialog";
import Modal from "components/Modal";
import Spinner from "components/common/Spinner";
import { timeStringToReadable } from "../../util";
import CheckIcon from "@mui/icons-material/Check";
import { DataGrid } from "@mui/x-data-grid";

const ErrorsAdmin = () => {
	const [deleteError, setDeleteError] = useState(null);
	const [message, setMessage] = useState(null);
	const [search, setSearch] = useState("");
	const [rowSelectionModel, setRowSelectionModel] = useState([]);

	const {
		data: rows,
		refetch,
		isLoading,
	} = useQuery(["adminErrors"], api.admin.errors.list);

	const columns = [
		{ field: "type", headerName: "Type", flex: 1 },
		{ field: "date", headerName: "Date", flex: 1 },
		{ field: "userEmail", headerName: "User Email", flex: 1 },
		{
			field: "reprocessed",
			headerName: "Reprocessed",
			flex: 1,
			renderCell: (params) => {
				return (
					<Box
						display="flex"
						justifyContent="center"
						background="red"
						width="100%"
					>
						{params.row.message.reprocess ? (
							<CheckIcon sx={{ color: "success" }} />
						) : null}
					</Box>
				);
			},
		},
		{
			field: "message",
			headerName: "Message",
			flex: 1,
			renderCell: (params) => {
				return (
					<Button
						onClick={(event) => {
							event.stopPropagation();
							setMessage(params.row.message);
						}}
					>
						Show
					</Button>
				);
			},
		},
		{
			field: "delete",
			headerName: "Delete",
			sortable: false,
			flex: 1,
			disableClickEventBubbling: true,
			renderCell: (params) => {
				const onClick = (event) => {
					event.stopPropagation();
					setDeleteError(params.row.delete);
				};

				return (
					<LoadingButton onClick={onClick} color="danger">
						Delete
					</LoadingButton>
				);
			},
		},
	];

	const deleteMutation = useMutation(api.admin.errors.remove, {
		onSuccess: () => {
			setDeleteError(null);
			refetch();
		},
	});

	const reprocessMutation = useMutation(api.admin.errors.reprocess, {
		onSuccess: () => {
			setDeleteError(null);
			setRowSelectionModel([]);
			refetch();
		},
	});

	const handleReprocess = () => {
		reprocessMutation.mutate(rowSelectionModel);
	};

	const handleDeleteError = () => {
		deleteMutation.mutate(deleteError.id);
	};

	const filteredRows = rows?.filter((row) => {
		const searchRegex = new RegExp(search, "i");
		return (
			row.user?.email?.match(searchRegex) ||
			row.message?.Key?.match(searchRegex) ||
			row.message?.userId?.match(searchRegex) ||
			row.message?.fileName?.match(searchRegex) ||
			row.message?.platform?.match(searchRegex) ||
			row.id?.match(searchRegex)
		);
	});

	const rowss = filteredRows?.map((row, index) => ({
		id: row.id,
		type: row.type,
		date: timeStringToReadable(row.date),
		userEmail: row.user?.email,
		reprocessed: !!row.message?.reprocess,
		message: row.message,
		delete: row,
	}));

	return (
		<>
			<Stack direction="row" sx={{ justifyContent: "space-between" }} pb={3}>
				<Typography variant="h4">Errors</Typography>
				<LoadingButton
					variant="contained"
					color="primary"
					onClick={handleReprocess}
					loading={reprocessMutation.isLoading}
					disabled={rowSelectionModel.length === 0}
				>
					Reprocess
				</LoadingButton>
			</Stack>
			<Box sx={{ maxWidth: "100%", mb: 3 }}>
				<TextField
					fullWidth
					label="Search"
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					placeholder="Search by user email, message key, user id, file name, platform, or error id"
				/>
			</Box>

			<Box style={{ width: "100%" }}>
				{isLoading ? (
					<Spinner />
				) : (
					<DataGrid
						rows={rowss}
						columns={columns}
						pageSize={100}
						checkboxSelection
						onRowSelectionModelChange={(newRowSelectionModel) => {
							setRowSelectionModel(newRowSelectionModel);
						}}
						rowSelectionModel={rowSelectionModel}
						isRowSelectable={(params) => {
							return params.row.type === "ACTIVITY_FILE_FAILED_TO_PROCESS";
						}}
					/>
				)}
			</Box>

			<ConfirmDialog
				open={!!deleteError}
				title={`Permanently delete error?`}
				text="This cannot be undone. All record of it will be lost"
				confirmText="Delete Error Record"
				handleConfirm={handleDeleteError}
				handleClose={() => setDeleteError(null)}
			/>
			<Modal
				open={!!message}
				title="Error SQS Message"
				handleClose={() => setMessage(null)}
			>
				<Box sx={{ maxHeight: "80vh", overflowY: "scroll", padding: "24px" }}>
					<pre
						style={{
							overflowX: "auto",
							whiteSpace: "pre-wrap",
							wordWrap: "break-word",
						}}
					>
						{JSON.stringify(message, null, 2)}
					</pre>
				</Box>
			</Modal>
		</>
	);
};
export default ErrorsAdmin;
