// import DatePicker from "react-datepicker";
// const dateFormat = "MMMM dd yyy"

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// import PropTypes from 'prop-types';

const DatePickerField = ({ value, onUpdate, label, toIsoString }) => {
  // const [value, setValue] = React.useState<Date | null>(null);
  const setValue = (val) => {
    const updateValue = toIsoString ? val.toISOString() : val;
    onUpdate(updateValue);
  };

  return (
    <div className='input-wrapper'>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={label}
          value={value || null}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              size='small'
              variant='standard'
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
};

// DatePickerField.propTypes = {
//   : PropTypes.
// };

export default DatePickerField;
