import { Button, Box } from '@mui/material';
import { useRef } from 'react';
import html2canvas from 'html2canvas';

const DownloadPdfButton = ({
  component: Component,
  componentProps,
  children,
  fileName = 'simma-download',
  buttonText = 'Download',
}) => {
  const contentRef = useRef();

  const handleDownload = async () => {
    const content = contentRef.current;
    const canvas = await html2canvas(content);
    const imgData = canvas.toDataURL('image/jpeg');
    const link = document.createElement('a');
    link.href = imgData;
    link.download = `${fileName}.jpeg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  let content = null;
  if (Component) {
    content = <Component {...componentProps} />;
  } else if (children) {
    content = children;
  }

  if (!content) return null;

  return (
    <>
      <Button onClick={handleDownload}>{buttonText}</Button>
      <Box
        className='pdf-download-wrapper'
        p={4}
        ref={contentRef}
        sx={{
          position: 'absolute',
          left: '-10000px',
          width: '210mm',
          backgroundColor: 'white',
          color: 'black',
          opacity: 1,
        }}
      >
        {content}
      </Box>
    </>
  );
};
export default DownloadPdfButton;
