import React from 'react';

import { Stack } from '@mui/material';

import ProgressStatistics from '../../../../components/progress/ProgressStatistics';
import ProgressStrokeCounts from '../../../../components/progress/ProgressStrokeCounts';
import Card from '../Card';

const Stats = ({ statistics, strokes, activityType }) => {
  const isSwimActivity = activityType === 'swimming';

  return (
    <Stack gap={'10px'}>
      <Card title={'Total Statistics'}>
        <ProgressStatistics statistics={statistics} type={'totals'} />
      </Card>
      <Card title={'Session Averages'}>
        <ProgressStatistics statistics={statistics} type={'averages'} />
      </Card>
      {isSwimActivity && (
        <Card title={'Stroke Distribution'}>
          <ProgressStrokeCounts strokes={strokes} />
        </Card>
      )}
    </Stack>
  );
};

export default Stats;
