import SvgIcon from '@mui/material/SvgIcon';
const PaddlesIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M8.91132 18.3685C10.3664 17.4255 12.1066 17.0102 13.839 17.201C15.7494 17.4115 17.1986 15.5153 16.494 13.7271L16.4496 13.6146C15.8508 12.0939 14.8333 9.51043 13.4705 7.40985C13.4464 7.37274 13.4222 7.3357 13.3978 7.29873C14.0745 6.95396 14.7968 6.83576 15.5604 7.04036C19.3867 8.06562 18.2643 15.5765 17.7733 18.8625C17.7685 18.8946 17.7638 18.9263 17.7591 18.9576C17.5864 20.114 16.2462 20.6722 15.3037 19.9803C13.5784 18.7135 11.4403 18.1406 9.3127 18.375C9.17435 18.3902 9.03998 18.3873 8.91132 18.3685Z" fill="#EBEBF5" fillOpacity="0.6"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.70082 4.8724C11.5271 3.84714 14.3106 10.9129 15.5283 14.0042L15.5636 14.0937C15.9923 15.1815 15.1107 16.335 13.9485 16.207C11.8209 15.9726 9.68284 16.5455 7.95747 17.8123C7.015 18.5043 5.67477 17.9461 5.50211 16.7897L5.48796 16.695C4.99913 13.4237 3.87451 5.89765 7.70082 4.8724ZM10.2787 8.72714C10.529 8.61044 10.8265 8.71872 10.9432 8.96899L13.1747 13.7544C13.2914 14.0047 13.1831 14.3022 12.9328 14.4189C12.6826 14.5356 12.3851 14.4273 12.2684 14.177L10.0369 9.3916C9.92019 9.14133 10.0285 8.84384 10.2787 8.72714ZM6.94112 10.0413C6.91705 9.76624 7.12055 9.52373 7.39564 9.49966C7.67073 9.47559 7.91325 9.67909 7.93731 9.95418L8.3975 15.2142C8.42157 15.4893 8.21808 15.7318 7.94299 15.7559C7.66789 15.7799 7.42538 15.5764 7.40131 15.3014L6.94112 10.0413Z" fill="#EBEBF5" fillOpacity="0.6"/>
    </SvgIcon>
  );
}

export default PaddlesIcon;
