const Paddles = ({size = 41}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.2235 30.9444C17.7093 29.3855 20.6822 28.6989 23.6417 29.0144C26.9053 29.3623 29.381 26.2276 28.1772 23.2715L28.1015 23.0855C27.0784 20.5716 25.3402 16.3007 23.0121 12.8281C22.971 12.7668 22.9296 12.7056 22.8879 12.6445C24.0439 12.0745 25.2778 11.8791 26.5823 12.2173C33.1189 13.9122 31.2016 26.3288 30.3628 31.7612C30.3546 31.8143 30.3465 31.8667 30.3385 31.9184C30.0435 33.8301 27.7539 34.7529 26.1439 33.609C23.1964 31.5148 19.5439 30.5677 15.9092 30.9552C15.6729 30.9804 15.4433 30.9755 15.2235 30.9444Z" fill="#EBEBF5" fillOpacity="0.6"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.1556 8.63335C19.6922 6.93844 24.4472 18.6192 26.5276 23.7297L26.5878 23.8776C27.3201 25.6759 25.8141 27.5828 23.8287 27.3712C20.1941 26.9837 16.5415 27.9308 13.594 30.025C11.984 31.1689 9.69441 30.2462 9.39945 28.3345L9.37528 28.1779C8.54019 22.7699 6.61897 10.3283 13.1556 8.63335ZM17.5595 15.0058C17.9871 14.8129 18.4953 14.9919 18.6946 15.4056L22.5067 23.3167C22.7061 23.7304 22.5211 24.2222 22.0936 24.4151C21.666 24.608 21.1578 24.429 20.9585 24.0153L17.1464 16.1043C16.947 15.6906 17.132 15.1988 17.5595 15.0058ZM11.8578 17.1784C11.8166 16.7236 12.1643 16.3227 12.6342 16.2829C13.1042 16.2431 13.5185 16.5795 13.5596 17.0343L14.3458 25.7299C14.3869 26.1847 14.0392 26.5856 13.5693 26.6254C13.0993 26.6652 12.685 26.3288 12.6439 25.874L11.8578 17.1784Z" fill="#EBEBF5" fillOpacity="0.6"/>
    </svg>

  );
}

// Fins.propTypes = {
//   : PropTypes.
// };

export default Paddles;
