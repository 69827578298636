import {useActivityChart} from "../../hooks";
// @mui
import {Box, Stack, Typography} from "@mui/material";
// components
import PaceChart from "../../components/charts/PaceChart";
import MetricsToolbar from "../../components/charts/MetricsToolbar";
import HeartRateChart from "../../components/charts/HeartRateChart";
import HeartRateZonesWidget from "../../components/common/HeartRateZonesWidget";


const SwimChartsTab = ({activity}) => {
  const {segments, analytics, data, isStroke, setIsStroke, activeSegment, setIsActiveSegment} = useActivityChart(activity);

  if (!data || !segments) {
    return null
  }

    return (
    <Stack gap={2}>
      <Box>
        <Typography variant="h6" sx={{mb: 1}}>Pace</Typography>
        <MetricsToolbar
          disableMetrics
          metrics={data.correlationKeys}
          hasStrokeData={data.hasStrokeData}
          isStroke={isStroke}
          setIsStroke={setIsStroke}
        />
        <PaceChart
          disableMetrics
          data={data}
          segments={segments}
          isStroke={isStroke}
          setIsStroke={setIsStroke}
          activeSegment={activeSegment}
          setActiveSegment={setIsActiveSegment}
        />
      </Box>

      <Box>
        <Typography variant="h6" sx={{mb: 1}}>Heart rate</Typography>
        <HeartRateChart
          activity={activity}
          analytics={analytics}
          heartRateZones={activity.heartRateZones}
          maxHeartRate={activity.userMaxHeartRate}
          segments={segments}
          activeSegment={activeSegment}
          setActiveSegment={setIsActiveSegment}
        />
      </Box>

      {!!activity.timeInHeartRateZonesSec && <HeartRateZonesWidget timeInHeartRateZonesSec={activity.timeInHeartRateZonesSec}/>} 
    </Stack>
  );
};

export default SwimChartsTab;
