import useStyles from './index.styles'

import { useState } from 'react';

import {
	Bar,
	BarChart,
	CartesianGrid,
	ReferenceLine,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from "recharts";
import { Box, Chip, Stack, Typography } from "@mui/material";

import { strokes, parseChartData, CHART_TEXT, resolveTextColor, resolveEfficiencyText } from "../../utils";
import { HandIcon } from "../../../../assets/icons";
import CustomToolTip from "../../../charts/CustomToolTip";

const StrokeCount = ({ data, isMobile, features, hand }) => {

	const { classes } = useStyles({ isMobile })

	const [stroke, setStroke] = useState('strokeCountFreestyle')

	const blah = parseChartData(features, stroke, CHART_TEXT.STROKE_COUNT)
	const strokeItems = strokes.filter(item => Object.keys(data).includes(item.value))

	return (
		<Box className={ classes.root }>
			<Box className={ classes.header }>
				<Typography variant={ isMobile ? 'h5' : 'h4' }>Stroke Count</Typography>
				<Typography variant={ 'body2' } className={ classes.greyText }>A count of your strokes per lap, a lower
					stroke count is more efficient. </Typography>
			</Box>
			<Box className={ classes.subHeader }>
				<Typography variant={ 'h6' }>Analysis</Typography>
				<Typography variant={ 'body2' } className={ classes.greyText }>Data is compared to your
					"normal"</Typography>
			</Box>
			<Box className={ classes.buttonsContainer }>
				<Box className={ classes.chipContainer }>
					{ strokeItems.map(item => (
						<Chip
							key={ item.value }
							className={ `${ classes.chip } ${ stroke === item.value ? classes.activeChip : '' }` }
							onClick={ () => setStroke(item.value) }
							label={ item.text }
						/>
					)) }
				</Box>
				<Box className={ classes.handContainer }>
					<HandIcon type={ hand }/>
					<Typography
						className={ classes.handText }>{ hand.charAt(0).toUpperCase() + hand.slice(1) } hand</Typography>
				</Box>
			</Box>
			<Box className={ classes.statsWrapper }>
				<Box className={ classes.statsContainer }>
					<Stack>
						<Typography variant={ 'h5' }>
							{ `${ data[stroke].mean } strokes` }
						</Typography>
						<Typography variant={ 'caption' } sx={ { color: 'rgba(235, 235, 245, 0.6)' } }>
							Avg. this swim
						</Typography>
					</Stack>
					<Stack>
						<Typography sx={ { color: resolveTextColor(data[stroke].similarityColor) } } variant={ 'h5' }>
							{ resolveEfficiencyText(data[stroke].similarityColor) }
						</Typography>
						<Typography variant={ 'caption' } sx={ { color: 'rgba(235, 235, 245, 0.6)' } }>
							{ `${ (data[stroke].similarityPercent) }% p-value` }
						</Typography>
					</Stack>
				</Box>
				<Box className={classes.graphStatsContainer}>
					<Box className={ classes.square }></Box>
					<Typography sx={ { fontSize: '11px', marginRight: '17px' } }>Stroke Count</Typography>
					<Typography sx={ { fontSize: '11px', marginRight: '8px' } }>{ data[stroke].normal }</Typography>
					<Typography sx={ { fontSize: '11px', marginRight: '8px' } }>Your ‘normal’</Typography>
				</Box>
			</Box>
			<Box className={ classes.chartContainer }>
				<ResponsiveContainer width="100%" height="100%">
					<BarChart
						data={ blah.data }
						margin={ { top: 0, right: 0, left: 0, bottom: 0 } }
					>
						<CartesianGrid stroke={ 'rgba(255, 255, 255, 0.14)' }/>
						<XAxis ticks={ blah.ticks }/>
						<YAxis width={ 22 } axisLine={ false } tickLine={ false }/>
						<Tooltip content={ <CustomToolTip/> }/>
						<Bar dataKey={ CHART_TEXT.STROKE_COUNT } fill="#FF5082"/>
						<ReferenceLine y={ blah.normal } stroke="white" strokeDasharray="3 3"/>
					</BarChart>
				</ResponsiveContainer>
			</Box>
			<Stack gap={ '8px' }>
				<Typography variant={ 'h6' }>About Strokes per Lap</Typography>
				<Typography variant={ 'body1' } className={ classes.greyText }>An easy way to monitor this in your swim
					is to count the number of strokes you take each lap.
					In your next swim count your strokes per lap when you are swimming easy, at threshold and sprinting.
					Your turn efficiency in pool swimming will also reduce your strokes per lap. </Typography>
			</Stack>
		</Box>
	);
};

export default StrokeCount;
