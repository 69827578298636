import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Drawer(theme) {
  return {
    MuiDrawer: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'persistent' && {
            '& .MuiDrawer-paper': {
              backgroundColor: alpha(theme.palette.common.white, .14),
              borderRight: 'none',
              boxShadow: `0px 8px 10px -5px ${alpha(
                theme.palette.common.black,
                0.2
              )}, 
              0px 16px 24px 2px ${alpha(
                theme.palette.common.black,
                0.14
              )}, 
              0px 6px 30px 5px ${alpha(
                theme.palette.common.black,
                0.12
              )}`,
            },
          }),
        }),
      },
    },
  };
}
