const Buoy = ({size = 41}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.427 28.6793C10.3699 28.6812 10.3109 28.6822 10.25 28.6822C5.97913 28.6822 3.41663 24.9815 3.41663 20.4164C3.41663 15.8513 5.97913 12.1506 10.25 12.1506C10.3109 12.1506 10.3699 12.1516 10.427 12.1535C10.4554 12.1516 10.484 12.1506 10.5128 12.1506C12.1454 12.1506 13.2761 12.9269 14.5575 13.8066C16.0193 14.8101 17.9375 16.222 20.5 16.222C23.0625 16.222 24.9806 14.8101 26.4424 13.8066C27.7238 12.9269 28.8545 12.1506 30.4871 12.1506C30.5159 12.1506 30.5445 12.1516 30.5729 12.1535C30.63 12.1516 30.689 12.1506 30.75 12.1506C35.0208 12.1506 37.5833 15.8513 37.5833 20.4164C37.5833 24.9815 35.0208 28.6822 30.75 28.6822C30.689 28.6822 30.63 28.6812 30.5729 28.6793C30.5445 28.6812 30.5159 28.6822 30.4871 28.6822C28.8545 28.6822 27.7238 27.9059 26.4424 27.0262C24.9237 25.9836 23.0625 24.5828 20.1651 24.5828C17.2677 24.5828 16.0351 25.9101 14.6294 26.9278C13.3595 27.8472 12.2061 28.6822 10.5128 28.6822C10.484 28.6822 10.4554 28.6812 10.427 28.6793Z" fill="#EBEBF5" fillOpacity="0.6"/>
    </svg>

  );
}

// Fins.propTypes = {
//   : PropTypes.
// };

export default Buoy;
