import { useCallback, useState } from "react";

import {
	VictoryChart,
	VictoryLine,
	VictoryAxis,
	VictoryArea,
	VictoryScatter,
	VictoryZoomContainer,
} from "victory";
import { Box, Stack, Typography } from "@mui/material";

import { theme } from "./theme";
import colors from "style/colors";

const height = 235;

const findMax = (data) => {
	const max = data.reduce((acc, curr) => {
		if (curr.formScore > acc) {
			return curr.formScore;
		}
		return acc;
	}, 0);

	return max;
};

const findMin = (data) => {
	const min = data.reduce((acc, curr) => {
		if (curr.formScore < acc) {
			return curr.formScore;
		}
		return acc;
	}, 0);

	return min;
};

const roundUpToNearest5 = (num) => {
	return Math.ceil(num / 5) * 5;
};

const roundDownToNearest5 = (num) => {
	return Math.floor(num / 5) * 5;
};

const arrayOfIncrementsOf10Between = (min, max) => {
	const arr = [];
	for (let i = min; i <= max; i += 10) {
		arr.push(i);
	}
	return arr;
};

const yLabels = [
	{ title: 'Strain', value: -35 },
	{ title: 'Grind', value: -15 },
	{ title: 'Coast', value: 0 },
	{ title: 'Taper', value: 15 },
	{ title: 'Decondition', value: 35 }
]

const FormChart = ({ formHistory, isMobile }) => {

	const [boundingRect, setBoundingRect] = useState({ width: 0, height: 0 });

	const graphRef = useCallback((node) => {
		if (node !== null) {
			setBoundingRect(node.getBoundingClientRect());
		}
	}, []);

	const chartData = formHistory.map((d, i) => ({ x: i, y: d.formScore }));
	const activityData = formHistory
		.map((d, i) => ({ x: i, y: d.formScore }))
		.filter((d) => !!formHistory[d.x].id);

	const score = formHistory[formHistory.length - 1].formScore;

	const min = findMin(formHistory);
	const max = findMax(formHistory);

	const domain = [
		min < -35 ? roundDownToNearest5(min) : -35,
		max > 35 ? roundUpToNearest5(max) : 35,
	];

	const ticks = arrayOfIncrementsOf10Between(domain[0], domain[1]);

	return (
		<>
			<Stack
				direction="row"
				sx={ { alignItems: "center", justifyContent: "space-between" } }
				pb={ 1 }
			>
				<Typography fontSize={ 22 }>Recent Form</Typography>
				<Stack direction="row" spacing={ 2 }>
					<Stack direction="row" sx={ { alignItems: "center" } } spacing={ 1 }>
						<Box
							sx={ {
								height: "2px",
								width: "37px",
								bgcolor: "primary.main",
							} }
						/>
						<Typography variant={ 'subtitle2' } sx={ { color: 'rgba(235, 235, 245, 0.6)' } }>
							Form
						</Typography>
					</Stack>
				</Stack>
			</Stack>
			<Box sx={ { width: '100%', height: `${height}px`, marginBottom: '10px' } } ref={ graphRef }>
				<VictoryChart
					theme={ theme }
					height={ height }
					width={ boundingRect.width }
					padding={ { top: 10, bottom: 25, left: isMobile ? 25 : 70, right: 10 } }
					domain={ { y: domain } }
					containerComponent={
						<VictoryZoomContainer
							zoomDomain={ { x: [0, formHistory.length - 1] } }
							zoomDimension="x"
						/>
					}
				>
					<VictoryArea
						data={ chartData.map((d) => ({ x: d.x, y: domain[1], y0: 25 })) }
						style={ {
							data: {
								fill: "#00CDFF",
								opacity: score >= 25 ? 0.4 : 0.2,
							},
						} }
					/>
					<VictoryArea
						data={ chartData.map((d) => ({ x: d.x, y: 25, y0: 15 })) }
						style={ {
							data: {
								fill: "#1ADEA3",
								opacity: score >= 15 && score < 25 ? 0.4 : 0.2,
							},
						} }
					/>
					<VictoryArea
						data={ chartData.map((d) => ({ x: d.x, y: 15, y0: -10 })) }
						style={ {
							data: {
								fill: "#E4F14F",
								opacity: score >= -10 && score < 15 ? 0.4 : 0.2,
							},
						} }
					/>
					<VictoryArea
						data={ chartData.map((d) => ({ x: d.x, y: -10, y0: -30 })) }
						style={ {
							data: {
								fill: "#F38D2E",
								opacity: score >= -30 && score < -10 ? 0.4 : 0.2,
							},
						} }
					/>
					<VictoryArea
						data={ chartData.map((d) => ({ x: d.x, y: -30, y0: domain[0] })) }
						style={ {
							data: {
								fill: "#F1584F",
								opacity: score < -30 ? 0.4 : 0.2,
							},
						} }
					/>
					<VictoryAxis
						width={ "100%" }
						height={ height }
						theme={ theme }
						offsetY={ 25 }
						standalone={ false }
						tickCount={ 10 }
						tickValues={ formHistory.map((d, i) => i) }
						tickFormat={ (x) => {
							const date = formHistory[x].date.split("T")[0].split("-");
							return `${ date[1] }/${ date[2] }`;
						} }
						style={ {
							grid: { strokeWidth: 0.5, opacity: 0.3 },
							ticks: { strokeWidth: 0 },
							tickLabels: { fill: 'rgba(235, 235, 245, 0.6)' }
						} }
					/>
					<VictoryAxis
						dependentAxis
						width={ "100%" }
						height={ height }
						theme={ theme }
						standalone={ false }
						tickValues={ ticks }
						tickFormat={ (tick, index) => {
							const labelExist = yLabels.find(({ value }) => value === tick)?.title
							if (!!labelExist) {
								return `${ !isMobile ? labelExist : '' } ${ tick }`
							}
							return tick
						} }
						style={ {
							grid: { strokeWidth: 0.5, opacity: 0.3 },
							ticks: { strokeWidth: 0 },
							tickLabels: { fill: 'rgba(235, 235, 245, 0.6)' }
						} }
					/>

					<VictoryLine
						theme={ theme }
						data={ chartData }
						style={ { data: { stroke: colors.pink } } }
					/>
					<VictoryScatter
						style={ { data: { fill: "white" } } }
						size={ 2 }
						data={ activityData }
					/>
				</VictoryChart>
			</Box>
		</>
	);
};
export default FormChart;
