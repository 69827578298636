import {userApi} from "./axios";

export const list = async (coachId) => {
  let path = "workouts";
  if (!!coachId) {
    path += `?coachId=${coachId}`;
  }
  const {data} = await userApi.get(path);
  return data;
};

export const getList = async (params) => {
  const {data} = await userApi.get('/workouts', {params});
  return data;
};

export const get = async (id) => {
  const {data} = await userApi.get(`/workouts/${id}`);
  return data;
};
