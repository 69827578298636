import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const RunIcon = ({sx, ...props}) => {
  return (
    <SvgIcon sx={{fill: '#E4F14F', fontSize: 48, ...sx}} {...props} viewBox="0 0 48 48">
      <rect width="48" height="48" rx="24"/>
      <g clipPath="url(#clip0_306_10375)">
        <path d="M29.1566 18.2644C29.3569 18.4658 29.6301 18.5793 29.9139 18.5782H32.9279C33.4709 18.5793 33.9283 18.1723 33.9925 17.6335C34.0557 17.0937 33.7055 16.5924 33.1774 16.4661C33.0992 16.3065 32.9825 16.1694 32.8379 16.0666C32.6119 15.908 32.3356 15.8416 32.0624 15.878C31.9446 15.7442 31.8022 15.6328 31.6436 15.5503C31.1188 15.2889 30.488 15.3661 30.0413 15.7474C29.8206 15.9412 29.6653 16.1983 29.5968 16.4843C29.238 16.5946 28.9638 16.8859 28.8738 17.2501C28.7838 17.6143 28.891 17.9988 29.1566 18.2644ZM29.5176 17.1666C29.599 17.0712 29.7115 17.0091 29.8346 16.9909C29.9803 16.9705 30.0949 16.8538 30.111 16.707C30.1581 16.3461 30.4216 16.0515 30.775 15.9648C31.1274 15.878 31.498 16.0151 31.7069 16.3118C31.7883 16.4264 31.9318 16.4768 32.0657 16.4371C32.0903 16.4296 32.115 16.4243 32.1396 16.4211C32.2735 16.4029 32.4084 16.435 32.5188 16.5121C32.6077 16.5764 32.6741 16.6653 32.7105 16.7692C32.7501 16.8902 32.8583 16.9759 32.9857 16.9877C33.261 17.0187 33.4645 17.2587 33.4495 17.5361C33.4345 17.8124 33.2053 18.0298 32.9279 18.0298H29.9139C29.7758 18.0298 29.6419 17.9752 29.5444 17.8767C29.4459 17.7792 29.3912 17.6453 29.3912 17.5071C29.3912 17.3818 29.4362 17.2608 29.5176 17.1666Z" fill="black"/>
        <path d="M23.6908 15.5419C26.0526 16.0924 27.4205 16.9011 27.7577 17.9476C28.2751 19.5585 26.3814 21.7178 24.7106 23.6232C24.1536 24.2583 23.6277 24.8581 23.1832 25.4376C23.1757 25.4483 23.1682 25.4579 23.1618 25.4697C21.6248 28.1785 21.3152 30.3282 22.2439 31.8577C23.5902 34.0749 27.0585 34.2163 27.9165 34.2163C28.0204 34.2163 28.0857 34.2141 28.105 34.213C28.1778 34.2109 28.2485 34.1798 28.2978 34.1263C28.3481 34.0727 28.3738 34.002 28.3717 33.9292C28.3685 33.8564 28.3374 33.7878 28.2839 33.7375C28.2303 33.6882 28.1596 33.6625 28.0857 33.6657C28.0461 33.6668 24.0564 33.7867 22.7131 31.5739C21.8969 30.2286 22.2054 28.2717 23.6299 25.7568C24.0605 25.1966 24.5768 24.6075 25.123 23.9852C26.976 21.872 28.8921 19.6869 28.2796 17.7804C27.8747 16.5197 26.4138 15.6136 23.8154 15.0085C23.7447 14.9914 23.6697 15.0032 23.6076 15.0417C23.5455 15.0803 23.5015 15.1413 23.4855 15.212C23.4683 15.2838 23.4812 15.3577 23.5198 15.4198C23.5583 15.4819 23.6191 15.5258 23.6908 15.5419Z" fill="black"/>
        <path d="M19.299 15.5567C22.6464 16.3234 24.3941 17.2289 24.6427 18.326C25.0113 19.953 22.223 22.2652 18.5339 25.1215C16.262 26.9611 15.1584 28.8458 15.2559 30.7217C15.3892 33.3103 17.7541 34.8867 17.8549 34.9527V34.9538C17.986 35.0386 18.1641 35.0034 18.2515 34.8745C18.3389 34.7445 18.303 34.5705 18.1731 34.4835C18.1507 34.4691 15.9483 33.0008 15.8295 30.6932C15.7421 29.0045 16.775 27.2774 18.8947 25.56C22.8681 22.4835 25.6453 20.1547 25.2039 18.2028C24.8936 16.8357 23.1135 15.8499 19.4301 15.0061L19.429 15.0072C19.355 14.9907 19.2766 15.0028 19.2128 15.0424C19.1478 15.0821 19.1018 15.1449 19.085 15.2176C19.0671 15.2914 19.0806 15.3674 19.1209 15.4313C19.1612 15.4952 19.2251 15.5402 19.299 15.5567Z" fill="black"/>
        <path d="M14.2738 21.8452H16.3625V24.7648C16.3625 24.9159 16.4856 25.039 16.6367 25.039C16.7888 25.039 16.9109 24.9159 16.9109 24.7648V21.8452H19.0005C19.0927 21.8452 19.1794 21.798 19.2298 21.7209C19.2801 21.6427 19.2887 21.5453 19.2512 21.4607L18.7274 20.2696H18.7424C18.8356 20.2696 18.9234 20.2214 18.9738 20.1421C19.0241 20.0629 19.0305 19.9643 18.9909 19.8787L18.4211 18.6651C18.4982 18.6373 18.5593 18.5773 18.5871 18.4991C18.6149 18.422 18.6053 18.3363 18.5614 18.2656L16.8701 15.5H16.8712C16.8177 15.4229 16.7298 15.3779 16.6366 15.3779C16.5435 15.3779 16.4567 15.4229 16.4031 15.5L14.7119 18.2656C14.6605 18.3502 14.6583 18.4562 14.7065 18.543C14.7558 18.6298 14.8469 18.6833 14.9465 18.6833H14.9582L14.397 19.8786C14.3638 19.9483 14.3616 20.0297 14.3916 20.1004C14.4216 20.1721 14.4805 20.2267 14.5544 20.2525L14.0232 21.4606C13.9857 21.5452 13.9943 21.6427 14.0446 21.7209C14.0949 21.798 14.1817 21.8452 14.2738 21.8452ZM16.6367 16.1685L17.8384 18.135H15.4349L16.6367 16.1685ZM15.5634 18.6834H17.8234L18.3107 19.7213H15.0771L15.5634 18.6834ZM15.1457 20.2697H18.1287L18.5796 21.2968H14.6937L15.1457 20.2697Z" fill="black"/>
        <path d="M27.3066 28.5759C27.357 28.653 27.4426 28.7001 27.5358 28.7001H30.0165V32.1501C30.0165 32.3011 30.1396 32.4243 30.2907 32.4243C30.4417 32.4243 30.5649 32.3011 30.5649 32.1501V28.7001H33.0455C33.1387 28.7001 33.2244 28.653 33.2747 28.5759C33.3261 28.4988 33.3336 28.4002 33.2961 28.3156L32.6578 26.8643H32.7445C32.8388 26.8643 32.9255 26.8161 32.9759 26.7368C33.0262 26.6575 33.0327 26.5579 32.993 26.4733L32.3065 25.0081H32.3054C32.3964 24.9952 32.4735 24.9363 32.5121 24.8538C32.5496 24.7714 32.5442 24.675 32.496 24.5968L30.5242 21.3727C30.4717 21.2956 30.3839 21.2496 30.2907 21.2496C30.1975 21.2496 30.1097 21.2956 30.0572 21.3727L28.0853 24.5968C28.0339 24.6814 28.0318 24.7874 28.08 24.8742C28.1292 24.9609 28.2203 25.0145 28.3188 25.0145H28.4045L27.7201 26.4733C27.6837 26.5504 27.6847 26.6415 27.7254 26.7175C27.7661 26.7936 27.84 26.846 27.9257 26.8589L27.2852 28.3156C27.2477 28.4002 27.2552 28.4977 27.3066 28.5759ZM30.2918 22.0412L31.7742 24.4661H28.8084L30.2918 22.0412ZM29.0097 25.0146H31.7024L32.3129 26.316H28.3993L29.0097 25.0146ZM28.5213 26.8644H32.059L32.6256 28.1518H27.9557L28.5213 26.8644Z" fill="black"/>
      </g>
      <defs>
        <clipPath id="clip0_306_10375">
          <rect width="20" height="20" fill="white" transform="translate(14 15)"/>
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default RunIcon;