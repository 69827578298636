import PropTypes from 'prop-types';
// form
import {useFormContext, Controller} from 'react-hook-form';
// @mui
import {Radio, RadioGroup, FormHelperText, FormControlLabel} from '@mui/material';

// ----------------------------------------------------------------------

RHFRadioGroup.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
};

export default function RHFRadioGroup({name, options, disabled, ...other}) {
  const {control} = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState: {error}}) => (
        <div>
          <RadioGroup {...field} row {...other}>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio/>}
                label={option.label}
                disabled={disabled}
              />
            ))}
          </RadioGroup>

          {!!error && (
            <FormHelperText error sx={{px: 2}}>
              {error.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
}
