import {useState} from "react";
import {useMutation} from "react-query";
import api from "api";
import {trackUpdateFtp} from "../../tracking";

import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	InputLabel,
} from "@mui/material";
import TimeField from "../forms/TimeField";

const ProfileFTPRunForm = ({handleClose, user, refetch}) => {
	const [time, setTime] = useState(user.functionalThresholdPaceRun || 0);
	const mutation = useMutation((data) => api.user.update(data), {
		onSuccess: () => {
			refetch();
			handleClose();
		},
	});

	const submit = () => {
		mutation.mutate({
			functionalThresholdPaceRun: time,
		});
		trackUpdateFtp(time);
	};

	return (
		<>
			<DialogTitle className="title">Run functional threshold pace</DialogTitle>
			<DialogContent sx={{minWidth: {md: 450}}}>
				<InputLabel>Time</InputLabel>
				<TimeField
					value={time}
					onChange={setTime}
					size="small"
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<button
					className="button"
					onClick={submit}
					disabled={mutation.isLoading}
				>
					Submit
				</button>
			</DialogActions>
		</>
	);
};

export default ProfileFTPRunForm;
