import {useEffect, useMemo, useState} from "react";
import {useForm, useWatch} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Link} from "react-router-dom";
import * as Yup from 'yup';
// @mui
import {
  Box,
  Button,
  Card,
  Stack,
  Typography,
} from "@mui/material";
// icons
import LoadingButton from "@mui/lab/LoadingButton";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FlagIcon from "@mui/icons-material/Flag";
// components
import FormProvider from "../../../components/hook-form";
import TimeField from "../sections/formSections/formFields/TimeField";
import {createSet} from "../../../components/WorkoutCreator/utils";
import SetPreviewModal from "../../../components/WorkoutCreator/SetPreviewModal";
import SetSection from "../sections/formSections/SetSection";
import {StyledIconButton} from "../WorkoutCreator/form/WorkoutSetsStep";
import {secondsToHMS} from "../../../util";


const SplitsSchema = Yup.object().shape({
  distance: Yup.number()
    .required('Distance is required')
    .positive('Distance must be a positive number')
    .integer('Distance must be an integer')
    .min(1, 'Distance must be more than or equal to 1')
    .test({
      name: 'multiple',
      message: 'Segment distance needs to be a mulitple of 25.',
      test: (value) => value == null || !(value % 25),
    }),
  stroke: Yup.string().required('Stroke / Drill is required'),
  effort: Yup.string().required('Effort is required'),
  targetTime: Yup.number(),
});

const SegmentsSchema = Yup.object().shape({
  repeat: Yup.number()
    .required('Repeat is required')
    .positive('Repeat must be a positive number')
    .integer('Repeat must be an integer')
    .min(1, 'Repeat must be more than or equal to 1'),
  equipment: Yup.array(),
  distance: Yup.number()
    .required('Distance is required')
    .positive('Distance must be a positive number')
    .integer('Distance must be an integer')
    .min(1, 'Distance must be more than or equal to 1')
    .test({
      name: 'multiple',
      message: 'Segment distance needs to be a mulitple of 25.',
      test: (value) => value == null || !(value % 25),
    }),
  stroke: Yup.string().required('Stroke / Drill is required'),
  effort: Yup.string().required('Effort is required'),
  targetTime: Yup.number(),
  rest: Yup.number(),
  isSplit: Yup.boolean(),
  splits: Yup.array(SplitsSchema).when('isSplit', {
    is: true,
    then: (schema) => schema.min(2, 'Splits must be more than or equal to 2'),
  }),
});


const SetCreatorSchema = Yup.object().shape({
  baseFtp: Yup.number().min(1, 'Base FTP is required'),
  name: Yup.string().required('Set name is required'),
  repeat: Yup.number()
    .required('Repeat is required')
    .positive('Repeat must be a positive number')
    .integer('Repeat must be an integer')
    .min(1, 'Repeat must be more than or equal to 1'),
  segments: Yup.array(SegmentsSchema).min(1, 'Sets is required'),
});

const SetToolbar = ({onPreview}) => {
  const [totalDistance, totalTime] = useWatch({name: ['totalDistance', 'totalTime']})

  return (
    <Box display="flex" justifyContent="space-between">
      <Stack direction="row" alignItems="center" gap={2}>
        <Typography variant="body2" color="text.secondary">Set Totals</Typography>
        <Box display="flex" gap={.5}>
          <FlagIcon opacity={.55}/>
          <Typography variant="body1">{totalDistance ?? '0'}</Typography>
        </Box>
        <Box display="flex" gap={.5}>
          <AccessTimeIcon opacity={.55}/>
          <Typography variant="body1">{secondsToHMS(totalTime || 0)}</Typography>
        </Box>
      </Stack>

      <Box display="flex" gap={1}>
        <StyledIconButton size="small" onClick={onPreview}>
          <VisibilityIcon fontSize="small"/>
        </StyledIconButton>
      </Box>
    </Box>
  );
}

const SetCreatorForm = ({currenSet, onSubmit, isSubmitting}) => {
  const [modal, setModal] = useState(false);

  const defaultValues = useMemo(
    () => currenSet || ({
      ...createSet(),
      baseFtp: 0,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currenSet]
  );

  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(SetCreatorSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    watch,
    formState: {isDirty},
  } = methods;

  const {baseFtp} = watch();

  const handleOnSubmit = (data) => {
    onSubmit(data);
  }

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currenSet]);


  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(handleOnSubmit)}>
      <Card sx={{p: 2, display: 'flex', flexDirection: 'column', gap: 4, backgroundColor: "background.secondary"}}>
        <Box maxWidth={400}>
          <TimeField
            name="baseFtp"
            label="Add Set FTP"
            variant="filled"
            disabled={!!baseFtp}
          />
          <Box sx={{px: 1.5}}>
            <Typography variant="caption" color="text.secondary">
              Sets need an FTP so they can be scaled for each swimmer. Format of FTP is x:xx / 100m
            </Typography>
          </Box>
        </Box>

        {!!baseFtp && <SetSection toolbar={<SetToolbar onPreview={() => setModal(true)}/>}/>}

        <Box sx={{p: 1}}>
          <Stack direction="row" justifyContent="space-between" gap={2}>
            <Button to="/coaching/training" component={Link} variant="outlined">cancel</Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              disabled={!isDirty || !baseFtp}
            >
              save
            </LoadingButton>
          </Stack>
        </Box>
      </Card>

      <SetPreviewModal
        open={modal}
        onClose={() => setModal(false)}
        isSetCreator
      />
    </FormProvider>
  );
};

export default SetCreatorForm;
