import * as Yup from 'yup';
// form
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
// @mui
import {Stack, Typography, Box, Link} from '@mui/material';
import {LoadingButton} from '@mui/lab';
// components
import {useAuthContext} from "../../auth/useAuthContext";
import FormProvider from '../../components/hook-form';
import {hideEmail} from "./utils";

// ----------------------------------------------------------------------

const AuthVerifyEmailForm = ({onContinue}) => {
  const {sendVerification} = useAuthContext();

  const emailVerify = typeof window !== 'undefined' ? sessionStorage.getItem('email-verify') : '';

  const VerifyEmailSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(VerifyEmailSchema),
    defaultValues: {email: emailVerify},
  });

  const {
    handleSubmit,
    formState: {isSubmitting},
  } = methods;

  const onSendNewLink = () => {
    sendVerification({email: emailVerify});
  };

  const onSubmit = async () => {
    try {
      console.log('Verify success!');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{px: 2, py: 3}}>
        <Stack gap={3}>
          <Typography variant="body1">Create an account with Simma</Typography>

          <Box>
            <Typography variant="body1">
              We have sent an email to {emailVerify && hideEmail(emailVerify)}. Please check your email, follow the
              instructions to verify your email address, and then click the button below to continue.
            </Typography>
          </Box>

          <LoadingButton
            onClick={onContinue}
            sx={{textTransform: 'capitalize'}}
            size="large"
            fullWidth
            type="submit"
            variant="gradient"
            loading={isSubmitting}
          >
            Continue
          </LoadingButton>

          <Stack direction="row" gap={1} justifyContent="center" alignItems="center">
            <Typography variant="body1" color="text.secondary">Didn’t receive the mail?</Typography>

            <Link onClick={onSendNewLink} variant="body1" underline="none">
              Send a new link
            </Link>
          </Stack>
        </Stack>
      </Box>
    </FormProvider>
  );
}

export default AuthVerifyEmailForm;