import {useEffect, useState} from "react";
import {useActivityChart} from "../../hooks";
// @mui
import {Box, Stack, Typography} from "@mui/material";
// components
import MetricsToolbar from "../../components/charts/MetricsToolbar";
import PaceChart from "../../components/charts/PaceChart";
import MotionAnalytics from "../../components/motion-analytics";
import StrokeChart from "../../components/charts/StrokeChart";


const AnalyticsTab = ({activity}) => {
  const {segments, data, isStroke, setIsStroke, activeSegment, setIsActiveSegment} = useActivityChart(activity);

  const [selectedPaceMetrics, setSelectedPaceMetrics] = useState([]);

  const [selectedStrokeMetrics, setSelectedStrokeMetrics] = useState([]);

  const motionAnalyticsData = activity?.analytics?.sensorFeatures?.iOSData;

  const handleOnSelectPaceMetric = (value) => {
    setSelectedPaceMetrics(prevState =>
      prevState.includes(value) ? prevState.filter((item) => item !== value) : [...prevState, value]
    );
  };

  const handleOnStrokePaceMetric = (value) => {
    setSelectedStrokeMetrics(prevState =>
      prevState.includes(value) ? prevState.filter((item) => item !== value) : [...prevState, value]
    );
  };

  useEffect(() => {
    if (data?.correlationKeys) {
      setSelectedPaceMetrics(data.correlationKeys);
    }
    if (data?.strokeTimingKeys) {
      setSelectedStrokeMetrics(data.strokeTimingKeys);
    }
  }, [data]);

  if (!data || !segments) {
    return null
  }


  return (
    <Stack gap={2}>
      {motionAnalyticsData && (
        <Box>
          <Typography variant="h6" sx={{mb: 1}}>Motion Analytics</Typography>
          <MotionAnalytics data={motionAnalyticsData} activity={activity} />
        </Box>
      )}

      <Box>
        <Typography variant="h6" sx={{mb: 1}}>Pace correlation</Typography>
        <MetricsToolbar
          metrics={data.correlationKeys}
          selected={selectedPaceMetrics}
          onSelect={handleOnSelectPaceMetric}
          hasCorrelation={data.hasCorrelation}
          hasStrokeData={data.hasStrokeData}
          isStroke={isStroke}
          setIsStroke={setIsStroke}
        />
        <PaceChart
          data={data}
          segments={segments}
          selectedMetrics={selectedPaceMetrics}
          isStroke={isStroke}
          setIsStroke={setIsStroke}
          activeSegment={activeSegment}
          setActiveSegment={setIsActiveSegment}
        />
      </Box>

      <Box>
        <Typography variant="h6" sx={{mb: 1}}>Stroke timing</Typography>
        <MetricsToolbar
          metrics={data.strokeTimingKeys}
          selected={selectedStrokeMetrics}
          onSelect={handleOnStrokePaceMetric}
          hasCorrelation={data.hasCorrelation}
          hasStrokeData={data.hasStrokeData}
          isStroke={isStroke}
          setIsStroke={setIsStroke}
        />
        <StrokeChart
          data={data}
          segments={segments}
          selectedMetrics={selectedStrokeMetrics}
          isStroke={isStroke}
          activeSegment={activeSegment}
          setActiveSegment={setIsActiveSegment}
        />
      </Box>
    </Stack>
  );
};

export default AnalyticsTab;
