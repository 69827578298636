import {useState} from "react";
import {Controller, useFormContext} from "react-hook-form";
// @mui
import {TextField} from "@mui/material";
// utils
import {getSecondsFromHHMMSS, secondsToHMS} from "../../../../../util";

// ----------------------------------------------------------------------

const TimeField = ({name, onChangeCallback, disabled, ...other}) => {
  const {control} = useFormContext();
  const [tempValue, setTempValue] = useState(undefined);

  const handleOnChange = (field, value) => {
    const formattedValue = formatValue(value);
    const seconds = getSeconds(value);
    field.onChange(seconds);
    onChangeCallback && onChangeCallback({time: seconds});
    setTempValue(formattedValue);
  }


  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState: {error}}) => (
        <TextField
          {...field}
          fullWidth
          value={tempValue ?? formatDisplayValue(field.value)}
          onChange={(e) => handleOnChange(field, e.target.value)}
          onBlur={() => {
            setTempValue(undefined);
            field.onBlur();
          }}
          error={!!error}
          helperText={error?.message}
          disabled={disabled && !tempValue}
          {...other}
        />
      )}
    />
  );
};

const getSeconds = (value) => {
  const isNumber = !isNaN(Number(value));
  const currValue = isNumber ? Number(value) : getSecondsFromHHMMSS(value);
  return Math.max(0, currValue);
}

const formatDisplayValue = (value) => {
  return secondsToHMS(value);
}

const formatValue = (value) => {
  return value.replace(/[^\d.:]/g, '')
}

export default TimeField;