import {v4 as uuid} from "uuid";

const efforts = {
  a: 20,
  b: 12.5,
  c: 5,
  d: 0,
  e: -5,
};

const effortBands = {
  a: [17.5, Infinity],
  b: [7.5, 17.5],
  c: [2.5, 7.5],
  d: [-2.5, 2.5],
  e: [0, -2.5],
};

export const effortColors = {
  a: "#00CDFF",
  b: "#1ADEA3",
  c: "#E4F14F",
  d: "#F38D2E",
  e: "#F1584F",
};

export const calculateTargetTime = (ftp, distance, effort) => {
  return Math.round((ftp + efforts[effort]) / (100 / distance));
};

export const calculateEffort = (ftp, distance, time) => {
  const keys = Object.keys(effortBands).reverse();
  return keys.find(
    (key) => time < (ftp + effortBands[key][1]) * (distance / 100)
  );
};

export const defaultSet = {
  name: "",
  description: "",
  flavor: "main",
  repeat: 1,
  segmentCount: 1,
  equipment: [],
  totalDistance: 0,
  distance: 0,
};

export const defaultSegment = {
  repeat: 1,
  rest: 0,
  cycleTime: "",
  cycleTimeDisplay: "",
  isSplit: false,
  isDrill: false,
  equipment: [],
  splitCount: 0,
  distance: 0,
  stroke: "",
  effort: "d",
  targetTime: 0,
  targetTimeDisplay: "",
  splits: [],
};

export const defaultSplit = {
  distance: 0,
  stroke: "",
  effort: "d",
  targetTime: 0,
  targetTimeDisplay: "",
};

export const createSet = (isWarmup) => {
  return {
    ...defaultSet,
    id: uuid(),
    flavor: !!isWarmup ? "warmup" : "main",
    segments: [
      {
        ...defaultSegment,
        id: uuid(),
      },
    ],
  };
};

export const createSegment = (props) => {
  return {
    ...defaultSegment,
    ...props,
    id: uuid(),
  };
};

export const createSplit = (props) => {
  return {
    ...defaultSplit,
    ...props,
    id: uuid(),
  };
};

export const flavorsChip = {
  warmup: {
    label: 'Warm Up',
    color: 'success',
  },
  main: {
    label: 'Main Set',
    color: 'warning',
  },
  cooldown: {
    label: 'Cool Down',
    color: 'info',
  },
};