import { Fragment } from "react";
import {
  XAxis,
  YAxis,
  ReferenceArea,
  ReferenceLine,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import ReferenceLabel from "./ReferenceLabel";
import SegmentLabel from "./SegmentLabel";
import CustomToolTip from "./CustomToolTip";

import { CHART_CONSTANTS } from "./chartUtilities";
import {metricBarsData} from "./utils";
const labelHeight = CHART_CONSTANTS.labelHeight;



const StrokeChart = ({
  data,
  segments,
  selectedMetrics,
  isStroke,
  activeSegment,
  setActiveSegment,
}) => {
  const metrics = data.strokeTimingKeys;

  return (
    <ResponsiveContainer width="100%" height={247}>
      <BarChart
        data={data[`${isStroke ? "stroke" : "lap"}Raw`]}
        margin={{ top: 0, right: 40, left: 0, bottom: 0 }}
        syncId="anyId"
      >
        <XAxis
          type="number"
          dataKey="x"
          tick={false}
          domain={data[`${isStroke ? "stroke" : "lap"}Domain`]}
          height={labelHeight}
        />
        <YAxis width={40} tick={{ fontSize: 1 }} />
        <Tooltip offset={25} content={<CustomToolTip />} />
        {(selectedMetrics || metrics).map(key => (
          <Bar
            key={key}
            isAnimationActive={false}
            dataKey={metricBarsData[key].label}
            stackId="a"
            fill={metricBarsData[key].color}
          />
        ))}
        {data.annotations[
          `features${isStroke ? "Stroke" : "Lap"}`
          ].segmentStartIndices.map((index, i, arr) => {
          return (
            <Fragment key={"s" + index}>
              <ReferenceLine
                x={index - 0.5}
                stroke="#78848D"
                strokeOpacity={0.5}
              />
              <ReferenceArea
                x1={arr[i] - 0.5}
                x2={`${arr[i + 1] - 0.5}`}
                fillOpacity={activeSegment === i ? 0.2 : 0}
                label={
                  <ReferenceLabel active={activeSegment === i}>
                    <SegmentLabel segment={segments[i]} />
                  </ReferenceLabel>
                }
                onMouseOver={() => setActiveSegment(i)}
                onMouseOut={() => setActiveSegment(null)}
              />
            </Fragment>
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StrokeChart;
