import { useState } from 'react';
import { Button, Dialog, Link, styled } from '@mui/material';
import LandingHeroSection from './sections/LandingHeroSection';
import LandingSecondHeroSection from './sections/LandingSecondHeroSection';
import LandingFeaturesSection from './sections/LandingFeaturesSection';
import LandingCTASection from './sections/LandingCTASection';
import LandingFooterSection from './sections/LandingFooterSection';
import LandingNavSection from './sections/LandingNavSection';
import LandingActivitiesSection from './sections/LandingActivitiesSection';
import { pxToRem, responsiveFontSizes } from '../../util';
// dialogs
import AuthLoginForm from '../../components/auth/AuthLoginForm';
import AuthRegisterForm from '../../components/auth/AuthRegisterForm';
import AuthResetPasswordForm from '../../components/auth/AuthResetPasswordForm';
import AuthVerifyCodeForm from '../../components/auth/AuthVerifyCodeForm';
import AuthNewPasswordForm from '../../components/auth/AuthNewPasswordForm';
import AuthVerifyEmailForm from '../../components/auth/AuthVerifyEmailForm';
import { useAuthContext } from '../../auth/useAuthContext';

export const StyledRoot = styled('div')({
  h2: {
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ sm: 48, md: 54, lg: 60 })
  },
  h3: {
    fontSize: pxToRem(34),
    ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 })
  },
  h4: {
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 30, md: 32, lg: 34 })
  },
  '.MuiTypography-body1': {
    lineHeight: 1.5,
    fontSize: pxToRem(14),
    ...responsiveFontSizes({ sm: 16, md: 16, lg: 16 })
  },
  '.MuiTypography-subtitle1': {
    lineHeight: 1.5,
    fontSize: pxToRem(14),
    ...responsiveFontSizes({ sm: 16, md: 16, lg: 16 })
  },
  '.MuiButton-contained': {
    color: '#000000DE'
  }
});

const featureData = [
  {
    title: 'Tighten up your training',
    description:
      'With a dynamic form meter that continuously updates, you’ll know exactly where you’re at and whether you need to get a workout in or take a day off. A form history chart shows the shape of your training and lets you set up to be in the perfect zone before that big event.',
    imageSrc: '/images/landing/landing-feature-6.png'
  },
  {
    title: 'Dive deep into swim technique',
    description:
      'Apple watch users get advanced motion analytics about their freestyle stroke. See your entry angle, crossover, pull force and more. Correlation charts show how it all fits together.',
    imageSrc: '/images/landing/landing-feature-2.png',
    imageWidth: 612
  },
  {
    title: 'See your sets in detail',
    description:
      'Simma automatically tracks your sets and rests. See how your splits stack up to reveal the shape of your session or your heart rate for each lap.',
    imageSrc: '/images/landing/landing-feature-3.png',
    bgImageSrc: '/images/landing/landing-feature-3-bg.png'
  },
  {
    title: 'Heart rate charts for every session',
    description:
      'Use heart rate as a key metric in your training seeing it peak with your efforts and how it drops with recovery between sets. Adding your age details in your profile will personalise the heart rate zones for you.',
    imageSrc: '/images/landing/landing-feature-4.png'
  },
  // {
  //   title: 'Plan for the pool',
  //   description: 'Take the guessing out of what you should do next. Select the form zone you want to be in and the day you want to train. Simma will calculate how fast and how far you need to go to get in that zone.',
  //   imageSrc: '/images/landing/landing-feature-5.png',
  //   bgImageSrc: '/images/landing/landing-feature-5-bg.png',
  // },

  {
    title: 'Workouts from around the world',
    description:
      'Whether you are preparing for an Ironman or trying to sneak in a lunch time swim, select from an ever increasing library of workouts from top coaches around the world. Workouts are automatically scaled to your level.',
    imageSrc: '/images/landing/landing-feature-7.png',
    bgImageSrc: '/images/landing/landing-feature-7-bg.png'
  },
  {
    title: 'Connect with a coach',
    description:
      'Find a coach to get you to the next phase of your swimming. Share your training data and chat live from anywhere in the world.',
    imageSrc: '/images/landing/landing-feature-8.png',
    imageWidth: 'auto'
  }
];

const ctaData = [
  {
    title: 'Start your swim journey with Simma',
    description:
      'Gain exclusive access to personalised swim tracking and advanced analytics tailored to enhance your individual skills and requirements.'
  },
  {
    title: 'iPhone',
    description: 'Connect with your Apple or Garmin Watch by downloading our free iOS app.',
    imageSrc: '/images/landing/landing-apps-ios.png',
    buttonText: 'Download iOS App',
    link: 'https://apps.apple.com/us/app/simma/id1599744272?platform=iphone'
  },
  {
    title: 'Android',
    description:
      "Sign up for Simma's web application for free and connect seamlessly with your Garmin Watch.",
    imageSrc: '/images/landing/landing-apps-android.png'
  }
];

const defaultDialogsState = {
  login: false,
  register: false,
  forgotPassword: false,
  verifyCodeForm: false,
  verifyEmailForm: false,
  newPasswordForm: false
};

const LandingPage = () => {
  const { isAuthenticated } = useAuthContext();

  const [openDialogs, setOpenDialogs] = useState(defaultDialogsState);

  const handleOnLogin = () => setOpenDialogs({ ...defaultDialogsState, login: true });
  const handleOnRegister = () => setOpenDialogs({ ...defaultDialogsState, register: true });
  const handleOnForgotPassword = () =>
    setOpenDialogs({ ...defaultDialogsState, forgotPassword: true });
  const handleOnResetPassword = () =>
    setOpenDialogs({ ...defaultDialogsState, verifyCodeForm: true });
  const handleOnVerifyEmail = () =>
    setOpenDialogs({ ...defaultDialogsState, verifyEmailForm: true });
  const handleOnClose = () => setOpenDialogs(defaultDialogsState);

  return (
    <StyledRoot>
      <LandingNavSection
        action={
          isAuthenticated ? (
            <Button href='/progress' variant='outlined'>
              Go to app
            </Button>
          ) : (
            <Button onClick={handleOnLogin} variant='outlined' rel='noopener noreferrer'>
              Log In
            </Button>
          )
        }
      />

      <LandingHeroSection
        title='Welcome to the next generation platform for athletes and coaches'
        action={
          isAuthenticated ? (
            <Button component={Link} href='/progress' variant='contained'>
              Get Started
            </Button>
          ) : (
            <Button onClick={handleOnRegister} variant='contained'>
              Get Started
            </Button>
          )
        }
      />

      <LandingActivitiesSection />

      <LandingSecondHeroSection
        title='Apple and Garmin ready'
        description='Push workouts to the watch so you can follow along. Works with both Apple Watch and Garmin Watches.'
        imageSrc='/images/landing/landing-feature-1.png'
      />

      <LandingFeaturesSection data={featureData} />

      <LandingCTASection
        data={ctaData}
        action={
          isAuthenticated ? (
            <Button component={Link} href='/progress' variant='contained'>
              Sign Up Now
            </Button>
          ) : (
            <Button onClick={handleOnRegister} variant='contained'>
              Sign Up Now
            </Button>
          )
        }
      />

      <LandingFooterSection />

      <Dialog
        fullWidth
        sx={{ maxWidth: 440, m: 'auto' }}
        open={openDialogs.login}
        onClose={handleOnClose}>
        <AuthLoginForm onRegister={handleOnRegister} onForgotPassword={handleOnForgotPassword} />
      </Dialog>

      <Dialog
        fullWidth
        sx={{ maxWidth: 440, m: 'auto' }}
        open={openDialogs.register}
        onClose={handleOnClose}>
        <AuthRegisterForm onLogin={handleOnLogin} onVerifyEmail={handleOnVerifyEmail} />
      </Dialog>

      <Dialog
        fullWidth
        sx={{ maxWidth: 440, m: 'auto' }}
        open={openDialogs.forgotPassword}
        onClose={handleOnClose}>
        <AuthResetPasswordForm onResetPassword={handleOnResetPassword} />
      </Dialog>

      <Dialog
        fullWidth
        sx={{ maxWidth: 440, m: 'auto' }}
        open={openDialogs.verifyCodeForm}
        onClose={handleOnClose}>
        <AuthVerifyCodeForm onVerify={handleOnLogin} />
      </Dialog>

      <Dialog
        fullWidth
        sx={{ maxWidth: 440, m: 'auto' }}
        open={openDialogs.newPasswordForm}
        onClose={handleOnClose}>
        <AuthNewPasswordForm />
      </Dialog>

      <Dialog
        fullWidth
        sx={{ maxWidth: 440, m: 'auto' }}
        open={openDialogs.verifyEmailForm}
        onClose={handleOnClose}>
        <AuthVerifyEmailForm onContinue={handleOnLogin} />
      </Dialog>
    </StyledRoot>
  );
};

export default LandingPage;
