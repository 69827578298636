import SvgIcon from '@mui/material/SvgIcon';
const KickboardIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M6 10C6 6.68629 8.68629 4 12 4C15.3137 4 18 6.68629 18 10V18.1018C18 18.9064 17.0563 19.4427 16.31 19.1419C15.2477 18.7136 13.7486 18.2857 12 18.2857C10.2514 18.2857 8.75232 18.7136 7.68996 19.1419C6.94369 19.4427 6 18.9064 6 18.1018V10Z" fill="#EBEBF5" fillOpacity="0.6"/>
    </SvgIcon>
  );
}

export default KickboardIcon;
