import {chartColors} from "../../style/colors";

export const selectorMetricOptions = {
  // Pace
  crossoverAngle: { label: "Crossover", color: chartColors.blue},
  entryAngle: { label: "Hand entry", color: chartColors.green },
  strokeRate: { label: "Stroke rate", color: chartColors.yellow },
  splashScore: { label: "Splash", color: chartColors.orange },
  forceScore: { label: "Force", color: chartColors.red },
  // Stroke
  glideTime: { label: "Glide", color: chartColors.blue },
  downsweepTime: { label: "Downsweep", color: chartColors.green },
  insweepTime: { label: "Insweep", color: chartColors.yellow },
  upsweepTime: { label: "Upsweep", color: chartColors.orange },
  recoveryTime: { label: "Recovery", color: chartColors.red },
};

export const metricLinesData = {
  crossoverAngle: { label: "Crossover angle", color: chartColors.blue },
  entryAngle: { label: "Entry angle", color: chartColors.green },
  strokeRate: { label: "Stroke rate", color: chartColors.yellow },
  splashScore: { label: "Splash score", color: chartColors.orange },
  forceScore: { label: "Force score", color: chartColors.red },
};

export const metricBarsData = {
  glideTime: { label: "Glide", color: chartColors.blue },
  downsweepTime: { label: "Downsweep", color: chartColors.green },
  insweepTime: { label: "Insweep", color: chartColors.yellow },
  upsweepTime: { label: "Upsweep", color: chartColors.orange },
  recoveryTime: { label: "Recovery", color: chartColors.red },
};