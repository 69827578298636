import PropTypes from 'prop-types';
// form
import {useFormContext, Controller} from 'react-hook-form';
// @mui
import {Checkbox, FormControlLabel, FormGroup, FormHelperText} from '@mui/material';
import * as React from "react";

// ----------------------------------------------------------------------

RHFCheckbox.propTypes = {
  name: PropTypes.string,
};

export function RHFCheckbox({name, ...other}) {
  const {control} = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({field}) => <FormControlLabel control={<Checkbox {...field} checked={field.value}/>} {...other} />}
    />
  );
}

// ----------------------------------------------------------------------

RHFMultiCheckbox.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
};

export function RHFMultiCheckbox({name, options, formGroupProps, transform, ...other}) {
  const {control} = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState: {error}}) => {
        const onSelected = (option) =>
          field.value.includes(option) ? field.value.filter((value) => value !== option) : [...field.value, option];

        const onChangeWithTransform = transform?.output ?? onSelected;
        const valueWithTransform = transform?.input(field.value) ?? field.value;

        return (
          <>
            <FormGroup row {...formGroupProps}>
              {options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  disabled={!!option.disabled}
                  error={error}
                  control={
                    <Checkbox
                      checked={valueWithTransform.includes(option.value)}
                      onChange={() => field.onChange(onChangeWithTransform(option.value, field.value))}
                    />
                  }
                  label={option.label}
                  {...other}
                />
              ))}
            </FormGroup>

            {!!error && (
              <FormHelperText error>
                {error.message}
              </FormHelperText>
            )}
          </>
        );
      }}
    />
  );
}
